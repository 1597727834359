


























































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import moment from 'moment';
import ApiHelper from 'api-helper';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import Zoom from '@components/iconSVG/Zoom.vue';
import ImageZoomComponent from './ImageZoomComponent.vue';
import Toast from '@common/helpers/toast';
import resizeImage from '@common/utils/resizeImage';

@Component({
  components: {
    ImagePrivate,
    ImageZoomComponent,
    Zoom,
  },
  filters: {
    timeFormat(value) {
      if (!value) { return ''; }
      return moment(value).format('YYYY/MM/DD');
    },
  },
})
export default class PatientDetailImageDescriptionOrigin extends Vue {
  @Prop(Object) readonly listMouthImageOrigin!: object;
  @Prop(Object) readonly dataPatientDetail!: object;
  @Prop(String) readonly avatar!: string;
  @Prop(Object) readonly dataDetail!: object;
  @Prop(Boolean) readonly isAdmin!: false;
  private listImageDes: any = {};
  private listImageDesUpload: any = {};
  private imagePosition: string = '';
  private isUploadImage: boolean = false;
  private listMouthImageOriginData: any = {};

  get imageDesUpload() {
    return this.$refs.imageDesUpload as any;
  }

  get isDisabledUpload() {
    return !Object.keys(this.listImageDesUpload).length;
  }

  get titleBtnUpload() {
    if (this.listMouthImageOriginData && !this.listMouthImageOriginData.id) {
      return '写真を登録する';
    }
    return '写真を再登録する';
  }

  mounted() {
    this.listMouthImageOriginData = (this.listMouthImageOrigin as any).doctor_images;
  }

  @Watch('listMouthImageOrigin')
  private onDataDetail() {
    this.listMouthImageOriginData = (this.listMouthImageOrigin as any).doctor_images;
    // if (this.listMouthImageOrigin.doctor_images && !this.listMouthImageOrigin.doctor_images.id) {
    //   this.isUploadImage = true;
    // } else {
    //   this.listImageDes = {};
    // }
  }

  private cancelHandleUpload() {
    // if (this.listMouthImageOriginData.id) {
    //   this.isUploadImage = false;
    // }
    this.isUploadImage = false;
    this.listImageDes = {};
  }

  private handleUpload() {
    this.listImageDes = {};
    this.isUploadImage = !this.isUploadImage;
  }

  private submitImageDescription() {
    this.$emit('loadingChild');
    const formData = new FormData();
    formData.append('user_id', (this.dataPatientDetail as any).id);
    if (this.listImageDesUpload.top) {
      formData.append('image_top', this.listImageDesUpload.top);
    }
    if (this.listImageDesUpload.left) {
      formData.append('image_left', this.listImageDesUpload.left);
    }
    if (this.listImageDesUpload.center) {
      formData.append('image_mid', this.listImageDesUpload.center);
    }
    if (this.listImageDesUpload.right) {
      formData.append('image_right', this.listImageDesUpload.right);
    }
    if (this.listImageDesUpload.bottom) {
      formData.append('image_bot', this.listImageDesUpload.bottom);
    }
    ApiHelper.getApi('DoctorMouthApi').uploadMouthImage(formData)
      .then((response) => {
        this.isUploadImage = false;
        this.$emit('reloadMouthImage');
      })
      .catch((error) => {
        Toast.error(error.message);
      })
    .finally(() => {
      this.$emit('loadingChild');
    });
  }

  private openUploadImage(positionImage) {
    if (this.isUploadImage) {
      this.imagePosition = positionImage;
      this.imageDesUpload.click();
    }
  }

  private onFileChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) { return; }
    this.createImage(files[0], this.imagePosition);
  }

  private async createImage(file, positionImage) {
    const reader = new FileReader();
    const vm = this;

    reader.onload = (event) => {
      vm.listImageDes = {...vm.listImageDes, [positionImage]: (event as any).target.result};
    };
    reader.readAsDataURL(file);
    const resizeFile = await resizeImage.compress_until(file, 5242880);
    this.listImageDesUpload = {...this.listImageDesUpload, [positionImage]: resizeFile};
  }
}
