import BaseApi from './BaseApi';

class MedicineApi extends BaseApi {
  public async create(params: any) {
    return await this.post('/', params);
  }

  public fetchMedicineList(params: any) {
    return this.get('/', params);
  }

  public update(medicineId: number, params: any) {
    return this.post(`/${medicineId}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/medicines';
  }
}

export default MedicineApi;
