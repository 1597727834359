<template >
<div>
  <ChimeWaiting
    :reservation="reservation"
    :isCameraOpen="isCameraOpen"
    :isMute="isMute"
    :toggleCamera="toggleCamera"
    :toggleAudioInput="toggleAudioInput"
    :audioInputDevice="audioInputDevice"
    :videoInputDevice="videoInputDevice"
    :audioOutputDevice="audioOutputDevice"
    :listAudioInputDevices="listAudioInputDevices"
    :listAudioOutputDevices="listAudioOutputDevices"
    :listVideoInputDevices="listVideoInputDevices"
    :isLoaded="isLoaded"
    :hasVideoWait="hasVideoWait"
    @onSelectDevice="onSelectDevice"
    @startCalling="onCalling"
    v-if="!isCalling"
  />
  <ChimeCalling
    :reservation="reservation"
    :isCameraOpen="isCameraOpen"
    :isMute="isMute"
    :meetingSession="meetingSession"
    :toggleCamera="toggleCamera"
    :toggleAudioInput="toggleAudioInput"
    :audioInputDevice="audioInputDevice"
    :videoInputDevice="videoInputDevice"
    :audioOutputDevice="audioOutputDevice"
    :isUserjoin="isUserjoin"
    :hasVideoCall="hasVideoCall"
    :isShareScreen="isShareScreen"
    :isShareScreenLoading="isShareScreenLoading"
    @toggleShareScreen="toggleShareScreen"
    :isCallingLoaded="isCallingLoaded"
    @blurCamera="blurCamera"
    :isBlur="isBlur"
    :isStartCalling="isStartCalling"
    @endMeeting="openPopupConfirm"
    v-else
  />
  <PopupConfirm
    :message="'診察を終了しますか？'"
    name="confirmCloseCalling"
    @submit="confirmCloseCalling"
    @cancel="$modal.hide('confirmCloseCalling')"
    height="auto"
  />
</div>
</template>

<script>
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultModality,
  DefaultVideoTransformDevice,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import ChimeWaiting from '@/components/components/chime/chime-waiting.vue';
import ChimeCalling from '@/components/components/chime/chime-calling.vue';
import { BlurBackgroundProcessor } from '@/common/modules/backgroundProcessor';
import ApiHelper from 'api-helper';
import { mapState } from 'vuex';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import {
  faVideo,
  faMicrophone,
  faVolumeUp,
  faMicrophoneSlash,
  faVideoSlash,
  faDesktop,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'calling',
  components: {
    ChimeWaiting,
    ChimeCalling,
    PopupConfirm,
  },
  // layout: 'chime',
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      hasPermisson: false,
      isCameraOpen: false,
      isCalling: false,
      isMute: true,
      meetingSession: {},
      reservation: {},
      audioInputDevice: {},
      videoInputDevice: {},
      audioOutputDevice: {},
      listAudioInputDevices: [],
      listAudioOutputDevices: [],
      listVideoInputDevices: [],
      observer: {},
      isUserjoin: false,
      isLoaded: false,
      isCallingLoaded: false,
      isShareScreenLoading: false,
      hasVideoWait: false,
      hasVideoCall: false,
      isShareScreen: false,
      isBlur: false,
      isStartCalling: false,
    };
  },

  async mounted() {
    await this.createMeetingInfo();
    await this.getListMediaDevices();
    await this.toggleCamera();
    this.toggleAudioInput();
    this.observer = {
      videoTileDidUpdate: (tileState) => {
        if (!tileState.boundAttendeeId) {
          return;
        }
        const yourAttendeeId =
          this.meetingSession.configuration.credentials.attendeeId;
        console.log(yourAttendeeId);
        // tileState.boundAttendeeId is formatted as 'attendee-id#content'.
        const boundAttendeeId = tileState.boundAttendeeId;
        console.log(boundAttendeeId);
        // Get the attendee ID from 'attendee-id#content'.
        const baseAttendeeId = new DefaultModality(boundAttendeeId).base();
        console.log(baseAttendeeId);
        if (baseAttendeeId === yourAttendeeId) {
          console.log('You called startContentShareFromScreenCapture');
        }
        this.updateTiles();
      },
      contentShareDidStart: () => {
        console.log('Screen share started');
      },
      contentShareDidStop: () => {
        this.isShareScreenLoading = false;
        console.log('Screen share stopped');
      },
    };
    this.meetingSession.audioVideo.addContentShareObserver(this.observer);
    this.meetingSession.audioVideo.addObserver(this.observer);
    if (this.videoInputDevice?.deviceId) {
      const previewVideoEl = document.querySelector('#preview-video');
      this.meetingSession.audioVideo.startVideoPreviewForVideoInput(
        previewVideoEl,
      );
    }
    this.isLoaded = true;
  },

  setTagAura(tagId, tagsDelete) {
    const lineId = this.$route.query.lineId;
    const params = {
      tags: [tagId],
      is_add_and_delete: tagsDelete ? 1 : 0,
      line_id: lineId,
      delete_after_add: tagsDelete || [],
    };
    ApiHelper.getApi('AuraApi').addAuraTag(params);
  },

  beforeDestroy() {
    if (this.$router.currentRoute.meta.isResv) {
      this.setTagAura(863288, null);
    }
    this.stopCameraStream();
    this.meetingSession.audioVideo.removeObserver(this.observer);
    this.meetingSession.audioVideo.stop();
  },

  created() {
    library.add(faVideo);
    library.add(faMicrophone);
    library.add(faVolumeUp);
    library.add(faMicrophoneSlash);
    library.add(faVideoSlash);
    library.add(faDesktop);
    dom.watch();
  },

  beforeRouteLeave(to, from, next) {
    if (this.$router.currentRoute.meta.isResv) {
      this.setTagAura(863288, null);
    }
    if (this.needCheck) {
      next();
      return;
    }
    this.next = next;
    this.$modal.show('confirmCloseCalling');
  },

  methods: {
    confirmCloseCalling() {
      this.endMeeting();
    },
    openPopupConfirm() {
      this.$modal.show('confirmCloseCalling');
    },
    cancelPopupConfirm() {
      this.$modal.hide('confirmCloseCalling');
    },
    async createMeetingInfo() {
      const channel_id = this.$router.currentRoute.params.channelId;
      this.reservation = {
        user: {
          name: this.$router.currentRoute.query.userName,
        },
        doctor: {
          name: this.currentUser.name,
        },
      };
      const meetingInfo = await ApiHelper.getApi('ChannelApi').getResvCalling(channel_id)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log('error', error);
          Toast.error(error.response.data.message);
        })
        .finally(() => {
          console.log('finally');
        });

      const meetingInfoFormat = {
        attendee: {
          attendeeId: meetingInfo.attendee.AttendeeId,
          externalUserId: meetingInfo.attendee.ExternalUserId,
          joinToken: meetingInfo.attendee.JoinToken,
        },
        meeting: {
          mediaRegion: meetingInfo.meeting.MediaRegion,
          meetingId: meetingInfo.meeting.MeetingId,
          mediaPlacement: {
            audioHostUrl: meetingInfo.meeting.MediaPlacement.AudioHostUrl,
            screenDataUrl: meetingInfo.meeting.MediaPlacement.ScreenDataUrl,
            screenSharingUrl:
              meetingInfo.meeting.MediaPlacement.ScreenSharingUrl,
            screenViewingUrl:
              meetingInfo.meeting.MediaPlacement.ScreenViewingUrl,
            signalingUrl: meetingInfo.meeting.MediaPlacement.SignalingUrl,
            turnControlUrl: meetingInfo.meeting.MediaPlacement.TurnControlUrl,
          },
        },
      };
      this.createMeetingSession(meetingInfoFormat);
    },

    createMeetingSession(meetingInfo) {
      const logger = new ConsoleLogger('MyLogger', LogLevel.OFF);
      const deviceController = new DefaultDeviceController(logger);
      const meetingResponse = meetingInfo.meeting;
      const attendeeResponse = meetingInfo.attendee;
      const configuration = new MeetingSessionConfiguration(
        meetingResponse,
        attendeeResponse,
      );
      this.meetingSession = new DefaultMeetingSession(
        configuration,
        logger,
        deviceController,
      );
      const attendeePresenceSet = new Set();
      let attendeePresenceArr = [];
      const callback = (presentAttendeeId, present) => {
        console.log(`Attendee ID: ${presentAttendeeId} Present: ${present}`);
        this.isStartCalling = false;
        if (presentAttendeeId.includes('#') && present) {
          this.isShareScreen = true;
          return;
        }
        if (presentAttendeeId.includes('#') && !present) {
          this.isShareScreen = false;
          return;
        }
        this.isLoaded = true;
        if (present) {
          attendeePresenceSet.add(presentAttendeeId);
          attendeePresenceArr = [...attendeePresenceSet];
          if (presentAttendeeId !== attendeePresenceArr[0]) {
            this.isUserjoin = true;
            console.log('user joined', attendeePresenceArr, presentAttendeeId);
          }
        } else {
          attendeePresenceSet.delete(presentAttendeeId);
          attendeePresenceArr = [...attendeePresenceSet];
          console.log(attendeePresenceArr, presentAttendeeId);
          if (this.isCalling) {
            const videoAttendeeElm = document.querySelector(
              `.video-container #video-${presentAttendeeId}`,
            );
            videoAttendeeElm.parentElement.remove();
            console.log('user left');
          }
          this.isUserjoin = false;
        }
      };

      this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
        callback,
      );
    },
    async getAudioDevices() {
      this.listAudioInputDevices =
        await this.meetingSession.audioVideo.listAudioInputDevices();
      this.listAudioOutputDevices =
        await this.meetingSession.audioVideo.listAudioOutputDevices();
      this.audioInputDevice = this.listAudioInputDevices[0];
      this.audioOutputDevice = this.listAudioOutputDevices[0];
      try {
        await this.meetingSession.audioVideo.chooseAudioInputDevice(
          this.audioInputDevice.deviceId,
        );
      } catch (e) {
        this.audioInputDevice = {};
      }
      try {
        await this.meetingSession.audioVideo.chooseAudioOutputDevice(
          this.audioOutputDevice.deviceId,
        );
      } catch (e) {
        this.audioOutputDevice = {};
      }
    },
    async getListMediaDevices() {
      await this.getAudioDevices();
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        this.listVideoInputDevices =
          await this.meetingSession.audioVideo.listVideoInputDevices();
        this.videoInputDevice = this.listVideoInputDevices[0];
        await this.meetingSession.audioVideo.chooseVideoInputDevice(
          this.videoInputDevice.deviceId,
        );
      } catch (e) {
        this.videoInputDevice = {};
      }
    },

    async onSelectDevice(type, value) {
      switch (type) {
        case 'videoInput': {
          this.videoInputDevice = value;
          await this.meetingSession.audioVideo.chooseVideoInputDevice(
            this.videoInputDevice.deviceId,
          );
          break;
        }
        case 'audioInput':
          this.audioInputDevice = value;
          await this.meetingSession.audioVideo.chooseAudioInputDevice(
            this.audioInputDevice.deviceId,
          );
          break;
        case 'audioOutput':
          this.audioOutputDevice = value;
          await this.meetingSession.audioVideo.chooseAudioOutputDevice(
            this.audioOutputDevice.deviceId,
          );
          break;
        default:
          break;
      }
    },
    endMeeting() {
      ApiHelper.getApi('ChannelApi')
        .endCalling(this.$route.params.channelId)
        .then((res) => {
          window.close();
        })
        .catch((error) => {
          if (error.response) {
            Toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          window.close();
        });
    },

    async openCameraStream() {
      await this.meetingSession.audioVideo.chooseVideoInputDevice(
        this.videoInputDevice.deviceId,
      );
      this.meetingSession.audioVideo.startLocalVideoTile();
    },

    stopCameraStream() {
      this.meetingSession.audioVideo.stopLocalVideoTile();
    },

    async toggleCamera() {
      if (this.videoInputDevice) {
        if (this.isCameraOpen) {
          await this.stopCameraStream();
        } else {
          await this.openCameraStream();
        }
        this.isCameraOpen = !this.isCameraOpen;
        this.hasVideoWait = !this.hasVideoWait;
      }
    },

    toggleAudioInput() {
      if (this.isMute) {
        this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
      } else {
        this.meetingSession.audioVideo.realtimeMuteLocalAudio();
      }
      this.isMute = !this.isMute;
    },

    onCalling() {
      this.isStartCalling = true;
      this.stopCameraStream();
      this.isCalling = true;
      this.$nextTick(() => {
        if (this.isCameraOpen) {
          this.openCameraStream();
        }
      });
    },
    updateTiles() {
      const tiles = this.meetingSession.audioVideo.getAllVideoTiles();
      tiles.forEach((tile) => {
        const tileId = tile.tileState.tileId;
        const tileboundAttendeeId = tile.tileState.boundAttendeeId;
        if (tile.tileState.isContent) {
          const videoShareElm = document.querySelector(
            '.share-screen-wrap .share-screen-video',
          );
          this.meetingSession.audioVideo.bindVideoElement(
            tileId,
            videoShareElm,
          );
          this.isShareScreenLoading = false;
          console.log('Start share');
        } else {
          let videoElement = document.getElementById(
            'video-' + tileboundAttendeeId,
          );
          if (!videoElement) {
            videoElement = document.createElement('video');
            videoElement.id = 'video-' + tileboundAttendeeId;
            const videoContainer = document.createElement('div');
            videoContainer.className = 'video-container';
            videoContainer.append(videoElement);
            document.querySelector('.video-list').append(videoContainer);
          }
          this.meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
          this.hasVideoCall = true;
        }
      });
    },
    async toggleShareScreen() {
      try {
        if (!this.isShareScreenLoading) {
          if (!this.isShareScreen) {
            this.isShareScreenLoading = true;
            const stream =
              await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
            stream.getVideoTracks()[0].onended = () => {
              this.isShareScreenLoading = true;
            };
            this.isShareScreen = true;
          } else {
            this.isShareScreenLoading = true;
            await this.meetingSession.audioVideo.stopContentShare();
            this.isShareScreen = false;
          }
        }
      } catch (err) {
        this.isShareScreenLoading = false;
        this.isShareScreen = false;
      }
    },
    async blurCamera() {
      if (!this.isCallingLoaded) {
        if (!this.isBlur) {
          this.isCallingLoaded = true;
          const blurDevice = await new DefaultVideoTransformDevice(
            new ConsoleLogger('SDK', LogLevel.INFO),
            this.videoInputDevice.deviceId,
            [await new BlurBackgroundProcessor()],
          );
          await this.meetingSession.audioVideo.chooseVideoInputDevice(
            blurDevice,
          );
          this.isCallingLoaded = false;
        } else {
          this.isCallingLoaded = true;
          await this.meetingSession.audioVideo.chooseVideoInputDevice(
            this.videoInputDevice.deviceId,
          );
          this.isCallingLoaded = false;
        }
        this.isBlur = !this.isBlur;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/.v--modal-background-click .v--modal-box.v--modal {
  top: 43vh !important;
  left: 45% !important;
}
</style>