



















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { AuthenticationModule } from '@/store';
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import { PatientModule } from '@/store';
import { formatDateTime } from '@/common/app.config';
import moment from 'moment';
import PageLoader from '@components/common/PageLoader.vue';

@Component({
  components: {
    Pagination,
    PageLoader,
  },
  data() {
    return {
      listHealth: {},
    };
  },
})

export default class Index extends Vue {
  private page = 1;
  private perPage = 10;
  private pageCount = 1;
  private listHealth!: any;
  private isLoading: boolean = false;

  private get dataPatientDetail() {
    return PatientModule.dataPatientDetail;
  }

  private formatDateTime(time) {
    return moment(time).format(formatDateTime);
  }

  private async patientDetail() {
    try {
      const { id } = this.$route.params;
      const data = {
        id,
      };
      await PatientModule.getPatientDetail(data);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  private async list() {
    try {
      this.listHealth = {};
      this.isLoading = true;
      const { id } = this.$route.params;
      const params = {
        user_id: id,
        per_page: this.perPage,
        page: this.page,
      };
      const response = await ApiHelper.getApi('PatientApi').listHealth(params);
      this.pageCount = response.last_page;
      this.listHealth = response.data;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      const errors = error.response.data;
      Toast.error(errors.message);
    }
  }

  private async created() {
    this.patientDetail();
    this.list();
  }

  private pageChanged(page) {
    this.page = page;
    this.list();
  }
}
