



























































































































import { Vue, Component } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { splitLongText } from '@common/helpers/split-long-text';
import PageLoader from '@components/common/PageLoader.vue';
import ContentLayout from '@components/layouts/Content.vue';
import Listicon from '@/assets/img/clinicListicon.svg';
import { config } from '@/common/app.config';
import { CLINIC_TYPE_TO_TEXT } from '@/common/constants/auth';

@Component({
  components: {
    Pagination,
    PageLoader,
    ContentLayout,
  },
  data() {
    return {
      listClinics: {},
      iconHeader: Listicon,
      appConfig: config,
    };
  },
})
export default class Index extends Vue {
  public appConfig: any;
  public iconHeader: any;
  public page = this.$route.query.page || 1;
  public perPage = 10;
  public pageCount = 1;
  public listClinics!: any;
  public isLoading: boolean = false;

  public searchForm = {
    id: this.$route.query.id || '',
    name: this.$route.query.name || '',
    name_kana: this.$route.query.name_kana || '',
    with: 'managers',
    order: '-created_at',
    per_page: this.perPage,
    page: this.page,
  };

  public longText(text: string, qty: number) {
    return splitLongText(text, qty);
  }

  public searchClinic() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));

    this.searchForm.page = 1;
    this.page = 1;
    this.list(this.searchForm);
  }

  public pageChanged(page) {
    let paramsSearch: any;

    if (LocalStorageUtils.getItem('paramsSearch')) {
      paramsSearch = JSON.parse(LocalStorageUtils.getItem('paramsSearch'));
    } else {
      paramsSearch = Object.assign({}, this.searchForm);
      paramsSearch.id = '';
      paramsSearch.name = '';
      paramsSearch.name_kana = '';
    }

    paramsSearch.page = page;
    this.page = page;
    this.list(paramsSearch);
  }

  public gotoDetail(id) {
    this.$router.push({
      path: `/clinics/show/${id}`,
      query: {
        id: this.searchForm.id || '',
        name: this.searchForm.name || '',
        clinic_name: this.searchForm.name_kana || '',
        page: this.page ? this.page.toString() : '1',
      },
    });
  }

  private async created() {
    if (LocalStorageUtils.getItem('paramsSearch')) {
      LocalStorageUtils.removeItem('paramsSearch');
    }

    this.list(this.searchForm);
  }

  private mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  private async list(data) {
    this.isLoading = true;

    await ApiHelper.getApi('ClinicApi')
      .list(data)
      .then((res: any) => {
        this.listClinics = res.data;
        this.pageCount = res.last_page;
      })
      .catch((error: any) => {
        this.toast(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  private typeToText(type) {
    return CLINIC_TYPE_TO_TEXT[type] || '-';
  }
}
