import BaseApi from './BaseApi';

class MonthlyReportApi extends BaseApi {
  public detail(params: any) {
    return this.get('/detail', params);
  }

  public summary(params: any) {
    return this.get('/summary', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/revenue-management/report-month';
  }
}

export default MonthlyReportApi;
