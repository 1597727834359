import BaseApi from './BaseApi';

class PharmacistReservationApi extends BaseApi {
  public startResv(id: number | string, params?: any) {
    return this.post(`/${id}/start`, params);
  }

  public finishResv(id: number | string, params?: any) {
    return this.post(`/${id}/finish`, params);
  }

  public recordVideos(id: number|string, params?: any) {
    return this.get(`/${id}/records`, params);
  }

  public deleteVideo(id: number|string, record: number|string) {
    return this.del(`/${id}/records/${record}`);
  }

  public cancelResv(id: number | string, params: any = {}) {
    return this.post(`/${id}/cancel`, params);
  }

  public updateMemo(id: number | string, params: any = {}) {
    return this.post(`/${id}/update-memo`, params);
  }

  public uploadPrescription(id: number | string, params: any) {
    return this.postWithFile(`/${id}/prescriptions`, params);
  }

  public uploadBill(id: number | string, params: any) {
    return this.postWithFile(`/${id}/upload-bill`, params);
  }

  public deleteBill(id: number | string, params: any) {
    return this.del(`/bill/${id}`, params);
  }

  public deletePrescription(id: number | string, params: any) {
    return this.del(`/prescriptions/${id}`, params);
  }

  public payment(id: number | string, params: any) {
    return this.post(`/${id}/payment`, params);
  }

  public dateValidShift(id: number | string, params: any) {
    return this.get(`/${id}/dates-valid-shift`, params);
  }

  public timeAvailable(id: number | string, params: any) {
    return this.get(`/${id}/time-available`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/pharmacist-reservations';
  }
}

export default PharmacistReservationApi;
