






































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { DoctorModule } from '@/store';
import { Doctor,  Clinic, DataForm, defaultDataForm } from '@/models/doctors';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import Toast from '@common/helpers/toast';
import { extend } from 'vee-validate';

import { Roles } from '@/common/app.config';
import ApiHelper from 'api-helper';
import UploadImage from '@/components/common/UploadImage.vue';
import TableDoctorForm from '@components/Doctor/TableDoctorForm.vue';

@Component({
  components: {
    UploadImage,
    TableDoctorForm,
    PopupConfirm,
  },
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
})
export default class HomeCreate extends Vue {
  public extend: any = extend;
  // Data
  private isSubmit: boolean = false;
  private dataForm: DataForm = JSON.parse(JSON.stringify(defaultDataForm));
  private currentUser: any;

  // Computed
  private get computedForm() {
    return this.$refs.doctorCreateForm as any;
  }

  private get roles() {
    return Roles;
  }

  private get isEditClinic() {
    return this.roles.clinic !== this.currentUser.role;
  }

  // Methods
  private updateForm(field: string, value: string | Clinic[] | Doctor) {
    this.$set(this.dataForm, field, value);
  }

  private async openPopupConfirm(event) {
    event.preventDefault();
    event.stopPropagation();
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    this.$modal.show('confirmCreateDoctor');
  }
  private async openPopupCancelCreateDoctor() {
    this.$modal.show('confirmCancelCreateDoctor');
  }

  private cancelPopupConfirm() {
    this.$modal.hide('confirmCreateDoctor');
  }

  private cancelPopupConfirmCancelDoctor() {
    this.$modal.hide('confirmCancelCreateDoctor');
  }

  private cancelCreateDoctor() {
    this.$router.push({ name: 'doctors'});
  }

  private async createDoctor() {
    if (!this.dataForm.clinic) {
      Toast.error('please choose clinic');
      return ;
    }
    try {
      this.isSubmit = true;
      await DoctorModule.addDoctor(this.dataForm);
      this.isSubmit = false;
      Toast.success('登録しました');
      this.cancelPopupConfirm();
      this.$router.push({name: 'doctors'});
    } catch (error) {
      this.isSubmit = false;
      this.cancelPopupConfirm();
      if (error.response) {
        const { data, status } = error.response;
        if (status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
      }
      Toast.error(error.message);
    }
  }

  // private async autoChooseClinic() {
  //   if (this.currentUser.role === this.roles.clinic) {
  //     const { id, name, name_kana } = this.currentUser.clinics[0];
  //     this.updateForm('clinic', [{ id, name, name_kana }]);
  //   }
  // }

  private get imageErrorMessages() {
    return {
      mimes: '画像はjpg, pngのファイルでアップロードしてください',
    };
  }

  private get isPermission() {
    return this.currentUser && (this.currentUser.role === Roles.superAdmin || this.currentUser.role === Roles.admin);
  }


  private mounted() {
    // this.autoChooseClinic();
    window.scrollTo(0, 0);
  }

  private created() {
    this.extend('priority', {
      validate: this.searchPriority,
      message: '重複されましたため、他の数字を入力してください。',
    });
  }

  private async searchPriority() {
    try {
      const params = {
        priority: Number(this.dataForm.priority),
      };
      const { is_valid } = await ApiHelper.getApi('PriorityApi').validatePriority(params);
      return is_valid;
    } catch (error) {
      return false;
    }
  }
}
