import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
// import { CLINIC_TYPE, Roles } from '@common/app.config';
// import { uiAvatars } from '@common/helpers/ui-avatar';
import avatarManageDefault from '@/assets/img/BIOPORT_PLUG.svg';
import Avatar from '@components/v2/Avatar.vue';

@Component({
  computed: {
    ...mapGetters('channel', [
      'currentChannel',
    ]),
    ...mapState('auth', [
      'currentUser',
    ]),
    ...mapState('message', [
      'isMeIds',
    ]),
  },
  components: {
    Avatar,
  },
})
export default class MessageMixin extends Vue {
  currentChannel!: any;
  currentUser!: any;
  isMeIds!: any;


  @Prop({type: Object, default: () => [], required: true}) readonly message!: any;

  get formatTime() {
    return 'MM月DD日 HH:mm';
  }

  hasAvatar(message) {
    return !!message.senderable.avatar_url;
  }

  getAvatarDefaultManager() {
    return avatarManageDefault;
  }

  isMessageSendByMe(message) {
    return message.senderable.id === this.currentUser.id && message.senderable_type === 'App\\Models\\Manager';
  }

  // get userSender() {
  //   const users = this.currentChannel && this.currentChannel.users;
  //   return users ? users[0] : {};
  // }

  // getAvatar(message) {
  //   if (this.isMessageSendByMe(message)) {
  //     if (this.currentUser.role === Roles.pharmacy) {
  //       const pharmacy = this.currentUser.pharmacies[0];
  //       return pharmacy.image_url || uiAvatars(pharmacy.name);
  //     }

  //     const {clinics} = this.currentUser;
  //     const clinic = message.senderable_type === CLINIC_TYPE
  //                 ? clinics.find((item) => item.id === message.sender_id) : this.currentUser;
  //     return clinic.avatar || uiAvatars(clinic.name);
  //   }
  //   const userAvart = this.userSender;

  //   if (!userAvart.family_name && !userAvart.given_name && !userAvart.avatar) {
  //     return imageDefault;
  //   }

  //   const name = `${userAvart.family_name} ${userAvart.given_name}`;
  //   return userAvart.avatar || uiAvatars(name);
  // }

  // getAvatarTextManager(message) {
  //   const nameSenderable = message.senderable.name;
  //   if (!nameSenderable) {
  //     return imageDefault;
  //   }

  //   return uiAvatars(nameSenderable);
  // }

  // getAvatarText() {
  //   const userAvart = this.userSender;

  //   if (!userAvart.name) {
  //     return imageDefault;
  //   }

  //   return uiAvatars(userAvart.name);
  // }

}
