



































































import ApiHelper from 'api-helper';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import moment from 'moment';
import lodash from 'lodash';

@Component({
  components: {
    PageLoader,
  },
  filters: {
    formatNumPrice: (value) => {
      if (Number(value) === 0) { return '0万円'; }
      if (!value) { return ''; }
      value = Number(value) + Number(value) / 10;
      value = Math.ceil(value).toString();
      value = value.substr(0, value.length - 1) + '0';
      value = Number(value) / 10000;
      value = Math.ceil(Number((value * 100).toFixed(2))) / 100;
      return parseFloat(value) + '万円';
    },
  },
})
export default class PopupTreatmentDetail extends Vue {
  @Prop() readonly treatment!: any;
  @Prop() readonly ids!: string;
  @Prop() readonly expiredDate!: string;
  @Prop() readonly doctorInput!: string;
  @Prop() readonly channelId!: number;
  isLoading: boolean = false;
  fullPaymentMin: any = {};
  // fullPaymentMax: any = {};
  monthlyPaymentMin: any = {};
  // monthlyPaymentMax: any = {};
  expiredDateFormat: string = '';
  thumbnail_url: string = '';
  doctorInputTime: string = '';

  get getTime() {
    if (!this.doctorInputTime) {
     return ' ~ ';
    }
    if (this.monthlyPaymentMin.is_high_value === 1 || this.fullPaymentMin.is_high_value === 1) {
      return `${this.doctorInputTime} ~ ${(Math.round(Number(this.doctorInputTime) * 1.5))}ヶ月`;
    }
    return `${this.doctorInputTime}ヶ月 ~`;
  }

  get getSaveMoney() {
    const {amount_first_month, amount, time} = this.monthlyPaymentMin;
    const savePrice = (Number(amount_first_month) + Number(amount) * (Number(this.doctorInputTime) - 1))
      - this.fullPaymentMin.amount;
    return savePrice;
  }

  getPriceMonthlyPayment(value, isMax) {
    if (!value.time) { return ''; }
    const {amount_first_month, amount, time, is_high_value} = value;

    if (amount === 0 || amount_first_month === 0) { return '0万円'; }

    // const timeHigh = (isMax && is_high_value === 1)
    //   ? Math.round(Number(this.doctorInputTime) * 1.5)
    //   : Number(this.doctorInputTime);
    // if (isMax && is_high_value === 2) {
    //   return '';
    // }

    const totalPrice = (Number(amount_first_month) + Number(amount) * Number(time - 1));
    let totalPriceHasFee: any = totalPrice + totalPrice / 10;
    totalPriceHasFee = Math.ceil(totalPriceHasFee).toString();
    totalPriceHasFee = totalPriceHasFee.substr(0, totalPriceHasFee.length - 1) + '0';
    totalPriceHasFee = Number(totalPriceHasFee) / 10000;
    totalPriceHasFee = Math.ceil(Number((totalPriceHasFee * 100).toFixed(2))) / 100;
    return parseFloat(totalPriceHasFee) + '万円';
  }


  private getDetail(idCourse) {
    this.$modal.show('modal-treatment-course', { idDetailCourse: idCourse });
  }

  private mounted() {
    this.$nextTick(() => {
      const { course_info_doctor } = this.treatment;
      this.fullPaymentMin = {};
      // this.fullPaymentMax = {};
      this.monthlyPaymentMin = {};
      // this.monthlyPaymentMax = {};
      this.expiredDateFormat = '';
      this.doctorInputTime = '';
      if (course_info_doctor) {
        this.doctorInputTime = this.doctorInput;
        this.expiredDateFormat = moment(this.expiredDate).format('YYYY年MM月DD日');
        this.fullPaymentMin = course_info_doctor.one_payment && course_info_doctor.one_payment[0] || {};
        // this.fullPaymentMax = course_info_doctor.one_payment && course_info_doctor.one_payment[1] || {};
        this.monthlyPaymentMin = course_info_doctor.many_payment && course_info_doctor.many_payment[0] || {};
        // this.monthlyPaymentMax = course_info_doctor.many_payment && course_info_doctor.many_payment[1] || {};
      }
    });
  }
}
