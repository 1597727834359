











import { Vue, Component, Watch } from 'vue-property-decorator';
import Sidebar from '@components/layouts/Sidebar.vue';
import Navbar from '@components/layouts/Navbar.vue';
import UserSessionNotFound from '@components/layouts/UserSessionNotFound.vue';
import PopupZoomImage from '@components/common/PopupZoomImage.vue';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import { mapState } from 'vuex';
import { config, Roles } from '@common/app.config';
import AuthenticationUtils from '@common/AuthenticationUtils';
import { MessageNotifyModule } from '@/store';
import LocalStorageUtils from '../common/LocalStorageUtils';
import { AuthenticationModule } from '@/store';

@Component({
  components: {
    Sidebar,
    Navbar,
    UserSessionNotFound,
    PopupZoomImage,
  },
})

export default class Layout extends Vue {
  @State((state) => state.messageNotify.notifyIds) notifyIds!: any;
  @State((state) => state.auth.currentUser) currentUser!: any;

  async getUserInfo() {
    const { commit } = this.$store;
    commit('auth/updateIsGettingUserInfo', false);
    if (this.$route.meta && this.$route.meta.auth === false) {
      return ;
    }
    try {
      const response = await ApiHelper.getApi('UserApi').getInfo();
      const { currentUser } = response;

      AuthenticationUtils.saveCurrentUser(currentUser);
      commit('auth/updateCurrentUser', currentUser);
      commit('auth/updateUnreadMessageCount', currentUser.unread_count);
      commit('auth/updateIsGettingUserInfo', true);
    } catch (error) {
      commit('auth/updateIsGettingUserInfo', false);
      Toast.error(error.response.data.message);
    }
  }

  private get userStyle() {
    if (!this.currentUser) { return ''; }
    if (this.currentUser.role === Roles.pharmacy || this.currentUser.role === Roles.pharmacist) {
      return {
        '--primary-color': '#41CFD9',
        '--danger-color': '#FF888F',
        '--primary-color-5': '#EBFAFB',
        '--content-bg': '#F0F7F7',
        '--border-color': 'rgba(67,92,112,0.1)',
      };
    }
  }

  get isSuperAdminRole() {
    return this.currentUser?.isSuperAdminRole;
  }

  async created() {
    await this.getUserInfo();
    this.$on('NewMessage', (data) => {
      const currentRouter = this.$router.currentRoute;

      if (!this.currentUser) { return; }
      if (
        data.sender_id === this.currentUser.id
        || ![Roles.superAdmin, Roles.pharmacy, Roles.doctor].includes(this.currentUser.role)
        || currentRouter.name === 'MessageBox'
        || currentRouter.name === 'HomePage'
        || this.notifyIds.includes(data.channel_id)
      ) {
        return;
      }

      MessageNotifyModule.updateNotifyId(data.channel_id);
      Toast.link('新規メッセージが届きました。', {
        action: [{
          class: 'action-link',
          text: 'LINK',
          href: `/messages/${data.channel_id}`,
          onClick: async (e, toastObject) => {
            const URL = e.target.href;
            toastObject.goAway(0);
            MessageNotifyModule.removeId(data.channel_id);

            try {
              const res = await ApiHelper.getApi('MessageApi').readAllMessages(data.channel_id);
              window.open(URL, '_blank');
            } catch (error) {
              Toast.error(error.response.data.message);
            }
          },
        },
        {
          class: 'action-link ml-3',
          icon: 'fa-times',
          target: data.channel_id,
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
            MessageNotifyModule.removeId(data.channel_id);
          },
         }],
        duration: null,
      });
    });
  }

  async mounted() {
    const UA = (window as any).UA;
    if (UA) {
      UA.then(async (sdk) => {
        await sdk.register();
        await sdk.channel.namedUser.set(`managers_${this.currentUser.id}`);
        await sdk.register();
      });
    }
    window.addEventListener('storage', this.storageChange);
  }

  private removeAuthenticate() {
    AuthenticationUtils.removeAuthenticationData();
    AuthenticationModule.updateIsAuthenticated(false);
    AuthenticationModule.updateAccessToken('');
    AuthenticationModule.updateCurrentUser({});

    this.$router.push('/login');
  }

  private storageChange(event) {
    if (event.key === 'dashboardID') {
      const dashboardID = LocalStorageUtils.getItem('dashboardID');
      if (dashboardID === null) {
        this.removeAuthenticate();
      }
    }
  }
}
