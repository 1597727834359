



























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro, faDownload } from '@fortawesome/free-solid-svg-icons';
import imageDefault from '@/assets/img/icon-no-image.svg';
import Toast from '@common/helpers/toast';

@Component
export default class ZoomImage extends Vue {
  @Prop({ required: true}) src!: string;
  @Prop({ default: true }) isZoom!: boolean;
  @Prop({ default: false }) clickToZoom!: boolean;
  @Prop({ default: false }) isDownload!: boolean;
  @Prop({ default: true }) isBorder!: boolean;
  @Prop({ default: 'image-zoom' }) name!: string;
  @Prop(String) thumbnail!: string;

  private isLoadModal: boolean = false;

  private get isShowZoom() {
    return !!this.src || !!this.thumbnail;
  }

  private get thumbnailSrc() {
    if (!this.thumbnail) { return this.src; }
    return this.thumbnail;
  }

  private isLoading: boolean = false;

  private onClickImage() {
    if (this.clickToZoom) {
      this.showImage();
    }
  }

  private dowloadImage() {
    this.$emit('dowload', this.src);
  }

  private created() {
    library.add(faDownload);
    dom.watch();
    this.isLoading = true;
  }

  private showImage() {
    this.$modal.show(this.name);
  }
}
