

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CurrencyNumber } from '@/common/directives/currency_number';
import { changeCurrencyNumber } from '@/common/helpers/custom-number';

@Component({
  directives: {
    CurrencyNumber,
  },
})
export default class CurrencyInput extends Vue {
  @Prop({ default: 'currencyInput' }) readonly id!: string;
  @Prop(String) readonly placeholder!: string;
  @Prop(Number) readonly valueFee!: string | number;
  @Prop(String) readonly styleClass!: string;
  @Prop(String) readonly errorMessage!: string;
  @Prop({ default: false }) readonly isValidate!: boolean;
  @Prop({ default: false }) readonly isHaveZero!: boolean;
  @Prop({ default: 11 }) readonly maxLength!: string | number;

  fee = '';

  @Watch('isHaveZero')
  isHaveZeroChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.fee = '0';
    }
  }

  @Watch('valueFee')
  onValueChange(newValue: string, oldValue: string) {
    if (newValue) {
      this.fee = changeCurrencyNumber(newValue, this.maxLength);
    }
  }

  onInputFee() {
    this.fee = changeCurrencyNumber(this.fee, this.maxLength);
    const valueEmit = String(this.fee).replace(/\,/g, '');

    this.$emit('changeValue', valueEmit);
  }

  onPressEnter() {
    this.$emit('onPressEnter');
  }
}
