

























































































import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ApiHelpers from '../../api/ApiHelper';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import ZoomImage from '@/components/common/ZoomImage.vue';
import ProfileEditModal from './ProfileEditModal/index.vue';
import UpdatePassword from './UpdatePassword.vue';
import moment from 'moment';

@Component({
  components: {
    PageLoader,
    ZoomImage,
    ProfileEditModal,
    UpdatePassword,
  },
})
export default class PharmacistProfile extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  private isLoading: boolean = false;
  private pharmacist: any = {};

  private get pharmacies() {
    return (this.pharmacist.stores || []).map((x) => x.name).join(', ');
  }

  private openUpdatePassword() {
    this.$modal.show('update-password');
  }

  private editProfile() {
    this.$modal.show('profile-edit', { pharmacist: this.pharmacist });
  }

  private async getPharmacistDetail() {
    try {
      this.isLoading = true;
      const { id } = this.currentUser;
      const data = await ApiHelpers.getApi('UserApi').getInfo();
      this.pharmacist = data.currentUser;
    } catch (errors) {
      Toast.error(errors.response.data.message);
    } finally {
      this.isLoading = false;
    }
  }

  private created() {
    this.getPharmacistDetail();
  }

  private reloadData() {
    this.getPharmacistDetail();
  }
}
