






























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PopupComfirm extends Vue {
  @Prop({ default: true }) readonly clickToClose!: boolean;
  @Prop(String) readonly name!: string;
  @Prop({ default: 180 }) readonly height!: number;
  @Prop({ default: 400 }) readonly width!: number;
  @Prop({ default: 'キャンセル' }) readonly textClosePopup!: string;
  @Prop({ default: 'はい' }) readonly textActionPopup!: string;
  @Prop(String) readonly message!: string;


  private onOK: any = '';

  private closePopUp() {
    this.$modal.hide(this.name);
  }

  private beforeOpen(event) {
    if (typeof event.params.onOK === 'function') {
      this.onOK = event.params.onOK;
    }
  }
}
