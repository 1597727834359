import BaseApi from '../BaseApi';

class ChannelAPI extends BaseApi {

  public show(userId, params?) {
    return this.get(`/${userId}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/channels';
  }
  // public readAllMessages(channelId) {
  //   return this.get(`/${channelId}/reset-unread`);
  // }

  // public sendMessage(channelId, params) {
  //   const data = new FormData();
  //   Object.keys(params).forEach((key) => {
  //     data.append(key, params[key]);
  //   });

  //   return this.post(`/${channelId}`, data);
  // }

  // public sendSuggestPlan(channelId, data) {
  //   return this.post(`/${channelId}/suggest-plan`, data);
  // }

  // public confirmSuggestPlan(channelId, data) {
  //   return this.post(`/${channelId}/confirm-suggest-plan`, data);
  // }

}

export default ChannelAPI;
