






















































import {Vue, Component} from 'vue-property-decorator';
import {mapState} from 'vuex';
import ApiHelper from 'api-helper';
import lodash from 'lodash';
import moment from 'moment';

import {Order} from '@/models/order';
import {PaymentMethodIdDisplay} from '@common/enums/order';
import iconOrder from '@/assets/img/icon-order.svg';
import ContentLayout from '@components/layouts/Content.vue';
import OrderTrackingTable from '@components/order/OrderTrackingTable.vue';
import ModalCancel from '@components/order/ModalCancel.vue';
import ModalHistoryPayment from '@components/order/ModalHistoryPayment.vue';
import {PLAN_PAYMENT_TYPE} from '@common/constants/plan';
import {Tracking} from '@/models/tracking';

@Component({
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      iconHeader: iconOrder,
      lodash,
      moment,
      PaymentMethodIdDisplay,
      PLAN_PAYMENT_TYPE,
    };
  },
  components: {
    ContentLayout,
    OrderTrackingTable,
    ModalCancel,
    ModalHistoryPayment,
  },
})
export default class OrderDetail extends Vue {
  private order: Order | object = {};
  private firstOrderTracking: Tracking | object = {};
  private isLoadingOrder: boolean = false;

  mounted() {
    this.fetchOrder();
  }

  private fetchOrder() {
    this.isLoadingOrder = true;
    const opts = {with: 'plan,user,tracking,tracking.ordersTrackingHistoryRefunded'};
    const orderId = this.$route.params.id;

    ApiHelper.getApi('OrderApi')
        .getOrderDetail(orderId, opts)
        .then((res: any) => {
          this.order = new Order().deserialize(res);
          this.firstOrderTracking = lodash.find(res.tracking, function(o) {
            return o.delivery_sequence == 1;
          }) || {};
          this.isLoadingOrder = false;
        });
  }

  private redirectUserInfo() {
    const userId = lodash.get(this.order, 'user_id');

    this.$router.push(`/v2/patients/${userId}`);
  }

  private getUserName() {
    return `${lodash.get(this.order, 'user.family_name', '')} ${lodash.get(this.order, 'user.given_name', '')}`.trim() || '-';
  }
}
