import ListDoctorAdmin from '@/views/doctor-admins/ListDoctorAdmin.vue';
import CreateEditDoctorAdmin from '@/views/doctor-admins/CreateEditDoctorAdmin.vue';
import { authAdmin } from '@/common/constants/auth';

export default [
  {
    path: '/doctor-admins',
    name: 'doctorAdmins',
    component: ListDoctorAdmin,
    meta: authAdmin,
  },
  {
    path: '/doctor-admins/create',
    name: 'doctorAdmins.create',
    component: CreateEditDoctorAdmin,
    meta: authAdmin,
  },
  {
    path: '/doctor-admins/edit/:id',
    name: 'doctorAdmins.edit',
    component: CreateEditDoctorAdmin,
    meta: authAdmin,
  },
];
