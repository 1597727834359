






















// libs
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

// interfaces
import { Patient } from '@/models/patient';
import { User } from '@/models/user';

// components
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import {PatientModule} from '@/store';
import Toast from '@common/helpers/toast';


@Component({
  components: {
    PopupConfirm,
  },
})
export default class PatientItem extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;
  @Prop(Function) readonly redirectPageWhenDeleteUser!: () => void;

  private messageConfirmDel: string = '';

  private get canDeleteUser() {
    return this.currentUser.isAdminRole ||
      this.currentUser.isDoctorRole ||
      this.currentUser.isCSRole;
  }

  private showConfirmDeleteUser() {
    const userName =
      `${this.patient.family_name} ${this.patient.given_name}`;
    this.messageConfirmDel = `<p>本当に削除しますか？</p> <p class="mb-5">${userName}</p>`;
    this.$modal.show('removeUser');
  }

  private hiddenPopupRemoveUser() {
    this.$modal.hide('removeUser');
  }

  private async removerUser() {
    const patientId = this.patient.id || 0;
    try {
      await PatientModule.removePatient(patientId);
      // await this.$router.push('/v2/patients');
      this.redirectPageWhenDeleteUser();
      Toast.success('ユーザを削除しました。');
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error('更新に失敗しました');
      }
    } finally {
      this.$modal.hide('removeUser');
    }
  }
}
