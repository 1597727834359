







































































































































































































import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import { IMAGES_MIME, MAX_FILE_SIZE, Roles } from '@common/app.config';
import { CHAT_STATUS, MESSAGE_TYPE } from '@common/enums/message';
import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { MessageModule } from '@/store';
import { mapState, mapGetters } from 'vuex';
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import PopupListPlan from './PopupListPlan/index.vue';
import PopupAddTreatment from './PopupAddTreatment.vue';
import PopupTreatmentDetail from './PopupTreatmentDetail.vue';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import PopupResvDoctor from '@components/common/PopupResvDoctor.vue';
import PageLoader from '@components/common/PageLoader.vue';
import { OrderStatusType } from '@/common/enums/order';
import { get } from 'lodash';

const MAX_LENGTH_MESSAGE = 2000;

@Component({
  components: {
    PopupListPlan,
    PopupAddTreatment,
    PopupTreatmentDetail,
    PopupResvDoctor,
    PageLoader,
  },
  computed: {
    ...mapGetters('channel', ['currentChannel']),
  },
})
export default class MessageInput extends Vue {

  private get isPharmacy() {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser.role === Roles.pharmacy;
  }

  private get isDoctor() {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser.role === Roles.doctor;
  }

  private get isAdmin() {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser.role === Roles.superAdmin;
  }

  get fileInput() {
    return this.$refs.fileInput as any;
  }

  get planName() {
    if (
        this.currentChannel
        && this.currentChannel.suggest_plan
        && this.currentChannel.suggest_plan.length
    ) {
      return this.currentChannel.suggest_plan[0].name.trim();
    }
    return null;
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop() readonly userId!: number | string;
  @Prop() isUpdate: boolean;

  accept = IMAGES_MIME.join();
  message: string = '';
  maxLength = MAX_LENGTH_MESSAGE;
  currentChannel!: any;
  isShiftEnter = true;
  isSending: boolean = false;
  isCalling: boolean = false;
  isToggleListPlan: boolean = false;
  isLoading: boolean = false;
  inputHeight: string = '62.67px';
  doctorSuggest: boolean = false;
  userAccept: boolean = false;
  doctorConfirm: boolean = false;
  lifecycleCourse: boolean = true;
  isEnableChat = false;
  isDisableChat = false;
  isHasTreatmentCourse = false;

  mounted() {
    this.$nextTick(() => {
      this.doctorSuggest = this.currentChannel.status === CHAT_STATUS.ACTIVE;
      this.userAccept = this.currentChannel.status === CHAT_STATUS.PREPARE && !this.currentChannel.is_confirm;
      this.doctorConfirm = this.currentChannel.status === CHAT_STATUS.PREPARE && this.currentChannel.is_confirm;
      this.isEnableChat = (
        this.currentChannel.expired_at === null &&
        this.currentChannel.users &&
        get(this.currentChannel, 'users[0].order_first[0].id') !== null &&
        (
          get(this.currentChannel, 'users[0].order_first[0].status') === OrderStatusType.Confirm ||
          get(this.currentChannel, 'users[0].order_first[0].status') === OrderStatusType.Success
        )
      );
      this.isDisableChat = (
        this.currentChannel.expired_at !== null &&
        this.currentChannel.users &&
        get(this.currentChannel, 'users[0].order_first[0].id') !== null &&
        (
          get(this.currentChannel, 'users[0].order_first[0].status') === OrderStatusType.Confirm ||
          get(this.currentChannel, 'users[0].order_first[0].status') === OrderStatusType.Success
        )
      );
      this.isHasTreatmentCourse = !!get(this.currentChannel, 'users[0].order_first[0].id');
    });
  }

  isInActiveChat(): boolean {
    return this.currentChannel && this.currentChannel.status === CHAT_STATUS.INACTIVE;
  }

  isPaymentCourse(): boolean {
    return this.currentChannel.latest_message && this.currentChannel.latest_message.is_payment_success;
  }

  isActiveChat(): boolean {
    return this.currentChannel && this.currentChannel.status === CHAT_STATUS.ACTIVE;
  }

  isPrepareFinishChat(): boolean {
    return this.currentChannel && this.currentChannel.status === CHAT_STATUS.PREPARE;
  }

  isDoctorConfirmCourse(): boolean {
    return this.currentChannel.is_confirm;
  }

  channelId() {
    return this.currentChannel.id;
  }

  @Watch('isUpdate')
  onChangeUpdate() {
    Toast.success('コースの確定を取り下げました。');
    this.doctorSuggest = true;
    this.userAccept = false;
    this.doctorConfirm = false;
    this.lifecycleCourse = false;
  }

  @Watch('currentChannel')
  onChangeChannelStatus() {
    this.$nextTick(() => {
      if (this.currentChannel && this.currentChannel.status === CHAT_STATUS.INACTIVE) {
        this.doctorSuggest = true;
        this.userAccept = false;
        this.doctorConfirm = false;
        this.lifecycleCourse = true;
        return;
      }

      if (this.currentChannel && this.currentChannel.status === CHAT_STATUS.ACTIVE) {
        this.doctorSuggest = true;
        this.userAccept = false;
        this.doctorConfirm = false;
        this.lifecycleCourse = true;
        return;
      }

      if (this.currentChannel && this.currentChannel.status === CHAT_STATUS.PREPARE) {
        if (this.currentChannel.suggest_plan.length > 0) {
          this.doctorSuggest = true;
        } else {
          this.doctorSuggest = false;
        }
        if (this.currentChannel.is_confirm) {
          this.doctorConfirm = true;
          this.doctorSuggest = false;
        } else {
          this.doctorConfirm = false;
        }

        if (this.currentChannel.suggest_plan_accepted) {
          this.userAccept = true;
          this.doctorSuggest = false;
        } else {
          this.userAccept = false;
          this.doctorSuggest = true;
        }

        this.lifecycleCourse = true;
        return;
      }
    });
  }

  @Watch('isSending')
  onIsSendingChange(val: boolean, oldVal: boolean) {
    if (!val && oldVal) {
      this.message = '';
      this.fileInput.value = '';
    }
  }

  @Watch('message')
  onChangeMessage(val: boolean, oldVal: boolean) {
    this.$nextTick(() => {
      const textareaContainer = document.getElementById('inputMessage');
      if (textareaContainer) {
        textareaContainer.style.height = ''; /* Reset the height*/
        textareaContainer.style.height = Math.min(textareaContainer.scrollHeight + 3, 132) + 'px';
      }
    });
  }

  sendMessage(event) {
    if (event.shiftKey) {
      if (!this.isShiftEnter) {
        return;
      }
      this.sendTextMessage(event, null);
      return;
    }

    if (this.isShiftEnter) {
      return;
    }

    this.sendTextMessage(event, null);
  }

  sendTextMessage(event, messageContent) {
    if (messageContent) {
      const paramsContent = {
        channelId: this.currentChannel.id,
        message: messageContent,
        image: null,
        type: MESSAGE_TYPE.TEXT,
      };
      return this.sendMessageToUser(paramsContent);
    }
    event.preventDefault();
    if (this.isSending || !this.message || !this.message.trim()) {
      return;
    }

    const params = {
      channelId: this.currentChannel.id,
      message: this.message,
      image: null,
      type: MESSAGE_TYPE.TEXT,
    };
    this.sendMessageToUser(params);
  }

  sendSuggestPlan(plan_id) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    ApiHelper.getApi('MessageApi')
      .sendSuggestPlan(this.currentChannel.id, { plan_id })
      .then(() => {
        this.setTagAura([863285, 837092], [927453]);
        this.lifecycleCourse = true;
        Toast.success('処方プラン提案を送信しました');
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async getChannelInfo(channelId) {
    if (!channelId) {
      return;
    }
    try {
      const data = await ApiHelper.getApi('ChannelApi').getOne(channelId, {
        with: 'users,latest_message',
      });
      return data;
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  confirmSuggestPlan(suggest_plan_id) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    ApiHelper.getApi('MessageApi')
      .confirmSuggestPlan(this.currentChannel.id, { suggest_plan_id })
      .then(async () => {
        this.setTagAura([863276, 837092], null);
        Toast.success('処方プラン提案を送信しました');
        this.doctorSuggest = false;
        this.userAccept = false;
        this.doctorConfirm = true;
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async getDataPatientChannelInfo() {
    const channelId = parseInt(this.$route.params.channelId, 10);
    let patientInfo: any = this.$route.params.patient;
    let dataChannel: any = this.$route.params.channel;
    if (channelId) {
      if (!patientInfo || !dataChannel) {
        const data = await this.getChannelInfo(channelId);
        patientInfo = data.users[0];
        dataChannel = data;
      }
    }
    if (channelId && patientInfo && dataChannel) {
      const { commit, dispatch } = this.$store;
      const channel = {
        ...dataChannel,
        users: [{ ...patientInfo }],
      };
      dispatch('channel/setActiveChannel', channel);
    }
  }

  onPaste(event) {
    if (event.clipboardData) {
      const content = event.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, content);
    }

    const windowApp = window as any;
    if (windowApp.clipboardData) {
      const content = windowApp.clipboardData.getData('Text');
      if (windowApp.getSelection) {
        windowApp.getSelection().getRangeAt(0).insertNode(document.createTextNode(content));
      }
    }

    if (this.message.length > MAX_LENGTH_MESSAGE) {
      this.message = this.message.substr(0, MAX_LENGTH_MESSAGE);
    }
  }

  checkMessageLength(event) {
    if (this.message.length >= MAX_LENGTH_MESSAGE) {
      if (!(event.which < 0x20)) {
        event.preventDefault();
        return;
      }
    }
  }

  sendAttachment($event) {
    const files = ($event.target as HTMLInputElement).files as any;
    if (this.isSending || !files.length) {
      return;
    }
    const file = files.item(0);
    if (file.size > MAX_FILE_SIZE) {
      Toast.error('サイズが5MBを超える画像はアップロードできません');
      return;
    }

    if (!IMAGES_MIME.includes(file.type)) {
      Toast.error('jpg又はpng形式でアップロードしてください');
      return;
    }
    const params = {
      channelId: this.currentChannel.id,
      message: null,
      image: file,
      type: MESSAGE_TYPE.IMAGE,
    };
    this.sendMessageToUser(params);
  }

  async sendMessageToUser({ channelId, message, image, type }) {
    try {
      this.message = '';
      this.isSending = true;
      this.$emit('isSending', true);
      const response = await ApiHelper.getApi('MessageApi').sendMessage(channelId, {
        message,
        image,
        type,
      });
      const { channel } = response;
      this.isSending = false;
      (window as any).app.$broadcast('UpdateNewChannel', channel);
      delete response.channel;
      this.$emit('newMessage', response);
    } catch (error) {
      Toast.error(error.response.data.message);
    } finally {
      this.isSending = false;
      this.$emit('isSending', false);
    }
  }

  updateIsShiftEnter() {
    LocalStorageUtils.saveItem('isShiftEnter', this.isShiftEnter);
  }

  openPopupPlan() {
    this.isToggleListPlan = true;
    this.$modal.show('popup-list-plan');
  }

  openPopupPlanTreatment() {
    this.$modal.show('popup-add-treatment');
  }

  confirmPlan() {
    if (this.currentChannel) {
      const suggest_plan_id = this.currentChannel.suggest_plan_accepted;
      this.confirmSuggestPlan(suggest_plan_id);
    }
  }

  resetSelectCourse() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    ApiHelper.getApi('CourseApi')
      .resetSelectCourse(this.currentChannel.id)
      .then((response: any) => {
        if (response) {
          Toast.success('コースの確定を取り下げました。');
          this.doctorSuggest = true;
          this.userAccept = false;
          this.doctorConfirm = false;
          this.lifecycleCourse = false;
        }
      })
      .catch((err: any) => {
        Toast.error(err.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  setTagAura(tagIds, tagsDelete) {
    const params = {
      tags: tagIds,
      is_add_and_delete: tagsDelete ? 1 : 0,
      line_id: (this.currentChannel as any).users[0].line_id,
      delete_after_add: tagsDelete || [],
    };
    ApiHelper.getApi('AuraApi').addAuraTag(params);
  }

  removeTagAura(tagsDelete) {
    const params = {
      tags: tagsDelete,
      is_add_and_delete: 0,
      line_id: (this.currentChannel as any).users[0].line_id,
    };
    ApiHelper.getApi('AuraApi').removeAuraTag(params);
  }

  addTreatment(body) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    ApiHelper.getApi('CourseApi')
      .suggestTreatmentCourses(body)
      .then((response: any) => {
        if (response) {
          if (body.tooth_extraction === 2) {
            this.setTagAura([923622], null);
          }
          this.setTagAura([863285, 837092], [927453]);
          Toast.success('処方プラン提案を送信しました');
          this.closeTreatmentPopup();
          this.lifecycleCourse = true;
        } else {
          Toast.error('入力した治療期間と該当なコードがありません');
        }
      })
      .catch((err: any) => {
        Toast.error(err.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  closeTreatmentPopup() {
    this.$modal.hide('popup-add-treatment');
  }

  addPlan(planId) {
    this.closePlanPopup();
    this.sendSuggestPlan(planId);
  }

  closePlanPopup() {
    this.isToggleListPlan = false;
    this.$modal.hide('popup-list-plan');
  }

  created() {
    const isShiftEnter = LocalStorageUtils.getItem('isShiftEnter');
    this.isShiftEnter = isShiftEnter !== null ? isShiftEnter : true;
    library.add(faPaperclip);
    library.add(faPaperPlane);
    dom.watch();
  }

  toCalling() {
    if (this.isCalling) {
      return;
    }

    // this.isCalling = true;
    const lineId = this.currentChannel.users[0].line_id;
    const routeData = this.$router.resolve({
      name: 'calling',
      params: {
        channeltoken: this.currentChannel.channel_token,
        chatId: this.$route.params.channelId,
        userName: this.currentChannel.users[0].name,
      },
      query: {
        lineId,
        channelId: this.currentChannel.channel_uuid,
        userName: this.currentChannel.users[0].first_name + ' ' + this.currentChannel.users[0].last_name,
      },
    });
    // const routeData = this.$router.resolve({
    //   name: 'calling',
    //   query: {
    //     lineId,
    //     channeltoken: res.channel_token,
    //     channelUuid: res.channel_uuid,
    //   },
    // });
    window.open(routeData.href, '_blank');
    // ApiHelper.getApi('ChannelApi')
    //   .getResvCalling(this.currentChannel.id)
    //   .then((res: any) => {
    //     console.log('res', res)
    //     console.log('this.currentChannel.id', this.currentChannel.id)
    //     const routeData = this.$router.resolve({
    //       name: 'calling',
    //       query: {
    //         lineId,
    //         channeltoken: res.channel_token,
    //         channelUuid: res.channel_uuid,
    //       },
    //     });
    //     console.log('routeData.href', routeData.href);
    //     console.log('res.channel_token', res.channel_token);
    //     console.log('res.channel_uuid', res.channel_uuid);

    //     window.open(routeData.href, '_blank');
    //   })
    //   .catch((error: any) => {
    //     console.log('error', error);
    //     this.isCalling = false;
    //     Toast.error(error.response.data.message);
    //   })
    //   .finally(() => {
    //     console.log('finally');
    //     this.isCalling = false;
    //   });
  }

  @Watch('currentChannel.users')
  private setEnable() {
    const valueOrderFirstId = get(this.currentChannel, 'users[0].order_first[0].id');
    if (this.currentChannel.users) {
      this.isHasTreatmentCourse = !!valueOrderFirstId;
      const channelOrderStatus = get(this.currentChannel, 'users[0].order_first[0].status');
      this.isEnableChat = (
        this.currentChannel.expired_at === null &&
        this.currentChannel.users &&
        valueOrderFirstId !== null &&
        (
          channelOrderStatus === OrderStatusType.Confirm ||
          channelOrderStatus === OrderStatusType.Success
        )
      );
      this.isDisableChat = (
        this.currentChannel.expired_at !== null &&
        this.currentChannel.users &&
        valueOrderFirstId !== null &&
        (
          channelOrderStatus === OrderStatusType.Confirm ||
          channelOrderStatus === OrderStatusType.Success
        )
      );
      this.isHasTreatmentCourse = !!get(this.currentChannel, 'users[0].order_first[0].id');
    }
  }

  private addResvDoctor() {
    const dataResvDoctor = {
      userId: this.userId,
    };
    this.$modal.show('popup-add-resv-doctor', { dataResvDoctor });
  }

  private addResvDoctorSuccess(resvData, time) {
    this.setTagAura([863291], [923619]);
    this.sendTextMessage(null,
      'オンライン診療の日時をご案内いたします。\n' + '\n' +
      `<b>` + time + `</b>` + 'から予約をいたしました。\n' +
      '当日はどうぞよろしくお願いいたします。',
    );
  }
}
