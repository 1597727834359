

































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { NOTIFICATION_STATUS_OPTIONS } from '@/common/app.config';
import BasicSelect from '@components/common/BasicSelect.vue';
import { autoDeletePropertyEmpty } from '@common/utils';
import DatePicker from 'vue2-datepicker';
import moment, { months } from 'moment';

@Component({
  components: {
    BasicSelect,
    DatePicker,
  },
})
export default class NotificationSearch extends Vue {
  private today = moment();
  private lang = { yearFormat: 'YYYY年' };
  private paramsSearch: any = {
    title: '',
    id: '',
    status: '',
    schedule_time_gte: '',
    schedule_time_lte: '',
  };

  private statusLists = [
    {name: '全て', value: ''},
    ...NOTIFICATION_STATUS_OPTIONS,
  ];

  private get isTimeError() {
    if (this.paramsSearch.schedule_time_gte && this.paramsSearch.schedule_time_lte) {
      return new Date(this.paramsSearch.schedule_time_gte) > new Date(this.paramsSearch.schedule_time_lte);
    }
  }

  private created() {
    this.paramsSearch.schedule_time_gte = this.today.clone().startOf('date').format('YYYY-MM-DD HH:mm');
    const month = this.today.month();
    const year = this.today.year();
    const lastDay = new Date(year, month + 1, 0);
    lastDay.setHours(23);
    lastDay.setMinutes(59);
    this.paramsSearch.schedule_time_lte = moment(lastDay).format('YYYY-MM-DD HH:mm');
    this.$emit('search', autoDeletePropertyEmpty(this.paramsSearch));
  }

  private onSearch() {
    this.$emit('search', autoDeletePropertyEmpty(this.paramsSearch));
  }
}
