








import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class PatientAction extends Vue {}
