// import Show from '@/views/pharmacist_reservation/Show.vue';
import Index from '@/views/pharmacist_reservation/Index.vue';
// import InfoClinic from '@/views/info_clinic/Index.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.doctor, Roles.admin, Roles.superAdmin, Roles.pharmacist],
};

export default [
  {
    path: '/reservation',
    name: 'reservation',
    component: Index,
    meta: authMeta,
  },
  // {
  //   path: '/reservation/:id',
  //   name: 'reservation.detail',
  //   component: Show,
  //   meta: authMeta,
  // },
];
