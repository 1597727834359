















































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import lodash from 'lodash';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import UploadImageIndentification from '@/components/common/UploadImageIndentification.vue';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import ApiHelpers from '@/api/ApiHelper';

@Component({
  components: {
    DatePicker,
    ImagePrivate,
    UploadImageIndentification,
  },
  data() {
    return {};
  },
})
export default class UpdatePatientInfoModal extends Vue {
  private get listPrefectureOption() {
    return this.listPrefecture.map((prefecture) => {
      return {
        title: prefecture.ja_name,
        id: prefecture.id,
      };
    });
  }
  @Prop({ default: true }) readonly clickToClose!: boolean;
  @Prop({ default: false }) readonly draggable!: boolean;
  @Prop({ default: true }) readonly closeByIcon!: boolean;
  @Prop({ default: false }) readonly autoHeight!: boolean;
  @Prop(String) readonly name!: string;
  @Prop({ default: 'auto' }) readonly height!: string | number;
  @Prop({ default: 800 }) readonly width!: string | number;
  @Prop({ default: true }) readonly scrollable!: boolean;
  @Prop({ default: {} }) readonly currentUser!: any;
  @Prop({ default: [] }) readonly listPrefecture!: any[];
  @Prop({ default: '' }) readonly urlFrontPath!: any;
  @Prop({ default: '' }) readonly urlBackPath!: any;
  @Prop({ default: '' }) readonly defaultFrontIndentityImage!: any;
  @Prop({ default: '' }) readonly defaultBackIndentityImage!: any;
  @Prop({ default: null }) readonly changeCurrentChannelUser!: any;
  private formData: any = {};
  private datePickerReservationMode: string = 'date';
  private isSubmitting: boolean = false;
  private frontImage: any = '';
  private backImage: any = '';

  get displayLineNameErrorMessages() {
    return {
      max: 'LINE Nameは255文字以内で入力してください',
    };
  }

  mounted() {
    library.add(faCalendarDay);
    dom.watch();

    this.formData = lodash.cloneDeep(this.currentUser);
  }

  async show() {
    this.formData = lodash.cloneDeep(this.currentUser);
    this.frontImage = '';
    this.backImage = '';
    this.$modal.show('edit-patient');
  }

  getRef(name) {
    return this.$refs[name] as any;
  }

  async savePatientInformation(invalid) {
    if (invalid) {
      (this.$refs.editInfoForm as any).validate();
      return;
    }
    const data = {
      family_name: this.formData.family_name,
      given_name: this.formData.given_name,
      family_name_kana: this.formData.family_name_kana,
      given_name_kana: this.formData.given_name_kana,
      date_of_birth: this.formData.date_of_birth,
      line_id: this.formData.line_id,
      line_name: this.formData.line_name,
      email: this.formData.email,
      phone: this.formData.phone,
      postal_number: this.formData.postal_number,
      prefecture_id: this.formData.prefecture_id,
      address: this.formData.address,
      building_name: this.formData.building_name,
      reservation_date: null,
      with: 'latestReservation',
    };

    if (this.formData.latestReservation) {
      data.reservation_date =
        this.currentUser.latestReservation.reservation_date !=
        this.formData.latestReservation.reservation_date
          ? this.formData.latestReservation.reservation_date
          : null;
    }

    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    }

    if (this.frontImage && this.frontImage instanceof File) {
      formData.append('front_side_card', this.frontImage);
    }
    if (this.backImage && this.backImage instanceof File) {
      formData.append('back_side_card', this.backImage);
    }

    const self = this;
    this.isSubmitting = true;
    await ApiHelper.getApi('PatientApi')
      .updateInformation(this.formData.id, formData)
      .then((res: any) => {
        self.changeCurrentChannelUser(res);
        Toast.success('更新しました');
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        self.isSubmitting = false;
        self.$modal.hide('edit-patient');
      });
  }

  disabledBirthdayCalendar(date) {
    const ToDate = new Date();
    ToDate.setFullYear(ToDate.getFullYear() - 18);
    return date.getTime() > ToDate.getTime();
  }

  disabledReservationDate(date) {
    const ToDate = new Date();
    return date.getTime() < ToDate.setHours(0, 0, 0, 0);
  }

  disabledReservationTime(date) {
    const ToDate = new Date();
    return date.getTime() <= ToDate.getTime();
  }

  changeImage(event, type) {
    if (type === 'front') {
      this.frontImage = event;
    }
    if (type === 'back') {
      this.backImage = event;
    }
  }

  closePopup() {
    if (this.closeByIcon) {
      this.$modal.hide(this.name);
    } else {
      this.$emit('closeModal');
    }
  }

  searchZipcode() {
    const params = {
      postal_code: this.formData.postal_number,
    };

    ApiHelpers.getApi('ZipcodeApi')
      .getList(params)
      .then((res) => {
        this.formData.prefecture_id = res.address1;
        this.formData.city_id = res.address2;
        this.formData.address = res.address3;
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      });
  }
}
