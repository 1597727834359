


















































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ApiHelper from 'api-helper';
import datetime from 'vuejs-datetimepicker';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

import { Roles } from '@common/app.config';
import Toast from '@/common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import PopupDelete from '@/components/common/PopupCourseDelete.vue';
import UploadImage from '@/components/common/UploadImage.vue';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import PopupAddMedicine from '@/components/common/PopupAddMedicine.vue';
import PopupAddClinic from '@/components/common/PopupAddClinic.vue';
import PopupAddMenu from '@/components/common/PopupAddMenu.vue';
import {Regexs} from '@/common/constants/regex';


@Component({
  components: {
    PageLoader,
    UploadImage,
    datetime,
    PopupConfirm,
    PopupDelete,
    DatePicker,
    PopupAddMedicine,
    PopupAddClinic,
    PopupAddMenu,
  },
})
export default class PlanDetail extends Vue {
  showTimePanel = false;
  REGEX = Regexs;

  private get courseForm() {
    return this.$refs.courseForm as any;
  }

  get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  get isCreate() {
    return this.$route.name === 'plans.create';
  }

  get getRulesPrice() {
    const rules = { required: true, regex: Regexs.numberNotStartWithZero, min: 0 , numeric: true };

    if (+this.course.payment_type === 2) {
      rules['same_price'] = this.course.price_first;
    }

    return rules;
  }

  get isRequireMenu() {

    if (!this.course.is_shampoo_subscription) {
      return this.menus.length === 0;
    } else {
      return false;
    }
  }

  get isRequireMedicine() {
    return this.medicines.length <= 0;
  }

  public get numericErrorMessages() {
    return {
      regex: '最初の0を外してください。',
    };
  }

  @State((state) => state.auth.currentUser) currentUser!: any;
  course: any = {};
  isLoading: boolean = false;
  image: any = null;
  dateRange: Date[] = [];
  isSubmit: boolean = false;
  isSubmitDelete: boolean = false;
  defaultData: any = {
    name: '',
    description: '',
    payment_type: 1,
    cycle: '30',
    price_first: '',
    price_after: '',
    price_show: '0',
    price_show_with_tax: '0',
    price_base_first: '',
    price_base_after: '',
    display_order: '',
    status: 1,
    start: '',
    end: '',
    detail: '',
    image: '',
    karte_status: false,
    karte_end: '',
    karte_start: '',
    is_shampoo_subscription: false,
    is_hospital_prescription: false,
    auto_suggestion_status: false,
  };
  isChangeUpdateForm: boolean = false;
  medicines: any[] = [];
  medicalServices: any[] = [];
  clinics: any[] = [];
  menus: any[] = [];
  messageMenu: string = '';

  mounted() {
    this.getCourseDetail();
    library.add(faTrashAlt);
    dom.watch();
  }

  setMenu(data) {
    this.menus = [data];
    this.isChangeUpdateForm = true;

    // let menuIds: any[] = [];
    // this.menus.map((data) => {
    //   menuIds = [...menuIds, data.id];
    //   return menuIds;
    // });
    this.course.menu_ids = [data.id];
    this.hiddenPopup('popup-add-menu');
  }

  showMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.course.clinic_ids) {
      this.messageMenu = '';
      this.showPopup(event, 'popup-add-menu');
      return;
    } else {
      this.messageMenu = 'クリニック・サロンを選択してください。';
      return;
    }
  }

  resetDate(name: string) {
    if (name == 'auto_suggestion_status') {
      this.course.start = '';
      this.course.end = '';
      return;
    }

    if (name == 'karte_status') {
      this.course.karte_start = '';
      this.course.karte_end = '';
      return;
    }
  }

  setClinic(data) {
    this.clinics = [data];
    this.isChangeUpdateForm = true;

    let clinicIds: any[] = [];
    this.clinics.map((data) => {
      clinicIds = [...clinicIds, data.id];
      return clinicIds;
    });
    this.course.clinic_ids = clinicIds;
    this.course.menu_ids = [];
    this.course.menus = [];
    this.menus = [];
    this.hiddenPopup('popup-add-clinic');
  }

  toggleTimePanel() {
    this.showTimePanel = !this.showTimePanel;
  }
  handleOpenChange() {
    this.showTimePanel = false;
  }

  handleClickShampooSubs(event) {
    event.preventDefault();
    event.stopPropagation();

    this.course.is_shampoo_subscription = true;
    this.course.auto_suggestion_status = false;
    this.course.karte_status = false;
    this.course.is_hospital_prescription = false;
    this.course.menu_ids = [];
    this.course.menus = [];
    this.menus = [];
    this.course.karte_start = '';
    this.course.karte_end = '';
    this.course.start = '';
    this.course.end = '';
  }

  getCourseDetail() {
    if (this.isLoading) {
      return;
    }

    if (this.isCreate) {
      this.course = this.defaultData;
    } else {
      this.isLoading = true;
      ApiHelper.getApi('CourseApi')
        .getCourseDetails(this.$route.params.id)
        .then((res: any) => {
          this.course = res;
          this.image = this.course.image_url;
          this.isLoading = false;

          // medicine
          this.medicines = this.course.medicines;
          let medicine_ids: any[] = [];
          this.medicines.map((data) => {
            medicine_ids = [...medicine_ids, data.id];
            return medicine_ids;
          });
          this.course.medicine_ids = medicine_ids;

          // clinic
          this.clinics = this.course.clinics;
          let clinic_ids: any[] = [];

          this.clinics.map((data) => {
            clinic_ids = [...clinic_ids, data.id];
            return clinic_ids;
          });
          this.course.clinic_ids = clinic_ids;

          // menu
          this.menus = this.course.menus;
          let menu_ids: any[] = [];

          this.menus.map((data) => {
            menu_ids = [...menu_ids, data.id];
            return menu_ids;
          });
          this.course.menu_ids = menu_ids;
        })
        .catch((error: any) => {
          this.isLoading = false;
          if (error.response) {
            Toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  backToList() {
    window.close();
    this.$router.go(-1);
  }

  changeAvatar(event) {
    this.image = event;
  }

  deleteImage() {
    this.image = '';
    this.course.image_url = '';
  }

  async showPopupConfirmSubmitCourse() {
    this.$modal.show('comfirmSubmitCourse');
  }

  async showPopupConfirmDeleteCourse() {
    this.$modal.show('comfirmDeleteCourse');
  }

  cancelPopupConfirmDelete() {
    this.$modal.hide('comfirmDeleteCourse');
  }

  deleteCourse() {
    this.isSubmitDelete = true;
    ApiHelper.getApi('CourseApi')
        .deleteCourse(this.$route.params.id)
        .then((res: any) => {
          this.$router.push({name: 'plans'});
          Toast.success('削除しました');
        })
        .catch((error: any) => {
          this.isLoading = false;
          if (error.response) {
            Toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.isSubmitDelete = false;
        });
  }

  cancelPopupConfirmSubmit() {
    this.$modal.hide('comfirmSubmitCourse');
  }

  setMedicine(medicines) {
    this.medicines = medicines;
    this.isChangeUpdateForm = true;

    let medicineIds: any[] = [];
    this.medicines.map((data) => {
      medicineIds = [...medicineIds, data.id];
      return medicineIds;
    });
    this.course.medicine_ids = medicineIds;
    this.hiddenPopup('popup-add-medicine');

    return;
  }

  async submitCourse() {
    const isValid = await this.courseForm.validate();
    if (!isValid) {
      return;
    }

    if (!this.course['medicine_ids'] || this.course['medicine_ids'].length <= 0) {
      Toast.error('商品を選択してください');
      return;
    }

    this.isLoading = true;
    this.isSubmit = true;
    const data = new FormData();

    for (const key in this.course) {
      if (this.course[key] !== null && this.course[key] !== '') {
        if (
            (key === 'medicine_ids' || key === 'clinic_ids' || key === 'menu_ids') &&
            this.course[key].length > 0
          ) {
          this.course[key].map((keyId) => {
            data.append(`${key}[]`, keyId);
            return keyId;
          });
        } else {
          data.append(key, this.course[key]);
        }
      }
    }
    if (this.image && this.image instanceof File) {
      data.append('avatar', this.image);
    }
    if (this.course.start) {
      data.set('start', moment(this.course.start).format('YYYY-MM-DD HH:mm'));
    }
    if (this.course.end) {
      data.set('end', moment(this.course.end).format('YYYY-MM-DD HH:mm'));
    }
    if (this.course.karte_start) {
      data.set('karte_start', moment(this.course.karte_start).format('YYYY-MM-DD HH:mm'));
    }
    if (this.course.karte_end) {
      data.set('karte_end', moment(this.course.karte_end).format('YYYY-MM-DD HH:mm'));
    }
    data.delete('medicines');
    data.delete('menus');
    data.delete('clinics');
    if (!data['menu_ids']) {
      data.delete('menu_ids');
    }
    if (this.isCreate) {
      ApiHelper.getApi('CourseApi')
          .createCourse(data, this.$route.params.id)
          .then((res: any) => {
            this.course = res;
            this.isLoading = false;
            this.$router.push({name: 'plans'});
            Toast.success('登録しました');
          })
          .catch((error: any) => {
            this.isLoading = false;
            if (error.response) {
              Toast.error(error.response.data.message);
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.isSubmit = false;
          });
    } else {
      ApiHelper.getApi('CourseApi')
          .updateCourse(data, this.$route.params.id)
          .then((res: any) => {
            this.course = res;
            this.isLoading = false;
            this.$router.push({name: 'plans'});
            Toast.success('登録しました');
          })
          .catch((error: any) => {
            this.isLoading = false;
            if (error.response) {
              Toast.error(error.response.data.message);
            }
            const { data, status } = error.response;
            if (status === 422) {
              this.courseForm.setErrors(data.errors);
              return;
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.isSubmit = false;
          });
    }
  }

  private hiddenPopup(name) {
    this.$modal.hide(name);
  }

  private showPopup(event, name) {
    event.preventDefault();
    event.stopPropagation();

    this.$modal.show(name);
  }

  private disabledStartDatePicker(date) {
    return moment(date).isAfter(moment(this.course.end));
  }

  private disableStartTime(date) {
    return moment(date).format('YYYY-MM-DD HH:mm') >= moment(this.course.end, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
  }

  private disabledEndDatePicker(date) {
    return moment(date).format('YYYY-MM-DD') < moment(this.course.start, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  private disableEndTime(date) {
    return moment(date).format('YYYY-MM-DD HH:mm') <= moment(this.course.start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
  }

  private disabledStartKarteDatePicker(date) {
    return moment(date).isAfter(moment(this.course.karte_end));
  }

  private disableStartKarteTime(date) {
    return moment(date).format('YYYY-MM-DD HH:mm') >= moment(this.course.karte_end, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
  }

  private disabledEndKarteDatePicker(date) {
    return moment(date).format('YYYY-MM-DD') < moment(this.course.karte_start, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  private disableEndKarteTime(date) {
    return moment(date).format('YYYY-MM-DD HH:mm') <= moment(this.course.karte_start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
  }

}
