import Vue from 'vue';
const BroadcastHub = new Vue();

const on = Vue.prototype.$on;
const $on = function(this: any, event: string, callback: any) {
  on.call(BroadcastHub, event, callback);
  on.call(this, event, callback);
  return this;
};

const off = Vue.prototype.$off;
const $off = function(this: any, event: string, callback: any) {
  off.call(BroadcastHub, event, callback);
  off.call(this, event, callback);
  return this;
};

const emit = Vue.prototype.$emit;
const $broadcast = function(this: any, event: string, ...agrs: any[]) {
  emit.call(BroadcastHub, event, ...agrs);
  return this;
};

export default {
  install(vue: any): void {
    vue.prototype.$on = $on;
    vue.prototype.$off = $off;
    vue.prototype.$broadcast = $broadcast;
  },
  $on,
  $off,
  $broadcast,
};
