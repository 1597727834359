


































































import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import PageLoader from '@components/common/PageLoader.vue';

@Component({
  components: {
    DatePicker,
    PageLoader,
  },
})
export default class PopupClinicPatternDetail extends Vue {
  private isUpdate: boolean = false;
  private resvId: number;
  private lang = { yearFormat: 'YYYY年' };
  private isLoading: boolean = true;
  private isFirstLoading: boolean = true;
  private isMounted: boolean = false;

  private userId: string = '';
  private start_date: string = '';

  private today = moment();

  closePicker() {
    (this.$refs['datepickerDoctor'] as any).closePopup();
  }

  private notBeforeToday(date) {
    return date < this.today.clone().startOf('date');
  }

  private notBeforeTime(date) {
    const year = this.today.clone().year();
    const month = this.today.clone().month();
    const day = this.today.clone().date();
    const hour = this.today.clone().hour() - 1;
    const minute = this.today.clone().minute();
    return date < new Date(year, month, day, hour, minute);
  }

  private async createReservationClinic() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const params = {
      user_id: this.userId,
      start_date: moment(this.start_date).format('YYYY-MM-DD HH:mm'),
    };
    ApiHelper.getApi('UserApi')
      .createResvDoctor(params)
      .then((newResv) => {
        const newResvFormat = {
          ...newResv,
          start_date: moment(this.start_date).format('YYYY/MM/DD HH:mm'),
        };
        const timeInMessage = moment(this.start_date).format('YYYY年MM月DD日 HH時mm分');
        this.$emit('setNewResv', newResvFormat, timeInMessage);
        Toast.success('作成しました');
        this.closeModal();
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          return Toast.error(data.message);
        }
        Toast.error(error.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private resetData() {
    this.start_date = '';
  }

  private handleReservation() {
    if (this.isUpdate) {
      this.updateReservationClinic();
    } else {
      this.createReservationClinic();
    }
  }

  private async updateReservationClinic() {
    this.isLoading = true;
    const params = {
      start_date: moment(this.start_date).format('YYYY-MM-DD HH:mm'),
      user_id: this.userId,
    };
    try {
      const newResv = await ApiHelper.getApi('UserApi').updateResvDoctor(this.resvId, params);
      const newResvFormat = {
        ...newResv,
        start_date: moment(this.start_date).format('YYYY/MM/DD HH:mm'),
      };
      const timeInMessage = moment(this.start_date).format('YYYY年MM月DD日 HH時mm分');
      this.$emit('setNewResv', newResvFormat, timeInMessage);
      Toast.success('更新しました');
      this.closeModal();
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        return Toast.error(data.message);
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  private beforeClose() {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.resetData();
  }

  private closeModal() {
    this.$modal.hide('popup-add-resv-doctor');
    this.resetData();
  }

  private beforeOpen(event) {
    try {
      this.userId = event.params.dataResvDoctor.userId;
      this.isUpdate = !!event.params.dataResvDoctor.resvId;
      if (event.params.dataResvDoctor.resvId) {
        this.resvId = event.params.dataResvDoctor.resvId;
        this.start_date = event.params.dataResvDoctor.start_date;
      }
      this.isMounted = true;
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        return Toast.error(data.message);
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
      this.isFirstLoading = false;
    }
  }
}
