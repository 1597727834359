








import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Index from '@/views/clinic/Index.vue';
import Message from '@/views/messages/index.vue';
import { State } from 'vuex-class';
import { Roles } from '@/common/app.config';

@Component({
  components: {
    Index,
    Message,
  },
})
export default class Home extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  get isDoctor() {
    return this.currentUser.role === Roles.doctor;
  }

  get isHideSidebar() {
    if (!this.currentUser) { return true; }
    return [Roles.pharmacy, Roles.pharmacist].includes(this.currentUser.role);
  }
}
