import BaseApi from './BaseApi';

class PriorityApi extends BaseApi {
  public validatePriority(params: any) {
    return this.post('/validate-priority', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/doctors';
  }
}

export default PriorityApi;
