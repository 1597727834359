import { ModelPatientDetail, defaultPatientDetail } from './patient';
import { ModelClinic } from './clinics';
import { ModelPrescription } from './prescription';

export interface QueryResv {
    page?: number;
    per_page?: number;
    start_at_gte?: string;
    end_at_lte?: string;
    status?: string;
    menu_id?: number | string;
    freeword?: string;
    with?: string;
    user_id?: number | null;
}

export interface Response {
    data: ModelResv[];
    total: number;
    last_page: number;
    per_page: number;
    current_page: number;
}

export interface ModelFile {
    reservation_id: number;
    type: number;
    path_url: string;
    created_at: string;
}

export interface ModelResv {
    id: number | null;
    start_at: string;
    end_at: string;
    type: number | null;
    insurance: boolean;
    channel_uuid: string;
    status: string;
    channel_id: number | null;
    age_range: number | null;
    state: number | null;
    reason?: string | null;
    remarks?: string | null;
    prescriptions?: ModelPrescription[];
    clinic?: ModelClinic;
    doctor?: {
        id: number | null;
        name: string;
        email: string;
        avatar_url: string
    };
    user?: ModelPatientDetail;
    files?: ModelFile[];
    invoice?: object;
}

export const defaultDataResv: ModelResv = {
    id: null,
    start_at: '',
    end_at: '',
    type: null,
    insurance: true,
    channel_uuid: '',
    status: '',
    channel_id: null,
    age_range: null,
    state: null,
    prescriptions: [],
    doctor: {
        id: null,
        name: '',
        email: '',
        avatar_url: '',
    },
    user: JSON.parse(JSON.stringify(defaultPatientDetail)),
    files: [],
};
