






































































































































































































































import { Vue, Component, Watch, Mixins } from 'vue-property-decorator';
import { get } from 'lodash';
import { State } from 'vuex-class';
import ApiHelper from 'api-helper';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import DatePickerMixin from '@/common/mixins/DatePickerMixin';
import BasicSelect from '@/components/common/BasicSelect.vue';
import Pagination from '@/components/common/Pagination.vue';
import PageLoader from '@/components/common/PageLoader.vue';
import Toast from '@/common/helpers/toast';
import { Delivery, DeliveryInput } from '@/models/delivery';
import { DeliverySearchNew } from '@/models/delivery-search-new';
import { Paginate } from '@/models/paginate';
import { Roles } from '@/common/app.config';
import { DeliveryStatus } from '@/common/app.config';
import 'vue2-datepicker/locale/ja';

@Component({
  name: 'ManagerDelivery',
  components: {
    DatePicker,
    BasicSelect,
    Pagination,
    PageLoader,
  },
})
export default class ManagerDelivery extends Mixins(DatePickerMixin) {

  get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  get searchStatus(): any[] {
    return DeliveryStatus;
  }

  get fileName(): string {
    return this.fileInput.name || 'ファイルを選択';
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  isLoading: boolean = false;
  isPaginate: boolean = false;
  isUploadCSV: boolean = false;
  fileInput: any = {};
  lang = { yearFormat: 'YYYY年' };

  frSearch: DeliverySearchNew = new DeliverySearchNew();
  tableHead: string[] = [
    '',
    '受注番号\n (ecforceから)',
    '処方プラン\n SKUコード',
    'ユーザーID \nユーザー名',
    '発送完了日',
  ];
  deliveries: Delivery[] = [];
  paginate: Paginate = new Paginate();
  pageOptions = [10, 50, 100];
  isRangeDateValid = false;
  updatedLast = '';

  mounted() {
    this.onChangePerPage(10);
    this.scrollToTop();
    this.$on(`UploadCSVFinish`, this.onFinishUploadCSV);
    this.$on(`UploadCSVFail`, this.onFinishUploadCSVFail);
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  onPageChange(page): void {
    if (this.isPaginate) {
      return;
    }

    this.resetOrder();
    this.isPaginate = true;
    this.paginate.setPage(page);
    this.fetchOrders();
  }

  resetOrder() {
    this.deliveries = [];
    this.paginate.last_page = 0;
    this.paginate.setPage(1);
  }

  onChangePerPage(perPage: number) {
    this.resetOrder();
    this.paginate.setPerPage(perPage);
    this.fetchOrders();
  }

  isActivePerPage(perPage: number) {
    return this.paginate.per_page === perPage;
  }

  onSearch(checkValidate: any) {
    if (checkValidate) {
      return;
    }

    this.resetOrder();
    this.fetchOrders();
  }

  private onFinishUploadCSVFail(data) {
    Toast.error('CSVのフォーマットに誤りがあるため、CSVのインポートに失敗しました。', { duration: 5000, keepOnHover: true });
  }

  private onFinishUploadCSV(data) {
    if (data.errors.total) {
      const message = 'CSVファイル入力が終了しました。\n' +
        `\n新規作成：${data.total_insert}件\n` +
        `\n更新：${data.total_update}件\n` +
        `\nスキップ：${data.errors.total}件 {行 ${data.errors.row} }`;
      Toast.error(message, { duration: 10000, keepOnHover: true });
    } else {
      this.fetchOrders();
      const message = 'CSVファイル入力が終了しました。\n' +
        `\n新規作成：${data.total_insert}件\n` +
        `\n更新：${data.total_update}件\n`;
      Toast.success(message, { duration: 5000, keepOnHover: true });
    }
  }

  private onFileChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    this.fileInput = files[0];
  }

  private onUploadCSV() {
    this.isUploadCSV = !this.isUploadCSV;
  }

  private handleUploadCSV() {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', this.fileInput);
    ApiHelper.getApi('DoctorMouthApi').uploadCSV(formData)
      .then((response) => {
        // Toast.success('csvのインポート');
        this.isUploadCSV = false;
        this.fileInput = {};
      })
      .catch((error) => {
        Toast.error(error.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private fetchOrders(): void {
    if (this.isLoading) {
      return;
    }

    const opts = {
      with: 'user, course',
      ...this.frSearch.toSearchJSON(),
      ...this.paginate.toPaginateJSON(),
    };

    this.isLoading = true;
    ApiHelper.getApi('DoctorMouthApi')
      .getDeliveryEF(opts)
      .then((res: any) => {
        this.deliveries = res.data.map((item: DeliveryInput) => new Delivery().deserialize(item));
        this.paginate = new Paginate().deserialize(res);
        this.updatedLast = res.updated_last;
      })
      .catch((err: any) => {
        Toast.error(get(err, 'response.data.message'));
      })
      .finally(() => {
        this.isLoading = false;
        this.isPaginate = false;
      });
  }

  @Watch('frSearch.date_gte')
  private watchStartDate(val: any) {
    if (!val || val === 'Invalid date' || !this.frSearch.date_lte || this.frSearch.date_lte === 'Invalid date') {
      return;
    }
    this.isRangeDateValid = moment(val).isAfter(this.frSearch.date_lte);
  }

  @Watch('frSearch.date_lte')
  private watchEndDate(val: any) {
    if (!val || val === 'Invalid date' || !this.frSearch.date_gte || this.frSearch.date_gte === 'Invalid date') {
      return;
    }
    this.isRangeDateValid = moment(this.frSearch.date_gte).isAfter(val);
  }
}
