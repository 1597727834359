import BaseApi from './BaseApi';

class ResvApi extends BaseApi {
  public timeAvailableForCreate(params: any) {
    return this.get('/time-available-for-create', params);
  }

  public timeAvailableForUpdate(params: any) {
    return this.get('/time-available-for-update', params);
  }

  public resvDetail(id: number | string, params?: any) {
    return this.get(`/${id}`, params);
  }

  public create(params: any) {
    return this.post('/', params);
  }

  public createPrescription(id: number | string, params?: any) {
    return this.post(`/${id}/prescriptions`, params);
  }

  public records(id: number | string, params?: any) {
    return this.get(`/${id}/records`, params);
  }

  public downloadRecords(id: number | string, params: any) {
    return this.get(`/${id}/records/download`, params);
  }

  public delRecords(id: number | string, params?: any) {
    return this.del(`/${id}/records`, params);
  }

  public startResv(id: number | string, params?: any) {
    return this.post(`/${id}/start`, params);
  }

  public updatePrescriptionsResv(id: number | string, params: any) {
    return this.post(`/${id}/prescriptions`, params);
  }

  public finishResv(id: number | string, params) {
    return this.post(`/${id}/finish`, params);
  }

  public updateAmount(id: number | string, params?: any) {
    return this.post(`/${id}/update-amount`, params);
  }

  public async updateExamination(id: number | string, params: any) {
    return await this.post(`/${id}/examination-status`, params);
  }

  public deletePrescriotion(id: number, prescriptionId: number, params: any) {
    return this.del(`/${id}/prescriptions/${prescriptionId}`, params);
  }

  public update(id: number | string, params: any) {
    return this.post(`/${id}/update`, params);
  }

  public uploadBill(id: number | string, params: any) {
    return this.postWithFile(`/${id}/upload-bill`, params);
  }

  public uploadPrescription(id: number | string, params: any) {
    return this.postWithFile(`/${id}/upload-prescription`, params);
  }

  public prescriptionUpdatePharmacy(idResv: number | string, idPrescription: number | string,  params: any) {
    return this.put(`/${idResv}/prescriptions/${idPrescription}/update-pharmacy`, params);
  }

  public deletePrescription(id: number | string, params) {
    return this.post(`/${id}/delete-prescription`, params);
  }

  public sendFax(id: number | string, params) {
    return this.post(`/${id}/send-fax`, params);
  }

  public updatePrescriptionMethod(id: number | string, params) {
    return this.post(`/${id}/update-prescription-file`, params);
  }

  public updateMemo(id: number | string, params: any) {
    return this.put(`/${id}`, params);
  }

  public cancelResv(id: number | string, params: any = {}) {
    return this.post(`/${id}/cancel`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/reservations';
  }
}

export default ResvApi;
