






























































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { mapState, mapGetters } from 'vuex';
import moment, { Moment } from 'moment';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { get, includes } from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';

import PatientInfo from '@components/messages/PatientInfo.vue';
import { MessageModule } from '@/store';
import PageLoader from '@components/common/PageLoader.vue';
import {URL_REGEX, Roles, MANAGER_TYPE, CLINIC_TYPE, config} from '@common/app.config';
import { MESSAGE_TYPE } from '@/common/enums/message';
import { CHAT_STATUS } from '@common/enums/message';
import MessageImage from '@components/messages/MessageImage.vue';
import MessageTemplate from '@components/messages/MessageTemplate.vue';
import MessageText from '@components/messages/MessageText.vue';
import MessageLink from '@components/messages/MessageLink.vue';
import MessageButton from '@components/messages/MessageButton.vue';
import MessageLabel from '@components/messages/MessageLabel.vue';
import MessageInput from '@components/messages/MessageInput.vue';
import MessageClinicPattern from '@components/messages/MessageClinicPattern.vue';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import imageDefault from '@/assets/img/icon-no-image.svg';
import imageDefaultManager from '@/assets/img/BIOPORT_PLUG.svg';
import { uiAvatars } from '@common/helpers/ui-avatar';
import { User } from '../../models/user';
import PatientInfoChannel from './PatientInfoChannel.vue';
import QuestionInfoChannel from './QuestionInfoChannel.vue';
import KarteInfoChannel from './KarteInfoChannel.vue';
import DeliveryInfoChannel from './DeliveryInfoChannel.vue';
import { USER_STATUS } from '@/common/constants/auth';
import LoaderComponent from '@components/common/LoaderComponent.vue';
import VideoComponent from './VideoComponent.vue';
import ChatChannelPopup from '@components/messages/ChatChannelPopup.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';

interface IsMe {
  id: number;
  type: string;
}

@Component({
  components: {
    PatientInfo,
    PageLoader,
    MessageInput,
    VuePerfectScrollbar,
    MessageText,
    MessageTemplate,
    MessageImage,
    MessageLink,
    MessageButton,
    MessageLabel,
    MessageClinicPattern,
    InfiniteLoading,
    ImagePrivate,
    PatientInfoChannel,
    QuestionInfoChannel,
    KarteInfoChannel,
    DeliveryInfoChannel,
    LoaderComponent,
    VideoComponent,
    ChatChannelPopup,
    PopupConfirm,
  },
  computed: {
    ...mapState('auth', ['currentUser', 'isGettingUserInfo']),
    ...mapState('channel', ['channels']),
    ...mapGetters('channel', ['currentChannel']),
  },
})
export default class MessageBox extends Vue {
  @Prop() isChanged!: any;

  private get channelAvatar() {
    if (this.currentChannel && this.currentChannel.users) {
      const user = this.currentChannel.users[0] || {};
      if (!user.name && !user.avatar_url) {
        return imageDefault;
      }
      const avatarUser = user.avatar || uiAvatars(user.name);
      return avatarUser;
    }
  }

  private get channelAvatarText() {
    if (this.currentChannel && this.currentChannel.users) {
      const user = this.currentChannel.users[0] || {};
      if (!user.name) {
        return imageDefault;
      }
      return uiAvatars(user.name);
    }
  }

  get messageBox() {
    return this.$refs.messageBox as any;
  }

  get channelUserDateOfBirth() {
    return  this.currentChannelUser && moment(this.currentChannelUser.date_of_birth).format('YYYY年 MM月 DD日');
  }

  get channelUserRegisterDate() {
    return  this.currentChannelUser && moment(this.currentChannelUser.created_at).format('YYYY年 MM月 DD日');
  }

  private isShowModal = true;
  private nextTab = 1;
  private channels!: any;
  private messages: any = [];
  private currentChannel!: any;
  private totalPage: number = 1;
  private page: number = 1;
  private currentUser!: any;
  private isLoading: boolean = false;
  private isSending: boolean = false;
  private unreadMessage = 0;
  private userAvatarUrl = '';
  private currentUserAvatarUrl = '';
  private userId = null;
  private isUpdate = false;
  private userTabs = [
    {
      id: 1,
      name: '患者情報',
      image: '@/assets/img/patient-user.svg',
      role: ['clinic_admin', 'doctor', 'cs'],
      nameID: 'patient',
    },
    {
      id: 2,
      name: '問診内容',
      image: '@/assets/img/patient-question.svg',
      role: ['doctor'],
      nameID: 'question',
    },
    {
      id: 3,
      name: 'カルテ',
      image: '@/assets/img/patient-medical.svg',
      role: ['doctor'],
      nameID: 'medical',
    },
    {
      id: 4,
      name: '配送',
      image: '@/assets/img/patient-truck.svg',
      role: ['clinic_admin', 'doctor'],
      nameID: 'delivery',
    },
    {
      id: 5,
      name: 'チャット',
      image: '@/assets/img/patient-chat.svg',
      role: ['clinic_admin', 'doctor', 'cs'],
      nameID: 'chat',
    },
  ];

  private activeTab = this.userTabs[0];
  private currentChannelUser: User = new User();
  private urlBack: any = imageDefault;
  private urlBackPath: string = '';
  private urlHeadPath: string = '';
  private urlFront: any = imageDefault;
  private urlFrontPath: string = '';
  private patientAnswer: any = [];
  private patientQuestion: any = [];
  private isLoadingUser: boolean = false;
  private isVideoCall: boolean = false;
  private feedbackForm: any = {};
  private listFeedback: any[] = [];
  private hasQuestion: boolean = false;
  private hasKarte: boolean = false;
  private isSubmitting: boolean = false;
  // because the channel automatically loads after 1 minute
  private stateDeliveryChannel: any = {
    activeOrder: {},
    paginate: {
      page: 1,
      per_page: 10,
    },
  };
  setStateDeliveryChannel(data: any) {
    this.stateDeliveryChannel = data;
  }
  // end because the channel automatically loads after 1 minute

  setVideo(status) {
    this.isVideoCall = status;
  }

  resetUnreadMessage() {
    if (this.currentChannel && this.currentChannel.message_unread_count > 0) {
      this.readAllMessages(this.currentChannel.id);
    }
  }

  addListen() {
    const channelId = parseInt(this.$route.params.channelId, 10);
    this.$on('ScrollToMessage', (messageId) => this.scrollToMessage(messageId));
    this.$on(`OnMessageCreated_${channelId}`, this.onReceiveMessage);
    this.$on(`MessageDeleted`, this.onMessageDeleted);
  }

  getTimeSection(time: Moment) {
    if (!time) {
      return;
    }

    if (time.isSame(moment(), 'date')) {
      return '今日';
    }

    return time.format('YYYY年MM月DD日');
  }

  onNewMessage(message) {
    this.scrollToBottom(200);
    const messageLength = this.messages.length;
    let timeSection: any = moment(message.created_at);
    if (messageLength) {
      timeSection = this.calculateSectionTime(
        this.messages[messageLength - 1].created_at,
        message.created_at,
      );
    }
    // this.messages.push(
    //   Object.assign(
    //     {
    //       isMe: true,
    //       timeSection,
    //     },
    //     message,
    //   ),
    // );
    this.isSending = false;
  }

  isInActiveChat(): boolean {
    return this.currentChannel && this.currentChannel.status === CHAT_STATUS.INACTIVE;
  }

  isMessageText(item): boolean {
    return (
      MESSAGE_TYPE.TEXT === item.type_message
      || MESSAGE_TYPE.PAYING === item.type_message
      || MESSAGE_TYPE.FOR_DOCTOR === item.type_message
    )
      && !this.isMessageUrl(item);
  }

  isMessageTemplate(item): boolean {
    return MESSAGE_TYPE.TEXT === item.type_message && this.isMessageUrl(item);
  }

  isMessageImage(item): boolean {
    return MESSAGE_TYPE.IMAGE === item.type_message;
  }

  isMessageLink(item): boolean {
    return MESSAGE_TYPE.LINK === item.type_message;
  }

  isMessageButton(item): boolean {
    return MESSAGE_TYPE.BUTTON === item.type_message;
  }

  isMessageLabel(item): boolean {
    return MESSAGE_TYPE.LABEL === item.type_message;
  }

  isMessageClinicPattern(item): boolean {
    return MESSAGE_TYPE.CLINIC_PATTERN === item.type_message;
  }

  isMessageUrl(item) {
    const pattern = new RegExp(URL_REGEX);
    return !!pattern.test(item.message_content);
  }

  scrollToMessage(messageId: number) {
    setTimeout(() => {
      const container = this.messageBox.$el;
      const elementScrollTo = container.querySelector(`#msg_${messageId}`);

      container.scrollTop = elementScrollTo ? elementScrollTo.offsetTop : 0;
    }, 0);
  }

  getIsMeId(currentUser) {
    const isMe: IsMe[] = [];
    if (currentUser.role === Roles.superAdmin || currentUser.role === Roles.doctor) {
      isMe.push({
        id: currentUser.id,
        type: MANAGER_TYPE,
      });
    }

    if (currentUser.role === Roles.clinic) {
      isMe.push({
        id: currentUser.id,
        type: MANAGER_TYPE,
      });

      const { clinics } = currentUser;
      clinics.forEach((clinic) => {
        isMe.push({
          id: clinic.id,
          type: CLINIC_TYPE,
        });
      });
    }

    MessageModule.updateIsMeIds(isMe);
  }

  onChangeSending(isSending) {
    this.isSending = isSending;
  }

  @Watch('currentChannel')
  onChangeCurrentChannel(newVal, oldVal) {
    if (oldVal && newVal && newVal.id === oldVal.id) {
      return ;
    }
    this.resetUnreadMessage();
  }

  @Watch('currentChannelUser')
  onChangeCurrentChannelUser(newVal, oldVal) {
    if (!oldVal.id && newVal.id) {
      if (includes([USER_STATUS.CHOICE_INTERVIEW_METHOD], newVal.state) && !this.isDisabledTab(this.userTabs[1])) {
        this.messages = [];
        this.removeListen();
        this.getPatientInfo(this.currentChannelUser.id);
        this.activeTab = this.userTabs[1];
        return ;
      }

      // chat channel
      if (newVal.state === USER_STATUS.CHAT_INTERVIEW_SCHEDULED && !this.isDisabledTab(this.userTabs[4])) {
        this.addListen();
        this.resetUnreadMessage();
        this.activeTab = this.userTabs[4];
        return ;
      }
      // delivery channel
      if (newVal.state === USER_STATUS.IDENTIFICATION_OK && !this.isDisabledTab(this.userTabs[2])) {
        this.messages = [];
        this.removeListen();
        this.activeTab = this.userTabs[2];
        return ;
      }

      this.messages = [];
      this.removeListen();
      // information channel
      this.activeTab = this.userTabs[0];
    }
  }

  @Watch('isSending')
  onIsSendingChange() {
    this.scrollToBottom(200);
  }

  scrollToBottom(duration: number = 0) {
    this.$nextTick(() => {
      const container = document.querySelector('.messages-history')!;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    });
  }

  private get chatChannelPopupRef() {
    return this.$refs.chatChannelPopup as any;
  }

  setChatChannelOpen(name, status) {
    if (name === 'question') {
      this.hasQuestion = status;
    }

    if (name === 'karte') {
      this.hasKarte = status;
    }
  }

  showModalChatChannel(name) {
    if (name !== 'questionKarte') {
      return;
    }

    this.getPatientInfo(this.currentChannelUser.id);
    this.hasQuestion = true;
    this.feedbackForm = {
      id: null,
      created_at_jp: null,
      content: '',
      plans: [],
      type: null,
    };
    this.hasKarte = true;
    this.getListFeedback();
    this.$modal.show('chat-channel-popup');
  }

  getListFeedback() {
    this.isLoading = true;
    const self = this;
    ApiHelper.getApi('DoctorMouthApi').getListFeedback(this.currentChannelUser.id)
      .then(function(res, error) {
        self.listFeedback = res.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  savedFeedback() {
    const self = this;
    ApiHelper.getApi('ChannelApi').getOne(this.$route.params.channelId, { with: 'users,latest_message' })
      .then(function(res, error) {
        self.$store.commit('channel/updateChannel', res);
        // self.currentChannelUser.state = get(res, 'users[0].state');
        const newChannelUser: any = {...self.currentChannelUser};
        newChannelUser.state = get(res, 'users[0].state');
        self.changeCurrentChannelUser(newChannelUser);
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      });
    this.getListFeedback();
  }

  get showBtnVideoCall() {
    const reservationDate = get(this.currentChannelUser, 'latestReservation.reservation_date');
    const doctorId = get(this.currentChannelUser, 'latestReservation.doctor.id');
    const currentUserId = this.currentUser && get(this.currentUser, 'id');

    return Number(currentUserId) === Number(doctorId) && moment().isBetween(
      moment(reservationDate).subtract(10, 'minutes'),
      moment(reservationDate).add(40, 'minutes'),
    );
  }


  get showBtnChatReminder() {
    const statusUser = get(this.currentChannelUser, 'state');

    return (
      this.currentUser &&
      this.currentUser.role === Roles.doctor &&
      statusUser === USER_STATUS.CHAT_INTERVIEW_SCHEDULED
    );
  }

  async reminderChat() {
    if (!this.currentUser.id || !this.currentChannelUser.id) {
      return;
    }
    this.isSubmitting = true;
    try {
      await ApiHelper.getApi('UserApi').reminderChat(this.currentChannelUser.id);
      Toast.success('リマインドメッセージを送信しました。');
      this.isSubmitting = false;
    } catch (error) {
      this.isSubmitting = false;
      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  async getChannelInfo(channelId) {
    if (!channelId) {
      return;
    }
    try {
      const data = await ApiHelper.getApi('ChannelApi').getOne(channelId, {
        with: 'users,message_unread_count,latest_message,users.latestReservation,users.latestReservation.doctor',
      });
      this.currentChannelUser = data.users[0];
      return data;
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  getPatientInfo(channelId) {
    if (!channelId) {
      return;
    }
    ApiHelper.getApi('PatientApi')
    .getOne(channelId, {
      with: 'latestReservation.answers',
    })
    .then((res: any) => {
      this.patientAnswer = res.latestReservation.answers;
      this.patientQuestion = res.latestReservation.questions;
    })
    .catch((err: any) => {
      console.log(err);
    })
    .finally(() => {
      console.log();
    });
  }

  async readAllMessages(channelId) {
    if (!channelId) {
      return;
    }
    await ApiHelper.getApi('MessageApi').readAllMessages(channelId);
    this.getDataPatientChannelInfo();
  }

  onReceiveMessage(data) {
    const currentId = this.$route.params.channelId;
    if (!currentId) {
      return;
    }
    if (String(data.channel_id) !== String(currentId)) {
      return;
    }
    this.readAllMessages(data.channel_id);
    const index = this.messages.findIndex((mess) => mess.id === data.id);
    if (index > -1) {
      return;
    }
    this.messages.push(data);
    this.scrollToBottom(200);
  }

  beforeDestroy() {
    this.messages = [];
    this.removeListen();
  }

  removeListen() {
    if (!this.currentChannel) {
      return ;
    }

    const channelId = parseInt(this.currentChannel.id, 10);
    this.$off('ScrollToMessage', (messageId) => this.scrollToMessage(messageId));
    this.$off(`OnMessageCreated_${channelId}`, this.onReceiveMessage);
    this.$off(`MessageDeleted`, this.onMessageDeleted);
    this.$off(`LoadDoctorStatus`, this.onLoadDoctorStatus);
  }

  calculateSectionTime = (prevCreatedAt: string, currentCreatedAt: string) => {
    if (!prevCreatedAt && !!currentCreatedAt) {
      return moment(currentCreatedAt).isBefore(moment(), 'date') ? moment(currentCreatedAt) : '';
    }

    if (moment(prevCreatedAt).isBefore(moment(currentCreatedAt), 'date')) {
      return moment(currentCreatedAt);
    }

    return null;
  }

  async infiniteHandler($state) {
    this.getIsMeId(this.currentUser);
    const params = {
      per_page: 20,
      page: this.page,
      order: '-id',
      with: 'senderable,suggestPlan,suggestPlan.treatment',
    };
    const id = this.$route.params.channelId;
    const response = await ApiHelper.getApi('MessageApi').getMessages(id, params);
    this.isLoading = false;
    const { data, last_page, current_page } = response;
    this.totalPage = last_page;
    if (data.length) {
      const dupliceMessages: any = [];
      data.forEach((element) => {
        const index = this.messages.findIndex((item) => item.id === element.id);
        if (index > -1) {
          dupliceMessages.push(element);
        }
      });
      const refreshData = data.filter((item) => {
        return dupliceMessages.findIndex((b) => b.id === item.id) === -1;
      });
      const firstMessageId = data[0].id;
      let prevMessage: any = this.messages.length ? this.messages[this.messages.length - 1] : {};
      const addMoreMessages = refreshData.reverse().map((mess) => {
        const timeSection = this.calculateSectionTime(prevMessage.created_at, mess.created_at);
        prevMessage = Object.assign({}, mess);
        return {
          ...mess,
          timeSection,
        };
      });
      if (current_page === 1) {
        this.messages = addMoreMessages;
      } else {
        this.messages.unshift(...addMoreMessages);
      }
      this.page === this.totalPage ? $state.complete() : (this.page += 1);
      $state.loaded();
    } else {
      $state.complete();
    }
  }

  onMessageDeleted(data) {
    if (this.currentUser.role !== Roles.clinic) {
      return;
    }
    const channelId = parseInt(this.$route.params.channelId, 10);
    const targetChannelId = data.channel_id;
    if (!channelId || channelId !== targetChannelId) {
      return;
    }
    const messageIndex = this.messages.findIndex((item) => item.id === data.message_id);
    if (messageIndex > -1) {
      this.messages.splice(messageIndex, 1);
    }
  }

  onLoadDoctorStatus(data) {
    if (this.currentUser.role === Roles.doctor) {
      const channelId = parseInt(data.channel_id, 10);
      this.getChannelInfo(channelId);
      this.getDataPatientChannelInfo();
      this.readAllMessages(channelId);
      this.isUpdate = true;
    } else {
      return;
    }
  }

  async getDataPatientChannelInfo() {
    this.isLoadingUser = true;
    const channelId = parseInt(this.$route.params.channelId, 10);
    let patientInfo: any = this.$route.params.patient;
    let dataChannel: any = this.$route.params.channel;
    if (channelId) {
      if (!patientInfo || !dataChannel) {
        const dataResponsive = await this.getChannelInfo(channelId);
        patientInfo = dataResponsive && dataResponsive.users[0];
        dataChannel = dataResponsive;
        this.urlBack =  patientInfo && patientInfo.back_side_card_url ? patientInfo.back_side_card_url : imageDefault,
        this.urlFront =  patientInfo && patientInfo.front_side_card_url ? patientInfo.front_side_card_url : imageDefault,
        this.urlBackPath =  patientInfo && patientInfo.back_side_card || imageDefault,
        this.urlFrontPath =  patientInfo && patientInfo.front_side_card || imageDefault;
        this.urlHeadPath =  patientInfo && patientInfo.head_image || imageDefault;
        this.userId = patientInfo && patientInfo.id;
      }
      if (patientInfo && patientInfo.avatar) {
        let imageurl;
        const url = `${config.apiUrl}/api/managers/get-image?path=${patientInfo.avatar}`;
        imageurl = await axios
                .get(url, {
                  responseType: 'arraybuffer',
                  headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Access-Control-Allow-Origin': '*',
                  },
                })
                .then((response) => {
                  const image = btoa(
                          new Uint8Array(response.data)
                                  .reduce((data, byte) => data + String.fromCharCode(byte), ''),
                  );
                  return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
                })
                .catch((ex) => {
                  console.log(ex);
                });
        this.userAvatarUrl = imageurl;
      }
      if (this.currentUser.role === Roles.doctor) {
        const url = `${config.apiUrl}/api/managers/get-image?path=${this.currentUser.avatar}`;
        axios
          .get(url, {
            responseType: 'arraybuffer',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Access-Control-Allow-Origin': '*',
            },
          })
          .then((response) => {
            if (response.data && response.data.byteLength) {
              const image = btoa(
                      new Uint8Array(response.data)
                              .reduce((data, byte) => data + String.fromCharCode(byte), ''),
              );

              this.currentUserAvatarUrl = `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
            }
          })
          .catch((ex) => {
            console.log(ex);
          });
        this.currentUserAvatarUrl = imageDefaultManager;
      }
    }
    if (channelId && patientInfo && dataChannel) {
      const { commit } = this.$store;
      const channel = {
        ...dataChannel,
        users: [{ ...patientInfo }],
      };
      commit('channel/updateActiveChannel', channel);
    }
    this.isLoadingUser = false;
  }

  changeCurrentChannelUser(userData) {
    this.currentChannelUser = userData;
    this.urlBackPath =  userData.back_side_card || imageDefault,
    this.urlFrontPath =  userData.front_side_card || imageDefault;
    const newChannelData = this.currentChannel;
    newChannelData.users[0] = userData;
    newChannelData.state = userData.state;
    this.$store.dispatch('channel/setChangeChannels', true);
  }

  showModalLeaveVideo() {
    if (this.isShowModal === false) {
      this.$modal.hide('popupConfirm');
      this.isShowModal = true;
    } else {
      this.$modal.show('popupConfirm');
    }
  }

  leavePage() {
    this.$modal.hide('popupConfirm');
    this.isShowModal = true;
    this.messages = [];
    this.removeListen();
    this.activeTab = this.userTabs[this.nextTab - 1];
  }

  closeModal() {
    this.isShowModal = false;
    this.$modal.hide('popupConfirm');
  }

  ChangeActiveUserTab(tabId) {
    if (
      this.activeTab.id === this.userTabs[4].id &&
      tabId !== this.userTabs[4].id &&
      this.isVideoCall
    ) {
      this.showModalLeaveVideo();
      this.nextTab = tabId;
      return ;
    }
    if (this.activeTab === this.userTabs[tabId - 1]) {
      return ;
    }
    if (tabId === this.userTabs[1].id) {
      this.getPatientInfo(this.currentChannelUser.id);
    }
    if (tabId !== this.userTabs[4].id) {
      this.messages = [];
      this.removeListen();
    }
    if (tabId === this.userTabs[4].id) {
      this.addListen();
      this.resetUnreadMessage();
    }
    this.activeTab = this.userTabs[tabId - 1];
  }

  created() {
    this.getDataPatientChannelInfo();
    // const channelId = parseInt(this.$route.params.channelId, 10);
    // this.$on('ScrollToMessage', (messageId) => this.scrollToMessage(messageId));
    // this.$on(`OnMessageCreated_${channelId}`, this.onReceiveMessage);
    // this.$on(`MessageDeleted`, this.onMessageDeleted);
    this.$on(`LoadDoctorStatus`, this.onLoadDoctorStatus);
  }

  private isDisabledTab(tab) {
    const isSAdmin = this.currentUser.role === 'super_admin';
    const isAdmin = this.currentUser.role === 'clinic_admin';
    const isDoctor = this.currentUser.role === 'doctor';
    const isPharmacist = this.currentUser.role === 'pharmacist';
    const isCS = this.currentUser.role === 'cs';

    if (isSAdmin) {
      if (tab.id === this.userTabs[0].id) {
        return false;
      }
      return true;
    }
    if (isAdmin || isPharmacist || isCS) {
      if (tab.id === this.userTabs[0].id || tab.id === this.userTabs[3].id) {
        return false;
      }
      return true;
    }
    if (isDoctor) {
      return false;
    }
    // if (this.currentUser && isCanAccessRole) {
    //   console.log('passss');
    //   if (
    //     tab.id === this.userTabs[1].id ||
    //     tab.id === this.userTabs[2].id ||
    //     tab.id === this.userTabs[4].id
    //   ) {
    //     return true;
    //   }

    //   return false;
    // }

    return false;
    // return !((this.currentUser.role === 'cs' && tab.id === 2) || (this.currentUser.role === 'cs' && tab.id === 3));
  }
}
