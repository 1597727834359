















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SearchInput extends Vue {
  @Prop(String) readonly placeholder!: string;
  private text: string = '';
}
