


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Roles } from '@/common/app.config';

@Component
export default class PharmacistSearch extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  @Prop({ default: '検索'}) searchText!: string;
  @Prop() searchIcon!: string;

  private get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }

  private filter = {
    pharmacy_name: '',
    uuid: '',
    name: '',
  };

  mounted() {
    this.onSearch();
  }

  private onSearch() {
    this.$emit('search', this.filter);
  }
}
