

















































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import moment from 'moment';
import FullCalendar from '@fullcalendar/vue';
import jaLocale from '@fullcalendar/core/locales/ja';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {mapState} from 'vuex';
import {State} from 'vuex-class';
import { Roles, formatTime } from '@/common/app.config';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import BasicSelect from '@components/common/BasicSelect.vue';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import BaseDatePicker from '@/components/common/BaseDatePicker.vue';

@Component({
  components: {
    FullCalendar,
    BasicSelect,
    VueTimepicker,
    DatePicker,
    BaseDatePicker,
  },
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
})

export default class Calendar extends Vue {

  private get computedForm() {
    return this.$refs.formUpdateShift as any;
  }
  @Prop(Array) readonly events!: any;
  @Prop({default: []}) readonly doctorOptions!: any;
  @State((state) => state.auth.currentUser) currentUser!: any;
  public formUpdate = {
    id: null,
    doctor_id: null,
    date: null,
    start_time: '00:00',
    end_time: '00:00',
  };

  private formatTime: string = formatTime;
  private hourRange: any = this.range(0, 24);
  private minutesRangeStartTime: any = [0, 30];
  private minutesRangeEndTime: any = [0, 30];
  private Roles = Roles;
  private option = 'week';
  private searchOption = {};
  private jaLocale = jaLocale;
  private calendarPlugins = [ dayGridPlugin, timeGridPlugin ];
  private header = {
    left: 'dayGridMonth,timeGridWeek,timeGridDay title',
    center: '',
    right: 'prev,next',
  };
  private customButtons = {
    dayGridMonth: {
      text: '月',
      click: () => {
        const fullCalendar: any = this.$refs.fullCalendar;
        const calendarApi = fullCalendar.getApi();
        calendarApi.changeView('dayGridMonth');
        this.option = 'month';
        this.searchOption = {search_by: this.option, date: moment(calendarApi.getDate()).format('YYYY-MM-DD')};
        this.$emit('setSearchOptions', this.searchOption);
        this.$emit('fetchDataShifts');
      },
    },
    timeGridWeek: {
      text: '週',
      click: () => {
        const fullCalendar: any = this.$refs.fullCalendar;
        const calendarApi = fullCalendar.getApi();
        calendarApi.changeView('timeGridWeek');
        this.option = 'week';
        this.searchOption = {search_by: this.option, date: moment(calendarApi.getDate()).format('YYYY-MM-DD')};
        this.$emit('setSearchOptions', this.searchOption);
        this.$emit('fetchDataShifts');
      },
    },
    timeGridDay: {
      text: '日',
      click: () => {
        const fullCalendar: any = this.$refs.fullCalendar;
        const calendarApi = fullCalendar.getApi();
        calendarApi.changeView('timeGridDay');
        this.option = 'date';
        this.searchOption = {search_by: this.option, date: moment(calendarApi.getDate()).format('YYYY-MM-DD')};
        this.$emit('setSearchOptions', this.searchOption);
        this.$emit('fetchDataShifts');
      },
    },
    prev: {
      click: () => {
        const fullCalendar: any = this.$refs.fullCalendar;
        const calendarApi = fullCalendar.getApi();
        calendarApi.prev();
        this.searchOption = {search_by: this.option, date: moment(calendarApi.getDate()).format('YYYY-MM-DD')};
        this.$emit('setSearchOptions', this.searchOption);
        this.$emit('fetchDataShifts');
      },
    },
    next: {
      click: () => {
        const fullCalendar: any = this.$refs.fullCalendar;
        const calendarApi = fullCalendar.getApi();
        calendarApi.next();
        this.searchOption = {search_by: this.option, date: moment(calendarApi.getDate()).format('YYYY-MM-DD')};
        this.$emit('setSearchOptions', this.searchOption);
        this.$emit('fetchDataShifts');
      },
    },
  };

  changeHandler(eventData) {
    if (moment().format('YYYY-MM-DD') === moment(eventData).format('YYYY-MM-DD')) {
      this.hourRange = this.range(parseInt(moment().format('HH')), 23);
      this.formUpdate.start_time = moment().format('HH:mm');
      this.formUpdate.end_time = moment().add(1, 'minutes').format('HH:mm');
    } else {
      this.hourRange = this.range(0, 23);
      this.minutesRangeStartTime = [0, 30];
      this.formUpdate.start_time = '00:00';
      this.formUpdate.end_time = '00:00';
    }
  }

  eventRender(info) {
    const element = info.el.querySelector('.fc-time');
    element.classList.add('no-before');
    element.innerHTML = `${info.event.extendedProps.start_time} ~ ${info.event.extendedProps.end_time} <br />`;
    if (this.currentUser.role === Roles.doctor) {
      info.el.querySelector('.fc-title').innerHTML = '';
    }
  }

  eventClick(info) {
    const eventObj = info.event;
    this.formUpdate.date = eventObj.extendedProps.current_date;
    this.formUpdate = {
      id: eventObj.id,
      doctor_id: eventObj.extendedProps.doctor_id,
      date: eventObj.extendedProps.current_date,
      start_time: eventObj.extendedProps.start_time,
      end_time: eventObj.extendedProps.end_time,
    };
    this.$modal.show('formUpdateShift');
  }

  private isCheckAfterNow() {
    const date = this.formUpdate.date;
    return  date && moment().format('YYYY-MM-DD') >= date;
  }

  private notAfterToday(date) {
    return date < moment().clone().startOf('date');
  }

  private checkStartEndTime() {
    const timeStart = moment(this.formUpdate.start_time, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    const timeEnd = moment(this.formUpdate.end_time, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    return timeEnd > timeStart;
  }

  private checkSubmit() {
    return !(this.formUpdate.doctor_id && this.formUpdate.start_time && this.formUpdate.end_time && this.formUpdate.date && this.checkStartEndTime());
  }

  private hiddenUpdateShift() {
    this.$modal.hide('formUpdateShift');
  }

  private async updateShift() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }

    await ApiHelper.getApi('DoctorShiftApi')
        .update(this.formUpdate.id, this.formUpdate).then(() => {
          this.hiddenUpdateShift();
          this.$emit('fetchDataShifts');
        }).catch((error) => {
          if (error.response.status === 422) {
            this.computedForm.setErrors(error.response.data.errors);
          }
        });
  }

  private async deleteShift() {
    await ApiHelper.getApi('DoctorShiftApi')
        .delete(this.formUpdate.id).then(() => {
          this.hiddenUpdateShift();
          this.$emit('fetchDataShifts');
          Toast.info('削除しました。');
        }).catch((error) => {
          if (error.response.status === 422) {
            this.computedForm.setErrors(error.response.data.errors);
          }
        });
  }

  @Watch('formUpdate.start_time')
  private changeStartTime(value) {
    const hour = parseInt(moment(value, 'HH:mm').format('HH'));
    if (moment().format('YYYY-MM-DD') == this.formUpdate.date && hour == parseInt(moment().format('HH'))) {
      if (hour == 0) {
        this.minutesRangeStartTime = [0, 30];
      } else if (hour <= 30) {
        this.minutesRangeStartTime = [30];
      } else {
        this.minutesRangeStartTime = [0, 30];
      }
    } else {
      this.minutesRangeStartTime = [0, 30];
    }
  }

  @Watch('formUpdate.end_time')
  private changeEndTime(value) {
    const hour = parseInt(moment(value, 'HH:mm').format('HH'));
    if (moment().format('YYYY-MM-DD') == this.formUpdate.date && hour == parseInt(moment().format('HH'))) {
      if (hour == 0) {
        this.minutesRangeEndTime = [0, 30];
      } else if (hour <= 30) {
        this.minutesRangeEndTime = [30];
      } else {
        this.minutesRangeEndTime = [0, 30];
      }
    } else {
      this.minutesRangeEndTime = [0, 30];
    }
  }

  @Watch('formUpdate.date')
  private changeDate(value, oldValue) {
    if (!oldValue) { return; }

    if (moment().format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD')) {
      const minutes = parseInt(moment().format('mm'));
      const hour = parseInt(moment().format('HH'));

      if (minutes == 0) {
        this.hourRange = this.range(hour, 23);
        this.minutesRangeStartTime = [0, 30];
        this.minutesRangeStartTime = [0, 30];
        this.formUpdate.start_time = `${(`0${hour}`).slice(-2)}:00`;
        this.formUpdate.end_time = `${(`0${hour}`).slice(-2)}:30`;
      } else if (minutes <= 30) {
        this.hourRange = this.range(hour, 23);
        this.minutesRangeStartTime = [30];
        this.minutesRangeEndTime = [30];
        this.formUpdate.start_time = `${(`0${hour}`).slice(-2)}:30`;
        this.formUpdate.end_time = `${(`0${hour + 1}`).slice(-2)}:00`;
      } else {
        this.hourRange = this.range(hour + 1, 23);
        this.minutesRangeStartTime = [0, 30];
        this.minutesRangeEndTime = [0, 30];
        this.formUpdate.start_time = `${(`0${hour + 1}`).slice(-2)}:00`;
        this.formUpdate.end_time = `${(`0${hour + 1}`).slice(-2)}:30`;
      }
    } else {
      this.hourRange = this.range(0, 23);
      this.minutesRangeStartTime = [0, 30];
      this.minutesRangeEndTime = [0, 30];
      // this.formUpdate.start_time = '00:00';
      // this.formUpdate.end_time = '00:00';
    }
  }

  private range(start, end) {
    const results: any = [];
    for (let i = start; i <= end; i++) {
      results.push(i);
    }
    return results;
  }

  private get isDisableButton() {
    return this.currentUser.role === Roles.pharmacist;
  }
}
