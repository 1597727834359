


















































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import 'vue2-datepicker/index.css';
import DatePickerMixin from '@/common/mixins/DatePickerMixin';
import ContentLayout from '@components/layouts/Content.vue';

import { DeliveryState } from '@/common/app.config';
import { SelectType } from '@/common/interfaces/common';
import { LineMessageSearch } from '@/models/line-message-search';
import { Paginate } from '@/models/paginate';

import BasicSelect from '@/components/common/BasicSelect.vue';
import Pagination from '@/components/common/Pagination.vue';
import PageLoader from '@/components/common/PageLoader.vue';
import Toast from '@/common/helpers/toast';
import iconLineMessage from '@/assets/img/ic-line-message.svg';

import { get, remove, find, sortedUniqBy } from 'lodash';

import { USER_STATUS, USER_STATUS_NAME } from '@/common/constants/auth';
import { User } from '@/models/user';
import ModalLineMessage from '@/components/LineMessage/ModalLineMessage.vue';

@Component({
  name: 'LineMessage',
  components: {
    BasicSelect,
    Pagination,
    PageLoader,
    ContentLayout,
    ModalLineMessage,
  },
  data() {
    return {
      iconHeader: iconLineMessage,
      get,
    };
  },
})
export default class LineMessage extends Mixins(DatePickerMixin) {
  isLoading: boolean = false;
  keyword: string = '';
  isPaginate: boolean = false;
  lang = { yearFormat: 'YYYY年' };
  idsSelected: Array<{}> = [];
  tableHead: string[] = [
    '',
    'ID',
    '患者名',
    'LINE Name',
    'ユーザーステータス',
    '契約プラン',
  ];

  frSearch: LineMessageSearch = new LineMessageSearch();
  currentSearchParams: any = {};
  isSelectAll: boolean = true;

  data: User[] = [];
  paginate: Paginate = new Paginate();
  pageOptions = [10, 50, 100];
  isRangeDateValid = false;
  isDisableBtnShowModel = false;
  isLoadingEcForce: boolean = false;
  plans = [];

  get deliveryState(): SelectType[] {
    return DeliveryState;
  }

  get planOptions() {
    const options = [
      {
        name: '全て',
        value: null,
      },
      ...this.plans,
    ];
    return options;
  }

  get userStateOptions() {
    let data: any = [{
        name: '全て',
        value: null,
      }];
    Object.keys(USER_STATUS).map((name) => {
      data = [
        ...data,
        { name: USER_STATUS_NAME[name], value: USER_STATUS[name] },
      ];
    });

    return data;
  }

  getListUserId() {
    const ids = this.idsSelected.map((item) => get(item, 'user_id'));
    return sortedUniqBy(ids, Math.floor);
  }

  resetIdsSelected() {
    this.idsSelected = [];
    this.setDisableButtonSentLineMessage();
  }

  changeSelectAll() {
    this.isSelectAll = !this.isSelectAll;
    const isChecked = this.isSelectAll;
    // if (this.isSelectAll) {
    //   // this.data.forEach((user) => {(user as any).isChecked = true; });
    // } else {
    //   this.idsSelected = [];
    //   // this.data.forEach((user) => {(user as any).isChecked = false; });
    // }
    this.idsSelected = [];
    this.data.forEach((user) => {(user as any).isChecked = isChecked; });
    this.setDisableButtonSentLineMessage();
    // console.log(this.idsSelected, this.isSelectAll);
  }

  onChecked(user: any) {
    user.isChecked = !user.isChecked;

    if (this.isSelectAll) {
      !user.isChecked ? this.pushItemSelected(user) : this.removeItemSelected(user);
    } else {
      user.isChecked ? this.pushItemSelected(user) : this.removeItemSelected(user);
    }
    this.setDisableButtonSentLineMessage();
    // console.log(this.idsSelected, this.isSelectAll);
  }

  pushItemSelected(user) {
    this.idsSelected.push({ id: user._key, user_id: user.id });
  }

  removeItemSelected(user) {
    remove(this.idsSelected, (item) => get(item, 'id') === user._key);
  }

  setDisableButtonSentLineMessage() {
    const isDisable = this.isSelectAll ? this.paginate.total === this.idsSelected.length : !(this.idsSelected.length > 0);
    this.isDisableBtnShowModel = this.data.length === 0 || isDisable;
  }

  isChecked(user: User) {
    const isUserSelected = find(this.idsSelected, (item) => get(item, 'id') === user._key);

    return this.isSelectAll ? !isUserSelected : isUserSelected;
  }

  showModelSendLineMessage() {
    this.$modal.show('modalLineMessage');
  }

  mounted() {
    this.getPlanList();
    this.paginate.setPerPage(10);
    this.fetchData();
  }

  onSearch(): void {
    this.currentSearchParams = {...this.frSearch.toSearchJSON()};
    this.isSelectAll = true;
    this.resetIdsSelected();
    this.resetDataTable();
    this.fetchData(true);
  }

  resetDataTable() {
    this.paginate.last_page = 0;
    this.paginate.setPage(1);
  }

  onPageChange(page): void {
    if (this.isPaginate) {
      return;
    }

    this.resetDataTable();
    this.isPaginate = true;
    this.paginate.setPage(page);
    this.fetchData();
  }

  onChangeKeyword(event) {
    this.keyword = event.target.value;
  }

  onChangePerPage(perPage: number) {
    this.resetDataTable();
    this.paginate.setPerPage(perPage);
    this.fetchData();
  }

  isActivePerPage(perPage: number) {
    return this.paginate.per_page === perPage;
  }

  getPlanList() {
    ApiHelper.getApi('PlanApi')
      .fetchPlanList({})
      .then((response: any) => {
        this.plans = response.data.map((item) => {
          return { name: item.name, value: item.id };
        });
      });
  }

  private async fetchData(callBySearching: boolean = false ) {
    const opts = {
      with: 'plans',
      ...this.currentSearchParams,
      ...this.paginate.toPaginateJSON(),
    };
    this.isLoading = true;
    await ApiHelper.getApi('PatientApi')
      .listWithPlan(opts)
      .then((res: any) => {
        this.data = res.data.map((item) => {
          item.state = get(USER_STATUS_NAME, item.state?.toUpperCase());
          item._key = `${item.order_id ? item.order_id : '00'}-${item.id}`;
          item.isChecked = this.isChecked(item);
          return new User().deserialize(item);
        });
        if (callBySearching) {
          this.isSelectAll = this.data.length > 0;
        }

        this.paginate = new Paginate().deserialize(res);
        this.setDisableButtonSentLineMessage();
        // console.log(this.idsSelected, this.isSelectAll);
      })
      .catch((err: any) => {
        Toast.error(get(err, 'response.data.message'));
      })
      .finally(() => {
        this.isLoading = false;
        this.isPaginate = false;
      });
  }
}
