import BaseApi from './BaseApi';

class OrderChangeApi extends BaseApi {

  changeOrder(orderId: number, data = {}) {
    return this.post(`orders/${orderId}/recreate`, data);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/';
  }
}

export default OrderChangeApi;
