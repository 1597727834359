





















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IQuestion } from '@/models/question';
import { State } from 'vuex-class';
import { Roles } from '../../../common/app.config';

@Component({})
export default class RadioQuestion extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  @Prop() editMode!: boolean;
  @Prop({ required: true }) question!: IQuestion;

  private value: any = null;
  private isDuplicate: boolean = false;

  @Watch('question', { deep: true })
  onChangeQuestion() {
    this.$emit('change', this.question);
  }

  private get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }

  private onInputOption(index) {
    const currentValueOption = this.question.options[index];
    this.checkDuplice(currentValueOption);
  }

  private checkDuplice(value) {
    if (!value) { return; }
    const items = this.question.options.filter((item) => item === value);
    if (items.length > 1) {
      this.isDuplicate = true;
    } else {
      this.isDuplicate = false;
    }
  }

  private onFocusInput(event) {
    this.value = event.target.value;
    event.target.select();
    this.checkDuplice(event.target.value);
  }

  private addNewOption() {
    this.$emit('addOption');
  }

  private clearOption(index) {
    this.$emit('clearOption', index);
  }
}
