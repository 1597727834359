



































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { MedicineModule } from '@/store';
import { Medicine } from '@/models/medicine';
import { LIMIT_ADD_MEDICINE_OF_MENU } from '@/common/app.config';
import BasicModal from '@/components/common/BasicModal.vue';
import Pagination from '@components/common/Pagination.vue';
import SearchInput from '../common/SearchInput.vue';

@Component({
  components: {
    BasicModal,
    SearchInput,
    Pagination,
  },
})
export default class PopupListMedicine extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(Array) readonly medicinesChoosed!: [];
  private tempMedicine = [];
  private tempMedicineRemove = [];
  private checkDirty = false;
  private query = {
    freeword: '',
    page: 1,
    per_page: 5,
  };

  private get dataMedicineList() {
    return MedicineModule.dataMedicine;
  }

  private get isEmptyData() {
    return MedicineModule.isEmptyDataMedicine;
  }


  private get totalPage() {
    return MedicineModule.totalPage;
  }

  private get isDisabledButtonChoose() {
    return this.medicinesChoosed.length >= LIMIT_ADD_MEDICINE_OF_MENU;
  }

  @Emit('onChooseMedicine')
  private onChooseMedicine(medicine) {
    this.checkDirty = true;
    (this.medicinesChoosed as any).push(medicine);
    (this.tempMedicine as any).push(medicine);

    const medicineIdRemove = (this.tempMedicineRemove as any).map((x) => x.id);
    if (medicineIdRemove.includes(medicine.id)) {
      this.tempMedicineRemove = this.tempMedicineRemove.filter((x) => !medicineIdRemove.includes((x as any).id));
    }

    return this.medicinesChoosed;
  }

  @Emit('onChooseMedicine')
  private onRemoveMedicineChoosed(medicine) {
    this.checkDirty = true;
    if (!(this.tempMedicineRemove as any).map((x) => x.id).includes(medicine.id)) {
      (this.tempMedicineRemove as any).push(medicine);
    }

    this.tempMedicine = this.tempMedicine.filter((x) => (x as any).id !== medicine.id);
    return this.medicinesChoosed.filter((x) => (x as any).id !== medicine.id);
  }

  @Emit('onChooseMedicine')
  private cancelPopup() {
    this.$modal.hide(this.name);
    if (this.tempMedicineRemove.length > 0) {
      this.tempMedicineRemove.filter((x) => {
      if (!(this.medicinesChoosed as any).map((item) => item.id).includes((x as any).id)) {
        this.medicinesChoosed.push(x);
      }
      });
    }
    const medicineIds = this.tempMedicine.map((x) => (x as any).id);
    return this.medicinesChoosed.filter((x) => !medicineIds.includes((x as any).id));
  }

  // Methods
  private checkMedicineChoosed(id: number) {
    return this.medicinesChoosed.some((x) => (x as any).id === id);
  }

  private beforeOpen(data) {
    this.tempMedicine = [];
    this.query = {...this.query, freeword: '', page: 1};
    MedicineModule.getMedicineListPaginate(this.query);
  }

  private onChangeSearch(freeword: string) {
    this.query = {...this.query, freeword, page: 1};
  }

  private onPageChaned(page: number) {
    this.query = {...this.query, page};
  }

  @Watch('query')
  private onQueryChanged(query: object, oldQuery: object) {
    MedicineModule.getMedicineListPaginate(query);
  }
}
