export const splitLongText = (text: string, quantity: number): string => {
  if (text === null) {
    return '';
  }

  if (text.length > quantity) {
    return text.substring(0, quantity) + '...';
  }

  return text;
};
