import { Module, VuexModule } from 'vuex-module-decorators';
import { PlanInput, Plan } from '@/models/plan';

@Module({ name: 'plan', namespaced: true })
export default class PlanStore extends VuexModule {
  public dataPlan: PlanInput[] = [];
  public plan: Plan = new Plan();
  public total: number = 0;
  public totalPage: number = 0;
  public isLoading = false;
}
