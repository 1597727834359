


































































import { Vue, Component, Prop } from 'vue-property-decorator';
// Components
import BaseModal from '@/components/common/BaseModal.vue';
import FormAction from '@/components/common/FormAction.vue';
import LazyloadImage from '@components/image/LazyloadImage.vue';

import ApiHelper from 'api-helper';
import { Plan } from '@/models/plan';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    BaseModal,
    FormAction,
    LazyloadImage,
  },
})
export default class ModalChangePlan extends Vue {
  @Prop(String) readonly name: string;
  @Prop(Boolean) readonly isSubmitting: boolean;

  isLoading: boolean = false;
  plans: Plan[] = [];
  selectedPlanId = null;
  isShowMsg: boolean = false;

  beforeOpen() {
    this.selectedPlanId = null;
    this.isShowMsg = false;
    this.getPlans();
  }

  getPlans() {
    this.isLoading = true;
    const self = this;
    ApiHelper
      .getApi('PlanApi').fetchPlanList({karte_status: true})
      .then(function(res) {
        self.plans = res.data.map((item) => new Plan().deserialize(item));
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  choosePlan(plan) {
    this.selectedPlanId = plan.id;
    this.isShowMsg = true;
  }
}
