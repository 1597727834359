import BaseApi from './BaseApi';

class ClinicUserApi extends BaseApi {
  public changeStatus(params) {
    return this.post(`/change-status`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/clinic-users';
  }
}

export default ClinicUserApi;
