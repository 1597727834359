import moment from 'moment';
import {ja} from 'vuejs-datepicker/dist/locale';
import { SelectType } from '@/common/interfaces/common';
import {OrderStatusType, DeliveryStatusType, DeliveryStateType, DeliveryStateDisplay} from '@/common/enums/order';
import { VerifyType } from '@/common/enums/patient';

export const config = {
  apiUrl: process.env.VUE_APP_API_URL,
  webUrl: process.env.VUE_APP_WEB_URL,
  branchIOKey: process.env.VUE_APP_BRANCHIO_KEY,
  title: process.env.VUE_APP_TITLE,
  socketUrl: process.env.VUE_APP_SOCKET_URL,
  calllingUrl: process.env.VUE_APP_CALLING_PATH,
  calllingTalkUrl: process.env.VUE_APP_CALLING_TALK_PATH,
  calllingWorkspace: process.env.VUE_APP_CALLING_WORKSPACE,
  airshipAppKey: process.env.VUE_APP_AIRSHIP_APP_KEY,
  airshipAccessToken: process.env.VUE_APP_AIRSHIP_ACCESS_TOKEN,
  airshipPublicKey: process.env.VUE_APP_AIRSHIP_PUBLIC_KEY,
  manualUrl: process.env.VUE_APP_MANUAL_URL,
};

export const Roles = {
  admin: 'clinic_admin',
  superAdmin: 'super_admin',
  customerService: 'cs',
  doctor: 'doctor',
  delivery: 'delivery',
  // TODO remove
  clinic: 'C',
  pharmacy: 'S',
  pharmacist: 'pharmacist',
};

export const CType = {
  clinic: 'clinic',
  salon: 'salon',
  clinicDoctor: 'clinic_doctor',
};

export const CSV_FILE_TYPE = ['text/csv', '.csv', 'application/vnd.ms-excel'];

export const URL_NO_IMAGE = 'https://cdn11.bigcommerce.com/s-auu4kfi2d9/stencil/59606710-d544-0136-1d6e-61fd63e82e44/e/0fa64ac0-0314-0137-cf43-1554cd16a871/icons/icon-no-image.svg';

export const formatDateJp = 'YYYY年MM月DD日';
export const formatDate = 'YYYY-MM-DD';
export const formatDate2 = 'YYYY/MM/DD';
export const formatDateTime = 'YYYY/MM/DD HH:mm';
export const formatDateTime2 = 'YYYY-MM-DD HH:mm';
export const formatDateTimeJp = 'YYYY年MM月DD日 HH:mm';
export const formatDateTimeJp2 = 'YYYY年 MM月 DD日 (ddd) HH:mm';
export const formatTime = 'HH:mm';

export const URL_REGEX = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g;

export const CSV_COLUMN_REGEX = /^[ｦ-ﾟ ､ー一-龯\\sぁ-んァ-ンＡ-ｚA-Za-z0-9０-９ 　]{1,30}$/;

export const IMAGES_MIME = ['image/png', 'image/jpeg', 'image/pjpeg'];

export const MONEY_REGEX = /(\d)(?=(\d\d\d)+(?!\d))/g;

export const RESERVATION_TYPE = [
  {
    id: 1,
    text: 'オンライン診断',
  },
  {
    id: 2,
    text: '来院',
  },
  {
    id: 3,
    text: '事前カウンセリング_オンライン',
  },
  {
    id: 4,
    text: '事前カウンセリング_来院',
  },
  {
    id: 5,
    text: 'その他',
  },
];

export const WEEKDAYS = [
  { id: 0, text: '日' },
  { id: 1, text: '月' },
  { id: 2, text: '火' },
  { id: 3, text: '水' },
  { id: 4, text: '木' },
  { id: 5, text: '金' },
  { id: 6, text: '土' },
];


export const PRESCRIPTION_METHOD = [
  {
    id: 0,
    text: '院外処方',
  },
  {
    id: 1,
    text: '院内処方',
  },
];

export const TYPE_BANK = [
  {
    id: 1,
    text: '普通',
  },
  {
    id: 2,
    text: '当座',
  },
  {
    id: 3,
    text: '貯蓄',
  },
];

export const PHARMACIST_RESV_STATUS = [
  { text: 'すべて', id: null },
  { text: '予約中', id: 'new' },
  { text: '服薬指導中', id: 'started' },
  { text: '服薬指導完了', id: 'finished' },
  { text: '決済完了', id: 'paid' },
  { text: '決済失敗', id: 'waitPayment' },
  { text: 'キャンセル済', id: 'canceled' },
];

export const RESERVATION_STATUS = [
  {
    id: 'new',
    text: '予約中',
  },
  {
    id: 'started',
    text: '診察中',
  },
  {
    id: 'finished',
    text: '診察完了',
  },
  {
    id: 'canceled',
    text: 'キャンセル済',
  },
  {
    id: 'waitPayment',
    text: '決済失敗',
  },
];

export const PAYMENT_STATUS = [
  {
    id: 0,
    text: '未決済',
  },
  {
    id: 1,
    text: 'オーソリ完了',
  },
  {
    id: 2,
    text: 'オーソリ失敗',
  },
  {
    id: 3,
    text: '決済失敗',
  },
  {
    id: 4,
    text: '決済完了',
  },
];

export const MAX_FILE_SIZE = 5 * 1024 * 1024;

export const LIMIT_ADD_CLINIC = 5;
export const LIMIT_ADD_DOCTOR_OF_MENU = 30;
export const LIMIT_ADD_MEDICINE_OF_MENU = 30;

export const MANAGER_TYPE = 'App\\Models\\Manager';
export const CLINIC_TYPE = 'App\\Models\\Clinic';

export enum SERVICE_TYPE {
  doctor = 'doctor',
  user = 'user',
  webuser = 'webuser',
  server = 'server',
  admin = 'clinic_admin',
}

export const DATETIME_CONFIG = {
  language: ja,
  format: (date) => moment(date).format('YYYY年 MM月DD日'),
};

export const MEDICINE_TYPE = [
  {
    id: 1,
    text: '処方薬',
  },
  {
    id: 2,
    text: 'サプリ',
  },
  {
    id: 3,
    text: '漢方',
  },
];

export const HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const MINUTES = ['00', '30'];

export const SHIFT_TYPE = ['オンラインのみ', '来院のみ', 'どちらも'];

export const MEDICAL_MENU_TYPE = [
  { name: 'すべて', value: null },
  { name: '保険診療', value: 1 },
  { name: '自由診療', value: 0 },
];

export const MEDICAL_MENU_STATUS = [
  { name: 'すべて', value: null },
  { name: '表示', value: 1 },
  { name: '非表示', value: 0 },
];

export const NEW_PAYMENT_STATUS = [
  { name: 'すべて', value: null },
  { name: '決済済み', value: 1 },
  { name: '未決済', value: 0 },
];

export const MEDICINE_TYPE_OPTIONS = [
  {name: '内用薬', value: '内用薬' },
  {name: '外用薬', value: '外用薬' },
];

export const NOTIFICATION_STATUS_OPTIONS = [
  {name: '予約', value: 1 },
  {name: '下書き', value: 0 },
  {name: '配信エラー', value: -1 },
  {name: '配信済み', value: 2 },
];

export const PLAN_STATUS = [
  {name: '全て', value: null},
  {name: '無効', value: 0},
  {name: '有効', value: 1},
];

export const OrderStatus: SelectType[] = [
  {name: '全て', value: null},
  {name: '未決済', value: OrderStatusType.Waiting},
  {name: '決済エラー', value: OrderStatusType.Failure},
  {name: '決済済み', value: OrderStatusType.Success},
  {name: 'キャンセル', value: OrderStatusType.Cancelled},
  {name: '契約', value: OrderStatusType.Delivered},
];

export const DeliveryStatus: SelectType[] = [
  {name: '全て', value: null},
  {name: '発送待ち', value: DeliveryStatusType.Success},
  {name: '発送完了', value: DeliveryStatusType.Delivered},
  {name: 'キャンセル済', value: DeliveryStatusType.Undelivered},
  {name: '返金済み', value: DeliveryStatusType.Refunded},
];

export const DeliveryState: SelectType[] = [
  {name: '全て', value: null},
  {name: DeliveryStateDisplay[DeliveryStateType.NotPaid], value: DeliveryStateType.NotPaid},
  {name: DeliveryStateDisplay[DeliveryStateType.Paid], value: DeliveryStateType.Paid},
  {name: DeliveryStateDisplay[DeliveryStateType.PreparingForDelivery], value: DeliveryStateType.PreparingForDelivery},
  {name: DeliveryStateDisplay[DeliveryStateType.Delivered], value: DeliveryStateType.Delivered},
  {name: DeliveryStateDisplay[DeliveryStateType.DeliveryComplete], value: DeliveryStateType.DeliveryComplete},
  {name: DeliveryStateDisplay[DeliveryStateType.Cancel], value: DeliveryStateType.Cancel},
  {name: DeliveryStateDisplay[DeliveryStateType.ReadyForDelivery], value: DeliveryStateType.ReadyForDelivery},
  {name: DeliveryStateDisplay[DeliveryStateType.ErrorPaid], value: DeliveryStateType.ErrorPaid},
  {name: DeliveryStateDisplay[DeliveryStateType.Refunded], value: DeliveryStateType.Refunded},
  {name: DeliveryStateDisplay[DeliveryStateType.Skipped], value: DeliveryStateType.Skipped},
];

export const DeliveryPharmacistState: SelectType[] = [
  {name: '全て', value: null},
  {name: DeliveryStateDisplay[DeliveryStateType.PreparingForDelivery], value: DeliveryStateType.PreparingForDelivery},
  {name: DeliveryStateDisplay[DeliveryStateType.ReadyForDelivery], value: DeliveryStateType.ReadyForDelivery},
  {name: DeliveryStateDisplay[DeliveryStateType.Delivered], value: DeliveryStateType.Delivered},
];

export const VerifyPatient = [
  { name: '全て', value: null },
  { name: '審査完了', value: VerifyType.Verified },
  { name: '未提出', value: VerifyType.New },
  { name: '未審査', value: VerifyType.Waiting },
  { name: '審査NG', value: VerifyType.Unverified },
];

export const ROLE_LIST = [
  { name: 'システムAdmin', value: Roles.superAdmin },
  { name: 'Admin', value: Roles.admin },
  { name: 'Doctor', value: Roles.doctor },
  { name: 'カウンセラー', value: Roles.pharmacist },
  { name: 'CS', value: Roles.customerService },
  { name: 'delivery', value: Roles.delivery },
];

export const CLINIC_TYPE_OPTIONS = [
  { text: '-', id: null },
  { text: 'クリニック', id: CType.clinic },
  { text: 'サロン', id: CType.salon },
  { text: '診察クリニック', id: CType.clinicDoctor },
];

export const CLINIC_TYPE_DISPLAY = {
  null: '-',
  clinic: 'クリニック',
  salon: 'サロン',
  clinic_doctor: '診察クリニック',
};

export const MEDICINE_CODE_REGEX = /^[0-9A-Z]*$/;

export const COLORS = {
  WHITE: '#fff',
};
