import './class-component-hooks';
import Vue from 'vue';
import VueBroadcast from '@/common/VueBroadcast';
import vClickOutside from 'v-click-outside';
import 'ant-design-vue/dist/antd.css';
import 'vue2-datepicker/index.css';
import 'material-icons/iconfont/material-icons.css';
import CKEditor from 'ckeditor4-vue';
import '@/styles/global.scss';
import Antd from 'ant-design-vue';
import moment from 'moment';
moment.locale('ja');

declare global {
  interface Window { app: Vue; }
}
Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueBroadcast);
Vue.use(vClickOutside);
Vue.use(CKEditor);

import '@common/bootstrap';
import '@common/Filters';
import AuthenticationUtils from '@common/AuthenticationUtils';
import App from './App.vue';

import router from './router';
import store from './store';
import { Roles } from './common/app.config';
import ApiHelper from 'api-helper';
import Component from 'vue-class-component';

const i18n = window.i18n;

router.beforeEach(async (to, _from, next) => {
  const isAuthenticated = AuthenticationUtils.isAuthenticated();
  const { meta } = to;

  if (meta?.auth && !isAuthenticated) {
    return next({ path: `/login?destination=${to.path}` });
  }

  if (meta?.guest && isAuthenticated) {
    return next({ path: '/' });
  }

  const authorize = meta?.authorize;
  const currentUser = AuthenticationUtils.getCurrentUser();

  const isHasLicense =
    !!currentUser.license && (!!currentUser.license.license_code || !!currentUser.license.license_file);

  if (to.name === 'license.upload') {
    if (currentUser.role === Roles.doctor && !isHasLicense) {
      // ApiHelper.getApi('DoctorApi').getDetail(currentUser.id, {
      //   with: 'license',
      // })
      ApiHelper.getApi('UserApi').getInfo()
      .then((data) => {
        if (data.license && (data.license.license_code || data.license.license_file)) {
          return next({ path: '/' });
        } else {
          return next();
        }
      });
    }
  }

  if (authorize && authorize.length && !authorize.includes(currentUser.role)) {
    return next({ path: '/401' });
  }

  return next();
});

window.app = new Vue({
  i18n,
  router,
  store,
  render: (h: any) => h(App),
  async created() {
    if (this.$router.currentRoute.name === 'resv.ended') {
      return;
    }
    const isAuthenticated = AuthenticationUtils.isAuthenticated();
    // this.$store.commit('locale/updateLocale', i18n.locale);
    // this.$store.dispatch('locale/fetchLocalizationData');
    if (isAuthenticated) {
      const accessToken = AuthenticationUtils.getAccessToken();
      const currentUser = AuthenticationUtils.getCurrentUser();

      this.$store.commit('auth/updateIsAuthenticated', isAuthenticated);
      this.$store.commit('auth/updateAccessToken', accessToken);
      this.$store.commit('auth/updateCurrentUser', currentUser);
    }
  },
}).$mount('#app');
