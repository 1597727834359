export const QUESTION_TYPES = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

export const QUESTION_TYPE_OPTIONS = [
  { name: 'テキスト（シングル）', value: QUESTION_TYPES.INPUT },
  { name: 'テキスト（複数行）', value: QUESTION_TYPES.TEXTAREA },
  { name: 'チェックボックス', value: QUESTION_TYPES.CHECKBOX },
  { name: 'ラジオボタン', value: QUESTION_TYPES.RADIO },
];

export const PREFIX_QUESTION_LOCAL_ID = 'question_';
