import { Roles } from '@common/app.config';
import ListQuestion from '@/views/clinic/question/question.vue';
import EditQuestion from '@/views/clinic/question/edit.vue';
import CreateQuestion from '@/views/clinic/question/create.vue';
import Index from '@/views/medical_menu/Index.vue';

const authMeta =  {
  auth: true,
  authorize: [Roles.superAdmin],
};

export default [
  {
    path: '/medical-menu',
    name: 'medical_menu',
    component: Index,
    meta: authMeta,
  },
  {
    path: '/medical-menu/:menuId/questions',
    name: 'menu.question',
    component: ListQuestion,
    meta: authMeta,
  },
  {
    path: '/medical-menu/:menuId/questions/edit',
    name: 'menu.question.edit',
    component: EditQuestion,
    meta: authMeta,
  },
  {
    path: '/medical-menu/:menuId/questions/create',
    name: 'menu.question.create',
    component: CreateQuestion,
    meta: authMeta,
  },
];
