



















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { formatDateTime } from '@/common/app.config';
import Toast from '@common/helpers/toast';
import PageLoader from '@/components/common/PageLoader.vue';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import moment from 'moment';

@Component({
  components: {
    PageLoader,
    Pagination,
  },
})
export default class ListTopPosts extends Vue {
  private isLoading: boolean = false;
  private listNews: any[] = [];
  private page: number = 1;
  private pageCount: number = 1;

  private created() {
    this.getNews();
  }

  private getDateText(value, isUpdated = false) {
    if (!value) { return ''; }
    if (isUpdated) {
      return moment(value).format(formatDateTime);
    }
    const date = new Date(`${value}Z`);
    return moment(date).format(formatDateTime);
  }

  private pageChanged(page) {
    this.page = page;
    this.listNews = [];
    this.getNews();
  }

  private async getNews() {
    try {
      this.isLoading = true;
      const data = await ApiHelper.getApi('NewsApi').getList({
        page: this.page,
        per_page: 10,
        order: '-published_at,-id',
      });
      this.listNews = data.data;
      this.pageCount = data.last_page;
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
