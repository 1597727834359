















































































































































































































import ApiHelper from 'api-helper';
import { Vue, Component } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import PageLoader from '@components/common/PageLoader.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@components/common/Pagination.vue';
import LocalStorageUtils from '@common/LocalStorageUtils';
import {Member} from '@/models/member';
import BasicSelect from '@components/common/BasicSelect.vue';
import {CLINIC_TYPE_DISPLAY, ROLE_LIST, Roles, CType} from '@common/app.config';
import ContentLayout from '@components/layouts/Content.vue';
import memberIcon from '@/assets/img/memberIcon.svg';
import PopupAddClinic from '@/components/common/PopupAddClinic.vue';
import _ from 'lodash';

@Component({
  components: {
    PageLoader,
    Pagination,
    BasicSelect,
    PopupAddClinic,
    ContentLayout,
  },
  data() {
    return {
      iconHeader: memberIcon,
    };
  },
  filters: {
    paymentType: (paymentType) => {
      return paymentType == 1 ? '定期' : '単品';
    },
    status: (isShow) => {
      return isShow ? '表示' : '表示しない';
    },
  },
})
export default class HomePlan extends Vue {

  get queryParams() {
    return {
      ...this.paginate,
    };
  }

  get isEmptyDataPlan(): boolean {
    return this.dataMembers && !this.dataMembers.length;
  }

  private get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  private get computedForm() {
    return this.$refs.inviteForm as any;
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  dataMembers: Member[] = [];
  isLoading = false;
  totalPage = 1;

  paginate = {
    page: 1,
    per_page: 10,
  };
  private page = 1;
  private perPage = 10;
  private roleListOption = ROLE_LIST;
  private clinicType = CLINIC_TYPE_DISPLAY;
  private searchRole = '';
  private submitting = false;
  private clinicId = '';
  private clinicName = '-';
  private clinic: any = {};

  private searchForm = {
    id: '',
    name: '',
    name_kana: '',
    role: '',
    clinic_name: '',
    per_page: this.perPage,
    page: this.page,
  };

  private inviteForm = {
    email: '',
    role: '',
    clinic_id: this.clinicId,
  };

  mounted() {
    this.getMemberList();
    // this.getClinicInfo();
  }

  beforeOpen() {
    document.documentElement.style.overflow = 'hidden';
  }

  beforeClose() {
    document.documentElement.style.overflow = 'auto';
  }

  created() {
    library.add(faTrashAlt);
    library.add(faPlusCircle);
    dom.watch();
  }

  setClinic(clinic) {
    this.clinic = clinic;
    this.clinicName = clinic.name;
    this.inviteForm.clinic_id = clinic.id;
    this.hiddenPopup('popup-add-clinic');
    return;
  }

  private get roleDisplayOption() {
    if (this.currentUser.role === Roles.superAdmin) {
      return this.roleListOption;
    }
    return [
      { name: 'Admin', value: Roles.admin },
      { name: 'Doctor', value: Roles.doctor },
      { name: 'Pharmacist', value: Roles.pharmacist },
      { name: 'CS', value: Roles.customerService },
    ];
  }

  private hiddenPopup(name) {
    this.$modal.hide(name);
  }

  private async changeRole(val, id) {
    this.isLoading = true;
    this.dataMembers = [];
    await ApiHelper.getApi('MemberApi')
        .changeRole({id, role: val})
        .then(() => {
          Toast.success('ロールを更新しました。');
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        })
        .finally(() => {
          console.log();
        });

    await this.getMemberList();
    this.isLoading = false;
  }

  private searchMembersByRole() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify({...this.searchForm, role: this.searchRole }));
    this.page = 1;
    this.searchForm.page = 1;
    this.searchForm.role = this.searchRole;
    this.paginate.page = 1;
    this.getMemberList();
  }

  private searchMembers() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));
    this.page = 1;
    this.searchForm.page = 1;
    this.getMemberList();
  }

  private getClinicName(clinic): string {
    if (clinic && clinic[0] && clinic[0].name) {
      return clinic[0].name;
    } else {
      return '-';
    }
  }

  private getRoleListOption() {
    const roleList = [
      { name: 'Admin', value: Roles.admin },
    ];

    // if (this.currentUser.role === Roles.admin && this.currentUser.clinics[0] && this.currentUser.clinics[0].type === CType.clinicDoctor) {
    //   roleList.push({ name: 'Doctor', value: Roles.doctor });
    // }

    if (this.currentUser.role === Roles.superAdmin) {
      roleList.push({ name: 'CS', value: Roles.customerService });
      roleList.push({ name: 'システムAdmin', value: Roles.superAdmin });
      roleList.push({ name: 'カウンセラー', value: Roles.pharmacist });
      roleList.push({ name: 'Delivery', value: Roles.delivery });
    }

    return roleList;
  }

  private showOptionClinic() {
    return this.currentUser.role === Roles.superAdmin && !(
      this.inviteForm.role === Roles.pharmacist
      || this.inviteForm.role === Roles.superAdmin
      || this.inviteForm.role === Roles.customerService
      || this.inviteForm.role === Roles.delivery
    );
  }

  // private async getClinicInfo() {
  //   if (this.currentUser.clinic && this.currentUser.clinic[0]) {
  //     this.clinicName = this.currentUser.clinic[0].name;
  //     this.clinicId = this.currentUser.clinic[0].id;
  //   }
  // }

  private async getMemberList() {
    this.dataMembers = [];

    this.isLoading = true;

    await ApiHelper.getApi('MemberApi')
        .fetchMemberList({...this.searchForm, ...this.paginate})
        .then((response: any) => {
          this.totalPage = response.last_page;
          this.dataMembers = response.data;
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

  private pageChanged(page) {
    this.paginate = {
      ...this.paginate,
      page,
    };
    this.getMemberList();
  }

  private resetForm() {
    this.clinicName = '-';
    this.inviteForm = {
      email: '',
      role: '',
      clinic_id: this.clinicId,
    };
  }

  private cancelModal() {
    this.resetForm();
    this.$modal.hide('inviteModal');
  }

  private sendInvite(invalid) {
    if (invalid) {
      (this.$refs.inviteForm as any).validate();
      return;
    }
    if (!this.showOptionClinic()) {
      delete this.inviteForm.clinic_id;
    }

    if (this.currentUser.role === Roles.admin && this.inviteForm.role == Roles.admin) {
      this.inviteForm.clinic_id = _.get(this.currentUser, 'clinics[0].id', '');
    }

    this.submitting = true;
    ApiHelper.getApi('MemberApi')
      .sendInvite(this.inviteForm)
      .then((response: any) => {
        this.resetForm();
        this.$modal.hide('inviteModal');
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);

        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
        }
      })
      .finally(() => {
        this.submitting = false;
      });
    }

  private showPopup(event, name) {
    event.preventDefault();
    event.stopPropagation();

    this.$modal.show(name);
  }
}
