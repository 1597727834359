















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import axios from 'axios';
import { config } from '@common/app.config';
import lodash from 'lodash';
import imageDefault from '@/assets/img/icon-no-image.svg';

@Component
export default class ImagePrivate extends Vue {

  private get noImage() {
    return !lodash.includes(this.path, 'icon-no-image') && !lodash.includes(this.path, 'ui-avatars.com');
  }
  private get imageDefault() {
    return this.avatarDefault || imageDefault;
  }
  @Prop() readonly path!: any | string | File;
  @Prop(String) readonly alt!: string;
  @Prop(String) readonly styles!: string;
  @Prop(String) readonly avatarDefault!: string;

  private src = '';

  async loadImage() {
    let imageurl;
    const url = `${config.apiUrl}/api/managers/get-image?path=${this.path}`;
    if (!this.src && !lodash.includes(this.path, 'https:') && !lodash.includes(this.path, 'icon-no-image') && typeof this.path === 'string') {
      imageurl = await axios
        .get(url, {
          responseType: 'arraybuffer',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*',
          },
        })
        .then((response) => {
          const image = btoa(
              new Uint8Array(response.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), ''),
          );
          return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
    this.src = imageurl || this.avatarDefault;
    this.$emit('srcLoaded', this.src);
  }

  private mounted() {
    this.loadImage();
  }

  @Watch('path')
  private onPathChanged(val, oldVal) {
    this.src = '';
    this.loadImage();
  }
}
