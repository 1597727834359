








































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import { MEDICAL_MENU_TYPE, MEDICAL_MENU_STATUS } from '@common/app.config';
import BasicSelect from '@components/common/BasicSelect.vue';

@Component({
  components: {
    BasicSelect,
  },
})
export default class MedicalMenuSearch extends Vue {
  private typeList = MEDICAL_MENU_TYPE;
  private statusList = MEDICAL_MENU_STATUS;
  private searchParams: object = {
    menu_id: null,
    menu_name: null,
    menu_type: null,
    menu_status: null,
  };

  private onSearch() {
  for (const key in this.searchParams) {
    if (this.searchParams[key] === '' || this.searchParams[key] === []) {
      this.searchParams[key] = null;
    }
  }
  this.$emit('search', {...this.searchParams });
  }
}
