import BaseApi from './BaseApi';

class SalesManagementApi extends BaseApi {

  public index(params: any) {
    return this.get('/revenues', params);
  }

  public show(clinic_id: any, params: any) {
    return this.get(`/revenues/${clinic_id}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers';
  }
}

export default SalesManagementApi;
