

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import imageDefault from '@/assets/img/icon-no-image.svg';

@Component
export default class BaseImage extends Vue {
  @Prop([String, File]) imageUrl!: any;

  isLoading: boolean = true;
  error: boolean = false;
  count: number = 0;

  get imageDefault() {
    return imageDefault;
  }

  get imageSrc() {
    return this.error ? imageDefault : this.imageUrl;
  }

  loaded() {
    this.isLoading = false;
  }

  handleError() {
    this.error = true;
  }

  @Watch('imageUrl')
  watchImageUrl(newValue, oldValue) {
    this.count = this.count + 1;
    if (newValue !== oldValue) {
      this.isLoading = true;
      this.error = false;
    }
  }
}
