
































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { DoctorModule } from '@/store';
import { Doctor,  Clinic, DataForm, ModelDoctorDetail, defaultDataFormEdit } from '@/models/doctors';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import DowloadImage from '@/components/common/DowloadImage.vue';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';

import { Roles } from '@/common/app.config';
import UploadImage from '@/components/common/UploadImage.vue';
import TableDoctorForm from '@components/Doctor/TableDoctorForm.vue';
import TrRow from '@/components/common/TrRow.vue';

@Component({
  components: {
    UploadImage,
    TableDoctorForm,
    PopupConfirm,
    TrRow,
    DowloadImage,
  },
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
})
export default class HomeEdit extends Vue {
  private isSubmit: boolean = false;
  private dataForm: DataForm = JSON.parse(JSON.stringify(defaultDataFormEdit));
  private dataFormTmp: DataForm = JSON.parse(JSON.stringify(defaultDataFormEdit));
  private typeUpdate: number = 2;
  private currentUser: any;
  private doctorImage: string | Blob = '';
  private isChangeImage = false;
  private urlPathLicense = '';
  private urlPathAvatar = '';

  private created() {
    this.getDoctorDetail();
  }

  // Computed
  private get computedForm() {
    return this.$refs.doctorEdit as any;
  }

  private get dataDoctorDetail() {
    return DoctorModule.dataDoctorDetail;
  }

  private get roles() {
    return Roles;
  }

  private get isEditClinic() {
    return this.roles.clinic !== this.currentUser.role;
  }

  private get isEdit() {
    return JSON.stringify(this.dataForm) === JSON.stringify(this.dataFormTmp);
  }

  private onChangeDoctorImage(file) {
    this.isChangeImage = true;
    this.doctorImage = file;
  }

  // Methods
  private async getDoctorDetail() {
    try {
      const { id } = this.$route.params;
      const params = { with: 'clinics,license' };
      const data = {
        id,
        params,
      };
      const doctorDetailResponse =  await DoctorModule.getDoctorDetail(data);
      this.urlPathLicense = doctorDetailResponse.license && doctorDetailResponse.license.license_file || '';
      this.urlPathAvatar = doctorDetailResponse.avatar || '';
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  private updateForm(field: string, value: string | Clinic[] | Doctor) {
    this.$set(this.dataForm, field, value);
  }

  private async openPopupComfirm() {
    if (this.isChangeImage) {
      this.$modal.show('confirmUpdateDoctor');
      return;
    }
    if (this.isEdit) {
      return;
    }
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    this.$modal.show('confirmUpdateDoctor');
  }

  private cancel() {
    this.$router.push({ name: 'doctors'});
  }

  private cancelPopupConfirm() {
    this.$modal.hide('confirmUpdateDoctor');
  }

  private async updateImageDoctorDetail(value) {
    try {
      this.isSubmit = true;
      this.typeUpdate = 1;
      const data = {
        id: this.dataDoctorDetail.id,
        body: {
          avatar: value,
        },
      };
      await DoctorModule.updateImageDoctorDetail(data);
      this.isSubmit = false;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const result = event.target.result;
        this.$set(this.dataForm, 'avatar', result);
      };
      reader.readAsDataURL(value);
    } catch (error) {
      if (error.response) {
        this.isSubmit = false;
        this.cancelPopupConfirm();
        const { data, status } = error.response;
        if (status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  private async updateDoctorImage() {
    const formData = new FormData();
    formData.append('license_file', this.doctorImage);
    await ApiHelper.getApi('DoctorApi').uploadLicense(this.dataDoctorDetail.id, formData);
  }

  private async updateDoctor() {
    try {
      if (this.isChangeImage) {
        this.updateDoctorImage();
      }
      this.isSubmit = true;
      this.typeUpdate = 2;
      await DoctorModule.updateDoctorDetail(this.dataForm);
      this.isSubmit = false;
      Toast.success('更新しました');
      this.getDoctorDetail();
      this.cancelPopupConfirm();
    } catch (error) {
      if (error.response) {
        this.isSubmit = false;
        if (error.response.data.errors.license_code) {
          Toast.error('医籍登録番号は既に存在しています');
        }
        this.cancelPopupConfirm();
        const { data, status } = error.response;
        if (status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }
  private mounted() {
    window.scrollTo(0, 0);
  }

  private get licenseCode() {
    if (this.dataDoctorDetail.license && this.dataDoctorDetail.license.license_code) {
      return this.dataDoctorDetail.license.license_code;
    }
    return  '';
  }

  private get licenseImage() {
    if (this.dataDoctorDetail.license && this.dataDoctorDetail.license.license_url) {
      return this.dataDoctorDetail.license.license_url;
    }
    return  '';
  }

  private get licenseFile() {
    if (this.dataDoctorDetail.license && this.dataDoctorDetail.license.license_file) {
      return this.dataDoctorDetail.license.license_file;
    }
    return  '';
  }

  @Watch('dataDoctorDetail')
  private onDataDoctorDetail(val: ModelDoctorDetail, oldVal: ModelDoctorDetail) {
    if (this.typeUpdate === 1) {
      this.$set(this.dataForm, 'avatar', val.avatar_url);
    }
    if (this.typeUpdate === 2) {
      const mapData: DataForm = {
        id: val.id,
        doctor_name: val.name,
        doctor_name_kana: val.name_kana,
        doctor: {
          id: null,
          name: '',
          name_kana: '',
        },
        clinic: val.clinics && val.clinics.map((x) => ({
          id: x.id,
          name: x.name,
          name_kana: x.name_kana,
        })),
        doctor_phone: val.phone,
        doctor_email: val.email,
        avatar: val.avatar_url,
        created_at: val.created_at,
        updated_at: val.updated_at,
        license_code: this.licenseCode,
      };
      this.dataForm = {...mapData};
      this.dataFormTmp = {...mapData};
    }
  }
}
