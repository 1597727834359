





























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import PharmacistItem from './PharmacistItem.vue';
import Pagination from '../common/Pagination.vue';
import ApiHelper from 'api-helper';
import { Roles } from '@/common/app.config';
import PageLoader from '@components/common/PageLoader.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { State } from 'vuex-class';

@Component({
  components: {
    PharmacistItem,
    Pagination,
    PageLoader,
    PopupConfirm,
  },
})
export default class PharmacistList extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  @Prop() query!: object;

  private listPharmacists: any[] = [];
  private page = 1;
  private pageCount = 0;
  private isLoading: boolean = false;
  private totalAmount: number = 0;
  private pharmacistId: number | string = '';

  created() {
    this.getList();
  }

  @Watch('query', {deep: true})
  private onChangeQuery() {
    this.page = 1;
    this.getList();
  }

  private get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }

  private onPageChaned(page) {
    this.page = page;
    this.getList();
  }

  private hiddenPopupConfirm() {
    this.$modal.hide('delPharmacist');
  }

  private confirmDel(id) {
    this.$modal.show('delPharmacist');
    this.pharmacistId = id;
  }

  private async deletePharmacy() {
    if (!this.pharmacistId) {
      return ;
    }

    try {
      await ApiHelper.getApi('PharmacistApi').destroy(this.pharmacistId);
      Toast.success('削除しました');
      this.page = 1;
      this.getList();
      this.$modal.hide('delPharmacist');
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      const data = error.response.data;
      if (error.response.status === 422) {
        return;
      }

      Toast.error(data.message);
    }
  }

  private async getList() {
    try {
      this.listPharmacists = [];
      this.isLoading = true;
      const response = await ApiHelper.getApi('PharmacistApi').getList({
        with: 'pharmacistStores',
        page: this.page,
        per_page: 10,
        ...this.query,
      });
      this.isLoading = false;
      this.listPharmacists = response.data;
      this.totalAmount = Number(response.total_amount);
      this.pageCount = response.last_page;
    } catch (error) {
      this.isLoading = false;
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    }
  }
}
