import { Roles } from './../../../common/app.config';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import AuthenticationUtils from '@common/AuthenticationUtils';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';

@Module({ name: 'auth', namespaced: true })
export default class Authentication extends VuexModule {
  public isAuthenticated = false;
  public accesToken: string = '';
  public currentUser: any = {};
  public isGettingUserInfo = false;
  public unreadMessageCount = 0;
  public isLoading = false;

  @Mutation
  public updateLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public updateIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  @Mutation
  public updateAccessToken(accesToken: string) {
    this.accesToken = accesToken;
  }

  @Mutation
  public updateCurrentUser(currentUser: any) {
    // assign role for current user.
    if (currentUser) {
      currentUser.isSuperAdminRole = currentUser.role === Roles.superAdmin;
      currentUser.isAdminRole = currentUser.role === Roles.admin;
      currentUser.isCSRole = currentUser.role === Roles.customerService;
      currentUser.isDoctorRole = currentUser.role === Roles.doctor;
      currentUser.isPharmacistRole = currentUser.role === Roles.pharmacist;
      currentUser.isDeliveryRole = currentUser.role === Roles.delivery;
    }
    this.currentUser = currentUser;
  }

  @Mutation
  public updateIsGettingUserInfo(isGettingUserInfo: any) {
    this.isGettingUserInfo = isGettingUserInfo;
  }

  @Mutation
  public updateUnreadMessageCount(unreadMessageCount: number) {
    this.unreadMessageCount = unreadMessageCount;
  }

  @Action({ rawError: true })
  public async updateUser(currentUser: any) {
    const { commit } = this.context;
    commit('updateCurrentUser', currentUser);
  }

  @Action({rawError: true})
  public async logout() {
    const { commit } = this.context;
    try {
      commit('updateLoading', true);
      await ApiHelper.getApi('UserApi').logout();
      commit('updateCurrentUser', null);
      commit('updateAccessToken', '');
      AuthenticationUtils.removeAuthenticationData();
    } catch (error) {
      Toast.error(error.response.data.message);
    } finally {
      commit('updateLoading', false);
    }
  }
}
