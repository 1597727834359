var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-wrapper"},[_c('div',{staticClass:"search-channel d-flex"},[_c('multiselect',{class:'multi-select',attrs:{"options":_vm.searchOptions,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"ステータス確認","label":"name","track-by":"name","preselect-first":true,"selectedLabel":'',"deselectLabel":'',"selectLabel":'',"readonly":true},on:{"input":_vm.debouncedSearch},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+"つ選択済み")]):_vm._e()]}}]),model:{value:(_vm.searchOption),callback:function ($$v) {_vm.searchOption=$$v},expression:"searchOption"}}),_c('BasicSelect',{staticClass:"search-option w-50 channel-select",attrs:{"searchable":false,"clearable":false,"label":'name',"reduce":function (type) { return type.value; },"options":_vm.sortOptions,"isChannel":true},on:{"input":_vm.debouncedSearch},model:{value:(_vm.sortOption),callback:function ($$v) {_vm.sortOption=$$v},expression:"sortOption"}})],1),_c('div',{staticClass:"search-channel"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.query),expression:"query",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"type":"text","name":"id_or_name","placeholder":'キーワード'},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.query=$event.target.value.trim()},_vm.debouncedSearch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"channel-list"},[_vm._l((_vm.channels),function(channel,index){return (_vm.isChannelUserHasState(channel))?_c('router-link',{key:("channel-" + index + "s"),attrs:{"to":("/messages/" + (channel.id) + "?" + (_vm.makeQueryParams()))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"channel-item",class:{'active': !!_vm.currentChannel && isActive, 'is-not-last': (index + 1) != _vm.channels.length, 'disable': !channel.status && !isActive && !!_vm.currentChannel}},[_c('ChannelItem',{key:channel.id,attrs:{"channel":channel},on:{"change":_vm.onChange}})],1)])]}}],null,true)}):_vm._e()}),(!_vm.isLoading && _vm.channels && _vm.channels.length === 0 && !_vm.query)?_c('div',{staticClass:"text-center my-2 empty-channel-text"},[_vm._v(" 患者とのチャットルームがありません ")]):_vm._e(),(!_vm.isLoading && _vm.channels && _vm.channels.length === 0 && _vm.query)?_c('div',{staticClass:"text-center mt-2"},[_vm._v(" データが存在しません ")]):_vm._e(),_c('InfiniteLoading',{ref:"infiniteLoading",on:{"infinite":_vm.getListChannel}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"})])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }