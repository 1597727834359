












import Defaulticon from '@/assets/img/clinicListicon.svg';
import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class ContentLayout extends Vue {
@Prop({
    type: String,
    default: Defaulticon,
}) readonly icon: string;
@Prop({
    default: 'Name',
}) readonly name: string;
}
