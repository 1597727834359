import { render, staticRenderFns } from "./PopupNote.vue?vue&type=template&id=dff1858e&scoped=true&"
import script from "./PopupNote.vue?vue&type=script&lang=ts&"
export * from "./PopupNote.vue?vue&type=script&lang=ts&"
import style0 from "./PopupNote.vue?vue&type=style&index=0&id=dff1858e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff1858e",
  null
  
)

export default component.exports