import BaseApi from './BaseApi';

class ReservationDoctorAPI extends BaseApi {
    public list(params: any) {
        return this.get('/', params);
    }

    protected getSpecificApiPrefix(): string {
        return 'managers/reservations-doctor';
    }
}

export default ReservationDoctorAPI;
