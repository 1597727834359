



































import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BankJPModule } from '@/store';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';

@Component
export default class SelectSearchBank extends Mixins(ClinicsMixin) {
  @Prop(String) readonly placeholder!: string;
  @Prop({default: ''}) readonly currentBank!: string;
  @Prop({default: true}) readonly isCreate!: boolean;
  private isChoosend = true;
  private search = '';

  private get dataBank() {
    return BankJPModule.dataBank;
  }

  private handleBlur() {
    this.$emit('notChoosendBank', this.search);
    this.isChoosend = true;
  }

  private focusInput() {
    this.getBank();
    this.isChoosend = false;
  }

  private choosenData(item) {
    this.$emit('setData', item);
    this.isChoosend = true;
    this.search = item.name;
  }

  private getBank() {
    if (this.search) {
      const param = {
        name: this.search,
      };

      BankJPModule.listBank(param);
    } else {
      BankJPModule.listBank();
    }
  }

  @Watch('search')
  private searchBank() {
    if (!this.search) {
      this.$emit('hasResultBank', false);
    }

    if (!this.isChoosend) {
      this.getBank();
    }
  }

  @Watch('currentBank')
  private wsearch() {
    this.search = this.currentBank;
  }

  @Watch('dataBank')
  private wdataBank() {
    if (this.dataBank.length) {
      this.$emit('hasResultBank', true);
    } else {
      this.$emit('hasResultBank', false);
    }
  }
}
