













































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import Pagination from '@components/common/Pagination.vue';
import LocalStorageUtils from '@common/LocalStorageUtils';
import lodash from 'lodash';

@Component({
  components: {
    Pagination,
    PageLoader,
  },
})
export default class PopupAddMedicine extends Vue {
  @Prop({ default: {} }) readonly medicineSelected!: any;
  get queryParams() {
    return {
      ...this.paginate,
      with: 'order,user,order.plan,order.plan.medicines',
      ...this.query,
    };
  }
  get initialQuery() {
    return {
      ...this.paginate,
      with: 'medicines,license',
    };
  }

  get isEmptyDataMedicine(): boolean {
    return this.listMedicines && !this.listMedicines.length;
  }
  paginate = {
    page: this.$route.query.page || 1,
    per_page: 10,
  };
  totalPage = 1;
  private listMedicines = [];
  private activeMedicines: any[] = [];
  private isLoading: boolean = false;

  private query = {
    freeword: this.$route.query.freeword || '',
  };

  @Watch('medicineSelected')
  getMedicineSelected() {
    this.activeMedicines = this.medicineSelected || [];
  }

  remoteParamSearch() {
    if (LocalStorageUtils.getItem('doctorDetailSearchMedicine')) {
      LocalStorageUtils.removeItem('doctorDetailSearchMedicine');
    }
  }

  beforeOpen() {
    this.activeMedicines = this.medicineSelected || [];
    this.initDataDefault();
    this.getMedicinesList(this.initialQuery);
    this.remoteParamSearch();
  }

  initDataDefault() {
    // Clear data when close popup
    this.listMedicines = [];
    this.paginate = {
      page: 1,
      per_page: 10,
    };
    this.totalPage = 1;
    this.query = {
      freeword: '',
    };
  }

  cancelSetMedicine() {
    this.activeMedicines = [];
    this.initDataDefault();
    this.$emit('cancel');
  }

  getMedicinesList(query) {
    if (this.isLoading) {
      return;
    }
    this.listMedicines = [];
    this.isLoading = true;

    // ApiHelper.getApi('MedicineApi').list(changePage ? this.queryParams : this.initialQuery)
    ApiHelper.getApi('MedicineApi')
      .fetchMedicineList(query)
      .then((res: any) => {
        this.listMedicines = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private mounted() {
    this.remoteParamSearch();
  }

  private pageChanged(page) {
    let paramsSearch: any;
    this.paginate.page = page;

    if (LocalStorageUtils.getItem('doctorDetailSearchMedicine')) {
      paramsSearch = JSON.parse(
        LocalStorageUtils.getItem('doctorDetailSearchMedicine'),
      );
    } else {
      paramsSearch = Object.assign({}, this.initialQuery);
    }

    paramsSearch = {
      ...paramsSearch,
      ...this.paginate,
      page,
    };

    this.getMedicinesList(paramsSearch);
  }

  private isActiveMedicine(medicine) {
    return lodash.some(
      this.activeMedicines,
      (data) => lodash.get(data, 'id') === medicine.id,
    );
  }

  private handleSearch() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.paginate.page = 1;
    LocalStorageUtils.saveItem(
      'doctorDetailSearchMedicine',
      JSON.stringify(this.queryParams),
    );

    ApiHelper.getApi('MedicineApi')
      .fetchMedicineList(this.queryParams)
      .then((res: any) => {
        this.listMedicines = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private closePopup() {
    this.$emit('closeModal');
  }

  private setActiveMedicine(medicine, isInList) {
    // this.activeMedicine = medicine;
    let newMedicines = [...this.activeMedicines];
    if (isInList) {
      newMedicines = lodash.filter(
        newMedicines,
        (data) => data.id !== medicine.id,
      );
    } else {
      newMedicines = [...newMedicines, medicine];
    }
    this.activeMedicines = newMedicines;
  }

  private beforeClose() {
    this.paginate = {
      page: 1,
      per_page: 10,
    };
    this.totalPage = 1;
    this.listMedicines = [];
  }
}
