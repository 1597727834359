import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

@Module({ name: 'messageNotify', namespaced: true })
export default class MessageNotify extends VuexModule {
  public notifyIds: any[] = [];

  @Mutation
  public addId(id: string|number) {
    if (!this.notifyIds.includes(id)) {
      this.notifyIds.push(id);
    }
  }

  @Mutation
  public removeId(id: string|number) {
    const index = this.notifyIds.findIndex((i) => i === id);
    if (index > -1) {
      this.notifyIds.splice(index, 1);
    }
  }

  @Action({ rawError: true })
  public async updateNotifyId(id: any) {
    const { commit } = this.context;
    commit('addId', id);
  }

  @Action({ rawError: true })
  public async removeNotifyId(id: any) {
    const { commit } = this.context;
    commit('removeId', id);
  }

}
