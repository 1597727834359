import { render, staticRenderFns } from "./PharmacistUpdateModal.vue?vue&type=template&id=bbe74fb4&scoped=true&"
import script from "./PharmacistUpdateModal.vue?vue&type=script&lang=ts&"
export * from "./PharmacistUpdateModal.vue?vue&type=script&lang=ts&"
import style0 from "./PharmacistUpdateModal.vue?vue&type=style&index=0&id=bbe74fb4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbe74fb4",
  null
  
)

export default component.exports