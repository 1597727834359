


















































import { Vue, Component } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import PageLoader from '@components/common/PageLoader.vue';
import Toast from '@common/helpers/toast';
import { formatDateJp } from '@/common/app.config';

@Component({
  components: {
    PageLoader,
  },
})

export default class SubPatient extends Vue {
  private children = {};
  private isLoading: boolean = false;

  private async created() {
    this.patientDetail();
  }

  private get formatDateJp() {
    return formatDateJp;
  }

  private async patientDetail() {
    try {
      this.isLoading = true;
      const { id } = this.$route.params;
      const params: { with: string } = { with: 'parent,children' };
      const data = {
        id,
        params,
      };
      const response = await ApiHelper.getApi('PatientApi').getOne(data.id, data.params);
      this.children = response.children;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    }
  }
}
