import Edit from '@/views/doctor_profile/edit/Edit.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.doctor],
};

export default [
  {
    path: '/doctor_profile',
    name: 'doctor_profile',
    component: Edit,
    meta: authMeta,
  },
];
