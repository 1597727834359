







































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

// Components
import BasicSelect from '@/components/common/BasicSelect.vue';
import BaseInput from '@/components/common/BaseInput.vue';
import BaseTextarea from '@/components/common/BaseTextarea.vue';
import QuestionListItemGroup from '@/views/question/components/QuestionListItem/QuestionListItemGroup.vue';
import QuestionListItemOptions from '@/views/question/components/QuestionListItem/QuestionListItemOptions.vue';
import QuestionListItemAction from '@/views/question/components/QuestionListItem/QuestionListItemAction.vue';
// Constants
import {
  QUESTION_TYPE_OPTIONS,
  QUESTION_TYPES,
} from '@/views/question/constants';
// Icons
import iconTrash from '@/assets/img/icon-trash.svg';

@Component({
  components: {
    BasicSelect,
    BaseInput,
    BaseTextarea,
    QuestionListItemGroup,
    QuestionListItemOptions,
    QuestionListItemAction,
  },
})
export default class QuestionListItem extends Vue {
  @Prop(Object) question: any;
  @Prop([String, Number]) questionOptionEditting: string | number;
  @Prop(Boolean) readOnly: boolean;
  @Prop(Boolean) hiddenIconDrag: boolean;
  @Prop(Boolean) hiddenIconDelete: boolean;
  @Prop(Number) questionIndex: number;

  isSubmiting = false;
  isLoading: boolean = false;
  isEditQuestion: boolean = false;

  get iconTrash() {
    return iconTrash;
  }

  get typeOptions() {
    return QUESTION_TYPE_OPTIONS;
  }

  get isShowInputPlaceholder() {
    const { INPUT, TEXTAREA } = QUESTION_TYPES;
    return [INPUT, TEXTAREA].includes(this.question.type);
  }

  get isShowQuestionOptions() {
    const { CHECKBOX, RADIO } = QUESTION_TYPES;
    return [CHECKBOX, RADIO].includes(this.question.type);
  }

  get questionErrorMessages() {
    return {
      max: '質問は255文字以内で入力してください',
    };
  }

  get questionInputErrorMessages() {
    return {
      max: '質問は100文字以内で入力してください',
      numeric: '正の整数を入力してください',
    };
  }

  get isTextArea() {
    return this.question.type === QUESTION_TYPES.TEXTAREA;
  }

  questionValue(value) {
    let result = '';
    const stringCutLine = value.split('\\n');
    let stringCutWrongCharacter: any = [];

    stringCutLine.filter((data) => data).map((data) => {
      stringCutWrongCharacter = [...stringCutWrongCharacter, data.replace(/\\/g, '')];
      return data;
    }).map((data, index) => {
      if (index === 0) {
        result = result + data;
      } else {
        result = result + '\n' + data;
      }
    });

    return result;
  }

  async created() {
    library.add(faCameraRetro);
    dom.watch();
  }
}
