import List from '@/views/diagnosis/List.vue';
import Create from '@/views/diagnosis/Create.vue';
import Detail from '@/views/diagnosis/Detail.vue';
import Precaution from '@/views/diagnosis/Precaution.vue';
import { authAdminDoctorClinic, authAdmin } from '@/common/constants/auth';

export default [
  {
    path: '/diagnosis',
    name: 'diagnosis',
    component: List,
    meta: authAdminDoctorClinic,
  },
  {
    path: '/diagnosis/create',
    name: 'diagnosis.create',
    component: Create,
    meta: authAdmin,
  },
  {
    path: '/diagnosis/detail/:id',
    name: 'diagnosis.detail',
    component: Detail,
    meta: authAdminDoctorClinic,
  },
  {
    path: '/diagnosis/detail/:id/precaution',
    name: 'diagnosis.precaution',
    component: Precaution,
    meta: authAdminDoctorClinic,
  },
];
