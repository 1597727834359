import BaseApi from '../BaseApi';

class PlanApi extends BaseApi {
  /** Example "api/v2/managers/users/{userId}/plans" */
  protected getSpecificApiPrefix(): string {
    return 'v2/managers/users/';
  }
}

export default PlanApi;
