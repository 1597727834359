import NotFound404 from '@/views/errors/404.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: Object.keys(Roles).map((key) => Roles[key]),
};


export default [
  {
    path: '/404',
    name: 'PageNotFound',
    component: NotFound404,
    meta: authMeta,
  },
];
