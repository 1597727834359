
































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import MedicalMenuItem from './MedicalMenuItem.vue';
import Pagination from '../common/Pagination.vue';
import PopupMedicalItemDetail from './PopupMedicalMenuDetail.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';

import MedicalMenuApi from '../../api/MedicalMenuApi';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import { Roles } from '@common/app.config';
import PageLoader from '@components/common/PageLoader.vue';

@Component({
  components: {
    MedicalMenuItem,
    Pagination,
    PopupMedicalItemDetail,
    PageLoader,
    PopupConfirm,
  },
})
export default class MedicalMenuList extends Vue {
  @Prop() query!: object;
  @State((state) => state.auth.currentUser) currentUser!: any;
  listMenus: any[] = [];
  medicalMenuId: number;
  page = 1;
  pageCount = 0;
  isLoading: boolean = false;
  isSubmitting: boolean = false;

  get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  created() {
    window.onfocus = () => {
      if (localStorage.getItem('isReload') === 'true') {
        this.getList();
      }
    };
    this.getList();
  }


  closeModal() {
    this.$modal.hide('medical-menu-delete');
  }

  onShowDetail(medicalMenu) {
    this.$modal.show('medical-menu-detail', { item: medicalMenu });
  }

  onShowDelete(medicalMenu) {
    this.medicalMenuId = medicalMenu.id;
    this.$modal.show('medical-menu-delete');
  }

  createMenu() {
    this.$modal.show('medical-menu-detail', { isCreate: true });
  }

  onPageChaned(page) {
    this.page = page;
    this.getList();
  }

  async getList() {
    localStorage.setItem('isReload', 'false');
    try {
      this.listMenus = [];
      this.isLoading = true;
      const response = await ApiHelper.getApi('MedicalMenuApi').getList({
        page: this.page,
        per_page: 10,
        ...this.query,
        with: 'doctors',
      });
      this.isLoading = false;
      this.listMenus = response.data;
      this.pageCount = response.last_page;
    } catch {
      this.isLoading = false;
    }
  }

  confirmDeleteMenu() {
    this.isSubmitting = true;

    ApiHelper.getApi('MedicalMenuApi')
      .delete(this.medicalMenuId)
      .then((res: any) => {
        Toast.success('削除しました');
        this.getList();
        this.closeModal();
      })
      .catch((error) => {
        this.isSubmitting = false;
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  @Watch('query', {deep: true})
  private onChangeQuery() {
    this.page = 1;
    this.getList();
  }
}
