import { authSAdmin } from '@/common/constants/auth';
import Index from '@/views/sales_management/Index.vue';
import Show from '@/views/sales_management/SaleManagementDetail.vue';


export default [
  {
    path: 'sales-management',
    name: 'sales-management',
    component: Index,
    meta: authSAdmin,
  },
  {
    path: '/sales-management/show/:clinic_id',
    name: 'sales-management.show',
    component: Show,
    meta: authSAdmin,
  },
];
