












































































































































































import ApiHelper from 'api-helper';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ModelPatientDetail } from '@/models/patient';
import { PatientModule } from '@/store';
import Toast from '@common/helpers/toast';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import BasicSelect from '@/components/common/BasicSelect.vue';
import Tr from './Tr.vue';
import { mapState } from 'vuex';
import { Roles } from '@common/app.config';

@Component({
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  components: {
    Tr,
    BasicSelect,
    PopupConfirm,
  },
})

export default class PatientBasicInfo extends Vue {

  private get gender() {
    return this.dataPatientDetail.gender ? '男性' : '女性';
  }

  private get emailErrorMessage() {
    return {
      email: 'メールアドレスを正しく入力してください。',
      max: 'メールアドレスは30文字以内で入力してください',
    };
  }

  private get healthcareCodeErrorMessage() {
    return {
      max: 'カルテ番号は30文字以内で入力してください',
    };
  }

  private get insuranceCardErrorMessage() {
    return {
      max: '保険証番号は30文字以内で入力してください',
      numeric: '保険証は半角数字のみで入力してください',
    };
  }

  private get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }
  @Prop(Object) readonly dataPatientDetail!: ModelPatientDetail;
  private isEdit: boolean = false;
  private isSubmit: boolean = false;
  private email: string = '';
  private emailTmp: string = '';
  private isEditting: string | number = '';
  private doctorOptions: any[] = [];
  private currentUser!: any;

  mounted() {
    if (this.isAdmin) { this.fetchData(); }
  }

  private clinicEditActive(id) {
    return this.isEditting === id;
  }

  private changeToEdit(id) {
    this.isEditting = id;
  }

  private async updateHealthcare(clinic) {
    try {
      this.isSubmit = true;
      const { id } = this.$route.params;
      const data = {
        id,
        body: {
          clinic_id: clinic.id,
          healthcare_code: clinic.healthcare_code,
        },
      };

      await PatientModule.updateHealthcareRecords(data);
      this.isSubmit = false;
      this.isEdit = false;
      this.isEditting = '';
      Toast.success('更新しました');
    } catch (error) {
      this.isSubmit = false;
      if (error.response) {
        const { data, status } = error.response;
        Toast.error(data.message);
      }
    }
  }

  private async updateInsuranceNum() {
    const isValid = await (this.$refs.insurance_card_number as any).validate();
    if (!isValid) {
      return;
    }
    try {
      this.isSubmit = true;
      const { id } = this.$route.params;
      const data = {
        id,
        body: {
          insurance_card_number: (this.dataPatientDetail as any).insurance_card_number,
        },
      };

      await PatientModule.updateInsuranceCardNum(data);
      this.isSubmit = false;
      this.isEdit = false;
      Toast.success('更新しました');
    } catch (error) {
      this.isSubmit = false;
      if (error.response) {
        const { data, status } = error.response;
        if (data.errors && data.errors.insurance_card_number) {
          Toast.error(data.errors.insurance_card_number[0]);
          return;
        }
        if (status === 422) {
          (this.$refs.email as any).setErrors(data.errors);
          return;
        }
        Toast.error(error.message);
      }
    }
  }

  private async fetchData() {
    try {
      const result = await ApiHelper.getApi('DoctorApi').list();

      this.doctorOptions = result.data.map((item) => ({
        name: item.name,
        value: item.id,
      }));
    } catch (e) {
      Toast.error(e.response.data.message);
    }
  }

  @Watch('dataPatientDetail')
  private dataPatientDetailChanged(val: ModelPatientDetail) {
    this.email = val.email;
    this.emailTmp = val.email;
  }
}
