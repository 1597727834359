var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient-control patient-control-content patient-control-margin"},[_c('div',{staticClass:"treatment-info"},[_c('p',{staticClass:"treatment-info__name"},[_vm._v("診療科目："+_vm._s(_vm.treatmentName))]),_c('p',{staticClass:"treatment-info__interview-date"},[_vm._v(" 回答日："+_vm._s(_vm._f("format_time")(_vm.interviewDate,_vm.formatDateJp, "-"))+" ")])]),(_vm.patientAnswer && _vm.patientAnswer.length > 0)?_c('div',_vm._l((_vm.patientAnswer),function(data,index){return _c('div',{key:data.id,staticClass:"patient-control-question"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"patient-control-question-first"},[_vm._v("Q"+_vm._s(index + 1))]),_c('p',{staticClass:"patient-control-question-second pre-wrap"},[_vm._v(" "+_vm._s(data.question.question)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"patient-control-question-first question-answer"},[_vm._v("回答")]),(
            data.question.type === 'radio' ||
            data.question.type === 'checkbox'
          )?_c('div',{staticClass:"patient-control-question-second pre-wrap"},_vm._l((data.answer),function(ans,index){return _c('span',{key:index},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(ans.value))])])}),0):_vm._e(),(
            data.question.type === 'textarea' ||
            data.question.type === 'input'
          )?_c('div',{staticClass:"patient-control-question-second pre-wrap"},[_c('div',[_c('div',{staticClass:"patient-control-question-second pre-wrap"},_vm._l((data.answer),function(ans){return _c('span',{key:ans.id},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(ans.value))])])}),0)])]):_vm._e()])])}),0):_vm._e(),(_vm.currentUser.isDoctorRole || _vm.currentUser.isPharmacistRole)?_c('div',{staticClass:"patient-control-question-footer"},[(_vm.currentUser.isDoctorRole)?_c('div',[_c('p',{staticClass:"text-center patient-control-title mt-2"},[_vm._v(_vm._s(_vm.stateNotice))]),_c('div',{staticClass:"patient-control-button-status"},[_c('button',{staticClass:"btn btn-info btn-w-166 btn-md",attrs:{"disabled":_vm.isChangeState},on:{"click":function () {
              if (_vm.patientReserved) {
                _vm.onChangeUserStatus(_vm.USER_TREATMENT_STATE.CONFIRMED_RESERVATION);
              } else {
                _vm.onChangeUserStatus(_vm.USER_TREATMENT_STATE.INTERVIEW_COMPLETED);
              }
            }}},[_vm._v(" 問診票確認完了 ")])])]):_vm._e(),_c('a-row',{staticStyle:{"margin-top":"20px"},attrs:{"type":"flex","justify":"center","align":"middle"}},_vm._l((_vm.activeTreatment.images),function(image){return _c('a-col',{key:image.id,staticClass:"upload-image",staticStyle:{"padding":"10px"},attrs:{"span":12}},[(
            image.mime_type === 'video/quicktime' ||
            image.mime_type === 'video/mp4'
          )?_c('video',{staticStyle:{"margin":"auto"},attrs:{"controls":"","height":"200px"}},[_c('source',{attrs:{"src":image.s3_url,"type":"video/mp4"}})]):_c('img',{staticStyle:{"display":"block","margin":"auto"},attrs:{"height":"200px","src":image.s3_url}})])}),1)],1):_vm._e(),_c('PrescriptionPopup',{attrs:{"current-channel-user":_vm.currentPatient,"changeState":_vm.changeState},on:{"submit":_vm.handleWhenCreateFeedback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }