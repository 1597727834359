import BaseApi from './BaseApi';

class DoctorDetailApi extends BaseApi {
  public updateDoctorInfo(id, params: any) {
    return this.post(`/${id}/update-info`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/doctor';
  }
}

export default DoctorDetailApi;
