







































































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Clinic, DataForm } from '@/models/doctors';
import CustomErrorMessage from '@/common/mixins/CustomErrorMessage';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import PopupClinicList from './PopupClinicList.vue';
import PopupDoctorList from './PopupDoctorList.vue';
import TrRow from '@/components/common/TrRow.vue';
import PopupAddClinic from '@/components/common/PopupAddClinic.vue';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import { Roles } from '@common/app.config';

@Component({
  components: {
    PopupClinicList,
    PopupDoctorList,
    TrRow,
    PopupAddClinic,
    DragDropFileUpload,
  },
  mixins: [CustomErrorMessage],
})
export default class TableDoctorForm extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop(Object) readonly dataForm!: DataForm;
  @Prop(String) readonly title!: string;
  @Prop({ default: true }) readonly isShowFieldPassword!: boolean;
  @Prop({ default: true }) readonly isEditClinic!: boolean;
  @Prop({ default: false }) readonly isDisabledCircled!: boolean;
  @Prop({ default: false }) readonly isPermission!: boolean;

  roles = Roles;

  private get isAdmin() {
    return (
      this.currentUser.role === Roles.superAdmin ||
      this.currentUser.role === Roles.admin
    );
  }

  private clinic: any = {};

  private changeValue(value) {
    this.dataForm.avatar_url = value;
    if (value === '') {
      this.dataForm.avatar = value;
    }
  }
  // Computed
  // private get clinics() {
  //   const data = this.dataForm && this.dataForm.clinic;
  //   if (this.isEditClinic && ( !data.length || data && data.length < 5)) {
  //     return [...data, { id: null, name: '', name_kana: '' }];
  //   }
  //   return data;
  // }

  // Emit
  @Emit('updateForm')
  private updateForm(field: string, value: string | number) {
    return {
      field,
      value,
    };
  }

  @Emit('updateForm')
  private onChooseDoctor(doctor) {
    this.closeModal('popupDoctor');
    return {
      field: 'doctor',
      value: doctor,
    };
  }

  @Emit('updateForm')
  private onChooseClinic(clinic: any) {
    this.hiddenPopup('popup-add-clinic');
    return {
      field: 'clinic',
      value: clinic,
    };
  }

  @Emit('updateForm')
  private onDeleteDoctor() {
    const field = 'doctor';
    const value = {
      id: null,
      name: '',
      name_kana: '',
    };
    return {
      field,
      value,
    };
  }

  @Emit('updateForm')
  private onDeleteClinic(clinic) {
    const field = 'clinic';
    const value = this.dataForm.clinic.filter((x) => x.id !== clinic.id);
    return {
      field,
      value,
    };
  }

  @Emit('updateForm')
  private changeAvatarValue(event) {
    const eventData = Object.assign(event);
    this.handleFile(event);
    const field = 'avatar';
    // const value = event;
    return {
      field,
      value: eventData,
    };
  }

  private handleFile(val) {
    if (typeof val === 'object') {
      const file = val;
      const reader = new FileReader();
      this.$set(this.dataForm, 'avatar', val);
      reader.onload = (event: any) => {
        this.$set(this.dataForm, 'avatar_url', event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  // Methods
  private openModalDoctorList() {
    this.$modal.show('popupDoctor');
  }

  private openModalClinicList(event, name) {
    event.preventDefault();
    event.stopPropagation();
    this.$modal.show(name);
  }

  private hiddenPopup(name) {
    this.$modal.hide(name);
  }

  private onInputPassword(field: string, value: string) {
    let valuePassword: string = '';
    if (value && value.length <= 100) {
      valuePassword = value;
    } else {
      valuePassword = value.slice(0, 100);
    }
    this.updateForm(field, valuePassword);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }
  private mounted() {
    library.add(faPlus, faTrashAlt);
    dom.watch();
  }
}
