import BaseApi from '../BaseApi';

class UserTreatmentV2Api extends BaseApi {
  index(params?: any) {
    return this.get('/', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/user-treatment';
  }
}

export default UserTreatmentV2Api;
