






























import {Vue, Component, Prop} from 'vue-property-decorator';
import {Order} from '@/models/order';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

@Component({
  components: {},
  data() {
    return {
    };
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString =  value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}円`;
    },
  },
})
export default class ModalCancel extends Vue {
  @Prop(Object) readonly order: Order;
  private isLoading: boolean = false;

  private showCancel() {
    this.$modal.show('modalCancel');
  }

  private hiddenCancel() {
    this.$modal.hide('modalCancel');
  }

  private cancelOrder() {
    this.isLoading = true;
    const orderId = this.order.id;
    ApiHelper.getApi('DoctorMouthApi')
        .updateOrderStatus(orderId)
        .then(() => {
          Toast.success('キャンセルをしました。');
          this.hiddenCancel();
          this.$emit('fetchOrder');
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          Toast.error(error.message);
        });
  }
}
