




























































































































// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import moment from 'moment';
import lodash from 'lodash';

// helpers
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

// interfaces
import { Order } from '@/models/order';
import { Patient } from '@/models/patient';
import { User } from '@/models/user';

// constants
import { DELIVERY_STATUS, DELIVERY_STATUS_NAME, USER_TREATMENT_STATE } from '@/common/constants/auth';
import { PER_PAGE } from '@common/constants/pagination';

// components
import PageLoader from '@components/common/PageLoader.vue';
import Pagination from '@components/common/Pagination.vue';
import LazyloadImage from '@/components/image/LazyloadImage.vue';
import ModalCancelDelivery from '@/components/v2/patient/delivery/ModalCancelDelivery.vue';
import ModalChangePlan from '@components/v2/patient/delivery/ModalChangePlan.vue';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    PageLoader,
    ModalCancelDelivery,
    ModalChangePlan,
    Pagination,
    LazyloadImage,
  },
})

export default class DeliveryInfoChannel extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  private DELIVERY_STATUS = DELIVERY_STATUS;
  private orderList: Order[] | [] = [];
  private isLoading: boolean = false;
  private isSubmitting: boolean = false;

  private totalPage: number = 1;
  private page: number = 1;
  private activeOrder: Order | null = null;

  async created() {
    this.$store.commit('treatment_detail/setLoadingChild', true);
    await this.getOrderList();
    this.$store.commit('treatment_detail/setLoadingChild', false);
  }

  private async getOrderList() {
    try {
      this.isLoading = true;
      const payload = {
        user_treatment_id: this.activeTreatment.id,
        with: 'plan,tracking,user,userTreatment.menu',
        page: this.page,
        per_page: PER_PAGE,
      };

      const response = await ApiHelper
        .getApi('PatientV2Api')
        .getDeliveryList(this.patient.id, payload);
      this.orderList = response.data.map((item) => new Order().deserialize(item));
      this.totalPage = response.meta.last_page;
      this.isLoading = false;
    } catch (error) {
      if (error.message === 'cancel') {
        return;
      }
      this.isLoading = false;
    }
  }

  private pageChanged(page) {
    this.page = page;
    this.getOrderList();
  }

  private changeActiveOrder(order) {
    this.activeOrder = order;
  }

  private handleHiddenModal(modalName) {
    this.$modal.hide(modalName);
  }

  private handleShowModal(modalName, order) {
    // this.changeActiveOrder(order);
    this.$modal.show(modalName, { order });
  }

  private goToDetail(delivery) {
    if (this.currentUser.isDoctorRole) {
      return;
    }
    this.$router.push({path: `/deliveries/${delivery.id}`});
  }

  private canCancelOrder(listDelivery) {
    const state = [DELIVERY_STATUS.NOT_PAID, DELIVERY_STATUS.ERROR_PAID];

    return this.checkRole() && lodash.some(listDelivery, (data) => state.includes(data.state));
  }

  private canChangePlan(order) {
    return !order.plan.is_shampoo_subscription &&
           !order.isCancelled &&
           order.tracking.some((tracking) => tracking.paid_at != null) &&
           this.checkRole() &&
           [USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_OK, USER_TREATMENT_STATE.SUBSCRIPTION_CONTRACT].includes(this.activeTreatment.state);
  }

  private checkRole() {
    return this.currentUser.isAdminRole || this.currentUser.isCSRole;
  }

  private getDeliveryState(state) {
    return DELIVERY_STATUS_NAME[state];
  }

  private deliveryDate(delivery) {
    return moment(delivery).format('YYYY年 M月 D日');
  }

  private cancelOrder() {
    if (this.activeOrder && this.activeOrder.id) {
      this.isLoading = true;
      this.isSubmitting = true;
      ApiHelper.getApi('DoctorMouthApi').updateOrderStatus(this.activeOrder.id)
        .then(() => {
          this.getOrderList();
          Toast.success('更新しました');
        })
        .catch(() => {
          Toast.error('ビデオ診療予定日は担当医とCSのみ編集できます。');
        })
        .finally(() => {
          this.handleHiddenModal('modalCancelDelivery');
          this.isLoading = false;
          this.isSubmitting = false;
        });
    }
  }

  private changeOrder(e) {
    this.isSubmitting = true;
    const self = this;

    ApiHelper
      .getApi('OrderChangeApi')
      .changeOrder(this.activeOrder?.id, e)
      .then((res) => {
        this.$modal.hide('modalChangePlan');
        this.getOrderList();
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isSubmitting = false;
      });
  }
}
