























import { Vue, Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { AuthenticationModule } from '@/store';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { Roles } from '@common/app.config';
import PageLoader from '@components/common/PageLoader.vue';
import { splitLongText } from '@common/helpers/split-long-text';
import LocalStorageUtils from '../../common/LocalStorageUtils';
import { DashboardModule } from '@/store';

@Component({
  components: {
    PopupConfirm,
    PageLoader,
  },
})
export default class Navbar extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @State((state) => state.auth.isLoading) isLoading!: any;
  private isShow = false;

  private get roles() {
    return Roles;
  }

  private get currentName() {
    if (!this.currentUser)  { return; }
    if (this.currentUser.role === this.roles.clinic) {
      return splitLongText((this.currentUser.clinics[0] || {}).name, 30);
    }
    return splitLongText(this.currentUser.name, 30);
  }

  private turnOfShow() {
    this.isShow = false;
  }

  private async logout() {
    (window as any).UA.then(async (sdk: any) => {
      if (sdk.channel && !sdk.channel.namedUser) {
        return;
      }
      if (sdk.channel) {
        await sdk.channel.namedUser.remove(`managers_${this.currentUser.id}`);
      }
      await sdk.register();
    });
    await AuthenticationModule.logout();
    await DashboardModule.setClinics([]);
    await LocalStorageUtils.removeItem('dashboardID');
    await LocalStorageUtils.removeItem('revenueClinic');
    await LocalStorageUtils.removeItem('monthlyReportClinic');
    await localStorage.removeItem('isLoggedIn');
    this.$router.push('/login');
  }

  private confirmlogout() {
    if (
      this.$router.currentRoute.name === 'news.create' ||
      this.$router.currentRoute.name === 'news.detail'
    ) {
      this.$router.push('/login');
      return;
    }
    if (this.$router.currentRoute.name === 'resv.calling') {
      this.$modal.show('confirmLogout');
    } else {
      this.logout();
    }
  }

  private toggle() {
    this.isShow = !this.isShow;
  }
}
