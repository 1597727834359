
























































import {Component, Mixins, Prop} from 'vue-property-decorator';
import MessageMixin from './Message';

@Component
export default class MessageClinicPattern extends Mixins(MessageMixin) {
  @Prop() avatarUser!: any;
  @Prop() avatarManager!: any;
  listDate = [
    {
      label: '月',
      value: 1,
    }, {
      label: '火',
      value: 2,
    }, {
      label: '水',
      value: 3,
    }, {
      label: '木',
      value: 4,
    }, {
      label: '金',
      value: 5,
    }, {
      label: '土',
      value: 6,
    }, {
      label: '日',
      value: 7,
    },
  ];
  listTime = [
    {
      label: '午前（9時〜13時）',
      value: 1,
    }, {
      label: '午後（13時〜17時）',
      value: 2,
    }, {
      label: '夕方（17時〜）',
      value: 3,
    },
  ];
  detailClinicPattern = {
    date_in_week: [],
    time: [],
  };

  mounted() {
    this.detailClinicPattern = JSON.parse(this.message.message_content.replace(/(?:\\[rn]|[\r\n])/g, '<br>'));
  }
}
