





































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PatientModule } from '@/store';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import PatientDetailHeader from '@/components/Patient/PatientDetailHeader.vue';
import PatientDetailBasicInfo from '@/components/Patient/PatientDetailBasicInfo.vue';
import PatientDetailAssignDoctor from '@/components/Patient/PatientDetailAssignDoctor.vue';
import PatientDetailNote from '@/components/Patient/PatientDetailNote.vue';
import PatientDetailAction from '@/components/Patient/PatientDetailAction.vue';
import PatientDetailResvClinic from '@/components/Patient/PatientDetailResvClinic.vue';
import PatientInsuranceCard from '@/components/Patient/PatientInsuranceCard.vue';
import PatientDetailImageDescriptionOrigin from '@/components/Patient/PatientDetailImageDescriptionOrigin.vue';
import PatientDetailImageDescriptionByMonth from '@/components/Patient/PatientDetailImageDescriptionByMonth.vue';
import PatientDetailFeedBack from '@/components/Patient/PatientDetailFeedBack.vue';
import VerifyPhoto from '@/components/Patient/VerifyPhoto.vue';
import InsuranceInfo from '@components/common/InsuranceInfo.vue';
import {Roles} from '@common/app.config';
import {mapState} from 'vuex';
import PopupAddDateToClinic from '@components/common/PopupAddDateToClinic.vue';
import PopupResvDoctor from '@components/common/PopupResvDoctor.vue';
import ApiHelper from 'api-helper';
import moment from 'moment';
import lodash from 'lodash';
import { MESSAGE_TYPE } from '@common/enums/message';
import IconDeleteSvg from '@components/iconSVG/Delete.vue';
import IconEditSvg from '@components/iconSVG/Edit.vue';
import {OrderStatusType} from '@common/enums/order';

@Component({
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  components: {
    PatientDetailHeader,
    PatientDetailBasicInfo,
    PatientDetailNote,
    PatientDetailAction,
    PatientInsuranceCard,
    VerifyPhoto,
    PageLoader,
    InsuranceInfo,
    PopupConfirm,
    PopupAddDateToClinic,
    PopupResvDoctor,
    PatientDetailResvClinic,
    PatientDetailAssignDoctor,
    PatientDetailImageDescriptionOrigin,
    PatientDetailImageDescriptionByMonth,
    PatientDetailFeedBack,
    IconDeleteSvg,
    IconEditSvg,
  },
})
export default class HomeDetail extends Vue {
  private isLoading: boolean = false;
  private currentUser!: any;
  private reservationClinicPattern: any = {};
  private reservationDoctor: any = [];
  private idResvDoctorSelect: number;
  private showTab: number = 1;
  private listMouthImageOrigin: any = {};
  private isUserUploadImage: boolean = false;
  private isLoadMouth: boolean = false;
  private isLoadFeedBack: boolean = false;
  private listFeedback: any = [];
  private titleFeedback: string = '';
  private get dataPatientDetail() {
    return PatientModule.dataPatientDetail;
  }

  private get isWaitingChannel() {
    const firstPayment = lodash.get(PatientModule.dataPatientDetail, 'first_payment_flag', false);
    return firstPayment;
  }

  private get isCloseChannel() {
    const orders = lodash.get(PatientModule.dataPatientDetail, 'orderPaymented[0]', null);
    if (!orders) {
      return false;
    }
    const lengthOrder = orders.length;
    const lastOrder = orders[lengthOrder - 1] || [];
    return lastOrder.status === OrderStatusType.Confirm || lastOrder.status === OrderStatusType.Success;
  }

  private get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  private get titleTab() {
    if (this.showTab === 1) {
      return '患者様の情報＿基本情報';
    } else if (this.showTab === 2) {
      return '患者様の情報＿口腔内写真';
    } else {
      return '患者様の情報＿治療フィードバック';
    }
  }

  private isOverTime(data) {
    return moment().isAfter(moment(data));
  }

  // Methods
  private async handleChangeTab(tabId) {
    this.showTab = tabId;
  }

  private async addDateToClinicPattern() {
    const { id } = this.$route.params;
    this.$modal.show('popup-add-date-to-clinic', { userId: id });
  }

  private setTagAura(tagIds, tagsDelete) {
    const params = {
      tags: tagIds,
      is_add_and_delete: tagsDelete ? 1 : 0,
      line_id: (this.dataPatientDetail as any).line_id,
      delete_after_add: tagsDelete || [],
    };
    ApiHelper.getApi('AuraApi').addAuraTag(params);
  }

  private setResvClinicPattern(newResvClinicPattern) {
    this.setTagAura([863300], null);
    this.reservationClinicPattern = newResvClinicPattern;
  }

  private updateDateToClinicPattern(resvId) {
    const { id } = this.$route.params;
    const dataResvClinicPattern = {
      clinic: this.reservationClinicPattern.clinicPattern,
      start_date: this.reservationClinicPattern.start_date,
    };
    this.$modal.show('popup-add-date-to-clinic', { userId: id, resvId, dataResvClinicPattern });
  }

  private deleteDateToClinicPattern() {
    this.$modal.show('removeResvClinicPattern');
  }

  private hiddenPopupResvClinicPattern() {
    this.$modal.hide('removeResvClinicPattern');
  }

  private async addResvDoctor() {
    const { id } = this.$route.params;
    const dataResvDoctor = {
      userId: id,
    };
    this.$modal.show('popup-add-resv-doctor', { dataResvDoctor });
  }

  private setResvDoctor(newResvDoctor, timeInMessage) {
    this.setTagAura(863291, [923619]);
    const listResvFilterUpdate = this.reservationDoctor.filter((resv) => resv.id !== newResvDoctor.id);
    const listResvNew = [...listResvFilterUpdate, newResvDoctor];
    const listResvSort = lodash.orderBy(listResvNew, 'start_date', 'desc');
    this.reservationDoctor = listResvSort;

    const params = {
      message: 'オンライン診療の日時をご案内いたします。\n' + '\n' +
        `<b>` + timeInMessage + `</b>` + 'から予約をいたしました。\n' +
        '当日はどうぞよろしくお願いいたします。',
      image: null,
      type: MESSAGE_TYPE.TEXT,
      forgot_line: 'has',
    };
    const channelId = lodash.get(this.dataPatientDetail, 'channel.id', null);
    if (channelId) {
      const response = ApiHelper.getApi('MessageApi').sendMessage(channelId, params);
    }
  }

  private updateResvDoctor(resv) {
    const { id } = this.$route.params;
    const dataResvDoctor = {
      userId: id,
      resvId: resv.id,
      start_date: resv.start_date,
    };
    this.$modal.show('popup-add-resv-doctor', { dataResvDoctor });
  }

  private deleteResvDoctor(id) {
    this.idResvDoctorSelect = id;
    this.$modal.show('removeResvDoctor');
  }

  private hiddenPopupResvDoctor() {
    this.$modal.hide('removeResvDoctor');
  }

  private loadFeedback() {
    const { id } = this.$route.params;
    ApiHelper.getApi('DoctorMouthApi')
      .getListFeedback(id)
      .then((response) => {
        this.listFeedback = response.data;
      })
      .catch((error) => {
        Toast.error(error.message);
      })
      .finally(() => {
        this.isLoadFeedBack = true;
      });

    if (!this.isAdmin) {
      ApiHelper.getApi('DoctorMouthApi')
        .getTitleFeedback(id)
        .then((response) => {
          this.titleFeedback = response;
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    }
  }

  private patientMouthImage() {
    this.isLoading = true;
    const { id } = this.$route.params;
    ApiHelper.getApi('DoctorMouthApi')
      .getMouthImageOrigin(id)
      .then((response) => {
        this.listMouthImageOrigin = response;
        this.isUserUploadImage = !!response.dates.length;
      })
      .catch((error) => {
        Toast.error(error.message);
      })
    .finally(() => {
      this.isLoading = false;
      this.isLoadMouth = true;
    });
  }

  private loadingChild() {
    this.isLoading = !this.isLoading;
  }

  private async patientDetail() {
    try {
      this.isLoading = true;
      const { id } = this.$route.params;
      const params: { with: string } = { with: 'reservations,city,prefecture,clinics,parent,children,channel,orderPaymented' };
      const data = {
        id,
        params,
      };
      await PatientModule.getPatientDetail(data);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  private openPopupInsuranceInfo() {
    if (!(this.dataPatientDetail as any).ocr_response) {
      return;
    }

    this.$modal.show(
      'insurance-number-info',
      {
        insuranceInfo : (this.dataPatientDetail as any).ocr_response,
        nameUser: (this.dataPatientDetail as any).name,
      },
    );
  }

  private async removeResvDoctor() {
    if (!this.idResvDoctorSelect) { return; }
    try {
      await ApiHelper.getApi('UserApi').deleteResvDoctor(this.idResvDoctorSelect);
      Toast.success('削除しました');
      const listResvFilterDelete = this.reservationDoctor.filter((resv) => resv.id !== this.idResvDoctorSelect);
      this.reservationDoctor = listResvFilterDelete;
    } catch (error) {
      const { data } = error.response;
      Toast.error(data.message);
    } finally {
      this.$modal.hide('removeResvDoctor');
    }
  }

  private async removeResvClinicPattern() {
    const { id } = this.reservationClinicPattern;
    if (!id) { return; }
    try {
      await ApiHelper.getApi('ResvClinicPatternApi').delete(id);
      Toast.success('削除しました');
      this.reservationClinicPattern = {};
    } catch (error) {
      const { data } = error.response;
      Toast.error(data.message);
    } finally {
      this.$modal.hide('removeResvClinicPattern');
    }
  }

  private async removerUser() {
    const { id } = this.$route.params;
    try {
      await PatientModule.removePatient(id);
      // const deleteTags = [
      //   867998, 863339, 837089, 863336, 922302, 922305, 922308, 863333, 863330,
      //   863327, 923583, 863324, 948374, 948377, 948380, 863321,863318, 922311,
      //   922314, 863315, 922317, 922320, 863312, 922323, 922326, 863309,922329,
      //   922332, 863306, 931506, 923613, 856733, 922335, 922338, 922341, 863303,
      //   837089, 923613, 922344, 922347, 923553, 863300, 945128, 945131, 863294,
      //   837092, 923619, 922350, 922353, 922359, 922362, 863291, 922374, 922377,
      //   922383, 863288, 923622, 863285, 837092, 937407, 937410, 937413, 863282,
      //   863279, 927453, 863267, 837089, 863276, 837092, 922731, 922734, 922737,
      //   863273, 863270, 837095, 1112796, 1112797, 863264, 863258, 971582, 971585,
      //   971588, 863255, 971594, 971597, 971591, 971600, 971603, 971606, 971609,
      //   987311, 863294,863270, 837095
      // ]
      this.setTagAura(
        [867998, 863339, 837089],
        [856733, 837092, 837095, 927453],
      );
      this.patientDetail();
      Toast.success('ユーザを削除しました。');
    } catch (error) {
      const { data } = error.response;
      if (data.code === 'errors.user_exist_ecforce') {
        Toast.error('先ず、ECForceでアカウントを削除してください。');
      } else {
        Toast.error(data.message);
      }
    } finally {
      this.$modal.hide('removeUser');
    }
  }

  private showConfirmDeleteUser(id) {
    this.$modal.show('removeUser');
  }

  private hiddenPopup() {
    this.$modal.hide('removeUser');
  }

  @Watch('$route.params', { deep: true })
  private routeChange() {
    this.patientDetail();
  }

  private created() {
    this.patientDetail();
  }

  private mounted() {
    const { id } = this.$route.params;
    if (this.isAdmin) {
      ApiHelper.getApi('ResvClinicPatternApi')
        .list(id)
        .then((listResvClinicPattern) => {
          const resvClinicPattern = listResvClinicPattern.data[0];
          if (resvClinicPattern) {
            const resvFormat = {
              ...resvClinicPattern,
              start_date: moment(resvClinicPattern.start_date).add(9, 'hours').format('YYYY/MM/DD HH:mm'),
            };
            this.reservationClinicPattern = resvFormat;
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    }

    this.patientMouthImage();
    this.loadFeedback();

    ApiHelper.getApi('UserApi')
      .getResvDoctor(id)
      .then((listResvDoctor) => {
        const resvFormat = listResvDoctor.data.map((resDoctor) => ({
          ...resDoctor,
          start_date: moment(resDoctor.start_date).add(9, 'hours').format('YYYY/MM/DD HH:mm'),
        }));
        this.reservationDoctor = resvFormat;
      })
      .catch((error) => {
        Toast.error(error.message);
      });
    window.scrollTo(0, 0);
  }
}
