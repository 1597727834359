import pako from 'pako';

export default {
  saveItem(key: string, value: any): void {
    const binaryString = pako.deflate(JSON.stringify(value), {to: 'string'});
    window.localStorage.setItem(key, binaryString);
  },
  getItem(key: string): any {
    const zipData: any = window.localStorage.getItem(key);
    if (!zipData) {
      return zipData;
    }

    return JSON.parse(pako.inflate(zipData, { to: 'string' }));
  },
  removeItem(key: any) {
    window.localStorage.removeItem(key);
  },
};
