import HomeCreate from '@/views/doctor/HomeCreate.vue';
import HomeEdit from '@/views/doctor/HomeEdit.vue';
import ListDoctor from '@/views/doctor/ListDoctor.vue';
import DetailDoctor from '@/views/doctor/DetailDoctor.vue';
import { authAdmin, authAdminDoctor } from '@/common/constants/auth';

export default [
  {
    path: '/doctors',
    name: 'doctors',
    component: ListDoctor,
    meta: authAdmin,
  },
  {
    path: '/doctors/create',
    name: 'doctors-create',
    component: HomeCreate,
    meta: authAdmin,
  },
  {
    path: '/doctors/:id',
    name: 'doctors-detail',
    component: DetailDoctor,
    meta: authAdminDoctor,
  },
  {
    path: '/doctors/edit/:id',
    name: 'doctors-edit',
    component: HomeEdit,
    meta: authAdmin,
  },
];
