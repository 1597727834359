























import { Vue, Component, Prop } from 'vue-property-decorator';
import PharmacistSearch from '@components/Pharmacist/PharmacistSearch.vue';
import PharmacistList from '@components/Pharmacist/PharmacistList.vue';
import { State } from 'vuex-class';
import { Roles } from '@/common/app.config';
import PharmacistCreateModal from '@components/Pharmacist/PharmacistUpdateModal.vue';

@Component({
  components: {
    PharmacistSearch,
    PharmacistList,
    PharmacistCreateModal,
  },
})
export default class Index extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  private query: object = {};

  private onSearch(query) {
    this.query = {...query};
  }

  private reloadData() {
    this.query = {...this.query};
  }

  private openCreateModal() {
    this.$modal.show('pharmacist-update', {
      isCreate: true,
    });
  }

  private get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }
}
