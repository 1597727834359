import BaseApi from './BaseApi';

class WebhookLineApi extends BaseApi {
  public webhookLineSendMessage(data) {
    return this.post(`webhook-line/send-message`, data);
  }

  public webhookLineSendMessageToManyUser(data) {
    return this.post(`webhook-line/send-message-many-user`, data);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/';
  }
}

export default WebhookLineApi;
