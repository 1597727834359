








export default {
  props: {
    title: String,
    value: [String, Number],
  },
};
