



























































































import ApiHelper from 'api-helper';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Plan, QueryPlan } from '@/models/plan';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { uniqBy, compact } from 'lodash';
import Pagination from '@components/common/Pagination.vue';

@Component({
  components: {
    PageLoader,
    Pagination,
  },
  filters: {
    formatNumPrice: (value) => {
      if (!value) { return ''; }
      const totalPrice = Number(value) + Number(value) / 10;
      return Math.ceil(totalPrice) / 10000;
    },
    nameTypeTreatment: (treatmentType) => {
      if (treatmentType === 1) { return 'Emininal'; }
      if (treatmentType === 2) { return 'インビザライン'; }
      if (treatmentType === 3) { return 'その他'; }
      return '';
    },
    timeTreatment: (treatmentTime) => {
      if (treatmentTime) { return treatmentTime + 'ヶ月'; }
      return '';
    },
  },
})
export default class PopupListPlan extends Vue {
  @Prop({ default: true }) readonly clickToClose!: boolean;
  @Prop(String) readonly name!: string;
  @Prop(Boolean) readonly disabledBtSubmit!: string;
  @Prop(Boolean) readonly isToggle!: boolean;
  @Prop({ default: '55%' }) readonly width!: number | string;
  @Prop({ default: 700 }) readonly height!: number | string;
  @Prop({ default: '確定する' }) readonly textActionPopup!: string;
  @Prop({ default: false }) readonly showHeader!: boolean;
  @Prop(Number) readonly channelId!: number | string;
  isLoading: boolean = false;
  dataCourse: Plan[] = [];
  selectedPlan: number = 0;

  totalPage = 1;

  paginate = {
    page: 1,
    per_page: 10,
    channel_id: this.channelId,
  };

  getPlanList() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.dataCourse = [];

    ApiHelper.getApi('CourseApi')
      .getSuggestCourses(this.paginate)
      .then((response: any) => {
        this.totalPage = response.last_page;
        this.dataCourse = response.data
          .map(({ attributes, treatment_plan }) => ({...attributes, treatment_plan: treatment_plan || {}}));
      })
      .catch((err: any) => {
        Toast.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  choosePlan() {
    const lastChosenPlan = this.selectedPlan;
    this.selectedPlan = 0;
    return lastChosenPlan;
  }

  get isEmptyDataPlan(): boolean {
    return !this.dataCourse.length;
  }

  @Watch('isToggle')
  isPressChangePlan(val: boolean, oldVal: boolean) {
    if (val !== oldVal && val === true) {
      this.getPlanList();
    }
  }

  private pageChanged(page) {
    this.paginate = {
      ...this.paginate,
      page,
    };
    this.getPlanList();
  }
}
