import Index from '@/views/clinic/Index.vue';
import Create from '@/views/clinic/create/Create.vue';
import Show from '@/views/clinic/show/Show.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.superAdmin],
};

export default [
  {
    path: '/clinics',
    name: 'clinics',
    component: Index,
    meta: authMeta,
  },
  {
    path: '/clinics/create',
    name: 'clinics.create',
    component: Create,
    meta: authMeta,
  },
  {
    path: '/clinics/show/:clinicId',
    name: 'clinics.show',
    component: Show,
    meta: authMeta,
  },
];
