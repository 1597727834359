














import { Vue, Component, Watch } from 'vue-property-decorator';
import NotificationList from '@components/Notifications/NotificationList.vue';
import NotificationSearch from '@components/Notifications/NotificationSearch.vue';

@Component({
  components: {
    NotificationList,
    NotificationSearch,
  },
})
export default class Notifications extends Vue {
  private query = {};

  private onSearch(newQuery) {
    this.query = {...newQuery};
  }
}
