import BaseApi from './BaseApi';

class DoctorNotAdmin extends BaseApi {
  public list(params: any) {
      return this.get('/', params);
  }

  protected getSpecificApiPrefix(): string {
      return 'managers/doctor-not-admin';
  }
}

export default DoctorNotAdmin;
