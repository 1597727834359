import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import ApiHelper from 'api-helper';
@Module({ name: 'bankJP', namespaced: true })
export default class BankJP extends VuexModule {
  public dataBank = [];
  public dataBankBranch = [];
  @Action({ rawError: true, commit: 'SET_DATA_BANK'})
  public async listBank(param?: any) {
    const response = await ApiHelper.getApi('BankApi').list(param);

    return response.data;
  }

  @Action({ rawError: true, commit: 'SET_DATA_BANK_BRANCH'})
  public async listBankBranch(param) {
    const response = await ApiHelper.getApi('BankApi').list(param);

    return response.data;
  }

  @Action({ rawError: true, commit: 'SET_DATA_BANK_BRANCH'})
  public async resetBranch() {
    return [];
  }

  @Mutation
  public SET_DATA_BANK(response) {
    this.dataBank = response;
  }

  @Mutation
  public SET_DATA_BANK_BRANCH(response) {
    this.dataBankBranch = response;
  }

}
