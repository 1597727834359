






















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseInputRadio extends Vue {
  @Prop([String, Number]) readonly id!: string | number;
  @Prop([String, Number, Boolean]) readonly value!: string | number | boolean;
  @Prop(String) readonly label!: string;
  @Prop(Boolean) readonly checked!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
}
