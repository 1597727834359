import BaseApi from './BaseApi';

class ImportUserApi extends BaseApi {
  public changeStatus(params) {
    return this.post(`/change-status`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/imported-users';
  }
}

export default ImportUserApi;
