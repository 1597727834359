















// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';

// interface
import { Patient } from '@/models/patient';
import { User } from '@/models/user';

// components
import UpdatePatientInfoModal from '@/components/v2/patient/about/patient_info/UpdatePatientInfoModal.vue';

@Component({
  components: {
    UpdatePatientInfoModal,
  },
})
export default class EditPatient extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;

  private get editPatientModalRef() {
    return this.$refs.editPatientModal as any;
  }

  private get canEditUser() {
    return this.currentUser.isAdminRole || this.currentUser.isCSRole;
  }

  private showEditPatientModal() {
    this.editPatientModalRef.show();
  }
}
