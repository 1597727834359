import { Roles } from '@common/app.config';
import Answers from '@/components/Reservation/Answers.vue';

const authMeta =  {
    auth: true,
    authorize: [Roles.superAdmin, Roles.doctor],
  };

export default [
  {
    path: '/answer/:id',
    name: 'answer.detail',
    component: Answers,
    meta: authMeta,
  },
];
