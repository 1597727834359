import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import sortBy from 'lodash/sortBy';

@Module({ name: 'channel', namespaced: true })
export default class Channel extends VuexModule {
  public channels: any[] = [];
  public isLoading = false;
  public perPage = 20;
  public page = 1;
  public activeChannel = {};
  public totalPage = 1;
  public currentChannelId: number = 0;
  public realation = 'users.plan,message_unread_count';
  public isChangeChannels = true;

  @Mutation
  public updateChannels(channels) {
    this.channels = channels;
  }

  @Mutation
  public updateChannel(channel) {
    this.channels = this.channels.map(function(item) {
      if (item.id == channel.id) {
        return channel;
      }
      return item;
    });
  }

  @Mutation
  public updateIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @Mutation
  public updateActiveChannel(channel) {
    this.activeChannel = channel;
  }

  @Mutation
  public updateTotalPage(totalPage) {
    this.totalPage = totalPage;
  }

  @Mutation
  public async updateCurrentChannelId(channelId: number) {
    this.currentChannelId = channelId;
  }

  @Mutation
  public updatePage(page) {
    this.page = page;
  }

  @Mutation
  public updateIsChangeChannels(isChange) {
    this.isChangeChannels = isChange;
  }

  @Action({rawError: true})
  public resetChannels() {
    const { commit } = this.context;
    commit('updateChannels', []);
    commit('updatePage', 1);
    commit('updateCurrentChannelId', null);
  }

  @Action({rawError: true})
  updateNewChannel(newChannel) {
    const { commit, state } = this.context;
    const { channels } = state as any;
    const channel = channels.find((item) => item.id === newChannel.id);
    if (channel) {
      channel.latest_message_at = newChannel.latest_message_at;
      const sortedChannels = sortBy(channels, (item) => -(new Date(item.latest_message_at)));
      commit('updateChannels', sortedChannels);
      return;
    }

    channels.unshift(newChannel);
    commit('updateChannels', channels);
  }

  @Action({rawError: true})
  setActiveChannel(channel) {
    const { commit, state } = this.context;
    const { channels } = state as any;
    if (channel) {
      const targetChannel = channels.find((item) => item.id === channel.id);
      targetChannel.status = channel.status;
      targetChannel.state = channel.users[0].state;
      targetChannel.users = channel.users;
      commit('updateActiveChannel', targetChannel);
      return;
    } else {
      commit('updateActiveChannel', null);
    }
  }

  @Action({rawError: true})
  setChangeChannels(isChange = false) {
    const { commit } = this.context;
    commit('updateIsChangeChannels', isChange);
  }

  get currentChannel() {
    return this.activeChannel;
  }
}
