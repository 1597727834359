import BaseApi from '../BaseApi';

class PatientApi extends BaseApi {
  public list(params: any) {
    return this.get('/', params);
  }

  public detail(id: number) {
    return this.get(`/${id}`);
  }

  public getPlansByPatientId(patientId: number, payload) {
    return this.get(`/${patientId}/plans`, payload);
  }

  public getKarteList(patientId: number, params: any) {
    return this.get(`/${patientId}/kartes`, params);
  }

  public getDeliveryList(patientId: number, params: any) {
    return this.get(`/${patientId}/deliveries`, params);
  }


  public getReservation(patientId: number, params: any) {
    return this.get(`/${patientId}/reservation`, params);
  }

  public changeUserTreatmentState(userId: number, params: any) {
    return this.put(`/${userId}/choose-method-inquiry`, params);
  }

  public doctorConfirmOrder(userId: number, params: any) {
    return this.post(`/${userId}/orders/doctor-confirm`, params);
  }

  public getPatientOrderAccepted(id: number, params: any) {
    return this.get(`/${id}/patients/get-order-accepted`, params);
  }

  public reminderChat(id: number, params: any) {
    return this.post(`/${id}/chat-reminder`, params);
  }

  public createFeedback(userId: number, params: any) {
    return this.post(`/${userId}/feedback`, params);
  }

  public exportUserCsv(params: any) {
    return this.get('/export-csv', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/users';
  }
}

export default PatientApi;
