






















































































import ApiHelper from 'api-helper';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ModelPatientDetail } from '@/models/patient';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { downloadFile } from '@/common/helpers/download-file';
import imageDefault from '@/assets/img/icon-no-image.svg';
import BasicSelect from '@/components/common/BasicSelect.vue';
import ZoomImage from './ZoomImage.vue';
import { VerifyTypeBoolean } from '@/common/constants/patient';
import Toast from '@/common/helpers/toast';
import PopupListReason from './PopupReason.vue';
import moment from 'moment';
import {State} from 'vuex-class';
import {Roles} from '@common/app.config';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import ImageZoomComponent from './ImageZoomComponent.vue';

@Component({
  components: {
    ZoomImage,
    BasicSelect,
    PopupListReason,
    ImagePrivate,
    ImageZoomComponent,
  },
})
export default class PatientInsuranceCard extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop(Object) readonly dataPatientDetail!: ModelPatientDetail;
  private isLoading: boolean = false;
  private urlBack: any = imageDefault;
  private urlBackPath: string = '';
  private urlFront: any = imageDefault;
  private urlFrontPath: string = '';
  private url = '';
  private verifyList = [
    { name: '審査完了', value: VerifyTypeBoolean.Verified },
    { name: '未審査', value: VerifyTypeBoolean.Unverified },
  ];
  private listReasonArr: any = [];

  private downloadFile(link): string {
    return link ? downloadFile(link) : '';
  }

  private changeStatusDelivery(status: boolean) {
    if (this.isLoading) {
      return;
    }

    const data = {
      is_verified: status,
    };

    this.isLoading = true;
    ApiHelper.getApi('PatientApi')
      .updateVerify(this.dataPatientDetail.id, data)
      .then(() => {
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Watch('dataPatientDetail')
  private onChangeAvatar() {
    const data = this.dataPatientDetail as any;
    this.urlBack = data.back_side_card_url ? data.back_side_card_url : imageDefault;
    this.urlFront = data.front_side_card_url ? data.front_side_card_url : imageDefault;
    this.urlBackPath = data.back_side_card;
    this.urlFrontPath = data.front_side_card;
  }

  private created() {
    library.add(faDownload);
    library.add(faSearchPlus);
    dom.watch();
    this.urlBack = imageDefault;
    this.urlFront = imageDefault;
  }

  private mounted() {
    const data = this.dataPatientDetail as any;
    const isLoadDataDone = Number(this.$route.params.id) === this.dataPatientDetail.id;
    this.urlBack = isLoadDataDone ? data.back_side_card_url ? data.back_side_card_url : imageDefault : imageDefault ;
    this.urlFront = isLoadDataDone ? data.front_side_card_url ? data.front_side_card_url : imageDefault : imageDefault;
    this.urlBackPath = isLoadDataDone ? data.back_side_card : imageDefault;
    this.urlFrontPath = isLoadDataDone ? data.front_side_card : imageDefault;

    if (this.currentUser.role === Roles.superAdmin) {
      ApiHelper.getApi('ReasonApi')
        .getList()
        .then((result) => {
          for (const [key, value] of Object.entries(result)) {
            this.listReasonArr.push({ id: key, value });
          }
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  private get textStatus() {
    if (!this.dataPatientDetail.back_side_card
      && !this.dataPatientDetail.front_side_card
      && !this.dataPatientDetail.admin_review_at
    ) {
      return '未提出';
    }
    if ((this.dataPatientDetail.back_side_card || this.dataPatientDetail.front_side_card)
        && !this.dataPatientDetail.is_review_valid
    ) {
      return '未審査';
    }
    if (!this.dataPatientDetail.admin_review_at) {
      return '未審査';
    }
    const statusText = this.dataPatientDetail.is_review_valid
      ? '審査完了'
      : this.dataPatientDetail.reason;
    return statusText;
  }

  private get dateUpdate() {
    return this.dataPatientDetail.user_upload_at
      ? moment(this.dataPatientDetail.user_upload_at).format('YYYY/MM/DD')
      : null;
  }

  private get dateReview() {
    return this.dataPatientDetail.admin_review_at
      ? moment(this.dataPatientDetail.admin_review_at).format('YYYY/MM/DD')
      : null;
  }

  private get reviewed() {
    if (this.dataPatientDetail.is_review_valid) {
      return false;
    } else if (
        !this.dataPatientDetail.is_review_valid
        && (!this.dataPatientDetail.front_side_card && !this.dataPatientDetail.back_side_card)
    ) {
      return true;
    } else if (
        !this.dataPatientDetail.is_review_valid
        && (this.dataPatientDetail.front_side_card || this.dataPatientDetail.back_side_card)
    ) {
      return false;
    }
  }

  private get reviewedForOk() {
    if (this.dataPatientDetail.is_review_valid) {
      return true;
    } else if (
      !this.dataPatientDetail.is_review_valid
      && (!this.dataPatientDetail.front_side_card && !this.dataPatientDetail.back_side_card)
    ) {
      return true;
    } else if (
      !this.dataPatientDetail.is_review_valid
      && (this.dataPatientDetail.front_side_card || this.dataPatientDetail.back_side_card)
    ) {
      return false;
    }
  }

  private show(urlImage) {
    this.url = urlImage;
    this.$modal.show('namePreview', { url: urlImage });
  }

  private showReason() {
    this.$modal.show('selectReason');
  }

  private accept() {
    ApiHelper.getApi('UserApi')
      .acceptVerify({user_id: Number(this.$route.params.id)})
      .then(() => {
        Toast.success('更新しました');
        this.$emit('reloadData');
        this.setTagAura([863306, 931506, 923613, 856733], [837089, 923583]);
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      });
  }

  private setTagAura(tagId, tagsDelete) {
    const params = {
      tags: tagId,
      is_add_and_delete: 1,
      line_id: (this.dataPatientDetail as any).line_id,
      delete_after_add: tagsDelete || [],
    };
    ApiHelper.getApi('AuraApi').addAuraTag(params);
  }

  private reject(reasonId) {
    const params = {
      reason_id: Number(reasonId),
      user_id: Number(this.$route.params.id),
    };
    ApiHelper.getApi('UserApi')
      .rejectVerify(params)
      .then((result) => {
        let tagId;
        let taskDelete;
        switch (Number(reasonId)) {
          case 1:
            tagId = 863318;
            taskDelete = [863321];
            break;
          case 2:
            tagId = 863312;
            taskDelete = [863321];
            break;
          case 3:
            tagId = 863315;
            taskDelete = [863321];
            break;
          default:
            tagId = 863309;
            taskDelete = [863321];
            break;
        }
        this.setTagAura([tagId], taskDelete);
        Toast.success('更新しました');
        this.$modal.hide('selectReason');
        this.$emit('reloadData');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      });
  }
}
