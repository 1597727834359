































































































































































































































































































































import { Vue, Component, Watch, Mixins } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import DatePickerMixin from '@/common/mixins/DatePickerMixin';
import ContentLayout from '@components/layouts/Content.vue';

import {DeliveryState, OrderStatus} from '@/common/app.config';
import { SelectType } from '@/common/interfaces/common';
import { Order, OrderInput } from '@/models/order';
import { OrderSearch } from '@/models/order-search';
import { Paginate } from '@/models/paginate';

import BaseDatePicker from '@/components/common/BaseDatePicker.vue';
import BasicSelect from '@/components/common/BasicSelect.vue';
import Pagination from '@/components/common/Pagination.vue';
import PageLoader from '@/components/common/PageLoader.vue';
import Toast from '@/common/helpers/toast';
import iconOrder from '@/assets/img/icon-order.svg';

import { get } from 'lodash';
import moment from 'moment';
import 'vue2-datepicker/locale/ja';
import {OrderStatusDisplay, PaymentMethodIdDisplay, PaymentMethodId, OrderStatusType} from '@/common/enums/order';

@Component({
  name: 'OrderList',
  components: {
    BaseDatePicker,
    DatePicker,
    BasicSelect,
    Pagination,
    PageLoader,
    ContentLayout,
  },
  data() {
    return {
      iconHeader: iconOrder,
      get,
      OrderStatusDisplay,
      PaymentMethodIdDisplay,
      PaymentMethodId,
    };
  },
})
export default class OrderList extends Mixins(DatePickerMixin) {
  isLoading: boolean = false;
  keyword: string = '';
  isPaginate: boolean = false;
  lang = { yearFormat: 'YYYY年' };
  tableHead: string[] = ['ID', '患者名', 'プラン名', '決済方法', 'オーダーステータス'];
  optionPaymentMethodId = [
    {name: '全て', value: null},
    {name: 'クレジットカード', value: 1},
  ];
  orderStatusType = OrderStatusType;

  frSearch: OrderSearch = new OrderSearch();
  orders: Order[] = [];
  paginate: Paginate = new Paginate();
  orderSelected: Order = new Order();
  statusSelected: string = '';
  usersNameSelected: string = '';
  pageOptions = [10, 50, 100];
  isRangeDateValid = false;
  isLoadingEcForce: boolean = false;
  plans = [];

  get paymentMethodId(): SelectType[] {
    return [];
  }

  get deliveryState(): SelectType[] {
    return DeliveryState;
  }


  get searchStatus(): SelectType[] {
    return OrderStatus;
  }

  get planOptions() {
    const options = [
      {
        name: '全て',
        value: null,
      },
      ...this.plans,
    ];
    return options;
  }

  mounted() {
    this.getPlanList();
    this.paginate.setPerPage(10);
    this.fetchOrders();
  }

  onSearch(): void {
    this.resetOrder();
    this.fetchOrders();
  }

  resetOrder() {
    this.orders = [];
    this.paginate.last_page = 0;
    this.paginate.setPage(1);
  }

  onPageChange(page): void {
    if (this.isPaginate) {
      return;
    }

    this.resetOrder();
    this.isPaginate = true;
    this.paginate.setPage(page);
    this.fetchOrders();
  }

  onChangeKeyword(event) {
    this.keyword = event.target.value;
  }

  onChangePerPage(perPage: number) {
    this.resetOrder();
    this.paginate.setPerPage(perPage);
    this.fetchOrders();
  }

  isActivePerPage(perPage: number) {
    return this.paginate.per_page === perPage;
  }

  handleMinDate(date: any): boolean {
    return moment(date).isBefore(this.frSearch.date_gte, 'date');
  }

  handleMaxDate(date: any): boolean {
    return moment(date).isAfter(this.frSearch.date_lte, 'date');
  }

  handleMinTime(time: any): boolean {
    return moment(time).isBefore(this.frSearch.date_gte, 'hour');
  }

  handleMaxTime(time: any): boolean {
    return moment(time).isAfter(this.frSearch.date_lte, 'hour');
  }

  onOpenModal(name: string, item?: Order): void {
    if (item && item.id) {
      this.usersNameSelected = (item.user as any).first_name + (item.user as any).last_name;
      this.orderSelected = item;
    }
    this.$modal.show(name);
  }

  closeModal(name) {
    this.$modal.hide(name);
    this.statusSelected = '';
    this.orderSelected = new Order();
  }

  closeAllModal() {
    this.$modal.hide('modalChangeWaitingStatus');
    this.$modal.hide('modalConfirmChangeStatus');
    this.$modal.hide('modalChangeStatus');
    this.statusSelected = '';
    this.orderSelected = new Order();
  }

  onChangeStatus(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    ApiHelper.getApi('OrderApi')
      .changeStatus(this.orderSelected.id, {status: this.statusSelected})
      .then((res: any) => {
        this.closeAllModal();
        this.fetchOrders();
      })
      .catch((err: any) => {
        Toast.error(get(err, 'response.data.message'));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getPlanList() {
    ApiHelper.getApi('PlanApi')
        .fetchPlanList({})
        .then((response: any) => {
          this.plans = response.data.map((item) => {
            return {name: item.name, value: item.id};
          });
        });
  }

  private notBeforeDate(date) {
    if (this.frSearch.paidAtFrom) {
      return date < new Date(this.frSearch.paidAtFrom.toString());
    } else {
      return false;
    }
  }

  private notAfterDate(date) {
    if (this.frSearch.paidAtTo) {
      return date > new Date(this.frSearch.paidAtTo.toString());
    } else {
      return false;
    }
  }

  private gotoDetail(id) {
    this.$router.push({path: `/orders/${id}`});
  }

  private fetchOrders(): void {
    const opts = {
      with: 'plan, user, plan.treatment',
      ...this.frSearch.toSearchJSON(),
      ...this.paginate.toPaginateJSON(),
    };

    this.isLoading = true;
    ApiHelper.getApi('OrderApi')
      .getOrders(opts)
      .then((res: any) => {
        this.orders = res.data.map((item: OrderInput) => new Order().deserialize(item));
        this.paginate = new Paginate().deserialize(res);
      })
      .catch((err: any) => {
        Toast.error(get(err, 'response.data.message'));
      })
      .finally(() => {
        this.isLoading = false;
        this.isPaginate = false;
      });
  }


  @Watch('frSearch.date_gte')
  private watchStartDate(val: any) {
    if (!val || val === 'Invalid date' || !this.frSearch.date_lte || this.frSearch.date_lte === 'Invalid date') {
      return;
    }
    this.isRangeDateValid = moment(val).isAfter(this.frSearch.date_lte);
  }

  @Watch('frSearch.date_lte')
  private watchEndDate(val: any) {
    if (!val || val === 'Invalid date' || !this.frSearch.date_gte || this.frSearch.date_gte === 'Invalid date') {
      return;
    }
    this.isRangeDateValid = moment(this.frSearch.date_gte).isAfter(val);
  }
}
