






import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import QuestionList from '@components/Question/QuestionList.vue';

@Component({
  components: {
    QuestionList,
  },
})
export default class ListQuestion extends Vue {

}
