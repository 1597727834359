import { RESERVATION_TYPE } from '@/common/app.config';
import Encoding from 'encoding-japanese';

export const getFormData = (data: any, options = { checkEmpty: true }) => {
  const { checkEmpty } = options;
  const formData = new FormData();
  for (const key in data) {
    if (checkEmpty && [null, undefined, ''].includes(data[key])) {
      continue;
    } else if (Array.isArray(data[key])) {
      data[key].forEach((x: any) => {
        formData.append(`${key}[]`, x);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const autoDeletePropertyEmpty = ( object: any ) => {
  const data = {};
  for (const key in object) {
    if (!['', null, undefined].includes(object[key])) {
      data[key] = object[key];
    }
  }
  return data;
};

export const getReservationType = (type: number | null): { id?: number; text?: string } => {
  return RESERVATION_TYPE.find((x) => x.id === type) || {};
};

export const transformBlobToFile = (data, file) => {
  const uniArray = Encoding.stringToCode(data);
  const sjisArray = Encoding.convert(uniArray, {
    to: 'SJIS',
    from: 'UNICODE',
  });
  const unit8Array = new Uint8Array(sjisArray);
  const blob = new Blob([unit8Array], { type: 'text/csv;charset=Shift_JIS' });

  if (window.navigator.msSaveOrOpenBlob) {
    // for IE,Edge
    window.navigator.msSaveOrOpenBlob(blob, file);
  } else {
    // for chrome, firefox
    const url = URL.createObjectURL(blob);
    const linkEl: any = document.createElement('a');
    linkEl.href = url;
    linkEl.setAttribute('download', file);
    document.body.appendChild(linkEl);
    linkEl.click();

    URL.revokeObjectURL(url);
    linkEl.parentNode.removeChild(linkEl);
  }
};
