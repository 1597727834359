<template>
  <div class="video-component" v-click-outside="showModalLeaveVideo" id="video-component">
    <div class="video-header">
      <span>ビデオ通話</span>
      <span>
        {{ `${currentChannelUser.family_name || ''} ${currentChannelUser.given_name || ''}`.trim() || '-' }}
      </span>
    </div>

    <img v-if="userMicOff" :src="micUserIcon" class="mic-icon-user"  alt="Micro"/>
    <div class="video-container">
      <div class="video-content" id="video-content"></div>
      <div class="video-user">
        <div class="video-content-user" id="video-content-user"></div>
      </div>
    </div>
    <div class="video-footer">
      <div class="video-item">
        <img :src="camIcon" @click="camToggle()"  alt="Camera"/>
      </div>
      <div class="video-item">
        <button
          v-if="isCall"
          class="video-btn video-btn-call"
          @click="leaveCalling"
        >
          退出する
        </button>
        <button
          v-else
          class="video-btn video-btn-end"
          id="join"
          @click="joinCalling"
        >
          開始する
        </button>
      </div>
      <div class="video-item">
        <img :src="micIcon" @click="micToggle()"  alt="Micro"/>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import AgoraRTC from 'agora-rtc-sdk-ng';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import CamOn from '@/assets/img/camera.svg';
import CamOff from '@/assets/img/camera-lock.svg';
import MicOn from '@/assets/img/mic.svg';
import MicOff from '@/assets/img/mic-lock.svg';

@Component
export default class VideoComponent extends Vue {
  @Prop() currentChannel;
  @Prop() currentChannelUser;
  @Prop() setVideo;
  @Prop() showModalLeaveVideo;

  agoraTokenData = {};
  hasMic = true;
  hasCamera = true;
  isCall = false;
  hasUser = false;
  userMicOff = false;

  rtc = {
    localAudioTrack: null,
    localVideoTrack: null,
    client: null,
  };

  options = {
    // Pass your App ID here.
    appId: 'Your App ID',
    // Set the channel name.
    channel: 'test',
    // Pass your temp token here.
    token: 'Your temp token',
    // Set the user ID.
    uid: 123456,
  };

  mounted() {
    this.getVideoToken();
    // this.startBasicCall();
  }

  micToggle() {
    this.hasMic = !this.hasMic;
    this.rtc.localAudioTrack.setEnabled(this.hasMic);
  }

  camToggle() {
    this.hasCamera = !this.hasCamera;
    this.rtc.localVideoTrack.setEnabled(this.hasCamera);
  }

  get camIcon() {
    return this.hasCamera ? CamOn : CamOff;
  }

  get micIcon() {
    return this.hasMic ? MicOn : MicOff;
  }

  get micUserIcon() {
    return this.userMicOff ? MicOff : MicOn;
  }

  async getVideoToken() {
    await ApiHelper.getApi('ChannelApi')
      .agoraToken(this.$route.params.channelId, {
        with: 'users,latest_message,users.latestReservation',
      })
      .then((response) => {
        this.agoraTokenData = response.data;
        this.options = {
          appId: response.data.app_id,
          channel: response.data.channel_uuid,
          token: response.data.token,
          uid: Number(response.data.uid),
        };
        this.startBasicCall();
      })
      .catch((error) => {
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        console.log();
      });
  }

  async startBasicCall() {
    // Create an AgoraRTCClient object.
    this.rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

    // Listen for the 'user-published' event, from which you can get an AgoraRTCRemoteUser object.
    this.rtc.client.on('user-published', async (user, mediaType) => {
      // Subscribe to the remote user when the SDK triggers the 'user-published' event
      await this.rtc.client.subscribe(user, mediaType);
      console.log('subscribe success');

      // If the remote user publishes a video track.
      if (mediaType === 'video') {
        // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
        const remoteVideoTrack = user.videoTrack;
        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        const remotePlayerContainer = document.createElement('div');
        // Specify the ID of the DIV container. You can use the uid of the remote user.
        remotePlayerContainer.id = user.uid.toString();
        // remotePlayerContainer.textContent = 'Remote user ' + user.uid.toString();
        remotePlayerContainer.style.width = '100%';
        remotePlayerContainer.style.height = 'calc(100vh - 12vh)';
        const videoContent = document.getElementById('video-content');
        videoContent.append(remotePlayerContainer);
        // document.body.append(remotePlayerContainer);

        // Play the remote video track.
        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
        // remoteVideoTrack.play(remotePlayerContainer);
        remoteVideoTrack.play(remotePlayerContainer, { fit: 'contain', mirror: false });
      }

      // If the remote user publishes an audio track.
      if (mediaType === 'audio') {
        this.userMicOff = false;
        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        const remoteAudioTrack = user.audioTrack;
        // Play the remote audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }

      // Listen for the 'user-unpublished' event
      this.rtc.client.on('user-unpublished', (user, mediaType) => {
        // Get the dynamically created DIV container.
        const remotePlayerContainer = document.getElementById(user.uid);
        // Destroy the container.

        // If the remote user publishes an audio track.
        if (mediaType === 'audio') {
          this.userMicOff = true;
        }
        if (mediaType === 'video') {
          remotePlayerContainer.remove();
        }
        });
    });
  }

  async joinCalling() {
    // document.getElementById('join').onclick = async function () {
    // Join an RTC channel.
    await this.rtc.client.join(
      this.options.appId,
      this.options.channel,
      this.options.token,
      this.options.uid,
    );
    // Create a local audio track from the audio sampled by a microphone.
    this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a local video track from the video captured by a camera.
    this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    // Publish the local audio and video tracks to the RTC channel.
    await this.rtc.client.publish([
      this.rtc.localAudioTrack,
      this.rtc.localVideoTrack,
    ]);
    // Dynamically create a container in the form of a DIV element for playing the local video track.
    const localPlayerContainer = document.createElement('div');
    // Specify the ID of the DIV container. You can use the uid of the local user.
    localPlayerContainer.id = this.options.uid;
    // localPlayerContainer.textContent = 'Local user ' + this.options.uid;
    localPlayerContainer.style.width = '200px';
    localPlayerContainer.style.height = '200px';
    const videoContentUser = document.getElementById('video-content-user');
    videoContentUser.append(localPlayerContainer);

    // Play the local video track.
    // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
    this.rtc.localVideoTrack.play(localPlayerContainer);
    if (!this.hasCamera) {
      await this.rtc.localVideoTrack.setEnabled(false);
    }
    if (!this.hasMic) {
      await this.rtc.localAudioTrack.setEnabled(false);
    }
    console.log('publish success!');
    // };
    this.isCall = true;
  }

  async leaveCalling() {
    // Destroy the local audio and video tracks.
    this.rtc.localAudioTrack.close();
    this.rtc.localVideoTrack.close();

    // Traverse all remote users.
    this.rtc.client.remoteUsers.forEach((user) => {
      // Destroy the dynamically created DIV containers.
      const playerContainer = document.getElementById(user.uid);
      playerContainer && playerContainer.remove();
    });

    // Leave the channel.
    await this.rtc.client.leave();
    this.isCall = false;
    this.setVideo(false);
  }

  beforeDestroy() {
    this.rtc.client.leave();
    this.setVideo(false);
  }
}
</script>

<style lang='scss' scoped>
.video {
  &-component {
    height: calc(100vh - 8vh);
    position: relative;
  }
  &-header {
    background-color: #000;
    padding: 10px;
    color: #fff;
    font-weight: bold;

    span {
      margin-left: 10px;
    }
  }

  &-content {
    padding-top: 10px;
    z-index: 1;
  }

  &-item {
    padding: 10px 10px 0;
    z-index: 1000;
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding: 10px 10px 10px;
  }

  &-btn {
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
    padding: 10px;
    border: unset;

    &-call {
      background: #fc1d1d;
    }
    &-end {
      background: #008505;
    }
  }

  &-container {
    position: relative;
    height: 90%;
    background-color: #D9D9D9;
  }

  &-user {
    position: absolute;
    right: 18px;
    bottom: 10px;
    width: 200px;
    height: 200px;
    z-index: 100;
    background-color: transparent;
  }
}
.mic-icon-user {
  position: absolute;
  top: 58px;
  z-index: 1000;
  height: 30px;
  left: 20px;
}
</style>
