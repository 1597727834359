





























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import moment from 'moment';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import lodash, { includes } from 'lodash';

import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { Roles } from '@common/app.config';
import { DELIVERY_STATUS, DELIVERY_STATUS_NAME, USER_STATE } from '@/common/constants/auth';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import UpdateOrderTrackingListModal from '@/components/OrderTracking/UpdateOrderTrackingListModal.vue';
import ModalCancelDelivery from '@/views/messages/ModalCancelDelivery.vue';
import ModalChangePlan from '@/views/messages/ModalChangePlan.vue';
import { Delivery } from '@/models/delivery';
import { Order } from '@/models/order';
import Pagination from '@components/common/Pagination.vue';

@Component({
  components: {
    PageLoader,
    VuePerfectScrollbar,
    PopupConfirm,
    UpdateOrderTrackingListModal,
    ModalCancelDelivery,
    ModalChangePlan,
    Pagination,
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString =  value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}円`;
    },
  },
})

export default class DeliveryInfoChannel extends Vue {
  @Prop() activeTab!: any;
  @Prop() patientAnswer!: any;
  @Prop() changeCurrentChannelUser: any;
  @Prop() currentChannelUser: any;
  @Prop() currentUser: any;
  // because the channel automatically loads after 1 minute
  @Prop() stateDeliveryChannel: any;
  @Prop() setStateDeliveryChannel: any;
  // because the channel automatically loads after 1 minute
  private isLoading: boolean = false;
  private DELIVERY_STATUS = DELIVERY_STATUS;
  // private activeOrder: any = {};
  private moment: any = moment;
  private isSubmitting: boolean = false;
  private selectedDelivery: Delivery = new Delivery();
  private orderList: any[] = [];
  private totalPage: number = 1;
  // private paginate = {
  //   page: 1,
  //   per_page: 2,
  // };

  mounted() {
    this.getOrderList();
  }

  private pageChanged(page) {
    this.getOrderList(page, true);
  }
  // because the channel automatically loads after 1 minute
  private setState(page: any = null, per_page: any = null, activeOrder: any = null) {
    const data = {
      activeOrder: activeOrder ?? this.activeOrder,
      paginate: {
        page: page ?? this.paginate.page,
        per_page: per_page ?? 10,
      },
    };

    this.setStateDeliveryChannel(data);
  }
  private get paginate() {
    return this.stateDeliveryChannel.paginate;
  }

   private get activeOrder() {
    return this.stateDeliveryChannel.activeOrder;
  }
  // because the channel automatically loads after 1 minute

  private handleHiddenModal(modalName) {
    this.$modal.hide(modalName);
  }

  private handleShowModal(modalName, order) {
    this.changeActiveOrder(order);
    this.$modal.show(modalName);
  }

  private changeActiveOrder(order) {
    this.setState(null, null, order);
  }

  private goToDetail(delivery) {
    if (this.currentUser.isDoctorRole) {
      return;
    }
    this.$router.push({path: `/deliveries/${delivery.id}`});
  }

  private canCancelOrder(listDelivery) {
    const state = [DELIVERY_STATUS.NOT_PAID, DELIVERY_STATUS.ERROR_PAID];

    return this.checkRole() && lodash.some(listDelivery, (data) => state.includes(data.state));
  }

  private checkIsDoctor() {
    return this.currentUser && this.currentUser.role === Roles.doctor;
  }

  private checkIsAminOrCS() {
     return this.currentUser && includes([Roles.admin, Roles.customerService], this.currentUser.role);
  }

  private canChangePlan(order) {
    return !order.plan.is_shampoo_subscription &&
           !order.isCancelled &&
           order.tracking.some((tracking) => tracking.paid_at != null) &&
           this.checkRole() && [USER_STATE.DOCTOR_PRESCRIPTION_OK, USER_STATE.SUBSCRIPTION_CONTRACT].includes(order.user.state) ;
  }

  private checkRole() {
    return this.currentUser.isAdminRole || this.currentUser.isCSRole;
  }

  private getDeliveryState(state) {
    return DELIVERY_STATUS_NAME[state];
  }

  private deliveryDate(delivery) {
    return moment(delivery).format('YYYY年 M月 D日');
  }

  // get order list from api
  private async getOrderList(page: any = null, isChangePage: boolean = false) {
    try {
    this.isLoading = true;
    const payload = {
      user_id: this.currentChannelUser.id,
      with: 'plan, tracking, user',
      page: isChangePage ? page : this.paginate.page,
      per_page: this.paginate.per_page,
    };
    const response = await ApiHelper
      .getApi('OrderApi')
      .getOrders(payload);
    this.orderList = response.data.map((item) => new Order().deserialize(item));
    this.totalPage = response.last_page;
    // get active order in response;
    const activeOrder = response.data.find((order) => order.id == this.activeOrder.id);

    this.setState(response.current_page, null, activeOrder ?? {});
    this.isLoading = false;
    } catch (error) {
      if (error.message === 'cancel') {
        return;
      }
      this.isLoading = false;
    }
  }

  private cancelOrder() {
    if (this.activeOrder && this.activeOrder.id) {
      // this.isLoading = true;
      this.isSubmitting = true;
      ApiHelper.getApi('DoctorMouthApi').updateOrderStatus(this.activeOrder.id)
        .then((response) => {
          this.getOrderList(1, true);
          Toast.success('更新しました');
        })
        .catch((error) => {
          Toast.error('ビデオ診療予定日は担当医とCSのみ編集できます。');
        })
        .finally(() => {
          this.handleHiddenModal('modalCancelDelivery');
          // this.isLoading = false;
          this.isSubmitting = false;
        });
    }
  }

  private showModalChangePlan(delivery) {
    this.selectedDelivery = delivery;
    this.$modal.show('modalChangePlan');
  }

  private changeOrder(e) {
    this.isSubmitting = true;
    const self = this;

    ApiHelper
      .getApi('OrderChangeApi')
      .changeOrder(this.activeOrder.id, e)
      .then((res) => {
        this.$modal.hide('modalChangePlan');
        this.getOrderList(1, true);
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isSubmitting = false;
      });
  }
}
