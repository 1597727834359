<template lang="pug">
<div class="chime-calling">
  <div class="header">
    <p>{{nameUser}}さまの診察室</p>
  </div>
  <div class="main video-list" :class="{ 'shared-content': isShareScreen }" v-show="!isCallingLoaded &amp;&amp; !isShareScreenLoading &amp;&amp; !isStartCalling">
    <div class="video-container" v-if="!hasVideoCall">
      <video></video>
    </div>
    <audio style="display: none" id="meeting-audio"></audio>
  </div>
  <div class="share-screen-wrap" v-show="isShareScreen &amp;&amp; !isShareScreenLoading &amp;&amp; !isCallingLoaded">
    <video class="share-screen-video"></video>
  </div>
  <div class="footer justify-content-between" v-show="!isStartCalling">
    <div class="share-screen">
      <button @click="toggleShareScreen" :class="{'is-blur': isShareScreenLoading }">
        <span class="fas fa-desktop d-block icon-desktop" ></span><span>{{ !isShareScreen ? '画面共有' : '画面共有を終停止' }}</span>
      </button>
    </div>
    <div class="calling-function">
      <div class="calling-function-icon"  @click="toggleAudioInput">
        <div v-show="!isMute">
          <span class="micro-phone fas fa-microphone"></span>
        </div>
        <div v-show="isMute">
          <span class="micro-phone fas fa-microphone-slash"></span>
        </div>
      </div>
      <div class="calling-function-icon-close" @click="endMeeting">
        <span class="close-call fas fa-times"></span>
      </div>
      <div class="calling-function-icon" @click="onToggleCamera">
        <div v-show="isCameraOpen">
          <span class="fas fa-video"></span>
        </div>
        <div v-show="!isCameraOpen">
          <span class="fas fa-video-slash"></span>
        </div>
      </div>
      </div>
    <div class="other-function">
      <button class="blur-processor" @click="$emit('blurCamera')" :class="{ 'is-blur': isCallingLoaded }" :disabled="isCallingLoaded || !isCameraOpen"><img src="@/assets/img/icon_blur.png" height="22px"/><span>{{ !isBlur ? '背景ぼかし' : '背景ぼかしを停止' }}</span></button>
      <!--.question-->
      <!--  span.far.fa-question-circle.d-block-->
      <!--  span ヘルプ-->
    </div>
  </div>
</div></template>

<script>
export default {
  name: 'ChimeCalling',
  props: {
    meetingSession: {
      type: Object,
      default: () => ({}),
    },
    reservation: {
      type: Object,
      default: () => ({}),
    },
    isCameraOpen: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => true,
    },
    toggleCamera: {
      type: Function,
      default: () => ({}),
    },
    toggleAudioInput: {
      type: Function,
      default: () => ({}),
    },
    audioInputDevice: {
      default: () => ({}),
    },
    videoInputDevice: {
      default: () => ({}),
    },
    audioOutputDevice: {
      default: () => ({}),
    },
    isUserjoin: {
      type: Boolean,
      default: false,
    },
    isCallingLoaded: {
      type: Boolean,
      default: false,
    },
    hasVideoCall: {
      type: Boolean,
      default: false,
    },
    isShareScreen: {
      type: Boolean,
      default: false,
    },
    isShareScreenLoading: {
      type: Boolean,
      default: false,
    },
    isBlur: {
      type: Boolean,
      default: false,
    },
    isStartCalling: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nameUser() {
      return this.reservation.user?.name;
    },
  },
  mounted() {
    const audioOutputElement = document.getElementById('meeting-audio');
    this.meetingSession.audioVideo.bindAudioElement(audioOutputElement);
    this.meetingSession.audioVideo.start();
  },

  methods: {
    onToggleCamera() {
      this.toggleCamera();
    },
    endMeeting() {
      this.$emit('endMeeting');
    },
    toggleShareScreen() {
      this.$emit('toggleShareScreen');
    },
  },
};
</script>

<style lang='scss'>
.chime-calling {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: calc(100vh - 62px);
  background: url("/assets/bg_calling.svg") repeat, radial-gradient(1798px 1798px at 48.4% -2.34%, rgb(38, 57, 79) 0%, rgb(64, 131, 199) 0%, rgb(41, 67, 95) 100%);
  .header {
    p {
      color: white;
      font-size: 20px;
      padding: 20px 10px;
      margin: 0;
    }
  }
  .main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    height: 100px;
    &:not(.shared-content) {
      .video-container {
        margin: auto;
      }
    }
    .video-container {
      padding: 20px;
      video {
        background: url("/images/avatar-default.svg");
        background-color: rgba(38, 57, 79, 0.8);
        background-repeat: no-repeat;
        background-size: 150px 150px;
        background-position: center;
        width: 650px;
        height: 450px;
        border-radius: 15px;
        box-shadow: rgb(68, 153, 238) 0 0 10px;
        border: 2px solid rgb(68, 153, 238);
        object-fit: cover;
      }
    }
    &.shared-content {
      flex-direction: column;
      flex: 0;
      height: auto;
      align-items: flex-end;
      position: fixed;
      right: 0;
      .video-container {
        video {
          background-size: 25px 60px;
          width: 90px;
          height: 70px;
          object-fit: cover;
        }
      }
    }
  }
  .share-screen-wrap {
    display: flex;
    .share-screen-video {
      margin: auto;
      width: 1000px;
      height: 500px;
    }
  }
  .footer {
    background-color: #091933;
    display: flex;
    height: 80px;
    position: relative;
    button,
    .blur-processor,
    .question {
      outline: none;
      border: none;
      text-align: center;
      background: inherit;
      height: 100%;
      padding: 10px;
      min-width: 100px;

      &:hover {
        background-color: rgb(38, 57, 79);
        border-radius: 3px;
      }
      span {
        font-size: 12px;
        &:first-child {
          margin-bottom: 3px;
          padding-top: 10px;
          font-size: 18px !important;
        }
      }
    }
    span {
      color: white;
      cursor: pointer;
      font-size: 20px;
      &.is-blur {
        color: red;
        cursor: not-allowed;
      }
    }

    .calling-function {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100%;

      &-icon {
        border-radius: 50%;
        border: none;
        color: rgb(255, 255, 255);
        cursor: pointer;
        height: 60px;
        margin: 0px 5px;
        outline: none;
        width: 60px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        background: rgba(17, 17, 17, 0.5);
      }

      &-icon-close {
        border-radius: 50%;
        border: none;
        color: rgb(255, 255, 255);
        cursor: pointer;
        height: 60px;
        outline: none;
        width: 60px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        background: rgb(217, 9, 9);
        margin: 8px 5px;
        position: relative;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 60px;
        height: 60px;
        border: 1px solid #02021f;
        border-radius: 50%;
        padding: 10px;
        margin: 0 10px;
        background-color: #02021f;
        &.close-call {
          border: none;
          padding: 0;
          background-color: #d90909;
          margin: 0 20px;
          position: relative;
          color: white;
          font-size: 25px;
        }
      }
    }
    .other-function {
      display: flex;
      .blur-processor {
        font-size: 12px !important;
        color: white;
        height: 100%;
        &:disabled {
          opacity: 0.5;
          cursor: default;
          &:hover {
            background-color: inherit;
          }
        }

        img {
          background-color: white;
          margin: 0 auto 5px;
          display: block;
        }

        span {
          padding: 0;
          font-size: 12px;
        }
      }
    }
  }
  .chime-spin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 150px !important;
    }
    &.is-calling-loaded {
      position: relative;
      bottom: 50%;
    }
  }
  .icon-desktop {
    color: white;
    margin: auto;
  }
}
</style>
