import { Deserializable } from '@common/interfaces/deserializable';
import { Question } from './question';

export interface AnswerInput {
  id: number;
  answer: string;
  question: Question;
  answers: string[];

}

export class Answer implements Deserializable<Answer>, AnswerInput {
  id!: number;
  answer: string;
  question: Question = new Question();
  answers: string[] = [];
  created_at: string;

  deserialize(input: Partial<AnswerInput>): Answer {
    Object.assign(this, input);

    if (input.question) {
      this.question = new Question().deserialize(input.question);
    }

    return this;
  }
}
