

import { Vue, Component } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import {faCameraRetro} from '@fortawesome/free-solid-svg-icons';
import AuthenticationUtils from '@common/AuthenticationUtils';
import ApiHelper from 'api-helper';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { AuthenticationModule } from '@/store';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import UploadImage from '@/components/common/UploadImage.vue';
import DowloadImage from '@/components/common/DowloadImage.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import EditPassword from '../components/EditPassword.vue';
import { mapState } from 'vuex';
import { Roles } from '@common/app.config';

@Component({
  components: {
    UploadImage,
    PopupConfirm,
    EditPassword,
    DowloadImage,
  },
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
})

export default class Edit extends Vue {
  private createDoctorForm =  {
    doctor_email: '',
    doctor_phone: '',
    doctor_name: '',
    doctor_name_kana: '',
    license_code: '',
  };
  private isSubmiting = false;
  private doctorImage: string | Blob = '';
  private isChangeImage = false;
  private currentUser: any;
  private doctor = {};
  private clinics = [];
  private uploadFile = {
    avatar: '',
  };

  private get computedForm() {
    return this.$refs.createClinicForm as any;
  }

  private get avatarDoctor() {
    return (this.doctor as any).avatar;
  }

  private get licenseDoctor() {
    if ((this.doctor as any).license) {
      return (this.doctor as any).license.license_file;
    }
    return '';
  }

  private get licenseImage() {
    if ((this.doctor as any).license) {
      return (this.doctor as any).license.license_url;
    }
    return '';
  }

  private get licenseFile() {
    if ((this.doctor as any).license) {
      return (this.doctor as any).license.license_file;
    }
    return '';
  }

  private get licenseCode() {
    if ((this.doctor as any).license) {
      return (this.doctor as any).license.license_code;
    }
    return '';
  }


  private mounted() {
    library.add(faListUl);
    dom.watch();
  }

  private created() {
    this.getDetail(this.currentUser.id, {with: 'clinics,license'});
  }

  private get phoneErrorMessages() {
    return {
      max: '電話番号を正しく入力してください。',
      min: '電話番号を正しく入力してください。',
      numeric: '電話番号は半角数字で入力してください（ハイフンなし）',
    };
  }

  private onChangeDoctorImage(file) {
    this.isChangeImage = true;
    this.doctorImage = file;
  }

  private getNoClinics(index) {
    return `&#931${index + 2}`;
  }

  private get emailErrorMessages() {
    return {
      email: 'メールアドレスを正しく入力してください。',
      max: 'メールアドレスは255文字以内で入力してください。',
    };
  }

  private get passwordErrorMessages() {
    return {
      min: 'パスワードを半角英数字8桁以上で入力してください。',
      required: 'パスワードは8桁以上半角英数字で入力してください。',
    };
  }

  private get confirmationErrorMessages() {
    return {
      required: 'パスワード(確認)は8桁以上半角英数字で入力してください。',
      confirmed: 'パスワードが一致しません。正しいパスワードを入力してください。',
    };
  }

  private get doctorNameKanaErrorMessages() {
    return {
      regex: '全角カタカナで入力してください',
      max: 'ドクター名(カナ)は100文字以内で入力してください。',
    };
  }

  private get doctorNameErrorMessages() {
    return {
      max: 'ドクター名は100文字以内で入力してください',
    };
  }

  private async getDetail(id, param) {
    try {
      const response = await ApiHelper.getApi('DoctorApi').getDetail(id, param);
      this.doctor = response;
      await this.$store.dispatch('auth/updateUser', {...response, role: Roles.doctor});
      this.clinics = response.clinics;
      this.createDoctorForm.doctor_phone = response.phone.replace(/^(84|81)/gi, '0');
      this.createDoctorForm.doctor_email = response.email;
      this.createDoctorForm.doctor_name = response.name;
      this.createDoctorForm.license_code = this.licenseCode;
      this.createDoctorForm.doctor_name_kana = response.name_kana;
      // this.uploadFile.avatar = response.avatar;
    } catch (error) {
      const errors = error.response.data;

      Toast.error(errors.message);
    }
  }

  private cancelPopupUpdateConfirm() {
    this.$modal.hide('confirmUpdateDoctor');
  }

  private async openConfirmUpdateDoctor() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }

    this.$modal.show('confirmUpdateDoctor');
  }

  private async updateInfo() {
    this.$modal.hide('confirmUpdateDoctor');
    try {
      this.isSubmiting = true;
      if (this.isChangeImage) {
        const formData = new FormData();
        formData.append('license_file', this.doctorImage);
        await ApiHelper.getApi('DoctorApi').uploadLicense(this.currentUser.id, formData);
      }
      const response = await ApiHelper.getApi('DoctorApi')
      .update(this.currentUser.id, {...this.createDoctorForm, doctor_phone: this.createDoctorForm.doctor_phone});

      this.isSubmiting = false;
      this.$modal.hide('confirmUpdateDoctor');
      this.getDetail(this.currentUser.id, {with: 'clinics,license'});
      Toast.success('更新しました');
    } catch (error) {
      this.isSubmiting = false;
      const { data, status } = error.response;

      if (status === 422) {
        this.computedForm.setErrors(data.errors);
        Toast.error(data.message);
        return;
      }
      Toast.error(data.message);
    }
  }

  private async updateImage(avatar) {
    try {
      this.isSubmiting = true;
      const param = {
        avatar,
      };
      const response = await ApiHelper.getApi('DoctorApi').uploadImage(this.currentUser.id, param);
      this.getDetail(this.currentUser.id, {with: 'clinics,license'});
      this.isSubmiting = false;
      this.uploadFile.avatar = avatar;
    } catch (error) {
      this.isSubmiting = false;
      const errors = error.response.data;

      Toast.error(errors.message);
    }
  }
}
