import Index from '@/views/settings/index.vue';
import CreatePost from '@/views/settings/posts/create.vue';
import DetailPost from '@/views/settings/posts/detail.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.superAdmin],
};

export default [
  {
    path: '/settings',
    name: 'settings',
    component: Index,
    meta: authMeta,
  },
  {
    path: '/settings/posts/create',
    name: 'news.create',
    component: CreatePost,
    meta: authMeta,
  },
  {
    path: '/settings/posts/:id',
    name: 'news.detail',
    component: DetailPost,
    meta: authMeta,
  },
];
