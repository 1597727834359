



import { Vue, Component } from 'vue-property-decorator';
import PharmacistProfile from '@components/Pharmacist/PharmacistProfile.vue';

@Component({
  components: {
    PharmacistProfile,
  },
})

export default class Profile extends Vue {}
