import BaseApi from './BaseApi';

class DoctorApi extends BaseApi {
  public list(params: any) {
    return this.get('/', params);
  }

  public delete(id) {
    return this.destroy(id, {});
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/doctor-admin';
  }
}

export default DoctorApi;
