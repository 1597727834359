













import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IQuestion } from '@/models/question';
import { State } from 'vuex-class';
import { Roles } from '../../../common/app.config';

@Component({})
export default class UploadFile extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  @Prop() editMode!: boolean;
  @Prop({ required: true }) question!: IQuestion;

  private get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }
}
