














































































import { Vue, Component } from 'vue-property-decorator';

import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';

@Component({
  head: {
    title: {
      inner: 'パスワード再設定',
    },
  },
})
export default class ForgotPassword extends Vue {
  isSubmiting = false;
  isResendSuccess = false;
  resetForm =  {
    email: '',
  };

  get computedForm() {
    return this.$refs.resetForm as any;
  }

  get emailErrorMessages() {
    return {
      required: '※必須項目が入力されていません',
      email: 'メールは有効なメールアドレスで入力してください',
    };
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  updateIsResendSuccess(isResendSuccess: boolean) {
    this.isResendSuccess = isResendSuccess;
  }

  async forgotPassword() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    try {
      this.updateIsSubmiting(true);
      const response = await ApiHelper.getApi('UserApi').resetPassword(this.resetForm);
      this.updateIsSubmiting(false);
      this.updateIsResendSuccess(true);
      this.$router.push({ name: 'ForgotPasswordSentMail' });
    } catch (error) {
      this.updateIsSubmiting(false);
      this.updateIsResendSuccess(false);
      const data = error.response.data;

      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
      Toast.error(data.message);
      this.$router.push({ name: 'ForgotPasswordSentMail' });
    }
  }
}
