







import TemplateClinicForm from '@components/ClinicPattern/Form.vue';
import ContentLayout from '@components/layouts/Content.vue';
import Listicon from '@/assets/img/clinicListicon.svg';

export default {
    components: {
        TemplateClinicForm,
        ContentLayout,
    },
    data() {
        return{
            iconHeader: Listicon,
        };
    },
};
