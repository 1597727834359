import Echo from 'laravel-echo';
import io from 'socket.io-client';
import AuthenticationUtils from '@common/AuthenticationUtils';
import { config } from '@common/app.config';

export const EVENT_MESSAGE_CREATED = 'MessageCreated';
export const EVENT_MESSAGE_UNREAD = 'UnreadMessage';
export const EVENT_TOTAL_MESSAGE_UNREAD = 'TotalUnreadMessage';
export const EVENT_USER_STATUS_UPDATED = 'UserStatusUpdated';
// export const EVENT_STOP_CALLING = 'StopCalling';
// export const EVENT_CREATED_RESV = 'ReservationCreated';
// export const EVENT_START_RESV_BEFORE = 'BeforeStartReservation';
// export const EVENT_CREATED_PHARMACIST_RESV = 'PharmacistReservationCreatedEvent';
// export const EVENT_BEFORE_START_P_RESV_ = 'BeforeStartPharmacistReservationEvent';
// export const EVENT_MESSAGE_DELETED = 'MessageDeleted';
// export const EVENT_UPLOAD_CSV_FINISH = 'ImportCSV';
// export const EVENT_UPLOAD_CSV_FAIL = 'ImportCSVFail';
// export const EVENT_CHANGE_DOCTOR_STATUS = 'AdminChangeStatusOrder';
// export const EVENT_USER_PAID_SUCCESS = 'UserPaidSuccess';
// export const EVENT_ASS_DOCTOR_TO_RESERVATION = 'AssginDoctorToReservation';

class SocketService {
  echo: any;
  channel: any;
  currentUser: any;

  init(user) {
    this.currentUser = user;
    if (this.echo) {
      this.authBroadcast();
      this.listenPrivateChannel();
      return;
    }
    (window as any).io = io;

    this.echo = new Echo({
      transports: ['websocket', 'polling', 'flashsocket'],
      broadcaster: 'socket.io',
      host: config.socketUrl,
      auth: { headers: { Authorization: `Bearer ${AuthenticationUtils.getAccessToken()}` } },
    });
    this.authBroadcast();
    this.listenPrivateChannel();
  }

  disconnect() {
    if (this.echo) {
      this.echo.disconnect();
      this.echo = null;
      return;
    }
  }

  authBroadcast() {
    this.echo.connect();
    const eventName = `manager.${this.currentUser.id}`;
    this.channel = this.echo.private(eventName);
  }

  listenPrivateChannel() {
    if (!this.channel) {
      this.authBroadcast();
    }

    this.onMessage();
    this.onMessageUnread();
    this.onTotalMessageUnread();
    this.onUserStatusUpdated();

    // this.onReservationCreated();
    // this.onStartReservationBeforeTenMinute();
    // this.onPharmacistResvCreated();
    // this.onBeforeStartPharmacistResv();
    // this.onMessageDeleted();
    // this.onUploadCSV();
    // this.onUploadCSVFail();
    // this.onLoadDoctorStatus();
    // this.onLoadOrderComplete();
    // this.onLoadAsNewDoctor();
  }

  onMessage() {
    this.channel.listen(EVENT_MESSAGE_CREATED, (response) => {
      if (response.socket_type !== 'message') {
        return;
      }
      const {data} = response;
      const { channel } = data;
      delete data.channel;
      // (window as any).app.$broadcast('NewMessage', data);
      // update chat box.
      (window as any).app.$broadcast(`OnMessageCreated_${data.channel_id}`, data);

      // update latest message of sidebar.
      (window as any).app.$broadcast('UpdateLatestMessage', data);

      // remove 2 event below event if release ok.
      (window as any).app.$broadcast('UpdateNewChannel', channel);
      (window as any).app.$broadcast('UpdateMessageChannel', data);
    });
  }

  onMessageUnread() {
    this.channel.listen(EVENT_MESSAGE_UNREAD, (response) => {
      if (response.socket_type !== 'unread') {
        return;
      }
      const { data } = response;
      // (window as any).app.$broadcast(`UnreadCount_${data.channelId}`, data);
      (window as any).app.$broadcast(`UpdateUnreadCount`, data);
    });
  }

  onUserStatusUpdated() {
    this.channel.listen(EVENT_USER_STATUS_UPDATED, (response) => {
      if (response.socket_type !== 'user_state_updated') {
        return;
      }
      (window as any).app.$broadcast(`UpdateUserState`, response);
    });
  }

  onTotalMessageUnread() {
    this.channel.listen(EVENT_TOTAL_MESSAGE_UNREAD, (response) => {
      if (response.socket_type !== 'unread') {
        return;
      }
      const { data } = response;
      (window as any).app.$broadcast('TotalUnreadCount', data);
    });
  }

  // onLoadAsNewDoctor() {
  //   this.channel.listen(EVENT_ASS_DOCTOR_TO_RESERVATION, (response) => {
  //     console.log('response', response);
  //     if (response.socket_type !== 'assgin_doctor_to_reservation') {
  //       return;
  //     }
  //     console.log('response 1111111', response);
  //     (window as any).app.$broadcast('LoadDoctorStatus', response);
  //   });
  // }
  //
  // onLoadOrderComplete() {
  //   this.channel.listen(EVENT_USER_PAID_SUCCESS, (response) => {
  //     if (response.socket_type !== 'user_paid_success') {
  //       return;
  //     }
  //     (window as any).app.$broadcast('LoadDoctorStatus', response);
  //   });
  // }
  //
  // onLoadDoctorStatus() {
  //   this.channel.listen(EVENT_CHANGE_DOCTOR_STATUS, (response) => {
  //     if (response.socket_type !== 'admin_change_status_order') {
  //       return;
  //     }
  //     (window as any).app.$broadcast('LoadDoctorStatus', response);
  //   });
  // }
  //
  // listenStopCalling() {
  //   if (!this.channel) {
  //     this.authBroadcast();
  //   }
  //
  //   this.channel.listen(EVENT_STOP_CALLING, (response) => {
  //     if (response.socket_type !== 'room_ended') {
  //       return;
  //     }
  //     const { data } = response;
  //     (window as any).app.$broadcast('StopCalling', data);
  //   });
  // }
  //
  // stopListenEventStopCalling() {
  //   this.channel.stopListening(EVENT_STOP_CALLING);
  // }
  //
  // onMessageDeleted() {
  //   this.channel.listen(EVENT_MESSAGE_DELETED, (response) => {
  //     if (response.socket_type !== 'delete_message') {
  //       return;
  //     }
  //     const { data } = response;
  //     (window as any).app.$broadcast('MessageDeleted', data);
  //   });
  // }
  //
  //
  // onPharmacistResvCreated() {
  //   this.channel.listen(EVENT_CREATED_PHARMACIST_RESV, (response) => {
  //     if (response.socket_type !== 'notification_pharmacist_reservation_created') {
  //       return;
  //     }
  //     (window as any).app.$broadcast(`CreatedPharmacistResv`, response.data);
  //   });
  // }
  //
  // onReservationCreated() {
  //   this.channel.listen(EVENT_CREATED_RESV, (response) => {
  //     if (response.socket_type !== 'notification_resv_created') {
  //       return;
  //     }
  //
  //     const channelId = response.data.channel_id;
  //     (window as any).app.$broadcast(`CreatedResv`, channelId);
  //   });
  // }
  //
  // onBeforeStartPharmacistResv() {
  //   this.channel.listen(EVENT_BEFORE_START_P_RESV_, (response) => {
  //     if (response.socket_type !== 'notification_10_minutes_before_pharmacist_reservation_start') {
  //       return;
  //     }
  //     const { data } = response;
  //
  //     (window as any).app.$broadcast(`StartReservationBefore`, {
  //       resv_id: data.reservation_id,
  //       start_at: data.start_at,
  //       isPharmacistResv: true,
  //     });
  //   });
  // }
  //
  // onStartReservationBeforeTenMinute() {
  //   this.channel.listen(EVENT_START_RESV_BEFORE, (response) => {
  //     if (response.socket_type !== 'notification_10_minutes_before_resv_start') {
  //       return;
  //     }
  //     const { data } = response;
  //
  //     (window as any).app.$broadcast(`StartReservationBefore`, data);
  //   });
  // }

  //
  // onUploadCSV() {
  //   this.channel.listen(EVENT_UPLOAD_CSV_FINISH, (response) => {
  //
  //     const { data } = response;
  //     (window as any).app.$broadcast('UploadCSVFinish', data);
  //   });
  // }
  //
  // onUploadCSVFail() {
  //   this.channel.listen(EVENT_UPLOAD_CSV_FAIL, (response) => {
  //
  //     const { data } = response;
  //     (window as any).app.$broadcast('UploadCSVFail', data);
  //   });
  // }
}

export default new SocketService();
