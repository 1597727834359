



























































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import IconDeleteSvg from '../iconSVG/Delete.vue';
import IconEditSvg from '../iconSVG/Edit.vue';
import Toast from '@common/helpers/toast';
import moment from 'moment';

@Component({
  components: {
    ImagePrivate,
    IconDeleteSvg,
    IconEditSvg,
  },
  filters: {
    formatType(value) {
      if (!value.length) { return ''; }
      let valueType = '';
      value.forEach((type) => {
        if (type === 1) {
          valueType = valueType.concat('治療は順調に進捗しています。このまましっかりマウスピースをつけましょう。\n');
        } else if (type === 2) {
          valueType = valueType.concat('少し浮きがありますので、アライナーチューイをしっかり噛み込むことを意識しましょう。\n');
        } else {
          valueType = valueType.concat('治療計画とずれがみとめられます。治療期間を再調整させてください。担当医師よりオンライン診断日時調整のメッセージが届きます。');
        }
      });
      return valueType;
    },
    formatTime(value) {
      return moment(value).format('YYYY年 MM月DD日 HH:mm');
    },
  },
})
export default class PatientDetailFeedBack extends Vue {

  private get readyAdd() {
    return this.progress.progressFirst || this.progress.progressSecond || this.progress.progressThree;
  }
  @Prop(Number) readonly isCloseChannel!: number;
  @Prop(Array) readonly listFeedback!: [];
  @Prop(Object) readonly dataPatientDetail: object;
  @Prop(Object) readonly listResvClinicPattern!: object;
  @Prop(Boolean) readonly isAdmin!: false;
  @Prop(Boolean) readonly isUserUploadImage!: false;
  @Prop(Boolean) readonly isWaitingChannel!: false;
  @Prop(String) readonly titleFeedback: string;
  private showAdd = false;
  private progress = {
    progressFirst: false,
    progressSecond: false,
    progressThree: false,
  };
  private comment = '';
  private listFeedBackData = [];

  mounted() {
    this.listFeedBackData = this.listFeedback || [];
  }

  @Watch('listFeedback')
  private onListFeedback() {
    this.listFeedBackData = this.listFeedback || [];
  }

  private reset() {
    this.comment = '';
    this.progress = {
      progressFirst: false,
      progressSecond: false,
      progressThree: false,
    };
  }

  private showAddFeedBack() {
    this.showAdd = true;
  }

  private cancelAddFeedBack() {
    this.showAdd = false;
  }

  private addFeedBack() {
    const listType: number[] = [];
    if (this.progress.progressFirst) {
      listType.push(1);
    }
    if (this.progress.progressSecond) {
      listType.push(2);
    }
    if (this.progress.progressThree) {
      listType.push(3);
    }

    const body = {
      user_id: (this.dataPatientDetail as any).id,
      type: listType,
      content: this.comment,
    };
    ApiHelper.getApi('DoctorMouthApi')
      .createFeedback(body)
      .then(() => {
        this.showAdd = false;
        this.reset();
        this.$emit('reload');
      })
      .catch((error) => {
        Toast.error(error.message);
      });
  }
}
