import QuestionListEdit from '@/views/question/containers/QuestionListEdit.vue';
import { authAdminDoctorClinic } from '@/common/constants/auth';

export default [
  {
    path: '/diagnosis/detail/:id/questions',
    name: 'question.list-edit',
    component: QuestionListEdit,
    meta: authAdminDoctorClinic,
  },
];
