import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import ApiHelper from 'api-helper';
import { Response, ModelResv, QueryResv, defaultDataResv } from '@/models/resv';
import Toast from '@common/helpers/toast';

@Module({ name: 'resv', namespaced: true })
export default class Resv extends VuexModule {
  public dataResv: ModelResv[] = [];
  public dataResvDetail: ModelResv = JSON.parse(JSON.stringify(defaultDataResv));
  public total: number = 0;
  public totalPage: number = 0;
  public prescriptions = [];
  public isLoading = false;

  get isEmptyDataResv() {
    return !this.dataResv.length;
  }

  get isShowPagination() {
    return this.totalPage > 1;
  }

  @Action({ rawError: true, commit: 'SET_DATA_RESV' })
  public async getResvList(params?: QueryResv) {
    const { commit, state } = this.context;
    try {
      commit('updateIsLoading', true);
      const response = await ApiHelper.getApi('ResvApi').getList(params);
      commit('updateIsLoading', false);
      return response;
    } catch (error) {
      commit('updateIsLoading', false);
      const data = error.response.data;
      Toast.error(data.message);
    }
  }

  @Action({ rawError: true, commit: 'SET_DATA_RESV' })
  public async getResvListIndex(params?: QueryResv) {
    const { commit, state } = this.context;
    try {
      commit('updateIsLoading', true);
      if (this.dataResv.length) {
        commit('RESET_STATE');
      }
      const response = await ApiHelper.getApi('ResvApi').getList(params);
      commit('updateIsLoading', false);
      return response;
    } catch (error) {
      commit('updateIsLoading', false);
      const data = error.response.data;
      Toast.error(data.message);
    }
  }

  @Action({ rawError: true, commit: 'SET_DATA_RESV_DETAIL' })
  public async getResvDetail(data: {
    id: number | string;
    params?: { with: string };
  }): Promise<ModelResv> {
    const response = await ApiHelper.getApi('ResvApi').getOne(data.id, data.params);

    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_RESV_DETAIL' })
  public async updateResvMemoDetail(data: {id: number | string, body: { doctor_memo: string }}) {
    await ApiHelper.getApi('ResvApi').updateMemo(data.id, data.body);
    return data.body;
  }

  @Action({ rawError: true, commit: 'SET_PRESCRIPTIONS' })
  public async createPrescriptions(data: {id: number | string, body: {}}) {
    const response = await ApiHelper.getApi('ResvApi').createPrescription(data.id, data.body);
    return response;
  }

  @Action({ rawError: true })
  public async updatePrescriptionsResv(data: {id: number | string, body: { prescription_ids: number[] }}) {
    await ApiHelper.getApi('ResvApi').updatePrescriptionsResv(data.id, data.body);
    return data.body;
  }

  @Action({ rawError: true })
  public async startResv(id: number | string) {
    const response = await ApiHelper.getApi('ResvApi').startResv(id, {});
    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_RESV_DETAIL' })
  public async finishResv(params: any) {
    const id = params.id;
    const data = {
      amounts: params.amounts,
    };

    try {
      const response = await ApiHelper.getApi('ResvApi').finishResv(id, data);
      Toast.success('診察が終了されました');

      return response;
    } catch (error) {
      const err = error.response.data;
      Toast.error(err.message);
    }
  }

  @Action({ rawError: true, commit: 'SET_DATA_RESV_DETAIL' })
  public async updateAmount(params: any) {
    const id = params.id;
    const data = {
      amounts: params.amounts,
    };

    try {
      const response = await ApiHelper.getApi('ResvApi').updateAmount(id, data);
      Toast.success('診察が終了されました');

      return response;
    } catch (error) {
      const err = error.response.data;
      Toast.error(err.message);
    }
  }

  @Action({ rawError: true })
  public async deletePrescription(data: {id: number | string, params}) {
    const response = await ApiHelper.getApi('ResvApi').deletePrescription(data.id, data.params);
    return response;
  }

  @Action({ rawError: true })
  public async uploadBill(data: {id: number | string, params: any}) {
    try {
      const response = await ApiHelper.getApi('ResvApi').uploadBill(data.id, data.params);
      Toast.success('更新しました');

      return response;
    } catch (error) {
      const err = error.response.data;
      if (err.errors.file && (err.errors.file[0] === 'fileはpdf, jpg, jpeg, pngのファイルでアップロードしてください')) {
        Toast.error('jpgやjpegやpngやpdf形式のファイルでアップロードしてください');

        return;
      }

      Toast.error(err.message);
    }
  }

  @Action({ rawError: true })
  public async sendFax(data: {id: number | string, params: any}) {
    try {
      const response = await ApiHelper.getApi('ResvApi').sendFax(data.id, data.params);
      Toast.success('FAXを送信しました');
      // Toast.success('メールを送信しました');

      return response;
    } catch (error) {
      const err = error.response.data;
      Toast.error(err.message);
    }
  }

  @Action({ rawError: true })
  public async uploadPrescription(data: {id: number | string, params: any}) {
    try {
      const response = await ApiHelper.getApi('ResvApi').uploadPrescription(data.id, data.params);
      Toast.success('更新しました');

      return response;
    } catch (error) {
      const err = error.response.data;
      if (err.errors.file && (err.errors.file[0] === 'fileはpdf, jpg, jpeg, pngのファイルでアップロードしてください')) {
        Toast.error('jpgやjpegやpngやpdf形式のファイルでアップロードしてください');

        return;
      }

      Toast.error(err.message);
    }
  }

  @Mutation
  public SET_PRESCRIPTIONS(response) {
    this.prescriptions = response;
  }

  @Mutation
  public SET_DATA_RESV(response: Response) {
    this.dataResv = response.data;
    this.total = response.total;
    this.totalPage = response.last_page;
  }

  @Mutation
  public SET_DATA_RESV_DETAIL(data: ModelResv) {
    this.dataResvDetail = {...this.dataResvDetail, ...data};
  }

  @Mutation
  public RESET_STATE() {
    this.dataResv = [];
    this.dataResvDetail = JSON.parse(JSON.stringify(defaultDataResv));
    this.total = 0;
    this.totalPage = 0;
  }

  @Mutation
  public updateIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
}
