










































// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import LodashGet from 'lodash/get';

// interface
import { Patient } from '@/models/patient';

// component
import Avatar from '@components/v2/Avatar.vue';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    Avatar,
  },
})
export default class PatientControlAvatar extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  get doctorName() {
    return LodashGet(this.activeTreatment, 'reservations[0].doctor.name', '-');
  }

  get clinicName() {
    return LodashGet(this.patient, 'clinics[0].name', '-');
  }

  get userId() {
    return LodashGet(this.activeTreatment, 'user_id', '-');
  }
}
