








































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PopupConfirm extends Vue {
  @Prop(String) readonly name!: string;
  @Prop({ default: 'キャンセル' }) readonly btnCancelText!: string;
  @Prop({ default: '削除' }) readonly btnSubmitText!: string;
  @Prop({ default: '本当に削除しますか？'}) readonly contentTitle!: string;
  @Prop(String) readonly contentText!: string;
  @Prop({ default: true }) readonly clickToClose!: boolean;
  @Prop(Boolean) readonly disabledBtnSubmit!: boolean;
  @Prop(Boolean) readonly disabledBtnCancel!: boolean;

  get hasContentSlot() {
    return !!this.$slots.content;
  }

  beforeOpen() {
    document.documentElement.style.overflow = 'hidden';
  }

  beforeClose() {
    document.documentElement.style.overflow = 'auto';
  }
}
