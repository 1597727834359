import BaseApi from './BaseApi';
class ShiftApi extends BaseApi {

  public async create(params: any) {
    return await this.post(`/`, params);
  }

  public list(params: any) {
    return this.get('/', params);
  }

  public getDetail(id, params) {
    return this.get(`/${id}`, params);
  }

  public delete(id, params: any) {
    return this.del(`/${id}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/shifts';
  }
}

export default ShiftApi;
