






















































































import {Component, Mixins, Prop} from 'vue-property-decorator';
// import { Plan } from '@/models/plan';
import MessageMixin from './Message';
import { MESSAGE_BUTTON_TYPE } from '@/common/enums/message';
import MessageTreatment from './MessageTreatment.vue';

@Component({
  components: {
    MessageTreatment,
  },
  filters: {
    titlePayment: (value) => {
      return value === 1 ? '月額' : '合計';
    },
    formatNumPrice: (value) => {
      if (Number(value) === 0) { return '0万円'; }
      if (!value) { return ''; }
      value = Number(value) + Number(value) / 10;
      value = Math.ceil(value).toString();
      value = value.substr(0, value.length - 1) + '0';
      value = Number(value) / 10000;
      value = Math.ceil(Number((value * 100).toFixed(2))) / 100;
      return parseFloat(value) + '万円';
    },
  },
})
export default class MessagePlan extends Mixins(MessageMixin) {
  @Prop() avatarUser!: any;
  @Prop() avatarManager!: any;
  suggestPlan: any = {};
  treatment: any = {};
  tempMessageContent = {
    action: MESSAGE_BUTTON_TYPE.CALLING,
  };
  mounted() {
    this.tempMessageContent = JSON.parse(this.message.message_content);

    if (this.message.suggest_plan && this.message.suggest_plan.id) {
      this.suggestPlan = this.message.suggest_plan || {};
      this.treatment = this.message.suggest_plan.treatment || {};
    }
  }
}
