




































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import moment from 'moment';
import ApiHelper from 'api-helper';
import lodash from 'lodash';

import ImagePrivate from '@/components/common/ImagePrivate.vue';
import imageDefault from '@/assets/img/icon-no-image.svg';
import { uiAvatars } from '@common/helpers/ui-avatar';
import Toast from '@common/helpers/toast';
import UpdatePatientInfoModal from '@/components/common/UpdatePatientInfoModal.vue';
import imageIdentityDefault from '@/assets/img/identity.svg';
import { USER_STATUS } from '@/common/constants/auth';
import { Roles, formatDateTimeJp2, formatDateTimeJp } from '@common/app.config';
import locale from 'ant-design-vue/es/date-picker/locale/ja_JP';
import ImageZoomComponent from '@/components/Patient/ImageZoomComponent.vue';
import ModalReservationDate from './ModalReservationDate.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { PatientModule } from '@/store';
import UpdateNote from '@/components/Patient/ModalUpdateNote.vue';
import { GENDER_NAME, GENDER_KEY } from '@/common/enums/gender';

@Component({
  data() {
    return {
      locale,
      moment,
      lodash,
      USER_STATUS,
      Roles,
    };
  },
  components: {
    ImagePrivate,
    UpdatePatientInfoModal,
    ImageZoomComponent,
    ModalReservationDate,
    PopupConfirm,
    UpdateNote,
  },
  computed: {
    ...mapState('auth', ['currentUser', 'isGettingUserInfo']),
    ...mapState('channel', ['channels']),
    ...mapState('auth', ['currentUser']),
  },
})
export default class PatientInfoChannel extends Vue {
  private get editPatientModalRef() {
    return this.$refs.editPatientModal as any;
  }

  private get canDeleteUser() {
    return (
      this.currentUser.isAdminRole ||
      this.currentUser.isDoctorRole ||
      this.currentUser.isCSRole
    );
  }

  private get canEditUser() {
    return this.currentUser.isAdminRole || this.currentUser.isCSRole;
  }

  private get canUpdateNote() {
    return (
      this.currentUser.isAdminRole ||
      this.currentUser.isPharmacistRole ||
      this.currentUser.isCSRole
    );
  }

  private get canVerify() {
    return (
      this.currentUser.isCSRole &&
      this.currentChannelUser.state === USER_STATUS.IDENTITY_CHECK &&
      this.currentChannelUser.front_side_card
    );
  }

  private get canDisplayReservationDate() {
    return lodash.includes(
      [USER_STATUS.CONFIRMED_RESERVATION, USER_STATUS.VIDEO_INTERVIEW_DATE],
      this.currentChannelUser.state,
    );
  }

  get canEditReservation() {
    return (
      [
        USER_STATUS.CONFIRMED_RESERVATION,
        USER_STATUS.VIDEO_INTERVIEW_DATE,
      ].includes(this.currentChannelUser.state) &&
      (this.currentUser.isDoctorRole ||
        this.currentUser.isCSRole ||
        this.currentUser.isAdminRole)
    );
  }

  private get channelAvatar() {
    if (this.currentChannel && this.currentChannel.users) {
      const user = this.currentChannel.users[0] || {};
      const name = `${user.family_name} ${user.given_name}`;

      if (!user.name && !user.family_name && !user.given_name) {
        return imageDefault;
      }

      const avatarUser = user.avatar || uiAvatars(name);
      return avatarUser;
    }
  }

  private get gender() {
    return this.currentChannelUser?.gender_id == GENDER_KEY.MALE
      ? GENDER_NAME.MALE
      : GENDER_NAME.FEMALE;
  }

  private get channelAvatarText() {
    if (this.currentChannel && this.currentChannel.users) {
      const user = this.currentChannel.users[0] || {};
      if (!user.family_name && !user.given_name) {
        return imageDefault;
      }
      const name = `${user.family_name} ${user.given_name}`;
      return uiAvatars(name);
    }
  }

  private get channelUserDateOfBirth() {
    const dateOfBirth =
      this.currentChannelUser && this.currentChannelUser.date_of_birth;
    if (!dateOfBirth) {
      return this.noData;
    }
    return moment(dateOfBirth).format('YYYY年 MM月 DD日');
  }

  private get noteAt() {
    return moment(this.currentChannelUser.noted_at).format(
      'YYYY年MM月DD日 HH:mm',
    );
  }

  private get channelUserRegisterDate() {
    return (
      this.currentChannelUser &&
      moment(this.currentChannelUser.created_at).format('YYYY年 MM月 DD日')
    );
  }

  private get latestReservation() {
    return this.currentChannelUser?.latestReservation || {};
  }

  private get channelUserReservationDate() {
    return lodash.get(
      this.currentChannelUser,
      'latestReservation.reservation_date',
    )
      ? moment(
          this.currentChannelUser.latestReservation.reservation_date,
        ).format('YYYY年 MM月 DD日 HH:mm')
      : '';
  }

  // get isShowReservationConfirm() {
  //   return this.currentUser.isDoctorRole;
  // }

  get isSMSVerified() {
    return !lodash.isEmpty(this.currentChannelUser.otp_verified_at);
  }

  get doctorName() {
    // if (this.fetchingDoctorDetail) {
    //   return '';
    // }
    // if (this.currentUser && this.currentUser.latestReservation) {
    //   return this.currentUser.name;
    // }
    return (
      lodash.get(this.currentChannelUser, 'latestReservation.doctor.name') ||
      this.noData
    );
  }

  get formatDateTimeJp2() {
    return formatDateTimeJp2;
  }

  get formatDateTimeJp() {
    return formatDateTimeJp;
  }

  private get computedForm() {
    return this.$refs.currentChannelUser as any;
  }

  get clinicName() {
    return this.currentChannelUser && this.currentChannelUser.clinics
      ? this.currentChannelUser.clinics[0].name
      : '';
  }
  // doctorDetail: any = {};
  // fetchingDoctorDetail: boolean = false;
  noData: string = '-';
  latestReservationEdit: any = {};
  @Prop() activeTab!: any;
  @Prop() currentChannel!: any;
  @Prop() currentChannelUser!: any;
  @Prop() urlFrontPath!: any;
  @Prop() urlBackPath!: any;
  @Prop() urlHeadPath!: any;
  @Prop() changeCurrentChannelUser: any;

  reservationDate1: any = '';
  reservationDate2: any = '';
  reservationDate3: any = '';
  reservationDate: any = {};
  disabledDateTime1: any = [];
  disabledDateTime2: any = [];
  disabledDateTime3: any = [];
  ampm1: any = '';
  ampm2: any = '';
  ampm3: any = '';
  messageConfirmDel: string = '';
  // isShowReservationNotification: boolean = false;

  private USER_STATUS = USER_STATUS;
  private Roles = Roles;
  private currentUser: any;
  private isSubmitting = false;
  private imageIdentityDefault: any = imageIdentityDefault;
  private listPrefecture: any[] = [];
  private isChangeState = false;
  private defaultFrontIndentityImage: any = '';
  private defaultBackIndentityImage: any = '';
  private defaultHeadImage: any = '';

  async created() {
    try {
      this.listPrefecture = (
        await ApiHelper.getApi('PrefectureApi').listPrefecture()
      ).data;
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    // this.showReservationNotification();
  }

  get isShowResvNotify() {
    return (
      this.currentUser.isDoctorRole &&
      this.currentChannelUser.state === USER_STATUS.CONFIRMED_RESERVATION
    );
  }

  // showReservationNotification() {
  //   if (this.isShowReservationConfirm) {
  //     this.isShowReservationNotification = true;
  //   }
  // }

  onChangePatientIdentify(status) {
    if (this.isSubmitting || !status) {
      return;
    }

    this.isSubmitting = true;

    ApiHelper.getApi('PatientApi')
      .identification(this.currentChannelUser.id, { state: status })
      .then((res: any) => {
        this.changeCurrentChannelUser(res);
        this.isChangeState = true;
        Toast.success(
          status === USER_STATUS.IDENTIFICATION_NG ? '本人確認NG' : '本人確認OK',
        );
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  async showReservation() {
    ApiHelper.getApi('ManagerApi')
      .getLatestReservation(this.currentChannelUser.id)
      .then((res: any) => {
        this.reservationDate = res;
        this.reservationDate1 =
          res && res.date1 ? moment(res.date1).format('YYYY-MM-DD') : '';
        this.reservationDate2 =
          res && res.date2 ? moment(res.date2).format('YYYY-MM-DD') : '';
        this.reservationDate3 =
          res && res.date3 ? moment(res.date3).format('YYYY-MM-DD') : '';
        this.disabledDateTime1 = this.disabledDateTime(res.ampm1);
        this.disabledDateTime2 = this.disabledDateTime(res.ampm2);
        this.disabledDateTime3 = this.disabledDateTime(res.ampm3);
        this.ampm1 = `第1希望/${res.ampm1 === 'am' ? '午前' : '午後'}`;
        this.ampm2 = `第2希望/${res.ampm2 === 'am' ? '午前' : '午後'}`;
        this.ampm3 = `第3希望/${res.ampm3 === 'am' ? '午前' : '午後'}`;
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        console.log();
      });
    this.$modal.show('reservationDateForm');
  }

  disabledDateTime(type) {
    if (type === 'am') {
      return {
        disabledHours: () => this.range(0, 9).concat(this.range(13, 24)),
      };
    }
    return {
      disabledHours: () => this.range(0, 14).concat(this.range(20, 24)),
    };
  }

  range(start, end) {
    const result: any = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  async onOkReservationDate(value) {
    this.$modal.hide('reservationDateForm');
    await ApiHelper.getApi('ManagerApi')
      .confirmReservation(this.currentChannelUser.latestReservation.id, {
        confirmed_date: moment(value).format('YYYY-MM-DD HH:mm:ss'),
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        console.log();
      });

    const channelId = parseInt(this.$route.params.channelId, 10);
    await ApiHelper.getApi('ChannelApi')
      .getOne(channelId, {
        with: 'users,latest_message,users.latestReservation',
      })
      .then((res: any) => {
        this.changeCurrentChannelUser(res.users[0]);
      });
  }

  updateUser(user) {
    this.currentChannelUser.note = user.note;
    this.currentChannelUser.noted_at = user.noted_at;
  }

  showEditPatientModal() {
    this.editPatientModalRef.show();
  }

  showUpdateNoteModal() {
    this.$modal.show('UserNoteModal');
  }

  getimageIdentity(src, type) {
    if (type === 'front') {
      this.defaultFrontIndentityImage = src;
    }
    if (type === 'back') {
      this.defaultBackIndentityImage = src;
    }
    if (type === 'head') {
      this.defaultHeadImage = src;
    }
  }

  openModalReservationDate() {
    this.latestReservationEdit = lodash.cloneDeep(this.latestReservation);
    this.$modal.show('modalReservationDate');
  }

  closeModalReservationDate() {
    this.latestReservationEdit = {};
  }

  updateLatestReservation(data) {
    const newData = {
      ...lodash.cloneDeep(this.currentChannelUser),
      latestReservation: data,
    };
    this.changeCurrentChannelUser(newData);
  }

  // @Watch('isShowReservationConfirm')
  // private watchIsShowReservation() {
  //   this.showReservationNotification();
  // }

  // @Watch('currentChannelUser')
  // private changeShowMessage() {
  //   if (
  //     this.isShowReservationNotification === true &&
  //     this.currentChannelUser &&
  //     this.currentChannelUser.state === USER_STATUS.CONFIRMED_RESERVATION
  //   ) {
  //     this.isShowReservationNotification = false;
  //   }
  // }

  private updateIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  private prepareFormData(data) {
    const form = {
      name: data.name,
      name_kana: data.name_kana,
      clinic_name: data.clinic_name,
      date_of_birth: data.date_of_birth,
      phone: data.phone,
      email: data.email,
      postal_code: data.postal_code,
      prefecture_id: data.prefecture_id,
      city_id: data.city_id,
      address: data.address,
      building_name: data.building_name,
    };

    return form;
  }

  private cancelUpdatePatient() {
    this.$modal.hide('edit-patient');
  }

  private async updatePatientInfo() {
    try {
      this.updateIsSubmitting(true);
      const cpForm = { ...this.currentChannelUser };
      const params = this.prepareFormData(cpForm);
      // const response = await ApiHelper.getApi('ClinicApi').update(this.clinicId, params);
      this.$modal.hide('edit-patient');
      Toast.success('更新しました');
      this.updateIsSubmitting(false);
    } catch (error) {
      this.updateIsSubmitting(false);
      this.$modal.hide('edit-patient');
      const data = error.response.data;
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
      Toast.error(data.message);
    }
  }

  private disabledDate(current) {
    return current && current < moment().endOf('day');
  }

  private showConfirmDeleteUser(id) {
    const userName = `${this.currentChannelUser.family_name} ${this.currentChannelUser.given_name}`;
    this.messageConfirmDel = `<p>本当に削除しますか？</p> <p class="mb-5">${userName}</p>`;
    this.$modal.show('removeUser');
  }

  private hiddenPopupRemoveUser() {
    this.$modal.hide('removeUser');
  }

  private async removerUser() {
    const patientId = this.currentChannelUser.id;
    try {
      await PatientModule.removePatient(patientId);
      this.$router.push('/messages');
      this.changeCurrentChannelUser({ isRemove: true });
      Toast.success('ユーザを削除しました。');
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
      } else {
        Toast.error('更新に失敗しました');
      }
    } finally {
      this.$modal.hide('removeUser');
    }
  }
}
