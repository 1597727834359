






























import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import Zoom from '@components/iconSVG/Zoom.vue';
import axios from 'axios';
import { config } from '@common/app.config';
import imageDefault from '@/assets/img/icon-no-image.svg';
import lodash from 'lodash';

@Component({
  components: {
    Zoom,
  },
})

export default class ImageZoomComponent extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) readonly path!: string;
  @Prop(String) readonly alt!: string;
  @Prop(String) readonly styles!: string;
  @Prop(String) readonly avatarDefault!: string;
  @Prop(Boolean) readonly fullHeight!: boolean;

  private src = '';
  private size: any = {};

  private get noImage() {
    return !lodash.includes(this.path, 'icon-no-image') && !lodash.includes(this.path, 'ui-avatars.com');
  }
  private get imageDefault() {
    return this.avatarDefault || imageDefault;
  }

  mounted() {
    if (this.path) {
      this.getImageData();
    }
  }

  @Watch('path')
  private async getImage() {
    if (this.path) {
      this.getImageData();
    }
  }

  private async getImageData() {
    let imageurl;
    const url = `${config.apiUrl}/api/managers/get-image?path=${this.path}`;
    if (!lodash.includes(this.path, 'https:') && !lodash.includes(this.path, 'icon-no-image')) {
    imageurl = await axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        const image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), ''),
        );
        return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
      })
      .catch((ex) => {
        console.log(ex);
      });
    const imageData = new Image();
    imageData.src = imageurl;
    const vm = this;

    imageData.onload = () => {
      vm.size = {
        width: imageData.width,
        height: imageData.height,
      };
    };

    // this.src = imageurl;
    }
    this.src = imageurl || this.avatarDefault;
    this.$emit('srcLoaded', this.src);
  }

  private zoomImage() {
    if (this.src) {
      this.$modal.show('preview-image-zoom', { src: this.src });
    }
  }
}
