

import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { ClinicModule } from '@/store';
import Toast from '@common/helpers/toast';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {faCameraRetro, faDownload} from '@fortawesome/free-solid-svg-icons';
import ApiHelper from 'api-helper';
import UploadImage from '@/components/common/UploadImage.vue';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';
import ClinicInfo from '@/components/Clinic/ClinicInfo/ClinicInfo.vue';
import ManagerInfo from '@/components/Clinic/ManagerInfo/ManagerInfo.vue';
import BankInfo from '@/components/Clinic/BankInfo/BankInfo.vue';
import { Roles } from '@/common/app.config';
import { downloadFile } from '@common/helpers/download-file';

@Component({
  head: {
    title: {
      inner: 'クリニック詳細・編集',
    },
  },
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
  components: {
    UploadImage,
    ClinicInfo,
    ManagerInfo,
    BankInfo,
  },
  data() {
    return {
      clinicId: this.$route.params.clinicId,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.pastRouter = from.path;
    });
  },
})

export default class Show extends Mixins(ClinicsMixin) {
  private updateClinicForm =  {
    image_url: '',
  };
  private selected = 'clinicInfo';
  private typeValidate: string = 'input';
  private clinicId: any;
  private isSubmiting = false;
  private currentUser: any;
  private pastRouter = '';

  private downloadFile(link): string {
    return link ? downloadFile(link) : '';
  }

  private get computedForm() {
    return this.$refs.updateClinicForm as any;
  }

  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  private setDataImage(data: any) {
    this.updateForm(this.updateClinicForm, 'image_url', data.image_url);
  }

  private prepareFormData(image: any) {
    const formData = new FormData();
    formData.append('image', image);

    return formData;
  }

  private changeTab(tab) {
    if (tab === 'clinicInfo') {
      this.selected = tab;
    }
  }

  private async openConfirmUpdateClinic() {
    this.typeValidate = 'click';
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }

    this.$modal.show('confirmUpdateClinic');
  }

  private cancelPopupUpdateConfirm() {
    this.$modal.hide('confirmUpdateClinic');
  }

  private copyLink() {
    const copyText = this.$refs.qrCodeLink as any;
    copyText.select();
    document.execCommand('copy');
  }

  private async updateImage(image) {
    if (this.updateClinicForm) {
      try {
        this.updateIsSubmiting(true);
        const response = await ApiHelper.getApi('ClinicApi').updateImage(this.clinicId, this.prepareFormData(image));
        ClinicModule.getClinicDetail(this.clinicId);
        this.updateIsSubmiting(false);
      } catch (error) {
        this.updateIsSubmiting(false);
        const data = error.response.data;
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
        Toast.error(data.message);
      }
    }
  }

  private get dataClinic() {
    return ClinicModule.dataClinic;
  }

  private created() {
    if (this.currentUser.role === Roles.clinic) {
      this.clinicId = (this.currentUser.clinics[0] || {}).id;
    }

    ClinicModule.getClinicDetail(this.clinicId);
    library.add(faCameraRetro);
    library.add(faDownload);
    dom.watch();
  }

  private mounted() {
    setTimeout(() => {
      if (this.pastRouter === '/monthly-report') {
        this.selected = 'bankInfo';
      }
    }, 0);
  }

  @Watch('dataClinic')
  private watchDataClinic() {
    this.setDataImage(this.dataClinic);
  }
}
