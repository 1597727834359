








































































































































import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import {ROLE_LIST, Roles} from '@common/app.config';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import ContentLayout from '@components/layouts/Content.vue';
import memberIcon from '@/assets/img/memberIcon.svg';
import PopupAddClinic from '@/components/common/PopupAddClinic.vue';
import PopupSetPassword from '@/components/common/PopupSetPasswordMember.vue';

@Component({
  components: {
    ContentLayout,
    PopupAddClinic,
    PopupSetPassword,
  },
  filters: {
  },
  data() {
    return {
      iconHeader: memberIcon,
    };
  },
})
export default class DetailMember extends Vue {

  private get isCanChangeClinic() {
    return (
      (this.currentUser.isSuperAdminRole ||
        this.currentUser.isAdminRole ||
        this.currentUser.isDocumentRole ||
        this.currentUser.isCSRole) &&
      this.formData.role == 'clinic_admin'
    );
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  submitting = false;
  isSubmitDelete = false;
  formData = {
    email: '',
    name: '',
    role: '',
    clinic_id: '',
  };
  defaultFormData = {
    email: '',
    name: '',
    role: '',
  };
  private clinic: any = {};
  private roleListOption = ROLE_LIST;

  setPassword() {
    this.hiddenPopup('popup-set-password');
  }

  beforeOpen() {
    document.documentElement.style.overflow = 'hidden';
  }

  beforeClose() {
    document.documentElement.style.overflow = 'auto';
  }

  created() {
    this.getInfo();
    library.add(faTrashAlt);
    dom.watch();
  }
  getInfo() {
    ApiHelper.getApi('MemberApi')
      .detail(this.$route.params.id, {id: this.$route.params.id})
      .then((res) => {
        this.formData = JSON.parse( JSON.stringify( res ) );
        if (res.clinics && res.clinics[0]) {
          this.clinic = res.clinics[0];
          this.formData.clinic_id = res.clinics[0].id;
        }
        this.defaultFormData = JSON.parse( JSON.stringify( res ) );
      });
  }

  setClinic(clinic) {
    if (clinic && clinic.id) {
      this.clinic = clinic;
      this.formData.clinic_id = clinic.id;
      this.hiddenPopup('popup-add-clinic');
      return;
    }
  }
  private hiddenPopup(name) {
    this.$modal.hide(name);
  }
  private submit(invalid) {
    if (invalid) {
      (this.$refs.memberForm as any).validate();
      return;
    }
    this.submitting = true;
    ApiHelper.getApi('MemberApi')
      .update(this.$route.params.id, this.formData)
      .then((res) => {
        this.formData = JSON.parse( JSON.stringify( res ) );
        if (res.clinics && res.clinics[0]) {
          this.clinic = res.clinics[0];
          this.formData.clinic_id = res.clinics[0].id;
        }
        this.defaultFormData = JSON.parse( JSON.stringify( res ) );
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  private deleteMember() {
    this.isSubmitDelete = true;
    ApiHelper.getApi('MemberApi')
      .removeMember(this.$route.params.id)
      .then((res) => {
        Toast.success('削除しました');
        this.$router.push('/members');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isSubmitDelete = false;
      });
  }

  private showPopup(event, name) {
    event.preventDefault();
    event.stopPropagation();

    this.$modal.show(name);
  }
}
