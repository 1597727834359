import { render, staticRenderFns } from "./Precaution.vue?vue&type=template&id=44b5146e&scoped=true&"
import script from "./Precaution.vue?vue&type=script&lang=ts&"
export * from "./Precaution.vue?vue&type=script&lang=ts&"
import style0 from "./Precaution.vue?vue&type=style&index=0&id=44b5146e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b5146e",
  null
  
)

export default component.exports