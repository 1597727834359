var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-reservation-date"},[_c('modal',{attrs:{"name":"modalReservationDate","width":"470","height":"auto","clickToClose":false},on:{"before-open":_vm.beforeOpen,"before-close":_vm.beforeClose}},[_c('ValidationObserver',{ref:"modalReservationDate",staticClass:"form-reservation-date",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.updateReservationDate($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('label',{staticClass:"form-reservation-date__label"},[_vm._v("ビデオ診療日時")]),_c('div',{staticClass:"mb-3 d-flex"},[_c('div',{staticClass:"flex-1 pr-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseDatePicker',{attrs:{"disabledEqualOrBeforeToday":"","clearable":false,"errorText":errors[0]},model:{value:(_vm.dataForm.date),callback:function ($$v) {_vm.$set(_vm.dataForm, "date", $$v)},expression:"dataForm.date"}})]}}],null,true)})],1),_c('div',{staticClass:"form-reservation-date__input-time"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseDatePicker',{attrs:{"type":"time","valueType":"format","disabledBeforeTimeToday":"","date":_vm.dataForm.date,"showSecond":false,"minuteStep":30,"format":_vm.formatTime,"errorText":errors[0]},model:{value:(_vm.dataForm.time),callback:function ($$v) {_vm.$set(_vm.dataForm, "time", $$v)},expression:"dataForm.time"}})]}}],null,true)})],1)]),_c('div',{staticClass:"form-reservation-date__action"},[_c('button',{staticClass:"form-reservation-date__btn form-reservation-date__btn-cancel",attrs:{"type":"button"},on:{"click":_vm.closeModalReservationDate}},[_vm._v(" キャンセル ")]),_c('button',{staticClass:"form-reservation-date__btn form-reservation-date__btn-submit",attrs:{"type":"button","disabled":!dirty || invalid || _vm.isSubmiting || !_vm.isChanged},on:{"click":_vm.updateReservationDate}},[_vm._v(" 保存 ")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }