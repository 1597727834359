























































// Libs
import { Vue, Component, Prop } from 'vue-property-decorator';
import LodashGet from 'lodash/get';

// Components
import BaseModal from '@/components/common/BaseModal.vue';
import FormAction from '@/components/common/FormAction.vue';
import LazyloadImage from '@/components/image/LazyloadImage.vue';

@Component({
  components: {
    BaseModal,
    FormAction,
    LazyloadImage,
  },
})
export default class ModalCancelDelivery extends Vue {
  @Prop(String) readonly name: string;
  @Prop(Object) readonly order!: any;
  @Prop(Boolean) readonly isSubmitting: boolean;

  private get menuName() {
    return LodashGet(this.order, 'userTreatment.menu.name', '');
  }
}
