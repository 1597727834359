<template>
<svg :style='svgStyle'>
  <text x='50%' y='50%' text-anchor='middle' dominant-baseline='central' :style='textStyle'>
    {{ avatarLetter }}
  </text>
</svg>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 50,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      default: null,
    },
  },
  computed: {
    avatarLetter() {
        const letterArray = this.name.split(' ');
        return letterArray.filter((item, idx) => idx < 2).map((string) => string.charAt(0)).join('').toUpperCase();
    },
    svgStyle() {
        // const letterIndex = this.avatarLetter.charCodeAt() - 64;
        // const hue = (360 / 26) * letterIndex;
      const backgroundColor = this.randomColor;
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? '100%' : '0',
        background: backgroundColor,
        marginRight: '4px',
      };
    },
    textStyle() {
      return {
        fill: 'rgba(255, 255, 255, .7)',
        // fontFamily: "'Lucida Console', Monaco, monospace",
        fontSize: `${this.size * 0.4}px`,
      };
    },
    randomColor() {
      const chars = `${this.avatarLetter}${this.object?.id || ''}`;
      const saturation = 100;
      const lightness = 40;
      let hash = 0;
      /* tslint:disable:no-bitwise */
      for (let i = 0; i < chars.length; i++) {
        hash = chars.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
      }
      /* tslint:enable:no-bitwise */
      return `hsl(${(hash % 18) * 20}, ${saturation}%, ${lightness}%)`;
    },
  },
};
</script>