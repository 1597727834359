











































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PopupConfirm extends Vue {
  @Prop({ default: true }) readonly clickToClose!: boolean;
  @Prop(String) readonly name!: string;
  @Prop(Boolean) readonly disabledBtSubmit!: string;
  @Prop(Boolean) readonly disabledBtCancel!: string;
  @Prop({ default: 150 }) readonly height!: number;
  @Prop({ default: 400 }) readonly width!: number;
  @Prop({ default: 'キャンセル' }) readonly textClosePopup!: string;
  @Prop({ default: 'はい' }) readonly textActionPopup!: string;
  @Prop(String) readonly message!: string;
  @Prop({ default: '' }) readonly contentOne!: string;
  @Prop({ default: '' }) readonly contentTwo!: string;
  @Prop({ default: '' }) readonly styleContentOne!: string;
  @Prop({ default: '' }) readonly styleContentTwo!: string;
  @Prop({ default: false }) readonly showHeader!: boolean;
  @Prop({ default: false }) readonly btnClose!: boolean;
  @Prop({ default: '' }) readonly pageClass!: string;
}
