import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import ApiHelper from 'api-helper';
import { ModelPatientDetail, defaultPatientDetail, ModelPatient } from '@/models/patient';

@Module({ name: 'patient', namespaced: true })
export default class Patient extends VuexModule {
  public dataPatient: ModelPatient[] = [];
  public dataPatientDetail: ModelPatientDetail = JSON.parse(JSON.stringify(defaultPatientDetail));
  public total: number = 0;
  public currentPage: number = 0;
  public lastPage: number = 0;
  public perPage: number = 0;

  get totalPage() {
    return this.total / 10;
  }
  @Action({ rawError: true, commit: 'SET_DATA_PATIENT_DETAIL' })
  public async getPatientDetail(data: {
    id: number | string;
    params?: { with: string };
  }): Promise<ModelPatientDetail> {
    const response = await ApiHelper.getApi('PatientApi').getOne(data.id, data.params);
    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_PATIENT_DETAIL' })
  public async updateNote(data: {id: number | string, body: { note: string }}) {
    const response = await ApiHelper.getApi('PatientApi').updateNote(data.id, data.body);
    return {
      note: response.note,
    };
  }

  @Action({ rawError: true, commit: 'SET_DATA_PATIENT_DETAIL' })
  public async updateEmail(data: {id: number | string, body: { email: string }}) {
    const response = await ApiHelper.getApi('PatientApi').updateEmail(data.id, data.body);
    return {
      email: response.email,
    };
  }

  @Action({ rawError: true, commit: 'SET_DATA_PATIENT_DETAIL' })
  public async updateInsuranceCardNum(data: {id: number | string, body: { insurance_card_number: string }}) {
    const response = await ApiHelper.getApi('PatientApi').updateInsuranceCardNum(data.id, data.body);
    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_PATIENT_DETAIL' })
  public async updateHealthcareRecords(data: {
    id: number | string,
    body: { clinic_id: string, healthcare_code: string },
  }) {
    const response = await ApiHelper.getApi('PatientApi').updateHealthcareRecords(data.id, data.body);

    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_PATIENT'})
  public async getPatientList(params: any) {
    const response = await ApiHelper.getApi('PatientApi').list(params);
    return response;
  }

  @Action({ rawError: true })
  public async removePatient(id: number | string) {
    const response = await ApiHelper.getApi('PatientApi').removePatient(id);
    return response;
  }

  @Mutation
  public SET_DATA_PATIENT_DETAIL(data: ModelPatientDetail) {
    this.dataPatientDetail = {...this.dataPatientDetail, ...data};
  }

  @Mutation
  public SET_DATA_PATIENT(
    response: {
      data: ModelPatient[],
      total: number,
      current_page: number,
      per_page: number,
      last_page: number,
    }) {
    this.dataPatient = response.data;
    this.total = response.total;
    this.currentPage = response.current_page;
    this.perPage = response.per_page;
    this.lastPage = response.last_page;
  }
}
