import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';

@Module({ stateFactory: true, name: 'locale', namespaced: true })
export default class Localization extends VuexModule {
  public locale: string = 'ja';
  public messages = {};
  public isLoading = false;
  public lastUpdate = {};
  public isLoadedLastUpdate = false;
  public isUploadingCSV = false;
  public siteUpload: string = '';

  @Mutation
  public updateSiteUpload(siteUpload: string) {
    this.siteUpload = siteUpload;
  }

  @Mutation
  public updateLocale(locale: string) {
    this.locale = locale;
  }

  @Mutation
  public updateLocaleMessages(messages: any) {
    (this.messages as any)[this.locale] = messages;
    window.app.$i18n.setLocaleMessage(this.locale, messages);
  }

  @Mutation
  public updateLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public updateIsLoadedLastUpdate(isLoadedLastUpdate: boolean) {
    this.isLoadedLastUpdate = isLoadedLastUpdate;
  }

  @Mutation
  public updateLastUpdate(lastUpdate: any) {
    this.lastUpdate = lastUpdate;
  }

  @Mutation
  public updateIsUploadingCSV(isUploadingCSV: any) {
    this.isUploadingCSV = isUploadingCSV;
  }

  @Action({commit: 'updateLastUpdate', rawError: true})
  public async getLastUpdate() {
    const { commit } = this.context;
    try {
      const response = await ApiHelper.getApi('TranslateApi').getLastUpdate();
      commit('updateIsLoadedLastUpdate', true);
      commit('updateLastUpdate', response);
      return response;
    } catch (error) {
      Toast.error(error.response.data.message);
      return {};
    }
  }

  @Action({rawError: true})
  public async uploadCSV(data: FormData) {
    const { commit, state } = this.context;

    commit('updateIsUploadingCSV', true);
    try {
      const response = await ApiHelper.getApi('TranslateApi').uploadCSV(data);
      const {lastUpdate, siteUpload} = state as any;
      lastUpdate[siteUpload] = {
        file_url: response.file_url,
        created_at: response.updated_at,
      };
      commit('updateLastUpdate', lastUpdate);
      commit('updateIsUploadingCSV', false);
      Toast.success('アップロードしました');
    } catch (error) {
      commit('updateIsUploadingCSV', false);
      Toast.error(error.response.data.message);
    }
  }

  @Action({commit: 'updateLocaleMessages', rawError: true})
  public async fetchLocalizationData() {
    const { context } = this;
    try {
      const messagesLoaded = this.messages as any;
      if (!!messagesLoaded[this.locale]) {
        return messagesLoaded[this.locale];
      }
      context.commit('updateLoading', true);
      const response = await ApiHelper.getApi('TranslateApi').getList();
      const messages = await (await fetch(response.doctor[this.locale])).json();
      context.commit('updateLoading', false);
      return messages;
    } catch (error) {
      context.commit('updateLoading', false);
      if (error.response) {
        Toast.error(error.response.data.message);
      }
      return null;
    }
  }
}
