var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'faq--active': _vm.isActive,
  'faq--invalid': _vm.isDisableBtn,
  'faq--new': _vm.faq.id === _vm.editIndex
  }},[_c('ValidationObserver',{attrs:{"tag":"div"}},[_c('div',{staticClass:"d-flex align-items-start justify-content-center"},[_c('div',{staticClass:"mr-3 col-7"},[_c('p',[_vm._v(_vm._s(("問" + (_vm.index + 1) + "：")))]),_c('div',{staticClass:"faq--item"},[_c('div',{staticClass:"faq--question mb-3 d-flex",on:{"click":_vm.toogleAnswer}},[_c('div',{staticClass:"faq--badge"},[_vm._v("Q")]),_c('div',{staticClass:"faq--content"},[(!_vm.isUpdating)?_c('p',{staticClass:"mb-0 d-flex align-items-center justify-content-between position-relative"},[_c('span',{staticClass:"text-wrap w-100"},[_vm._v(_vm._s(_vm.faq.title))]),_c('span',{staticClass:"material-icons",class:{'opened' : _vm.isToogle }},[_vm._v("keyboard_arrow_down")])]):_c('ValidationProvider',{attrs:{"rules":"required|max:255","customMessages":_vm.titleMessages,"name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.faq.title),expression:"faq.title",modifiers:{"trim":true}}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"name":"title","type":"text","rows":"1","placeholder":"質問の内容を入力してください"},domProps:{"value":(_vm.faq.title)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () {})($event)},"focus":function($event){_vm.isActive=true},"blur":[function($event){_vm.isActive=false},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.faq, "title", $event.target.value.trim())}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1)]),_c('transition',{attrs:{"name":"expand"},on:{"enter":_vm.enter,"after-enter":_vm.afterEnter,"leave":_vm.leave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isToogle),expression:"isToogle"}],staticClass:"faq--answer mb-3 flex"},[_c('div',{staticClass:"faq--badge blue"},[_vm._v("A")]),(!_vm.isUpdating)?_c('p',{staticClass:"mb-0 text-wrap faq--content",domProps:{"innerHTML":_vm._s(_vm.content)}}):_c('ValidationProvider',{staticClass:"w-100 ml-2",attrs:{"rules":"required|max:1000","customMessages":_vm.contentMessages,"name":"title","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.faq.content),expression:"faq.content",modifiers:{"trim":true}}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"placeholder":"回答を入力してください","rows":"5"},domProps:{"value":(_vm.faq.content)},on:{"focus":function($event){_vm.isActive=true},"blur":[function($event){_vm.isActive=false},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.faq, "content", $event.target.value.trim())}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0])+" ")])]}}])})],1)])],1),_c('div',{staticClass:"faq--footer d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('span',[_vm._v("Toppageに表示・非表示：")]),_c('ToggleButton',{staticClass:"m-0 ml-3",attrs:{"disabled":!_vm.isUpdating,"color":"#007bff","sync":true},model:{value:(_vm.faq.show_top_page),callback:function ($$v) {_vm.$set(_vm.faq, "show_top_page", $$v)},expression:"faq.show_top_page"}})],1),(_vm.dateFormat(_vm.faq.updated_at))?_c('div',[_c('span',[_vm._v("最終更新時間："+_vm._s(_vm.dateFormat(_vm.faq.updated_at)))])]):_vm._e()])]),_c('div',{staticClass:"faq--actions"},[(_vm.faq.newQuestion)?_c('button',{staticClass:"btn btn-primary d-block mb-3",attrs:{"disabled":_vm.isDisableBtn},on:{"click":_vm.createFAQ}},[_vm._v("登録する")]):[(!_vm.isUpdating)?_c('button',{staticClass:"btn btn-primary d-block mb-3",on:{"click":_vm.toggleUpdate}},[_vm._v("変更する")]):_c('button',{staticClass:"btn btn-primary d-block mb-3",attrs:{"disabled":_vm.isDisableBtn},on:{"click":_vm.saveFAQ}},[_vm._v("更新する")])],_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.deleteFAQ}},[_vm._v("削除する")])],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }