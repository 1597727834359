



























































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Draggable from 'vuedraggable';
import LodashSome from 'lodash/some';

// Utils
import Toast from '@common/helpers/toast';
import {
  getQuestionLocalId,
  isQuestionLocalId,
} from '@/views/question/helpers';
import { State } from 'vuex-class';
// Components
import ContentLayout from '@components/layouts/Content.vue';
import QuestionListItem from '@/views/question/components/QuestionListItem/Index.vue';
import FormAction from '@/components/common/FormAction.vue';
import PageLoader from '@components/common/PageLoader.vue';
import BaseInput from '@/components/common/BaseInput.vue';
// Constants
import { Roles } from '@/common/app.config';
import { QUESTION_TYPES } from '@/views/question/constants';
// Icons
import ListIcon from '@/assets/img/icon-diagnosis.svg';
import { cloneDeep, remove, unionBy } from 'lodash';

@Component({
  components: {
    ContentLayout,
    QuestionListItem,
    Draggable,
    FormAction,
    PageLoader,
    BaseInput,
  },
})
export default class QuestionListEdit extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  iconHeader: any = ListIcon;
  isSubmiting: boolean = false;
  isLoading: boolean = false;
  isChanged: boolean = false;
  questionOptionEditting: string = '';
  countManualSubscription: number | null = null;
  countAutoSubscription: number | null = null;

  questions: any[] = [];
  questionIdsDelete: number[] = [];
  validOptions: any[] = [];
  validOptionsUnionBy: any[] = [];

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Roles.superAdmin;
  }

  get diagnosisId() {
    return this.$route.params.id;
  }

  get refQuestionList() {
    return this.$refs.questionList as any;
  }

  get computedForm() {
    return this.$refs.questionForm as any;
  }

  get dragOptions() {
    return {
      animation: 200,
      disabled: this.questions.length === 1 || !this.isAdmin,
      handle: '.handle',
      forceFallback: true,
      scrollSensitivity: 200,
    };
  }

  async created() {
    this.isLoading = true;
    await this.getDiagnosisDetail();
    await this.getQuestions();
    this.isLoading = false;
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  updateIsSubmiting(isSubmiting) {
    this.isSubmiting = isSubmiting;
  }

  updateIsChanged(status) {
    this.isChanged = status;
  }

  clickEdit(id) {
    this.questionOptionEditting = id;
  }

  scrollBottomQuestionItem() {
    this.$nextTick(() => {
      const questionNodes = this.refQuestionList.$children;
      const lastQuestionNode = questionNodes[questionNodes.length - 1];
      lastQuestionNode.$el.scrollIntoView({ behavior: 'smooth' });
    });
  }

  handleUpdateQuestion(index, name, value, item) {
    if (name === 'count_number') {
      const newValue = {
        ...this.questions[index],
        options: { count_number: value },
      };

      this.$set(this.questions, index, newValue);
      return;
    }

    const { INPUT, TEXTAREA, CHECKBOX, RADIO } = QUESTION_TYPES;
    const newValue = {
      ...this.questions[index],
      [name]: value,
    };

    if (name === 'type' && [TEXTAREA, INPUT].includes(value)) {
      newValue.options = [];
      remove(this.validOptionsUnionBy, (i) => i.id === item.id);
    }

    if (name === 'type' && [CHECKBOX, RADIO].includes(value)) {
      newValue.placeholder = '';
      newValue.options = [];
    }
    this.$set(this.questions, index, newValue);
  }

  handleDeleteQuestion(id) {
    const newQuestions = this.questions.filter((x) => x.id !== id);
    this.questions = newQuestions;
    if (!isQuestionLocalId(id)) {
      this.questionIdsDelete.push(id);
    }
  }

  handleAddQuestion() {
    this.questions.push({
      id: getQuestionLocalId(),
      description: '',
      multiple: false,
      options: [],
      order: this.questions.length,
      placeholder: '',
      question: '',
      required: !(this.questions.length > 0),
      count_ng_required: false,
      type: QUESTION_TYPES.INPUT,
      woman_only: false,
    });
    this.scrollBottomQuestionItem();
  }

  handleAddOption(index) {
    const question = this.questions[index];
    const newValue = {
      ...question,
      options: [...question.options, ''],
    };
    this.$set(this.questions, index, newValue);
  }

  handleUpdateOption(index, optionIndex, value) {
    this.questionOptionEditting = '';
    const question = this.questions[index];
    const options: string[] = question.options.map((x, i) => {
      if (i === optionIndex) {
        return {
          is_call_video: false,
          ...value,
        };
      }
      return x;
    });
    const newValue = {
      ...question,
      options,
    };
    this.$set(this.questions, index, newValue);
  }

  handleDeleteOption(index, optionIndex) {
    const question = this.questions[index];
    const options: string[] = question.options.filter((_, i) => i !== optionIndex);
    const newValue = {
      ...question,
      options,
    };
    this.$set(this.questions, index, newValue);
  }

  handleCancel() {
    return this.$router.push({
      name: 'diagnosis.detail',
      params: { id: this.diagnosisId },
    });
  }

  async getDiagnosisDetail() {
    try {
      const res = await ApiHelper.getApi('MedicalMenuApi').getOne(this.diagnosisId);
      this.countAutoSubscription = res.count_auto_subscription;
      this.countManualSubscription = res.count_manual_subscription;
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
      }
    }
  }

  async getQuestions() {
    try {
      this.isLoading = true;
      const params = {
        menu_id: this.diagnosisId,
      };
      const { data } = await ApiHelper.getApi('QuestionApi').getList(params);
      this.questions = data.sort((a, b) => a.order - b.order);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error.response) {
        Toast.error(error.response.data.message);
      }
    }
  }

  async handleSubmit() {
    const valid = await this.computedForm.validate();

    if (!valid) {
      return;
    }

    let validOption: boolean = true;
    this.validOptionsUnionBy.map((item) => {
      if (!item.value) {
        validOption = false;
      }
    });

    if (!validOption) {
      return;
    }

    const questions = this.questions.map((x, i) => {
      const _isQuestionLocalId = isQuestionLocalId(x.id);
      return {
        ...x,
        id: _isQuestionLocalId ? '' : x.id,
        order: i,
      };
    });

    const payload = {
      menu_id: this.diagnosisId,
      ids_delete: this.questionIdsDelete,
      count_manual_subscription: !this.isDisabledInput ? Number(this.countManualSubscription) : null,
      count_auto_subscription: !this.isDisabledInput ? Number(this.countAutoSubscription) : null,
      questions,
    };

    try {
      const questions = this.questions.map((x, i) => {
        const _isQuestionLocalId = isQuestionLocalId(x.id);
        return {
          ...x,
          id: _isQuestionLocalId ? '' : x.id,
          order: i,
        };
      });
      const payload = {
        menu_id: this.diagnosisId,
        ids_delete: this.questionIdsDelete,
        count_manual_subscription: !this.isDisabledInput ? Number(this.countManualSubscription) : null,
        count_auto_subscription: !this.isDisabledInput ? Number(this.countAutoSubscription) : null,
        questions,
      };
      this.updateIsSubmiting(true);
      await ApiHelper.getApi('QuestionApi').updateQuestions(payload);
      await this.getQuestions();
      this.questionIdsDelete = [];
      this.updateIsSubmiting(false);
      this.updateIsChanged(false);
      Toast.success('登録しました');
    } catch (error) {
      this.updateIsSubmiting(false);
      this.updateIsChanged(false);
      const data = error.response.data;
      Toast.error(data.message);
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
    }
  }

  checkValidOptions(event) {
    this.validOptions.push(event);
    const validOptionsReverse = cloneDeep(this.validOptions).reverse();
    this.validOptionsUnionBy = unionBy(validOptionsReverse, 'id');
  }

  @Watch('questions', { deep: true })
  private watchQuestions(_, oldValue) {
    this.WatchAssignValueForFistQuestion();
    if (this.isChanged || !oldValue.length) {
      return;
    }
    this.updateIsChanged(true);
  }

  private WatchAssignValueForFistQuestion() {
    if (!LodashSome( this.questions, 'required') && this.questions.length > 0) {
      this.questions[0].required = true;
    }
  }

  get rulesCountAutoSubscription() {
    return `required|onlyNumber|bigger_number:${this.countManualSubscription}`;
  }

  get optionErrorInputMessages() {
    return {
      max: '100文字以内で入力してください',
      numeric: '正の整数を入力してください',
      required: '手動診察カウント数を入力してください',
    };
  }

  get optionErrorNumberOfMedicalInputMessages() {
    return {
      max: '100文字以内で入力してください',
      numeric: '正の整数を入力してください',
      required: '診療不要定期便カウント数を入力してください',
    };
  }

  get isDisabledInput() {
    if (LodashSome( this.questions, 'count_ng_required')) {
      return false;
    } else {
      this.countManualSubscription = null;
      this.countAutoSubscription = null;
      return true;
    }
  }
}
