
























import { Vue, Component } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

// Utils
import Toast from '@common/helpers/toast';
import { Menu } from '@/models/menu';

// Components
import ContentLayout from '@components/layouts/Content.vue';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import DiagnosisForm from './components/DiagnosisForm.vue';
// Icons
import ListIcon from '@/assets/img/icon-diagnosis.svg';

@Component({
  components: {
    ContentLayout,
    DragDropFileUpload,
    DiagnosisForm,
  },
})
export default class Create extends Vue {
  iconHeader: any = ListIcon;
  isSubmiting = false;
  isLoading: boolean = false;
  diagnosis: Menu = new Menu();

  get computedForm() {
    return this.$refs.dataForm as any;
  }

  async created() {
    library.add(faCameraRetro);
    dom.watch();
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  handleChange(name, value) {
    this.$set(this.diagnosis, name, value);
  }

  handleCancel() {
    return this.$router.push({ name: 'diagnosis' });
  }

  async handleSubmit() {
    const valid = await this.computedForm.validate();

    if (valid) {
      try {
        this.updateIsSubmiting(true);
        await ApiHelper
          .getApi('MedicalMenuApi')
          .create(this.diagnosis.formJSONData());
        this.updateIsSubmiting(false);
        Toast.success('登録しました');
        this.handleCancel();
      } catch (error) {
        this.updateIsSubmiting(false);
        const data = error.response.data;
        Toast.error(data.message);
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
      }
    }
  }
}
