import AuthenticationUtils from '@common/AuthenticationUtils';
import { config } from '@common/app.config';

export const downloadFile = (fileName: string, params: string = ''): string => {
  const token = AuthenticationUtils.getAccessToken();
  if (fileName) {
    return `${config.apiUrl}/api/managers/download?path=${fileName}&token=${token}&${params}`;
  }

  return 'javascript:void(0)';
};

export const downloadVideoRecord = (fileName: string, recordId, resvId): string => {
  const token = AuthenticationUtils.getAccessToken();
  if (fileName && recordId && resvId) {
    return `${config.apiUrl}/api/managers/reservations/${resvId}/records/download?filename=${fileName}&token=${token}&record_id=${recordId}`;
  }

  return 'javascript:void(0)';
};

export const dowloadCSV = (startDate, endDate, type = 'invoices') => {
  const token = AuthenticationUtils.getAccessToken();
  if (startDate && endDate) {
    return `${config.apiUrl}/api/managers/${type}/export?start_date=${startDate}&token=${token}&end_date=${endDate}`;
  }
  return 'javascript:void(0)';
};

export const downloadPResvRecord = (fileName: string, recordId, resvId): string => {
  const token = AuthenticationUtils.getAccessToken();
  if (fileName && recordId && resvId) {
    return `${config.apiUrl}/api/managers/pharmacist-reservations/${resvId}/records/download?filename=${fileName}&token=${token}&record_id=${recordId}`;
  }

  return 'javascript:void(0)';
};
