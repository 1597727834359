import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import ApiHelper from 'api-helper';
import {
  ModelDoctor,
  DataForm,
  ModelDoctorDetail,
  defaultDoctorDetail,
  QueryDoctor,
} from '@/models/doctors';

@Module({ name: 'doctor', namespaced: true })
export default class Doctor extends VuexModule {
  public dataDoctor: ModelDoctor[] = [];
  public dataDoctorDetail: ModelDoctorDetail = JSON.parse(JSON.stringify(defaultDoctorDetail));
  public totals: number = 0;

  get totalPage() {
    return Math.ceil(this.totals / 5);
  }

  get isEmptyData() {
    return !this.dataDoctor.length;
  }

  @Action({ rawError: true, commit: 'SET_DATA_DOCTOR' })
  public async getDoctorList(params?: QueryDoctor) {
    const response = await ApiHelper.getApi('DoctorApi').getList(params);

    return response.data;
  }

  @Action({ rawError: true, commit: 'SET_DATA_DOCTOR_PAGINATE' })
  public async getDoctorListPaginate(params?: QueryDoctor) {
    const response = await ApiHelper.getApi('DoctorApi').getList(params);

    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_DOCTOR_DETAIL' })
  public async getDoctorDetail(data: {
    id: number | string;
    params?: { with: string };
  }): Promise<ModelDoctorDetail> {
    const response = await ApiHelper.getApi('DoctorApi').getOne(
      data.id,
      data.params,
    );
    return response;
  }

  @Action({ rawError: true })
  public async addDoctor(data: DataForm) {
    const response = await ApiHelper.getApi('DoctorApi').create(data);
    return response.data;
  }

  @Action({ rawError: true })
  public async updateDoctorDetail(data: DataForm) {
    const response = await ApiHelper.getApi('DoctorApi').updateDoctor(data);
    return response.data;
  }

  @Action({ rawError: true })
  public async updateImageDoctorDetail(data: any) {
    const response = await ApiHelper.getApi('DoctorApi').updateImage(data);
    // this.getDoctorDetail({id: data.id});
    return response.data;
  }

  @Mutation
  public SET_DATA_DOCTOR(data: ModelDoctor[]) {
    this.dataDoctor = data;
  }

  @Mutation
  public SET_DATA_DOCTOR_PAGINATE(response) {
    this.dataDoctor = response.data;
    this.totals = response.total;
  }

  @Mutation
  public SET_DATA_DOCTOR_DETAIL(data: ModelDoctorDetail) {
    this.dataDoctorDetail = data;
  }
}
