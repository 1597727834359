


import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { ClinicModule, BankJPModule } from '@/store';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { Roles, TYPE_BANK } from '@/common/app.config';
import SelectSearchBank from '@/components/Clinic/SelectSearchBank.vue';
import SelectSearchBranch from '@/components/Clinic/SelectSearchBranch.vue';

@Component({
  head: {
    title: {
      inner: 'クリニック詳細・編集',
    },
  },
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
  components: {
    PopupConfirm,
    SelectSearchBank,
    SelectSearchBranch,
  },
  data() {
    return {
      clinicId: this.$route.params.clinicId,
    };
  },
})
export default class BankInfo extends Mixins(ClinicsMixin) {
  private isSubmiting = false;
  private currentUser: any;
  private clinicId!: any;
  private isChangeBankInfo = false;
  private isHasResultBank = false;
  private isChangeOtherInfo = false;

  private updateClinicForm =  {
    medical_institution_code: '',
    medical_department: '',
    clinic_name: '',
    clinic_name_kana: '',
    email: '',
    phone: '',
    fax: '',
    postal_code: '',
    prefecture_id: '',
    city_id: '',
    address: '',
    building_name: '',
    introduction: '',
    manager_name: '',
    manager_name_kana: '',
    position: '',
    manager_phone: '',
    manager_email: '',
    bank: {
      code: '',
      name: '',
      nameHalfKana: '',
      nameFullKana: '',
      nameFullHira: '',
    },
    branch: {
      code: '',
      name: '',
      nameHalfKana: '',
      nameFullKana: '',
      nameFullHira: '',
    },
    type: '1',
    number: '',
    holder_name: '',
  };

  private get computedForm() {
    return this.$refs.updateClinicForm as any;
  }

  private typeBank(id) {
    return (TYPE_BANK.find((x) => x.id === id) || {}).text;
  }

  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  private notChoosendBank(word) {
    if (this.updateClinicForm.bank.name !== word) {
      this.isHasResultBank = false;
      return;
    }
  }

  private getBank(item) {
    this.$set(this.updateClinicForm, 'bank', item);
    this.updateClinicForm.branch = {
      code: '',
      name: '',
      nameHalfKana: '',
      nameFullKana: '',
      nameFullHira: '',
    };

    BankJPModule.resetBranch();
  }

  private getBranch(item) {
    this.isChangeBankInfo = true;
    this.$set(this.updateClinicForm, 'branch', item);
  }

  private get dataBank() {
    return BankJPModule.dataBank;
  }

  private get dataBankBranch() {
    return BankJPModule.dataBankBranch;
  }

  private cancelUpdateBank() {
    this.$modal.hide('confirmUpdateBank');
    this.$emit('changeTab', 'clinicInfo');
  }

  private changeInfo() {
    this.isChangeOtherInfo = true;
  }

  private async updateInfoBank() {
    try {
      this.updateIsSubmiting(true);
      const cpForm = Object.assign({}, this.updateClinicForm);
      const params = this.prepareFormData(cpForm);
      const response = await ApiHelper.getApi('ClinicApi').update(this.clinicId, params);
      this.$modal.hide('confirmUpdateBank');
      Toast.success('更新しました');
      ClinicModule.getClinicDetail(this.clinicId);
      this.updateIsSubmiting(false);
      this.$emit('changeTab', 'clinicInfo');
    } catch (error) {
      this.updateIsSubmiting(false);
      this.$modal.hide('confirmUpdateBank');
      const data = error.response.data;
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
      Toast.error(data.message);
    }
  }

  private prepareFormData(data) {
    const form = {
      medical_institution_code: data.medical_institution_code,
      medical_department: data.medical_department,
      clinic_name: data.clinic_name,
      clinic_name_kana: data.clinic_name_kana,
      email: data.email,
      phone: data.phone,
      fax: data.fax,
      postal_code: data.postal_code,
      prefecture_id: data.prefecture_id,
      city_id: data.city_id,
      address: data.address,
      building_name: data.building_name,
      introduction: data.introduction,
      manager_name: data.manager_name,
      manager_name_kana: data.manager_name_kana,
      position: data.position,
      manager_phone: data.manager_phone,
      manager_email: data.manager_email,
      bank_code: data.bank.code,
      bank_name: data.bank.name,
      branch_code: data.branch.code,
      branch_name: data.branch.name,
      type: data.type,
      number: data.number,
      holder_name: data.holder_name,
    };

    return form;
  }

  private get dataClinic() {
    return ClinicModule.dataClinic;
  }

  private setDataBank(data) {
    this.$set(this.updateClinicForm, 'medical_institution_code', data.medical_institution_code);
    this.$set(this.updateClinicForm, 'medical_department', data.medical_department);
    this.$set(this.updateClinicForm, 'clinic_name', data.name);
    this.$set(this.updateClinicForm, 'clinic_name_kana', data.name_kana);
    this.$set(this.updateClinicForm, 'email', data.email);
    this.$set(this.updateClinicForm, 'phone', data.phone);
    this.$set(this.updateClinicForm, 'fax', data.fax);
    this.$set(this.updateClinicForm, 'postal_code', data.postal_code);
    this.$set(this.updateClinicForm, 'prefecture_id', data.prefecture_id);
    this.$set(this.updateClinicForm, 'city_id', data.city_id);
    this.$set(this.updateClinicForm, 'address', data.address);
    this.$set(this.updateClinicForm, 'building_name', data.building_name);
    this.$set(this.updateClinicForm, 'introduction', data.introduction);
    this.$set(this.updateClinicForm, 'manager_name', data.managers[0].name);
    this.$set(this.updateClinicForm, 'manager_name_kana', data.managers[0].name_kana);
    this.$set(this.updateClinicForm, 'position', data.managers[0].position);
    this.$set(this.updateClinicForm, 'manager_phone', data.managers[0].phone);
    this.$set(this.updateClinicForm, 'manager_email', data.managers[0].email);
    if (data.bankAccount) {
      const bank = {
        code: data.bankAccount.bank_code,
        name: data.bankAccount.bank_name,
        nameHalfKana: '',
        nameFullKana: '',
        nameFullHira: '',
      };

      const branch = {
        code: data.bankAccount.branch_code,
        name: data.bankAccount.branch_name,
        nameHalfKana: '',
        nameFullKana: '',
        nameFullHira: '',
      };
      this.$set(this.updateClinicForm, 'bank', bank);
      this.$set(this.updateClinicForm, 'branch', branch);
      this.$set(this.updateClinicForm, 'type', data.bankAccount.type);
      this.$set(this.updateClinicForm, 'number', data.bankAccount.number);
      this.$set(this.updateClinicForm, 'holder_name', data.bankAccount.holder_name);
    }
  }

  private hasResultBank(check) {
    this.isHasResultBank = check;
  }

  private created() {
    if (this.currentUser.role === Roles.clinic) {
      this.clinicId = (this.currentUser.clinics[0] || {}).id;
    }
    BankJPModule.listBank({});
    ClinicModule.getClinicDetail(this.clinicId);
  }

  @Watch('dataClinic')
  private watchDataClinic() {
    this.setDataBank(this.dataClinic);
  }
}
