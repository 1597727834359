export const changeCurrencyNumber = (number: string | number, maxLength: string | number = 9) => {
  const strTrimComma = String(number)
    .replace(/\,/g, '')
    .slice(0, Number(maxLength));

  if (/^(0)/.test(strTrimComma)) {
    return '0';
  }

  const result = strTrimComma.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return result;
};

export const changeNumericNumber = (number: string | number, maxLength: string | number = 5) => {
  const strTrimComma = String(number)
    .replace(/\,/g, '')
    .slice(0, Number(maxLength));

  if (/^(0)/.test(strTrimComma)) {
    return '0';
  }

  return strTrimComma;
};
