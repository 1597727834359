import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import ApiHelper from 'api-helper';
import { ModelClinic, QueryClinic } from '@/models/clinics';

@Module({ name: 'clinic', namespaced: true })
export default class Clinic extends VuexModule {
  public dataClinic: ModelClinic[] = [];
  public totals: number = 0;
  public isLoading: boolean;
  public fetchingClinic: boolean = false;

  get totalPage() {
    return Math.ceil(this.totals / 10);
  }

  get isEmptyDataClinic() {
    return !this.dataClinic.length;
  }

  @Action({ rawError: true, commit: 'SET_DATA_CLINIC'})
  public async getClinicList(params?: QueryClinic) {
    const response = await ApiHelper.getApi('ClinicApi').getList(params);
    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_CLINIC_DETAIL'})
  public async getClinicDetail(id) {
    this.context.commit('SET_FETCHING_CLINIC', true);
    const response = await ApiHelper.getApi('ClinicApi').show(id);
    this.context.commit('SET_FETCHING_CLINIC', false);
    return response;
  }

  @Action({ rawError: true, commit: 'SET_DATA_CLINIC'})
  public async create(params?: {}) {
    const response = await ApiHelper.getApi('ClinicApi').create(params);
    return response;
  }

  @Mutation
  public SET_DATA_CLINIC(response: {data: ModelClinic[], total: number}) {
    this.dataClinic = response.data;
    this.totals = response.total;
  }

  @Mutation
  public SET_DATA_CLINIC_DETAIL(response: ModelClinic[]) {
    this.dataClinic = response;
  }

  @Mutation
  public SET_FETCHING_CLINIC(status: boolean) {
    this.fetchingClinic = status;
  }
}
