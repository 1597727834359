var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentLayout',{attrs:{"icon":_vm.iconHeader,"name":"診療サービス詳細"}},[_c('div',{staticClass:"container-fluid question-list-edit"},[_c('div',{staticClass:"mx-2"},[(_vm.isAdmin)?_c('div',{staticClass:"question-list-edit__header header text-right"},[_c('div',{staticClass:"question-list-edit__header__text"},[_vm._v("問診票")]),_c('button',{staticClass:"btn",attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":_vm.handleAddQuestion}},[_c('i',{staticClass:"fa fa-plus-circle"}),_vm._v(" 質問追加 ")])]):_vm._e(),(_vm.isLoading && !_vm.isSubmiting)?_c('div',{staticClass:"question-list-edit__loading"},[_c('PageLoader')],1):_vm._e(),(!_vm.questions.length && !_vm.isLoading)?_c('div',{staticClass:"question-list-edit__no-data"},[_vm._v(" データが存在しません ")]):_vm._e(),(_vm.questions.length)?_c('ValidationObserver',{ref:"questionForm",class:{
          'question-list-edit__list': true,
          'question-list-edit__list__no-header': !_vm.isAdmin,
        },attrs:{"tag":"form"}},[_c('div',{staticClass:"question-list-edit__list"},[_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"font-weight-500 d-flex align-self-lg-center pr-3"},[_vm._v("問診NG(手動診察)カウント数")]),_c('ValidationProvider',{attrs:{"rules":!_vm.isDisabledInput ? 'required|onlyNumber' : '',"customMessages":_vm.optionErrorInputMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","maxlength":"4","errorText":errors[0],"disabled":_vm.isDisabledInput},on:{"input":function($event){return _vm.updateIsChanged(true)}},model:{value:(_vm.countManualSubscription),callback:function ($$v) {_vm.countManualSubscription=$$v},expression:"countManualSubscription"}})]}}],null,false,3870132356)})],1),_c('div',{staticClass:"d-flex mt-3 mb-3"},[_c('label',{staticClass:"font-weight-500 d-flex align-self-lg-center pr-3"},[_vm._v("問診NG(診察不要定期便)カウント数")]),_c('ValidationProvider',{attrs:{"rules":!_vm.isDisabledInput ? _vm.rulesCountAutoSubscription : '',"customMessages":_vm.optionErrorNumberOfMedicalInputMessages,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseInput',{staticClass:"input-custom-width",attrs:{"type":"text","maxlength":"4","errorText":errors[0],"disabled":_vm.isDisabledInput},on:{"input":function($event){return _vm.updateIsChanged(true)}},model:{value:(_vm.countAutoSubscription),callback:function ($$v) {_vm.countAutoSubscription=$$v},expression:"countAutoSubscription"}})]}}],null,false,122989682)})],1)]),_c('Draggable',_vm._b({ref:"questionList",attrs:{"list":_vm.questions}},'Draggable',_vm.dragOptions,false),_vm._l((_vm.questions),function(item,index){return _c('QuestionListItem',{key:item.id,staticClass:"mb-4",attrs:{"questionIndex":index,"question":item,"questionOptionEditting":_vm.questionOptionEditting,"hiddenIconDrag":_vm.dragOptions.disabled || !_vm.isAdmin,"hiddenIconDelete":_vm.dragOptions.disabled || !_vm.isAdmin,"readOnly":!_vm.isAdmin},on:{"onUpdateQuestion":function (name, value) { return _vm.handleUpdateQuestion(index, name, value, item); },"onDeleteQuestion":_vm.handleDeleteQuestion,"onAddOption":function($event){return _vm.handleAddOption(index)},"onChangeOption":function (optionIndex, value) { return _vm.handleUpdateOption(index, optionIndex, value); },"onEditOption":_vm.clickEdit,"onDeleteOption":function (optionIndex) { return _vm.handleDeleteOption(index, optionIndex); },"validOptions":_vm.checkValidOptions}})}),1)],1):_vm._e(),_c('FormAction',{staticClass:"question-list-edit__actions",attrs:{"disabledCancel":_vm.isSubmiting,"disabledSubmit":_vm.isLoading || _vm.isSubmiting || !_vm.isChanged || !_vm.questions.length,"hiddenBtnSubmit":!_vm.isAdmin,"btnCancelText":"戻る","btnSubmitText":"保存"},on:{"onSubmit":_vm.handleSubmit,"onCancel":_vm.handleCancel}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }