import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class DatePickerMixin extends Vue {
  // Methods
  handleMinDate(date: any, date_gte: any): boolean {
    return moment(date).isBefore(date_gte, 'date');
  }

  handleMaxDate(date: any, date_lte: any): boolean {
    if (moment(date).isAfter(moment(), 'date')) {
      return true;
    }
    return moment(date).isAfter(date_lte, 'date');
  }

  handleMinTime(time: any, date_gte: any): boolean {
    return moment(time).isBefore(date_gte, 'hour');
  }

  handleMaxTime(time: any, date_lte: any): boolean {
    if (moment(time).isAfter(moment(), 'minute')) {
      return true;
    }
    return moment(time).isAfter(date_lte, 'hour');
  }
}
