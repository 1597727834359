







































import {Vue, Component, Prop} from 'vue-property-decorator';
import moment from 'moment';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    DatePicker,
  },
  data() {
    return {
      moment,
    };
  },
})
export default class EditDelivery extends Vue {
  @Prop(Object) readonly deliveryItem: any;

  private formDeliveryDate = {
    delivery_date: null,
  };

  private get computedForm() {
    return this.$refs.formEditDelivery as any;
  }

  private hiddenEditDelivery() {
    this.$modal.hide('modalEditDelivery');
    this.resetLineMessage();
  }

  private resetLineMessage() {
    this.formDeliveryDate = {
      delivery_date: null,
    };
  }

  private async editDelivery() {
    const isValid = await this.computedForm?.validate();
    if (!isValid) {
      return;
    }
    ApiHelper.getApi('DeliveryApi')
        .updateDeliveryDate(this.deliveryItem.id, this.formDeliveryDate)
        .then((res: any) => {
          Toast.success('更新しました');
          this.hiddenEditDelivery();
          this.$emit('fetchOrder');
        })
        .catch((error: any) => {
          if (error.response) {
            Toast.error(error.response.data.message);
          }
        });
  }

  private notBeforeDate(date) {
    return date < new Date();
  }
}
