

























// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import moment from 'moment';

// interface
import { Patient } from '@/models/patient';
import { User } from '@/models/user';

// component
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { formatDateTimeJp } from '@/common/app.config';
import UpdateNote from '@/components/Patient/ModalUpdateNote.vue';


@Component({
  components: {
    PopupConfirm,
    UpdateNote,
  },
})
export default class UpdateNotePatient extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;

  private get canUpdateNote() {
    return this.currentUser.isAdminRole || this.currentUser.isPharmacistRole || this.currentUser.isCSRole;
  }

  private showUpdateNoteModal() {
    this.$modal.show('UserNoteModal');
  }

  private get noteAt() {
    return moment(this.patient.noted_at).format(formatDateTimeJp);
  }

  private updateUser(user) {
    this.patient.note = user.note;
    this.patient.noted_at = user.noted_at;
  }
}
