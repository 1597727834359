import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import LocalStorageUtils from '@common/LocalStorageUtils';

@Module({ name: 'dashboard', namespaced: true })
export default class DashBoard extends VuexModule {
  public selectedClinic: any[] = [];

  @Mutation
  public updateClinics(clinics: []) {
    this.selectedClinic = clinics;
    LocalStorageUtils.saveItem('dashboardID', clinics);
  }


  @Action({ rawError: true })
  public async setClinics(clinics: any) {
    const { commit } = this.context;
    commit('updateClinics', clinics);
  }
}
