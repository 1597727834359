








































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { DELIVERY_STATUS_NAME, DELIVERY_STATUS } from '@/common/constants/auth';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import lodash from 'lodash';
import CustomErrorMessage from '@/common/mixins/CustomErrorMessage';

@Component({
  components: {},
  mixins: [CustomErrorMessage],
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString = value
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}`;
    },
  },
})
export default class UpdateOrderTrackingListModal extends Vue {
  @Prop(String) readonly name!: string;
  @Prop({ default: 'auto' }) readonly height!: string | number;
  @Prop({ default: 800 }) readonly width!: string | number;
  @Prop({ default: true }) readonly scrollable!: boolean;
  @Prop({ default: {} }) readonly order!: any;

  private DELIVERY_STATUS = DELIVERY_STATUS;
  private isSubmiting = false;

  closeModal() {
    this.$modal.hide(this.name);
  }

  async submit() {
    const isValid = await this.getForm().validate();
    if (isValid) {
      let data: any = [];
      try {
        lodash.forOwn(
          this.getForm().fields,
          (value, key) => {
            if (value.changed === true) {
              let amount = this.order.tracking.find(
                (orderTracking) => orderTracking.id === Number(key),
              ).amount;

              amount = lodash.toNumber(amount);

              data = [
                ...data,
                {
                  id: value.name,
                  amount,
                },
              ];
            }
          },
        );

        this.updateIsSubmiting(true);

        const result = await ApiHelper.getApi(
          'OrderTrackingApi',
        ).updateOrderTrackingList({ orders_tracking: data });

        this.updateIsSubmiting(false);
        Toast.success('更新しました');
        this.$modal.hide(this.name);
      } catch (error) {
        this.isSubmiting = false;
        if (error.response) {
          const { data, status } = error?.response;
          if (status === 422) {
            this.getForm().setErrors(data.errors);
            return;
          }
        }
        this.$modal.hide(this.name);
        Toast.error(data?.message);
      }
    }
  }

  private getForm() {
    return (this.$refs.updateOrderTrackingListModal as any);
  }

  private deliveryDate(delivery) {
    return moment(delivery).format('YYYY年 M月 D日');
  }
  private getDeliveryState(state) {
    return DELIVERY_STATUS_NAME[state];
  }
  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }
}
