























import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component
export default class FormClinic extends Vue {
    // @State((state) => state.clinic) clinicState: any = {};
    @State((state) => state.bar) stateBar = {};

    public fieldItem: any = {
        id: {
            title: 'ID',
            readonly: true,
            type: '',
            rules: {},
        },
        affiliated_clinic_name: {
            title: 'クリニック名',
            type: 'input',
            rules: {
                maxlength: 50,
            },
        },
        affiliated_clinic_name_kana: {
            title: 'クリニック名（カナ）',
            type: 'input',
            rules: {
                maxlength: 10,
            },
        },
        phone: {
            title: '電話番号',
            type: 'input',
            rules: {},
        },
        postal_number: {
            title: '郵便番号',
            type: 'input',
            rules: {},
        },
        province: {
            title: '都道府県',
            type: 'select',
            rules: {},
        },
        city: {
            title: '市区町村',
            type: 'input',
            rules: {},
        },
        address: {
            title: '番地',
            type: 'input',
            rules: {},
        },
        building: {
            title: '建物名',
            type: 'input',
            rules: {},
        },
    };
}
