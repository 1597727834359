import BaseApi from './BaseApi';

class MessageApi extends BaseApi {
  public getMessages(channelId, params?) {
    return this.getOne(channelId, params);
  }

  public readAllMessages(channelId) {
    return this.get(`/${channelId}/reset-unread`);
  }

  public sendMessage(channelId, params) {
    const data = new FormData();
    Object.keys(params).forEach((key) => {
      data.append(key, params[key]);
    });

    return this.post(`/${channelId}`, data);
  }

  public sendSuggestPlan(channelId, data) {
    return this.post(`/${channelId}/suggest-plan`, data);
  }

  public confirmSuggestPlan(channelId, data) {
    return this.post(`/${channelId}/confirm-suggest-plan`, data);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/messages';
  }
}

export default MessageApi;
