import BaseApi from './BaseApi';

class AuraApi extends BaseApi {

  public addAuraTag(params: any) {
    return this.post('/add-aura-tag', params);
  }

  public removeAuraTag(params: any) {
    return this.post('/delete-aura-tag', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers';
  }

}

export default AuraApi;
