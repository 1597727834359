























































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PopupComfirm extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(String) readonly headerText!: string;
  @Prop(String) readonly message!: string;
  @Prop(String) readonly btnCloseText!: string;
  @Prop(String) readonly btnConfirmText!: string;
  @Prop({
    default: 'btn btn-danger btn-lg btn-rounded',
  }) readonly btnConfirmClass!: string;
  @Prop({ default: 'auto' }) readonly height!: number;
  @Prop({ default: 600 }) readonly width!: number;

  private onSubmit() {
    this.$emit('submit');
    this.$modal.hide(this.name);
  }
}
