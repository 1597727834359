export enum OrderStatusType {
  Waiting = 'waiting_payment',
  Confirm = 'confirm_payment',
  Failure = 'failure',
  Cancelled = 'cancelled',
  Success = 'success',
  Delivered = 'delivered',
  Undelivered = 'undelivered',
  Refunded = 'refunded',
}

export const OrderStatusDisplay = {
  waiting_payment: '未決済',
  failure: '決済エラー',
  cancelled: 'キャンセル',
  success: '決済済み',
  delivered: '契約',
  undelivered: '',
  refunded: '',
};

export enum DeliveryStatusType {
  Delivered = 'delivered',
  Undelivered = 'undelivered',
  Success = 'success',
  Refunded = 'refunded',
}

export enum DeliveryStateType {
  NotPaid = 'not_paid',
  Paid= 'paid',
  PreparingForDelivery= 'preparing_for_delivery',
  Delivered= 'delivered',
  DeliveryComplete= 'delivery_complete',
  DeliveryReturn= 'delivery_return',
  Cancel= 'cancel',
  ReadyForDelivery= 'ready_for_delivery',
  ErrorPaid= 'error',
  Refunded= 'refunded',
  Skipped= 'skipped',
}

export const DeliveryStateDisplay = {
  [DeliveryStateType.NotPaid]: '未決済',
  [DeliveryStateType.Paid]: '決済済み',
  [DeliveryStateType.PreparingForDelivery]: '配送準備中',
  [DeliveryStateType.Delivered]: '発送済',
  [DeliveryStateType.DeliveryComplete]: '配達済',
  [DeliveryStateType.DeliveryReturn]: '配送戻り',
  [DeliveryStateType.Cancel]: 'キャンセル',
  [DeliveryStateType.ReadyForDelivery]: '配送可',
  [DeliveryStateType.ErrorPaid]: '決済エラー',
  [DeliveryStateType.Refunded]: '返金済',
  [DeliveryStateType.Skipped]: 'スキップ済',
};


export enum OrderStatusString {
  Completed = '決済完了',
  Cancel = 'キャンセル済',
  Unpaind = '未決済',
  Confirm = '入金待ち',
}

export enum PaymentMethodId {
  credit = 1,
}

export const PaymentMethodIdDisplay = {
  [PaymentMethodId.credit]: 'クレジットカード',
};


export enum ProcessingType {
  ProductPurchase = 0,
  refund = 1,
  ChangeAmount = 2,
}

export const ProcessingTypeDisplay = {
  [ProcessingType.ProductPurchase]: '商品購入',
  [ProcessingType.refund]: '返金',
  [ProcessingType.ChangeAmount]: '変更 4回目配送',
};


