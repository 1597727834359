































































































































import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import PharmacistMixin from '@common/mixins/Pharmacist/PharmacistMixin';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import PageLoader from '@components/common/PageLoader.vue';
import AuthenticationUtils from '@common/AuthenticationUtils';

@Component({
  components: {
    PageLoader,
  },
})
export default class UpdatePassword extends Mixins(PharmacistMixin) {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop(String) readonly modalName!: string;
  private changePassword = {
    old_password: '',
    password: '',
    password_confirmation: '',
  };

  private isLoading: boolean = false;
  private computedForm: any = null;

  private get isDisableButton() {
    if (!this.computedForm) { return true; }
    const errors = this.computedForm.errors;
    for (const [key, value] of Object.entries(errors)) {
        if ((value as any).length) { return true; }
    }
  }

  private async updatePassword() {
    try {
      this.isLoading = true;
      const response = await ApiHelper.getApi('UserApi').updatePassword(this.changePassword);
      AuthenticationUtils.updateAccessToken(response.access_token);
      this.$modal.hide(this.modalName);
      Toast.success('更新しました');
      this.$emit('reload');
    } catch (error) {
      const data = error.response.data;
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
      Toast.error(data.message);
    } finally {
      this.isLoading = false;
    }
  }
  private onOpended() {
    this.$nextTick(() => {
      this.computedForm = this.$refs.pharmacistUpdatePassword;
    });
  }

  private beforeClose() {
    this.changePassword = {
      old_password: '',
      password: '',
      password_confirmation: '',
    };
    this.isLoading = false;
  }
}
