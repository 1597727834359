














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseModal extends Vue {
  @Prop(String) readonly name: string;
  @Prop({ default: '700px', type: String }) readonly width: string;
  @Prop({ default: 'auto', type: String }) readonly height: string;
  @Prop({ default: false, type: Boolean }) readonly clickToClose: boolean;

  beforeOpen({ params }) {
    document.documentElement.style.overflow = 'hidden';
    this.$emit('beforeOpen', { params });
  }

  beforeClose() {
    document.documentElement.style.overflow = 'auto';
    this.$emit('beforeClose');
  }
}
