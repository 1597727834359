


















































































































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DoctorModule, PatientModule } from '@/store';
import Toast from '@common/helpers/toast';
import { required } from 'vee-validate/dist/rules';
import ApiHelper from 'api-helper';
import { RESERVATION_TYPE } from '@/common/app.config';
import moment from 'moment';
import AutoNumeric from 'autonumeric';
import { MEDICAL_MENU_TYPE, MEDICAL_MENU_STATUS } from '@common/app.config';
import BasicSelect from '@components/common/BasicSelect.vue';
import PopupListDoctor from './PopupListDoctor.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';
import { splitLongText } from '@common/helpers/split-long-text';

@Component({
  components: {
    BasicSelect,
    PopupListDoctor,
  },
  filters: {
    formatNum: (value) => {
      const formatedString = value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
      return `¥${formatedString}`;
    },
  },
})
export default class PopupMedicalItemDetail extends Vue {
  private menuTimeList = [
    { name: '15分', value: 15 },
    { name: '30分', value: 30 },
    { name: '60分', value: 60 },
  ];

  private typeList = [
    { name: '保険診療', value: 1 },
    { name: '自由診療', value: 0 },
  ];
  private statusList = [
    { name: '表示', value: 1 },
    { name: '非表示', value: 0 },
  ];

  private doctorList = [];
  private doctorChoosend = [];
  private name: string = 'medical-menu-detail';
  private isEditting: boolean = false;
  private isCreate: boolean = false;
  private isLoading: boolean = false;
  private placeHolder = '予約料を入力してください';
  private item: any = {
    id: '',
    is_free: false,
    fee: '',
    name: '',
    status: 1,
    type: 1,
    time_frame: 30,
    updated_at: '',
    created_at: '',
    description: '',
    doctor_ids: '',
    request_health_insurance: 1,
  };

  private isNumber(event) {
    if (event.target.value.trim().length === 9) {
      return event.preventDefault();
    }
    const keyCode = event.charCode || event.keyCode;
    const currentVal = String.fromCharCode(keyCode);
    if (!/[0-9.]/.test(currentVal)) {
      event.preventDefault();
    }
  }

  private onInputFee() {
    const regex = /^([0-9]|\,)+$/;
    if (!regex.test(this.item.fee)) {
      this.item.fee = '';
      return;
    }
    this.item.fee = this.changeStrMoney(this.item.fee);
  }

  private changeStrMoney(num: string) {
    const strTrimComma = String(num).replace(/\,/g, '').slice(0, 7);
    if (/^(0)/.test(strTrimComma)) {
      return '0';
    }

    return strTrimComma.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  private onChooseDoctor(list) {
    this.doctorChoosend = list;
  }

  private choosenType() {
    this.item.is_free = false;
    this.item.fee = 0;
  }

  private splitText(value) {
    return splitLongText(value, 30);
  }

  private onDeleteDoctor(id) {
    this.doctorChoosend = this.doctorChoosend.filter((x) => (x as any).id !== id);
  }

  private get buttonText() {
    if (this.isCreate) {
      return '作成する';
    }
    if (this.isEditting) {
      return '更新する';
    }
    return '編集する';
  }

  private get date() {
    return moment((this.item as any).updated_at).format('YYYY/MM/DD HH:mm');
  }

  private get onlineText() {
    return (this.item as any).status === 1 ? '表示' : '非表示';
  }

  private get typeText() {
    return (this.item as any).type === 1 ? '保険診療' : '自由診療';
  }

  private get labelClass() {
    if (this.item.fee === 0 || this.item.fee) {
      return 'show';
    }
    return 'hide';
  }

  private get headingText() {
    if (this.isCreate) {
      return '診察メニュー作成';
    }
    if (this.isEditting) {
      return '診察メニュー編集';
    }
    return '診察メニュー詳細';
  }

  @Watch('item.is_free')
  private onChangeIsFree() {
    if (this.item.is_free === true) {
      this.item.fee = 0;
    }
  }

  private setDoctorIdArr(doctors) {
    if (doctors.length > 0) {
      this.item.doctor_ids = this.doctorChoosend.map((x) => (x as any).id);
    }
  }

  private async createMenu() {
    if (this.isLoading) {
      return;
    }

    if (this.item.fee === '') {
      this.item.fee = 0;
    } else {
      this.item.fee = String(this.item.fee).replace(/\,/g, '');
    }
    this.setDoctorIdArr(this.doctorChoosend);

    this.isLoading = true;
    ApiHelper.getApi('MedicalMenuApi')
      .create({
        ...this.item,
      })
      .then(() => {
        Toast.success('登録しました');
      })
      .catch((error) => {
        Toast.error(error.message);
      })
      .finally(() => {
        this.resetData();
        this.$modal.hide(this.name);
        this.isLoading = false;
        this.$emit('update');
      });
  }

  private resetData() {
    this.item = {
      id: '',
      is_free: false,
      fee: '',
      name: '',
      status: 1,
      type: 1,
      time_frame: 30,
      updated_at: '',
      created_at: '',
      description: '',
      doctor_ids: '',
      request_health_insurance: 1,
    };
  }

  private async updateMenu() {
    try {
      this.setDoctorIdArr(this.doctorChoosend);
      const fee = String(this.item.fee).replace(/\,/g, '');
      await ApiHelper.getApi('MedicalMenuApi').update(this.item.id, { ...this.item, fee });
      this.$modal.hide(this.name);
      Toast.success('更新しました');
      this.$emit('update');
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        Toast.error(data.message);
        return;
      }

      Toast.error(error.message);
    }
  }

  private handeEdit() {
    if (this.isCreate) {
      (this.$refs.form as any).validate().then((success) => {
        if (!success) {
          return;
        }
        this.createMenu();
      });
      return;
    }
    if (!this.isEditting) {
      this.isEditting = true;
      return;
    }

    this.updateMenu();
  }

  private onOpened() {
    this.item.fee = this.changeStrMoney(this.item.fee);
  }

  private beforeOpen(event) {
    if (event.params.isCreate) {
      this.isEditting = true;
      this.isCreate = true;
      this.doctorChoosend = [];

      return;
    }

    this.item = { ...event.params.item };
    this.doctorChoosend = cloneDeep(this.item.doctors);
    delete this.item.doctors;
    this.item = { ...this.item, doctor_ids: this.doctorChoosend.map((x) => (x as any).id) };
  }

  private beforeClose() {
    this.isCreate = false;
    this.isEditting = false;
    this.resetData();
  }

  private closeModal() {
    this.$modal.hide(this.name);
    this.resetData();
  }

  private mounted() {
    library.add(faPlus, faTrashAlt);
    dom.watch();
  }
}
