


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import { Roles } from '@common/app.config';
import { splitLongText } from '@common/helpers/split-long-text';

@Component
export default class MedicalMenuItem extends Vue {
  @Prop() item!: any;
  @State((state) => state.auth.currentUser) currentUser!: any;

  get feeText() {
    if (
      this.item.type === 1 ||
      (this.item.type === 0 && this.item.is_free)
    ) {
      return '無料';
    }
    const formatedString = this.item.fee
                            .toString()
                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    return `¥${formatedString}`;
  }

  get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }


  get onlineText() {
    return this.item.status === 1 ? '表示' : '非表示';
  }

  get typeText() {
    return this.item.type === 1 ? '保険診療' : '自由診療';
  }

  showDetail() {
    this.$emit('show-detail', this.item);
  }

  longText(text: string, qty: number) {
    return splitLongText(text, qty);
  }

  showPopupDelete() {
    this.$emit('show-delete', this.item);
  }
}
