import BaseApi from './BaseApi';

class RevenueApi extends BaseApi {
  public getSumRevenue(params: any) {
    return this.get('/revenue-total', params);
  }

  public getPriceData(params: any) {
    return this.get('/unit-price-chart', params);
  }

  public getRevenueData(params: any) {
    return this.get('/revenue-chart', params);
  }

  public getTableData(params: any) {
    return this.get('/revenue-by-day', params);
  }

  public redeposit(params: any) {
    return this.get('/redeposit', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/revenue-management';
  }
}

export default RevenueApi;
