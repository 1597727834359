<template>
  <div class="page-not-found">
    <div class="pt-5">
      <div class="content d-flex justify-content-center align-items-center">
        <div class="flex-fill">
          <div class="text-center mb-3">
            <h1 class="error-title">404</h1>
            <h5 class="text-danger mb-3">お探しのページは見つかりませんでした。</h5>
            <router-link to="/">
              <button type="button" class="btn-primary btn m-0">ダッシュボード</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class NotFound404 extends Vue {

}
</script>

<style lang="scss">
.error-title {
    color: #fff;
    font-size: 12.125rem;
    line-height: 1;
    margin-bottom: 2.5rem;
    font-weight: 300;
    text-stroke: 1px transparent;
    display: block;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
.page-not-found {
    height: 100vh;
    background: #f4f3ef;
}
</style>
