




















































































































import ApiHelper from 'api-helper';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Plan, QueryPlan } from '@/models/plan';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { uniqBy, compact } from 'lodash';
import Pagination from '@components/common/Pagination.vue';

@Component({
  components: {
    PageLoader,
  },
})
export default class PopupAddTreatment extends Vue {
  @Prop({ default: true }) readonly clickToClose!: boolean;
  @Prop(String) readonly name!: string;
  @Prop({ default: '55%' }) readonly width!: number | string;
  @Prop() readonly userId!: number | string;
  @Prop({ default: 700 }) readonly height!: number | string;
  @Prop(Number) readonly channelId!: string | number;

  isLoading: boolean = false;
  dataCourse: Plan[] = [];
  selectedPlan: number = 0;
  treatmentPoint: number = 0;
  treatmentType: number = 0;
  treatmentTime: string = '';
  toothExtraction: number = 0;
  description: string = '';

  get disabledToothExtraction(): boolean {
    return Number(this.treatmentPoint) === 2;
  }

  get isNullData(): boolean {
    if (Number(this.treatmentPoint) === 2) {
      return false;
    }
    return !this.treatmentPoint
        || !this.toothExtraction
        || !Number(this.treatmentTime)
        || !this.treatmentType;
  }

  isNumber(event) {
    const keyCode = event.charCode || event.keyCode;
    const currentVal = String.fromCharCode(keyCode);
    if (!/[0-9.]/.test(currentVal)) {
      event.preventDefault();
    }
  }

  formatTreatmentTime(e) {
    if (e.target.value.length === 2) {
      if (Number(e.target.value) > 48) {
        this.treatmentTime = '48';
      } else {
        this.treatmentTime = e.target.value;
      }
    } else {
      this.treatmentTime = e.target.value.slice(0, 2);
    }
  }

  getPlanList() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.dataCourse = [];

    ApiHelper.getApi('CourseApi')
        .getSuggestCourses({ channel_id: this.channelId})
        .then((response: any) => {
          this.dataCourse = response.data
              .map(({ attributes }) => attributes);
        })
        .catch((err: any) => {
          Toast.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
  }

  suggestTreatment() {
    let body = {};
    if (Number(this.treatmentPoint) === 2) {
      body = {
        user_id: this.userId,
        note: this.description,
        indication: Number(this.treatmentPoint),
      };
    } else {
      body = {
        user_id: this.userId,
        indication: Number(this.treatmentPoint),
        tooth_extraction: Number(this.toothExtraction),
        time_more: Number(this.treatmentTime),
        policy: Number(this.treatmentType),
        note: this.description,
      };
    }
    return body;
  }

  private beforeOpen() {
    this.treatmentPoint = 0;
    this.treatmentType = 0;
    this.treatmentTime = '';
    this.toothExtraction = 0;
    this.description = '';
  }
}
