import ListPlan from '@/views/plan/ListPlan.vue';
import CreateEditPlan from '@/views/plan/CreateEditPlan.vue';
import ConfirmPlan from '@/views/plan/ConfirmPlan.vue';
import DetailPlan from '@/views/plan/DetailPlan.vue';
import { authAdmin, authAdminDoctor, authSAdmin } from '@/common/constants/auth';

export default [
  {
    path: '/plans',
    name: 'plans',
    component: ListPlan,
    meta: authSAdmin,
  },
  {
    path: '/plans/create',
    name: 'plans.create',
    component: DetailPlan,
    meta: authAdmin,
  },
  {
    path: '/plans/confirm',
    name: 'plans.confirm',
    component: ConfirmPlan,
    meta: authAdmin,
  },
  {
    path: '/plans/detail/:id',
    name: 'plans.detail',
    component: DetailPlan,
    meta: authSAdmin,
  },
  {
    path: '/plans/edit/:id',
    name: 'plans.edit',
    component: CreateEditPlan,
    meta: authAdmin,
  },
];
