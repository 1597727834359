










import { Vue, Component, Watch } from 'vue-property-decorator';
import Channel from '@components/messages/Channel.vue';
import { ChannelModule, MessageModule } from '@/store';
import { mapState, mapGetters } from 'vuex';
import { State } from 'vuex-class';
import MessageBox from './MessageBox.vue';
import { Roles } from '@/common/app.config';
import LocalStorageUtils from '@/common/LocalStorageUtils';

@Component({
  components: {
    Channel,
    MessageBox,
  },
  computed: {
    ...mapState('channel', ['channels']),
    ...mapState('message', ['isUserListOpen']),
    ...mapState('auth', ['isGettingUserInfo']),
    ...mapGetters('channel', ['currentChannel']),
  },
})
export default class Message extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  channels!: any;
  currentChannel!: any;
  changedChannel: string = '';
  isGettingUserInfo!: boolean;
  isUserListOpen!: boolean;

  mounted() {
    if (this.$route.path === '/') {
      this.$router.push({name: 'PatientV2'});
    }

    MessageModule.toggleUserList(true);
  }

  get colorStyle() {
    if (this.currentUser && this.currentUser.role === Roles.pharmacy) {
      return {
        '--chat-msg-me': '#A1EAEF',
        '--chat-bg': '#F0F7F7',
      };
    }
  }

  beforeDestroy() {
    ChannelModule.resetChannels();
    if (LocalStorageUtils.getItem('currentChannel')) {
      LocalStorageUtils.removeItem('currentChannel');
    }
  }

  onChangedChannel() {
    this.changedChannel = this.$route.params.channelId;
  }

  get isNewUI() {
    if (this.currentUser && this.currentUser.role === Roles.pharmacy) {
      return 'new-ui';
    }
  }

  get isHasChannel() {
    return !!this.currentChannel;
  }
}
