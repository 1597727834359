import BaseApi from './BaseApi';

class FAQApi extends BaseApi {
  public orderFAQ(params) {
    return this.put(`/reorder`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/faq';
  }
}

export default FAQApi;
