







import { Component, Mixins } from 'vue-property-decorator';
import MessageMixin from './Message';
import { MESSAGE_TYPE, MESSAGE_LABEL_TYPE } from '@/common/enums/message';

@Component
export default class MessagePlan extends Mixins(MessageMixin) {
  textFinish: string = '';
  isFinish: boolean = false;
  mounted() {
    if (this.message.type_message === MESSAGE_TYPE.LABEL) {
      const temp = JSON.parse(this.message.message_content);
      if (temp.action === MESSAGE_LABEL_TYPE.ORDER_FINISH) {
        this.textFinish = temp.text;
      } else if (temp.action === MESSAGE_LABEL_TYPE.CHANNEL_FINISH) {
        this.textFinish = temp.text;
        this.isFinish = true;
      }
    }
  }
}
