


import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import PharmacistMixin from '@common/mixins/Pharmacist/PharmacistMixin';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import PageLoader from '@components/common/PageLoader.vue';
import ZoomImage from '@/components/common/ZoomImage.vue';
import UploadImage from '@/components/common/UploadImage.vue';
import PopupConfirm from '@/components/common/NewPopupConfirm.vue';

@Component({
  components: {
    PageLoader,
    ZoomImage,
    UploadImage,
    PopupConfirm,
  },
})
export default class ProfileEditModal extends Mixins(PharmacistMixin) {
  @State((state) => state.auth.currentUser) currentUser!: any;

  private pharmacist: any = {};
  private modalName = 'profile-edit';
  private isLoading: boolean = false;
  private computedForm: any = null;
  private isUpdateAvatar = false;

  private async updateAvatar(file) {
    try {
      this.isLoading = true;
      if (!file) { return; }
      const formData = new FormData();
      formData.append('avatar', file);
      const response = await ApiHelper.getApi('UserApi').uploadAvatar(formData);
      Toast.success('更新しました');
      this.isUpdateAvatar = true;
      const reader = new FileReader();
      reader.onload = (event: any) => {
          this.pharmacist.avatar_url = event.target.result;
        };
      reader.readAsDataURL(file);
    } catch (error) {
      const data = error.response.data;
      Toast.error(data.message);
    } finally {
      this.isLoading = false;
    }
  }

  private updatePharmacist() {
    this.computedForm.validate().then(async (success) => {
      if (!success) { return;  }
      try {
        this.isLoading = true;
        const expression = /^0/gi;
        await ApiHelper.getApi('UserApi').updateInfo({
          name: this.pharmacist.name,
          name_kana: this.pharmacist.name_kana,
          phone: this.pharmacist.phone.replace(expression, '81'),
          email: this.pharmacist.email,
        });

        Toast.success('更新しました');
        this.$modal.hide(this.modalName);
        this.$emit('reload');
      } catch (error) {
        const data = error.response.data;
        if (error.response.status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
        Toast.error(data.message);
      } finally {
        this.isLoading = false;
      }
    });
  }

  private onOpended() {
    this.$nextTick(() => {
      this.computedForm = this.$refs.pharmacistForm;
    });
  }

  private beforeOpen($event) {
    if ($event.params.pharmacist) {
      this.pharmacist = {...$event.params.pharmacist };
      const re = /^81/gi;
      this.pharmacist.phone = this.pharmacist.phone.replace(re, '0');
    }
  }

  private beforeClose() {
    if (this.isUpdateAvatar) {
      this.$emit('reload');
    }
    this.isUpdateAvatar = false;
  }
}
