var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrp-sidebar"},[(_vm.currentUser)?_c('div',{staticClass:"sidebar",attrs:{"data-color":"white","data-active-color":"danger"}},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":_vm.routeFollowRole}},[_c('img',{staticClass:"app-logo",attrs:{"src":require("@/assets/img/logo_horizontal_black.svg")}})])],1),_c('div',{staticClass:"sidebar-wrapper"},[(_vm.isShowClinicName() && _vm.currentClinicName())?_c('div',{staticClass:"c-name"},[_c('p',[_vm._v(_vm._s(_vm.currentClinicName()))])]):_vm._e(),_c('ul',{staticClass:"nav"},[_vm._l((_vm.routes),function(item,index){return [(
              item.roles.includes((_vm.currentUser || {}).role) &&
              !item.isExternal
            )?_c('router-link',{key:index,staticClass:"text-decoration-none text-nowrap sidebar-wrapper-item",attrs:{"to":item.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'router-link-active']},[(isActive && item.isDisabledSub)?_c('a',[(item.iconSvg)?_c('img',{staticClass:"mb-1",attrs:{"src":item.iconSvg,"alt":""}}):_c('i',{staticClass:"fas",class:item.icon}),_vm._v(" "+_vm._s(item.name)+" "),(item.isDisabledSub && _vm.unreadMessageCount)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(_vm._s(_vm.unreadMessageCount))]):_vm._e()]):_c('a',{attrs:{"href":href},on:{"click":navigate}},[(item.iconSvg)?_c('img',{staticClass:"mb-1",class:{ 'svg-inactive': !isActive },attrs:{"src":item.iconSvg,"alt":""}}):_c('i',{staticClass:"fas",class:item.icon}),_vm._v(" "+_vm._s(item.name)+" "),(item.isDisabledSub && _vm.unreadMessageCount)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(_vm._s(_vm.unreadMessageCount))]):_vm._e()])])]}}],null,true)}):_vm._e(),(
              item.roles.includes((_vm.currentUser || {}).role) && item.isExternal
            )?_c('li',{key:item.to,staticClass:"text-decoration-none text-nowrap sidebar-wrapper-item"},[_c('a',{key:item.to,attrs:{"href":item.to,"target":"_blank"}},[(item.iconSvg)?_c('img',{staticClass:"mb-1",attrs:{"src":item.iconSvg,"alt":""}}):_c('i',{staticClass:"fas",class:item.icon}),_vm._v(" "+_vm._s(item.name)+" "),(item.isDisabledSub && _vm.unreadMessageCount)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v(_vm._s(_vm.unreadMessageCount))]):_vm._e()])]):_vm._e()]})],2)]),_c('div',{staticClass:"sidebar-wrapper-setting"},[_c('div',{staticClass:"sidebar-wrapper-setting-info",attrs:{"title":_vm.currentUser.name}},[_c('img',{staticClass:"rounded-circle mr-2 img__object-fit--cover avatar-channel user-avatar mb-1",attrs:{"src":_vm.currentAvatar(),"alt":"A"}}),_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),_c('div',{staticClass:"btn-group",attrs:{"dropdown":"","placement":"top right"}},[_c('a',{staticClass:"btn btn-link text-decoration-none btn-toggle-setting",attrs:{"id":"button-alignment","href":"javascript:void(0)","aria-controls":"dropdown-alignment"},on:{"click":function($event){$event.stopPropagation();return _vm.toggle($event)}}},[_c('i',{staticClass:"fas fa-ellipsis-v"})]),_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.turnOfShow),expression:"turnOfShow"}],staticClass:"dropdown-menu dropdown-menu-left",class:{ show: _vm.isShow, 'not-doctor': !_vm.isDoctor() },attrs:{"id":"dropdown-alignment","role":"menu","aria-labelledby":"button-alignment"}},[(_vm.isDoctor())?_c('li',[_c('router-link',{staticClass:"text-white",attrs:{"to":("/doctors/" + (_vm.currentUser.id))}},[_vm._v("アカウント")])],1):_vm._e(),_c('li',{on:{"click":_vm.confirmlogout}},[_vm._v("ログアウト")])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }