import { Deserializable } from '@common/interfaces/deserializable';
import moment from 'moment';

export interface DeliverySearchNewInput {
  id: string;
  userName: string;
  planName: string;
  skuCode: string;
  date_lte: string;
  date_gte: string;
}

export class DeliverySearchNew implements Deserializable<DeliverySearchNew>, DeliverySearchNewInput {
  id: string;
  userName: string;
  planName: string;
  skuCode: string;
  date_lte: string;
  date_gte: string;

  deserialize(input: Partial<DeliverySearchNewInput>): DeliverySearchNew {
    Object.assign(this, input);

    return this;
  }

  toSearchJSON() {
    return {
      order_number: this.id,
      sku: this.skuCode,
      plan_ec_name: this.planName,
      user_name: this.userName,
      date_ship_from: this.date_gte && moment(this.date_gte).format('YYYY-MM-DD HH:mm:00'),
      date_ship_to: this.date_lte && moment(this.date_lte).format('YYYY-MM-DD HH:mm:59'),
    };
  }
}
