<template>
  <div class="custom">
    <!-- :class="isNewUI ? 'new-ui': ''" -->
    <paginate
      :pageCount="pageCount"
      :value="page"
      :clickHandler="pageChanged"
      :prevText="'<span>chevron_left</span>'"
      :nextText="'<span>chevron_right</span>'"
      :containerClass="containerClass"
      :page-link-class="'page-link'"
      :page-class="'pagination-page page-item'"
      :prev-link-class="'page-link material-icons'"
      :prev-class="'page-item material-icons'"
      :next-link-class="'page-link material-icons'"
      :next-class="'page-item material-icons'"
    >
    </paginate>
    <slot></slot>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Roles } from '@/common/app.config';
export default {
  props: ['pageCount', 'page', 'isDisabled'],
  methods: {
    pageChanged(pageNum) {
      this.$emit('pageChanged', pageNum);
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),

    isNewUI() {
      if (!this.currentUser) {
        return true;
      }
      return (
        this.currentUser.role === Roles.pharmacist ||
        this.currentUser.role === Roles.pharmacy
      );
    },

    containerClass() {
      return this.isDisabled ? 'pagination disabled' : 'pagination';
    },
  },
};
</script>
<style scoped lang='scss'>
.pagination {
  display: inline-flex;
  margin-top: 30px;

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.custom {
  text-align: center;

  &.new-ui {
    text-align: right;

    /deep/ .page-item {
      display: flex;
      justify-content: center;

      .page-link {
        border-radius: 8px;
        padding: 8px 14px;
        background-color: #d9dee2;
        color: white;
        transition: all 0.3s;

        &:hover {
          background-color: transparent;
          border-color: var(--primary-color);
          color: var(--primary-color);
        }
      }

      &.active a.page-link {
        background-color: var(--primary-color);
        border: var(--primary-color);

        &:hover {
          color: white;
        }
      }
    }
  }
}

/deep/ .pagination li + li {
  padding-left: 10px;
}

/deep/ .pagination .material-icons {
  font-size: 18px;
}

/deep/ .pagination li a {
  padding: 8px 13.5px;
}
</style>
