












































































































// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';

// helpers
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

// constants
import { USER_TREATMENT_STATE, USER_TREATMENT_STATE_CAN_ADD_KARTE } from '@/common/constants/auth';
import {formatDateJp, formatDateTimeJp} from '@common/app.config';

// components
import PageLoader from '@components/common/PageLoader.vue';
import BasicModal from '@components/common/BasicModal.vue';
import PrescriptionPopup from '@/components/v2/patient/message/partials/PrescriptionPopup.vue';

// interfaces
import { Patient } from '@/models/patient';
import { UserTreatment } from '@/models/user-treatment';
import LodashGet from 'lodash/get';
import { User } from '@/models/user';

@Component({
  components: {
    PageLoader,
    BasicModal,
    PrescriptionPopup,
  },
})

export default class KarteInfoChannel extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;
  @State((state) => state.auth.currentUser) currentUser!: User;

  private beingGetOrderConfirm: boolean = false;
  private isConfirming: boolean = false;

  private listFeedback: any[] = [];
  private feedbackForm: any = {};
  private orderConfirm: any = {};

  private formatDateJp = formatDateJp;
  private formatDateTimeJp = formatDateTimeJp;

  async created() {
    this.$store.commit('treatment_detail/setLoadingChild', true);
    await this.getListFeedback();
    this.$store.commit('treatment_detail/setLoadingChild', false);
  }

  // ***************** getter ***************** //
  private get isEnableConfirmingOrder() {
    return this.activeTreatment.state === USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK;
  }

  private get isDisableCreateFeedback() {
    return !USER_TREATMENT_STATE_CAN_ADD_KARTE.includes(this.activeTreatment.state);
  }

  private get confirmOrderFormRef() {
    return this.$refs.confirmOrderForm as any;
  }

  private get prescriptionPopupRef() {
    return this.$refs.prescriptionPopup as any;
  }

  private get treatmentName() {
    return this.activeTreatment.menu?.name;
  }

  public get isDisable() {
    const doctorId = LodashGet(
      this.activeTreatment,
      'reservations[0].doctor_id',
      '-',
    );

    return (!this.listFeedback || this.listFeedback.length === 0) && doctorId !== this.currentUser.id;
  }

  private isEditable(feedback) {
    if (feedback && feedback.doctor && feedback.doctor.id === this.currentUser.id) {
      return false;
    }
    return true;
  }

  private async getListFeedback() {
      const self = this;
      const payload = { user_treatment_id: this.activeTreatment.id, with: 'userTreatment.menu,plans,doctor' };
      await ApiHelper.getApi('PatientV2Api').getKarteList(this.patient.id, payload)
        .then(function(res) {
          self.listFeedback = res.data;
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        });
    }

  private showConfirmOrder() {
    if (!this.patient.id) {
      return;
    }

    const self = this;
    this.beingGetOrderConfirm = true;

    const params = {
      user_treatment_id: this.activeTreatment.id,
    };
    ApiHelper.getApi('PatientV2Api').getPatientOrderAccepted(this.patient.id, params)
      .then(function(res) {
        self.orderConfirm = res.data;
        self.$modal.show('confirmOrderForm');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.beingGetOrderConfirm = false;
      });
  }

  private showModalCreateFeedback(feedback = null) {
    if (!feedback) {
      this.feedbackForm = {
        id: null,
        created_at_jp: null,
        content: '',
        plans: [],
        type: null,
        ListPlans: [],
      };
    } else {
      this.feedbackForm = JSON.parse(JSON.stringify(feedback));
      this.feedbackForm.listPlans = [...this.feedbackForm.plans];
      this.feedbackForm.plans = this.feedbackForm.plans.map((item) => item.id);
    }

    this.prescriptionPopupRef.show(this.feedbackForm);
  }

  private savedFeedback() {
    this.getListFeedback();
  }

  private updateUserTreatmentState(data) {
    this.activeTreatment.state = data.state;
    this.$store.commit('user_treatment_list/setUserTreatmentState', this.activeTreatment);
  }

  private async saveConfirmOrder() {
    const isValid = await this.confirmOrderFormRef.validate();
    if (!isValid) {
      return;
    }

    this.isConfirming = true;
    const self = this;
    const params = {
      memo: this.orderConfirm.memo,
      order_id: this.orderConfirm.id,
    };
    await ApiHelper.getApi('PatientV2Api').doctorConfirmOrder(this.patient.id, params)
      .then((res) => {
        self.getListFeedback();
        if (res.data.userTreatment) {
          self.updateUserTreatmentState(res.data.userTreatment);
        }
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isConfirming = false;
        self.$modal.hide('confirmOrderForm');
      });
  }

  private handleWhenCreateFeedback(doctorFeedback: any) {
    this.activeTreatment.state = doctorFeedback.user_treatment.state;
    this.$store.commit('user_treatment_list/setUserTreatmentState', doctorFeedback.user_treatment);
  }
}
