import Login from '@/views/auth/Login.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import SetPassword from '@/views/auth/SetPassword.vue';
import ForgotPasswordSentMail from '@/views/auth/ForgotPasswordSentMail.vue';

const guestMeata = {
  guest: true,
  authorize: [],
};

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: guestMeata,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: guestMeata,
  },
  {
    path: '/forgot-password-sent-mail',
    name: 'ForgotPasswordSentMail',
    component: ForgotPasswordSentMail,
    meta: guestMeata,
  },
  {
    path: '/reset-password',
    name: 'SetPassword',
    component: SetPassword,
    meta: guestMeata,
  },
];
