
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseTextarea extends Vue {
  @Prop([String, Number]) readonly value!: string | number;
  @Prop(String) readonly errorText!: string;
  @Prop({default: '入力してください'}) readonly placeholder!: string;
  @Prop({default: '5'}) readonly rows!: string;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop([String, Number]) readonly maxlength!: string | number;
}
