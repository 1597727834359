







































import {Vue, Component, Prop} from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import {User} from '@/models/user';
import lodash from 'lodash';

@Component({
  components: {},
  data() {
    return {
    };
  },
})
export default class ModalLineMessage extends Vue {
  @Prop(Array) readonly userIds: any;
  @Prop(Boolean) readonly isSelectAll: any;
  @Prop(Object) readonly currentSearchParams: any;
  private isLoading: boolean = false;

  private formMessageLine = {
    message: null,
  };

  private hiddenLineMessage() {
    this.resetState();
    this.$modal.hide('modalLineMessage');
  }

  private resetState() {
    this.formMessageLine.message = null;
  }

  private async sendMessageLine() {
    const computedForm =  this.$refs.formMessageLine as any;
    const isValid = await computedForm?.validate();
    if (!isValid) {
      return;
    }
    this.isLoading = true;
    ApiHelper.getApi('WebhookLineApi')
        .webhookLineSendMessageToManyUser({ user_ids: this.userIds, is_select_all: this.isSelectAll, ...this.formMessageLine, ...this.currentSearchParams })
        .then(() => {
          this.isLoading = false;
          Toast.success(' ユーザーへメッセージを送信しました。');
          this.hiddenLineMessage();
        })
        .catch((error) => {
          this.isLoading = false;
          Toast.error(error.response.data.message);
        });
    // this.$emit('resetIdsSelected');
    this.hiddenLineMessage();

  }
}
