





















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { DeliveryStateDisplay } from '@common/enums/order';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import CustomErrorMessage from '@common/mixins/CustomErrorMessage';

@Component({
  components: {},
  mixins: [CustomErrorMessage],
  data() {
    return {
      moment,
      DeliveryStateDisplay,
    };
  },
})
export default class ModalEditAmount extends Vue {
  @Prop(Object) readonly deliveryItem: any;
  tableHead: string[] = ['配送予定日', '配送ステータス', '金額'];
  private isLoading: boolean = false;

  private formData = {
    amount: '',
  };

  @Watch('deliveryItem')
  private updateRefundAmount(value) {
    this.formData.amount = value.amount;
  }

  private async editAmount() {
    const computedForm = this.$refs.formEditAmount as any;
    const isValid = await computedForm?.validate();
    if (!isValid) {
      return;
    }
    this.isLoading = true;

    ApiHelper.getApi('DeliveryApi')
      .updateAmount(this.deliveryItem.id, this.formData)
      .then((res) => {
        this.isLoading = false;
        Toast.success('金額の変更が行われました');
        this.hiddenEditAmount();
        this.$emit('fetchOrder');
      })
      .catch((error) => {
        this.isLoading = false;
        Toast.error(error.response.data.message);
      });
  }

  private hiddenEditAmount() {
    this.$modal.hide('modalEditAmount');
  }
}
