










































































import { Vue, Component, Mixins } from 'vue-property-decorator';
import { defaultDataFormEdit } from '@/models/pharmacist';
import PopupConfirm from '@/components/common/NewPopupConfirm.vue';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { Roles, formatDateTime } from '@/common/app.config';
import ApiHelper from 'api-helper';
import ZoomImage from '@/components/common/ZoomImage.vue';
import PharmacistUpdateModal from './PharmacistUpdateModal.vue';
import moment from 'moment';

@Component({
  components: {
    PopupConfirm,
    PageLoader,
    ZoomImage,
    PharmacistUpdateModal,
  },
})
export default class PharmacistUpdateNewUI extends Vue {
  private isSubmit: boolean = false;
  private dataForm: any = JSON.parse(JSON.stringify(defaultDataFormEdit));
  private pharmacistId: any;
  private isLoading: boolean = false;

  private created() {
    const { id } = this.$route.params;
    this.pharmacistId = id;
    this.getPharmacistDetail();
  }

  private confirmDelete() {
    this.$modal.show('confirm');
  }

  private formatDate(date) {
    return moment(date).format(formatDateTime);
  }

  private get updateAt() {
    if (this.dataForm.created_at === this.dataForm.updated_at) {
      return null;
    }
    return this.formatDate(this.dataForm.updated_at);
  }

  private showEditModal() {
    this.$modal.show('pharmacist-update', {
      pharmacist: this.dataForm,
    });
  }

  private get storeLists() {
    const storeNames = this.dataForm.stores.map((item) => {
      return item.name;
    });
    return storeNames.join('、');
  }

  private async deletePharmacy() {
    if (!this.pharmacistId) {
      return ;
    }

    try {
      await ApiHelper.getApi('PharmacistApi').destroy(this.pharmacistId);
      Toast.success('削除しました');
      this.$router.push({ name: 'pharmacists' });

    } catch (error) {
      const data = error.response.data;
      if (error.response.status === 422) {
        return;
      }
      Toast.error(data.message);
    }
  }

  private async getPharmacistDetail(isImage = false) {
    try {
      this.isLoading = true;
      const params = { with: 'pharmacistStores' };
      const response = await ApiHelper.getApi('PharmacistApi').getOne(this.pharmacistId, params);
      this.dataForm = response;
      const re = /^81/gi;
      this.dataForm.phone = this.dataForm.phone.replace(re, '0');
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
