











































import ApiHelper from 'api-helper';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import { ModelPatientDetail } from '@/models/patient';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import BasicSelect from '@/components/common/BasicSelect.vue';
import { mapState } from 'vuex';
import { Roles } from '@common/app.config';

@Component({
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  components: {
    BasicSelect,
    PopupConfirm,
  },
})

export default class PatientDetailAssignDoctor extends Vue {

  private get gender() {
    return this.dataPatientDetail.gender ? '男性' : '女性';
  }

  private get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }
  @Prop(Object) readonly dataPatientDetail!: ModelPatientDetail;
  private doctorOptions: any[] = [];
  private selectedAssignDoctor: any = null;
  private isDoctorAssigned: boolean = false;
  private hasReservations: boolean = false;
  private currentUser!: any;

  handleAssignDoctorData() {
    let isDoctorAssigned = false;

    this.dataPatientDetail.reservations.forEach((item) => {
      if (item.doctor_id) { isDoctorAssigned = true; }
    });

    this.isDoctorAssigned = isDoctorAssigned;
    this.hasReservations = this.dataPatientDetail.reservations.length > 0;

    if (this.hasReservations) {
      this.selectedAssignDoctor = this.dataPatientDetail.reservations[0].doctor_id;
    }
  }

  mounted() {
    if (this.isAdmin) { this.fetchData(); }

    this.handleAssignDoctorData();
  }


  private hiddenPopup() {
    this.$modal.hide('confirmAssignDoctor');
  }

  private showConfirmAssignDoctorModal(id) {
    this.$modal.show('confirmAssignDoctor');
  }

  private async fetchData() {
    try {
      const result = await ApiHelper.getApi('DoctorApi').list();

      this.doctorOptions = result.data.map((item) => ({
        name: item.name,
        value: item.id,
      }));
    } catch (e) {
      Toast.error(e.response.data.message);
    }
  }

  private setTagAura(tagId, tagsDelete) {
    const params = {
      tags: tagId,
      is_add_and_delete: tagsDelete ? 1 : 0,
      line_id: (this.dataPatientDetail as any).line_id,
      delete_after_add: tagsDelete || [],
    };
    ApiHelper.getApi('AuraApi').addAuraTag(params);
  }

  private async onAssignDoctor() {
    try {
      const result = await ApiHelper.getApi('AssignDoctorApi').create({
        reservation_id: this.dataPatientDetail.reservations[0].id,
        doctor_id: this.selectedAssignDoctor,
      });
      Toast.success('成功');
      this.setTagAura([863294, 837092, 923619], [837089, 856733]);
      this.isDoctorAssigned = true;
    } catch (e) {
      Toast.error(e.response.data.message);
    } finally {
      this.hiddenPopup();
    }
  }

  @Watch('dataPatientDetail')
  private dataPatientDetailChanged(val: ModelPatientDetail) {
    this.handleAssignDoctorData();
  }
}
