






















































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import BasicSelect from '@components/common/BasicSelect.vue';
import PageLoader from '@components/common/PageLoader.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { State } from 'vuex-class';
import { NEW_USER_STATE, NEW_USER_STATE_NAME, USER_TREATMENT_STATE, USER_TREATMENT_STATE_CAN_ADD_KARTE, USER_TREATMENT_NAME } from '@/common/constants/auth';
import lodash from 'lodash';
import Multiselect from 'vue-multiselect';
import PatientItem from '@components/v2/patient/PatientItem.vue';
import moment from 'moment';
import { transformBlobToFile } from '@/common/utils';

@Component({
  components: {
    PageLoader,
    PatientItem,
    VuePerfectScrollbar,
    InfiniteLoading,
    BasicSelect,
    Multiselect,
  },
})
export default class Channel extends Vue {

  @State((state) => state.patient_list.data) patientList!: any;
  @State((state) => state.auth.currentUser) currentUser!: any;
  private query = '';
  private delayId: any = null;
  private selectedStates: any = [];
  private selectedTreatmentStates: any = [];
  private stateOptions: any[] = [];
  private treatmentStateOptions: any[] = [];
  private selectedSort: string = '';
  private sortOptions: any[] = [
    { name: '並び替え', value: '' },
    { name: '名前 昇順', value: 'family_name_kana' },
    { name: '名前 降順', value: '-family_name_kana' },
    { name: '登録日 昇順', value: 'registered_at' },
    { name: '登録日 降順', value: '-registered_at' },
    { name: '更新日 昇順', value: 'updated_at' },
    { name: '更新日 降順', value: '-updated_at' },
  ];
  private pathName = this.$route.matched[1].path;
  private activeTab: any = '';
  private isDownloadCsv: boolean = false;

  created() {
    this.stateOptions.push({ name: 'クリア', value: 'clear_all' }, ...this.makeStateOption());
    this.treatmentStateOptions.push({ name: 'クリア', value: 'clear_all' }, ...this.makeTreatmentsOption());
    this.setInputValueFromQueryData();
    // this.setSearchDefaultState();
    this.setUrlWithQuery();

    if (this.$refs.InfiniteLoading) {
      (this.$refs.InfiniteLoading as any).stateChanger.reset();
    }
    this.$store.commit('patient_list/resetPatientListState');
  }

  beforeDestroy() {
    this.$store.commit('patient_list/resetPatientListState');
  }


  debouncedSearch(selected) {
    if (lodash.isArray(selected) && selected.findIndex((option) => option.value === 'clear_all') !== -1) {
      this.selectedStates = [];
    }
    if (lodash.get(this.selectedStates, '0.value', '') === '') {
      this.selectedStates = [];
    }
    clearTimeout(this.delayId);
    this.$nextTick(() => {
      this.delayId = setTimeout(this.onSearch, 2000);
    });
  }

  onSearch($state) {
    this.setUrlWithQuery(this.getCurrentPage());
    (this.$refs.infiniteLoading as any).stateChanger.reset();
    this.$store.commit('patient_list/resetPatientListState');
  }

  async exportPatientList($state) {
    this.isDownloadCsv = true;

    const payload = {
      manager_query: this.query,
      new_state: this.getStatusSearch(),
      order: this.selectedSort,
      treatment_state: this.selectedTreatmentStates.map((item) => item.value),
    };

    const response = await this.$store.dispatch('patient_list/exportUserCsv', { payload, $state });

    transformBlobToFile(
      response,
      `${moment().format('YYYY年MM月DD日')}.csv`,
    );

    this.isDownloadCsv = false;
  }

  private isNotLastItem(index) {
    return (index + 1) != this.patientList.length;
  }


  private setUrlWithQuery(path: string|null = null) {
    this.$router.push({
      path: path ? path : this.$route.path,
      query: {...this.$route.query, ...this.makeSearchParams()},
    });
  }

  private makeSearchParams() {
    return {
        state: this.selectedStates.map((item) =>  item.value),
        treatment_state: this.selectedTreatmentStates.map((item) => item.value),
        search: this.query,
        order: this.selectedSort,
        time: lodash.now().toString(),
        tab: this.activeTab || '',
      };
  }

  private makeQueryParams() {
    return location.search;
  }

  private setSearchDefaultState() {
    if (this.$route.query.state || this.$route.query.search) {
      return;
    }

    // if (this.currentUser.isSuperAdminRole) {
    //   return this.selectedStates = [];
    // }

    if (this.currentUser.isCSRole) {
      return this.selectedStates = [
        { name: NEW_USER_STATE_NAME.IDENTITY_CHECK, value: NEW_USER_STATE.IDENTITY_CHECK },
      ];
    }

    if (!this.currentUser.isCSRole) {
      this.selectedStates = [
        { name: NEW_USER_STATE_NAME.PATIENT_PROVISIONALLY_REGISTERED, value: NEW_USER_STATE.PATIENT_PROVISIONALLY_REGISTERED },
        { name: NEW_USER_STATE_NAME.IDENTITY_CHECK, value: NEW_USER_STATE.IDENTITY_CHECK },
        { name: NEW_USER_STATE_NAME.IDENTITY_VERIFICATION_NOT_REGISTERED, value: NEW_USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED },
        { name: NEW_USER_STATE_NAME.REQUESTING_SMS_VERIFICATION, value: NEW_USER_STATE.REQUESTING_SMS_VERIFICATION },
        { name: NEW_USER_STATE_NAME.IDENTIFICATION_NG, value: NEW_USER_STATE.IDENTIFICATION_NG },
        { name: NEW_USER_STATE_NAME.IDENTIFICATION_OK, value: NEW_USER_STATE.IDENTIFICATION_OK },
      ];
    }
  }

  private setInputValueFromQueryData() {
    const query = this.$route.query;
    let stateList: any = [];

    if (lodash.isString(query.state)) {
      stateList.push(query.state);
    }
    if ( lodash.isArray(query.state)) {
      stateList = query.state;
    }

    this.selectedStates = this.getStateObjectList(stateList);
    this.query = query.search ? query.search.toString() : '';
    this.selectedSort = query.order ? query.order.toString() : '';
    this.activeTab = query?.tab ? query?.tab : '';
  }

  private getStateObjectList(stateList) {
    return lodash.compact(
      stateList.map(
        (state) => {
          return { name: NEW_USER_STATE_NAME[state?.toUpperCase()], value: state};
        },
      ),
    );
  }

  private getCurrentPage() {
    return location.pathname;
  }

  private async loadData($state) {
    const payload = {
      manager_query: this.query,
      new_state: this.getStatusSearch(),
      order: this.selectedSort,
      treatment_state: this.selectedTreatmentStates.map((item) => item.value),
    };
    await this.$store.dispatch('patient_list/getPatientList', { payload, $state});
  }

  private getStatusSearch() {
    let statesName: any = [];

    if (this.selectedStates && this.selectedStates.length > 0) {
      this.selectedStates.length > 0 && this.selectedStates.map((data) => {
        statesName = [...statesName, data.value];
      });

      return statesName.filter((data) => data.value !== '');
    }

    return statesName;
  }

  private makeStateOption = () => {
    const data: any = [];
    Object.keys(NEW_USER_STATE).map((name) => {
      if (
        ['PATIENT_PROVISIONALLY_REGISTERED', 'REGISTERED_MEMBER', 'VIDEO_INTERVIEW_SCHEDULED'].includes(name)
      ) {
        data.push({ name: NEW_USER_STATE_NAME[name], value: NEW_USER_STATE[name]});
      }
    });

    return data;
  }

  private makeTreatmentsOption = () => {
    const data: any = [];
    Object.keys(USER_TREATMENT_STATE).map((name) => {
      if (
        ['CONFIRMATION_INTERVIEW', 'INTERVIEW_COMPLETED'].includes(name)
      ) {
        data.push({ name: USER_TREATMENT_NAME[name], value: USER_TREATMENT_STATE[name] });
      }
    });

    return data;
  }

  private reloadPatientList() {
    // reload patient list for patient component
    (this.$refs.infiniteLoading as any).stateChanger.reset();
  }
}
