export enum CHAT_STATUS {
  ACTIVE = 1,
  PREPARE = 2,
  INACTIVE = 0,
}

export enum MESSAGE_TYPE {
  TEXT = 1,
  IMAGE = 2,
  LINK = 3,
  BUTTON = 4,
  LABEL = 5,
  CLINIC_PATTERN = 6,
  FOR_DOCTOR = 7,
  PAYING = 9,
}

export enum MESSAGE_LABEL_TYPE {
  ORDER_FINISH = 'order_finished',
  CHANNEL_FINISH = 'finish_channel',
}

export enum MESSAGE_BUTTON_TYPE {
  PLAN = 'plan_suggestion',
  CALLING = 'start_calling',
}
