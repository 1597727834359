import ListClinicPattern from '@/views/clinic_pattern/List.vue';
import CreateClinicPattern from '@/views/clinic_pattern/Create.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.superAdmin],
};

export default [
  {
    path: '/clinic_pattern',
    name: 'clinic-pattern',
    component: ListClinicPattern,
    meta: authMeta,
  },
  {
    path: '/clinic_pattern/create',
    name: 'clinic-pattern-create',
    component: CreateClinicPattern,
    meta: authMeta,
  },
];
