import ListDelivery from '@/views/deliveries/ListDelivery.vue';
import DetailDelivery from '@/views/deliveries/DetailDelivery.vue';
import { authDelivery, authAll } from '@/common/constants/auth';

export default [
    {
        path: '/deliveries',
        name: 'deliveries',
        component: ListDelivery,
        meta: authAll,
    },
    {
        path: '/deliveries/:id',
        name: 'deliveries.detail',
        component: DetailDelivery,
        meta: authDelivery,
    },
    // {
    //     path: '/deliveries/create',
    //     name: 'deliveries.create',
    //     component: DetailDelivery,
    //     meta: authDelivery,
    // },
];
