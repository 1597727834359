import Vue from 'vue';
import axios from 'axios';
import VueI18n from 'vue-i18n';

import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal';
import Paginate from 'vuejs-paginate';
import VueHead from 'vue-head';
import Datepicker from 'vuejs-datepicker';
import Popover from 'vue-js-popover';
import vSelect from 'vue-select';
import ClickOutside from './directives/click_outside';
import './Validation';
import './fontAwesome';

Vue.directive('click-outside', ClickOutside);

import AuthenticationUtils from './AuthenticationUtils';
import LocalStorageUtils from './LocalStorageUtils';

Vue.use(VueHead);
Vue.use(VModal, { dialog: true });
Vue.use(Toasted, {
  duration : 2000,
  iconPack: 'fontawesome',
  className: 'toasted-line-height',
});
Vue.use(Popover, { tooltip: true });

Vue.component('paginate', Paginate);
Vue.component('DatePicker', Datepicker);
Vue.component('v-select', vSelect);

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
AuthenticationUtils.updateAxiosHeader();

const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0].split('-')[0];
  }

  return navigator.language || 'ja';
};

const locale = LocalStorageUtils.getItem('user.locale') || getNavigatorLanguage();
window.document.documentElement.setAttribute('lang', 'locale');

declare global {
  interface Window { i18n: VueI18n; }
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'ja',
  messages: {},
});

window.i18n = i18n;
