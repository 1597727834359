var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"question-list-item"},[_c('QuestionListItemGroup',{attrs:{"label":"質問タイプ"}},[_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BasicSelect',{class:{
          'question-list-item__select': true,
          'is-invalid': !!errors.length,
        },attrs:{"value":_vm.question.type,"readOnly":_vm.readOnly,"reduce":function (option) { return option.value; },"options":_vm.typeOptions,"clearable":false,"searchable":false,"isIconUpDown":"","iconUpDownSimple":"","label":"name","placeholder":"選択してください"},on:{"input":function($event){return _vm.$emit('onUpdateQuestion', 'type', $event, _vm.question)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('QuestionListItemGroup',{attrs:{"label":"質問"}},[(_vm.isTextArea)?_c('ValidationProvider',{attrs:{"rules":"required|max:255","tag":"div","customMessages":_vm.questionErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"maxlength":"255","placeholder":"質問を入力してください","value":_vm.questionValue(_vm.question.question),"readOnly":_vm.readOnly,"errorText":errors[0]},on:{"input":function($event){return _vm.$emit('onUpdateQuestion', 'question', $event.target.value)}}})]}}],null,false,4008358559)}):_c('ValidationProvider',{attrs:{"rules":"required|max:255","tag":"div","customMessages":_vm.questionErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseInput',{attrs:{"value":_vm.question.question,"readOnly":_vm.readOnly,"errorText":errors[0],"placeholder":"質問を入力してください"},on:{"input":function($event){return _vm.$emit('onUpdateQuestion', 'question', $event)}}})]}}])})],1),(_vm.isShowInputPlaceholder)?_c('QuestionListItemGroup',{attrs:{"label":"プレースホルダー"}},[_c('ValidationProvider',{attrs:{"rules":"max:100","tag":"div","customMessages":_vm.questionInputErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseInput',{attrs:{"errorText":errors[0],"value":_vm.question.placeholder,"readOnly":_vm.readOnly,"placeholder":"プレースホルダーを入力してください"},on:{"input":function($event){return _vm.$emit('onUpdateQuestion', 'placeholder', $event)}}})]}}],null,false,1500701222)})],1):_vm._e(),(_vm.isShowInputPlaceholder && _vm.question.count_ng_required)?_c('QuestionListItemGroup',{attrs:{"label":""}},[_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('label',{staticClass:"m-0 mr-3 d-flex align-items-center"},[_vm._v("カウント数")]),_c('div',{staticClass:"wpx-150"},[_c('ValidationProvider',{attrs:{"rules":"required|onlyNumber","tag":"div","customMessages":_vm.questionInputErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","maxlength":"4","value":_vm.question.options.count_number,"errorText":errors[0]},on:{"input":function($event){return _vm.$emit('onUpdateQuestion', 'count_number', $event)}}})]}}],null,false,3434748819)})],1)])]):_vm._e(),(_vm.isShowQuestionOptions)?_c('QuestionListItemGroup',{attrs:{"labelBgNone":"","valueOverflowHidden":""}},[_c('QuestionListItemOptions',{attrs:{"question":_vm.question,"optionEditting":_vm.questionOptionEditting,"options":_vm.question.options,"readOnly":_vm.readOnly,"countNgRequired":_vm.question.count_ng_required},on:{"onChangeOption":function (index, value) { return _vm.$emit('onChangeOption', index, value); },"onEditOption":function($event){return _vm.$emit('onEditOption', $event)},"onDeleteOption":function($event){return _vm.$emit('onDeleteOption', $event)},"onAddOption":function($event){return _vm.$emit('onAddOption')},"validOptions":function($event){return _vm.$emit('validOptions' , $event)}}})],1):_vm._e(),_c('QuestionListItemAction',{attrs:{"required":_vm.question.required,"countNgRequired":_vm.question.count_ng_required,"hiddenIconDrag":_vm.hiddenIconDrag,"hiddenIconDelete":_vm.hiddenIconDelete,"readOnlyCheckboxRequired":_vm.readOnly,"disabledFirstQuestion":_vm.questionIndex == 0},on:{"onCheckbox":function (name, value) { return _vm.$emit('onUpdateQuestion', name, value); },"onDelete":function($event){return _vm.$emit('onDeleteQuestion', _vm.question.id)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }