import BaseApi from './BaseApi';

class MemberApi extends BaseApi {
    public fetchMemberList(params: any) {
        return this.get('/members', params);
    }

    public changeRole(data: {id: number, role: string}) {
        return this.post('/members/change-role', data);
    }

    public sendInvite(data: any) {
        return this.post(`/members/send-invite`, data);
    }

    public getInviteMemberByToken(token: string) {
        return this.get(`/members/get-invite-by-token/${token}`);
    }

    public createMemberByToken(token: string, data: any) {
        return this.post(`/members/create-by-token/${token}`, data);
    }

    public detail(id: number, data: any) {
        return this.get(`/members/${id}`, data);
    }

    public async update(id: number, data: any) {
        return this.put(`/members/${id}`, data);
    }

    public async removeMember(id: number) {
        return this.del(`/members/${id}`);
    }

  public async updatePassword(id, data) {
    return this.put(`/update-password/${id}`, data);
  }

    protected getSpecificApiPrefix(): string {
        return 'managers';
    }
}

export default MemberApi;
