






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {faCameraRetro} from '@fortawesome/free-solid-svg-icons';
import imageDefault from '@/assets/img/icon-no-image.svg';
import Toast from '@common/helpers/toast';
import ImagePrivate from '@/components/common/ImagePrivate.vue';

@Component({
  components: {
    PopupConfirm,
    ImagePrivate,
  },
})
export default class UploadImage extends Vue {
  @Prop([String, File]) readonly value!: string | File;
  @Prop(String) readonly urlPath!: string;
  @Prop(String) readonly alt!: string;
  @Prop({default: false}) readonly confirm!: boolean;
  @Prop(String) readonly messageConfirm!: string;
  @Prop({ default: 3}) readonly maxSize!: number;
  @Prop({default: 'jpgやjpegやpng形式のファイルでアップロードしてください'}) errorMessage!: string;
  @Prop({default: () => ['image/jpeg', 'image/png', 'image/jpg']}) imageAccept!: string[];
  @Prop()  deleteImage!: any;

  private isLoading: boolean = false;

  private imagePreview: string | File = this.value;
  private file: File | null = null;

  private get src() {
    return this.imagePreview;
  }

  private onChangeFile() {
    this.$emit('onChange', this.file);
    this.cancelPopupConfirm();
    this.isLoading = true;
  }

  private clearImage() {
    this.imagePreview = '';
    this.deleteImage && this.deleteImage('');
    this.isLoading = false;
  }

  private calculateMB(byte) {
    return Number(byte) / 1048576;
  }

  // Methods
  private openPopupConfirm(e: any) {
    const files = e.target.files;
    if (files && files[0]) {
      if (this.validateUploadFile(files[0].type)) {
        if (this.calculateMB(files[0].size) > this.maxSize) {
          Toast.error(`${this.maxSize}MB以下の写真をアップロードしてください`);
          this.clearForm();
          return;
        }

        if (this.confirm) {
        this.file = files[0];
        this.$modal.show('popupConfirm');
        } else {
          this.isLoading = true;
          this.$emit('onChange', files[0]);
        }
      } else {
        Toast.error(this.errorMessage);
      }
    }
  }

  private validateUploadFile(type) {
    const typeImages: string[] = this.imageAccept;
    return typeImages.includes(type);
  }

  private cancelPopupConfirm() {
    this.$modal.hide('popupConfirm');
  }

  private clearForm() {
    (this.$refs.myFiles as HTMLInputElement).value = '';
    this.file = null;
  }

  @Watch('value')
  private onValueChanged(val, oldVal) {
    if (!val || typeof val === 'string') {
      this.imagePreview = val;
      return;
    }
    if (typeof val === 'object') {
      const file = val;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imagePreview = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
  @Watch('src')
  private onSrcChanged(val: string, oldVal: string) {
    if (oldVal === imageDefault) {
      this.isLoading = true;
    }
  }

  private created() {
    library.add(faCameraRetro);
    dom.watch();
  }
}
