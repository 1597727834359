import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import ApiHelper from 'api-helper';

@Module({ name: 'medical_menu', namespaced: true })
export default class MedicalMenu extends VuexModule {
  public dataMenu = [];

  @Action({ rawError: true, commit: 'SET_DATA_MENU' })
  public async getListMenu(param?: any) {
    const response = await ApiHelper.getApi('MedicalMenuApi').getList(param);

    return response;
  }

  @Mutation
  public SET_DATA_MENU(response) {
    this.dataMenu = response.data;
  }
}
