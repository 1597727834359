var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient-control patient-control-content patient-control-margin"},[(_vm.patientAnswer && _vm.patientAnswer.length > 0)?_c('div',_vm._l((_vm.patientQuestionSort()),function(question,index){return _c('div',{key:question.id,staticClass:"patient-control-question"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"patient-control-question-first"},[_vm._v(" Q"+_vm._s(index + 1)+" ")]),_c('p',{staticClass:"patient-control-question-second pre-wrap"},[_vm._v(" "+_vm._s(question.question)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"patient-control-question-first question-answer"},[_vm._v("回答")]),(
            (question.type === 'radio' ||
            question.type === 'checkbox') &&
            _vm.getAnswer(question.id)
          )?_c('div',{staticClass:"patient-control-question-second pre-wrap"},_vm._l((_vm.getAnswer(question.id).answers),function(ans){return _c('span',{key:ans.id},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(ans.value))])])}),0):_vm._e(),(
            (question.type === 'textarea' ||
            question.type === 'input') &&
            _vm.getAnswer(question.id)
          )?_c('div',{staticClass:"patient-control-question-second pre-wrap"},[(_vm.getAnswer(question.id) && _vm.getAnswer(question.id).answers)?_c('div',[_c('div',{staticClass:"patient-control-question-second pre-wrap"},_vm._l((_vm.getAnswer(question.id).answers),function(ans){return _c('span',{key:ans.id},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(ans.value))])])}),0)]):_c('span',{staticClass:"mr-3 break-line"},[_vm._v(" "+_vm._s(_vm.getAnswer(question.id) ? _vm.getAnswer(question.id).answer : '')+" ")])]):_vm._e()])])}),0):_vm._e(),(_vm.isDoctor())?_c('div',{staticClass:"patient-control-question-footer"},[_c('p',{staticClass:"text-center patient-control-title mt-2"},[_vm._v(_vm._s(_vm.stateNotice))]),_c('div',{staticClass:"patient-control-button-status"},[_c('button',{staticClass:"btn btn-primary btn-w-166 btn-md",class:{
          'action-disabled' : _vm.isDisabledAfterChoose(_vm.USER_STATUS.CHAT_INTERVIEW_SCHEDULED)
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_STATUS.CHAT_INTERVIEW_SCHEDULED)}}},[_vm._v(" チャット診療 ")]),_c('button',{staticClass:"btn btn-primary btn-w-166 btn-md",class:{
          'action-disabled' : _vm.isDisabledAfterChoose(_vm.USER_STATUS.VIDEO_INTERVIEW_SCHEDULED)
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_STATUS.VIDEO_INTERVIEW_SCHEDULED)}}},[_vm._v(" ビデオ問診 ")]),_c('button',{staticClass:"btn btn-info btn-w-166 btn-md",class:{
          'action-disabled' : _vm.isDisabledAfterChoose(_vm.USER_STATUS.CONFIRMING_PRESCRIPTION)
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState},on:{"click":function($event){return _vm.openForm()}}},[_vm._v(" 処方する ")]),_c('button',{staticClass:"btn btn-danger  btn-w-166 btn-md",class:{
          'action-disabled' : _vm.isDisabledAfterChoose(_vm.USER_STATUS.SUBSCRIPTION_SUGGESTIONS)
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_STATUS.SUBSCRIPTION_SUGGESTIONS)}}},[_vm._v(" 処方NG ")])])]):_vm._e(),_c('modal',{staticClass:"feedback-modal",attrs:{"name":"feedbackForm","clickToClose":false}},[_c('ValidationObserver',{ref:"feedbackForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',{staticClass:"modal-header pt-4 pl-4 pb-2"},[_c('strong',[_vm._v("患者情報")])]),_c('div',{staticClass:"pr-4 text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(_vm.feedbackForm.created_at_jp)))])]),_c('div',{staticClass:"content-customer pl-4 pr-4"},[_c('p',{staticClass:"mb-0"},[_vm._v("コメント")]),_c('ValidationProvider',{attrs:{"rules":"required|max:1000","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.feedbackForm.content),expression:"feedbackForm.content"}],staticClass:"form-control",staticStyle:{"resize":"none"},attrs:{"disabled":_vm.isSubmitting,"rows":"5"},domProps:{"value":(_vm.feedbackForm.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.feedbackForm, "content", $event.target.value)}}}),_vm._v(" "),(errors.length > 0)?_c('div',{staticClass:"invalid-feedback invalid-feedback--custom"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('p',{staticClass:"mt-3"},[_vm._v("処方を選択してください")]),_c('div',{staticClass:"p-2 pl-4"},[_vm._l((_vm.listPlan),function(plan,index){return _c('div',{key:index},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.feedbackForm.plan_id),expression:"feedbackForm.plan_id"}],attrs:{"disabled":_vm.isSubmitting,"id":'plan_option_'+plan.id,"type":"radio"},domProps:{"value":plan.id,"checked":_vm._q(_vm.feedbackForm.plan_id,plan.id)},on:{"change":function($event){return _vm.$set(_vm.feedbackForm, "plan_id", plan.id)}}}),_c('label',{staticClass:"ml-1",attrs:{"for":'plan_option_'+plan.id}},[_vm._v(_vm._s(plan.name))])])}),(!_vm.isLoadingPlan)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.feedbackForm.plan_id),expression:"feedbackForm.plan_id"}],attrs:{"disabled":_vm.isSubmitting,"id":'plan_option_ng',"type":"radio"},domProps:{"value":'plan_ng',"checked":_vm._q(_vm.feedbackForm.plan_id,'plan_ng')},on:{"change":function($event){return _vm.$set(_vm.feedbackForm, "plan_id", 'plan_ng')}}}),_c('label',{staticClass:"ml-1 text-danger",attrs:{"for":'plan_option_ng'}},[_vm._v("処方NG")])]):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.feedbackForm.plan_id),expression:"feedbackForm.plan_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.feedbackForm.plan_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.feedbackForm, "plan_id", $event.target.value)}}}),(errors.length > 0)?_c('div',{staticClass:"invalid-feedback invalid-feedback--custom"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),(_vm.isLoadingPlan)?_c('PageLoader',{staticStyle:{"position":"static"}}):_vm._e()],2)],1)]}}])}),_c('div',{staticClass:"p-4 text-right"},[_c('button',{staticClass:"cancel-feedback-btn",attrs:{"disabled":_vm.isSubmitting},on:{"click":function($event){!_vm.isSubmitting ? _vm.$modal.hide('feedbackForm') : null}}},[_vm._v("キャンセル")]),_c('button',{staticClass:"create-feedback-btn",attrs:{"disabled":_vm.isSubmitting},on:{"click":function($event){!_vm.isSubmitting ? _vm.saveFeedbackForm() : null}}},[_vm._v("保存")])])],1),_c('PrescriptionPopup',{attrs:{"current-channel-user":_vm.currentChannelUser,"changeState":_vm.changeState,"changeCurrentChannelUser":_vm.changeCurrentChannelUser},on:{"savedFeedback":function($event){return _vm.savedFeedback()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }