






































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import remove from 'lodash/remove';

import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import Pagination from '@components/common/Pagination.vue';
import LocalStorageUtils from '@common/LocalStorageUtils';
import { Roles } from '@common/app.config';
import { Clinic } from '@/models/clinic';
import { cloneDeep, forEach } from 'lodash';

@Component({
  components: {
    Pagination,
    PageLoader,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
})
export default class BasicModal extends Vue {
  @Prop({ default: {} }) readonly clinicSelected!: any;
  @Prop({ default: '' }) readonly roleSelected!: string;
  @Prop({ default: false }) readonly multiple!: boolean;

  paginate = {
    page: this.$route.query.page || 1,
    per_page: 10,
  };
  totalPage = 1;
  listClinics = [];
  activeClinic: any;
  isLoading: boolean = false;
  currentUser: any;
  query = {
    freeword_on_modal: this.$route.query.freeword_on_modal || '',
  };

  get queryParams() {
    const query = {
      ...this.paginate,
      with: 'order,user,order.plan,order.plan.medicines',
      ...this.query,
    };

    if (this.roleSelected === Roles.doctor) {
      Object.assign(query, { type: 'clinic_doctor' });
    }

    return query;
  }

  get initialQuery() {
    const query = {
      ...this.paginate,
      with: 'clinics,license',
    };

    if (this.roleSelected === Roles.doctor) {
      Object.assign(query, { type: 'clinic_doctor' });
    }

    return query;
  }

  get isEmptyDataClinic(): boolean {
    return this.listClinics && !this.listClinics.length;
  }

  @Watch('roleSelected')
  getRoleSelected() {
    this.activeClinic = new Clinic();
    this.$emit('submit', this.activeClinic);
  }

  mounted() {
    this.remoteParamSearch();
  }

  beforeOpen() {
    this.activeClinic = cloneDeep(this.clinicSelected) || new Clinic();
    this.initDataDefault();
    this.getClinicsList(this.initialQuery);
    this.remoteParamSearch();
  }

  beforeClose() {
    this.paginate = {
      page: 1,
      per_page: 10,
    };
    this.totalPage = 1;
    this.listClinics = [];
  }

  remoteParamSearch() {
    if (LocalStorageUtils.getItem('doctorDetailSearchClinic')) {
      LocalStorageUtils.removeItem('doctorDetailSearchClinic');
    }
  }

  initDataDefault() {
    // Clear data when close popup
    this.listClinics = [];
    this.paginate = {
      page: 1,
      per_page: 10,
    };
    this.totalPage = 1;
    this.query = {
      freeword_on_modal: '',
    };
  }

  cancelSetClinic() {
    this.activeClinic = new Clinic();
    this.initDataDefault();
    this.$emit('cancel');
  }

  getClinicsList(query) {
    if (this.isLoading) {
      return;
    }
    this.listClinics = [];
    this.isLoading = true;

    ApiHelper.getApi('ClinicApi')
      .list(query)
      .then((res: any) => {
        if (this.currentUser.isAdminRole) {
          const curUserClinic = this.currentUser.clinics[0];
          const newRes = res.data.filter(
            (item) => item.id === curUserClinic.id,
          );
          this.listClinics = newRes.map((item) =>
            new Clinic().deserialize(item),
          );
        } else {
          this.listClinics = res.data.map((item) =>
            new Clinic().deserialize(item),
          );
        }
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  pageChanged(page) {
    let paramsSearch: any;
    this.paginate.page = page;

    if (LocalStorageUtils.getItem('doctorDetailSearchClinic')) {
      paramsSearch = JSON.parse(
        LocalStorageUtils.getItem('doctorDetailSearchClinic'),
      );
    } else {
      paramsSearch = Object.assign({}, this.initialQuery);
    }

    paramsSearch = {
      ...paramsSearch,
      ...this.paginate,
      page,
    };

    this.getClinicsList(paramsSearch);
  }

  handleSearch() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.paginate.page = 1;
    LocalStorageUtils.saveItem(
      'doctorDetailSearchClinic',
      JSON.stringify(this.queryParams),
    );

    ApiHelper.getApi('ClinicApi')
      .list(this.queryParams)
      .then((res: any) => {
        this.listClinics = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  closePopup() {
    this.$emit('closeModal');
  }

  setActiveClinic(clinic: Clinic) {
    const elClinicSelected = this.$refs[`clinic-${clinic.id}`][0];
    const elClinic: any = document.getElementsByClassName('table-hover');

    if (this.multiple) {
      elClinicSelected.classList.toggle('active-tr');
      if (this.checkActiveClinic(clinic)) {
        remove(this.activeClinic, (item: any) => item.id === clinic.id);
        return;
      }
      this.activeClinic.push(clinic);
      return;
    }

    elClinic.forEach((el: any) => el.classList.remove('active-tr'));
    elClinicSelected.classList.add('active-tr');
    this.activeClinic = clinic;
  }

  checkActiveClinic(item) {
    if (this.activeClinic && this.activeClinic.length) {
      return this.activeClinic.some((act: any) => act.id === item.id);
    }

    return this.activeClinic.id === item.id;
  }

  handleSubmit() {
    this.$emit('submit', this.activeClinic);
    this.$emit('cancel');
  }
}
