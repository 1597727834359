import BaseApi from './BaseApi';

class UserApi extends BaseApi {
  public login(params: any) {
    return this.post('login', params);
  }

  public logout(params = {}) {
    return this.post('logout', params);
  }

  public resetPassword(params: any) {
    return this.post('send-email-reset', params);
  }

  public setPassword(params: any) {
    return this.post('reset-password', params);
  }

  public getInfo(params?) {
    return this.get('info', params);
  }

  public updateInfo(params: any) {
    return this.post('info', params);
  }

  public uploadAvatar(params: any) {
    return this.post('upload-avatar', params);
  }

  public updatePassword(params: any) {
    return this.post('update-password', params);
  }

  public acceptVerify(params: any) {
    return this.post('apply-user', params);
  }

  public rejectVerify(params: any) {
    return this.post('reject-user', params);
  }

  public createResvDoctor(params: any) {
    return this.post('reservation-user', params);
  }

  public getResvDoctor(userId: any) {
    return this.get(`reservation-user?user_id=${userId}&with=doctor&order=-start_date`);
  }

  public updateResvDoctor(resvId: number, params: any) {
    return this.put('reservation-user/' + resvId, params);
  }

  public deleteResvDoctor(resvId: number, params: any) {
    return this.del('reservation-user/' + resvId, params);
  }

  public changeStatus(userId: number, params: any) {
    return this.put(`choose-method-inquiry/${userId}`, params);
  }

  public deliveryExport(params: any) {
    return this.get(`delivery-export`, params);
  }

  public confirmDelivery(params: any) {
    return this.post(`delivery/update-state-delivered`, params);
  }
  public updateDeliveryStatus(id: number, params: any) {
    return this.post(`delivery/${id}/update-state`, params);
  }

  public reminderChat(id: number, params: any) {
    return this.post(`chat-reminder/${id}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/';
  }
}

export default UserApi;
