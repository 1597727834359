import Vue from 'vue';
import moment from 'moment';
import { splitLongText } from '../common/helpers/split-long-text';

Vue.filter('format_time', (value: any, format = 'MM/DD/YYYY', defaultValue = '') => {
  if (!value) {
    return defaultValue;
  }
  return moment(value).format(format);
});

Vue.filter('format_price', (value) => {
  let formatedString = '0';
  if (value) {
    formatedString = value
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return `${formatedString}円`;
});

Vue.filter('phone_format', (phone: string) => {
  if (!phone) {
    return '';
  }
  return phone;
});

Vue.filter('format_time_updated', (value: any, duration = 7, type: 'hours', format = 'MM/DD/YYYY') => {
  if (!value) {
    return '';
  }
  return moment(value).add(duration, type).format(format);
});

Vue.filter('splitText', (value: any, length = 30) => {
  return splitLongText(value, length);
});

Vue.filter('format_price', (value) => {
  let formatString = '0';
  if (value) {
    formatString =  value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  return `${formatString}円`;
});
