

















































































































































































import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import BasicSelect from '@components/common/BasicSelect.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
@Component({
  components: {
    BasicSelect,
  },
})
export default class PopupClinicPatternDetail extends Vue {
  @Prop(Array) readonly listPrefectureData!: any[];
  private isCreate: boolean = true;
  private isUpdate: boolean = false;
  private name: string = 'popupClinicPatternDetail';
  private isLoading: boolean = false;

  private item: any = {
    affiliated_clinic_name: '',
    affiliated_clinic_name_kana: '',
    prefecture: {
      name: '',
      id: null,
    },
    postal_number: '',
    address: '',
    remarks: '',
    status: true,
  };

  private get buttonText() {
    if (!this.isUpdate) {
      return '作成する';
    } else {
      return '更新する';
    }
  }

  private get headingText() {
    if (!this.isUpdate) {
      return '提携クリニック新規登録';
    } else {
      return '提携クリニック編集';
    }
  }

  private async createMenu() {
    if (this.isLoading) {
      return;
    }
    const params = {
      ...this.item,
      status: +this.item.status,
      prefecture_id: this.item.prefecture.id,
    };

    this.isLoading = true;
    ApiHelper.getApi('ClinicPatternApi')
      .create(params)
      .then(() => {
        Toast.success('登録しました');
        this.resetData();
        this.$modal.hide(this.name);
        this.isLoading = false;
        this.$emit('reload');
      })
      .catch((error) => {
        Toast.error(error.message);
        this.isLoading = false;
      });
  }

  private resetData() {
    this.item = {
      affiliated_clinic_name: '',
      affiliated_clinic_name_kana: '',
      prefecture: {},
      postal_number: '',
      address: '',
      remarks: '',
      status: true,
    };
  }

  private async updateMenu() {
    const params = {
      ...this.item,
      status: +this.item.status,
      prefecture_id: this.item.prefecture.id,
    };
    try {
      await ApiHelper.getApi('ClinicPatternApi').update(this.item.id, params);
      this.$modal.hide(this.name);
      Toast.success('更新しました');
      this.$emit('reload');
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        Toast.error(data.message);
        return;
      }

      Toast.error(error.message);
    }
  }

  private handeEdit() {
    if (!this.isUpdate) {
      (this.$refs.form as any).validate().then((success) => {
        if (!success) {
          return;
        }
        this.createMenu();
      });
      return;
    } else {
      this.updateMenu();
    }
  }

  private beforeClose() {
    this.resetData();
  }

  private closeModal() {
    this.$modal.hide(this.name);
    this.resetData();
  }

  private beforeOpen(event) {
    this.isCreate = event.params.isCreate;
    if (event.params.dataUpdate.id) {
      const prefecture = this.listPrefectureData.find(
          (item) => item.id === Number(event.params.dataUpdate.prefecture_id));
      this.item = {
        id: event.params.dataUpdate.id,
        affiliated_clinic_name: event.params.dataUpdate.name,
        affiliated_clinic_name_kana: event.params.dataUpdate.name_kana,
        prefecture: prefecture || {},
        postal_number: event.params.dataUpdate.postal_number,
        address: event.params.dataUpdate.address,
        remarks: event.params.dataUpdate.remarks,
        status: !!event.params.dataUpdate.status,
      };
      this.isUpdate = true;
    } else {
      this.resetData();
      this.isUpdate = false;
    }
  }

  private mounted() {
    dom.watch();
  }
}
