var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delivery-detail"},[_vm._m(0),_c('div',{staticClass:"delivery-detail-container"},[_c('ValidationObserver',{ref:"versionFrom",attrs:{"tag":"div"}},[_c('table',{staticClass:"table delivery-detail-table"},[_c('tr',[_c('th',[_vm._v("ID")]),_c('td',[_vm._v(_vm._s(_vm.deliveryDetail.id))])]),_c('tr',[_c('th',[_vm._v("配送先")]),_c('td',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"delivery-detail-item"},[_c('div',{staticClass:"d-flex"},[_c('p',[_vm._v("名前")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.family_name || '-')+" "+_vm._s(_vm.deliveryUser.given_name))])]),_c('div',{staticClass:"d-flex"},[_c('p',[_vm._v("フリガナ")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.family_name_kana || '-')+" "+_vm._s(_vm.deliveryUser.given_name_kana))])]),_c('div',{staticClass:"d-flex"},[_c('p',[_vm._v("電話番号")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.phone))])]),_c('div',{staticClass:"d-flex"},[_c('p',[_vm._v("メールアドレス")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.email))])])]),_c('div',{staticClass:"delivery-detail-item"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("郵便番号")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.postal_number))])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("都道府県")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.prefecture_id))])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("市区町村")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.city_id))])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',[_vm._v("建物名・部屋番号")]),_c('p',[_vm._v(_vm._s(_vm.deliveryUser.building_name))])])])])])]),_c('tr',[_c('th',[_vm._v("配送ステータス")]),_c('td',[_c('ValidationProvider',{attrs:{"name":"state","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BasicSelect',{class:{ 'is-invalid': !!errors.length },attrs:{"label":"name","reduce":function (option) { return option.value; },"options":_vm.deliveryStateOptions,"clearable":false,"searchable":false,"readOnly":!_vm.isEditDeliveryState,"isIconUpDown":"","iconUpDownSimple":"","isFormStyle":""},model:{value:(_vm.deliveryDetail.state),callback:function ($$v) {_vm.$set(_vm.deliveryDetail, "state", $$v)},expression:"deliveryDetail.state"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',[_vm._v("商品内容")]),_c('td',[_vm._v(_vm._s(_vm.deliveryDetail.order && _vm.deliveryDetail.order.plan && _vm.deliveryDetail.order.plan.name))])]),_c('tr',[_c('th',[_vm._v("配送予定日")]),_c('td',[_c('BaseDatePicker',{attrs:{"isFormStyle":"","format":_vm.formatDateJapan,"placeholder":"選択してください","showIconClear":"","disabledBeforeToday":"","disabled":!_vm.isEditableRole(true)},model:{value:(_vm.deliveryDetail.delivery_date),callback:function ($$v) {_vm.$set(_vm.deliveryDetail, "delivery_date", $$v)},expression:"deliveryDetail.delivery_date"}})],1)]),_c('tr',[_c('th',[_vm._v("配送業者")]),_c('td',[_vm._v(_vm._s(_vm.deliveryDetail.delivery_company))])]),_c('tr',[_c('th',[_vm._v("問い合わせ番号")]),_c('td',[_c('ValidationProvider',{attrs:{"rules":{required: false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deliveryDetail.delivery_number),expression:"deliveryDetail.delivery_number"}],class:{'delivery-detail-input': true, 'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"12345-67890","maxlength":"30","disabled":!_vm.isEditableRole(true)},domProps:{"value":(_vm.deliveryDetail.delivery_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.deliveryDetail, "delivery_number", $event.target.value)}}}),_c('div',{staticClass:"notice-err"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('tr',[_c('th',[_vm._v("発送日")]),_c('td',{staticClass:"delivery-detail-calendar position-relative"},[_c('BaseDatePicker',{attrs:{"isFormStyle":"","format":_vm.formatDateJapan,"placeholder":"選択してください","showIconClear":"","disabledDates":"","renderDisabledDates":_vm.notAfterEndDay,"disabled":!_vm.isEditableRole(true)},model:{value:(_vm.deliveryDetail.send_at),callback:function ($$v) {_vm.$set(_vm.deliveryDetail, "send_at", $$v)},expression:"deliveryDetail.send_at"}})],1)]),_c('tr',[_c('th',[_vm._v("配達完了日")]),_c('td',{staticClass:"delivery-detail-calendar position-relative"},[_c('BaseDatePicker',{attrs:{"isFormStyle":"","format":_vm.formatDateJapan,"placeholder":"選択してください","showIconClear":"","disabledDates":"","renderDisabledDates":_vm.notAfterEndDay,"disabled":!_vm.isEditableRole || _vm.isNotDelivered},model:{value:(_vm.deliveryDetail.complete_at),callback:function ($$v) {_vm.$set(_vm.deliveryDetail, "complete_at", $$v)},expression:"deliveryDetail.complete_at"}})],1)])])])],1),_c('div',{staticClass:"text-center mb-3 delivery-detail-btn"},[_c('router-link',{staticClass:"btn btn-secondary delivery-detail-back",attrs:{"to":{name: 'deliveries', query: {
        state: this.$route.query.state || '',
        user_name: this.$route.query.user_name || '',
        page: this.$route.query.page || '1',
        id: this.$route.query.id || '',
        delivery_date_from: this.$route.query.delivery_date_from || '',
        delivery_date_to: this.$route.query.delivery_date_to || ''
      }}}},[_vm._v(" 戻る ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPermission),expression:"isPermission"}],class:{'btn btn-primary ml-3 delivery-detail-confirm': true },attrs:{"disabled":_vm.disableUpdateDelivery()},on:{"click":function($event){return _vm.updateDelivery()}}},[_vm._v("保存")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-0 header-course text-center pt-3 pb-3"},[_c('img',{staticClass:"invert",attrs:{"src":require("@/assets/img/patient-truck.svg"),"alt":""}}),_vm._v(" 配送詳細 ")])])}]

export { render, staticRenderFns }