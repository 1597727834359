



































import { Vue, Component, Prop } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import { splitLongText } from '@common/helpers/split-long-text';
import { Roles } from '@/common/app.config';
import { State } from 'vuex-class';

@Component
export default class PharmacistItem extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop() item!: any;

  private get kanaName() {
    return splitLongText(this.item.name_kana, 30);
  }

  private get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }

  private get pharmacies() {
    return (this.item.stores || {}).map((x) => x.name).join(',');
  }
}
