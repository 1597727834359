














































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';
import { AuthenticationModule } from '@/store';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faStethoscope } from '@fortawesome/free-solid-svg-icons';
// import SearchDoctor from './SearchDoctor.vue';
import { Roles } from '@common/app.config';
// import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { State } from 'vuex-class';
import PageLoader from '@components/common/PageLoader.vue';

@Component({
  components: {
    Pagination,
    // SearchDoctor,
    // PopupConfirm,
    PageLoader,
  },
})
export default class ListDoctor extends Vue {
  // private searchForm = {
  //   with: 'clinics,license',
  //   per_page: this.perPage,
  //   page: 1,
  // };

  get initialQuery() {
    if (this.$route.query.id) {
      this.isSearched = true;
    }
    return {
      ...this.paginate,
      with: 'clinics,license',
      id: this.$route.query.id || '',
      name: this.$route.query.name || '',
      clinic_name: this.$route.query.clinic_name || '',
    };
  }
  get queryParams() {
    return {
      ...this.paginate,
      with: 'clinics,license',
      ...this.query,
    };
  }

  get isEmptyDataDoctor(): boolean {
    return this.listDoctors && !this.listDoctors.length;
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  paginate = {
    page: this.$route.query.page || 1,
    per_page: 10,
  };
  totalPage = 1;
  private perPage = 10;
  private pageCount = 1;
  private listDoctors = [];
  private doctorId: any;
  private isLoading: boolean = false;
  private isSearched: boolean = false;
  private query = {
    id: this.$route.query.id || '',
    name: this.$route.query.name || '',
    clinic_name: this.$route.query.clinic_name || '',
  };

  getDoctorsList(changePage) {
    if (this.isLoading) {
      return;
    }
    this.listDoctors = [];
    this.isLoading = true;

    ApiHelper.getApi('DoctorApi')
      .list(changePage ? this.queryParams : this.initialQuery)
      .then((res: any) => {
        this.listDoctors = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private async created() {
    library.add(faStethoscope);
    dom.watch();
  }

  private mounted() {
    this.getDoctorsList(false);
  }

  // private searchDoctor(formData: any) {
  //   this.searchForm = {...this.searchForm, ...formData, page: 1};

  //   this.getDoctorsList(this.searchForm);
  // }

  private pageChanged(page) {
    this.paginate = {
      ...this.paginate,
      ...this.queryParams,
      page,
    };
    this.getDoctorsList(true);
  }

  private handleSearch() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.isSearched = true;
    this.paginate.page = 1;

    ApiHelper.getApi('DoctorApi')
      .list(this.queryParams)
      .then((res: any) => {
        this.listDoctors = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        this.toast(error);
      })
      .finally(() => {
        const refData = this.$refs.table as any;
        refData && refData.onFocus;
        this.isLoading = false;
      });
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  // private async list(data) {
  //   try {
  //     this.isLoading = true;
  //     this.listDoctors = [];
  //     this.pageCount = 1;
  //     const response = await ApiHelper.getApi('DoctorApi').list(data);
  //     this.pageCount = response.last_page;
  //     this.listDoctors = response.data;
  //     this.isLoading = false;
  //   } catch (error) {
  //     this.isLoading = false;
  //     const errors = error.response.data;

  //     Toast.error(errors.message);
  //   }
  // }

  // private hiddenPopup() {
  //   this.$modal.hide('delDoctor');
  // }

  // private show(id) {
  //   this.doctorId = id;
  //   this.$modal.show('delDoctor');
  // }

  // private async deleteDoctor() {
  //   if (!this.doctorId) {
  //     return ;
  //   }

  //   try {
  //     if ( this.currentUser.role === Roles.clinic) {
  //       await ApiHelper.getApi('DoctorApi').removeLink(this.doctorId, {clinic_id: this.currentUser.clinics[0].id});
  //     } else {
  //       await ApiHelper.getApi('DoctorApi').delete(this.doctorId);
  //     }

  //     Toast.success('削除しました');

  //     this.searchForm = {...this.searchForm, page: 1};
  //     this.list(this.searchForm);
  //     this.$modal.hide('delDoctor');
  //   } catch (error) {
  //     const data = error.response.data;
  //     if (error.response.status === 422) {
  //       return;
  //     }

  //     Toast.error(data.message);
  //   }
  // }

  private gotoDetail(id) {
    this.$router.push({
      path: `/doctors/${id}`,
      query: {
        id: this.queryParams.id || '',
        // delivery_date: this.queryParams.delivery_date || '',
        name: this.queryParams.name || '',
        clinic_name: this.queryParams.clinic_name || '',
        page: this.paginate.page ? this.paginate.page.toString() : '1',
      },
    });
  }
}
