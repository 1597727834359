







































































































































































import {Vue, Component, Watch} from 'vue-property-decorator';
import { State } from 'vuex-class';
import { ToggleButton } from 'vue-js-toggle-button';
import ApiHelper from 'api-helper';
import { Roles, VerifyPatient } from '@/common/app.config';
import { Patient } from '@/models/patient';
import Toast from '@/common/helpers/toast';
import BasicSelect from '@/components/common/BasicSelect.vue';
import LocalStorageUtils from '@/common/LocalStorageUtils';
import Pagination from '@/components/common/Pagination.vue';
import PageLoader from '@/components/common/PageLoader.vue';
import PatientImport from '@/components/Patient/PatientImport.vue';
import lodash from 'lodash';
import moment from 'moment';

@Component({
  components: {
    Pagination,
    PageLoader,
    PatientImport,
    ToggleButton,
    BasicSelect,
  },
  data() {
    return {
      listPatients: [],
    };
  },
  filters: {
    timeResv: (listResv ) => {
      const resv = lodash.get(listResv, '[0]', null);
      if (resv) {
        if ((resv as any).is_past) {
          return '完了';
        }
        return moment((resv as any).start_date).add(9, 'hours').format('YYYY/MM/DD HH:mm');
      }
      return 'なし';
    },
  },
})

export default class ListPatient extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  private page = 1;
  private perPage = 10;
  private pageCount = 1;
  private listPatients!: any;
  private isLoading: boolean = false;
  private statusDelete: boolean = false;
  private activeTab = 'patient';
  private pageOptions = [10, 50, 100];

  private searchForm =  {
    user_id: '',
    user_name: '',
    per_page: this.perPage,
    page: this.page,
    name: '',
    clinic_name: '',
    is_verified: null,
    with: 'reservationUser',
  };

  private get isClinic() {
    return this.currentUser.role === Roles.clinic;
  }

  private get searchVerify() {
    return VerifyPatient;
  }

  private get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  private get isUploadAble() {
    return this.currentUser.role === Roles.superAdmin || this.currentUser.role === Roles.clinic;
  }

  @Watch('statusDelete')
  private selectStatus() {
    this.page = 1;
    this.searchForm.page = 1;
    this.list(this.searchForm);
  }

  private onChangePerPage(perPage: number) {
    this.perPage = perPage;
    this.page = 1;
    this.searchForm.per_page = perPage;
    this.searchForm.page = 1;
    this.listPatients = [];
    if (this.activeTab === 'patient') {
      this.list(this.searchForm);
    } else {
      this.getImportPatient();
    }
  }

  private isActivePerPage(perPage: number) {
    return this.perPage === perPage;
  }

  private async changeTab(tab) {
    if (this.activeTab === tab) { return; }
    this.page = 1;
    this.searchForm =  {
      user_id: '',
      user_name: '',
      per_page: this.perPage,
      page: this.page,
      name: '',
      clinic_name: '',
      is_verified: null,
      with: 'reservationUser',
    };
    this.activeTab = tab;
    this.$router.replace({
      query: {
        tab,
      },
    });

    if (tab === 'patient') {
      this.list(this.searchForm);
    } else {
      await this.getImportPatient();
    }
  }

  private onImported() {
    this.activeTab = 'import';
    this.getImportPatient();
  }

  private isActive(tabName) {
    return this.activeTab === tabName;
  }

  private async created() {
    if (LocalStorageUtils.getItem('paramsSearch')) {
      LocalStorageUtils.removeItem('paramsSearch');
    }
    if (this.$route.query.tab && this.$route.query.tab === 'import') {
      this.activeTab = 'import';
      this.getImportPatient();
      return;
    }
    this.activeTab = 'patient';
    this.list(this.searchForm);
  }

  private async changeImportUserStatus(user) {
    try {
      await ApiHelper.getApi('ImportUserApi').changeStatus({
        healthcare_code: user.healthcare_code,
      });
    } catch (error) {
      Toast.error(error.response.data.message);
      user.status = !user.status;
    }
  }

  private async changePatientStatus(patient) {
    try {
      await ApiHelper.getApi('ClinicUserApi').changeStatus({
        user_id: patient.id,
      });
    } catch (error) {
      Toast.error(error.response.data.message);
      patient.status = !patient.status;
    }
  }

  private async getImportPatient() {
    this.listPatients = [];
    this.isLoading = true;
    const res = await ApiHelper.getApi('ClinicApi').getImportPatients({
      ...this.searchForm,
      with: 'clinic',
    });
    if (!this.isClinic) {
      this.listPatients = res.data;
    } else {
      this.listPatients = res.data.map((item) => {
        return {
          ...item,
          status: item.status === 1 ? true : false,
        };
      });
    }
    this.isLoading = false;
    this.pageCount = res.last_page;
  }


  private searchPatients() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));
    this.page = 1;
    this.searchForm.page = 1;
    if (this.isActive('patient')) {
      this.list(this.searchForm);
    } else {
      this.getImportPatient();
    }
  }

  private pageChanged(page) {
    this.page = page;
    this.searchForm.page = page;
    if (this.activeTab === 'patient') {
      this.list(this.searchForm);
    } else {
      this.getImportPatient();
    }
  }

  private onUploadCSV() {
    this.$modal.show('upload-patient');
  }

  private async list(data) {
    try {
      this.listPatients = {};
      this.isLoading = true;
      const response = await ApiHelper.getApi('PatientApi').list({...data, deleted_at: +this.statusDelete});

      this.pageCount = response.last_page;
      if (this.isClinic) {
        this.listPatients = response.data.map(((patient) => {
          return {
            ...patient,
            status: patient.clinics[0].clinic_user_status === 1 ? true : false,
          };
        }));
      } else {
        this.listPatients = response.data.map((item) => new Patient().deserialize(item));
      }
      this.isLoading = false;
    } catch (error) {
      const errors = error.response.data;
      this.isLoading = false;
      Toast.error(errors.message);
    }
  }
}
