






















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PLAN_TYPE } from '@/common/constants/plan';
import BasicSelect from '@/components/common/BasicSelect.vue';
import CurrencyInput from '@/components/common/CurrencyInput.vue';

@Component({
  components: {
    BasicSelect,
    CurrencyInput,
  },
})
export default class PlanSearch extends Vue {
  planTypes = PLAN_TYPE;
  isAmountError: boolean = false;
  searchParams: object = {
    id: null,
    name: null,
    amount_gte: null,
    amount_lte: null,
    type: null,
  };

  onSearch(checkValidate: boolean) {
    if (checkValidate) {
      return;
    }

    for (const key in this.searchParams) {
      if (this.searchParams[key] === '' || this.searchParams[key] === [] || this.searchParams[key] === 0) {
        this.searchParams[key] = null;
      }
    }

    this.$emit('search', {
      ...this.searchParams,
    });
  }

  changeSearchInput(event: any, key: string) {
    let strTrimComma = String(event.target.value).replace(/\,/g, '');

    if (/^(0)/.test(strTrimComma)) {
      strTrimComma = '';
    }

    this.searchParams[key] = event.target.value;
  }

  changeFee(event: string | number, type: string) {
    const priceChange = String(event).replace(/^0+/g, '');
    this.$set(this.searchParams, type, +priceChange);
  }

  @Watch('searchParams.amount_gte')
  private watchAmountGTE(val) {
    if (this.searchParams['amount_lte']) {
      this.isAmountError = this.searchParams['amount_lte'] < val;
    }
    if (!val) {
      this.isAmountError = false;
    }
  }

  @Watch('searchParams.amount_lte')
  private watchAmountLTE(val) {
    if (this.searchParams['amount_gte']) {
      this.isAmountError = this.searchParams['amount_gte'] > val;
    }
    if (!val) {
      this.isAmountError = false;
    }
  }
}
