





















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
// Components
import DatePicker from 'vue2-datepicker';
// Constants
import { formatDate, formatDate2 } from '@common/app.config';
// Icons
import iconCalendar from '@/assets/img/icon-calendar.svg';

@Component({
  components: {
    DatePicker,
  },
})
export default class BaseDatePicker extends Vue {
  @Prop(String) readonly value!: string;
  @Prop(String) readonly date!: string;
  @Prop(String) readonly errorText!: string;
  @Prop({ default: formatDate2 }) readonly format!: string;
  @Prop({ default: '入力してください' }) readonly placeholder!: string;
  @Prop({ default: 'date' }) readonly type!: string;
  @Prop({ default: formatDate }) readonly valueType!: string;
  @Prop(String) readonly labelText!: string;
  @Prop(Number) readonly minuteStep!: number;
  @Prop(Boolean) readonly showSecond!: boolean;
  @Prop(Boolean) readonly editable!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly disabledDates!: boolean;
  @Prop(Boolean) readonly disabledEqualOrBeforeToday!: boolean;
  @Prop(Boolean) readonly disabledBeforeToday!: boolean;
  @Prop(Boolean) readonly disabledBeforeTimeToday!: boolean;
  @Prop(Array) readonly hourOptions!: any;
  @Prop(Array) readonly minuteOptions!: any;
  @Prop({default: true}) readonly clearable!: boolean;
  @Prop({default: false, type: Boolean}) readonly showIconClear!: boolean;
  @Prop({default: false, type: Boolean}) readonly isFormStyle!: boolean;
  @Prop(Function) readonly renderDisabledDates!: (date) => void;

  iconCalendar: any = iconCalendar;

  get refDatePicker() {
    const ref = this.$refs.datePicker as any;
    return ref;
  }

  get checkBeforeTimeToday() {
    if (!this.date) {
      return true;
    }
    const today = new Date();
    const timestamp = Date.parse(this.date);
    const dateObject = new Date(timestamp);
    return dateObject <= today;
  }

  get disabledInput() {
    if (this.disabled) {
      return this.disabled;
    }
    if (this.disabledBeforeTimeToday) {
      return this.checkBeforeTimeToday;
    }
  }

  disabledDatePicker(date) {
    if (this.disabledDates) {
      return this.renderDisabledDates(date);
    }
    if (this.disabledEqualOrBeforeToday) {
      return this.checkEqualOrBeforeToday(date);
    }
    if (this.disabledBeforeToday) {
      return this.checkBeforeToday(date);
    }
  }

  checkEqualOrBeforeToday(date) {
    const today = new Date();
    return date <= today;
  }

  checkBeforeToday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
  }

  closePopupTimePicker() {
    this.refDatePicker?.closePopup();
  }

  @Watch('disabledInput')
  private watchDisabledInput() {
    this.$nextTick(() => {
      this.closePopupTimePicker();
    });
  }
}
