




import { Vue, Component } from 'vue-property-decorator';
import Update from '@components/Pharmacist/PharmacistUpdate.vue';
import UpdateNewUI from '@components/Pharmacist/PharmacistUpdateNewUI.vue';
import { State } from 'vuex-class';

@Component({
  components: {
    Update,
    UpdateNewUI,
  },
})
export default class UpdatePharmacist extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
}
