

































import {Component, Mixins, Prop} from 'vue-property-decorator';
import MessageMixin from './Message';

@Component
export default class MessageText extends Mixins(MessageMixin) {
  messageContent: string = '';

  mounted() {
    try {
      const messageParse = JSON.parse(this.message.message_content);
      this.messageContent = messageParse.doctor || this.message.message_content;
    } catch (e) {
      this.messageContent = this.message.message_content;
    }
  }
}
