









































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import Pagination from '@components/common/Pagination.vue';
import PageLoader from '@components/common/PageLoader.vue';
import { INotification } from '../../models/notification';
import NotificationItem from './NotificationItem.vue';
import moment from 'moment';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    Pagination,
    PageLoader,
    NotificationItem,
  },
})
export default class NotificationList extends Vue {
  @Prop({required: true, type: Object}) query!: object;

  private pageCount: number = 1;
  private page: number = 1;
  private notifications: INotification[] = [];
  private isLoading: boolean = false;

  @Watch('query', { deep: true})
  onChangeQuery() {
    this.notifications = [];
    this.page = 1;
    this.getNotifies();
  }

  private created() {
    this.isLoading = true;
  }

  private async getNotifies() {
    try {
      this.isLoading = true;
      const { data, last_page } = await ApiHelper.getApi('NotificationApi').getList({
        ...this.query,
        per_page: 10,
        page: this.page,
      });
      this.notifications = data;
      this.pageCount = last_page;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  private onPageChange(page) {
    this.page = page;
    this.notifications = [];
    this.getNotifies();
  }
}
