









































import { Vue, Component, Prop } from 'vue-property-decorator';
import Avatar from '@components/v2/Avatar.vue';
import { State } from 'vuex-class';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import { getUserStateName, getNewUserStateName } from '@/common/constants/auth';
import lodashGet from 'lodash/get';

@Component({
  components: {
    ImagePrivate,
    Avatar,
  },
})
export default class PatientItem extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop({ required: true }) patient!: any;

  get lastMessage() {
    return lodashGet(this.patient, 'channels.latest_message.message', '');
  }

  get getUnreadCount() {
    const unreadCount = lodashGet(this.patient, 'channels.message_recipients[0].unread_count', 0);

    if (unreadCount > 99) {
       return '99+';
    }

    return unreadCount;
  }

  get isShowUserTreatmentIcon() {
    const userTreatmentState = lodashGet(this.patient, 'userTreatments[0].state', null);

    const allowableState = ['choice_interview_method', 'chat_interview_scheduled', 'video_interview_date', 'doctor_prescription_check'];
    return !!(userTreatmentState && allowableState.includes(userTreatmentState) && this.currentUser?.isDoctorRole);
  }

  get patientName() {
      return `${this.patient.family_name || ''} ${this.patient.given_name || ''}`.trim() || '-';
  }

  get getStatusName() {
    return getNewUserStateName(this.patient.new_state);
  }

  get getUserId() {
    return this.patient.id;
  }
}
