































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import imageDefault from '@/assets/img/icon-no-image.svg';

@Component
export default class ZoomImage extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(String) readonly title!: string;
  @Prop({default: '450px'}) readonly widthImage!: string;
  @Prop({default: '500px'}) readonly heightImage!: string;
  @Prop({default: 'auto'}) readonly height!: string | number;
  @Prop({ default: 400 }) readonly width!: string | number;
  private urlImage = imageDefault;

  private beforeOpen(event) {
    if (event.params) {
      this.urlImage = event.params.url;
    } else {
      this.urlImage = imageDefault;
    }
  }

  private beforeClose() {
    this.urlImage = imageDefault;
  }
}

