




















































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import Toast from '@common/helpers/toast';
import { Delivery } from '@/models/deliveries';
import { DELIVERY_STATUS, DELIVERY_STATUS_NAME } from '@/common/constants/auth';
import { Roles, DeliveryState, formatDateJp } from '@common/app.config';
import jaLangDatePicker from 'vuejs-datepicker/dist/locale/translations/ja';
// Components
import BasicSelect from '@/components/common/BasicSelect.vue';
import BaseDatePicker from '@/components/common/BaseDatePicker.vue';
import { User } from '@/models/user';

@Component({
  components: {
    BasicSelect,
    BaseDatePicker,
  },
  filters: {
  },
})
export default class DetailDelivery extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: User;
  get queryParams() {
    return {
      with: 'order,user,order.plan,order.plan.medicines',
    };
  }
  private shipDate = moment().format('YYYY-MM-DD');
  private shipDoneDate = moment().format('YYYY-MM-DD');
  private query = {
    resv_start_at_gte: '',
    resv_end_at_lte: '',
    upload_date: '',
    freeword: null,
  };
  private isLoading: boolean = false;
  private isSubmiting = false;
  private deliveryDetail: any = {};
  private deliveryUser = {};
  private currentQuery: any = {};
  private isStop: boolean = false;
  private isCancel: boolean = false;
  private isChanged: boolean = false;
  private jaLangDatePicker: any = jaLangDatePicker;
  private formatDateJapan = formatDateJp;

  get isPermission() {
    return this.currentUser.isCSRole
    || this.currentUser.isPharmacistRole
    || this.currentUser.isAdminRole
    || this.currentUser.isDeliveryRole;
  }

  get isDeliveryRole() {
    return this.isPermission;
  }

  get isEditDeliveryState() {
    if (this.isDeliveryRole) {
      return this.deliveryStateOptionsDeliveryRole.some((x) => x.value === this.deliveryDetail.state);
    }
    return this.isEditableRole;
  }

  // get isView() {
  //   return this.currentUser.role === Roles.superAdmin || this.currentUser.role === Roles.doctor;
  // }

  get isNotDelivered() {
    return this.deliveryDetail.state !== DELIVERY_STATUS.DELIVERED;
  }

  get deliveryStateOptionsDeliveryRole() {
    const showState = [
      DELIVERY_STATUS.READY_FOR_DELIVERY,
      DELIVERY_STATUS.PREPARING_FOR_DELIVERY,
      DELIVERY_STATUS.DELIVERED,
    ];
    return DeliveryState.filter((x: any, i) => showState.includes(x.value));
  }

  get deliveryStateOptions() {
    if (this.isDeliveryRole && this.isEditDeliveryState) {
      return this.deliveryStateOptionsDeliveryRole;
    }
    return DeliveryState.filter((x, i) => i !== 0);
  }

  mounted() {
    window.scrollTo(0, 0);
    this.getDeliveryDetail();
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  updateIsChanged(status: boolean) {
    this.isChanged = status;
  }

  isEditableRole(withDelivery: false) {
    if (withDelivery) {
      return this.currentUser.isCSRole || this.currentUser.isAdminRole || this.currentUser.isDeliveryRole;
    }

    return this.currentUser.isCSRole || this.currentUser.isAdminRole;
  }
  getDeliveryDetail() {
    if (this.isLoading) {
      return;
    }

    this.deliveryDetail = [];

    this.isLoading = true;

    ApiHelper.getApi('DeliveryApi')
      .getDeliveryDetail(this.$route.params.id, this.queryParams)
      .then((res: any) => {
        this.deliveryDetail = res;
        this.isStop = res.state === 'cancel';
        this.isCancel = ['paid', 'preparing_for_delivery', 'delivered', 'delivery_complete', 'ready_for_delivery'].includes(res.state);
        this.deliveryUser = res.user;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  get isMatchDate() {
    if (!this.deliveryDetail.send_at || !this.deliveryDetail.complete_at) {
      return true;
    }
    if (moment(this.deliveryDetail.send_at).diff(moment(this.deliveryDetail.delivery_date), 'days') < 0) {
      return true;
    }
    if (moment(this.deliveryDetail.complete_at).diff(moment(this.deliveryDetail.send_at), 'days') < 0) {
      return true;
    }
    return false;
  }
  disableUpdateDelivery() {
    return this.isSubmiting || !this.isChanged;
  }
  updateDelivery() {
    const queryData = {
      state: this.deliveryDetail.state,
      delivery_date: this.deliveryDetail.delivery_date ? moment(this.deliveryDetail.delivery_date).format('YYYY-MM-DD') : null,
      delivery_number: this.deliveryDetail.delivery_number,
      send_at: this.deliveryDetail.send_at ? moment(this.deliveryDetail.send_at).format('YYYY-MM-DD') : null,
      complete_at: this.deliveryDetail.complete_at ? moment(this.deliveryDetail.complete_at).format('YYYY-MM-DD') : null,
    };
    this.updateIsSubmiting(true);
    this.updateIsChanged(true);
    ApiHelper.getApi('DeliveryApi')
      .updateDelivery(this.$route.params.id, queryData)
      .then((res: any) => {
        this.currentQuery = queryData;
        this.deliveryDetail.delivery_number = queryData.delivery_number;
        this.deliveryDetail.send_at = queryData.send_at;
        this.deliveryDetail.complete_at = queryData.complete_at;
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
        this.updateIsChanged(false);
        this.updateIsSubmiting(false);
      });
  }
  createNumberRegExp() {
    return new RegExp(`[0-9]{5}-[0-9]{5}`);
}

  private clearDate(name) {
    this.deliveryDetail[name] = '';
  }

  private deliveryStatus(status) {
    return DELIVERY_STATUS_NAME[status];
  }

  private notAfterEndDay(date) {
    if (this.deliveryDetail.delivery_date) {
      return date > moment(this.deliveryDetail.delivery_date).clone().startOf('date');
    }
    return true;
  }

  private notBeforeStartDay(date) {
     if (this.query.resv_end_at_lte) {
      return date > moment(this.query.resv_end_at_lte!).clone().startOf('date');
    }
  }

  private deliveryDate(date) {
    return moment(date).format('YYYY年 MM月 DD日');
  }

  private goBack() {
    this.$router.push({path: `/deliveries`, query: {
        state: this.$route.query.state || '',
        // delivery_date: this.$route.query.delivery_date || '',
        user_name: this.$route.query.user_name || '',
        page: this.$route.query.page || '1',
        delivery_date_from: this.$route.query.delivery_date_from || '',
        delivery_date_to: this.$route.query.delivery_date_to || '',
    }});
  }
  private stopDelivery() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    ApiHelper.getApi('UserApi')
      .updateDeliveryStatus(this.deliveryDetail.id, {
        state: 'cancel',
      })
      .then((res: any) => {
        this.isLoading = false;
        this.isStop = true;
        this.deliveryDetail.state = 'cancel';
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Watch('deliveryDetail', { deep: true })
  private watchDeliveryDetail(_, oldValue) {
    if (this.isChanged || !oldValue.id) {
      return;
    }
    this.updateIsChanged(true);
  }
}
