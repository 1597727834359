import { Deserializable } from '@common/interfaces/deserializable';
import { MedicineInput, Medicine } from '@/models/medicine';
import { Plan } from '@/models/plan';
import { ReservationInput, Reservation } from '@/models/reservation';
import { Patient, ModelPatient } from '@/models/patient';
import { DeliveryStatusType } from '@/common/enums/order';
import { formatDateTime } from '@/common/app.config';

import { get } from 'lodash';
import moment from 'moment';
import { Order } from './order';

export interface DeliveryInput {
  address: string;
  amount: string;
  building_name: string;
  city_id: string;
  created_at: string;
  id: number;
  medicines: MedicineInput[];
  memo: string;
  paid_at: string;
  received_date: string;
  plan: Plan;
  postal_number: string;
  prefecture_id: string;
  prescription_image: string;
  prescription_image_url: string;
  reservation: ReservationInput;
  reservation_id: number;
  status: string;
  status_rule: string[];
  updated_at: string;
  user: ModelPatient;
  user_id: number;
  order?: Order;
}

export class Delivery implements Deserializable<Delivery>, DeliveryInput {
  address: string;
  amount: string;
  building_name: string;
  city_id: string;
  created_at: string;
  id: number;
  memo: string;
  paid_at: string;
  received_date: string;
  postal_number: string;
  prefecture_id: string;
  prescription_image: string;
  prescription_image_url: string;
  reservation_id: number;
  status: string;
  status_rule: string[];
  updated_at: string;
  user_id: number;

  medicines: Medicine[] = [];
  reservation: Reservation = new Reservation();
  user: Patient = new Patient();
  plan: Plan = new Plan();
  order: Order = new Order();

  get doctorId(): number {
    return get(this.reservation, 'doctor.id', 0);
  }

  get doctorName(): string {
    return get(this.reservation, 'doctor.name', '');
  }

  get userIdDisplay(): string {
    return `ID: ${this.user_id}`;
  }

  get userIdDeliveryDisplay(): string {
    if (this.user) {
      return `ID: ${this.user.id}`;
    }
    return '';
  }

  get userIdDeliveryValue(): string {
    if (this.user) {
      return (this.user as any).id.toString();
    }
    return '';
  }

  get userName(): string {
    return `${get(this.user, 'first_name') || ''} ${get(this.user, 'last_name') || ''}`;
  }

  get priceTotal(): string {
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
      +this.amount,
    );
  }

  get dateDelivery(): string {
    return moment(this.created_at).format(formatDateTime);
  }

  get paidAtDelivery(): string {
    return moment(this.paid_at).format(formatDateTime);
  }

  get receivedAtDelivery(): string {
    return moment(this.received_date).format(formatDateTime);
  }

  get isDelivered(): boolean {
    return this.status === DeliveryStatusType.Delivered;
  }

  get isUndelivered(): boolean {
    return this.status === DeliveryStatusType.Undelivered;
  }

  get isSuccess(): boolean {
    return this.status === DeliveryStatusType.Success;
  }

  get isRefunded(): boolean {
    return this.status === DeliveryStatusType.Refunded;
  }

  get statusName(): string {
    return new Map()
      .set(DeliveryStatusType.Success, '発送待ち')
      .set(DeliveryStatusType.Delivered, '発送完了')
      .set(DeliveryStatusType.Undelivered, 'キャンセル済')
      .set(DeliveryStatusType.Refunded, '返金済み')
      .get(this.status);
  }

  get shippingDateTime(): string {
    if (this.isUndelivered || this.isRefunded) {
      if (this.received_date) {
        return `${this.receivedAtDelivery} （キャンセル）`;
      }
    }

    if (this.isDelivered) {
      return this.receivedAtDelivery;
    }

    return '未発送';
  }

  get purchasedPrice() {
    const formatter = new Intl.NumberFormat('ja-JP');
    return this.plan.price_first
      ? `${formatter.format(this.plan.price_first * this.order.quantity)}円`
      : `0円`;
  }

  deserialize(input: Partial<DeliveryInput>): Delivery {
    Object.assign(this, input);

    if (input.medicines) {
      this.medicines = input.medicines.map((item: MedicineInput) =>
        new Medicine().deserialize(item),
      );
    }

    if (input.reservation) {
      this.reservation = new Reservation().deserialize(input.reservation);
    }

    if (input.user) {
      this.user = new Patient().deserialize(input.user);
    }

    if (input.plan) {
      this.plan = new Plan().deserialize(input.plan);
    }

    if (input.order) {
      this.order = new Order().deserialize(input.order);
    }

    return this;
  }
}
