import Member from '@/views/member/index.vue';
import MemberDetail from '@/views/member/detail.vue';
import MemberCreate from '@/views/member/create.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
    auth: true,
    authorize: [Roles.superAdmin, Roles.admin],
};

const guestMeta = {
    auth: false,
    authorize: [],
};

export default [
    {
        path: '/members',
        name: 'members',
        component: Member,
        meta: authMeta,
    },
    {
        path: '/members/create',
        name: 'members.create',
        component: MemberCreate,
        meta: guestMeta,
    },
    {
        path: '/members/:id',
        name: 'members.detail',
        component: MemberDetail,
        meta: authMeta,
    },
];
