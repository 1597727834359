import BaseApi from './BaseApi';

class ResvClinicPatternApi extends BaseApi {
  public list(userId: number, params: any) {
    return this.get(`/reservation-clinic?user_id=${userId}&with=clinicPattern`, params);
  }

  public show(clinicId: any, params: any) {
    params = {
      with: 'managers',
      ...params,
    };
    return this.get(`/${clinicId}`, params);
  }

  public create(params: any) {
    return this.post('/reservation-clinic', params);
  }

  public update(resvId: number, params: any) {
    return this.put(`/reservation-clinic/${resvId}`, params);
  }

  public delete(resvId: number, params: any) {
    return this.del(`/reservation-clinic/${resvId}`, params);
  }

  public getClinicPatternDetail(id: number) {
    return this.get('/' + id);
  }

  public getPrefectureHasClinic(params: any) {
    return this.get('/prefectures-has-clinic', params);
  }

  public getClinicByPrefecture(id: number, params: any) {
    return this.get(`/get-clinic-contain-pattern?prefecture_id=${id}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers';
  }
}

export default ResvClinicPatternApi;
