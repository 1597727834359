



















































































































































import { Vue, Component } from 'vue-property-decorator';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faUserMd,
  faGlobe,
  faClinicMedical,
  faUserCheck,
  faHospital,
  faCommentDots,
  faCalendarAlt,
  faMoneyBill,
  faMedkit,
  faFilePrescription,
  faListUl,
  faListOl,
  faUserInjured,
  faFileSignature,
  faEnvelopeOpenText,
  faCapsules,
  faDatabase,
  faCog,
  faUser,
  faFileCsv,
  faChartLine,
  faUserNurse,
  faEllipsisV,
  faClipboardList,
  faTruck,
  faStethoscope,
} from '@fortawesome/free-solid-svg-icons';
import { Roles } from '@common/app.config';
import { mapState } from 'vuex';
import SocketService from '@common/utils/SocketService';
import { AuthenticationModule, DashboardModule } from '@/store';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ClinicIcon from '@/assets/img/clinicIcon.svg';
import ReservationIcon from '@/assets/img/reservation.svg';
import userIconDefault from '@/assets/img/sidebar-icon-black.svg';
import memberIcon from '@/assets/img/memberIcon.svg';
import medicineIcon from '@/assets/img/icon-medicine.svg';
import planList from '@/assets/img/plan-list.svg';
import diagnosisIcon from '@/assets/img/icon-diagnosis.svg';
import orderIcon from '@/assets/img/icon-order.svg';
import doctorShiftIcon from '@/assets/img/doctor-shift-icon.svg';
import salesManagementIcon from '@/assets/img/sales-management-icon.svg';
import manualIcon from '@/assets/img/manualIcon.svg';
import { get } from 'lodash';
import { config } from '@common/app.config';

@Component({
  computed: {
    ...mapState('auth', ['currentUser', 'unreadMessageCount']),
  },
  // head: {
  //   script: [
  //     {
  //       type: 'text/javascript',
  //       inner: `
  //       // 86acbd31cd7c09cf30acb66d2fbedc91daa48b86:1582863375.814468
  //       !function(n,t,c,e,r){var u,i,o=[],a=[],f={then:s,catch:function(n){return s(!1,n)},_async_setup:function(n){var t;try{t=n(r)}catch(n){
  //       return i=n,void h(a,n)}t.then((function(n){h(o,u=n)})).catch((function(n){i=n,h(defferedErrors,i)}))}};n[e]=f
  //       ;var d=t.createElement("script");function h(n,t){for(var c=0;c<n.length;c++)p(n[c],t)}function s(n,t){return n&&(u?p(n,u):o.push(n)),
  //       t&&(i?p(t,i):a.push(t)),f}function p(t,c){n.setTimeout((function(){t(c)}),0)}d.src=c,d.async=!0,d.id="_uasdk",d.rel=e,t.head.appendChild(d)
  //       }(window,document,'https://aswpsdkus.com/notify/v1/ua-sdk.min.js',
  //         'UA', {
  //           vapidPublicKey: '${config.airshipPublicKey}',
  //           appKey: '${config.airshipAppKey}',
  //           token: '${config.airshipAccessToken}',
  //         });
  //     `,
  //     },
  //   ],
  // },
})
export default class Sidebar extends Vue {
  private get roleWithENV() {
    if (process.env.VUE_APP_ENV !== 'production') {
      return [Roles.superAdmin, Roles.clinic];
    }
    return [Roles.superAdmin];
  }

  get routeFollowRole() {
    let nameRoute = 'patients';
    if (this.currentUser.role === Roles.superAdmin) {
      nameRoute = 'PatientV2';
    }

    if (this.currentUser.role === Roles.doctor) {
      nameRoute = 'PatientV2';
    }

    if (this.currentUser.role === Roles.customerService) {
      nameRoute = 'PatientV2';
    }

    if (this.currentUser.role === Roles.delivery) {
      nameRoute = 'deliveries';
    }

    if (this.currentUser.role === Roles.pharmacist) {
      nameRoute = 'PatientV2';
    }
    return { name: nameRoute };
  }

  routes = [
    // ...PharmacySidebars,
    // ...PharmacistSidebars,
    // {
    //   name: '予約一覧',
    //   to: '/resv',
    //   icon: 'fa-hospital',
    //   roles: [Roles.superAdmin, Roles.doctor, Roles.clinic],
    // },
    {
      name: '患者一覧',
      to: '/v2/patients',
      isDisabledSub: false,
      icon: 'far fa-user',
      roles: [
        Roles.superAdmin,
        Roles.admin,
        Roles.doctor,
        Roles.customerService,
        Roles.pharmacist,
      ],
    },
    {
      name: 'クリニック・サロン一覧',
      to: '/clinics',
      icon: 'fa-file-prescription',
      iconSvg: ClinicIcon,
      roles: [Roles.superAdmin],
    },
    {
      name: 'ドクター一覧',
      to: '/doctors',
      icon: 'fa-stethoscope',
      roles: [Roles.superAdmin, Roles.admin],
    },
    {
      name: '予約管理',
      to: '/reservation',
      icon: 'fa-hospital',
      iconSvg: ReservationIcon,
      roles: [Roles.doctor, Roles.superAdmin, Roles.admin, Roles.pharmacist],
    },
    // {
    //   name: 'プラン管理',
    //   to: '/plans',
    //   iconSvg: planList,
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: 'プラン管理',
    //   to: '/courses',
    //   icon: 'fa-clipboard-list',
    //   roles: [Roles.doctor],
    // },
    // {
    //   name: 'プラン一覧',
    //   to: '/courses',
    //   icon: 'fa-clipboard-list',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '商品管理',
    //   to: '/medicine',
    //   iconSvg: medicineIcon,
    //   roles: [Roles.superAdmin],
    // },
    {
      name: '診療サービス管理',
      to: '/diagnosis',
      iconSvg: diagnosisIcon,
      roles: [Roles.superAdmin],
    },
    {
      name: 'ユーザー管理',
      to: '/members',
      isDisabledSub: false,
      iconSvg: memberIcon,
      icon: 'fa fa-clipboard-list',
      roles: [Roles.superAdmin, Roles.admin],
    },
    // {
    //   name: '配送一覧',
    //   to: '/deliveries',
    //   icon: 'fa fa-truck fa-flip-horizontal',
    //   roles: [
    //     Roles.admin,
    //     Roles.pharmacy,
    //     Roles.customerService,
    //     Roles.superAdmin,
    //     Roles.delivery,
    //   ],
    // },
    // {
    //   name: 'オーダー一覧',
    //   to: '/orders',
    //   iconSvg: orderIcon,
    //   roles: [Roles.superAdmin, Roles.admin, Roles.customerService],
    // },
    // {
    //   name: 'LINEメッセージ',
    //   to: '/line-messages',
    //   iconSvg: lineMessageIcon,
    //   roles: [Roles.customerService],
    // },
    {
      name: 'シフト管理',
      to: '/doctor-shifts',
      iconSvg: doctorShiftIcon,
      roles: [Roles.doctor, Roles.superAdmin, Roles.admin, Roles.pharmacist],
    },
    // {
    //   name: '売上管理',
    //   to: '/sales-management',
    //   iconSvg: salesManagementIcon,
    //   roles: [Roles.superAdmin],
    // },
    //
    // {
    //   name: '注文一覧',
    //   to: '/order-list',
    //   icon: 'fa-list-ul',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '発送管理',
    //   to: '/delivery-list-ec-force',
    //   icon: 'fa-list-ol',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '患者一覧',
    //   to: '/patients',
    //   icon: 'fa-user-injured',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '分譲指示',
    //   to: '/delivery-list',
    //   icon: 'fa-list-ol',
    //   roles: [Roles.superAdmin, Roles.doctor],
    // },
    // {
    //   name: 'Doctor Admins',
    //   to: '/doctor-admins',
    //   icon: 'fa-user-md',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '診察メニュー一覧',
    //   to: '/medical-menu',
    //   icon: 'fa-file-signature',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '処方プラン一覧',
    //   to: '/plans',
    //   icon: 'fa-file-prescription',
    //   roles: [Roles.superAdmin, Roles.doctor],
    // },
    // {
    //   name: 'シフト一覧',
    //   to: '/shifts',
    //   icon: 'fa-calendar-alt',
    //   roles: [Roles.superAdmin, Roles.doctor, Roles.clinic],
    // },
    // {
    //   name: 'プロフィール',
    //   to: '/doctor_profile',
    //   icon: 'fa-user-injured',
    //   roles: [Roles.doctor],
    // },
    // {
    //   name: 'プロフィール',
    //   to: '/clinic_profile',
    //   icon: 'fa-clinic-medical',
    //   roles: [Roles.clinic],
    // },
    // {
    //   name: '決済管理',
    //   to: '/payments',
    //   icon: 'fa-file-signature',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '処方箋一覧',
    //   to: '/medical-prescription',
    //   icon: 'fa-file-prescription',
    //   roles: [Roles.superAdmin, Roles.doctor],
    // },
    // {
    //   name: 'お知らせ一覧',
    //   to: '/notifications',
    //   icon: 'fa-envelope-open-text',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '言語管理',
    //   to: '/upload-localization',
    //   icon: 'fa-globe',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: 'データ項目の結び付け',
    //   to: '/upload-csv',
    //   icon: 'fa-file-csv',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: 'アップデート設定',
    //   to: '/app-version',
    //   icon: 'fa-cog',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: 'Toppage設定',
    //   to: '/settings',
    //   icon: 'fa-cog',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '薬局一覧',
    //   to: '/pharmacies',
    //   icon: 'fa-medkit',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '薬剤師一覧',
    //   to: '/pharmacists',
    //   icon: 'fa-user-nurse',
    //   roles: [Roles.superAdmin],
    // },
    // {
    //   name: '月次報告',
    //   to: '/monthly-report',
    //   icon: 'fa-chart-line',
    //   roles: this.roleWithENV,
    // },
    // {
    //   name: '売上管理',
    //   to: '/revenue',
    //   icon: 'fa-money-bill',
    //   roles: [Roles.superAdmin, Roles.clinic],
    // },
    {
      name: '操作マニュアル',
      to: config.manualUrl,
      iconSvg: manualIcon,
      roles: [
        Roles.superAdmin,
        Roles.admin,
        Roles.doctor,
        Roles.customerService,
        Roles.pharmacist,
        Roles.delivery,
      ],
      isExternal: true,
    },
  ];
  private currentUser!: any;
  private unreadMessageCount!: number;
  private isShow = false;

  created() {
    this.$on('TotalUnreadCount', ({ unreadMessageCount }) => {
      this.$store.commit('auth/updateUnreadMessageCount', unreadMessageCount);
    });
  }

  mounted() {
    this.connectSocket(this.currentUser);
    library.add(faUserMd);
    library.add(faGlobe);
    library.add(faCommentDots);
    library.add(faClinicMedical);
    library.add(faUserCheck);
    library.add(faCalendarAlt);
    library.add(faHospital);
    library.add(faMedkit);
    library.add(faFilePrescription);
    library.add(faListUl);
    library.add(faListOl);
    library.add(faCapsules);
    library.add(faUser);
    library.add(faUserInjured);
    library.add(faFileSignature);
    library.add(faEnvelopeOpenText);
    library.add(faFileCsv);
    library.add(faCog);
    library.add(faMoneyBill);
    library.add(faDatabase);
    library.add(faChartLine);
    library.add(faUserNurse);
    library.add(faEllipsisV);
    library.add(faClipboardList);
    library.add(faTruck);
    library.add(faStethoscope);
    dom.watch();
  }

  beforeDestroy() {
    SocketService.disconnect();
  }

  currentClinicName() {
    const clinic = get(this.currentUser, 'clinics.[0]');
    return clinic ? clinic.name : '';
  }

  isShowClinicName() {
    return (
      this.currentUser.role != Roles.superAdmin &&
      this.currentUser.role != Roles.customerService
    );
  }

  isDoctor() {
    return this.currentUser.role == Roles.doctor;
  }

  currentAvatar() {
    return this.currentUser.avatar_url || userIconDefault;
  }

  turnOfShow() {
    this.isShow = false;
  }

  confirmlogout() {
    if (
      this.$router.currentRoute.name === 'news.create' ||
      this.$router.currentRoute.name === 'news.detail'
    ) {
      this.$router.push('/login');
      return;
    }

    if (this.$router.currentRoute.name === 'resv.calling') {
      this.$modal.show('confirmLogout');
    } else {
      this.logout();
    }
  }

  async logout() {
    if ((window as any).UA) {
      (window as any).UA.then(async (sdk: any) => {
        if (sdk.channel && !sdk.channel.namedUser) {
          return;
        }
        if (sdk.channel) {
          await sdk.channel.namedUser.remove(`managers_${this.currentUser.id}`);
        }
        await sdk.register();
      });
    }

    await AuthenticationModule.logout();
    await DashboardModule.setClinics([]);
    await LocalStorageUtils.removeItem('dashboardID');
    await LocalStorageUtils.removeItem('revenueClinic');
    await LocalStorageUtils.removeItem('monthlyReportClinic');
    await localStorage.removeItem('isLoggedIn');
    this.$router.push('/login');
  }

  isActiveLink(routerName) {
    return this.$route.name === routerName ? 'router-link-active' : '';
  }

  private toggle() {
    this.isShow = !this.isShow;
  }

  private connectSocket(currentUser) {
    SocketService.disconnect();
    SocketService.init(currentUser);
  }
}
