




























































































































































































































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import Toast from '@common/helpers/toast';
import { extend } from 'vee-validate';
// import { doctor } from '@/models/deliveries';
// import { doctor_STATUS, doctor_STATUS_NAME } from '@/common/constants/auth';
import { Roles } from '@common/app.config';
import jaLangDatePicker from 'vuejs-datepicker/dist/locale/translations/ja';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faStethoscope, faBars } from '@fortawesome/free-solid-svg-icons';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import PopupAddClinic from '@/components/common/PopupAddClinic.vue';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import PopupSetPassword from '@/components/common/PopupSetPassword.vue';
import { getFormData } from '@/common/utils';
import CustomErrorMessage from '@/common/mixins/CustomErrorMessage';
import { includes } from 'lodash';

@Component({
  components: {
    PopupConfirm,
    PopupAddClinic,
    DragDropFileUpload,
    PopupSetPassword,
  },
  filters: {},
  mixins: [CustomErrorMessage],
})
export default class DetailDoctor extends Vue {
  public extend: any = extend;

  roles = Roles;

  get queryParams() {
    return {
      with: 'order,user,order.plan,order.plan.medicines',
    };
  }

  get isPermission() {
    return (
      this.currentUser &&
      (includes([Roles.superAdmin, Roles.admin], this.currentUser.role) ||
        (this.currentUser.role === Roles.doctor &&
          Number(this.currentUser.id) === Number(this.$route.params.id)))
    );
  }

  get isAdmin() {
    return (
      this.currentUser &&
      (this.currentUser.role === Roles.superAdmin ||
        this.currentUser.role === Roles.admin)
    );
  }
  get isDoctor() {
    return this.currentUser && this.currentUser.role === Roles.doctor;
  }

  get isView() {
    return !this.isPermission;
  }

  @State((state) => state.auth.currentUser) currentUser!: any;
  private shipDate = moment().format('YYYY-MM-DD');
  private shipDoneDate = moment().format('YYYY-MM-DD');
  private query = {
    resv_start_at_gte: '',
    resv_end_at_lte: '',
    upload_date: '',
    freeword: null,
  };
  private isLoading: boolean = false;
  private doctorDetail: any = {};
  private doctorUser = {};
  private currentQuery: any = {};
  private isStop: boolean = false;
  private isCancel: boolean = false;
  private jaLangDatePicker: any = jaLangDatePicker;
  private clinic: any = {};
  private isOpenMenu: boolean = false;
  private isChangeUpdateForm: boolean = false;

  private doctorForm: any = {};
  private initialData: any = {};

  beforeOpen() {
    document.documentElement.style.overflow = 'hidden';
  }

  beforeClose() {
    document.documentElement.style.overflow = 'auto';
  }

  clickOutside(el) {
    this.isOpenMenu = false;
  }

  mounted() {
    window.scrollTo(0, 0);

    if (this.currentUser.role == 'doctor') {
      this.getUserInfo();
    } else {
      this.getDoctorDetail();
    }
  }

  async getUserInfo() {
    const { commit } = this.$store;
    commit('auth/updateIsGettingUserInfo', false);
    try {
      const response = await ApiHelper.getApi('UserApi').getInfo();
      const { currentUser } = response;
      this.doctorDetail = currentUser;
      this.isStop = this.doctorDetail.state === 'cancel';
      this.isCancel = [
        'paid',
        'preparing_for_doctor',
        'delivered',
        'doctor_complete',
      ].includes(this.doctorDetail.state);
      this.doctorUser = this.doctorDetail.user;
      // this.doctorDetail.avatar_url = this.doctorDetail.avatar;
      if (
        this.doctorDetail &&
        this.doctorDetail.clinics &&
        this.doctorDetail.clinics[0]
      ) {
        this.clinic = this.doctorDetail.clinics[0];
      }
      if (this.doctorDetail && this.doctorDetail.license) {
        this.doctorDetail.license_code = this.doctorDetail.license.license_code;
        this.doctorDetail.license_description =
          this.doctorDetail.license.license_description;
      }
      if (this.doctorDetail.send_at) {
        this.doctorDetail.send_at = moment(this.doctorDetail.send_at).toDate();
      }
      if (this.doctorDetail.complete_at) {
        this.doctorDetail.complete_at = moment(
          this.doctorDetail.complete_at,
        ).toDate();
      }
      this.doctorForm = this.doctorDetail;
      this.initialData = Object.assign(this.initialData, this.doctorDetail);
      commit('auth/updateCurrentUser', { ...currentUser });
    } catch (error) {
      commit('auth/updateIsGettingUserInfo', false);
      error && Toast.error(error.response.data.message);
    }
  }

  created() {
    this.extend('priority', {
      validate: this.searchPriority,
      message: '重複されましたため、他の数字を入力してください。',
    });

    library.add(faStethoscope);
    library.add(faBars);
    dom.watch();
  }

  @Watch('doctorForm.priority')
  updateOnChangeForm() {
    this.isChangeUpdateForm = true;
  }

  async searchPriority() {
    try {
      if (
        Number(this.initialData.priority) === Number(this.doctorForm.priority)
      ) {
        return true;
      }
      const params = {
        priority: Number(this.doctorForm.priority),
      };
      const { is_valid } = await ApiHelper.getApi(
        'PriorityApi',
      ).validatePriority(params);
      return is_valid;
    } catch (error) {
      return false;
    }
  }

  getDoctorDetail() {
    if (this.isLoading) {
      return;
    }

    this.doctorDetail = [];

    this.isLoading = true;

    ApiHelper.getApi('DoctorApi')
      .getDetail(this.$route.params.id, { with: 'clinics,license' })
      .then((res: any) => {
        this.doctorDetail = res;
        this.isStop = res.state === 'cancel';
        this.isCancel = [
          'paid',
          'preparing_for_doctor',
          'delivered',
          'doctor_complete',
        ].includes(res.state);
        this.doctorUser = res.user;
        // this.doctorDetail.avatar_url = this.doctorDetail.avatar;
        if (
          this.doctorDetail &&
          this.doctorDetail.clinics &&
          this.doctorDetail.clinics[0]
        ) {
          this.clinic = this.doctorDetail.clinics[0];
        }
        if (this.doctorDetail && this.doctorDetail.license) {
          this.doctorDetail.license_code =
            this.doctorDetail.license.license_code;
          this.doctorDetail.license_description =
            this.doctorDetail.license.license_description;
        }
        if (this.doctorDetail.send_at) {
          this.doctorDetail.send_at = moment(
            this.doctorDetail.send_at,
          ).toDate();
        }
        if (this.doctorDetail.complete_at) {
          this.doctorDetail.complete_at = moment(
            this.doctorDetail.complete_at,
          ).toDate();
        }
        this.doctorForm = this.doctorDetail;
        this.initialData = Object.assign(this.initialData, this.doctorDetail);
        // if (this.doctorDetail.send_at) {
        //   this.doctorDetail.send_at = moment(this.doctorDetail.send_at).toDate()
        // }
        // if (this.doctorDetail.complete_at) {
        //   this.doctorDetail.complete_at = moment(this.doctorDetail.complete_at).toDate()
        // }
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private get computedForm() {
    return this.$refs.doctorForm as any;
  }
  // get isMatchDate() {
  //   if (!this.doctorDetail.send_at || !this.doctorDetail.complete_at) {
  //     return true;
  //   }
  //   if (moment(this.doctorDetail.send_at).diff(moment(this.doctorDetail.doctor_date), 'days') < 0) {
  //     return true;
  //   }
  //   if (moment(this.doctorDetail.complete_at).diff(moment(this.doctorDetail.send_at), 'days') < 0) {
  //     return true;
  //   }
  //   return false;
  // }

  getDoctorForm(oldVal, newVal) {
    const dataForm: any = {};
    if (oldVal.name !== newVal.name) {
      dataForm.doctor_name = newVal.name;
    }
    if (oldVal.email !== newVal.email) {
      dataForm.doctor_email = newVal.email;
    }
    if (oldVal.name_kana !== newVal.name_kana) {
      dataForm.doctor_name_kana = newVal.name_kana;
    }
    if (oldVal.phone !== newVal.phone) {
      dataForm.doctor_phone = newVal.phone;
    }
    if (oldVal.clinic_ids !== newVal.clinic_ids) {
      dataForm.clinic_ids = newVal.clinic_ids;
    }
    if (oldVal.license_code !== newVal.license_code) {
      dataForm.license_code = newVal.license_code;
    }
    if (oldVal.license_description !== newVal.license_description) {
      dataForm.license_description = newVal.license_description;
    }
    if (
      this.doctorDetail.avatar &&
      typeof this.doctorDetail.avatar === 'object'
    ) {
      dataForm.avatar = this.doctorDetail.avatar;
    } else {
      // dataForm.avatar = null;
    }
    if (Number(oldVal.priority) !== Number(newVal.priority)) {
      dataForm.priority = Number(newVal.priority);
    }

    return dataForm;
  }

  private get currentDoctor() {
    return this.$route.params.id;
  }

  updateDoctor() {
    if (this.isLoading) {
      return;
    }

    // let formData = this.doctorDetail;
    // if (this.doctorDetail.avatar && (typeof this.doctorDetail.avatar === 'object')) {
    //   formData = this.doctorDetail.formJSONDataHaveImage(this.isEdit);
    // }

    // const queryData = {
    //   doctor_number: this.doctorDetail.doctor_number,
    //   send_at: this.doctorDetail.send_at ? moment(this.doctorDetail.send_at).format('YYYY-MM-DD') : null,
    //   complete_at: this.doctorDetail.complete_at ? moment(this.doctorDetail.complete_at).format('YYYY-MM-DD') : null,
    // };
    // if (
    //   this.currentQuery.doctor_number === this.doctorDetail.doctor_number &&
    //   this.currentQuery.send_at === moment(this.doctorDetail.send_at).format('YYYY-MM-DD') &&
    //   this.currentQuery.complete_at === moment(this.doctorDetail.complete_at).format('YYYY-MM-DD')
    //   ) {
    //     return;
    //   }
    let formData = this.getDoctorForm(
      { ...this.initialData },
      { ...this.doctorForm },
    );

    if (
      this.doctorDetail.avatar &&
      typeof this.doctorDetail.avatar === 'object'
    ) {
      formData = getFormData(formData);
    }
    if (this.doctorDetail.avatar === '') {
      formData.delete_avatar = true;
    }

    this.isLoading = true;
    ApiHelper.getApi('DoctorDetailApi')
      .updateDoctorInfo(this.$route.params.id, formData)
      .then((res: any) => {
        if (this.currentUser.role == 'doctor') {
          this.getUserInfo();
        } else {
          this.doctorDetail = res;
          if (this.doctorDetail.priority) {
            this.doctorDetail.priority = `${this.doctorDetail.priority}`;
          }
          // this.doctorForm = this.doctorDetail;
        }
        // this.doctorDetail.doctor_number = queryData.doctor_number;
        // this.doctorDetail.send_at = queryData.send_at;
        // this.doctorDetail.complete_at = queryData.complete_at;
        this.initialData = Object.assign(this.initialData, this.doctorDetail);
        Toast.success('更新しました');
        this.isChangeUpdateForm = false;
      })
      .catch((error: any) => {
        const data = error.response.data;
        if (error.response.status === 422) {
          const errorData = error.response.data.errors;
          if (errorData && errorData.doctor_name) {
            errorData.name = errorData.doctor_name;
          }
          if (errorData && errorData.doctor_name_kana) {
            errorData.name_kana = errorData.doctor_name_kana;
          }
          if (errorData && errorData.doctor_phone) {
            errorData.phone = errorData.doctor_phone;
          }
          if (errorData && errorData.doctor_email) {
            errorData.email = errorData.doctor_email;
          }
          this.computedForm.setErrors(error.response.data.errors);
        }
        error.response && Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  setClinic(clinic) {
    this.clinic = clinic;
    this.isChangeUpdateForm = true;
    this.doctorDetail.clinic_ids = [clinic.id];
    this.hiddenPopup('popup-add-clinic');

    return;
  }
  setPassword() {
    this.hiddenPopup('popup-set-password');
  }
  // createNumberRegExp() {
  //   return new RegExp(`[0-9]{5}-[0-9]{5}`);
  // }

  // private clearDate(name) {
  //   this.doctorDetail[name] = '';
  // }

  // private doctorStatus(status) {
  //   return doctor_STATUS_NAME[status];
  // }

  // private notAfterEndDay(date) {
  //    if (this.doctorDetail.doctor_date) {
  //     return date > moment(this.doctorDetail.doctor_date!).clone().startOf('date');
  //   }
  // }

  // private notBeforeStartDay(date) {
  //    if (this.query.resv_end_at_lte) {
  //     return date > moment(this.query.resv_end_at_lte!).clone().startOf('date');
  //   }
  // }

  // private doctorDate(date) {
  //   return moment(date).format('YYYY年 MM月 DD日');
  // }

  private async deleteDoctor() {
    if (!this.doctorDetail || !this.doctorDetail.id) {
      return;
    }

    try {
      if (this.currentUser.role === Roles.clinic) {
        await ApiHelper.getApi('DoctorApi').removeLink(this.doctorDetail.id, {
          clinic_id: this.currentUser.clinics[0].id,
        });
      } else {
        await ApiHelper.getApi('DoctorApi').delete(this.doctorDetail.id);
      }

      Toast.success('削除しました');
      this.$router.push('/doctors');
    } catch (error) {
      const data = error && error.response.data;
      if (error.response && error.response.status === 422) {
        return;
      }

      Toast.error(data.message);
    }
    this.isOpenMenu = false;
  }

  private goBack() {
    this.$router.push({
      path: `/doctors`,
      query: {
        id: this.$route.query.id || '',
        doctor_name: this.$route.query.doctor_name || '',
        clinic_name: this.$route.query.clinic_name || '',
        page: this.$route.query.page || '1',
      },
    });
  }

  private hiddenPopup(name) {
    this.$modal.hide(name);
  }

  private showPopup(event, name) {
    this.isOpenMenu = false;
    event.preventDefault();
    event.stopPropagation();

    this.$modal.show(name);
  }
  // private stopdoctor() {
  //   if (this.isLoading) {
  //     return;
  //   }

  //   this.isLoading = true;

  //   ApiHelper.getApi('UserApi')
  //     .updateDoctorStatus(this.doctorDetail.id, {
  //       state: 'cancel',
  //     })
  //     .then((res: any) => {
  //       this.isLoading = false;
  //       this.isStop = true;
  //       this.doctorDetail.state = 'cancel';
  //       Toast.success('更新しました');
  //     })
  //     .catch((error: any) => {
  //       Toast.error(error.response.data.message);
  //     })
  //     .finally(() => {
  //       this.isLoading = false;
  //     });
  // }
  private changeValue(value) {
    this.doctorDetail.avatar_url = value;
    this.isChangeUpdateForm = true;
    if (value === '') {
      this.doctorDetail.avatar = value;
    }
  }
  private updateForm(field: string, value: any) {
    this.isChangeUpdateForm = true;
    this.handleFile(value);
    this.$set(this.$store.state.doctor.dataDoctorDetail, field, value);
  }

  private handleFile(val) {
    if (typeof val === 'object') {
      const file = val;
      const reader = new FileReader();
      this.$set(this.doctorDetail, 'avatar', val);
      reader.onload = (event: any) => {
        this.$set(this.doctorDetail, 'avatar_url', event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  private openMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    this.isOpenMenu = !this.isOpenMenu;
  }
}
