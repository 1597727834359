




























































































































































































































import { Vue, Component, Mixins,  Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { getFormData } from '@/common/utils';
import PharmacistMixin from '@/common/mixins/Pharmacist/PharmacistMixin';
import ApiHelper from 'api-helper';
import ZoomImage from '@/components/common/ZoomImage.vue';
import UploadImage from '@/components/common/UploadImage.vue';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import PopupConfirm from '@/components/common/NewPopupConfirm.vue';

@Component({
  components: {
    ZoomImage,
    UploadImage,
    PageLoader,
    PopupConfirm,
  },
})
export default class PharmacistUpdateModal extends Mixins(PharmacistMixin) {
  @State((state) => state.auth.currentUser) currentUser!: any;

  private name = 'pharmacist-update';
  private file: File| any = null;
  private computedForm: any = null;
  private pharmacist: any = {
    name: '',
    name_kana: '',
    stores: [],
    email: '',
    phone: '',
    avatar_url: '',
    password: '',
    password_confirmation: '',
  };
  private isLoading: boolean = false;
  private isCreate: boolean = false;

  private onOpened() {
    this.computedForm = this.$refs.pharmacistForm;
  }

  private get stores() {
    if (this.currentUser.pharmacies[0]) {
      return this.currentUser.pharmacies[0];
    }
    return {};
  }

  private beforeClose() {
    this.file = null;
    this.pharmacist = {
      name: '',
      name_kana: '',
      email: '',
      phone: '',
      avatar_url: '',
      password: '',
      password_confirmation: '',
    };
    this.isCreate = false;
  }

  private beforeOpen($event) {
    if ($event.params.pharmacist) {
      this.pharmacist = {...$event.params.pharmacist};
    }
    if ($event.params.isCreate) {
      this.isCreate = $event.params.isCreate;
    }
  }

  private onInputPassword(field: string, value: string) {
    if (this.pharmacist[field].length > 100) {
      this.pharmacist[field] = this.pharmacist[field].slice(0, 100);
    }
  }

  private get isDisableButton() {
    if (!this.computedForm) { return false; }
    if (!this.pharmacist.avatar_url) { return true; }
    const errors = this.computedForm.errors;
    for (const [key, value] of Object.entries(errors)) {
        if ((value as any).length) { return true; }
    }
  }

  private async updateImage() {
    try {
      const formData = new FormData();
      if (!this.file) { return; }
      formData.append('avatar', this.file);
      const response = await ApiHelper.getApi('PharmacistApi').updateImage(
        this.pharmacist.id,
        formData,
        );
    } catch (error) {
      const data = error.response.data;
      Toast.error(data.message);
    }
  }

  private async submitAction() {
    if (this.isCreate) {
      await this.createPharmacist();
      return;
    }
    this.$modal.show('confirm-update');
  }


  private get requestData() {
    return {
      pharmacist_email: this.pharmacist.email,
      pharmacist_name: this.pharmacist.name,
      pharmacist_name_kana: this.pharmacist.name_kana,
      pharmacist_phone: this.pharmacist.phone.replace(/^0/gi, '81'),
    };
  }

  private async updatePharmacist() {
    try {
      const mapParams = {
        ...this.requestData,
        pharmacy_ids: this.pharmacist.stores.map((x) => (x as any).id),
      };
      await ApiHelper.getApi('PharmacistApi').update(this.pharmacist.id, mapParams);
      await this.updateImage();
      Toast.success('更新しました');
      this.$modal.hide(this.name);
      this.$emit('reload');
    } catch (error) {
      if (error.response) {
        const { data, status } = error.response;
        if (status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
      }
      Toast.error(error.message);
    }
  }

  private async createPharmacist() {
    try {
      this.isLoading = true;
      const mapParams = {
        ...this.requestData,
        avatar: this.file,
        pharmacy_ids: [this.currentUser.pharmacies[0].id],
        password: this.pharmacist.password,
        password_confirmation: this.pharmacist.password_confirmation,
      };
      const body = getFormData(mapParams);
      const response = await ApiHelper.getApi('PharmacistApi').create(body);
      Toast.success('登録しました');
      this.$modal.hide(this.name);
      this.$emit('reload');
    } catch (error) {
      if (error.response) {
        const { data, status } = error.response;
        if (status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  private onChangeFile(file) {
    this.file = file;
    const reader = new FileReader();
    reader.onload = (event: any) => {
        this.pharmacist.avatar_url = event.target.result;
      };
    reader.readAsDataURL(file);
  }

}
