
























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import FAQItem from './FAQItem.vue';
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import ApiHelper from 'api-helper';
import PageLoader from '@components/common/PageLoader.vue';
import autosize from 'autosize';
import cloneDeep from 'lodash/cloneDeep';

gsap.registerPlugin(ScrollToPlugin);

@Component({
  components: {
    FAQItem,
    draggable,
    PageLoader,
  },
})
export default class FAQList extends Vue {
  private editIndex: number|string = '';
  private openIndex: number|string = '';
  private isLoading: boolean = false;
  private listFAQ: any[] = [];
  private autoInterval = 0;
  private cloneListFAQ: any[] = [];

  private async created() {
    await this.getListFAQ();
    this.cloneListFAQ = cloneDeep(this.listFAQ);
    this.autoInterval = setInterval(() => {
      autosize(document.querySelectorAll('textarea'));
      autosize.update(document.querySelectorAll('textarea'));
    }, 100);
  }

  private get disabledDrag() {
    const item = this.listFAQ.filter((faq) => faq.newQuestion);
    return this.editIndex !== '' || item.length;
  }

  private beforeDestroy() {
    clearInterval(this.autoInterval);
  }

  private setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  private onEditFAQ(editId) {
    this.editIndex = editId;
  }

  private async getListFAQ() {
    try {
      this.cloneListFAQ = cloneDeep(this.listFAQ);
      this.listFAQ = [];
      this.isLoading = true;
      const data = await ApiHelper.getApi('FAQApi').getList({
        order: 'index_order',
      });
      this.listFAQ = await data.data;
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      this.isLoading = false;
    }
  }

  private get maxIndex() {
    if (!this.listFAQ.length) { return  -1; }
    const faq = this.listFAQ.reduce((prev, current) => (prev.id > current.id) ? prev : current);
    return faq.id;
  }

  private removeFAQ(item) {
    const index = this.listFAQ.findIndex((faq) => faq.id === item.id);
    const indexClone = this.cloneListFAQ.findIndex((faq) => faq.id === item.id);
    if (index > -1 ) {
      this.listFAQ.splice(index, 1);
      if (this.editIndex === item.id) {
        this.editIndex = '';
      }
    }
    if (indexClone > -1 ) {
      this.cloneListFAQ.splice(index, 1);
    }
  }

  private async orderFAQ() {
    this.cloneListFAQ = cloneDeep(this.listFAQ);
    const params = this.listFAQ.map((item, index) => {
      return {
        id: item.id,
        index_order: index + 1,
      };
    });
    try {
      this.isLoading = true;
      await ApiHelper.getApi('FAQApi').orderFAQ(params);
      await this.getListFAQ();
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      this.isLoading = false;
    }
  }

  private async deleteFAQ(faq) {
    if (faq.newQuestion) {
      this.removeFAQ(faq);
      return;
    }
    try {
      this.isLoading = true;
      await ApiHelper.getApi('FAQApi').destroy(faq.id);
      this.removeFAQ(faq);
      Toast.success('削除しました');
      await this.getListFAQ();
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      this.isLoading = false;
    }
  }

  private onOpenedFAQ(openId) {
    this.openIndex = openId;
  }

  private addFAQ() {
    const newItem = {
      id: this.maxIndex + 1,
      title: '',
      content: '',
      index_order: '',
      show_top_page: true,
      newQuestion: true,
    };
    this.listFAQ.push(newItem);
    this.cloneListFAQ.push(newItem);
    setTimeout(() => {
      this.editIndex = newItem.id;
      this.openIndex = newItem.id;
      gsap.to(window, {duration: 1, scrollTo: document.body.scrollHeight });
    });
  }
}
