import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators';
import moment from 'moment';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';

const calculateSectionTime = (prevCreatedAt: string, currentCreatedAt: string) => {
  if (!prevCreatedAt && !!currentCreatedAt) {
    return moment(currentCreatedAt).isBefore(moment(), 'date') ? moment(currentCreatedAt) : '';
  }

  if (moment(prevCreatedAt).isBefore(moment(currentCreatedAt), 'date')) {
    return moment(currentCreatedAt);
  }

  return null;
};

@Module({ name: 'message', namespaced: true })
class Message extends VuexModule {
  public messages: any[] = [];
  public isMeIds: any[] = [];
  public realation = '';
  public isLoading = false;
  public perPage = 20;
  public page = 1;
  public totalPage = 1;
  public isSending: boolean = false;
  public isUserListOpen: any = true;

  @Mutation
  public updateIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @Mutation
  public updateIsSending(isSending) {
    this.isSending = isSending;
  }

  @Mutation
  public updateTotalPage(totalPage) {
    this.totalPage = totalPage;
  }

  @Mutation
  public updatePage(page: number) {
    this.page = page;
  }

  @Mutation
  public updateMessages(messages) {
    this.messages = messages;
  }

  @Mutation
  updateIsMeIds(isMeIds) {
    this.isMeIds = isMeIds;
  }

  @Mutation
  toggleIsUserListOpen(value = null) {
    if (value !== null) {
      this.isUserListOpen = value;
    } else {
      this.isUserListOpen = !this.isUserListOpen;
    }
  }

  @Action({rawError: true})
  onRecieveMessage({message}) {
    const { commit, state } = this.context;
    const {isMeIds, messages} = state as any;

    if (messages.find((item) => item.id === message.id)) {
      return;
    }

    const isMe = isMeIds.some((me) => {
      const isManager = message.senderable_type === me.type && me.id === message.sender_id;
      const isClinic = message.owner_senderable_type === me.type && me.id === message.owner_sender_id;
      return isManager || isClinic;
    });
    messages.push(Object.assign({
      isMe,
    }, message));
    commit('updateMessages', messages);
  }

  @Action({rawError: true})
  reset() {
    const { commit } = this.context;
    commit('updateMessages', []);
    commit('updatePage', 1);
  }

  @Action({rawError: true})
  toggleUserList(value) {
    const { commit } = this.context;
    commit('toggleIsUserListOpen', value);
  }
}

export default Message;
