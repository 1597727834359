import BaseApi from './BaseApi';
import { getFormData, autoDeletePropertyEmpty } from '../common/utils';

class DeliveryApi extends BaseApi {
  getDeliveries(opts: any = {}) {
    return this.get('', opts);
  }

  getDeliveryDetail(id, opts: any = {}) {
    return this.get(`/${id}`, opts);
  }

  uploadImage(id, params: any) {
    const body = getFormData(params);
    return this.postWithFile(`/${id}/upload-image`, body);
  }

  updateMemo(id, params: any) {
    return this.post(`/${id}/update-memo`, params);
  }

  updateLotInfo(id, params: any) {
    return this.post(`/${id}/update-lot-info`, params);
  }

  updateStatus(id, params: any) {
    return this.post(`/${id}/update-status`, params);
  }

  updateDelivery(id, params: any) {
    return this.post(`/${id}/update-delivery`, params);
  }

  updateDeliveryDate(id, data: any) {
    return this.put(`/${id}/update-delivery-date`, data);
  }

  updateAmount(id, data: any) {
    return this.put(`/${id}/update-amount`, data);
  }


  protected getSpecificApiPrefix(): string {
    return 'managers/delivery';
  }
}

export default DeliveryApi;
