var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentLayout',{attrs:{"icon":_vm.iconHeader,"name":"クリニック・サロン詳細"}},[_c('div',{staticClass:"container-fluid wrap-edit"},[_c('div',{staticClass:"mx-2"},[_c('ValidationObserver',{ref:"updateClinicForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"info-clinic"},[_c('div',{staticClass:"group-info-clinic"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center buton-delete"},[_c('button',{staticClass:"btn",attrs:{"disabled":_vm.fetchingClinic},on:{"click":function($event){$event.preventDefault();return _vm.$modal.show('confirmDeleteClinic')}}},[_c('i',{staticClass:"fa fa-trash-alt"}),_vm._v(" クリニック・サロン削除 ")])]),_c('table',{staticClass:"table table-bordered table-striped"},[_c('tr',[_c('th',[_vm._v("ID")]),_c('td',[_vm._v(_vm._s(_vm.clinicId))])]),_c('tr',[_c('th',[_vm._v("UUID")]),_c('td',[_vm._v(_vm._s(_vm.uuidClinic))])]),(_vm.updateClinicForm.type !== _vm.CType.clinicDoctor)?_c('tr',[_c('th',[_vm._v("クリニック・サロンのURL")]),_c('td',[_c('a',{attrs:{"href":((_vm.appConfig.webUrl) + "/clinics/" + _vm.uuidClinic)}},[_vm._v(_vm._s(((_vm.appConfig.webUrl) + "/clinics/" + _vm.uuidClinic)))])])]):_vm._e(),_c('tr',[_c('th',[_vm._v("クリニック/サロン名 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","tag":"div","customMessages":_vm.clinicNameErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.name),expression:"updateClinicForm.name"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.updateClinicForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("クリニック/サロン名（カナ）")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"name_kana","rules":"required|kana|max:100","tag":"div","customMessages":_vm.clinicNameKanaErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.name_kana),expression:"updateClinicForm.name_kana"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.updateClinicForm.name_kana)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "name_kana", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("電話番号 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"phone","rules":"numeric|required|max:11|min:10|phone","tag":"div","customMessages":_vm.phoneErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.phone),expression:"updateClinicForm.phone"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.updateClinicForm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("郵便番号 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"mode":_vm.input,"name":"postal_code","rules":{ required: true, regex: /^\d{3}\d{4}$/, postal_code: true},"tag":"div","customMessages":_vm.postalCodeErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.postal_code),expression:"updateClinicForm.postal_code"}],class:("form-control " + (!!errors.length ? 'is-invalid' : '')),attrs:{"type":"text","maxLength":"7","placeholder":"入力してください"},domProps:{"value":(_vm.updateClinicForm.postal_code)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "postal_code", $event.target.value)},function($event){_vm.typeValidate = 'input'; _vm.isSetPrefectureCity = true}]}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("都道府県 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{staticClass:"w-50",attrs:{"name":"prefecture_id","rules":"required|max:30","tag":"div","customMessages":_vm.prefectureErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"prefecture-select",class:{'is-invalid': !!errors.length},attrs:{"value":"id","label":"title","placeholder":"選択してください","options":_vm.listPrefectureOption,"reduce":function (option) { return ("" + (option.id)); }},model:{value:(_vm.updateClinicForm.prefecture_id),callback:function ($$v) {_vm.$set(_vm.updateClinicForm, "prefecture_id", $$v)},expression:"updateClinicForm.prefecture_id"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("一致する項目がありません")])]),(errors.length)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("市区町村")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"city_id","rules":"required|max:30","tag":"div","customMessages":_vm.cityErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.city_id),expression:"updateClinicForm.city_id"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.updateClinicForm.city_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "city_id", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("番地 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"required|max:30","tag":"div","customMessages":_vm.addressErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.address),expression:"updateClinicForm.address"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.updateClinicForm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "address", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("建物名")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"building_name","rules":"max:30","tag":"div","customMessages":_vm.buildingNameErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.building_name),expression:"updateClinicForm.building_name"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.updateClinicForm.building_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "building_name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("区分")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{staticClass:"w-50",attrs:{"name":"type","rules":"required|max:30","tag":"div","customMessages":_vm.typeErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"type-select",class:{'is-invalid': !!errors.length},attrs:{"value":"id","label":"title","placeholder":"選択してください","options":_vm.listTypeOption,"reduce":function (option) { return ("" + (option.id)); }},model:{value:(_vm.updateClinicForm.type),callback:function ($$v) {_vm.$set(_vm.updateClinicForm, "type", $$v)},expression:"updateClinicForm.type"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("一致する項目がありません")])]),(errors.length)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("GTM ID")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"gtm_id","rules":"max:255","tag":"div","customMessages":_vm.gtmIdErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.updateClinicForm.gtm_id),expression:"updateClinicForm.gtm_id",modifiers:{"trim":true}}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください（「GTM-XXXXXX」の形式）"},domProps:{"value":(_vm.updateClinicForm.gtm_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "gtm_id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])]),_c('div',{staticClass:"form-group d-flex justify-content-center button-bottom"},[_c('button',{staticClass:"btn btn-cancel",attrs:{"type":"button"},on:{"click":_vm.openCancelCreateClinic}},[_vm._v("戻る")]),_c('button',{staticClass:"btn btn-submit",attrs:{"type":"button","disabled":!dirty || invalid || _vm.isSubmiting || !_vm.isChange},on:{"click":_vm.updateInfoClinic}},[_vm._v("保存")])]),_c('PopupConfirmDeletion',{attrs:{"name":"confirmDeleteClinic","contentText":_vm.dataClinic ? _vm.dataClinic.name : '',"disabledBtnSubmit":_vm.isSubmitDelete},on:{"submit":_vm.deleteInfoClinic,"cancel":function($event){return _vm.$modal.hide('confirmDeleteClinic')}}})]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }