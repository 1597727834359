import HomeChime from '@/views/resv/HomeChime.vue';
// import Calling from '@/views/calling/Calling.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.superAdmin, Roles.doctor],
};

export default [
  {
    path: '/calling/:channelId',
    name: 'calling',
    component: HomeChime,
    meta: {
      ...authMeta,
      authorize: [Roles.doctor],
    },
  },
];
