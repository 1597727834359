import BaseApi from './BaseApi';

class DoctorMouthApi extends BaseApi {
  public uploadMouthImage(params: any) {
    return this.post('doctor-images', params);
  }

  public getMouthImageOrigin(userId) {
    return this.get(`list-image-doctor?user_id=${userId}&with=doctor`);
  }

  public getListFeedback(userId) {
    return this.get(`list-feedback?user_id=${userId}&with=doctor,userTreatment.menu`);
  }

  public getTitleFeedback(userId) {
    return this.get(`link-create-feedback?user_id=${userId}`);
  }

  public getDeliveryEF(params) {
    return this.get('list-order-ecforce', params);
  }

  public uploadCSV(params: any) {
    return this.post('import-csv', params);
  }

  public createFeedback(params: any) {
    return this.post('feedback', params);
  }

  public updateFeedback(feedbackId, params: any) {
    return this.post(`feedback/${feedbackId}`, params);
  }

  public reupload(params: any) {
    return this.post('require-upload-again', params);
  }

  public changeStatusReceiveTooth(params: any) {
    return this.post('require-kit', params);
  }

  public getListDelivery(userId, params) {
    return this.get(`delivery?user_id=${userId}`, params);
  }

  public updateOrderStatus(order_id) {
    return this.put(`delivery/${order_id}/cancel`, {});
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/';
  }
}

export default DoctorMouthApi;
