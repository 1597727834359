






























































































































































































































import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';
import { extend } from 'vee-validate';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro, faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ClinicModule } from '@/store';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import PopupConfirmDeletion from '@/components/common/PopupConfirmDeletion.vue';
import { Roles, CType } from '@common/app.config';
import ContentLayout from '@components/layouts/Content.vue';
import Listicon from '@/assets/img/clinicListicon.svg';
import { config } from '@/common/app.config';
import { get } from 'lodash';

@Component({
  head: {
    title: {
      inner: 'クリニック詳細・編集',
    },
  },
  components: {
    PopupConfirm,
    PopupConfirmDeletion,
    ContentLayout,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      clinicId: this.$route.params.clinicId,
      prefectures: [],
      cities: [],
      clinic: {},
      iconHeader: Listicon,
      appConfig: config,
    };
  },
})
export default class ClinicInfo extends Mixins(ClinicsMixin) {
  public iconHeader: any;
  public appConfig: any;
  public listPrefecture: any[] = [];
  private extend: any = extend;
  private typeValidate: string = 'input';
  private isSubmiting = false;
  private isSubmitDelete: boolean = false;
  private currentUser: any;
  private clinicId!: any;
  private clinicUuid: string = '';
  private isSetPrefectureCity: boolean = false;
  private isChange: boolean = false;
  private CType = CType;

  private updateClinicForm = {
    name: '',
    name_kana: '',
    phone: '',
    postal_code: '',
    prefecture_id: '',
    city_id: '',
    address: '',
    building_name: '',
    type: '',
    gtm_id: '',
  };

  public get listPrefectureOption() {
    return this.listPrefecture.map((prefecture) => {
      return {
        title: prefecture.ja_name,
        id: `${prefecture.id}`,
      };
    });
  }

  public get listTypeOption() {
    return [
      {title: 'クリニック', id: `clinic`},
      {title: 'サロン', id: `salon`},
      {title: '診察クリニック', id: `clinic_doctor`},
    ];
  }

  private openCancelCreateClinic() {
    return this.$router.push({name: 'clinics', query: {
        id: this.$route.query.id || '',
        name: this.$route.query.name || '',
        name_kana: this.$route.query.name_kana || '',
        page: this.$route.query.page ? this.$route.query.page.toString() : '1',
    }});
  }

  private get computedForm() {
    return this.$refs.updateClinicForm as any;
  }

  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  private updateIsChange(isChange: boolean) {
    if (this.isChange && isChange) {
      return;
    }
    this.isChange = isChange;
  }

  private input() {
    return {
      on: ['input'],
    };
  }

  @Watch('updateClinicForm', { deep: true })
  private formChangeData() {
    !this.isChange && this.updateIsChange(true);
  }

  private async updateInfoClinic() {
    try {
      // const re = /^0/gi;
      const cpForm = Object.assign({}, this.updateClinicForm);
      // cpForm.phone = cpForm.phone && cpForm.phone.replace(re, '81');
      // cpForm.fax = cpForm.fax && cpForm.fax.replace(re, '81');
      // cpForm.manager_phone = cpForm.manager_phone && cpForm.manager_phone.replace(re, '81');
      this.updateIsSubmiting(true);
      await ApiHelper.getApi('ClinicApi').update(this.clinicId, cpForm);
      this.updateIsSubmiting(false);
      this.updateIsChange(false);
      Toast.success('更新しました');
      const data = await ClinicModule.getClinicDetail(this.clinicId);
      if (this.currentUser.role === Roles.clinic) {
        const updateData = { ...this.currentUser, clinics: [data] };
        await this.$store.dispatch('auth/updateUser', updateData);
      }
    } catch (error) {
      this.updateIsSubmiting(false);
      this.updateIsChange(false);
      const data = error.response.data;
      Toast.error(data.message);
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
    }
  }

  private async deleteInfoClinic() {
    try {
      this.isSubmitDelete = true;
      await ApiHelper.getApi('ClinicApi').delete(this.clinicId);
      this.isSubmitDelete = false;
      Toast.success('削除しました');
      this.$router.push('/clinics');
    } catch (error) {
      this.isSubmitDelete = false;
      const data = error && error.response.data;
      if (error.response && error.response.status === 422) {
        return;
      }
      Toast.error(data.message);
    }
  }

  private async searchZipcode() {
    try {
      const params = {
        postal_code: this.updateClinicForm.postal_code,
      };
      const result = await ApiHelper.getApi('ZipcodeApi').getList(params);
      if (this.isSetPrefectureCity) {
        this.updateForm(this.updateClinicForm, 'prefecture_id', result.address1);
        this.updateForm(this.updateClinicForm, 'city_id', result.address2);
        this.updateForm(this.updateClinicForm, 'address', result.address3);
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  private get dataClinic() {
    return ClinicModule.dataClinic;
  }

  private get uuidClinic() {
    return get(this.dataClinic, 'uuid', '');
  }

  private get fetchingClinic() {
    return ClinicModule.fetchingClinic;
  }

  private async created() {
    if (this.currentUser.role === Roles.clinic) {
      this.clinicId = (this.currentUser.clinics[0] || {}).id;
      this.clinicUuid = this.currentUser.uuid;
    }

    ClinicModule.getClinicDetail(this.clinicId);
    this.extend('postal_code', {
      validate: (value: string) => {
        if (this.typeValidate === 'click') {
          return this.computedForm.refs.postal_code.flags.valid;
        }
        return this.searchZipcode();
      },
      message: '住所が見つかりません',
    });
    library.add(faCameraRetro);
    library.add(faDownload);
    library.add(faTrashAlt);
    dom.watch();

    try {
      this.listPrefecture = (await ApiHelper.getApi('PrefectureApi').listPrefecture()).data;
    } catch (error) {
      console.log(error);
    }
  }

  @Watch('dataClinic')
  private watchDataClinic() {
    this.setData(this.updateClinicForm, this.dataClinic);
  }
}
