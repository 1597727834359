








































































































































import ApiHelper from 'api-helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { PLAN_PAYMENT_TYPE } from '@/common/constants/plan';
import moment from 'moment';
import { extend } from 'vee-validate';
import { USER_STATE } from '@/common/constants/auth';
import { State } from 'vuex-class';
import { UserTreatment } from '@/models/user-treatment';
import { Patient } from '@/models/patient';

extend('notSpecialChar', (text) => {
  const textCompare = text;
  if (text) {
    return textCompare.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    ).length == text.length
      ? true
      : '絵文字または特殊記号を入力しないでください。';
  }
  return true;
});

@Component({
  components: {
    PageLoader,
  },
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})
export default class PrescriptionPopup extends Vue {
  @State((state) => state.patient_detail.data) currentPatient!: Patient;
  @Prop() changeState: any;

  private get prescriptionFormRef() {
    return this.$refs.prescriptionForm as any;
  }
  @Prop() currentChannelUser!: any;
  @Prop() changeCurrentChannelUser!: any;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  protected PRESCRIPTION_TYPE = {
    PRESCRIPTION: 'prescription',
    NG: 'ng',
  };
  private isSubmitting: boolean = false;
  private isLoading: boolean = false;
  private listPlan: any[] = [];

  private PLAN_PAYMENT_TYPE: any = PLAN_PAYMENT_TYPE;
  private prescriptionForm: any = {
    content: null,
    type: null,
    plans: [],
  };
  private isPrescription: boolean = false;
  private isNG: boolean = false;
  private prescriptionType: string = '';

  mounted() {
    this.getListPlans();
  }

  show(currentPrescription) {
    this.prescriptionForm = currentPrescription;
    this.prescriptionType = currentPrescription.type;
    this.$modal.show('prescriptionForm');
  }

  getListPlans() {
    this.isLoading = true;
    const self = this;
    // ApiHelper.getApi('PlanV2Api').fetchPlanList({ karte_status: true })
    ApiHelper.getApi('PatientV2Api')
      .getPlansByPatientId(this.currentChannelUser.id)
      .then(function(res, error) {
        self.listPlan = res.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  makeListPlans() {
    return this.prescriptionForm.id == null
      ? this.listPlan
      : this.prescriptionForm.listPlans;
  }

  prescriptionHandle() {
    this.isPrescription = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.PRESCRIPTION;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.PRESCRIPTION;
  }

  ngHandle() {
    this.isNG = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.NG;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.NG;
  }

  async savePrescriptionForm() {
    const isValid = await this.prescriptionFormRef.validate();
    if (!isValid) {
      return;
    }
    const data = {
      user_id: this.currentChannelUser.id,
      content: this.prescriptionForm.content,
      plans:
        this.prescriptionForm.type === this.PRESCRIPTION_TYPE.PRESCRIPTION
          ? this.prescriptionForm.plans
          : null,
      type: this.prescriptionForm.type,
      user_treatment_id: this.activeTreatment.id,
    };
    this.isSubmitting = true;
    const self = this;

    if (this.prescriptionForm.id) {
      await ApiHelper.getApi('DoctorMouthApi')
        .updateFeedback(this.prescriptionForm.id, data)
        .catch((error: any) => {
          error.response && Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
        });
    } else {
      // new feedback.
      await ApiHelper.getApi('PatientV2Api')
        .createFeedback(this.currentPatient.id, data)
        .then(function(res, error) {
          self.$emit('submit', res.data);
        })
        .catch((error: any) => {
          error.response && Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
        });
    }

    self.$emit('savedFeedback');
  }
}
