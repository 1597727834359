import BaseApi from './BaseApi';

class QuestionApi extends BaseApi {
  public getList(params: any) {
    return this.get('/questions', params);
  }

  public updateQuestions(params: any) {
    return this.post('/questions', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers';
  }
}

export default QuestionApi;
