







































import { Vue, Component, Prop } from 'vue-property-decorator';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import IconDeleteSvg from '../iconSVG/Delete.vue';
import IconEditSvg from '../iconSVG/Edit.vue';
import { ModelPatientDetail } from '@/models/patient';

@Component({
  components: {
    ImagePrivate,
    IconDeleteSvg,
    IconEditSvg,
  },
})
export default class PatientDetailResvClinic extends Vue {
  @Prop(Boolean) readonly isCloseChannel!: false;
  @Prop(Object) readonly listResvClinicPattern!: object;
  @Prop(Boolean) readonly isAdmin!: false;
  @Prop(Boolean) readonly isWaitingChannel!: false;
  @Prop(Object) readonly dataDetail!: ModelPatientDetail;
  private isChannelClose = false;
  private isChannelWaiting = false;
  private isAdminRole = false;
  private reservationClinicPattern = {};

  mounted() {
    this.isChannelWaiting = this.isWaitingChannel;
    this.isAdminRole = this.isAdmin;
    this.isChannelClose = this.isCloseChannel;
    this.reservationClinicPattern = this.listResvClinicPattern;
  }

  updated() {
    this.isChannelWaiting = this.isWaitingChannel;
    this.isAdminRole = this.isAdmin;
    this.isChannelClose = this.isCloseChannel;
    this.reservationClinicPattern = this.listResvClinicPattern;
  }

  private handleUpdateResv(id) {
    this.$emit('updateDateToClinicPattern', id);
  }

  private handleDeleteResv() {
    this.$emit('deleteDateToClinicPattern');
  }

  private handleAddResv() {
    this.$emit('addDateToClinicPattern');
  }
}
