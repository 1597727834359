import BaseApi from './BaseApi';

class PharmacistPatientApi extends BaseApi {
  public peer(id: number | string, params?: any) {
    return this.get(`/${id}/peer`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/users';
  }
}

export default PharmacistPatientApi;
