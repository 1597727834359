



































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BasicModal from '@/components/common/BasicModal.vue';

@Component({
  components: {
    BasicModal,
  },
})
export default class PatientAction extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(String) readonly note!: string;
  @Prop({default: false}) readonly disabledBtSave!: boolean;
  @Prop({ default: 600 }) readonly width!: number | string;

  private text: string = '';
  private textTmp: string = '';

  private get isEdit() {
    return this.text === this.note;
  }

  private beforeOpen(event: any) {
    const note: string = event.params.note;
    this.text = note;
    this.textTmp = note;
  }

  private async onSubmit() {
    const isValid = await (this.$refs.noteEdit as any).validate();
    if (!isValid) {
      return;
    }
    this.$emit('save', this.text);
  }
}
