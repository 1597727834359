
























































































































































































import { Vue, Component } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { splitLongText } from '@common/helpers/split-long-text';
import PageLoader from '@components/common/PageLoader.vue';
import ContentLayout from '@components/layouts/Content.vue';
import SalesManagement from '@/assets/img/sales-management-icon.svg';
import { config } from '@/common/app.config';
import { CLINIC_TYPE_TO_TEXT } from '@/common/constants/auth';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

@Component({
  components: {
    Pagination,
    PageLoader,
    ContentLayout,
    DatePicker,
  },
  data() {
    return {
      listClinics: {},
      totalRevenueInfo: {},
      iconHeader: SalesManagement,
      appConfig: config,
    };
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString = value
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}`;
    },
  },
})
export default class Index extends Vue {
  public appConfig: any;
  public iconHeader: any;
  public listClinics!: any;
  public totalRevenueInfo!: any;
  public isLoading: boolean = false;

  public searchForm = {
    clinic_id: this.$route.query.clinic_id || '',
    clinic_name: this.$route.query.clinic_name || '',
    start_date: this.$route.query.start_date || '',
    end_date: this.$route.query.end_date || '',
    with: 'managers',
    order: 'clinic_id',
  };

  public searchClinic() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));
    this.list(this.searchForm);
  }

  public gotoDetail(clinic_id) {
    this.$router.push({
      path: `/sales-management/show/${clinic_id}`,
      query: {
        start_date: this.searchForm.start_date || '',
        end_date: this.searchForm.end_date || '',
      },
    });
  }

  created() {
    if (LocalStorageUtils.getItem('paramsSearch')) {
      LocalStorageUtils.removeItem('paramsSearch');
    }

    this.list(this.searchForm);
  }

  mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  clearDateStart() {
    this.clearDate('start_date');
  }

  private async list(data) {
    this.isLoading = true;

    await ApiHelper.getApi('SalesManagementApi')
      .index(data)
      .then((res: any) => {
        this.listClinics = res.data.revenues;
        this.totalRevenueInfo = res.data;
      })
      .catch((error: any) => {
        this.toast(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  private notAfterDate(date) {
    if (this.searchForm.end_date) {
      return (
        moment(date).format('YYYY-MM-DD') >
        moment(this.searchForm.end_date.toString()).format('YYYY-MM-DD')
      );
    }
  }

  private notBeforeDate(date) {
    if (this.searchForm.start_date) {
      return (
        moment(date).format('YYYY-MM-DD') <
        moment(this.searchForm.start_date.toString()).format('YYYY-MM-DD')
      );
    }
  }

  private clearDate(name) {
    if (this.searchForm[name]) {
      this.searchForm[name] = '';
    }
  }
}
