import BaseApi from './BaseApi';

class RefundApi extends BaseApi {
  public refundAmount(id, data) {
    return this.post(`refund/${id}`, data);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/';
  }
}

export default RefundApi;
