import BaseApi from './BaseApi';

class PrefectureApi extends BaseApi {
  public listPrefecture(params: any) {
    return this.get('prefectures', params);
  }

  public listCity(params: any) {
    return this.get('cities', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'users/';
  }
}

export default PrefectureApi;
