






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FAQList from '@components/Settings/FAQ/FAQList.vue';
import ListTopPosts from '@components/Settings/News/ListTopPosts.vue';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    FAQList,
    ListTopPosts,
  },
})
export default class TopPageSettings extends Vue {
  private currentComponent: string =  'ListTopPosts';

  created() {
    if (this.$route.query.tab === 'faq') {
      this.currentComponent = 'FAQList';
    } else {
      this.currentComponent = 'ListTopPosts';
    }
  }

  private handleNextStep(component) {
    if (this.currentComponent === component) {
      return;
    }
    this.currentComponent = component;
    const tab = component === 'ListTopPosts' ? 'news' : 'faq';
    this.$router.replace({query: { tab }});
  }

  private isActive(component) {
    if (this.currentComponent === component) {
      return 'active';
    }
  }
}
