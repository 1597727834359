import BaseApi from './BaseApi';
class ManagerApi extends BaseApi {
  public getLatestReservation(id: number) {
    return this.get(`/${id}/latest-reservation`);
  }

  public deliveryImport(data: any) {
    return this.postWithFile(`/delivery-import`, data);
  }

  public confirmReservation(id: number, data: any) {
    return this.put(`/${id}/confirm-reservation-date`, data);
  }

  public updateReservationDate(id: number, data: any) {
    return this.put(`/${id}/update-reservation-date`, data);
  }

  public deleteReservationDate(id: number, data: any) {
    return this.put(`/${id}/delete-reservation-date`, data);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers';
  }
}

export default ManagerApi;
