



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class QuestionListItemGroup extends Vue {
  @Prop(String) label!: string;
  @Prop(Boolean) labelBgNone!: boolean;
  @Prop(Boolean) valueOverflowHidden!: boolean;
}
