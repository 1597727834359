
// libs
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

// helpers
// import Toast from '@common/helpers/toast';
// import ApiHelper from '@/api/ApiHelper';

// interfaces
import { UserTreatment } from '@/models/user-treatment';

// constants
// import { USER_STATUS } from '@common/constants/auth';

@Module({ name: 'treatment_detail', namespaced: true })
export default class Index extends VuexModule<any> {
  private data: UserTreatment | {} = {};
  private isLoadingChild = false;
  private isLoading = false;

  // ********* Mutations ********* //
  @Mutation
  public setTreatment(treatment: any) {
    if (treatment.constructor.name === 'Object') {
      treatment = new UserTreatment().deserialize(treatment);
    }
    this.data = treatment;
  }

  @Mutation
  public setLoading(isLoading = true) {
    this.isLoading = isLoading;
  }

  @Mutation
  public setLoadingChild(isLoadingChild = true) {
    this.isLoadingChild = isLoadingChild;
  }

  // ********* Actions ********* //
  // @Action({ rawError: true })
  // public async getTreatment(payload) {
  //   const { commit } = this.context;
  //   const { id } = payload;
  //   try {
  //     commit('setLoading');
  //     const response = await ApiHelper.getApi('TreatmentV2Api').detail(id);
  //     commit('setTreatment', response);
  //   } catch (error) {
  //     commit('setLoading', false);
  //     const data = error.response.data;
  //     Toast.error(data.message);
  //   } finally {
  //     commit('setLoading', false);
  //   }
  // }

  // @Action({ rawError: true })
  // public async updateTreatment(payload) {
  //   const { commit } = this.context;
  //   const { id , data} = payload;
  //   try {
  //     commit('setLoading');
  //     const response = await ApiHelper.getApi('TreatmentApi').updateInformation(id, data);
  //     commit('setTreatment', response);
  //     Toast.success('更新しました');
  //   } catch (error) {
  //     commit('setLoading', false);
  //     const data = error.response.data;
  //     Toast.error(data.message);
  //   } finally {
  //     commit('setLoading', false);
  //   }
  // }

  // @Action({ rawError: true })
  // public async updateStateIdentification(payload) {
  //   const { commit } = this.context;
  //   const { id , data} = payload;
  //   try {
  //     commit('setLoading');
  //     await ApiHelper.getApi('TreatmentApi').identification(id, data);
  //     const response = await ApiHelper.getApi('TreatmentV2Api').detail(id);
  //     commit('setTreatment', response);

  //     Toast.success(data.state === USER_STATUS.IDENTIFICATION_NG ? '本人確認NG' : '本人確認OK');
  //   } catch (error) {
  //     commit('setLoading', false);
  //     const data = error.response.data;
  //     Toast.error(data.message);
  //   } finally {
  //     commit('setLoading', false);
  //   }
  // }
}
