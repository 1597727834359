import BaseApi from './BaseApi';

class PharmacistApi extends BaseApi {
  public updateImage(id, params: any) {
    return this.postWithFile(`/${id}/upload-avatar`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/pharmacists';
  }
}

export default PharmacistApi;
