import Notifications from '@/views/notifications/index.vue';
import NotificationCreate from '@/views/notifications/create.vue';
import NotificationEdit from '@/views/notifications/detail.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.superAdmin],
};

export default [
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: authMeta,
  },
  {
    path: '/notifications/create',
    name: 'notifications.create',
    component: NotificationCreate,
    meta: authMeta,
  },
  {
    path: '/notifications/:id',
    name: 'notifications.edit',
    component: NotificationEdit,
    meta: authMeta,
  },
];
