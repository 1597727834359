




























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ToggleButton } from 'vue-js-toggle-button';
import { mapGetters } from 'vuex';
import { oc } from 'ts-optchain';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import Toast from '@common/helpers/toast';
import imageDefault from '@/assets/img/icon-no-image.svg';
import { uiAvatars } from '@common/helpers/ui-avatar';
import { Roles } from '@/common/app.config';
import { Reservation } from '@/models/reservation';
import { MessageModule } from '@/store';
import AnswerPopup from './AnswerPopup.vue';
import lodash from 'lodash';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { OrderStatusType } from '@/common/enums/order';
import { get } from 'lodash';

@Component({
  components: {
    ToggleButton,
    AnswerPopup,
    PopupConfirm,
  },
  computed: {
    ...mapGetters('channel', [
      'currentChannel',
    ]),
  },
})
export default class PatientInfo extends Vue {

  get doctorInfo() {
    return this.reservations;
  }

  get channelAvatar() {
    if (this.currentChannel && this.currentChannel.users) {
      const user = this.currentChannel.users[0] || {};
      if (!user.name && !user.avatar_url) {
        return imageDefault;
      }
      const avatarUser = user.avatar_url || uiAvatars(user.name);
      return avatarUser;
    }
  }

  get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }

  get isDoctor() {
    return this.currentUser.role === Roles.doctor;
  }

  get toggleColor() {
    if (this.isPharmacy) {
      return '#41CFD9';
    }
    return '#007bff';
  }

  get channelStatusClose() {
    return this.currentChannel && this.currentChannel.status === 0;
  }

  get channelOrderStatus() {
    return get(this.currentChannel, 'users[0].order_first[0].status');
  }

  get channelName() {
    if (!this.currentChannel || !this.currentChannel.users) {
      return '';
    }

    const { last_name, first_name } = this.currentChannel.users[0] || {};

    return `${first_name || ''} ${last_name || ''}`;
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop(String) onLoadDoctorStatus: any;

  currentChannel!: any;
  reservations: Reservation[] = [];

  isOpen: boolean = false;
  isLoadingListResv: boolean = false;
  isLoading: boolean = false;
  closeChannel: boolean = false;
  isEnableChat = false;
  isDisableChat = false;

  mounted() {
    if (this.isDoctor) {
      this.getChannelUser();
    }

    const nameHeight = (this.$refs.patientName as any).clientHeight;
    if (nameHeight > 50) {
      (this.$refs.patientNameTitle as any).classList = 'text-center mb-0 text--break w-100 smaller-text';
    }
  }

  onChangePriority({ value }) {
    this.updateChannelPriority(value);
  }

  messageEnableChat() {
    return this.isEnableChat ?
      'チャット機能がオフになり、<br>メッセージの送受信が一時停止されます' :
      'チャット機能を復帰します。<br>メッセージの送受信ができるようになります。';
  }

  async updateChannelPriority(value) {
    try {
      await ApiHelper.getApi('ChannelApi').updatePriority(this.currentChannel.id, value);
    } catch (error) {
      this.currentChannel.is_high_priority = false;
      Toast.error(error.response.data.message);
    }
  }

  onToggleUserList() {
    MessageModule.toggleUserList(null);
  }

  getChannelUser() {
    this.isLoadingListResv = true;

    const channelId = this.$route.params.channelId;
    const params = {
      with: 'users',
    };

    ApiHelper.getApi('ChannelApi')
      .getOne(channelId, params)
      .then((res: any) => {
        this.getListResv(res.users[0].id);
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
        this.isLoadingListResv = false;
      });
  }

  getListResv(userId: number) {
    if (userId) {
      const params = {
        user_id: userId,
        has_answer: 1,
      };

      ApiHelper.getApi('ResvApi')
        .getList(params)
        .then((res: any) => {
          this.reservations = res.data.map((item: Reservation) => new Reservation().deserialize(item));
        })
        .catch((error) => {
          Toast.error(error.response.data.message);
          this.isLoadingListResv = false;
        })
        .finally(() => {
          this.isLoadingListResv = false;
        });
    }
  }

  chooseResv(resv: Reservation) {
    const open = lodash.get(this.$refs.answerPopup, 'open');

    open(resv.id);

    this.isOpen = false;
  }

  setTagAura(tagIds, tagsDelete) {
    const params = {
      tags: tagIds,
      is_add_and_delete: tagsDelete ? 1 : 0,
      line_id: (this.currentChannel as any).users[0].line_id,
      delete_after_add: tagsDelete || [],
    };
    ApiHelper.getApi('AuraApi').addAuraTag(params);
  }

  finishChannel() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    ApiHelper.getApi('ChannelApi')
      .finishChannel(this.$route.params.channelId)
      .then(() => {
        this.setTagAura([863267, 837089], [837092]);
        Toast.success('診察を終了しました。');
        this.closeChannel = true;
      })
      .catch((error: any) => {
        this.isLoading = false;
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isLoading = false;

        this.$modal.hide('confirmFinishChannel');
      });
  }

  enableChat() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    ApiHelper.getApi('ChannelApi')
      .enableChat(this.$route.params.channelId, {
        status : this.isEnableChat ? 0 : this.currentChannel.suggest_plan_accepted ? 2 : 1,
      })
      .then(() => {
        this.currentChannel.expired_at = this.isEnableChat ? this.currentChannel.expired_at : null;
        // this.getChannelUser();
        this.$emit('onLoadDoctorStatus', this.currentChannel);
      })
      .catch((error: any) => {
        this.isLoading = false;
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isLoading = false;

        this.$modal.hide('confirmEnableChat');
      });
  }

  @Watch('currentChannel.users')
  private setEnable() {
    const valueOrderFirstId = get(this.currentChannel, 'users[0].order_first[0].id');
    if (this.currentChannel.users) {
      this.isEnableChat = (
        this.currentChannel.expired_at === null &&
        this.currentChannel.users &&
        valueOrderFirstId !== null &&
        (this.channelOrderStatus === OrderStatusType.Confirm || this.channelOrderStatus === OrderStatusType.Success )
      );
      this.isDisableChat = (
        this.currentChannel.expired_at !== null &&
        this.currentChannel.users &&
        valueOrderFirstId !== null &&
        (this.channelOrderStatus === OrderStatusType.Confirm || this.channelOrderStatus === OrderStatusType.Success )
      );
    }
  }
}
