import BaseApi from './BaseApi';

class ChannelApi extends BaseApi {
  public updatePriority(channelId: string | number, isHighPriority: boolean) {
    return this.post(`/${channelId}/update-high-priority`, {
      is_high_priority: isHighPriority,
    });
  }

  getResvCalling(channelId: string | number, params?: any) {
    return this.post(`/${channelId}/calling`, params);
  }

  endCalling(channelId: string | number, params?: any) {
    return this.post(`/${channelId}/end-calling`, params);
  }

  finishChannel(channelId: string | number, params?: any) {
    return this.post(`/${channelId}/finish`, params);
  }

  enableChat(channelId: string | number, params?: any) {
    return this.post(`/${channelId}/finish_chat`, params);
  }

  agoraToken(channelId: string | number, params?: any) {
    return this.get(`/${channelId}/agora-token`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/channels';
  }
}

export default ChannelApi;
