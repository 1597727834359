























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { cloneDeep, remove, isEqual } from 'lodash';
import draggable from 'vuedraggable';
import autosize from 'autosize';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faVenus } from '@fortawesome/free-solid-svg-icons';

import ApiHelper from 'api-helper';
import PageLoader from '@components/common/PageLoader.vue';
import Toast from '@common/helpers/toast';
import { Question } from '@/models/question';
import QuestionItem from './QuestionItem.vue';

@Component({
  components: {
    QuestionItem,
    PageLoader,
    draggable,
  },
})
export default class QuestionList extends Vue {
  @Prop({ default: false}) editMode!: boolean;
  @Prop({ default: false}) isCreate!: boolean;

  editingIndex: any = null;
  editingId: any = null;
  listQuestions: Question[] = [];
  oldQuestions: Question[] = [];
  delQuestions: Question[] = [];
  errorQuestions: any[] = [];

  menuId!: number | string;
  loading: boolean = false;
  menuName: string = '';
  autoInterval: any = null;

  get isChangeOldData() {
    return !isEqual(this.oldQuestions, this.listQuestions);
  }

  get hasError() {
    let isHasError = false;
    this.questions.some((question) => {
      const errorQuestion =  this.errorQuestions.find((eq) => eq.id === question.id);
      if (errorQuestion && errorQuestion.hasError) {
        isHasError = true;
      }
    });
    return isHasError;
  }

  async created() {
    this.menuId = this.$route.params.menuId;
    await this.getListQuestion(this.menuId);
    this.autoInterval = setInterval(() => {
      autosize(document.querySelectorAll('textarea'));
      autosize.update(document.querySelectorAll('textarea'));
    }, 100);
  }

  mounted() {
    library.add(faVenus);
    dom.watch();
  }

  beforeDestroy() {
    clearInterval(this.autoInterval);
  }

  onStartDrag() {
    if (this.editingIndex) {
      this.editingId = this.listQuestions[this.editingIndex].id;
    }
  }

  onEndDrag() {
    if (this.editingId) {
      const index = this.listQuestions.findIndex((item) => item.id === this.editingId);
      this.editingIndex = index;
    }
  }

  onError(errorQ) {
    const errorItem = this.errorQuestions.find((item) => item.id === errorQ.id);
    if (errorItem) {
      errorItem.hasError = errorQ.hasError;
    } else {
      this.errorQuestions.push(errorQ);
    }
  }

  closeTab(isReload) {
    localStorage.setItem('isReload', isReload);
    window.close();
  }

  async saveQuestion() {
    try {
      if (this.isMaxImageQuestion) {
        Toast.error('画像アップロード数は5枚以下アップロードできます');
        return;
      }
      const questions = this.questions.map((item, index) => {
        const question =  {
          ...item,
          id: item.is_new ? null : item.id,
          order: index,
        };
        if (['textarea', 'image'].includes(question.type)) {
          delete question.options;
        }
        delete question.is_new;
        return question;
      });
      const idsDeleted = this.delQuestions.map((item) => item.id);
      this.loading = true;
      await ApiHelper.getApi('QuestionApi').updateQuestions({
        menu_id: this.menuId,
        questions,
        ids_delete: idsDeleted,
      });
      this.loading = false;
      const messageToast = this.isCreate ? '登録しました' : '更新しました';
      Toast.success(messageToast);
      setTimeout(() => {
        this.closeTab('true');
      }, 1500);
    } catch (error) {
      this.loading = false;
      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  onEditingItem(question) {
    this.editingIndex = question;
  }

  get questions() {
    return [...this.listQuestions];
  }


  deleteQuestion(question) {
    const index = this.listQuestions.findIndex((q) => (q as any).id === question.id);
    if (index > -1) {
      this.listQuestions.splice(index, 1);
      if (!question.is_new) {
        this.delQuestions.push(question);
      }
    }
    index === 0 ? this.editingIndex = 0 : this.editingIndex = index - 1;
  }

  addQuestion() {
    const question: Question = new Question();
    question.id = +this.maxIndex + 1;
    question.order = +this.maxIndex + 1;

    this.listQuestions.push(question);

    const index = this.questions.findIndex((q) => q.id === question.id);
    this.editingIndex = index;
    this.$nextTick(() => {
      const element = (document.querySelector('.question-active')! as any).offsetTop;
      window.scrollTo(0, element);
    });
  }

  private get isMaxImageQuestion() {
    const imgQuestions = this.questions.filter((question) => question.type === 'image');
    return imgQuestions.length > 5;
  }

  private get maxIndex() {
    if (!this.questions.length) { return  -1; }
    const question = this.questions.reduce((prev, current) => (prev.id > current.id) ? prev : current);
    return question.id;
  }

  private async getListQuestion(menuId) {
    try {
      this.loading = true;
      const data = await ApiHelper.getApi('MedicalMenuApi').getOne(menuId, { with: 'questions'});
      this.menuName = data.name;
      const sortData = data.questions.sort((a, b) => {
        return a.order - b.order;
      });
      this.listQuestions = sortData;
      this.oldQuestions = cloneDeep(this.listQuestions);
      this.loading = false;
    } catch {
      this.loading = false;
    }
  }

}
