











































































































































import ApiHelper from 'api-helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { PLAN_PAYMENT_TYPE } from '@/common/constants/plan';
import moment from 'moment';
import { extend } from 'vee-validate';
import {
  USER_STATE,
  USER_TREATMENT_STATE_CAN_ADD_KARTE,
} from '@/common/constants/auth';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { State } from 'vuex-class';
import { UserTreatment } from '@/models/user-treatment';
import { Patient } from '@/models/patient';
import LodashGet from 'lodash/get';
import { User } from '@/models/user';

extend('notSpecialChar', (text) => {
  const textCompare = text;
  if (text) {
    return textCompare.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    ).length == text.length
      ? true
      : '絵文字または特殊記号を入力しないでください。';
  }
  return true;
});

@Component({
  components: {
    PageLoader,
    PopupConfirm,
  },
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})
export default class PrescriptionForm extends Vue {
  @Prop() changeState: any;

  private get prescriptionFormRef() {
    return this.$refs.prescriptionForm as any;
  }
  @Prop() currentChannelUser!: any;
  // @Prop() changeCurrentChannelUser!: any;
  // @Prop() savedFeedback: any;
  @Prop() listFeedback: any;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;
  @State((state) => state.patient_detail.data) currentPatient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;

  protected PRESCRIPTION_TYPE = {
    PRESCRIPTION: 'prescription',
    NG: 'ng',
  };
  private isSubmitting: boolean = false;
  private isLoading: boolean = false;
  private listPlan: any[] = [];

  private PLAN_PAYMENT_TYPE: any = PLAN_PAYMENT_TYPE;
  private prescriptionForm: any = {
    content: null,
    type: null,
    plans: [],
  };
  private isPrescription: boolean = false;
  private isNG: boolean = false;
  private prescriptionType: string = '';

  mounted() {
    this.getListPlans();
    this.isPrescription = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.PRESCRIPTION;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.PRESCRIPTION;
  }

  private get isDisableCreateFeedback() {
    // return !this.currentChannelUser.state !== USER_STATE.CONFIRMING_PRESCRIPTION;
    return !USER_TREATMENT_STATE_CAN_ADD_KARTE.includes(
      this.activeTreatment.state,
    );
    // return this.currentChannelUser.state == USER_STATE.CONFIRMING_PRESCRIPTION;
  }

  hideModal() {
    if (!this.isSubmitting) {
      this.$modal.hide('prescriptionForm');
      this.$modal.hide('chat-channel-popup'); // hide modal chat question in chat channel
    }
  }

  show(currentPrescription) {
    this.prescriptionForm = currentPrescription;
    this.prescriptionType = currentPrescription.type;
    this.$modal.show('prescriptionForm');
  }

  getListPlans() {
    this.isLoading = true;
    const self = this;
    const payload = { user_treatment_id: this.activeTreatment.id };
    ApiHelper.getApi('PatientV2Api')
      .getPlansByPatientId(this.currentChannelUser.id, payload)
      .then(function(res, error) {
        self.listPlan = res.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  prescriptionHandle() {
    this.isPrescription = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.PRESCRIPTION;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.PRESCRIPTION;
  }

  ngHandle() {
    this.isNG = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.NG;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.NG;
  }

  async savePrescriptionForm() {
    const isValid = await this.prescriptionFormRef.validate();
    if (!isValid) {
      return;
    }
    if (this.listFeedback.length > 0) {
      this.$modal.show('confirmCreateKarte');
      return true;
    }
    this.createKarte();
  }

  async createKarte() {
    const doctorId = LodashGet(
      this.activeTreatment,
      'reservations[0].doctor_id',
      '-',
    );
    if (doctorId !== this.currentUser.id) {
      Toast.error('このユーザーに紐づいていませんので、編集できません。');
      return;
    }

    const data = {
      user_id: this.currentChannelUser.id,
      content: this.prescriptionForm.content,
      plans:
        this.prescriptionForm.type === this.PRESCRIPTION_TYPE.PRESCRIPTION
          ? Array.isArray(this.prescriptionForm.plans)
            ? this.prescriptionForm.plans
            : [this.prescriptionForm.plans]
          : [],
      type: this.prescriptionForm.type,
      user_treatment_id: this.activeTreatment.id,
    };
    this.isSubmitting = true;
    const self = this;
    let feedback = null;

    if (this.prescriptionForm.id) {
      await ApiHelper.getApi('DoctorMouthApi')
        .updateFeedback(this.prescriptionForm.id, data)
        .then((res) => {
          feedback = res.data;
          Toast.success('カルテ作成が完了しました。');
        })
        .catch((error: any) => {
          error.response && Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
        });
    } else {
      await ApiHelper.getApi('PatientV2Api')
        .createFeedback(this.currentPatient.id, data)
        .then(function(res, error) {
          feedback = res.data;
          Toast.success('カルテ作成が完了しました。');
        })
        .catch((error: any) => {
          const {
            response: {
              data: { code, message },
            },
          } = error;
          if (code && code === 'errors.exists_doctor_feedback_for_user') {
            return Toast.error('カルテが既に作成されています。');
          }
          message && Toast.error(message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
          self.$modal.hide('confirmCreateKarte');
        });
    }

    self.$emit('savedFeedback', feedback);
  }

  cancelPopupConfirm() {
    this.$modal.hide('confirmCreateKarte');
  }
}
