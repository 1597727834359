import Index from '@/views/delivery_new/index.vue';
import { authAdmin } from '@common/constants/auth';

export default [
  {
    path: '/delivery-list-ec-force',
    name: 'delivery-list-ec-force',
    component: Index,
    meta: authAdmin,
  },
];
