import { Pharmacy } from './../models/pharmacist';
import CreatePharmacist from '@/views/pharmacist/Create.vue';
import UpdatePharmacist from '@/views/pharmacist/Update.vue';
import Index from '@/views/pharmacist/Index.vue';
import { Roles } from '@common/app.config';
import PharmacistProfile from '@/views/pharmacist/Profile.vue';

const authMeta =  {
  auth: true,
  authorize: [Roles.superAdmin],
};

export default [
  {
    path: '/pharmacists',
    name: 'pharmacists',
    component: Index,
    meta: authMeta,
  },
  {
    path: '/pharmacists/create',
    name: 'pharmacists.create',
    component: CreatePharmacist,
    meta: authMeta,
  },
  {
    path: '/pharmacists/detail/:id',
    name: 'pharmacists.detail',
    component: UpdatePharmacist,
    meta: authMeta,
  },
  {
    path: '/pharmacist-profile',
    name: 'pharmacist.profile',
    component: PharmacistProfile,
    meta: {
      auth: true,
      authorize: [Roles.pharmacist],
    },
  },
];
