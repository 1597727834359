



































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { required } from 'vee-validate/dist/rules';
import ApiHelper from 'api-helper';
import { mapState } from 'vuex';
import Toast from '@common/helpers/toast';
import CustomErrorMessage from '@/common/mixins/CustomErrorMessage';
import { AuthenticationModule } from '@/store';
import AuthenticationUtils from '@common/AuthenticationUtils';


@Component({
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
  mixins: [CustomErrorMessage],
})

export default class EditPassword extends Vue {
  @Prop(String) readonly name!: string;
  private isSubmiting = false;
  private currentUser!: any;
  private passwordChange = {};

  private async beforeOpen() {
    this.passwordChange = {
      old_password: '',
      password: '',
      password_confirmation: '',
    };
  }

  private get computedForm() {
    return this.$refs.passwordChange as any;
  }

  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  private async changePassword() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    try {
      this.updateIsSubmiting(true);
      const response = await ApiHelper.getApi('DoctorApi').updatePassword(this.currentUser.id, this.passwordChange);
      const {user_id, access_token} = response;
      AuthenticationUtils.updateAccessToken(access_token);
      this.updateIsSubmiting(false);
      Toast.success('更新しました');
      this.$modal.hide(this.name);
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

}
