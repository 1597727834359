















































import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faVenus } from '@fortawesome/free-solid-svg-icons';

import PageLoader from '@/components/common/PageLoader.vue';
import ApiHelper from 'api-helper';
import { ResvModule } from '@/store';
import Toast from '@/common/helpers/toast';
import { Reservation } from '@/models/reservation';
import { Question } from '@/models/question';
import { Menu } from '@/models/menu';
import { Answer } from '@/models/answer';

@Component({
  components: {
    PageLoader,
  },
})
export default class Answers extends Vue {
  resv: Reservation = new Reservation();
  answers: Answer[] = [];

  isLoading: boolean = false;

  mounted() {
    library.add(faCheckSquare);
    library.add(faVenus);
    dom.watch();

    this.getResvDetail();
  }

  closeTab(isReload) {
    window.close();
  }

  getResvDetail() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const params: { with: string } = {
      with: 'user,menu,answers',
    };
    const {id} = this.$route.params;

    ApiHelper.getApi('ResvApi')
      .getOne(id, params)
      .then((res: Reservation) => {
        this.resv = new Reservation().deserialize(res);
        this.answers = this.resv.answers;
        this.isLoading = false;
      })
      .catch((error: any) => {
        this.isLoading = false;
        Toast.error(error.response.data.message);
      });
  }
}
