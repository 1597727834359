












import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({
  components: {},
})

export default class LazyloadImage extends Vue {
  @Prop() readonly imgUrl!: string;

  private isLoaded: boolean = false;

  async mounted() {
    this.isLoaded = false;
    await this.addImageProcess(this.imgUrl);
    this.isLoaded = true;
  }

  private addImageProcess(src) {
    if (src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = reject;
        img.src = src;
      });
    }
  }
}
