
























import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import NotificationForm from '@components/Notifications/NotificationForm.vue';
import PageLoader from '@components/common/PageLoader.vue';
import { INotification } from '../../models/notification';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import moment from 'moment';
import { autoDeletePropertyEmpty } from '@common/utils';

@Component({
  components: {
    NotificationForm,
    PageLoader,
  },
})
export default class NotificationCreate extends Vue {
  private notification: Partial<INotification> = {
    title: '',
    content: '',
    schedule_time: '',
    status: '',
  };
  private isLoading: boolean = false;
  private isValid: boolean = true;
  private isSendNow: boolean = true;

  private created() {
    const { notification } = this.$route.params;
    if (notification) {
      const { title, content } = (notification as any);
      this.notification.title = title;
      this.notification.content = content;
    }
  }

  private onChangeSendStatus(isSendNow: boolean) {
    this.isSendNow = isSendNow;
  }

  private get isDisableBtn() {
    return !this.notification.title || !this.notification.content || !this.isValid;
  }

  private get isDisableBtnDraft() {
    return !this.notification.title || !this.isValid;
  }

  private onValidate(isValid: boolean) {
    this.isValid = isValid;
  }

  private onChangeForm(params) {
    this.notification = params;
  }

  private async createNotify(status) {
    try {
      let time = '';
      if (this.notification.schedule_time && !this.isSendNow) {
        time = this.notification.schedule_time!.replace(/\//g, '-');
        if (new Date(time) < new Date()) {
          Toast.error('未来の時間で設定してください');
          return;
        }
      }
      this.isLoading = true;
      await ApiHelper.getApi('NotificationApi').create({
        ...autoDeletePropertyEmpty(this.notification),
        schedule_time: time,
        status,
      });
      this.isLoading = false;
      Toast.success('登録しました');
      this.$router.push({ name: 'notifications' });
    } catch (error) {
      this.isLoading = false;
      if (error.message === 'Network Error') {
        Toast.error('インターネットに接続できませんでした');
        return;
      }

      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }
}
