














































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import {ModelPatientDetail} from '@/models/patient';
import imageDefault from '@/assets/img/icon-no-image.svg';
import moment from 'moment';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    ImagePrivate,
  },
  filters: {
    formatDate(value) {
      return moment(value).format('YYYY年MM月DD日');
    },
    getStatusValue(value) {
      return value ? 'OFF' : 'ON';
    },
  },
})
export default class PatientHeader extends Vue {
  @Prop(String) readonly avatar!: string;
  @Prop(Number) readonly tabActive: number;
  @Prop(Object) readonly dataDetail!: ModelPatientDetail;
  @Prop(Boolean) readonly isAdmin!: false;
  private src = '';
  private receiveToothPattern = false;
  private imageDefault = imageDefault;

  updated() {
    this.src = this.avatar;
  }

  @Watch('dataDetail')
  private listMouthImageData() {
    this.receiveToothPattern = !(this.dataDetail as any).require_kit_at;
  }

  private handleChangeTab(id) {
    this.$emit('changeTab', id);
  }

  private handleDeleteuser() {
    this.$emit('confirmDeleteuser');
  }

  private goToImageResv() {
    this.$emit('changeTab', 2);
  }

  private goToFeedBack() {
    this.$emit('changeTab', 3);
  }

  private goToChat(dataDetail) {
    (this.$router as any).push({
      name: 'MessageBox',
      params: {
        channelId: (dataDetail.channel || {}).id,
        patient: {
          name: dataDetail.name,
          id: dataDetail.id,
          avatar: dataDetail.avatar,
        },
        channel: dataDetail.channel,
      },
    });
  }

  private changeStatusReceive() {
    const { id } = this.$route.params;
    const body = {
      user_id: id,
      status_require: +!this.receiveToothPattern,
    };
    ApiHelper.getApi('DoctorMouthApi')
      .changeStatusReceiveTooth(body)
      .then()
      .catch((error) => {
        Toast.error(error.message);
      })
      .finally();
  }

  private reloadUser() {
    if (this.tabActive === 1) {
      this.$emit('reloadData');
    } else {
      this.$emit('changeTab', 1);
    }
  }
}
