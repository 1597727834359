import BaseApi from './BaseApi';

class MedicalMenuApi extends BaseApi {
  delete(id: number, params?: any) {
    return this.del(`/${id}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/menus';
  }
}

export default MedicalMenuApi;
