












































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import { ToggleButton } from 'vue-js-toggle-button';
import PageLoader from '@/components/common/PageLoader.vue';

@Component({
  components: {
    PageLoader,
  },
})
export default class CreateNews extends Vue {

  private get isContentError() {
    return this.contentLength > 10000;
  }

  private get contentLength() {
    const imgLength = (this.content.match(/<img/g) || []).length;
    const cleanContent = this.content
      .replace(/<(?:.|\n)*?>/gm, '')
      .replace(/(\r\n|\n|\r)/gm, '')
      .replace(/&nbsp;/g, '').trim();
    return cleanContent.length + imgLength;
  }

  private get isDisabledBtn() {
    return !this.title || !this.content || this.isContentError || !this.contentLength;
  }

  @Prop({default: '新着情報作成'}) pageTitle!: string;
  @Prop({default: false}) isDetail!: boolean;

  private editorUrl = 'https://cdn.ckeditor.com/4.14.1/full-all/ckeditor.js';
  private isLoading: boolean = false;
  private newDetail: any = {};
  private content = '';
  private oldContent = '';
  private isFocus = false;
  private isShowPlaceholder: boolean = true;
  private title = '';
  private isPublic = true;
  private isLoaded = false;
  private editorConfig = {
    language: 'ja',
    font_names:
      'Noto San JP/Noto Sans JP, sans-serif;' +
      'Arial/Arial, Helvetica, sans-serif;' +
      'Comic Sans MS/Comic Sans MS, cursive;' +
      'Courier New/Courier New, Courier, monospace;' +
      'Georgia/Georgia, serif;' +
      'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' +
      'Tahoma/Tahoma, Geneva, sans-serif;' +
      'Times New Roman/Times New Roman, Times, serif;' +
      'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' +
      'Verdana/Verdana, Geneva, sans-serif',
    toolbarGroups : [
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
      { name: 'forms', groups: [ 'forms' ] },
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
      { name: 'links', groups: [ 'links' ] },
      { name: 'insert', groups: [ 'insert' ] },
      { name: 'colors', groups: [ 'colors' ] },
      { name: 'styles', groups: [ 'styles' ] },
      { name: 'tools', groups: [ 'tools' ] },
      { name: 'others', groups: [ 'others' ] },
      { name: 'about', groups: [ 'about' ] },
    ],
    removeButtons:
      `Radio,Preview,Maximize,NewPage,Print,Save,Templates,` +
      `Replace,Find,SelectAll,Scayt,Form,TextField,Textarea,` +
      `Select,Button,ImageButton,HiddenField,CreateDiv,Anchor,` +
      `Flash,Smiley,PageBreak,ShowBlocks,About,Language,Iframe,` +
      `Paste,PasteText,PasteFromWord`,
  };


  @Watch('title')
  onChangeTitle(value) {
    if (!this.isDetail) {
      if (value !== '') {
        this.$emit('change', true);
      }
    } else {
      if (value !== this.newDetail.title) {
        this.$emit('change', true);
      }
    }
  }

  @Watch('isPublic')
  onChangePubLic(value) {
    if (this.isDetail) {
      const isPublic = !!this.newDetail.published_at;
      if (value !== isPublic) {
        this.$emit('change', true);
      }
    } else {
      this.$emit('change', true);
    }
  }

  @Watch('content')
  onChangeContent(value) {
    if (!this.isDetail) {
      this.$emit('change', true);
    } else {
      if (
        value !== this.newDetail.content &&
        value !== `${this.newDetail.content}\n`
      ) {
        this.$emit('change', true);
      }
    }
  }

  private onEditorBlur() {
    if (this.content !== '') {
      this.isShowPlaceholder = false;
    } else {
      this.isShowPlaceholder = true;
      this.isFocus = true;
    }
  }

  private onEditorFocus() {
    this.isShowPlaceholder = false;
  }

  private saveNews() {
    if (this.isDetail) {
      this.saveNewsDetail();
    } else {
      this.createNews();
    }
  }

  private async saveNewsDetail() {
    try {
      this.$emit('change', false);
      this.isLoading = true;
      await await ApiHelper.getApi('NewsApi').update(this.newDetail.id, {
        title: this.title,
        content: this.content,
        is_published: this.isPublic === true ? 1 : 0,
      });
      this.$router.push({
        name: 'settings',
        query: { tab: 'news'},
      });
      Toast.success('更新しました');
    } catch (errors) {
      Toast.error(errors.response.data.message);
    } finally {
      this.isLoading = false;
    }
  }

  private created() {
    if (this.isDetail) {
      this.getNewDetail(this.$route.params.id);
    }
  }

  private async getNewDetail(id: string|number) {
    try {
      this.isLoading = true;
      const data = await ApiHelper.getApi('NewsApi').getOne(id);
      this.newDetail = data;
      this.title = data.title;
      this.content = data.content;
      this.isShowPlaceholder = false;
      this.isPublic = data.published_at ? true : false;
    } catch (error) {
      Toast.error(error.response.data.message);
    } finally {
      this.isLoading = false;
      this.isLoaded = true;
    }
  }

  private async createNews() {
    try {
      this.$emit('change', false);
      this.isLoading = true;
      await ApiHelper.getApi('NewsApi').create({
        title: this.title,
        content: this.content,
        is_published: this.isPublic === true ? 1 : 0,
      });
      this.$router.push({
        name: 'settings',
        query: { tab: 'news'},
      });
      Toast.success('登録しました');
    } catch (errors) {
      Toast.error(errors.response.data.message);
    } finally {
      this.isLoading = false;
    }
  }
}
