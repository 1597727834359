










































































import { Vue, Prop, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Plan } from '@/models/plan';
import { Roles } from '../../common/app.config';
import ApiHelper from 'api-helper';
import Toast from '@/common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import imageDefault from '@/assets/img/icon-no-image.svg';

@Component({
  components: {
    PageLoader,
  },
})
export default class PlanDetail extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @State((state) => state.plan.plan) plan: Plan;
  imagePreview: string | File = this.$store.state.plan.plan.image_url;
  isLoading: boolean = false;
  private medicineNames = this.$store.state.plan.plan.medicines;

  get src() {
    return this.imagePreview || imageDefault;
  }

  backToCreate() {
    this.$router.push({ name: 'plans.create', params: { isConfirm: '1' } });
  }

  createPlan() {
    if (this.isLoading) {
      return;
    }
    let formData = this.plan.formJSONData(false);
    if (this.plan.image) {
      formData = this.plan.formJSONDataHaveImage(false);
    }

    this.isLoading = true;

    ApiHelper.getApi('PlanApi')
      .create(formData)
      .then((response) => {
        Toast.success('更新しました');
        this.$router.push({ name: 'plans' });
      })
      .catch((error) => {
        Toast.error('更新に失敗しました');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  mounted() {
    if (!this.plan.name) {
      this.$router.push({ name: 'plans.create' });
    }
  }
}
