























































































import { Vue, Component } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import { State } from 'vuex-class';
import planList from '@/assets/img/plan-list.svg';
// Utils
import Toast from '@common/helpers/toast';
// Components
import ContentLayout from '@components/layouts/Content.vue';
// Icons
import { Menu } from '@/models/menu';

import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import BaseInput from '@/components/common/BaseInput.vue';
import FormAction from '@/components/common/FormAction.vue';
import PageLoader from '@components/common/PageLoader.vue';
import iconTrash from '@/assets/img/icon-trash.svg';

@Component({
  components: {
    ContentLayout,
    FormAction,
    PageLoader,
    BaseInput,
  },
})
export default class Precaution extends Vue {
  iconHeader: any = planList;
  isSubmitting = false;
  isLoading: boolean = false;
  isChanged: boolean = false;
  diagnosis: Menu = new Menu();

  precautions: any[] = [];
  precautionIdsDelete: number[] = [];
  precautionsName: string = '';
  isNewPrecaution: boolean = false;

  @State((state) => state.auth.currentUser) currentUser!: any;

  get disabledBtnCancel() {
    return false;
  }

  get disabledBtnSubmit() {
    let isHasNullData = false;

    this.precautions.length > 0 && this.precautions.map((pre) => {
      if (!pre.content) {
        isHasNullData = true;
      }
    });
    return this.precautions.length > 0 && isHasNullData;
  }

  get optionErrorNumberOfMedicalInputMessages() {
    return {
      max: '255文字以内で入力してください',
      required: '注意事項を入力してください',
    };
  }

  get computedForm() {
    return this.$refs.precautionForm as any;
  }

  get isSuperAdminRole() {
    return this.currentUser.isSuperAdminRole;
  }

  get diagnosisId() {
    return this.$route.params?.id;
  }

  get iconTrash() {
    return iconTrash;
  }

  handleDeletePrecaution(deleteIndex) {
    const newPrecautions = this.precautions.filter((x, index) => index !== deleteIndex);
    this.precautions = newPrecautions;
  }

  handleAddPrecaution() {
    this.precautions.push({
      id: '',
      content: '',
      position: this.precautions.length,
    });

    const index = this.precautions.length - 1;
    [this.precautions[index], this.precautions[index - 1]] = [this.precautions[index - 1], this.precautions[index]];
    this.precautions[index].position = index + 1;
  }

  handleUpdatePrecaution(id, $event) {
    const newPrecautions = [...this.precautions];
    newPrecautions[id].content = $event;

    this.precautions = newPrecautions;
  }

  async created() {
    this.getListPrecautions();
    library.add(faCameraRetro);
    dom.watch();
  }

  // update precaution
  async getListPrecautions() {
    try {
      const res = await ApiHelper.getApi('PrecautionApi').getList(this.diagnosisId);
      this.precautions = res.data || [];
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
      }
    }
  }

  async handleSubmit() {
    console.log(this.precautions);
    if (this.precautions.length === 0) {
      const params = {
        menu_id: Number(this.diagnosisId),
        precautions: [],
      };
      try {
        this.updateIsSubmitting(true);
        await ApiHelper
          .getApi('PrecautionApi')
          .precaution(params);
        this.updateIsSubmitting(false);
        this.updateIsChanged(false);
        Toast.success('変更しました');
        this.handleCancel();
      } catch (error) {
        this.updateIsSubmitting(false);
        this.updateIsChanged(false);
        const data = error.response.data;
        Toast.error(data.message);
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
      }
    } else {
      const valid = await this.computedForm.validate();
      if (valid) {
        let newPrecaution: any[] = [];
        const mergePrecautions = [...this.precautions];
        if (mergePrecautions.length > 0) {
          mergePrecautions.map((pre, index) => {
            newPrecaution = [...newPrecaution, {id: '', content: pre.content, position: index + 1}];
          });
        }
        const params = {
          menu_id: Number(this.diagnosisId),
          precautions: newPrecaution,
        };
        try {
          this.updateIsSubmitting(true);
          await ApiHelper
            .getApi('PrecautionApi')
            .precaution(params);
          this.updateIsSubmitting(false);
          this.updateIsChanged(false);
          Toast.success('変更しました');
          this.handleCancel();
        } catch (error) {
          this.updateIsSubmitting(false);
          this.updateIsChanged(false);
          const data = error.response.data;
          Toast.error(data.message);
          if (error.response.status === 422) {
            this.computedForm.setErrors(error.response.data.errors);
            return;
          }
        }
      }
    }
  }

  private mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  private updateIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  private updateIsChanged(status: boolean) {
    this.isChanged = status;
  }

  private handleCancel() {
    return this.$router.push({
      name: 'diagnosis.detail',
      params: { id: this.diagnosisId },
    });
  }
}
