
















































































































































































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import { required, email, min, max, regex, digits, confirmed, numeric } from 'vee-validate/dist/rules';
import Toast from '@common/helpers/toast';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {faCameraRetro} from '@fortawesome/free-solid-svg-icons';
import { extend } from 'vee-validate';

import ApiHelper from 'api-helper';
import AuthenticationUtils from '@common/AuthenticationUtils';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import UploadImage from '@/components/common/UploadImage.vue';
import SelectSearchBank from '@/components/Clinic/SelectSearchBank.vue';
import SelectSearchBranch from '@/components/Clinic/SelectSearchBranch.vue';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';
import { config, TYPE_BANK } from '@/common/app.config';
import { ClinicModule, BankJPModule } from '@/store';
import Listicon from '@/assets/img/clinicListicon.svg';
import ContentLayout from '@components/layouts/Content.vue';

@Component({
  head: {
    title: {
      inner: 'クリニックアカウント新規作成',
    },
  },
  components: {
    PopupConfirm,
    UploadImage,
    SelectSearchBank,
    SelectSearchBranch,
    ContentLayout,
  },
  data() {
    return {
      prefectures: [],
      cities: [],
      iconHeader: Listicon,
    };
  },
})

export default class Create extends Mixins(ClinicsMixin) {
  public iconHeader: any;
  public isSubmiting = false;
  public listPrefecture: any[] = [];
  public ruleNumCard = { numeric: true, min: 1, max: 7 };
  public ruleHolderCard = { max: 30, onlyText: true };
  public ruleTypeCard = {};
  public createClinicForm =  {
    medical_institution_code: '',
    medical_department: '',
    uuid: '',
    name: '',
    name_kana: '',
    email: '',
    phone: '',
    fax: '',
    postal_code: '',
    prefecture_id: '',
    city_id: '',
    address: '',
    building_name: '',
    introduction: '',
    manager_name: '',
    manager_name_kana: '',
    position: '',
    manager_phone: '',
    manager_email: '',
    password: '',
    password_confirmation: '',
    bank: {
      code: '',
      name: '',
      nameHalfKana: '',
      nameFullKana: '',
      nameFullHira: '',
    },
    branch: {
      code: '',
      name: '',
      nameHalfKana: '',
      nameFullKana: '',
      nameFullHira: '',
    },
    type: '',
    number: '',
    holder_name: '',
    gtm_id: '',
  };
  public extend: any = extend;
  public imgChoosen = '';
  public bankChoosend = false;
  public branchChoosend = false;
  public isHasResultBank = false;
  public isHasResultBranch = false;
  public typeValidate: string = 'input';
  public input() {
    return {
      on: ['input'],
    };
  }

  public get listPrefectureOption() {
    return this.listPrefecture.map((prefecture) => {
      return {
        title: prefecture.ja_name,
        id: `${prefecture.id}`,
      };
    });
  }

  public get listTypeOption() {
    return [
      {title: 'クリニック', id: `clinic`},
      {title: 'サロン', id: `salon`},
      {title: '診察クリニック', id: `clinic_doctor`},
    ];
  }

  public openCancelCreateClinic() {
    return this.$router.push({name: 'clinics'});
  }

  public async createClinic() {
    this.typeValidate = 'click';
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    try {
      this.updateIsSubmiting(true);
      // const re = /^0/gi;
      const cpForm = Object.assign({}, this.createClinicForm);
      // cpForm.phone = cpForm.phone.replace(re, '81');
      // cpForm.manager_phone = cpForm.manager_phone.replace(re, '81');
      // cpForm.fax = cpForm.fax.replace(re, '81');
      const params = this.prepareFormData(cpForm);
      const response = await ClinicModule.create(params);
      this.updateIsSubmiting(false);
      this.$router.push({ name: 'clinics' });
      Toast.success('登録しました');
    } catch (error) {
      this.updateIsSubmiting(false);
      this.toast(error);
    }
  }

  private toast(error: any) {
    const data = error.response.data;
    Toast.error(data.message);
    if (error.response.status === 422) {
      this.computedForm.setErrors(error.response.data.errors);
      return;
    }
  }

  private get computedForm() {
    return this.$refs.createClinicForm as any;
  }

  private get typeBank() {
    return TYPE_BANK;
  }

  private getBank(item) {
    this.$set(this.createClinicForm, 'bank', item);
    this.createClinicForm.branch = {
      code: '',
      name: '',
      nameHalfKana: '',
      nameFullKana: '',
      nameFullHira: '',
    };

    BankJPModule.resetBranch();
  }

  private getBranch(item) {
    this.$set(this.createClinicForm, 'branch', item);
  }

  private notChoosendBank(word) {
    if (this.createClinicForm.bank.name !== word) {
      this.createClinicForm.bank = {
        code: '',
        name: '',
        nameHalfKana: '',
        nameFullKana: '',
        nameFullHira: '',
      };

      this.createClinicForm.branch = {
        code: '',
        name: '',
        nameHalfKana: '',
        nameFullKana: '',
        nameFullHira: '',
      };
      this.isHasResultBank = false;

      return;
    }
  }

  private notChoosendBranch(word) {
    if (this.createClinicForm.branch.name !== word) {
      this.createClinicForm.branch = {
        code: '',
        name: '',
        nameHalfKana: '',
        nameFullKana: '',
        nameFullHira: '',
      };
      this.isHasResultBranch = false;
      return;
    }
  }

  @Watch('createClinicForm.branch.code')
  private watchBranchChoosend() {
    this.branchChoosend = true;
  }

  private get dataBank() {
    return BankJPModule.dataBank;
  }

  private get dataBankBranch() {
    return BankJPModule.dataBankBranch;
  }
  private updateFormCreate(field: string, value: any) {
    if (field === 'image') {
      this.createClinicForm = Object.assign(this.createClinicForm, {image: value});
      this.imgChoosen = value;
    } else {
      this.$set(this.createClinicForm, field, value);
    }
  }

  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  private prepareFormData(data: any) {
    const formData = new FormData();
    if (data.image) {
      formData.append('image', data.image);
    }
    // formData.append('medical_institution_code', data.medical_institution_code);
    // formData.append('medical_department', data.medical_department);
    formData.append('name', data.name);
    formData.append('name_kana', data.name_kana);
    // formData.append('email', data.email);
    formData.append('phone', data.phone);
    // formData.append('fax', data.fax);
    formData.append('postal_code', data.postal_code);
    formData.append('prefecture_id', data.prefecture_id);
    formData.append('city_id', data.city_id);
    formData.append('address', data.address);
    formData.append('building_name', data.building_name);
    formData.append('type', data.type);
    formData.append('uuid', data.uuid);
    formData.append('gtm_id', data.gtm_id);
    // formData.append('introduction', data.introduction);
    // formData.append('manager_name', data.manager_name);
    // formData.append('manager_name_kana', data.manager_name_kana);
    // formData.append('position', data.position);
    // formData.append('manager_phone', data.manager_phone);
    // formData.append('manager_email', data.manager_email);
    // formData.append('password', data.password);
    // formData.append('password_confirmation', data.password_confirmation);
    // if (this.isHasResultBank) {
    //   formData.append('bank_code', data.bank.code);
    //   formData.append('bank_name', data.bank.name);
    // }

    // if (data.branch.code) {
    //   formData.append('branch_code', data.branch.code);
    //   formData.append('branch_name', data.branch.name);
    // }
    // formData.append('type', data.type);
    // formData.append('number', data.number);
    // formData.append('holder_name', data.holder_name);

    return formData;
  }

  private async searchZipcode() {
    try {
      const params = {
        postal_code: this.createClinicForm.postal_code,
      };
      const result = await ApiHelper.getApi('ZipcodeApi').getList(params);
      this.updateFormCreate('prefecture_id', result.address1);
      this.updateFormCreate('city_id', result.address2);
      this.updateFormCreate('address', result.address3);
      return true;
    } catch (error) {
      return false;
    }
  }

  private cancelPopupConfirm() {
    this.$modal.hide('confirmCreateClinic');
  }

  private cancelPopupConfirmCancelClinic() {
    this.$modal.hide('confirmCancelCreateClinic');
  }

  private hasResultBank(check) {
    if (!this.createClinicForm.bank.code) {
      this.isHasResultBank = false;
    } else {
      this.isHasResultBank = check;
    }
  }

  private hasResultBranch(check) {
    if (!this.createClinicForm.branch.code) {
      this.isHasResultBranch = false;
    } else {
      this.isHasResultBranch = check;
    }
  }

  @Watch('createClinicForm.bank.code')
  private wBankCode() {
    if (this.createClinicForm.bank.code) {
      this.bankChoosend = true;
      this.isHasResultBank = true;
      (this.ruleNumCard as any) = { ...this.ruleNumCard, required: true };
      (this.ruleHolderCard as any) = { ...this.ruleHolderCard, required: true };
      (this.ruleTypeCard as any) = { ...this.ruleTypeCard, required: true };
    } else {
      this.ruleNumCard = { numeric: true, min: 1, max: 7 };
      this.ruleHolderCard = { max: 30, onlyText: true };
      this.ruleTypeCard = {};
    }
  }

  private checkType(id) {
    if (id === this.createClinicForm.type) {
      this.createClinicForm.type = '';
    }
  }

  private async created() {
    BankJPModule.listBank({});
    this.extend('postal_code', {
      validate: (value: string) => {
        if (this.typeValidate === 'click') {
          return this.computedForm.refs.postal_code.flags.valid;
        }
        return this.searchZipcode();
      },
      message: '住所が見つかりません',
    });
    library.add(faCameraRetro);
    dom.watch();

    try {
      this.listPrefecture = (await ApiHelper.getApi('PrefectureApi').listPrefecture()).data;
    } catch (error) {
      console.log(error);
    }
  }
}
