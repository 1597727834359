import Vue from 'vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {faTimes,
        faExclamationTriangle,
        faCheckDouble,
        faInfo,
        faInfoCircle,
      } from '@fortawesome/free-solid-svg-icons';

class Toast {
  private toasted: any;
  constructor() {
    this.toasted = Vue.toasted;
    library.add(faTimes);
    library.add(faExclamationTriangle);
    library.add(faCheckDouble);
    library.add(faInfo);
    library.add(faInfoCircle);
    dom.watch();
  }

  public success(message: string, options = {}) {
    this.toasted.success(this._renderText(message), Object.assign({
      icon: 'fa-check-double',
      className: 'word-break-all',
    }, options));
  }

  public link(message: string, options = {}) {
    this.toasted.success(this._renderText(message), Object.assign({
      icon: 'fa-info-circle',
    }, options));
  }

  public error(message: any, options = {}) {
    let msg: string = '';

    switch (message.constructor) {
      case String:
        msg = message;
        break;
      case Object:
        if (message.hasOwnProperty('message')) {
          msg = message.message;
          break;
        }
        break;
      case Array:
        if (message.length > 0) {
          msg = message[0];
        }
        break;
    }

    if (!msg) {
      return;
    }

    this.toasted.error(this._renderText(msg), Object.assign({
      icon: 'fa-times',
      className: 'word-break-all',
    }, options));
  }

  public info(message: string, options = {}) {
    return this.toasted.info(this._renderText(message), Object.assign({
      icon: 'fa-info',
    }, options));
  }

  public warning(message: string, options = {}) {
    return this.toasted.show(this._renderText(message), Object.assign({
      className: 'bg-warning',
      icon: 'fa-exclamation-triangle',
    }));
  }

  public show(message: string, options = {}) {
    return this.toasted.show(this._renderText(message), options);
  }

  public clear() {
    this.toasted.clear();
  }

  private _renderText(s: string): string {
    return s.replace('\n', '<br>');
  }
}

export default new Toast();
