












































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import moment from 'moment';
import { get, sortBy, find } from 'lodash';

import Toast from '@common/helpers/toast';
import { USER_STATUS } from '@/common/constants/auth';
import PageLoader from '@components/common/PageLoader.vue';
import BasicModal from '@components/common/BasicModal.vue';
import { Roles } from '../../common/app.config';
import PrescriptionPopup from '@components/messages/partials/PrescriptionPopup.vue';

@Component({
  components: {
    PageLoader,
    BasicModal,
    PrescriptionPopup,
  },
  computed: {
  },
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})

export default class QuestionInfoChannel extends Vue {
  @Prop() activeTab!: any;
  @Prop() patientAnswer!: any;
  @Prop() patientQuestion!: any;
  @Prop() changeCurrentChannelUser: any;
  @Prop() currentChannelUser: any;
  @Prop() currentUser: any;
  @Prop() savedFeedback: any;
  private USER_STATUS = USER_STATUS;
  private feedbackForm: any = {};
  private listPlan: any[] = [];
  private isLoadingPlan: boolean = false;
  private isLoading: boolean = false;
  private listFeedback: any[] = [];
  private isSubmitting: boolean = false;
  private isChoiceInterview: boolean = true;
  private isChangeState: boolean = false;

  created() {
    this.getListFeedback();
  }

  getListFeedback() {
    this.isLoading = true;
    const self = this;
    ApiHelper.getApi('DoctorMouthApi').getListFeedback(this.currentChannelUser.id)
      .then(function(res, error) {
        self.listFeedback = res.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  async saveFeedbackForm() {
    const isValid = await this.feedbackFormRef.validate();
    if (!isValid) {
      return;
    }
    const data = {
      user_id: this.currentChannelUser.id,
      content: this.feedbackForm.content,
      plan_id: this.feedbackForm.plan_id != 'plan_ng' ? this.feedbackForm.plan_id : null,
    };
    this.isSubmitting = true;
    const self = this;
    if (!this.feedbackForm.id) {
      await ApiHelper.getApi('DoctorMouthApi').createFeedback(data)
        .then(function(res, error) {
          self.getListFeedback();
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('feedbackForm');
        });
    } else {
      await ApiHelper.getApi('DoctorMouthApi').updateFeedback(this.feedbackForm.id, data)
        .then(function(res, error) {
          self.getListFeedback();
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('feedbackForm');
        });
    }

    ApiHelper.getApi('ChannelApi').getOne(this.$route.params.channelId, { with: 'users,latest_message' })
      .then(function(res, error) {
        self.$store.commit('channel/updateChannel', res);
        // self.currentChannelUser.state = get(res, 'users[0].state');
        const newChannelUser = {...self.currentChannelUser};
        newChannelUser.state = get(res, 'users[0].state');
        self.changeCurrentChannelUser(newChannelUser);
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      });
  }

  onChangeUserStatus(status) {
    if (!status || !this.currentChannelUser) {
      return;
    }

    this.isChangeState = true;
    ApiHelper.getApi('UserApi')
      .changeStatus(this.currentChannelUser.id, {state: status})
      .then((res: any) => {
        this.changeCurrentChannelUser(res);
        Toast.success('患者に通知しました。');
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isChangeState = false;
      });
  }

  openForm() {
    this.$modal.show('prescriptionForm');
  }

  isDisabledAfterChoose(status) {
    return this.currentChannelUser?.state !== status &&
      this.currentChannelUser?.state !== USER_STATUS.CHOICE_INTERVIEW_METHOD;
  }

  isDoctor() {
    return this.currentUser && this.currentUser.role === Roles.doctor;
  }

  // onUpdateUserState() {
  //   console.log('here')
  //   this.isChoiceInterview = false
  // }

  private changeState() {
    this.isChangeState = true;
  }

  private get stateNotice() {
    if (this.currentChannelUser && this.currentChannelUser.state === USER_STATUS.CHOICE_INTERVIEW_METHOD) {
      return '診療方法を選択してください';
    }
    if (this.currentChannelUser && this.currentChannelUser.state === USER_STATUS.CHAT_INTERVIEW_SCHEDULED) {
      return 'チャット診療中';
    }
    if (this.currentChannelUser && this.currentChannelUser.state === USER_STATUS.VIDEO_INTERVIEW_SCHEDULED) {
      return 'ビデオ診療';
    }
    if (this.currentChannelUser && this.currentChannelUser.state === USER_STATUS.CONFIRMING_PRESCRIPTION) {
      return '診療完了';
    }

    return '診療方法を選択してください';
  }

  private get feedbackFormRef() {
    return this.$refs.feedbackForm as any;
  }

  private get isDisableInterview() {
    return this.currentChannelUser.state !== USER_STATUS.CHOICE_INTERVIEW_METHOD;
  }

  private getAnswer(id) {
    return find(this.patientAnswer, (data) => data && data.question.id === id);
  }

  private patientQuestionSort() {
   return sortBy(this.patientQuestion, 'order');
  }

}
