






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { dom } from '@fortawesome/fontawesome-svg-core';

@Component
export default class PopupZoomImage extends Vue {
  @Prop({ default: 'preview-image-zoom' }) name!: string;
  @Prop(String) thumbnail!: string;
  private src: string = '';

  private isLoadModal: boolean = false;

  private isLoading: boolean = false;

  private async beforeOpen(event) {
    if (event.params.src) {
      this.src = event.params.src;
      this.isLoadModal = true;
    }
  }

  private created() {
    dom.watch();
    this.isLoading = true;
  }
}
