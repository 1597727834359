import BaseApi from './BaseApi';

class ClinicApi extends BaseApi {
  public list(params: any) {
    return this.get('/', params);
  }

  public show(clinicId: any, params: any) {
    params = {
      with: 'managers',
      ...params,
    };
    return this.get(`/${clinicId}`, params);
  }

  public create(params: any) {
    return this.postWithFile('/', params);
  }

  public update(clinicId: number, params: any) {
    return this.patch(`/${clinicId}`, params);
  }

  public updateQuestions(params: any) {
    return this.post('/questions', params);
  }

  public resetPassword(clinicId: number, params: any) {
    return this.post(`/${clinicId}/change-password`, params);
  }

  public updateImage(clinicId: number, params: any) {
    return this.postWithFile(`/${clinicId}/upload-image`, params);
  }

  public uploadPatients(params: any) {
    return this.postWithFile(`/import-users`, params);
  }

  public getImportPatients(params: any) {
    return this.get(`/imported-users`, params);
  }

  public setCSV(params: any) {
    return this.post('/csv-columns', params);
  }

  public setBookingTime(params: any) {
    return this.post('/set-booking-time', params);
  }

  public delete(id: number) {
    return this.destroy(id, {});
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/clinics';
  }
}

export default ClinicApi;
