import Index from '@/views/line_message/index.vue';
import {authCS} from '@/common/constants/auth';

export default [
  {
    path: '/line-messages',
    name: 'OrderList',
    component: Index,
    meta: authCS,
  },
];
