import BaseApi from './BaseApi';

class ClinicPatternApi extends BaseApi {
  public list(params: any) {
    console.log(45900, params);
    return this.get('/', params);
  }

  public show(clinicId: any, params: any) {
    params = {
      with: 'managers',
      ...params,
    };
    return this.get(`/${clinicId}`, params);
  }

  public create(params: any) {
    return this.post('/', params);
  }

  public update(clinicId: number, params: any) {
    return this.put(`/${clinicId}`, params);
  }

  public delete(id: number) {
    return this.destroy(id, {});
  }

  public getClinicPatternDetail(id: number) {
    return this.get('/' + id);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/clinic-contain-pattern';
  }
}

export default ClinicPatternApi;
