import BaseApi from './BaseApi';

class PharmacyApi extends BaseApi {
  public create(params: any) {
    return this.postWithFile('/', params);
  }

  public updateImage(id, params: any) {
    return this.postWithFile(`/${id}/upload-image`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/pharmacies';
  }
}

export default PharmacyApi;
