
























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faImage, faCameraRetro, faPlus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import Toast from '@common/helpers/toast';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import BaseImage from '@/components/common/BaseImage.vue';

@Component({
  components: {
    ImagePrivate,
    BaseImage,
  },
})
export default class DragDropFileUpload extends Vue {
  @Prop({default: 'imageUpload'}) readonly name!: string;
  @Prop([String, File]) value!: any;
  @Prop(String) readonly alt!: string;
  @Prop() readonly urlPath!: any;
  @Prop({default: false}) readonly confirm!: boolean;
  @Prop({default: false}) readonly hideCloseButton!: boolean;
  @Prop({default: false}) readonly loadingUploadImage!: boolean;
  @Prop({default: false}) readonly isZoom!: boolean;
  @Prop({default: false}) readonly isFitContain!: boolean;
  @Prop({default: false, type: Boolean}) readonly viewMode!: boolean;
  @Prop(String) readonly messageConfirm!: string;
  @Prop(String) readonly styleCamera!: string;
  @Prop({ default: 3}) readonly maxSize!: number;
  @Prop({default: 'jpgやjpegやpng形式のファイルでアップロードしてください'}) errorMessage!: string;
  @Prop({default: () => ['image/jpeg', 'image/png', 'image/jpg']}) imageAccept!: string[];
  @Prop()  changeValue!: any;
  isDragging: boolean = false;
  dragCount: number = 0;
  isLoading: boolean = false;
  isLoadModal: boolean = false;

  imagePreview: string | File = this.value;
  file: File | null = null;

  onChangeFile(file: any) {
    this.$emit('onChange', file);
    this.isLoading = false;
  }

  get src() {
    return this.value;
  }

  onDragEnter(e) {
    if (this.viewMode) {
      return;
    }
    e.preventDefault();
    this.dragCount++;
    this.isDragging = true;

    return false;
  }

  onDragLeave(e) {
    if (this.viewMode) {
      return;
    }
    e.preventDefault();
    this.dragCount--;

    if (this.dragCount <= 0) {
      this.isDragging = false;
    }
  }

  onInputChange(e) {
    const files = e.target && e.target.files ? e.target.files : e.dataTransfer.files;
    if (files && files[0]) {
      if (this.validateUploadFile(files[0].type)) {
        if (this.calculateMB(files[0].size) > this.maxSize) {
          Toast.error(`${this.maxSize}MB以下の写真をアップロードしてください`);
          this.clearForm();
          return;
        }

        this.isLoading = true;
        this.onChangeFile(files[0]);
      } else {
        Toast.error(this.errorMessage);
      }
    }
  }

  onDrop(e) {
    if (this.viewMode) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    this.isDragging = false;
    this.onInputChange(e);
  }

  @Watch('value')
  onValueChanged(val, oldVal) {
    if (!val || typeof val === 'string') {
      this.imagePreview = val;
      return this.src;
    }
    if (typeof val === 'object') {
      const file = val;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imagePreview = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  @Watch('src')
  onSrcChanged(val: string, oldVal: string) {
    if (!oldVal) {
      // this.isLoading = true;
    }
  }

  validateUploadFile(type) {
    const typeImages: string[] = this.imageAccept;
    return typeImages.includes(type);
  }

  calculateMB(byte) {
    return Number(byte) / 1048576;
  }

  clearForm() {
    (this.$refs.myFiles as HTMLInputElement).value = '';
    this.$store.state.plan.plan.image = null;
    this.$store.state.plan.plan.image_url = '';
    this.$store.state.medicine.medicine.image = null;
    this.$store.state.medicine.medicine.image_url = '';
    this.$store.state.delivery.delivery.prescription_image = null;
    this.$store.state.delivery.delivery.prescription_image_url = '';
    this.$store.state.doctor.dataDoctorDetail.avatar = '';
    this.$store.state.doctor.dataDoctorDetail.avatar_url = null;
    this.imagePreview = '';
    this.changeValue && this.changeValue('');
    this.$emit('onDelete');
    this.isLoading = false;
  }

  show(urlImage: string) {
    this.$modal.show('imagePreview', { url: urlImage });
  }

  created() {
    library.add(faImage, faCameraRetro, faPlus, faSearchPlus);
    dom.watch();
  }
}
