


import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';
import { extend } from 'vee-validate';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {faCameraRetro, faDownload} from '@fortawesome/free-solid-svg-icons';
import { ModelClinic } from '@/models/clinics';
import { ClinicModule } from '@/store';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import AuthenticationUtils from '@common/AuthenticationUtils';

@Component({
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
})
export default class ChangePassword extends Mixins(ClinicsMixin) {
  @Prop({ default: 650 }) readonly width!: number;
  @Prop({ default: 470 }) readonly height!: number;
  private isSubmiting = false;
  private currentUser: any;
  private changePassword = {
    old_password: '',
    password: '',
    password_confirmation: '',
  };

  private beforeClose() {
    this.changePassword = {
      old_password: '',
      password: '',
      password_confirmation: '',
    };
  }

  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  private async updatePassword() {
    try {
      this.updateIsSubmiting(true);
      const response = await ApiHelper.getApi('ClinicApi').resetPassword(this.currentUser.id, this.changePassword);
      AuthenticationUtils.updateAccessToken(response.access_token);
      this.updateIsSubmiting(false);
      this.$modal.hide('change-password-clinic');
      Toast.success('更新しました');
      ClinicModule.getClinicDetail((this.currentUser.clinics[0] || {}).id);
    } catch (error) {
      this.updateIsSubmiting(false);
      this.$modal.hide('change-password-clinic');
      const data = error.response.data;
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
      Toast.error(data.message);
    }
  }

  private get computedForm() {
    return this.$refs.changePassword as any;
  }
}
