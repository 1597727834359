import { Deserializable } from '@/common/interfaces/deserializable';

export interface ResetPwInput {
  uuid: string;
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export class ResetPw implements Deserializable<ResetPw>, ResetPwInput {
  uuid: string = '';
  email: string = '';
  password: string = '';
  password_confirmation: string = '';
  token: string = '';

  deserialize(input: Partial<ResetPw>): ResetPw {
    if (input) {
      Object.assign(this, input);
    }
    return this;
  }

  formJSONData() {
    return {
      uuid: this.uuid,
      email: this.email,
      password: this.password,
      password_confirmation: this.password_confirmation,
      token: this.token,
    };
  }
}
