



import { Vue, Component } from 'vue-property-decorator';
import AuthenticationUtils from '@common/AuthenticationUtils';
import { AuthenticationModule } from '@/store';

@Component
export default class UserSessionNotFound extends Vue {
  private showUserSessionNotFoundDialog() {
    this.$modal.show('dialog', {
        title: 'Session Not Found',
        text: 'User session not found, please try to login again',
      },
    );
  }

  private removeAuthenticate() {
    AuthenticationUtils.removeAuthenticationData();
    AuthenticationModule.updateIsAuthenticated(false);
    AuthenticationModule.updateAccessToken('');
    AuthenticationModule.updateCurrentUser({});

    this.$router.push('/login');
  }

  private created() {
    this.$on('UserSessionNotFound', this.showUserSessionNotFoundDialog);
  }
}
