


import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';
import { extend } from 'vee-validate';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ModelClinic } from '@/models/clinics';
import { ClinicModule } from '@/store';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import ChangePassword from '@/components/Clinic/ChangePassword/ChangePassword.vue';
import { Roles } from '@/common/app.config';

@Component({
  head: {
    title: {
      inner: 'クリニック詳細・編集',
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  components: {
    PopupConfirm,
    ChangePassword,
  },
  data() {
    return {
      clinicId: this.$route.params.clinicId,
      prefectures: [],
      cities: [],
      clinic: {},
    };
  },
})
export default class ManagerInfo extends Mixins(ClinicsMixin) {
  private extend: any = extend;
  private typeValidate: string = 'input';
  private isSubmiting = false;
  private currentUser: any;
  // private clinic!: any;
  private clinicId!: any;
  // private clinicUuid: string = '';
  private isSetPrefectureCity: boolean = false;

  private updateClinicForm = {
    medical_institution_code: '',
    medical_department: '',
    clinic_name: '',
    clinic_name_kana: '',
    email: '',
    phone: '',
    fax: '',
    postal_code: '',
    prefecture_id: '',
    city_id: '',
    address: '',
    building_name: '',
    introduction: '',
    image: '',
    manager_name: '',
    manager_name_kana: '',
    position: '',
    manager_phone: '',
    manager_email: '',
  };

  private get computedForm() {
    return this.$refs.updateClinicForm as any;
  }

  private updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  private input() {
    return {
      on: ['input'],
    };
  }

  private async updateInfoClinic() {
    try {
      const re = /^0/gi;
      const cpForm = Object.assign({}, this.updateClinicForm);
      cpForm.phone = cpForm.phone && cpForm.phone;
      cpForm.fax = cpForm.fax && cpForm.fax;
      cpForm.manager_phone = cpForm.manager_phone && cpForm.manager_phone;
      this.updateIsSubmiting(true);
      const response = await ApiHelper.getApi('ClinicApi').update(this.clinicId, cpForm);
      this.updateIsSubmiting(false);
      this.$modal.hide('confirmUpdateManager');
      Toast.success('更新しました');
      ClinicModule.getClinicDetail(this.clinicId);
      this.$emit('changeTab', 'clinicInfo');
    } catch (error) {
      this.updateIsSubmiting(false);
      this.$modal.hide('confirmUpdateManager');
      const data = error.response.data;
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
      Toast.error(data.message);
    }
  }

  private async searchZipcode() {
    try {
      const params = {
        postal_code: this.updateClinicForm.postal_code,
      };
      const result = await ApiHelper.getApi('ZipcodeApi').getList(params);
      if (this.isSetPrefectureCity) {
        this.updateForm(this.updateClinicForm, 'prefecture_id', result.address1);
        this.updateForm(this.updateClinicForm, 'city_id', result.address2);
        this.updateForm(this.updateClinicForm, 'address', result.address3);
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  private get dataClinic() {
    return ClinicModule.dataClinic;
  }

  private created() {
    if (this.currentUser.role === Roles.clinic) {
      this.clinicId = (this.currentUser.clinics[0] || {}).id;
    }

    ClinicModule.getClinicDetail(this.clinicId);
    this.extend('postal_code', {
      validate: (value: string) => {
        if (this.typeValidate === 'click') {
          return this.computedForm.refs.postal_code.flags.valid;
        }
        return this.searchZipcode();
      },
      message: '住所が見つかりません',
    });
    library.add(faCameraRetro);
    library.add(faDownload);
    dom.watch();
  }

  @Watch('dataClinic')
  private watchDataClinic(val, oldVal) {
    this.setData(this.updateClinicForm, val);
  }
}
