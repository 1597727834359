




























import ApiHelper from 'api-helper';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import lodash from 'lodash';

@Component({
  components: {
      PageLoader,
  },
})
export default class PopupTreatmentDetail extends Vue {
  @Prop() readonly userId!: number | string;
  isLoading: boolean = true;
  thumbnail_url: string = '';
  detailCourse: any = {};

  private closeModal() {
    this.thumbnail_url = '';
    this.detailCourse = {};
    this.isLoading = true;
    this.$modal.hide('modal-treatment-course');
  }

  private getDetail(idCourse) {
    ApiHelper.getApi('CourseApi').getCourseDetailsCheckDelete(idCourse)
      .then((response) => {
        this.isLoading = false;
        if (response.data) {
          this.detailCourse = response.data;
          this.thumbnail_url = lodash.get(response, 'included[0].attributes.url_medium', '');
        } else {
          Toast.error('このコースは現在閲覧できません。運営までお知らせください');
          this.closeModal();
        }
      })
      .catch((error) => {
        this.closeModal();
        if (error.response.status === 404) {
          Toast.error('この薬剤が存在しません');
          return;
        }
        Toast.error(error.response.data.message);
      });
  }

  private beforeOpen(event) {
    if (event.params.idDetailCourse) {
      this.getDetail(event.params.idDetailCourse);
    } else {
      this.isLoading = false;
    }
  }
}
