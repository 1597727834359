import BaseApi from './BaseApi';
import { getFormData, autoDeletePropertyEmpty } from '../common/utils';
class DoctorApi extends BaseApi {
  public list(params: any) {
    return this.get('/', params);
  }

  public async create(params: any) {
    const mapParams = {
      ...params,
      doctor: params.doctor.id,
      clinic_ids: [params.clinic.id],
      doctor_phone: params.doctor_phone,
    };
    delete mapParams.clinic;
    delete mapParams.avatar_url;
    const body = getFormData(mapParams);
    return await this.post('/', body);
  }

  public async updateDoctor(params: any) {
    const mapParams = {
      ...params,
      clinic_ids: params.clinic.map((x) => x.id),
      doctor_phone: params.doctor_phone,
      _method: 'put',
    };
    delete mapParams.id;
    delete mapParams.doctor;
    delete mapParams.clinic;
    delete mapParams.avatar;
    return await this.post(`/${params.id}`, mapParams);
  }

  public async updateImage(params: any) {
    const body = getFormData(params.body);
    return await this.post(`/${params.id}/upload-image`, body);
  }

  public delete(id) {
    return this.destroy(id, {});
  }

  public uploadLicense(id: number | string, params: any) {
    return this.postWithFile(`/${id}/upload-license`, params);
  }

  public removeLink(id, params: any) {
    return this.del(`/${id}/remove-link`, params);
  }

  public getDetail(id, params: any) {
    return this.get(`/${id}`, params);
  }

  public uploadImage(id, params: any) {
    const body = getFormData(params);
    return this.postWithFile(`/${id}/upload-image`, body);
  }


  public updatePassword(id, params: any) {
    return this.post(`/${id}/change-password`, params);
  }

  public update(id, params: any) {
    return this.post(`/${id}`, params);
  }

  public updateDoctorInfo(id, params: any) {
    return this.post(`/doctor/${id}/update-info`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/doctors';
  }
}

export default DoctorApi;
