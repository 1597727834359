import { Deserializable } from '@common/interfaces/deserializable';

export interface UserInput {
  id: number;
  name: string;
  name_kana: string;
  email: string;
  phone: string;
  date_of_birth: string;
  gender: boolean;
  front_side_card: string;
  front_side_card_url: string;
  back_side_card: string;
  back_side_card_url: string;
  avatar: string;
  avatar_url: string;
  address: string;
  postal_number: string;
  prefecture_id: string;
  city_id: string;
  building_name: string;
  created_at: string;
  updated_at: string;
  chat: string;
  plan_id: string;
  note: string;
  insurance_card_number: string;
  plan_name?: string;
  order_id?: number;
  _key?: string;
}

export class User implements Deserializable<User>, UserInput {
  id!: number;
  name: string;
  name_kana: string;
  email: string;
  phone: string;
  date_of_birth: string;
  gender: boolean;
  front_side_card: string;
  front_side_card_url: string;
  back_side_card: string;
  back_side_card_url: string;
  avatar: string;
  avatar_url: string;
  address: string;
  postal_number: string;
  prefecture_id: string;
  city_id: string;
  building_name: string;
  created_at: string;
  updated_at: string;
  chat: string;
  plan_id: string;
  note: string;
  insurance_card_number: string;
  plan_name?: string;
  order_id?: number;
  otp_verified_at?: string;
  isSuperAdminRole: boolean;
  isDoctorRole: boolean;
  isCSRole: boolean;
  isAdminRole: boolean;
  isPharmacistRole: boolean;
  isDeliveryRole: boolean;
  _key?: string;

  deserialize(input: Partial<UserInput>): User {
    Object.assign(this, input);

    return this;
  }
}
