export interface DataForm {
  id?: number | null;
  uuid?: string | null;
  pharmacist_name: string;
  pharmacist_name_kana: string;
  stores: [];
  pharmacist_phone: string;
  pharmacist_email: string;
  password?: string | undefined;
  password_confirmation?: string | undefined;
  avatar: string;
  created_at?: string;
  updated_at?: string;
}

export const defaultDataForm: DataForm = {
  pharmacist_name: '',
  pharmacist_name_kana: '',
  stores: [],
  pharmacist_phone: '',
  pharmacist_email: '',
  password: '',
  password_confirmation: '',
  avatar: '',
};

export const defaultDataFormEdit: DataForm = {
  id: null,
  uuid: null,
  pharmacist_name: '',
  pharmacist_name_kana: '',
  stores: [],
  pharmacist_phone: '',
  pharmacist_email: '',
  avatar: '',
  created_at: '',
  updated_at: '',
};


export interface Pharmacy {
  id: number | null;
  name: string;
  name_kana: string;
}
