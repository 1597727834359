import { CType, Roles } from '@/common/app.config';
import { User } from '@/models/user';

export const authSAdmin = {
  auth: true,
  authorize: [Roles.superAdmin],
};

export const authAdmin = {
  auth: true,
  authorize: [Roles.superAdmin, Roles.admin],
};

export const authCS = {
  auth: true,
  authorize: [Roles.customerService],
};

export const authDoctor = {
  auth: true,
  authorize: [Roles.doctor],
};

export const authAdminDoctor = {
  auth: true,
  authorize: [Roles.superAdmin, Roles.admin, Roles.doctor],
};

export const authAdminClinic = {
  auth: true,
  authorize: [Roles.superAdmin, Roles.clinic],
};

export const authAdminDoctorClinic = {
  auth: true,
  authorize: [Roles.superAdmin, Roles.doctor, Roles.admin],
};

export const authDelivery = {
  auth: true,
  authorize: [
    Roles.admin,
    Roles.pharmacist,
    Roles.pharmacy,
    Roles.customerService,
    Roles.delivery,
  ],
};

export const authAdminCustomer = {
  auth: true,
  authorize: [Roles.superAdmin, Roles.customerService],
};
export const authSAdminAdminCustomer = {
  auth: true,
  authorize: [Roles.superAdmin, Roles.admin, Roles.customerService],
};

export const authAll = {
  auth: true,
  authorize: [
    Roles.admin,
    Roles.pharmacist,
    Roles.pharmacy,
    Roles.customerService,
    Roles.superAdmin,
    Roles.delivery,
  ],
};

export const USER_STATUS = {
  PATIENT_PROVISIONALLY_REGISTERED: 'patient_provisionally_registered',
  REGISTERED_INTERVIEW: 'registered_interview',
  NO_NEED_INTERVIEW: 'no_need_interview',
  SUBSCRIPTION_SUGGESTIONS: 'subscription_suggestions',
  CHOICE_INTERVIEW_METHOD: 'choice_interview_method',
  CHAT_INTERVIEW_SCHEDULED: 'chat_interview_scheduled',
  VIDEO_INTERVIEW_SCHEDULED: 'video_interview_scheduled',
  CONFIRMED_RESERVATION: 'confirmed_reservation',
  VIDEO_INTERVIEW_DATE: 'video_interview_date',
  CONFIRMING_PRESCRIPTION: 'confirming_prescription',
  NOT_PAID: 'not_paid',
  SUCCESS_PAID: 'success_paid',
  REQUESTING_SMS_VERIFICATION: 'requesting_sms_verification',
  SUBSCRIPTION_SMS_VERIFICATION: 'subscription_sms_verification',
  KARTE_SMS_VERIFICATION: 'karte_sms_verification',
  OTP_VERIFIED: 'otp_verified',
  IDENTITY_VERIFICATION_NOT_REGISTERED: 'identity_verification_not_registered',
  IDENTITY_CHECK: 'identity_check',
  IDENTIFICATION_OK: 'identification_ok',
  IDENTIFICATION_NG: 'identification_ng',
  SUBSCRIPTION_NOT_PAID: 'subscription_not_paid',
  SUBSCRIPTION_SUCCESS_PAID: 'subscription_success_paid',
  SUBSCRIPTION_CONTRACT: 'subscription_contract',
  DOCTOR_PRESCRIPTION_OK: 'doctor_prescription_ok',
  CONFIRMATION_OF_PROPOSAL: 'confirmation_of_proposal',
  TREATMENT_NG: 'treatment_ng',
};

export const USER_STATUS_NAME = {
  PATIENT_PROVISIONALLY_REGISTERED: '患者仮登録',
  REGISTERED_INTERVIEW: '問診登録済',
  NO_NEED_INTERVIEW: '診療不要',
  SUBSCRIPTION_SUGGESTIONS: '定期購入提案',
  CHOICE_INTERVIEW_METHOD: '診療方法選択',
  CHAT_INTERVIEW_SCHEDULED: 'チャット診療予定',
  VIDEO_INTERVIEW_SCHEDULED: 'ビデオ診療予定',
  CONFIRMED_RESERVATION: '予約確定',
  VIDEO_INTERVIEW_DATE: 'ビデオ診療日',
  CONFIRMING_PRESCRIPTION: '処方確認中',
  NOT_PAID: '未決済',
  SUCCESS_PAID: '決済成功',
  REQUESTING_SMS_VERIFICATION: 'SMS認証中',
  SUBSCRIPTION_SMS_VERIFICATION: '定期購入SMS認証中',
  KARTE_SMS_VERIFICATION: 'カルテSMS認証中',
  OTP_VERIFIED: 'SMS認証済',
  IDENTITY_VERIFICATION_NOT_REGISTERED: '本人確認未登録',
  IDENTITY_CHECK: '本人確認チェック',
  IDENTIFICATION_OK: '本人確認OK',
  IDENTIFICATION_NG: '本人確認NG',
  SUBSCRIPTION_NOT_PAID: '定期購入未決済',
  SUBSCRIPTION_SUCCESS_PAID: '定期購入決済成功',
  SUBSCRIPTION_CONTRACT: '定期購入契約',
  DOCTOR_PRESCRIPTION_OK: '医師処方OK',
  CONFIRMATION_OF_PROPOSAL: '提案確認中',
  TREATMENT_NG: '治療NG',
};

export const NEW_USER_STATE = {
  PATIENT_PROVISIONALLY_REGISTERED: 'patient_provisionally_registered',
  IDENTITY_CHECK: 'identity_check',
  IDENTITY_VERIFICATION_NOT_REGISTERED: 'identity_verification_not_registered',
  REQUESTING_SMS_VERIFICATION: 'requesting_sms_verification',
  IDENTIFICATION_OK: 'identification_ok',
  IDENTIFICATION_NG: 'identification_ng',
  REGISTERED_MEMBER: 'registered_member',
  VIDEO_INTERVIEW_SCHEDULED: 'video_interview_scheduled',
};

export const NEW_USER_STATE_NAME = {
  PATIENT_PROVISIONALLY_REGISTERED: '患者仮登録',
  IDENTITY_CHECK: '本人確認チェック',
  IDENTITY_VERIFICATION_NOT_REGISTERED: '本人確認未登録',
  REQUESTING_SMS_VERIFICATION: 'SMS認証中',
  IDENTIFICATION_OK: '本人確認OK',
  IDENTIFICATION_NG: '本人確認NG',
  REGISTERED_MEMBER: '会員登録完了',
  VIDEO_INTERVIEW_SCHEDULED: 'ビデオ診療予定',
};

export const USER_TREATMENT_STATE = {
  SELECTED_TREATMENT: 'selected_treatment',
  REGISTERED_INTERVIEW: 'registered_interview',
  SUBSCRIPTION_NOT_PAID: 'subscription_not_paid',
  SUBSCRIPTION_SUCCESS_PAID: 'subscription_success_paid',
  SUBSCRIPTION_SUGGESTIONS: 'subscription_suggestions',
  CHOICE_INTERVIEW_METHOD: 'choice_interview_method',
  NO_NEED_INTERVIEW: 'no_need_interview',
  VIDEO_INTERVIEW_SCHEDULED: 'video_interview_scheduled',
  CHAT_INTERVIEW_SCHEDULED: 'chat_interview_scheduled',
  SUBSCRIPTION_CONTRACT: 'subscription_contract',
  CONFIRMED_RESERVATION: 'confirmed_reservation',
  VIDEO_INTERVIEW_DATE: 'video_interview_date',
  CONFIRMING_PRESCRIPTION: 'confirming_prescription',
  NOT_PAID: 'not_paid',
  SUCCESS_PAID: 'success_paid',
  DOCTOR_PRESCRIPTION_CHECK: 'doctor_prescription_check',
  DOCTOR_PRESCRIPTION_OK: 'doctor_prescription_ok',
  CONFIRMATION_OF_PROPOSAL: 'confirmation_of_proposal',
  TREATMENT_NG: 'treatment_ng',
  PRECAUTIONS_CONFIRMED: 'precautions_confirmed',
  CONFIRMATION_INTERVIEW: 'confirmation_interview',
  UPLOADED_IMAGE: 'uploaded_image',
  INTERVIEW_COMPLETED: 'interview_completed',
};

export const USER_TREATMENT_NAME = {
  SELECTED_TREATMENT: '診療科目選択済',
  REGISTERED_INTERVIEW: '問診登録済',
  SUBSCRIPTION_NOT_PAID: '定期購入未決済',
  SUBSCRIPTION_SUCCESS_PAID: '定期購入決済成功',
  SUBSCRIPTION_SUGGESTIONS: '定期購入提案',
  CHOICE_INTERVIEW_METHOD: '診療方法選択',
  NO_NEED_INTERVIEW: '診療不要',
  VIDEO_INTERVIEW_SCHEDULED: 'ビデオ診療予定',
  CHAT_INTERVIEW_SCHEDULED: 'チャット診療予定',
  SUBSCRIPTION_CONTRACT: '定期購入契約',
  CONFIRMED_RESERVATION: '予約確定',
  VIDEO_INTERVIEW_DATE: 'ビデオ診療日',
  CONFIRMING_PRESCRIPTION: '処方確認中',
  NOT_PAID: '未決済',
  SUCCESS_PAID: '決済成功',
  DOCTOR_PRESCRIPTION_CHECK: '医師処方チェック',
  DOCTOR_PRESCRIPTION_OK: '医師処方OK',
  TREATMENT_NG: '治療NG',
  CONFIRMATION_OF_PROPOSAL: '提案確認中',
  PRECAUTIONS_CONFIRMED: '注意事項確認済',
  CONFIRMATION_INTERVIEW: '問診内容確認',
  UPLOADED_IMAGE: '画像・動画アップロード済',
  INTERVIEW_COMPLETED: '問診確認完了',
};

export const getUserStateName = (
  state: string,
  isDoctor: boolean,
  isCS: boolean,
) => {
  switch (state) {
    case USER_STATUS.PATIENT_PROVISIONALLY_REGISTERED: {
      return {
        text: USER_STATUS_NAME.PATIENT_PROVISIONALLY_REGISTERED,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.REGISTERED_INTERVIEW: {
      return {
        text: USER_STATUS_NAME.REGISTERED_INTERVIEW,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.NO_NEED_INTERVIEW: {
      return {
        text: USER_STATUS_NAME.NO_NEED_INTERVIEW,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.SUBSCRIPTION_SUGGESTIONS: {
      return {
        text: USER_STATUS_NAME.SUBSCRIPTION_SUGGESTIONS,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.TREATMENT_NG: {
      return {
        text: USER_STATUS_NAME.TREATMENT_NG,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.CHOICE_INTERVIEW_METHOD: {
      return {
        text: USER_STATUS_NAME.CHOICE_INTERVIEW_METHOD,
        class: `user-status text-center user-status--yellow ${
          isDoctor ? 'user-status--red' : ''
        }`,
      };
    }
    case USER_STATUS.CHAT_INTERVIEW_SCHEDULED: {
      return {
        text: USER_STATUS_NAME.CHAT_INTERVIEW_SCHEDULED,
        class: `user-status text-center user-status--yellow ${
          isDoctor ? 'user-status--red' : ''
        }`,
      };
    }
    case USER_STATUS.VIDEO_INTERVIEW_SCHEDULED: {
      return {
        text: USER_STATUS_NAME.VIDEO_INTERVIEW_SCHEDULED,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.CONFIRMED_RESERVATION: {
      return {
        text: USER_STATUS_NAME.CONFIRMED_RESERVATION,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.VIDEO_INTERVIEW_DATE: {
      return {
        text: USER_STATUS_NAME.VIDEO_INTERVIEW_DATE,
        class: `user-status text-center user-status--yellow ${
          isDoctor ? 'user-status--red' : ''
        }`,
      };
    }
    case USER_STATUS.CONFIRMING_PRESCRIPTION: {
      return {
        text: USER_STATUS_NAME.CONFIRMING_PRESCRIPTION,
        class: `user-status text-center user-status--yellow ${
          isDoctor ? 'user-status--red' : ''
        }`,
      };
    }
    case USER_STATUS.CONFIRMATION_OF_PROPOSAL: {
      return {
        text: USER_STATUS_NAME.CONFIRMATION_OF_PROPOSAL,
        class: `user-status text-center user-status--yellow ${
          isDoctor ? 'user-status--red' : ''
        }`,
      };
    }
    case USER_STATUS.NOT_PAID: {
      return {
        text: USER_STATUS_NAME.NOT_PAID,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.SUCCESS_PAID: {
      return {
        text: USER_STATUS_NAME.SUCCESS_PAID,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.REQUESTING_SMS_VERIFICATION: {
      return {
        text: USER_STATUS_NAME.REQUESTING_SMS_VERIFICATION,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.KARTE_SMS_VERIFICATION: {
      return {
        text: USER_STATUS_NAME.KARTE_SMS_VERIFICATION,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.OTP_VERIFIED: {
      return {
        text: USER_STATUS_NAME.OTP_VERIFIED,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.IDENTITY_VERIFICATION_NOT_REGISTERED: {
      return {
        text: USER_STATUS_NAME.IDENTITY_VERIFICATION_NOT_REGISTERED,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.IDENTITY_CHECK: {
      return {
        text: USER_STATUS_NAME.IDENTITY_CHECK,
        class: `user-status text-center user-status--yellow ${
          isCS ? 'user-status--red' : ''
        }`,
      };
    }
    case USER_STATUS.IDENTIFICATION_OK: {
      return {
        text: USER_STATUS_NAME.IDENTIFICATION_OK,
        class: `user-status text-center user-status--yellow ${
          isDoctor ? 'user-status--red' : ''
        }`,
      };
    }
    case USER_STATUS.IDENTIFICATION_NG: {
      return {
        text: USER_STATUS_NAME.IDENTIFICATION_NG,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.SUBSCRIPTION_NOT_PAID: {
      return {
        text: USER_STATUS_NAME.SUBSCRIPTION_NOT_PAID,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.SUBSCRIPTION_SUCCESS_PAID: {
      return {
        text: USER_STATUS_NAME.SUBSCRIPTION_SUCCESS_PAID,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.SUBSCRIPTION_SMS_VERIFICATION: {
      return {
        text: USER_STATUS_NAME.SUBSCRIPTION_SMS_VERIFICATION,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.SUBSCRIPTION_CONTRACT: {
      return {
        text: USER_STATUS_NAME.SUBSCRIPTION_CONTRACT,
        class: `user-status text-center user-status--yellow`,
      };
    }
    case USER_STATUS.DOCTOR_PRESCRIPTION_OK: {
      return {
        text: USER_STATUS_NAME.DOCTOR_PRESCRIPTION_OK,
        class: `user-status text-center user-status--yellow`,
      };
    }
    default: {
      return {
        text: '',
        class: 'd-none',
      };
    }
  }
};

export const getNewUserStateName = (state: string) => {
  switch (state) {
    case NEW_USER_STATE.PATIENT_PROVISIONALLY_REGISTERED: {
      return {
        text: NEW_USER_STATE_NAME.PATIENT_PROVISIONALLY_REGISTERED,
        class: `user-status text-center user-status--gray-blue`,
      };
    }
    case NEW_USER_STATE.IDENTITY_CHECK: {
      return {
        text: NEW_USER_STATE_NAME.IDENTITY_CHECK,
        class: `user-status text-center user-status--gray-blue`,
      };
    }
    case NEW_USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED: {
      return {
        text: NEW_USER_STATE_NAME.IDENTITY_VERIFICATION_NOT_REGISTERED,
        class: `user-status text-center user-status--gray-blue`,
      };
    }
    case NEW_USER_STATE.REQUESTING_SMS_VERIFICATION: {
      return {
        text: NEW_USER_STATE_NAME.REQUESTING_SMS_VERIFICATION,
        class: `user-status text-center user-status--gray-blue`,
      };
    }
    case NEW_USER_STATE.IDENTIFICATION_NG: {
      return {
        text: NEW_USER_STATE_NAME.IDENTIFICATION_NG,
        class: `user-status text-center user-status--gray-blue`,
      };
    }
    case NEW_USER_STATE.IDENTIFICATION_OK: {
      return {
        text: NEW_USER_STATE_NAME.IDENTIFICATION_OK,
        class: `user-status text-center user-status--gray-blue`,
      };
    }
    case NEW_USER_STATE.REGISTERED_MEMBER: {
      return {
        text: NEW_USER_STATE_NAME.REGISTERED_MEMBER,
        class: `user-status text-center user-status--gray-blue`,
      };
    }

    default: {
      return {
        text: '',
        class: 'd-none',
      };
    }
  }
};

export const makeUserTreatmentState = (state: string, user: User) => {
  const stateName = USER_TREATMENT_NAME[state.toUpperCase()];
  const className =
    user.isDoctorRole && TREATMENT_STATE_OF_DOCTOR.includes(state)
      ? 'treatment-item__status--red'
      : '';

  return {
    stateName,
    className,
  };
};

export const TREATMENT_STATE_OF_DOCTOR = [
  USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD,
  USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED,
  USER_TREATMENT_STATE.VIDEO_INTERVIEW_DATE,
  USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK,
];

export const TREATMENT_STATE_OF_CS = [USER_TREATMENT_STATE.SUCCESS_PAID];

export const authAdminDoctorCustomerService = {
  auth: true,
  authorize: [Roles.superAdmin, Roles.doctor, Roles.customerService],
};

export const USER_STATE = {
  PATIENT_PROVISIONALLY_REGISTERED: 'patient_provisionally_registered',
  REGISTERED_INTERVIEW: 'registered_interview',
  NO_NEED_INTERVIEW: 'no_need_interview',
  SUBSCRIPTION_SUGGESTIONS: 'subscription_suggestions',
  CHOICE_INTERVIEW_METHOD: 'choice_interview_method',
  CHAT_INTERVIEW_SCHEDULED: 'chat_interview_scheduled',
  VIDEO_INTERVIEW_SCHEDULED: 'video_interview_scheduled',
  CONFIRMED_RESERVATION: 'confirmed_reservation',
  VIDEO_INTERVIEW_DATE: 'video_interview_date',
  CONFIRMING_PRESCRIPTION: 'confirming_prescription',
  NOT_PAID: 'not_paid',
  SUCCESS_PAID: 'success_paid',
  REQUESTING_SMS_VERIFICATION: 'requesting_sms_verification',
  OTP_VERIFIED: 'otp_verified',
  IDENTITY_VERIFICATION_NOT_REGISTERED: 'identity_verification_not_registered',
  IDENTITY_CHECK: 'identity_check',
  IDENTIFICATION_OK: 'identification_ok',
  IDENTIFICATION_NG: 'identification_ng',
  SUBSCRIPTION_NOT_PAID: 'subscription_not_paid',
  SUBSCRIPTION_SUCCESS_PAID: 'subscription_success_paid',
  SUBSCRIPTION_SMS_VERIFICATION: 'subscription_sms_verification',
  SUBSCRIPTION_CONTRACT: 'subscription_contract',
  DOCTOR_PRESCRIPTION_OK: 'doctor_prescription_ok',
  KARTE_SMS_VERIFICATION: 'karte_sms_verification',
  CONFIRMATION_OF_PROPOSAL: 'confirmation_of_proposal',
  TREATMENT_NG: 'treatment_ng',
};

export const USER_TREATMENT_STATE_CAN_ADD_KARTE = [
  USER_TREATMENT_STATE.CONFIRMING_PRESCRIPTION,
  USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD,
  USER_TREATMENT_STATE.VIDEO_INTERVIEW_DATE,
  USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED,
  USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED,
];

export const DELIVERY_STATUS = {
  NOT_PAID: 'not_paid',
  PAID: 'paid',
  PREPARING_FOR_DELIVERY: 'preparing_for_delivery',
  STATUS_CONFIRM_PAYMENT: 'confirm_payment',
  DELIVERED: 'delivered',
  DELIVERY_COMPLETE: 'delivery_complete',
  DELIVERY_RETURN: 'delivery_return',
  CANCEL: 'cancel',
  READY_FOR_DELIVERY: 'ready_for_delivery',
  ERROR_PAID: 'error',
  REFUNDED: 'refunded',
  SKIPPED: 'skipped',
};

export const DELIVERY_STATUS_NAME = {
  [DELIVERY_STATUS.NOT_PAID]: '未決済',
  [DELIVERY_STATUS.PAID]: '決済済み',
  [DELIVERY_STATUS.PREPARING_FOR_DELIVERY]: '配送準備中',
  [DELIVERY_STATUS.DELIVERED]: '発送済',
  [DELIVERY_STATUS.DELIVERY_COMPLETE]: '配達済',
  [DELIVERY_STATUS.DELIVERY_RETURN]: '配送戻り',
  [DELIVERY_STATUS.CANCEL]: 'キャンセル',
  [DELIVERY_STATUS.READY_FOR_DELIVERY]: '配送可',
  [DELIVERY_STATUS.ERROR_PAID]: '決済エラー',
  [DELIVERY_STATUS.REFUNDED]: '返金済',
  [DELIVERY_STATUS.SKIPPED]: 'スキップ済',
};

export const CLINIC_TYPE_TO_TEXT = {
  [CType.clinic]: 'クリニック',
  [CType.salon]: 'サロン',
  [CType.clinicDoctor]: '診察クリニック',
};
