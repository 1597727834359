import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import LocalStorageUtils from '@common/LocalStorageUtils';

@Module({ name: 'monthly_report', namespaced: true })
export default class MonthlyReport extends VuexModule {
  public selectedClinic: any = {};
  public selectedRevenueClinic: any = {};

  @Mutation
  public updateClinic(clinic) {
    this.selectedClinic = clinic;
    LocalStorageUtils.saveItem('monthlyReportClinic', clinic);
  }

  @Mutation
  public updateRevenueClinic(clinic) {
    this.selectedRevenueClinic = clinic;
    LocalStorageUtils.saveItem('revenueClinic', clinic);
  }


  @Action({ rawError: true })
  public async setClinic(clinic: any) {
    const { commit } = this.context;
    commit('updateClinic', clinic);
  }

  @Action({ rawError: true })
  public async setRevenueClinic(clinic: any) {
    const { commit } = this.context;
    commit('updateRevenueClinic', clinic);
  }
}
