var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"updateClinicForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"info-clinic mt-5"},[_c('div',{staticClass:"group-info-clinic"},[_c('table',{staticClass:"table table-bordered table-striped mt-4"},[_c('tr',[_c('th',[_vm._v("担当者名"),_c('span',{staticClass:"th-required"},[_vm._v("*")])]),_c('td',[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"manager_name","rules":"required|max:30","tag":"div","customMessages":_vm.managerNameErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.manager_name),expression:"updateClinicForm.manager_name"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"正井 ほにゃほにゃ"},domProps:{"value":(_vm.updateClinicForm.manager_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "manager_name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("担当者名(カナ)"),_c('span',{staticClass:"th-required"},[_vm._v("*")])]),_c('td',[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"manager_name_kana","rules":"required|kana|max:100","tag":"div","customMessages":_vm.managerNameKanaNameErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.manager_name_kana),expression:"updateClinicForm.manager_name_kana"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"マサイ ホニャホニャ"},domProps:{"value":(_vm.updateClinicForm.manager_name_kana)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "manager_name_kana", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("担当者役職")]),_c('td',[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"position","rules":"max:30","tag":"div","customMessages":_vm.positionErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.position),expression:"updateClinicForm.position"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"院長"},domProps:{"value":(_vm.updateClinicForm.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "position", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("担当者電話番号"),_c('span',{staticClass:"th-required"},[_vm._v("*")])]),_c('td',[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"manager_phone","rules":{ numeric: true, required: true, phone: true, min: 10, max: 11 },"tag":"div","customMessages":_vm.managerPhoneErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.manager_phone),expression:"updateClinicForm.manager_phone"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"09000000000"},domProps:{"value":(_vm.updateClinicForm.manager_phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "manager_phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v(" 担当者メールアドレス"),_c('span',{staticClass:"th-required"},[_vm._v("*")]),_c('p',{staticClass:"th-description"},[_vm._v("ログイン用のメールアドレスになります")])]),_c('td',[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"manager_email","rules":"required|email|max:255","tag":"div","customMessages":_vm.managerEmailErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateClinicForm.manager_email),expression:"updateClinicForm.manager_email"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"xxxx@xxxx"},domProps:{"value":(_vm.updateClinicForm.manager_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updateClinicForm, "manager_email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),(_vm.currentUser.role === 'C')?_c('tr',[_c('th',[_vm._v(" 担当者パスワード "),_c('p',{staticClass:"th-description"},[_vm._v("ログイン用のパスワードになります")])]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.show('change-password-clinic')}}},[_vm._v("パスワードを更新する")])])]):_vm._e()])])]),_c('div',{staticClass:"form-group d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('changeTab', 'clinicInfo')}}},[_vm._v("キャンセル")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":invalid || !dirty},on:{"click":function($event){return _vm.$modal.show('confirmUpdateManager')}}},[_vm._v("保存する")])]),_c('PopupConfirm',{attrs:{"message":"<p>クリニックの担当者情報を更新しても</p>\n      <p>よろしいでしょうか。</p>","name":"confirmUpdateManager","height":"200"},on:{"submit":_vm.updateInfoClinic,"cancel":function($event){return _vm.$modal.hide('confirmUpdateManager')}}}),_c('ChangePassword')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }