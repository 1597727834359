




























































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { ClinicModule } from '@/store';
import { Clinic } from '@/models/doctors';
import { LIMIT_ADD_CLINIC } from '@/common/app.config';
import BasicModal from '@/components/common/BasicModal.vue';
import Pagination from '@components/common/Pagination.vue';

import SearchInput from './SearchInput.vue';
import PageLoader from '@components/common/PageLoader.vue';

@Component({
  components: {
    BasicModal,
    SearchInput,
    Pagination,
    PageLoader,
  },
})
export default class PopupClinicList extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(Array) readonly clinic!: Clinic[];
  private tempClinic = [];
  private query = {
    with: 'managers',
    freeword_on_modal: '',
    order: '-created_at',
    page: 1,
    per_page: 10,
  };

  private get dataClinicList() {
    return ClinicModule.dataClinic;
  }

  private get isEmptyDataClinic() {
    return ClinicModule.isEmptyDataClinic;
  }

  private get isLoadingData() {
    return ClinicModule.isLoading || false;
  }

  private get totalPage() {
    return ClinicModule.totalPage;
  }

  private get isDisabledButtonChoose() {
    return this.clinic.length >= LIMIT_ADD_CLINIC;
  }

  @Emit('onChooseClinic')
  onChooseClinic(clinic) {
    const data: Clinic = {
      id: clinic.id,
      name: clinic.name,
      name_kana: clinic.name_kana,
    };

    (this.tempClinic as any).push(clinic.id);

    return [...this.clinic, data];
  }

  @Emit('onChooseClinic')
  onRemoveClinicChoosed(id: number) {
    return this.clinic.filter((x) => x.id !== id);
  }

  @Emit('onChooseClinic')
  private cancelPopup() {
    this.$modal.hide(this.name);
    return this.clinic.filter((x) => !(this.tempClinic as any).includes(x.id));
  }
  // Methods
  private checkClinicChoosed(id: number) {
    return this.clinic.some((x) => x.id === id);
  }

  private beforeOpen(data) {
    this.tempClinic = [];
    this.query = {...this.query, freeword_on_modal: '', page: 1};
    ClinicModule.getClinicList(this.query);
  }

  private onChangeSearch(freeword: string) {
    this.query = {...this.query, freeword_on_modal: freeword, page: 1};
  }

  private onPageChaned(page: number) {
    this.query = {...this.query, page};
  }

  @Watch('query')
  private onQueryChanged(query: object, oldQuery: object) {
    ClinicModule.getClinicList(query);
  }
}
