






























































































import { Vue, Component } from 'vue-property-decorator';
import { confirmed } from 'vee-validate/dist/rules';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import { ResetPw } from '@/models/auth-pw';

const DEFAULT_REDIRECT = '/';

@Component({
  head: {
    title: {
      inner: 'Reset Password',
    },
    script: [
      { type: 'text/javascript', inner: '(function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);'},
    ],
  },
})
export default class SetPassword extends Vue {
  resetForm: ResetPw = new ResetPw();
  validation: any = {};
  isSubmiting: boolean = false;
  isSetPassSuccess: boolean = false;
  get passwordErrorMessages() {
    return {
      min: '半角英数字を含める8文字以上で設定してください',
    };
  }

  get confirmationErrorMessages() {
    return {
      confirmed: 'パスワードと同じ文字を入力してください。',
    };
  }
}
