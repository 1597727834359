











































































import { Vue, Component } from 'vue-property-decorator';
import { AuthenticationModule } from '@/store';
import Toast from '@/common/helpers/toast';
import { Roles } from '@/common/app.config';
import ApiHelper from 'api-helper';
import AuthenticationUtils from '@common/AuthenticationUtils';

@Component({
  head: {
    title: {
      inner: 'Login',
    },
  },
})
export default class Login extends Vue {
  userAfterLogin!: any;
  loginForm = {
    email: '',
    password: '',
  };

  get routeFollowRole() {
    let nameRoute = 'patients';
    if (this.userAfterLogin.role === Roles.admin) {
      nameRoute = 'PatientV2';
    }

    if (this.userAfterLogin.role === Roles.superAdmin) {
      nameRoute = 'PatientV2';
    }

    if (this.userAfterLogin.role === Roles.doctor) {
      nameRoute = 'PatientV2';
    }

    if (this.userAfterLogin.role === Roles.customerService) {
      nameRoute = 'PatientV2';
    }

    if (this.userAfterLogin.role === Roles.delivery) {
      nameRoute = 'deliveries';
    }
    return { name: nameRoute };
  }

  isSubmiting = false;

  get isDisableBtnLogin() {
    return localStorage.getItem('isLoggedIn');
  }

  mounted() {
    this.$nextTick(() => {
      window.onfocus = () => {
        if (localStorage.getItem('isLoggedIn') === 'true') {
          if (this.$router.currentRoute.name === 'Login') {
            // window.location.reload();
          }
        }
      };
    });
  }

  created() {
    this.$toasted.clear();
  }

  get computedForm() {
    return this.$refs.loginForm as any;
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  async login() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    try {
      this.updateIsSubmiting(true);
      const response = await ApiHelper.getApi('UserApi').login(this.loginForm);

      localStorage.setItem('isLoggedIn', 'true');
      AuthenticationUtils.saveAuthenticationData(response);
      AuthenticationModule.updateIsAuthenticated(true);

      const { currentUser, access_token } = response;
      this.userAfterLogin = currentUser;
      AuthenticationModule.updateAccessToken(access_token);
      AuthenticationModule.updateCurrentUser(currentUser);

      this.updateIsSubmiting(false);
      let destination = this.$route.query.destination || this.routeFollowRole;

      if (this.$route.query.destination === '/') {
        destination = this.routeFollowRole;
      }

      if (currentUser.role === Roles.pharmacy) {
        return (this.$router as any).push({ name: 'pharmacies.profile' });
      }

      if (currentUser.role === Roles.pharmacist) {
        return (this.$router as any).push({ name: 'PatientV2' });
      }

      if (currentUser.role === Roles.doctor) {
        return (this.$router as any).push({ name: 'PatientV2' });
      }

      if (currentUser.role === Roles.delivery) {
        return (this.$router as any).push('/deliveries');
      }

      (this.$router as any).push(destination);
    } catch (error) {
      this.updateIsSubmiting(false);
      const data = error.response.data;

      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
      }
      Toast.error(data.message);
    }
  }

  get emailErrorMessages() {
    return {
      email: 'メールは有効なメールアドレスで入力してください',
    };
  }

  get passwordErrorMessages() {
    return {
      min: '半角英数字を含める8文字以上で設定してください',
    };
  }
}
