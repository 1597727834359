import { Deserializable } from '@/common/interfaces/deserializable';
import { Plan } from '@/models/plan';
import { ModelPatient, Patient } from '@/models/patient';
import { OrderStatusType, OrderStatusString } from '@/common/enums/order';

import { get } from 'lodash';
import moment from 'moment';
import {Tracking} from '@/models/tracking';
import { formatDate } from '@common/app.config';
import { UserTreatment } from './user-treatment';

export interface OrderInput {
  address: string;
  amount: string;
  building_name: string;
  city_id: string;
  created_at: string;
  id: number;
  paid_at: string;
  postal_number: string;
  prefecture_id: string;
  reservation_id: number;
  status: string;
  updated_at: string;
  user_id: number;
  plan: Plan;
  user: ModelPatient;
}

export class Order implements Deserializable<Order>, OrderInput {
  address: string;
  amount: string;
  building_name: string;
  city_id: string;
  created_at: string;
  id: number;
  paid_at: string;
  doctor_confirm_at: string;
  delivery_date: string;
  postal_number: string;
  prefecture_id: string;
  reservation_id: number;
  payment_method_id: number;
  status: string;
  updated_at: string;
  user_id: number;
  quantity: number;
  cancel_at: string;
  plan: Plan = new Plan();
  user: Patient = new Patient();
  tracking: Tracking[];
  user_treatment: UserTreatment;

  get planId(): number {
    return get(this.plan, 'plan_id', 0);
  }

  get planName(): string {
    return get(this.plan, 'name', '');
  }

  get userIdDisplay(): string {
    return `ID: ${this.user_id}`;
  }

  get planType(): number {
    return get(this.plan, 'treatment.payment_type', 0);
  }

  get planTypeDisplay(): string {
    if (!this.planType) { return ''; }
    if (this.planType === 2) {
      return '一括購入';
    }
    return '定期購入';
  }

  get planIdDisplay(): string {
    if (this.planType === 1) {
      const planId = get(this.plan, 'plan_id', '');
      return `ID: ${planId}`;
    }
    return '';
  }

  get userName(): string {
    return `${get(this.user, 'first_name') || ''} ${get(this.user, 'last_name') || ''}`;
  }

  get userId(): string | number | null {
    return get(this.user, 'id', 0);
  }

  get receiptTotal(): string {
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(+this.amount);
  }

  get dateOrder(): string {
    return moment(this.updated_at).format('YYYY/MM/DD HH:mm');
  }

  get isSuccess(): boolean {
    return this.status === OrderStatusType.Success;
  }

  get isCancelled(): boolean {
    return this.status === OrderStatusType.Cancelled;
  }

  get isFailure(): boolean {
    return this.status === OrderStatusType.Failure;
  }

  get isWaiting(): boolean {
    return this.status === OrderStatusType.Waiting;
  }

  get isConfirm(): boolean {
      return this.status === OrderStatusType.Confirm;
    }

  get formatCancelAt() {
    return this.cancel_at
      ? moment(this.cancel_at).format(formatDate)
      : moment().format(formatDate);
  }

  get statusDisplay(): string {
    return new Map()
      .set(OrderStatusType.Confirm, OrderStatusString.Confirm)
      .set(OrderStatusType.Waiting, OrderStatusString.Unpaind)
      .set(OrderStatusType.Success, OrderStatusString.Completed)
      .set(OrderStatusType.Delivered, OrderStatusString.Completed)
      .set(OrderStatusType.Undelivered, OrderStatusString.Completed)
      .set(OrderStatusType.Refunded, OrderStatusString.Completed)
      .set(OrderStatusType.Cancelled, OrderStatusString.Cancel)
      .get(this.status);
  }

  deserialize(input: Partial<OrderInput>): Order {
    Object.assign(this, input);

    if (input.plan) {
      this.plan = new Plan().deserialize(input.plan);
    }

    if (input.user) {
      this.user = new Patient().deserialize(input.user);
    }

    return this;
  }

  get amountFormat() {
    const formatter = new Intl.NumberFormat('ja-JP');
    return this.amount
      ? `${formatter.format(Number(this.amount) * this.quantity)}円`
      : `0円`;
  }
}
