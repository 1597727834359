import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { CLINIC_TYPE, Roles } from '@common/app.config';
import { uiAvatars } from '@common/helpers/ui-avatar';
import imageDefault from '@/assets/img/icon-no-image.svg';

@Component({
  computed: {
    ...mapGetters('channel', [
      'currentChannel',
    ]),
    ...mapState('auth', [
      'currentUser',
    ]),
    ...mapState('message', [
      'isMeIds',
    ]),
  },
})
export default class MessageMixin extends Vue {
  currentChannel!: any;
  currentUser!: any;
  isMeIds!: any;


  @Prop({type: Object, default: () => [], required: true}) readonly message!: any;

  get formatTime() {
    if (this.currentUser && this.currentUser.role === Roles.pharmacy) {
      return 'HH:mm';
    }
    return 'MM月DD日 HH:mm';
  }

  get userSender() {
    const users = this.currentChannel && this.currentChannel.users;
    return users ? users[0] : {};
  }

  hasAvatar(message) {
    if (this.isMessageSendByMe(message)) {
      const {clinics} = this.currentUser;
      const clinic = message.senderable_type === CLINIC_TYPE
        ? clinics.find((item) => item.id === message.sender_id) : this.currentUser;
      return !!clinic.avatar;
    }
    return !!this.userSender.avatar;
  }

  getAvatar(message) {
    if (this.isMessageSendByMe(message)) {
      if (this.currentUser.role === Roles.pharmacy) {
        const pharmacy = this.currentUser.pharmacies[0];
        return pharmacy.image_url || uiAvatars(pharmacy.name);
      }

      const {clinics} = this.currentUser;
      const clinic = message.senderable_type === CLINIC_TYPE
                  ? clinics.find((item) => item.id === message.sender_id) : this.currentUser;
      return clinic.avatar || uiAvatars(clinic.name);
    }
    const userAvart = this.userSender;

    if (!userAvart.family_name && !userAvart.given_name && !userAvart.avatar) {
      return imageDefault;
    }

    const name = `${userAvart.family_name} ${userAvart.given_name}`;
    return userAvart.avatar || uiAvatars(name);
  }

  getAvatarTextManager(message) {
    const nameSenderable = message.senderable.name;
    if (!nameSenderable) {
      return imageDefault;
    }

    return uiAvatars(nameSenderable);
  }

  getAvatarText() {
    const userAvart = this.userSender;

    if (!userAvart.name) {
      return imageDefault;
    }

    return uiAvatars(userAvart.name);
  }

  isMessageSendByMe(message) {
    const roleSenderable = message.senderable.role;
    return roleSenderable === Roles.doctor
      || roleSenderable === Roles.superAdmin
      || roleSenderable === Roles.clinic
      || message.sender_by === 'manager';
  }
}
