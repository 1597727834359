import BaseApi from './BaseApi';

class PrecautionMenuApi extends BaseApi {
  precaution(params?: any) {
    return this.post(``, params);
  }

  getList(menuId: number) {
    return this.get(`?menu_id=${menuId}`);

  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/precautions';
  }
}

export default PrecautionMenuApi;
