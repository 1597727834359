


































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { DoctorModule } from '@/store';

import BasicModal from '@/components/common/BasicModal.vue';

@Component({
  components: {
    BasicModal,
  },
})
export default class PopupDoctorList extends Vue {
  @Prop(String) readonly name!: string;

  private get doctorList() {
    return DoctorModule.dataDoctor;
  }

  @Emit('onChooseDoctor')
  onChooseDoctor(doctor) {
    return {
      id: doctor.id,
      name: doctor.name,
      name_kana: doctor.name_kana,
    };
  }

  // Methods
  private beforeOpen() {
    DoctorModule.getDoctorList();
  }
}
