<template lang="pug">
  <div class="chime-wait d-flex align-items-center">
    <div class="chime-wait-box" v-show="isLoaded">
      <div class="row">
        <div class="mt-2 col-6" :span="6">
          <div class="preview-call">
            <div class="preview-video video-list">
              <video v-if="!hasVideoWait"></video>
              <video v-show="hasVideoWait" id="preview-video"></video>
              <div class="preview-call-icon">
                <div class="preview-icon" @click="toggleAudioInput">
                  <div v-show="!isMute">
                    <span class="micro-phone fas fa-microphone" size="6x"></span>
                  </div>
                  <div v-show="isMute">
                    <span class="micro-phone fas fa-microphone-slash" size="6x"></span>
                  </div>
                </div>
                <div class="preview-icon" @click="onToggleCamera">
                  <div v-show="isCameraOpen">
                    <span class="fas fa-video"></span>
                  </div>
                  <div v-show="!isCameraOpen">
                    <span class="fas fa-video-slash"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="select-device">
              <div class="select-device-card d-flex">
                <p><span class="fas fa-video mr-2"></span><span>カメラ</span></p>
                <select class="select-device" :value="videoInputDevice.label">
                  <option v-for="(videoInput,index) in listVideoInputDevices" :key="index" @click="onSelectDevice('videoInput', videoInput)">{{videoInput.label}}</option>
                </select>
              </div>
              <div class="select-device-card d-flex">
                <p><span class="fas fa-microphone mr-2"></span><span>マイク</span></p>
                <select class="select-device" :value="audioInputDevice.label" style="width: 297px;">
                  <option v-for="(audioInput,index) in listAudioInputDevices" :key="index" @click="onSelectDevice('audioInput', audioInput)">{{audioInput.label}}</option>
                </select>
              </div>
              <div class="select-device-card d-flex">
                <p><span class="fas fa-volume-up mr-2"></span><span>スピーカー</span></p>
                <select class="select-device" :value="audioOutputDevice.label">
                  <option v-for="(audioOutput,index) in listAudioOutputDevices" :key="index" @click="onSelectDevice('audioOutput', audioOutput)">{{audioOutput.label}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 col-6" :span="6">
          <div class="info-call">
            <h2>{{nameUser}}</h2>
            <p>ユーザー名を入力して</p>
            <p>ミーティングに参加して下さい</p>
            <p class="doctor-name">{{nameDoctor}}</p>
            <p>サービス利用規約に同意し、入室ください</p>
            <p class="term-page" @click="redirectTerm">ⓘ 利用規約を表示する</p>
            <button class="btn-join-call" @click="$emit('startCalling')">参加する (Join)</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChimeWaiting',
  props: {
    reservation: {
      type: Object,
      default: () => ({}),
    },
    isCameraOpen: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: true,
    },
    toggleCamera: {
      type: Function,
      default: () => ({}),
    },
    toggleAudioInput: {
      type: Function,
      default: () => ({}),
    },
    audioInputDevice: {
      default: () => ({}),
    },
    videoInputDevice: {
      default: () => ({}),
    },
    audioOutputDevice: {
      default: () => ({}),
    },
    listAudioInputDevices: {
      type: Array,
      default: () => [],
    },
    listAudioOutputDevices: {
      type: Array,
      default: () => [],
    },
    listVideoInputDevices: {
      type: Array,
      default: () => [],
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
    hasVideoWait: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nameDoctor() {
      return this.reservation.doctor?.name;
    },
    nameUser() {
      return this.reservation.user?.name;
    },
  },

  methods: {
    onSelectDevice(type, value) {
      this.$emit('onSelectDevice', type, value);
    },
    onToggleCamera() {
      this.toggleCamera();
    },
    redirectTerm() {
      window.location.href = 'https://www.calling.fun/terms/';
    },
  },
};
</script>

<style lang="scss">
.chime-wait {
  width: 100%;
  height: calc(100vh - 62px);
  background: url("/assets/bg_calling.svg") repeat, radial-gradient(1798px 1798px at 48.4% -2.34%, rgb(38, 57, 79) 0%, rgb(64, 131, 199) 0%, rgb(41, 67, 95) 100%);

  .chime-wait-box {
    .preview-video {
      position: relative;
      display: flex;
      video {
        width: 500px;
        background: rgb(48, 67, 95) url("/images/avatar-default.svg");
        background-repeat: no-repeat;
        background-size: 90px 90px;
        background-position: center;
        transform: rotateY(180deg);
        margin: 0 auto 30px;
        border-radius: 10px;
        height: 280px;
        object-fit: cover;
      }
      .preview-call-icon {
        position: absolute;
        bottom: 50px;
        left: 200px;
        display: flex;
        
        .preview-icon {
          border-radius: 50%;
          border: none;
          color: rgb(255, 255, 255);
          cursor: pointer;
          height: 60px;
          margin: 0px 5px;
          outline: none;
          width: 60px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          background: rgba(127, 127, 127, 0.8);
        }

        span {
          width: 50px;
          height: 50px;
          color: white;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #c5c5c5;
          background-color: #c5c5c5;
          border-radius: 50%;
          cursor: pointer;
          padding: 10px;
          &.micro-phone {
            margin-right: 20px;
          }
        }
      }
    }
    .select-device {
      background-color: rgb(241, 241, 241);
      border-radius: 10px;
      .select-device {
        width: 300px;
      }
      .select-device-card {
        padding: 10px;
        p {
          flex-basis: 40%;
          span {
            &:first-child {
              margin-right: 10px;
            }
            &:last-child {
              font-weight: bold;
            }
          }
        }
        .ant-select {
          flex: 1;
        }
      }
    }
    width: 1100px;
    background-color: white;
    margin: 2rem auto;
    height: fit-content;
    border-radius: 10px;
    padding: 50px 35px;
    .info-call {
      text-align: center;
      p {
        margin-bottom: 0;
        font-weight: 500;
        &.doctor-name {
          margin: 20px auto;
          text-align: left;
          border: 1px solid #ccc;
          padding: 10px;
          width: 400px;
          border-radius: 5px;
        }
        &.term-page {
          color: #2b6ddc;
          cursor: pointer;
          margin-top: 5px;
        }
      }
      .btn-join-call {
        margin-top: 40px;
        border: none;
        width: 400px;
        padding: 10px;
        border-radius: 5px;
        background-image: linear-gradient(
          to right,
          rgb(254, 116, 34) 0%,
          rgb(217, 9, 9) 100%
        );
        font-size: 20px;
        color: #ffffff;
        &:hover {
          background-color: #f15b1a;
        }
      }
    }
  }
  .chime-spin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 150px !important;
    }
  }
}
</style>
