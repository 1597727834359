
































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PageLoader from '@components/common/PageLoader.vue';
import BasicModal from '@components/common/BasicModal.vue';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { isNull, get } from 'lodash';
import moment from 'moment';
import { USER_STATE } from '@/common/constants/auth';
import { PLAN_PAYMENT_TYPE } from '@/common/constants/plan';
import PrescriptionPopup from '@components/messages/partials/PrescriptionPopup.vue';
import { extend } from 'vee-validate';

extend('notSpecialChar', (text) => {
  const textCompare = text;
  if (text) {
    return textCompare.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '').length == text.length
      ? true
      : '絵文字または特殊記号を入力しないでください。';
  }
  return true;
});

@Component({
  components: {
    PageLoader,
    BasicModal,
    PrescriptionPopup,
  },
  computed: {
  },
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})

export default class KarteInfoChannel extends Vue {
  private get feedbackFormRef() {
    return this.$refs.feedbackForm as any;
  }

  private get confirmOrderFormRef() {
    return this.$refs.confirmOrderForm as any;
  }

  private get prescriptionPopupRef() {
    return this.$refs.prescriptionPopup as any;
  }

  private get isDisableCreateFeedback() {
    if (this.currentChannelUser.state === USER_STATE.CONFIRMING_PRESCRIPTION) {
      return false;
    } else {
      if (this.listFeedback.length > 0) {
        return true;
      }
      return false;
    }
    // return this.currentChannelUser.state == USER_STATE.CONFIRMING_PRESCRIPTION;
  }

  private get isEnableConfirmingOrder() {
    return this.currentChannelUser.state == USER_STATE.IDENTIFICATION_OK;
  }

  @Prop() currentChannelUser!: any;
  @Prop() changeCurrentChannelUser: any;
  private listFeedback: any[] = [];
  private isLoading: boolean = false;
  private listPlan: any[] = [];
  private isLoadingPlan: boolean = false;
  private feedbackForm: any = {};
  private type_prescription: string = 'prescription';
  private type_ng: string = 'ng';
  private isSubmitting: boolean = false;
  private orderConfirm: any = {};
  private beingGetOrderConfirm: boolean = false;
  private isConfirming: boolean = false;
  private PLAN_PAYMENT_TYPE: any = PLAN_PAYMENT_TYPE;

  mounted() {
    this.getListFeedback();
  }

  getListFeedback() {
    this.isLoading = true;
    const self = this;
    ApiHelper.getApi('DoctorMouthApi').getListFeedback(this.currentChannelUser.id)
      .then(function(res, error) {
        self.listFeedback = res.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }
  showModalCreateFeedback(feedback = null) {
    if (!feedback) {
      this.feedbackForm = {
        id: null,
        created_at_jp: null,
        content: '',
        plans: [],
        type: null,
        ListPlans: [],
      };
    } else {
      this.feedbackForm = JSON.parse(JSON.stringify(feedback));
      this.feedbackForm.content = this.feedbackForm.content;
      this.feedbackForm.listPlans = [...this.feedbackForm.plans];
      this.feedbackForm.plans = this.feedbackForm.plans.map((item) => item.id);
    }

    this.prescriptionPopupRef.show(this.feedbackForm);
  }

  savedFeedback() {
    const self = this;
    ApiHelper.getApi('ChannelApi').getOne(this.$route.params.channelId, { with: 'users,latest_message' })
      .then(function(res, error) {
        self.$store.commit('channel/updateChannel', res);
        // self.currentChannelUser.state = get(res, 'users[0].state');
        const newChannelUser = {...self.currentChannelUser};
        newChannelUser.state = get(res, 'users[0].state');
        self.changeCurrentChannelUser(newChannelUser);
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      });
    this.getListFeedback();
  }

  showConfirmOrder() {
    const self = this;
    this.beingGetOrderConfirm = true;
    ApiHelper.getApi('PatientApi').getOrderAccepted(this.currentChannelUser.id)
      .then(function(res, error) {
        self.orderConfirm = res;
        self.$modal.show('confirmOrderForm');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.beingGetOrderConfirm = false;
      });
  }

  async saveConfirmOrder() {
    const isValid = await this.confirmOrderFormRef.validate();
    if (!isValid) {
      return;
    }

    this.isConfirming = true;
    const self = this;
    const data = {
      memo: this.orderConfirm.memo,
    };
    await ApiHelper.getApi('OrderApi').doctorConfirm(this.orderConfirm.id, data)
      .then(function(res, error) {
        self.getListFeedback();
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isConfirming = false;
        self.$modal.hide('confirmOrderForm');
      });

    ApiHelper.getApi('ChannelApi').getOne(this.$route.params.channelId, { with: 'users,latest_message' })
      .then(function(res, error) {
        self.$store.commit('channel/updateChannel', res);
        // self.currentChannelUser.state = get(res, 'users[0].state');
        const newChannelUser = {...self.currentChannelUser};
        newChannelUser.state = get(res, 'users[0].state');
        self.changeCurrentChannelUser(newChannelUser);
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      });
  }
}
