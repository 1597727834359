













































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { Order } from '@/models/order';
import moment from 'moment';
import { ProcessingType } from '@common/enums/order';
import PageLoader from '@components/common/PageLoader.vue';

@Component({
  components: {
    PageLoader,
  },
  data() {
    return {
      moment,
      ProcessingType,
    };
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString = value
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}円`;
    },
  },
})
export default class ModalHistoryPayment extends Vue {
  @Prop(Object) readonly order: Order;
  tableHead: string[] = ['処理日時', '処理タイプ', '金額'];
  private ordersTrackingHistories = [];
  private isLoading = false;

  private showHistoryPayment() {
    this.ordersTrackingHistories = [];
    this.isLoading = true;
    this.$modal.show('modalHistoryPayment');
    ApiHelper.getApi('OrderTrackingHistoryApi')
      .getList({ order_id: this.order.id, union_all_paid_orders_tracking: 1 })
      .then((res) => {
        this.ordersTrackingHistories = res.data;
        this.isLoading = false;
      })
      .catch((error) => {
        Toast.error(error.message);
        this.isLoading = false;
      });
  }

  private hiddenHistoryPayment() {
    this.$modal.hide('modalHistoryPayment');
  }

  private processingTypeDisplay(type, deliverySequence) {
    if (ProcessingType.ProductPurchase === type) {
      return `商品購入 ${deliverySequence}回目配送`;
    }
    if (ProcessingType.refund === type) {
      return `返金 ${deliverySequence}回目配送`;
    }

    return `変更 ${deliverySequence}回目配送`;
  }
}
