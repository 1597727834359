






































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BasicSelect extends Vue {
  @Prop({ default: false }) readonly clearable!: boolean;
  @Prop({ default: false }) readonly isMultiple!: boolean;
  @Prop({ default: false }) readonly disable!: boolean;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop([String, Number, Object, Array, Boolean]) readonly value!: any;
  @Prop(Array) readonly options!: any;
  @Prop({default: () => []}) readonly searchBy!: string[];
  @Prop(Function) readonly reduce!: () => void;
  @Prop(String) readonly textNoData!: string;
  @Prop(String) readonly label!: string;
  @Prop(Boolean) readonly isIconUpDown!: boolean;
  @Prop(Boolean) readonly iconUpDownSimple!: boolean;
  @Prop({default: true}) readonly searchable!: boolean;
  @Prop({default: ''}) readonly placeholder!: string;

  get selectedValue() {
    if (!this.value) {
      return;
    }
    const value = this.value?.value || this.value;
    const result = this.options?.find((x) => x.value === value) || {};
    return result?.name;
  }

  private get noData() {
      return this.textNoData ? this.textNoData : 'データが存在しません';
  }

  private focus() {
    let isValid: boolean = false;
    const selectSearch = this.$refs.selectSearch as any;
    selectSearch.$el.classList.forEach((x) => x === 'is-invalid' ? isValid = true : isValid);
    if (!isValid) {
      selectSearch.$refs.toggle.setAttribute('class', 'vs__dropdown-toggle v-select-focus');
    }
  }

  private blur() {
    this.$emit('blur');
    const selectSearch = this.$refs.selectSearch as any;
    selectSearch.$refs.toggle.classList.remove('v-select-focus');
  }

  private filterBy(option, label, search) {
    const text: string = search.toLowerCase();
    let flag: boolean = false;
    for (const field of this.searchBy) {
      flag = option[field].toLowerCase().indexOf(text) > -1;
      if (flag) {
        break;
      }
    }
    return flag;
  }
}
