// libs
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

// helpers
import Toast from '@common/helpers/toast';
import ApiHelper from '@/api/ApiHelper';

// interfaces
import { Patient } from '@/models/patient';

// constants
import { USER_STATUS } from '@common/constants/auth';

@Module({ name: 'patient_detail', namespaced: true })
export default class Index extends VuexModule<any> {
  private data: Patient | {} = {};
  private isLoading = false;

  // ********* Mutations ********* //
  @Mutation
  public setPatient(patient) {
    if (patient.constructor.name === 'Object') {
      patient = new Patient().deserialize(patient);
    }
    this.data = patient;
  }

  @Mutation
  public setLoading(isLoading = true) {
    this.isLoading = isLoading;
  }

  // ********* Actions ********* //
  @Action({ rawError: true })
  public async getPatient(payload) {
    const { commit } = this.context;
    const { id } = payload;
    try {
      commit('setLoading');
      const response = await ApiHelper.getApi('PatientV2Api').detail(id);
      commit('setPatient', response);
    } catch (error) {
      commit('setLoading', false);
      const data = error.response.data;
      Toast.error(data.message);
    } finally {
      commit('setLoading', false);
    }
  }

  @Action({ rawError: true })
  public async updatePatient(payload) {
    const { commit } = this.context;
    const { id , data} = payload;
    try {
      commit('setLoading');
      const response = await ApiHelper.getApi('PatientApi').updateInformation(id, data);
      commit('setPatient', response);
      Toast.success('更新しました');
    } catch (error) {
      commit('setLoading', false);
      const data = error.response.data;
      Toast.error(data.message);
    } finally {
      commit('setLoading', false);
    }
  }

  @Action({ rawError: true })
  public async updateStateIdentification(payload) {
    const { commit } = this.context;
    const { id , data} = payload;
    try {
      commit('setLoading');
      await ApiHelper.getApi('PatientApi').identification(id, data);
      const response = await ApiHelper.getApi('PatientV2Api').detail(id);
      commit('setPatient', response);

      Toast.success(data.state === USER_STATUS.IDENTIFICATION_NG ? '本人確認NG' : '本人確認OK');
    } catch (error) {
      commit('setLoading', false);
      const data = error.response.data;
      Toast.error(data.message);
    } finally {
      commit('setLoading', false);
    }
  }
}
