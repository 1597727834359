





























































































































































import { Vue, Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { DataForm } from '@/models/pharmacist';
import CustomErrorMessage from '@/common/mixins/CustomErrorMessage';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import PharmacistMixin from '@common/mixins/Pharmacist/PharmacistMixin';
import PopupPharmacyList from './PopupPharmacyList.vue';
import TrRow from '@/components/common/TrRow.vue';
import { State } from 'vuex-class';
import { Roles } from '../../common/app.config';

@Component({
  components: {
    PopupPharmacyList,
    TrRow,
  },
  mixins: [CustomErrorMessage],
})
export default class TablePharmacistForm extends Mixins(PharmacistMixin) {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop(Object) readonly dataForm!: DataForm;
  @Prop(String) readonly title!: string;
  @Prop({default: true}) readonly isShowFieldPassword!: boolean;
  @Prop({default: false}) readonly isDisabledCircled!: boolean;
  @Prop({default: false}) readonly isEditPharmacist!: boolean;

  // Computed
  private get pharmacies() {
    const data = this.dataForm.stores;
    if (this.isPharmacy) {
      return data;
    }
    if (!data.length || data.length < 5) {
      return [...data, { id: null, name: '', name_kana: '' }];
    }
    return data;
  }

  private get isPharmacy() {
    return this.currentUser.role === Roles.pharmacy;
  }

  // Emit
  @Emit('updateForm')
  private updateForm(field: string, value: string | number) {
    return {
      field,
      value,
    };
  }

  @Emit('updateForm')
  private onChoosePharmacy(pharmacy: []) {
    return {
      field: 'stores',
      value: pharmacy,
    };
  }

  @Emit('updateForm')
  private onDeletePharmacy(pharmacy) {
    const field = 'stores';
    const value = this.dataForm.stores.filter((x) => (x as any).id !== pharmacy.id);
    return {
      field,
      value,
    };
  }

  // Methods
  private openModalPharmacyList() {
    this.$modal.show('popupPharmacies');
  }

  private onInputPassword(field: string, value: string) {
    let valuePassword: string = '';
    if (value.length <= 100) {
      valuePassword = value;
    } else {
      valuePassword = value.slice(0, 100);
    }
    this.updateForm(field, valuePassword);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }
  private mounted() {
    library.add(faPlus, faTrashAlt);
    dom.watch();
  }
}
