








import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import { mapState, mapGetters } from 'vuex';
import Toast from '@common/helpers/toast';

@Component({
  computed: {
    ...mapGetters('channel', [
      'currentChannel',
    ]),
  },
})

export default class Thankyou extends Vue {
  currentChannel!: any;
  isLoading: boolean = false;

  mounted() {
    this.endCalling();
  }

  endCalling() {
    this.isLoading = true;

    ApiHelper.getApi('ChannelApi')
      .endCalling(this.$route.params.channelId)
      .then((res) => {
        window.close();
      })
      .catch((error: any) => {
        this.isLoading = false;
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
