




































import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BankJPModule } from '@/store';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';

@Component
export default class SelectSearchBranch extends Mixins(ClinicsMixin) {
  @Prop(String) readonly placeholder!: string;
  @Prop(String) readonly codeBank!: string;
  @Prop(String) readonly currentBranch!: string;
  @Prop(Boolean) readonly isHasResultBank!: boolean;
  @Prop({default: true}) readonly isCreate!: boolean;
  private isChoosend = false;
  private search = '';

  private get dataBranch() {
    return BankJPModule.dataBankBranch;
  }

  private handleBlur() {
    this.$emit('notChoosendBranch', this.search);
    this.isChoosend = true;
  }

  private focusInput() {
    this.getBranch();
    this.isChoosend = false;
  }

  private choosenData(item) {
    this.$emit('setData', item);
    this.isChoosend = true;
    this.search = item.name;
  }

  private getBranch() {
    let param = {};
    if (this.search) {
      param = {
        name: this.search,
        code: this.codeBank,
      };
    } else {
      param = {
        code: this.codeBank,
      };
    }

    BankJPModule.listBankBranch(param);
  }

  @Watch('search')
  private searchBank() {
    if (!this.search) {
      this.$emit('hasResultBranch', false);
    }
    if (!this.isChoosend) {
      this.getBranch();
    }
  }

  @Watch('codeBank')
  private wcodeBank() {
    if (this.currentBranch) {
      this.search = this.currentBranch;
      this.isChoosend = true;
    } else {
      this.search = '';
    }
  }

  @Watch('dataBranch')
  private wdataBranch() {
    if (this.dataBranch.length) {
      this.$emit('hasResultBranch', true);
    } else {
      this.$emit('hasResultBranch', false);
    }
  }
}
