import { Vue, Component } from 'vue-property-decorator';

@Component
export default class PharmacistMixin extends Vue {
  public pharmacistNameErrorMessage = {
    max: '薬剤師名は30文字以内で入力してください',
  };

  public pharmacistNameKanaErrorMessage = {
    max: '薬剤師名(カナ)は100文字以内で入力してください',
    kana: '全角カタカナで入力してください',
  };

  public emailErrorMessages = {
    max: 'メールアドレスは100文字以内で入力してください',
    email: 'メールアドレスを正しく入力してください',
  };

  public passwordErrorMessages = {
    min: 'パスワードは8桁以上半角英数字で入力してください ',
  };

  public confirmationErrorMessages = {
    confirmed: 'パスワードが一致しません。正しいパスワードを入力してください',
    min: 'パスワード(確認)は8桁以上半角英数字で入力してください',
  };

  public phoneErrorMessages = {
    max: '電話番号は10~11桁の半角数字で入力してください',
    min: '電話番号は10~11桁の半角数字で入力してください',
    numeric: '電話番号は10~11桁の半角数字で入力してください',
    phone: '担当者電話番号は正しく入力してください',
  };

  public imageErrorMessages = {
    mimes: 'jpgやjpegやpng形式のファイルでアップロードしてください',
  };
}
