















































































import { Vue, Component, Prop, Watch} from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

@Component
export default class PopupSetPasswordMember extends Vue {
  @Prop({ default: '' }) readonly name!: string;
  private isSubmiting: boolean = false;
  private query = {
    password: '',
    password_confirmation: '',
  };

  cancelSetPassword() {
    this.query = {
      password: '',
      password_confirmation: '',
    };
    this.$emit('cancel');
  }

  validatePassword() {
    const {password, password_confirmation} = this.query;
    return !(password === password_confirmation);
  }

  @Watch('query.password')
  @Watch('query.password_confirmation')
  private updateIsSummit() {
    if (this.isSubmiting) {
      this.isSubmiting = false;
    }
  }

  private savePassword() {
    if (this.validatePassword()) {
      this.isSubmiting = true;
      return Toast.error('確認用パスワードは一致していません。');
    }
    if (this.isSubmiting) {
      return;
    }
    this.isSubmiting = true;

    ApiHelper.getApi('MemberApi')
      .updatePassword(this.$route.params.id, this.query)
      .then((res: any) => {
        this.query = {
          password: '',
          password_confirmation: '',
        };
        this.$emit('cancel');
        Toast.success('パスワードを更新しました。');
      })
      .catch((error: any) => {
        error.response && Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isSubmiting = false;
      });
  }

  private get passwordErrorMessages() {
    return {
      min: '半角英数字を含める8文字以上で設定してください',
    };
  }
}
