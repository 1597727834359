





























import {Component, Mixins, Prop} from 'vue-property-decorator';
import MessageMixin from './Message';
// import { oc } from 'ts-optchain';
// import imageDefault from '@/assets/img/icon-no-image.svg';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import LazyloadImage from '@/components/image/LazyloadImage.vue';

@Component({
  components: {
    ImagePrivate,
    LazyloadImage,
  },
})
export default class MessageImage extends Mixins(MessageMixin) {
  @Prop() avatarUser!: any;
  @Prop() avatarManager!: any;
  // getDefaultImage(message) {
  //   return (oc(message.image_url) as any)(imageDefault);
  // }
}
