






























import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiHelper from 'api-helper';

import BasicSelect from '@/components/common/BasicSelect.vue';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    BasicSelect,
  },
})
export default class CreateEditDoctorAdmin extends Vue {
  private isSubmit: boolean = false;
  private doctorOptions: any[] = [];
  private selectedAssignDoctor: any = null;

  private async fetchData() {
    try {
      const result = await ApiHelper.getApi('DoctorNotAdminApi').list();

      this.doctorOptions = result.data.map((item) => ({
        name: item.name,
        value: item.id,
      }));
    } catch (e) {
      Toast.error(e.response.data.message);
    }
  }

  private cancel() {
    this.$router.push({ name: 'doctorAdmins'});
  }

  private async submit() {
    try {
      await ApiHelper.getApi('DoctorAdminApi').create({
        id: this.selectedAssignDoctor,
      });

      this.$router.push({ name: 'doctorAdmins'});
    } catch (e) {
      Toast.error(e.response.data.message);
    }
  }

  private mounted() {
    this.fetchData();
  }
}
