












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MedicalMenuList from '@components/MedicalMenu/MedicalMenuList.vue';
import MedicalMenuSearch from '@components/MedicalMenu/MedicalMenuSearch.vue';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    MedicalMenuList,
    MedicalMenuSearch,
  },
})
export default class Index extends Vue {
  private query: object = {};

  private onSearch(query) {
    this.query = {...query};
  }
}
