































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import LoaderComponent from '@components/common/LoaderComponent.vue';

@Component({
  components: {
    LoaderComponent,
  },
})

export default class ModalUpdateNote extends Vue {
  @Prop(Object) readonly user: any;
  isLoading: boolean = false;
  formUpdateNote = {
    note: null,
  };

  beforeOpen() {
    this.formUpdateNote.note = this.user.note;
  }

  hiddenModel() {
    this.formUpdateNote.note = null;
    this.$modal.hide('UserNoteModal');
  }

  async sendMessageLine() {
    const computedForm = this.$refs.formUpdateNote as any;
    const isValid = await computedForm?.validate();
    if (!isValid) {
      return;
    }
    this.isLoading = true;
    ApiHelper.getApi('PatientApi')
        .updateNote(this.user.id, {...this.formUpdateNote })
        .then((res) => {
          this.isLoading = false;
          Toast.success('更新しました');
          this.$emit('updateUser', res);
          this.hiddenModel();
        })
        .catch((error) => {
          this.isLoading = false;
          Toast.error(error.response.data.message);
        });
  }
}
