import { render, staticRenderFns } from "./NotificationForm.vue?vue&type=template&id=620ef068&scoped=true&"
import script from "./NotificationForm.vue?vue&type=script&lang=ts&"
export * from "./NotificationForm.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationForm.vue?vue&type=style&index=0&id=620ef068&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620ef068",
  null
  
)

export default component.exports