












































import { Vue, Component, Prop } from 'vue-property-decorator';

// Components
import BaseInputCheckbox from '@components/common/BaseInputCheckbox.vue';

// Icons
import iconDrag from '@/assets/img/icon-drag.svg';
import iconTrash from '@/assets/img/icon-trash.svg';

@Component({
  components: {
    BaseInputCheckbox,
  },
})
export default class QuestionListItemAction extends Vue {
  @Prop(Boolean) required: boolean;
  @Prop(Boolean) hiddenIconDrag: boolean;
  @Prop(Boolean) hiddenIconDelete: boolean;
  @Prop(Boolean) readOnlyCheckboxRequired: boolean;
  @Prop(Boolean) countNgRequired: boolean;
  @Prop(Boolean) disabledFirstQuestion: boolean;

  get iconDrag() {
    return iconDrag;
  }

  get iconTrash() {
    return iconTrash;
  }
}
