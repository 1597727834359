import BaseApi from './BaseApi';

class BankApi extends BaseApi {
  public list(params?: any) {
    return this.get('/', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'guest/bank-code';
  }
}

export default BankApi;
