import BaseApi from './BaseApi';
class PatientApi extends BaseApi {
  public list(params: any) {
    return this.get('/', params);
  }

  public listWithPlan(params: any) {
    return this.get('/get-list-patient-with-plan', params);
  }

  public patientsHaventChannel(params: any) {
    return this.get('/doesnt-have-channel', params);
  }

  public async updateNote(id: number | string, params: any) {
    return await this.put(`/${id}/update-note`, params);
  }

  public async updateEmail(id: number | string, params: any) {
    return await this.post(`/${id}/update-email`, params);
  }

  public async updateHealthcareRecords(id: number | string, params: any) {
    return await this.post(`/${id}/update-healthcare-code`, params);
  }

  public async updateInsuranceCardNum(id: number | string, params: any) {
    return await this.post(`/${id}/update-insurance-card-num`, params);
  }

  public updateVerify(id: number | string, params: any) {
    return this.post(`/${id}/update-verify`, params);
  }

  public listHealth(params: any) {
    return this.get('/weights', params);
  }

  public removePatient(id: number | string, params: any) {
    return this.del(`/${id}`, params);
  }

  public identification(id: number | string, params: any) {
    return this.put(`/${id}/identification`, params);
  }

  public getOrderAccepted(id: number) {
    return this.get(`/${id}/get-order-accepted`);
  }

  public async updateInformation(id: number | string, params: FormData) {
    return await this.post(`/${id}/update-information`, params);
  }

  public async getUserChannelInfo(id: number | string, params: any) {
    return await this.get(`/${id}/channel-info`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/patients';
  }
}

export default PatientApi;
