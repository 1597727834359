




















































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ToggleButton } from 'vue-js-toggle-button';

import BasicSelect from '@/components/common/BasicSelect.vue';
import { Question } from '@/models/question';
import Toast from '@/common/helpers/toast';

import RadioQuestion from './QuestionTypes/MultiChoise.vue';
import CheckboxQuestion from './QuestionTypes/Checkbox.vue';
import UploadFile from './QuestionTypes/UploadFile.vue';

@Component({
  components: {
    BasicSelect,
    RadioQuestion,
    CheckboxQuestion,
    UploadFile,
    ToggleButton,
  },
})
export default class QuestionItem extends Vue {
  @Prop({ required: true }) question!: Question;
  @Prop({ required: true }) index!: number | string;
  @Prop({ required: true }) editIndex!: number | string;
  @Prop({ default: false }) editMode!: boolean;

  private get isHasDuplice() {
    const isValidTypeQuestion = ['radio', 'checkbox'].includes(this.question.type);
    if (this.question.options && isValidTypeQuestion) {
      return new Set(this.question.options).size !== this.question.options.length;
    }
  }

  private get isHasEmptyOption() {
    const isValidTypeQuestion = ['radio', 'checkbox'].includes(this.question.type);
    if (this.question.options) {
      return isValidTypeQuestion && this.question.options.includes('');
    }
    return false;
  }

  get isHasError() {
    return this.question.question === '' || this.isHasDuplice || this.isHasEmptyOption;
  }

  get isEditable() {
    if (this.index !== this.editIndex) {
      return false;
    }
    return true;
  }

  questionTypes = [
    { name: '記述式', value: 'textarea' },
    { name: 'ラジオボタン', value: 'radio' },
    { name: 'チェックボックス', value: 'checkbox' },
    { name: '画像アップロード', value: 'image' },
  ];

  @Watch('isHasError')
  onChangeHasError() {
    this.$emit('has-error', { id: this.question.id, hasError: this.isHasError });
  }

  @Watch('question.type')
  onChangeQuestionType(value) {
    if (['radio', 'checkbox', 'select'].includes(value)) {
      if (!this.question.options || !this.question.options.length) {
        this.question.options = ['', ''];
      }
    }
  }

  onChangeTitle() {
    const title = this.question.question;
    this.question.question = title.replace(/\n/g, ' ').substring(0, 255);
  }

  created() {
    if (this.isHasError) {
      this.$emit('has-error', { id: this.question.id, hasError: true });
    }
  }

  addOption() {
    if (this.question.options.length === 30) {
      return;
    }
    this.question.options.push('');
  }

  clearOption(index) {
    this.question.options.splice(index, 1);
  }

  changeEditable() {
    if (this.editMode) {
      if (this.isEditable) { return; }
      this.$emit('editting', this.index);
    }
  }

  emitAction(action: string) {
    this.$emit(action, this.question);
  }
}
