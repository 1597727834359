

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { State } from 'vuex-class';
import cloneDeep from 'lodash/cloneDeep';
import ApiHelper from 'api-helper';
// Components
import BaseFullCalendar from '@/components/common/BaseFullCalendar/Index.vue';
import BaseDatePicker from '@/components/common/BaseDatePicker.vue';
import ModalUpdateReservation from './ModalUpdateReservation.vue';
// Constants
import {
  formatDate,
  formatTime,
  formatDateTime2,
} from '@common/app.config';
import { COLORS } from '@common/app.config';

@Component({
  components: {
    BaseFullCalendar,
    ModalUpdateReservation,
    BaseDatePicker,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
})
export default class Calendar extends Vue {
  @Prop(Array) readonly reservations!: any;
  @Prop(Array) readonly channels!: any;
  @State((state) => state.auth.currentUser) currentUser!: any;

  isChanged: boolean = false;
  isSubmiting: boolean = false;
  isDelete: boolean = false;
  reservationEdit: object = {};

  get events() {
    const data = this.reservations
      .filter((x) => x.user)
      .map((x) => {
        const { reservation_date } = x;
        const itemDefault = {
          user_id: '',
          time: '',
          title: '',
          textColor: COLORS.WHITE,
          backgroundColor: `#${this.currentUser.color}`,
          borderColor: `#${this.currentUser.color}`,
        };
        const date = this.formatDateTime(reservation_date, formatDate);
        const startDateTime = this.formatDateTime(
          reservation_date,
          formatDateTime2,
        );
        const startTime = this.formatDateTime(startDateTime, formatTime);
        const endDateTime = this.formatDateTimeUpdated(
          reservation_date,
          30,
          'minutes',
          formatDateTime2,
        );
        const endTime = this.formatDateTime(endDateTime, formatTime);
        const title = `${startTime} ~ ${endTime}`;
        return {
          ...cloneDeep(itemDefault),
          ...cloneDeep(x),
          title,
          current_date: date,
          time: startTime,
          start: startDateTime,
          end: endDateTime,
        };
      });
    return data;
  }

  eventClick(info) {
    const reservation = this.events.find((x) => x.id === +info.event.id);
    if (!reservation) {
      return;
    }
    const channel = this.channels.find((x) => x.users[0].id === reservation.user.id);
    const cloneReservation = cloneDeep(reservation);
    this.reservationEdit = {
      ...cloneReservation,
      id: cloneReservation.id,
      date: cloneReservation.current_date,
      time: cloneReservation.time,
      channelId: channel?.id,
    };
    this.$modal.show('modalUpdateReservation');
  }

  onChangeGrid(data) {
    const { type, date } = data;
    const params = {
      search_by: type,
      date,
    };
    this.$emit('setSearchOptions', params);
    this.$emit('fetchDataCalendar');
  }

  formatDateTime(value: string, format: string) {
    return this?.$options?.filters?.format_time(value, format);
  }

  formatDateTimeUpdated(
    value: string,
    duration: number,
    type: string,
    format: string,
  ) {
    return this?.$options?.filters?.format_time_updated(
      value,
      duration,
      type,
      format,
    );
  }
}
