import Messages from '@/views/messages/index.vue';
import MessageBox from '@/views/messages/MessageBox.vue';
import HomePage from '@/views/messages/HomePage.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [
    Roles.superAdmin,
    Roles.admin,
    Roles.doctor,
    Roles.customerService,
    Roles.pharmacist,
  ],
};

export default [
  {
    path: 'messages',
    name: 'Messages',
    component: Messages,
    meta: authMeta,
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        meta: authMeta,
      },
      {
        path: ':channelId',
        name: 'MessageBox',
        component: MessageBox,
        props: true,
        meta: authMeta,
      },
    ],
  },
];
