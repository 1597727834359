



























import { Vue, Component, Watch } from 'vue-property-decorator';
import NotificationForm from '@components/Notifications/NotificationForm.vue';
import PageLoader from '@components/common/PageLoader.vue';
import { INotification } from '../../models/notification';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { formatDateTime} from '@common/app.config';
import moment from 'moment';


@Component({
  components: {
    NotificationForm,
    PageLoader,
  },
})
export default class NotificationDetail extends Vue {
  private notification: Partial<INotification> = {
    title: '',
    content: '',
    schedule_time: '',
    status: '',
  };
  private isLoading: boolean = false;
  private isLoadedData: boolean = false;
  private isValid: boolean = true;
  private isSendNow: boolean = true;

  private created() {
    const id = this.$route.params.id;
    this.getNotify(id);
  }

  private onChangeSendStatus(isSendNow: boolean) {
    this.isSendNow = isSendNow;
  }

  private onChangeValid(isValid) {
    this.isValid = isValid;
  }

  private get isDisableBtn() {
    return !this.notification.title || !this.notification.content || !this.isValid;
  }

  private get isDisableBtnDraft() {
    return !this.notification.title || !this.isValid;
  }

  private onChangeForm(params) {
    this.notification = params;
  }

  private copyMessage() {
    (this.$router as any).push({
      name: 'notifications.create',
      params: {
        notification: this.notification,
      },
    });
  }

  private get isSentMessage() {
    return this.notification.status === 2;
  }

  private async updateNotify(status) {
    try {
      let time = '';
      if (this.notification.schedule_time && !this.isSendNow) {
        time = this.notification.schedule_time!.replace(/\//g, '-');
      }
      this.isLoading = true;
      await ApiHelper.getApi('NotificationApi').update(this.notification.id, {
        ...this.notification,
        schedule_time: time,
        status,
      });
      this.isLoading = false;
      Toast.success('更新しました');
      this.$router.push({ name: 'notifications' });
    } catch (err) {
      this.isLoading = false;
      if (err.response) {
        if (err.response.data.code === 'errors.notify_already_sent') {
          Toast.error(err.response.data.message);
          setTimeout(() => {
            location.reload();
          }, 300);
        } else {
          Toast.error('未来の時間で設定してください');
        }
        return;
      }
      Toast.error(err.message);
    }
  }

  private async getNotify(id) {
    try {
      this.isLoading = true;
      const data  = await ApiHelper.getApi('NotificationApi').getOne(id);
      this.notification = data;
      if (!this.notification.content) {
        this.notification.content = '';
      }
      if (data.schedule_time) {
        const date = new Date(`${data.schedule_time}Z`);
        this.notification.schedule_time = moment(date).format(formatDateTime);
      }
      this.isLoadedData = true;
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      Toast.error(err.message);
    }
  }
}
