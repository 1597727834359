













import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { PatientModule } from '@/store';
import Toast from '@common/helpers/toast';

import PopupNote from './PopupNote.vue';

@Component({
  components: {
    PopupNote,
  },
})
export default class PatientBasicInfo extends Vue {
  @Prop(String) readonly note!: string;
  @Prop({default: null}) isDisabled!: string;

  private type: string = '';
  private isSubmit: boolean = false;

  private openPopupNote() {
    this.$modal.show('patient-detail-note', {note: this.note});
  }

  private closePopupNote() {
    this.$modal.hide('patient-detail-note');
  }

  private async updateNote(note: string) {
    try {
      const { id } = this.$route.params;
      const data = {
        id,
        body: {
          note,
        },
      };
      this.isSubmit = true;
      if (!this.note) {
        this.type = 'add';
      } else {
        this.type = 'update';
      }
      await PatientModule.updateNote(data);
      this.isSubmit = false;
      this.closePopupNote();
      if (this.type === 'add') {
        Toast.success('登録しました');
      } else {
        Toast.success('更新しました');
      }
    } catch (error) {
      this.isSubmit = false;
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    }
  }
}
