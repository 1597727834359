






































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BasicSelect from '@components/common/BasicSelect.vue';
import { CSV_FILE_TYPE } from '@common/app.config';
import ApiHelper from 'api-helper';
import { splitLongText } from '@common/helpers/split-long-text';
import PopupConfirm from '@components/common/PopupConfirm.vue';
import Toast from '@common/helpers/toast';
import { ModelClinic } from '@/models/clinics';
import { State } from 'vuex-class';
import { Roles } from '@common/app.config';

@Component({
  components: {
    BasicSelect,
    PopupConfirm,
  },
})
export default class PatientImport extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop() name!: string;
  @Prop() height!: string;
  @Prop() width!: string;
  private clinics: ModelClinic[] = [];
  private selectedClinic: any = null;
  private file: any  = '';

  private get isActiveBtn() {
    if (this.isClinic) {
      return this.file;
    }
    return this.file && this.selectedClinic;
  }

  created() {
    this.getClinic();
  }

  private get isClinic() {
    return this.currentUser.role === Roles.clinic;
  }

  private onChangeFile(event) {
    const file = event.target.files[0];
    if (file && !this.fileAccept.includes(file.type)) {
      Toast.error('CSV形式のファイルをインポートしてください');
      return;
    }
    this.file = file;
  }

  private onCancel() {
    this.$modal.hide('confirm-upload');
  }

  private resetForm() {
    this.selectedClinic = null;
    this.file = null;
  }

  private showConfirm() {
    this.$modal.show('confirm-upload');
  }

  private get clinicName() {
    if (this.isClinic) { return this.currentUser.clinics[0].name; }
    return (this.selectedClinic || {}).name;
  }

  private async uploadPatient() {
    try {
      const formData = new FormData();
      formData.append('file', this.file);
      if (!this.isClinic) {
        formData.append('clinic_id', this.selectedClinic.id);
      }

      this.$modal.hide('confirm-upload');
      this.$modal.hide(this.name);
      await ApiHelper.getApi('ClinicApi').uploadPatients(formData);
      Toast.success('CSVファイルをアップロードしました。');
      this.$emit('imported');
    } catch {
      Toast.error('エラーが発生して、インポート処理が完了できませんでした');
    }
  }

  private async getClinic() {
    if (this.currentUser.role === Roles.clinic || this.currentUser.role === Roles.superAdmin) {
      const response = await ApiHelper.getApi('ClinicApi').list();
      this.clinics = response.data;
    }
  }

  private get fileAccept() {
    return CSV_FILE_TYPE.join(',');
  }

  private shorterFileName() {
    return splitLongText(this.file.name, 10);
  }

  private get labelText() {
    if (this.file) { return splitLongText(this.file.name, 50); }
    return 'ファイルを選択する';
  }
}
