






































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DoctorModule } from '@/store';
import { LIMIT_ADD_DOCTOR_OF_MENU } from '@/common/app.config';
import BasicModal from '@/components/common/BasicModal.vue';
import Pagination from '@components/common/Pagination.vue';
import SearchInput from '../common/SearchInput.vue';
import { splitLongText } from '@common/helpers/split-long-text';

@Component({
  components: {
    BasicModal,
  },
})
export default class PopupListReason extends Vue {
  @Prop(Array) readonly listReasonArr!: any [];
  @Prop(Function) cancel;
  private idReason = 1;

  private closePopupReason() {
    this.$modal.hide('selectReason');
  }

  private beforeOpen() {
    this.idReason = 0;
  }

  private postReason() {
    if (this.idReason) {
      this.$emit('cancel', this.idReason);
    }
  }
}
