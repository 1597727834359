

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import moment from 'moment';
import { find, sortBy } from 'lodash';
import { State } from 'vuex-class';

import Toast from '@common/helpers/toast';
import { USER_STATUS } from '@/common/constants/auth';
import PageLoader from '@components/common/PageLoader.vue';
import BasicModal from '@components/common/BasicModal.vue';
import { Roles } from '@/common/app.config';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    PageLoader,
  },
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})

export default class QuestionList extends Vue {
  @Prop() patientAnswer!: any;
  @Prop() patientQuestion!: any;
  @Prop() closeQuestionModal!: any;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  get treatmentName() {
    return this.activeTreatment.menu?.name;
  }

  get interviewDate() {
    return this.activeTreatment.onlyOneReservation?.latest_answer?.created_at;
  }

  private getAnswer(id) {
    return find(this.patientAnswer, (data) => data && data.question.id === id);
  }

  private patientQuestionSort() {
   return sortBy(this.patientQuestion, 'order');
  }
}

