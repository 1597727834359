import { Deserializable } from '@common/interfaces/deserializable';
import moment from 'moment';

export interface OrderSearchInput {
  orderId: string;
  orderNumber: string;
  planName: string;
  userName: string;
  totalBasePrice: string | null;
  date_lte: string;
  date_gte: string;
  status: string | null;
}

export class OrderSearch
  implements Deserializable<OrderSearch>, OrderSearchInput {
  orderId: string;
  orderNumber: string;
  planName: string;
  userName: string;
  totalBasePrice: string | null = null;
  paymentMethodId: string | null = null;
  deliveryState: string | null = null;
  paidAtFrom: string;
  paidAtTo: string;
  planId: string | null = null;
  date_gte: string;
  date_lte: string;
  status: string | null = null;

  deserialize(input: Partial<OrderSearchInput>): OrderSearch {
    Object.assign(this, input);

    return this;
  }

  toSearchJSON() {
    return {
      id: this.orderId,
      payment_method_id: this.paymentMethodId,
      delivery_state: this.deliveryState,
      plan_id: this.planId,
      paid_at_from: this.paidAtFrom,
      paid_at_to: this.paidAtTo,
      order_number: this.orderNumber,
      status: this.status,
      plan_name: this.planName,
      total_base_price: this.totalBasePrice,
      user_name: this.userName,
      created_at_from:
        this.date_gte && moment(this.date_gte).format('YYYY-MM-DD HH:mm:00'),
      created_at_to:
        this.date_lte && moment(this.date_lte).format('YYYY-MM-DD HH:mm:59'),
    };
  }
}
