import { Deserializable } from '@common/interfaces/deserializable';
import moment from 'moment';

export interface LineMessageSearchInput {
  userName: string;
  lineName: string;
  planId: number | null;
  userState: string;
  deliverystate: string;
}

export class LineMessageSearch implements Deserializable<LineMessageSearch>, LineMessageSearchInput {
  userName: string;
  lineName: string;
  planId: number | null;
  userState: string;
  deliverystate: string;


  deserialize(input: Partial<LineMessageSearchInput>): LineMessageSearch {
    Object.assign(this, input);

    return this;
  }

  toSearchJSON() {
    return {
      user_name: this.userName,
      line_name: this.lineName,
      plan_id: this.planId,
      user_state: this.userState,
      delivery_state: this.deliverystate,
    };
  }
}
