




























































































































import Toast from '@common/helpers/toast';

import { Vue, Component, Prop } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import ApiHelper from 'api-helper';
import { URL_REGEX } from '@common/app.config';
import { State } from 'vuex-class';
import { Patient } from '@/models/patient';
import lodashGet from 'lodash/get';
import InfiniteLoading from 'vue-infinite-loading';
import PageLoader from '@components/common/PageLoader.vue';
import MessageInput from '@components/v2/patient/message/MessageInput.vue';
import MessageImage from '@components/v2/patient/message/MessageImage.vue';
import MessageTemplate from '@components/v2/patient/message/MessageTemplate.vue';
import MessageText from '@components/v2/patient/message/MessageText.vue';
import MessageLink from '@components/v2/patient/message/MessageLink.vue';
import MessageButton from '@components/v2/patient/message/MessageButton.vue';
import MessageLabel from '@components/v2/patient/message/MessageLabel.vue';
import MessageClinicPattern from '@components/v2/patient/message/MessageClinicPattern.vue';
import { MESSAGE_TYPE } from '@/common/enums/message';
import MedicalRecordPopup from '@components/v2/patient/message/MedicalRecordPopup.vue';
import VideoComponent from '@components/v2/patient/message/VideoComponent.vue';
import { USER_TREATMENT_STATE } from '@/common/constants/auth';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    InfiniteLoading,
    PageLoader,
    MessageInput,
    MessageImage,
    MessageTemplate,
    MessageText,
    MessageLink,
    MessageButton,
    MessageLabel,
    MessageClinicPattern,
    MedicalRecordPopup,
    VideoComponent,
    // PopupConfirm,
  },
})
export default class Chat extends Vue {
  @Prop() showModalLeaveVideo;
  @Prop() doctorId: any;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  get getVideoComponent() {
    return this.$refs.videoComponent as any;
  }

  get showBtnVideoCall() {
    const reservationDate = lodashGet(this.userReservation, 'reservation_date');
    const doctorId = lodashGet(this.userReservation, 'doctor_id');
    return (
      (Number(this.currentUser.id) === Number(doctorId) ||
        this.currentUser.isPharmacistRole) &&
        moment().isAfter(moment(reservationDate).subtract(10, 'minutes'))
    );
  }
  @State((state) => state.patient_detail.data) currentPatient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: any;
  public isVideoCall: boolean = false;
  public messages: any = [];
  public isSending: boolean = false;
  public pagination = {
    per_page: 20,
    page: 1,
    totalPage: 1,
    order: '-id',
    with: 'senderable,suggestPlan,suggestPlan.treatment',
  };
  public currentChannel: any = null;
  public isLoading: boolean = false;
  public medicalRecord: any = {
    hasData: false,
    feedbackList: [],
    answer: [],
    question: [],
    feedbackForm: {
      id: null,
      created_at_jp: null,
      content: '',
      plans: [],
      type: null,
    },
  };
  public userReservation: any = {};

  /**************** Setter ****************/
  setVideo(status = true) {
    this.isVideoCall = status;
    this.$emit('startCallVideo', status);
  }
  onChangeSending(isSending = true) {
    this.isSending = isSending;
  }
  setChannel(channel) {
    this.currentChannel = channel;
  }
  setLoading(isLoading = true) {
    this.isLoading = isLoading;
  }
  setPagination(pagination) {
    this.pagination = { ...this.pagination, ...pagination };
  }

  /**************** life cycle ****************/
  async created() {
    // channel data is required for this page.
    await this.loadChannel();
    await this.getAnswerData();

    this.$on(
      `OnMessageCreated_${this.currentChannel.id}`,
      this.onReceiveMessage,
    );
  }

  beforeDestroy() {
    this.$emit('startCallVideo', false);
    this.$off(`OnMessageCreated_${this.currentChannel.id}`);
  }

  /**************** methods ****************/

  async reminderChat() {
    // this.isSubmitting = true;
    try {
      const params = {
        user_treatment_id: this.activeTreatment.id,
      };

      await ApiHelper.getApi('PatientV2Api').reminderChat(
        this.currentPatient.id,
        params,
      );
      Toast.success('リマインドメッセージを送信しました。');
      // this.isSubmitting = false;
    } catch (error) {
      // this.isSubmitting = false;
      if (error.response) {
        Toast.error(error.response.data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  savedFeedback(feedback) {
    this.activeTreatment.state = feedback.user_treatment.state;
    this.$store.commit(
      'user_treatment_list/setUserTreatmentState',
      feedback.user_treatment,
    );
    this.getListFeedback();
  }

  async getListFeedback() {
    this.isLoading = true;
    const self = this;
    const payload = {
      user_treatment_id: this.activeTreatment.id,
      with: 'userTreatment.menu,plans,doctor',
    };
    await ApiHelper.getApi('PatientV2Api')
      .getKarteList(this.currentPatient.id, payload)
      .then(function(res, error) {
        self.medicalRecord.feedbackList = res.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      });
  }

  async getAnswerData() {
    if (this.activeTreatment.menu) {
      this.$store.commit('treatment_detail/setLoadingChild', true);
      await ApiHelper.getApi('PatientV2Api')
        .getReservation(this.currentPatient.id, {
          user_treatment_id: this.activeTreatment.id,
          with: 'answers,userTreatment.menu',
        })
        .then((res: any) => {
          this.userReservation = res.data;
          this.medicalRecord.answer = res.data.answers;
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.commit('treatment_detail/setLoadingChild', false);
        });
    }
  }

  getTimeSection(time: Moment) {
    if (!time) {
      return;
    }

    if (time.isSame(moment(), 'date')) {
      return '今日';
    }

    return time.format('YYYY年MM月DD日');
  }

  async infiniteHandler($state) {
    const response = await ApiHelper.getApi('MessageApi').getMessages(
      this.currentChannel.id,
      this.pagination,
    );
    const { data, last_page, current_page } = response;
    let newMessageList: any = [];
    // stop load more if data is empty.
    if (data.length === 0) {
      return $state.complete();
    }

    data.forEach((newMessage) => {
      // not found in oldMessageList.
      if (
        !this.messages.some((oldMessage) => newMessage.id === oldMessage.id)
      ) {
        newMessageList.push(newMessage);
      }
    });

    let prevMessage = this.messages.at(-1) || {};
    newMessageList = newMessageList.reverse().map((message) => {
      message.timeSection = this.calculateSectionTime(
        prevMessage.created_at,
        message.created_at,
      );
      prevMessage = message;

      return message;
    });
    this.messages.unshift(...newMessageList);

    this.setPagination({ page: current_page + 1 });
    // when page > last page -> stop load more.
    this.pagination.page <= last_page ? $state.loaded() : $state.complete();

    // reset unread message when load messages and check message unread is > 0.
    // todo
    this.readAllMessages();
  }

  calculateSectionTime = (prevCreatedAt: string, currentCreatedAt: string) => {
    if (!prevCreatedAt && !!currentCreatedAt) {
      return moment(currentCreatedAt).isBefore(moment(), 'date')
        ? moment(currentCreatedAt)
        : '';
    }

    if (moment(prevCreatedAt).isBefore(moment(currentCreatedAt), 'date')) {
      return moment(currentCreatedAt);
    }

    return null;
  }

  onReceiveMessage(data) {
    if (data.channel_id != this.currentChannel.id) {
      return;
    }
    // reset unread message.
    this.readAllMessages();

    // push new message to state if this message is not exists state.
    if (!this.messages.find((message) => message.id === data.id)) {
      this.messages.push(data);
    }
    this.scrollToBottom(200);
  }

  scrollToBottom(duration: number = 0) {
    this.$nextTick(() => {
      const container = document.querySelector('.messages-history')!;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    });
  }

  isMessageText(item): boolean {
    // return [MESSAGE_TYPE.TEXT, MESSAGE_TYPE.PAYING, MESSAGE_TYPE.FOR_DOCTOR].includes(item.type_message)
    return MESSAGE_TYPE.TEXT === item.type_message && !this.isMessageUrl(item);
  }

  isMessageTemplate(item): boolean {
    return MESSAGE_TYPE.TEXT === item.type_message && this.isMessageUrl(item);
  }

  isMessageImage(item): boolean {
    return MESSAGE_TYPE.IMAGE === item.type_message;
  }

  isMessageLink(item): boolean {
    return MESSAGE_TYPE.LINK === item.type_message;
  }

  isMessageButton(item): boolean {
    return MESSAGE_TYPE.BUTTON === item.type_message;
  }

  isMessageLabel(item): boolean {
    return MESSAGE_TYPE.LABEL === item.type_message;
  }

  isMessageClinicPattern(item): boolean {
    return MESSAGE_TYPE.CLINIC_PATTERN === item.type_message;
  }

  isMessageUrl(item) {
    const pattern = new RegExp(URL_REGEX);
    return !!pattern.test(item.message_content);
  }

  // call api reset unread message when focus input.
  async readAllMessages() {
    await ApiHelper.getApi('MessageApi').readAllMessages(
      this.currentChannel.id,
    );
  }

  public async loadChannel() {
    this.$store.commit('treatment_detail/setLoadingChild', true);
    this.setChannel(
      await ApiHelper.getApi('ChannelV2Api').show(this.currentPatient.id, {
        doctor_id: this.doctorId,
      }),
    );
    this.$store.commit('treatment_detail/setLoadingChild', false);
  }

  public showModal(modalName) {
    // this.getPatientInfo();
    this.getListFeedback();
    this.medicalRecord.feedbackForm = {
      id: null,
      created_at_jp: null,
      content: '',
      plans: [],
      type: null,
    };
    this.$modal.show('chat-channel-popup');
  }
}
