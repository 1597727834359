









































































import { Vue, Component, Prop } from 'vue-property-decorator';
// Components
import BaseModal from '@/components/common/BaseModal.vue';
import FormAction from '@/components/common/FormAction.vue';
import LazyloadImage from '@components/image/LazyloadImage.vue';
import { State } from 'vuex-class';

import ApiHelper from 'api-helper';
import { Plan } from '@/models/plan';
import Toast from '@common/helpers/toast';
import { Order } from '@/models/order';
import { Patient } from '@/models/patient';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    BaseModal,
    FormAction,
    LazyloadImage,
  },
})
export default class ModalChangePlan extends Vue {
  @Prop(String) readonly name: string;
  @Prop(Boolean) readonly isSubmitting: boolean;
  @State((state) => state.patient_detail.data) patient: Patient;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  isLoading: boolean = false;
  plans: Plan[] = [];
  selectedPlanId = null;
  isShowMsg: boolean = false;
  private order: Order;

  beforeOpen({ params }) {
    this.selectedPlanId = null;
    this.order = {...params.order};
    this.isShowMsg = false;
    this.getPlans();
  }

  getPlans() {
    this.isLoading = true;
    const self = this;
    // const params = this.order.user_treatment?.menu_id ? { menu_ids: [this.order.user_treatment?.menu_id]} : {};
    const params = {user_treatment_id: this.activeTreatment.id};
    ApiHelper
      .getApi('PatientV2Api').getPlansByPatientId(this.patient.id, params)
      .then(function(res) {
        self.plans = res.data.map((item) => new Plan().deserialize(item));
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  choosePlan(plan) {
    this.selectedPlanId = plan.id;
    this.isShowMsg = true;
  }
}
