






import { Vue, Component } from 'vue-property-decorator';
import PharmacistResvList from '@/components/PharmacistReservation/ListItem.vue';

@Component({
  components: {
    PharmacistResvList,
  },
})
export default class PharmacistResvPage extends Vue {}
