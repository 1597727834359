import BaseApi from '../BaseApi';

class MedicalMenuV2Api extends BaseApi {
  delete(id: number, params?: any) {
    return this.del(`/${id}`, params);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/menus';
  }
}

export default MedicalMenuV2Api;
