









































import { Vue, Component, Prop } from 'vue-property-decorator';

// Utils
import Toast from '@common/helpers/toast';
// Icons
import IconUploadFile from '@/assets/img/icon-upload-file.svg';

@Component
export default class DropzoneFile extends Vue {
  @Prop([File, String]) readonly value!: File | string;
  @Prop(String) readonly accept!: string;
  @Prop({default: 'CSV形式のファイルでアップロードしてください'}) readonly invalidTypeText!: string;
  @Prop({default: '5MBを超えるファイルはアップロードできません。'}) readonly invalidSizeText!: string;
  @Prop({default: 5}) readonly maxSize!: number;

  iconUploadFile: string = IconUploadFile;

  get fileName() {
    return (this.value as any).name;
  }

  get formatedMaxSize() {
    return this.maxSize * 1000000;
  }

  validate(file) {
    const isValidType = this.accept.split(', ').includes(file.type);
    if (!isValidType) {
      Toast.error(this.invalidTypeText);
      return false;
    }
    const isValidSize = file.size <= this.formatedMaxSize;
    if (!isValidSize) {
      Toast.error(this.invalidSizeText);
      return false;
    }
    return true;
  }

  handleClick(e) {
    e.preventDefault();
    this.clearFile();
  }

  handleFileChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    if (this.validate(files[0])) {
      this.$emit('input', files[0]);
      return;
    }
    this.clearFile();
  }

  clearFile() {
    (this.$refs.refDrop as any).value = '';
    (this.$refs.refSelect as any).value = '';
  }
}
