























import { Vue, Component, Prop } from 'vue-property-decorator';
import { INotification } from '../../models/notification';
import { NOTIFICATION_STATUS_OPTIONS } from '@/common/app.config';
import { splitLongText } from '@common/helpers/split-long-text';
import moment from 'moment';

@Component
export default class NotificationItem extends Vue {
  @Prop() item!: INotification;

  private get statusText() {
    const item =  NOTIFICATION_STATUS_OPTIONS.find((status) => status.value === this.item.status);
    if (item) { return item.name; }
    return '';
  }

  private dateFormat(value: any) {
    const localDate = new Date(`${value}Z`);
    return moment(localDate).format('YYYY/MM/DD HH:mm');
  }

  private updateText() {
    return moment(this.item.updated_at).format('YYYY/MM/DD HH:mm');
  }

  private splitText(value: string) {
    return splitLongText(value, 30);
  }
}
