import BaseApi from './BaseApi';

class OrderTrackingApi extends BaseApi {

  updateOrderTrackingList(params = {}) {
    return this.put('/update-list', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/order-tracking';
  }
}

export default OrderTrackingApi;
