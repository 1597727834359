import { Deserializable } from '@/common/interfaces/deserializable';
import { INPUT_TYPE } from '@/common/enums/input-type';
export interface IQuestion {
  id: number| string;
  type: string;
  order?: string|number;
  is_new?: boolean;
  question: string;
  description: string;
  required: boolean;
  count_ng_required: boolean;
  woman_only: boolean;
  placeholder?: string;
  multiple: boolean;
  options: string[];
}

export class Question implements Deserializable<Question>, IQuestion {
  id: number| string = '';
  order: number| string = '';
  type: string = 'radio';
  get isCheckbox() {
    return this.type && INPUT_TYPE.CHECKBOX === this.type;
  }
  get isImage() {
    return this.type && INPUT_TYPE.IMAGE === this.type;
  }
  get isTextareaOrRadio() {
    return this.type && ( INPUT_TYPE.RADIO === this.type || INPUT_TYPE.TEXTAREA === this.type );
  }
  is_new: boolean = true;
  question: string = '';
  description: string = '';
  required: boolean = false;
  count_ng_required: boolean = false;
  woman_only: boolean = false;
  multiple: boolean = false;
  placeholder: string = '';
  options: string[] = [
    '',
    '',
  ];

  deserialize(input: Partial<IQuestion>): Question {
    Object.assign(this, input);
    return this;
  }

  toJSONData() {
    return {
      id: this.id,
      type: this.type,
      is_new: this.is_new,
      question: this.question,
      description: this.description,
      required: this.required,
      woman_only: this.woman_only,
      multiple: this.multiple,
      placeholder: this.placeholder,
      options: this.options,
    };
  }
}
