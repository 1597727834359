



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import imageDefault from '@/assets/img/icon-no-image.svg';
import Toast from '@common/helpers/toast';
import { downloadFile } from '@common/helpers/download-file';
import axios from 'axios';
import ImagePrivate from '@/components/common/ImagePrivate.vue';

@Component({
  components: {
    ImagePrivate,
  },
})
export default class DowloadImage extends Vue {
  @Prop([String, File]) readonly value!: string | File;
  @Prop(String) readonly urlPath!: string;
  @Prop(String) readonly alt!: string;
  @Prop(String) readonly imageFile!: string;

  private isLoading: boolean = false;
  private imageAccept = ['image/jpeg', 'image/png', 'image/jpg'];
  private imagePreview: string | File = this.value;
  private file: File | null = null;
  private url: any = '';

  private get src() {
    return this.url || this.imagePreview;
  }

  private get imageDefault() {
    return imageDefault;
  }

  private onChangeImage(e) {
    const files = e.target.files;
    if (files && files[0]) {
      const { type, size } = files[0];
      if (!this.imageAccept.includes(type)) {
        Toast.error('jpgやjpegやpng形式のファイルでアップロードしてください');
        return;
      }
      if (this.calculateMB(size) > 3) {
        Toast.error('3MB以下の写真をアップロードしてください');
        return;
      }
      this.file = files[0];
      this.url = URL.createObjectURL(this.file);
      this.$emit('change', this.file);
    }
  }

  private calculateMB(byte) {
    return Number(byte) / 1048576;
  }

  private dowloadImage() {
    if (!this.value && !this.imageFile) { return; }
    const elem = window.document.createElement('a');
    elem.href = downloadFile(this.imageFile);
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }


  @Watch('value')
  private onValueChanged(val: string, oldVal: string) {
    if (!val || typeof val === 'string') {
      this.imagePreview = val;
    } else {
      const file = val;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imagePreview = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
  @Watch('src')
  private onSrcChanged(val: string, oldVal: string) {
    if (oldVal === imageDefault) {
      this.isLoading = true;
    }
  }

  private created() {
    this.imagePreview = '';
    library.add(faDownload);
    dom.watch();
  }

  private destroyed() {
    URL.revokeObjectURL(this.url);
  }
}
