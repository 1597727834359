




























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { formatDateTime } from '@/common/app.config';
import Toast from '@common/helpers/toast';
import { ToggleButton } from 'vue-js-toggle-button';
import moment from 'moment';
import ApiHelper from 'api-helper';
import cloneDeep from 'lodash/cloneDeep';
import sanitizeHtml from 'sanitize-html';

@Component
export default class FAQItem extends Vue {
  @Prop({ required: true }) index!: number;
  @Prop({ required: true }) editIndex!: number|string;
  @Prop({ required: true }) openIndex!: number|string;
  @Prop({ required: true }) faq!: any;
  private oldFaq: any = {};
  private isToogle = false;
  private isTopPage = false;
  private isUpdating: boolean = false;
  private isActive: boolean = false;
  private titleMessages = {
    required: '質問の内容を入力してください',
    max: '質問の内容は255文字以内に入力してください',
  };

  private contentMessages = {
    required: '回答を入力してください',
    max: '回答は1000文字以内に入力してください',
  };

  created() {
    if (this.index === 0) {
      this.isToogle = true;
    }
  }

  private onChangeShowTopPage(event) {
    this.faq.show_top_page = event.target.checked;
  }

  private enter(element) {
    const width = getComputedStyle(element).width;
    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';
    const height = getComputedStyle(element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = 0;
    requestAnimationFrame(() => {
      element.style.height = height;
    });
  }
  private afterEnter(element) {
      element.style.height = 'auto';
  }

  private leave(element) {
    const height = getComputedStyle(element).height;
    element.style.height = height;
    requestAnimationFrame(() => {
      element.style.height = 0;
    });
  }

  private mounted() {
    this.oldFaq = cloneDeep(this.faq);
  }

  private get isDisableBtn() {
    const isLengthError = this.faq.title.length > 255 || this.faq.content.length > 1000;
    return this.faq.title === '' || this.faq.content === '' || isLengthError;
  }

  private get content() {
    return sanitizeHtml(this.faq.content.replace(/\r\n|\r|\n/g, '<br />'));
  }

  @Watch('editIndex')
  private onChangeEditIndex(value) {
    if (value === this.faq.id) {
      this.isToogle = true;
      this.isUpdating = true;
    } else if (this.faq.newQuestion) {
      this.isToogle = true;
      this.isUpdating = true;
    } else {
      this.isUpdating = false;
      this.restoreData();
    }
  }

  private restoreData() {
    if (this.oldFaq) {
      this.faq.title = this.oldFaq.title;
      this.faq.content = this.oldFaq.content;
      this.faq.show_top_page = this.oldFaq.show_top_page;
    }
  }

  private deleteFAQ() {
    this.$emit('delete', this.faq);
  }

  private saveFAQ() {
    this.isUpdating = false;
    this.updateFAQ(this.faq);
    return;
  }

  private dateFormat(value) {
    if (!value) { return ''; }
    return moment(value).format(formatDateTime);
  }

  private toogleAnswer() {
    if (this.isUpdating) { return; }
    this.isToogle = !this.isToogle;
  }

  private async createFAQ() {
    try {
      this.$emit('setLoading', true);
      const params = {
        ...this.faq,
        show_top_page: this.faq.show_top_page ? 1 : 0,
      };
      const data = await ApiHelper.getApi('FAQApi').create(params);
      data.show_top_page = data.show_top_page === 1 ? true : false;
      this.oldFaq = cloneDeep(data);
      this.faq.updated_at = data.updated_at;
      this.faq.newQuestion = false;
      this.faq.id = data.id;
      Toast.success('作成しました');
      this.isUpdating = false;
      this.isToogle = true;
      this.$emit('edit', '');
      this.$emit('opened', '');
    } catch (err) {
      Toast.error(err.response.data.message);
    } finally {
      this.$emit('setLoading', false);
    }
  }

  private async updateFAQ(faq) {
    try {
      this.$emit('setLoading', true);
      const params = {
        title: faq.title,
        content: faq.content,
        show_top_page: faq.show_top_page ? 1 : 0,
      };
      const data = await ApiHelper.getApi('FAQApi').update(faq.id, params);
      this.faq.updated_at = data.updated_at;
      this.oldFaq = cloneDeep(this.faq);
      this.$emit('edit', '');
      this.$emit('reload');
      Toast.success('更新しました');
    } catch (err) {
      this.restoreData();
      Toast.error(err.response.data.message);
    } finally {
      this.$emit('setLoading', false);
    }

  }

  private async toggleUpdate() {
    if (this.isUpdating) {
      this.isUpdating = false;
      this.isToogle = false;
      return;
    }
    this.isToogle = true;
    this.isUpdating = true;
    this.$emit('edit', this.faq.id);
  }
}
