






























import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import ImageZoomComponent from './ImageZoomComponent.vue';

@Component({
  components: {
    ImagePrivate,
    ImageZoomComponent,
  },
})
export default class PatientDetailImageDescription extends Vue {
  @Prop(String) readonly avatar!: string;
  @Prop(Object) readonly dataDetail!: object;
  @Prop(Object) readonly month!: object;
  private monthData: any = {};

  mounted() {
    this.monthData = this.month;
  }

  @Watch('month')
  private monthMouthData() {
    this.monthData = this.month;
  }

}
