import BaseApi from './BaseApi';

class ZipcodeApi extends BaseApi {

  protected getSpecificApiPrefix(): string {
    return 'guest/postal-code';
  }

}

export default ZipcodeApi;
