import { Deserializable } from '@common/interfaces/deserializable';

export interface ClinicInput {
  id: number;
  name: string;
  name_kana: string;
}

export class Clinic implements Deserializable<Clinic>, ClinicInput {
  id!: number;
  name: string = '';
  name_kana: string = '';

  deserialize(input: Partial<ClinicInput>): Clinic {
    Object.assign(this, input);
    return this;
  }
}
