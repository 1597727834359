






















import { Vue, Component, Prop } from 'vue-property-decorator';
import FullCalendar from '@fullcalendar/vue';
import jaLocale from '@fullcalendar/core/locales/ja';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

// Constants
import { formatDate } from '@common/app.config';
import {
  CALENDAR_GRID_VIEW,
  CALENDAR_MONTH,
  CALENDAR_WEEK,
  CALENDAR_DATE,
} from '@/components/common/BaseFullCalendar/constants';
const { GRID_MONTH, GRID_WEEK, GRID_DAY } = CALENDAR_GRID_VIEW;

@Component({
  components: {
    FullCalendar,
  },
})
export default class BaseFullCalendar extends Vue {
  @Prop(Array) readonly events!: [];
  @Prop({ default: GRID_MONTH }) readonly defaultView!: string;
  @Prop({ default: false }) readonly displayEventTime!: boolean;

  type = CALENDAR_MONTH;
  gridView = this.defaultView;
  jaLocale = jaLocale;
  calendarPlugins = [dayGridPlugin, timeGridPlugin];
  header = {
    left: `${GRID_MONTH},${GRID_WEEK},${GRID_DAY} title`,
    center: '',
    right: 'prev,next',
  };
  customButtons = {
    dayGridMonth: {
      text: '月',
      click: () => {
        this.calendarApi.changeView(GRID_MONTH);
        this.type = CALENDAR_MONTH;
        this.gridView = GRID_MONTH;
        const data = {
          type: this.type,
          gridView: this.gridView,
          date: this.formatDateTime(this.calendarApi.getDate(), formatDate),
        };
        this.$emit('onChangeGrid', data);
      },
    },
    timeGridWeek: {
      text: '週',
      click: () => {
        this.calendarApi.changeView(GRID_WEEK);
        this.type = CALENDAR_WEEK;
        this.gridView = GRID_WEEK;
        const data = {
          type: this.type,
          gridView: this.gridView,
          date: this.formatDateTime(this.calendarApi.getDate(), formatDate),
        };
        this.$emit('onChangeGrid', data);
      },
    },
    timeGridDay: {
      text: '日',
      click: () => {
        this.calendarApi.changeView(GRID_DAY);
        this.type = CALENDAR_DATE;
        this.gridView = GRID_DAY;
        const data = {
          type: this.type,
          gridView: this.gridView,
          date: this.formatDateTime(this.calendarApi.getDate(), formatDate),
        };
        this.$emit('onChangeGrid', data);
      },
    },
    prev: {
      click: () => {
        this.calendarApi.prev();
        const data = {
          type: this.type,
          gridView: this.gridView,
          date: this.formatDateTime(this.calendarApi.getDate(), formatDate),
        };
        this.$emit('onChangeGrid', data);
      },
    },
    next: {
      click: () => {
        this.calendarApi.next();
        const data = {
          type: this.type,
          gridView: this.gridView,
          date: this.formatDateTime(this.calendarApi.getDate(), formatDate),
        };
        this.$emit('onChangeGrid', data);
      },
    },
  };

  get calendarApi() {
    const fullCalendar: any = this.$refs.fullCalendar;
    const calendarApi = fullCalendar.getApi();
    return calendarApi;
  }

  formatDateTime(value: string, format: string) {
    return this?.$options?.filters?.format_time(value, format);
  }
}
