import Index from '@/views/order/index.vue';
import {authSAdminAdminCustomer} from '@/common/constants/auth';
import OrderDetail from '@/views/order/OrderDetail.vue';

export default [
  {
    path: '/orders',
    name: 'OrderList',
    component: Index,
    meta: authSAdminAdminCustomer,
  },
  {
    path: '/orders/:id',
    name: 'OrderDetail',
    component: OrderDetail,
    meta: authSAdminAdminCustomer,
  },
];
