

















































































import { UserTreatment } from '@/models/user-treatment';
import LocalStorageUtils from '@common/LocalStorageUtils';
import { IMAGES_MIME, MAX_FILE_SIZE, Roles } from '@common/app.config';
import { MESSAGE_TYPE } from '@common/enums/message';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

/**************** Const ****************/
const MAX_LENGTH_MESSAGE = 2000;

@Component({
  components: {},
})
export default class MessageInput extends Vue {
  /**************** setter ****************/

  /**************** getter ****************/

  get fileInput() {
    return this.$refs.fileInput as any;
  }

  get channelId() {
    return this.currentChannel.id;
  }
  /**************** state ****************/
  @State((state) => state.auth.currentUser) currentUser!: any;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;
  @Prop() currentChannel!: any;
  Roles = Roles;

  public message: string = '';
  public isShiftEnter = LocalStorageUtils.getItem('isShiftEnter') || true;
  public isSending: boolean = false;
  public isCalling: boolean = false;
  public isLoading: boolean = false;

  public accept = IMAGES_MIME.join();

  /**************** life cycle ****************/

  /**************** methods ****************/

  sendMessageWhenKeyPress(event) {
    if (event.shiftKey) {
      if (!this.isShiftEnter) {
        return;
      }
      this.sendTextMessage(event);
      return;
    }

    if (this.isShiftEnter) {
      return;
    }

    this.sendTextMessage(event);
  }

  sendTextMessage(event) {
    if (this.message) {
      const params = {
        channelId: this.currentChannel.id,
        message: this.message,
        image: null,
        type: MESSAGE_TYPE.TEXT,
      };

      this.sendMessageToUser(params);
    }
    event.preventDefault();
  }

  onPaste(event) {
    if (event.clipboardData) {
      const content = event.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, content);
    }
    if (this.message.length > MAX_LENGTH_MESSAGE) {
      this.message = this.message.substr(0, MAX_LENGTH_MESSAGE);
    }
  }

  checkMessageLength(event) {
    if (this.message.length >= MAX_LENGTH_MESSAGE) {
      if (!(event.which < 0x20)) {
        event.preventDefault();
        return;
      }
    }
  }

  sendAttachment($event) {
    const files = ($event.target as HTMLInputElement).files as any;
    if (this.isSending || !files.length) {
      return;
    }
    const file = files.item(0);
    if (file.size > MAX_FILE_SIZE) {
      Toast.error('サイズが5MBを超える画像はアップロードできません');
      return;
    }

    if (!IMAGES_MIME.includes(file.type)) {
      Toast.error('jpg又はpng形式でアップロードしてください');
      return;
    }
    const params = {
      channelId: this.currentChannel.id,
      message: null,
      image: file,
      type: MESSAGE_TYPE.IMAGE,
    };
    this.sendMessageToUser(params);
  }

  async sendMessageToUser({ channelId, message, image, type }) {
    this.message = '';
    const response = await ApiHelper.getApi('MessageApi').sendMessage(
      channelId,
      {
        message,
        image,
        type,
        user_treatment_id: this.activeTreatment.id,
      },
    );
  }

  updateIsShiftEnter() {
    LocalStorageUtils.saveItem('isShiftEnter', this.isShiftEnter);
  }

  setTagAura(tagIds, tagsDelete) {
    const params = {
      tags: tagIds,
      is_add_and_delete: tagsDelete ? 1 : 0,
      line_id: (this.currentChannel as any).users[0].line_id,
      delete_after_add: tagsDelete || [],
    };
    ApiHelper.getApi('AuraApi').addAuraTag(params);
  }

  removeTagAura(tagsDelete) {
    const params = {
      tags: tagsDelete,
      is_add_and_delete: 0,
      line_id: (this.currentChannel as any).users[0].line_id,
    };
    ApiHelper.getApi('AuraApi').removeAuraTag(params);
  }

  addTreatment(body) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    ApiHelper.getApi('CourseApi')
      .suggestTreatmentCourses(body)
      .then((response: any) => {
        if (response) {
          if (body.tooth_extraction === 2) {
            this.setTagAura([923622], null);
          }
          this.setTagAura([863285, 837092], [927453]);
          Toast.success('処方プラン提案を送信しました');
          this.closeTreatmentPopup();
          // this.lifecycleCourse = true;
        } else {
          Toast.error('入力した治療期間と該当なコードがありません');
        }
      })
      .catch((err: any) => {
        Toast.error(err.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  closeTreatmentPopup() {
    this.$modal.hide('popup-add-treatment');
  }

  toCalling() {
    if (this.isCalling) {
      return;
    }

    const lineId = this.currentChannel.users[0].line_id;
    const routeData = this.$router.resolve({
      name: 'calling',
      params: {
        channeltoken: this.currentChannel.channel_token,
        chatId: this.$route.params.channelId,
        userName: this.currentChannel.users[0].name,
      },
      query: {
        lineId,
        channelId: this.currentChannel.channel_uuid,
        userName:
          this.currentChannel.users[0].first_name +
          ' ' +
          this.currentChannel.users[0].last_name,
      },
    });
    window.open(routeData.href, '_blank');
  }
}
