

























































































































import { Vue, Component } from 'vue-property-decorator';

import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import Toast from '@/common/helpers/toast';
import PopupListMedicine from '@/components/Plan/PopupListMedicine.vue';
import PageLoader from '@components/common/PageLoader.vue';
import UploadImage from '@/components/common/UploadImage.vue';
import { Plan } from '@/models/plan';
import imageDefault from '@/assets/img/icon-no-image.svg';
import plan from '@/routers/plans';
import { Regexs } from '@/common/constants/regex';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';


@Component({
  components: {
    PageLoader,
    UploadImage,
    PopupListMedicine,
    DragDropFileUpload,
  },
})
export default class CreateEdit extends Vue {
  @State((state) => state.plan.plan) plan: Plan;
  planDefault: Plan;
  isLoading: boolean = false;
  isEdit: boolean = false;
  private medicineChoosend: any = [];

  get src() {
    return this.plan.image_url || imageDefault;
  }

  mounted() {
    if (this.$route.params.hasOwnProperty('id')) {
      this.isEdit = true;
      this.getPlanDetail(this.$route.params.id);
    }
    this.medicineChoosend = this.plan.medicines && this.$route.params.isConfirm ? this.plan.medicines : [];

    if (!this.$route.params.isConfirm) {
      this.resetForm();
    }
  }

  getPlanDetail(id?: string) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    ApiHelper.getApi('PlanApi')
      .getOne(id, { with: 'medicines,withTrashed' })
      .then((res: any) => {
        if (!this.plan.created_at) {
          this.planDefault = new Plan().deserialize(res);
        }
        this.$store.state.plan.plan = new Plan().deserialize(res);
        this.medicineChoosend = this.plan.medicines;
        this.plan.amount = this.plan.amount.replace(Regexs.numberWithCommas, ',');
      })
      .catch((error: any) => {
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  updatePlan() {
    if (this.isLoading) {
      return;
    }

    let formData = this.plan.formJSONData(this.isEdit);
    if (this.plan.image && (typeof this.plan.image === 'object')) {
      formData = this.plan.formJSONDataHaveImage(this.isEdit);
    }
    this.isLoading = true;

    ApiHelper.getApi('PlanApi')
      .updatePlan(this.plan.id, formData)
      .then((res: any) => {
        Toast.success('更新しました');
        this.$router.push({ name: 'plans.detail', params: { id: `${this.plan.id}` } });
      })
      .catch((error: any) => {
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }


  private onChooseMedicine(list) {
    this.medicineChoosend = list;
  }

  private onDeleteMedicine(id) {
    this.medicineChoosend = this.medicineChoosend.filter((x) => (x as any).id !== id);
  }

  private updateForm(field: string, value: any) {
    this.handleFile(value);
    this.$set(this.$store.state.plan.plan, field, value);
  }

  private handleFile(val) {
    if (typeof val === 'object') {
      const file = val;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.$set(this.plan, 'image_url', event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  private setMedicineIdArr(medicines) {
    if (medicines.length > 0) {
      this.plan.medicine_ids = this.medicineChoosend.map((x) => (x as any).id);
      this.plan.medicines = this.medicineChoosend;
    }
  }

  private isNumber(event) {
    if (event.target.value.trim().length === 9) {
      return event.preventDefault();
    }
    const keyCode = event.charCode || event.keyCode;
    const currentVal = String.fromCharCode(keyCode);
    if (!/[0-9\,]/.test(currentVal)) {
      event.preventDefault();
    }
  }

  private onInputFee() {
    if (!Regexs.onlyNumberWithCommas.test(this.plan.amount)) {
      this.plan.amount = '';
      return;
    }
    this.plan.amount = this.changeStrMoney(this.plan.amount);
  }

  private changeStrMoney(num: string) {
    const strTrimComma = String(num).replace(/\,/g, '').slice(0, 9);
    if (/^(0)/.test(strTrimComma)) {
      return '0';
    }

    return strTrimComma.replace(Regexs.numberWithCommas, ',');
  }

  private actionCancel() {
    if (this.isEdit) {
      this.$router.push({ name: 'plans.detail', params: { id: `${this.plan.id}` } });
      return;
    }
    this.resetForm();
    this.$router.push({ name: 'plans' });
  }

  private actionSubmit() {
    this.setMedicineIdArr(this.medicineChoosend);
    this.plan.amount = String(this.plan.amount).replace(/\,/g, '');
    if (this.isEdit) {
      this.updatePlan();
      return;
    }
    this.$store.state.plan.plan = this.plan;
    this.$router.push({ name: 'plans.confirm' });
  }

  private resetForm() {
    this.$store.state.plan.plan = new Plan();
  }
}
