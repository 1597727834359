import BaseApi from './BaseApi';

class DoctorShiftApi extends BaseApi {
  public list(params: any) {
    return this.get('/', params);
  }
  public create(payload: any) {
    return this.post('/', payload);
  }
  public update(id, payload: any) {
    return this.put(`/${id}`, payload);
  }
  public delete(id) {
    return this.del(`/${id}`);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/doctor-shifts';
  }
}

export default DoctorShiftApi;
