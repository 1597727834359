import { authAdminDoctor } from '@/common/constants/auth';
import ShiftSetting from '@/views/doctor/ShiftSetting.vue';
import { Roles } from '@common/app.config';

const authMeta =  {
  auth: true,
  authorize: [Roles.doctor, Roles.admin, Roles.superAdmin, Roles.pharmacist],
};

export default [
  {
    path: 'doctor-shifts',
    name: 'doctor-shift',
    component: ShiftSetting,
    meta: authMeta,
  },
];
