


























import { Vue, Component } from 'vue-property-decorator';

// Utils
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
// Components
import DropzoneFile from '@/components/common/DropzoneFile.vue';
import FormAction from '@/components/common/FormAction.vue';

@Component({
  components: {
    DropzoneFile,
    FormAction,
  },
})
export default class ModalImportDeliveryCSV extends Vue {
  file: File | string = '';
  isSubmiting: boolean = false;

  mouted() {
    this.$modal.show('ModalImportDeliveryCSV');
  }

  handleCancellation() {
    this.file = '';
    this.$modal.hide('ModalImportDeliveryCSV');
  }

  async handleSubmit() {
    try {
      const payload = new FormData();
      payload.append('file', this.file);
      this.isSubmiting = true;
      await ApiHelper.getApi('ManagerApi').deliveryImport(payload);
      this.isSubmiting = false;
      Toast.success('アップロードしました');
      this.handleCancellation();
      this.$emit('onUploadSuccess');
    } catch (error) {
      this.isSubmiting = false;
      const data = error?.response?.data;
      Toast.error(data?.message || 'アップロードに失敗しました');
    }
  }
}
