import BaseApi from './BaseApi';

class ReasonApi extends BaseApi {

  protected getSpecificApiPrefix(): string {
    return 'managers/list-reason';
  }

}

export default ReasonApi;
