import BaseApi from './BaseApi';

class OrderTrackingHistoryApi extends BaseApi {

  protected getSpecificApiPrefix(): string {
    return 'managers/orders-tracking-history';
  }
}

export default OrderTrackingHistoryApi;
