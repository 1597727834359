















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SearchInput extends Vue {
  @Prop(String) readonly placeholder!: string;
  @Prop({default: ''}) readonly text!: string;
  private freeword = '';
  created()  {
    this.freeword = this.text;
  }
}
