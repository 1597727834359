

































































































import { Vue, Component } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { splitLongText } from '@common/helpers/split-long-text';
import PageLoader from '@components/common/PageLoader.vue';
import PopupClinicPatternDetail from '@components/ClinicPattern/PopupClinicPatternDetail.vue';
import ContentLayout from '@components/layouts/Content.vue';
import Listicon from '@/assets/img/clinicListicon.svg';

@Component({
  components: {
    Pagination,
    PageLoader,
    PopupClinicPatternDetail,
    ContentLayout,
  },
  data() {
    return {
      listClinics: {},
      listPrefecture: [],
      clinicPatternSelect: {},
      iconHeader: Listicon,
    };
  },
})
export default class Index extends Vue {
  public iconHeader: any;
  private page = 1;
  private perPage = 10;
  private pageCount = 1;
  private listClinics!: any;
  private clinicPatternSelect!: any;
  private listPrefecture!: any[];
  private isLoading: boolean = false;

  private searchForm = {
    id: '',
    name: '',
    name_kana: '',
    with: 'managers',
    order: '-created_at',
    per_page: this.perPage,
    page: this.page,
  };

  private longText(text: string, qty: number) {
    return splitLongText(text, qty);
  }

  private async created() {
    if (LocalStorageUtils.getItem('paramsSearch')) {
      LocalStorageUtils.removeItem('paramsSearch');
    }

    this.list(this.searchForm);
  }

  private mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  private prefectureName(id) {
    const name =
      this.listPrefecture.find((item) => item.id === Number(id)) || {};
    return name.ja_name;
  }

  private statusString(status) {
    return status ? '表示' : '非表示';
  }

  private openPopupClinicCreate(clinicData) {
    // if (clinicData) {
    //   this.clinicPatternSelect = clinicData;
    // } else {
    //   this.clinicPatternSelect = {};
    // }
    // this.$modal.show("popupClinicPatternDetail", {
    //   dataUpdate: clinicData || {},
    //   isCreate: true,
    // });
    this.$router.push({path: '/clinic_pattern/create'});
  }

  private searchClinic() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));

    this.searchForm.page = 1;
    this.page = 1;
    this.list(this.searchForm);
  }

  private pageChanged(page) {
    let paramsSearch: any;

    if (LocalStorageUtils.getItem('paramsSearch')) {
      paramsSearch = JSON.parse(LocalStorageUtils.getItem('paramsSearch'));
    } else {
      paramsSearch = Object.assign({}, this.searchForm);
      paramsSearch.id = '';
      paramsSearch.name = '';
      paramsSearch.name_kana = '';
    }

    paramsSearch.page = page;
    this.page = page;
    this.list(paramsSearch);
  }

  private async list(data) {
    try {
      this.isLoading = true;
      this.listClinics = [];
      const response = await ApiHelper.getApi('ClinicPatternApi').list(data);
      this.pageCount = response.last_page;
      this.listClinics = response.data;
      this.isLoading = false;
      const prefectureList = await ApiHelper.getApi(
        'PrefectureApi',
      ).listPrefecture();
      this.listPrefecture = prefectureList.data;
    } catch (error) {
      this.isLoading = false;
      const errors = error.response.data;

      Toast.error(errors.message);
    }
  }
}
