















import { Vue,  Prop, Watch } from 'vue-property-decorator';
import CreateNews from '@components/Settings/News/CreateNews.vue';
import LeaveConfirm from '@components/Settings/News/LeaveConfirm.vue';
import { AuthenticationModule } from '@/store';
import LocalStorageUtils from '@common/LocalStorageUtils';
import Toast from '@common/helpers/toast';
import { mapState } from 'vuex';
import { DashboardModule } from '@/store';

const CreatePost = Vue.extend({
  data() {
    return {
      isChangeData: false,
    };
  },

  created() {
    window.onbeforeunload = this.reloadHandler;
  },

  beforeDestroy() {
    window.onbeforeunload = null;
  },

  components: {
    CreateNews,
    LeaveConfirm,
  },

  computed: {
    ...mapState('auth', ['currentUser']),
  },

  methods: {
    async logout() {
      try {
        (window as any).UA.then(async (sdk: any) => {
          if (!sdk.channel.namedUser) {
            return;
          }
          await sdk.channel.namedUser.remove(`managers_${this.currentUser.id}`);
          await sdk.register();
        });
        await AuthenticationModule.logout();
        await DashboardModule.setClinics([]);
        await LocalStorageUtils.removeItem('dashboardID');
        await LocalStorageUtils.removeItem('revenueClinic');
        await LocalStorageUtils.removeItem('monthlyReportClinic');
        await localStorage.removeItem('isLoggedIn');
      } catch (error) {
        Toast.error(error.response.data.message);
      }
    },

    onChange(isChange) {
      this.isChangeData = isChange;
    },

    leaveConfirm(next, isSignout = false) {
      let options = {
        onOK: () => {
          next();
        },
      };
      if (isSignout) {
        options = {
          onOK: async () => {
            await this.logout();
            next();
          },
        };
      }
      this.$modal.show('leave-confirm', options);
    },

    reloadHandler(event) {
      if (this.isChangeData) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.isChangeData) {
      if (to.name === 'Login') {
        this.leaveConfirm(next, true);
      } else {
        this.leaveConfirm(next);
      }
    } else {
      if (to.name === 'Login') {
        this.logout().then(() => {
          next();
        });
      } else {
        next();
      }
    }
  },
});

export default CreatePost;
