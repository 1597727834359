






























































































import {Vue, Component, Watch, Prop} from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import BasicSelect from '@components/common/BasicSelect.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import PageLoader from '@components/common/PageLoader.vue';

@Component({
  components: {
    BasicSelect,
    DatePicker,
    PageLoader,
  },
})
export default class PopupClinicPatternDetail extends Vue {

  private get disabled() {
    return !this.reservation.clinic.id || !this.reservation.start_date;
  }
  private isUpdate: boolean = false;
  private resvId: number;
  private lang = { yearFormat: 'YYYY年' };
  private isLoading: boolean = true;
  private isFirstLoading: boolean = true;
  private isMounted: boolean = false;
  private listPrefectureData: any[] = [];
  private listClinicByPrefecture: any[] = [];
  private firstPrefecture: number;

  private prefecture: any = {
    ja_name: '',
    id: null,
  };

  private userId: string = '';

  private reservation: any = {
    clinic: {},
    start_date: '',
  };

  private today = moment();

  closePicker() {
    (this.$refs['datepickerAdmin'] as any).closePopup();
  }

  @Watch('prefecture')
  private watchPrefecture(value) {
    if (value.id) {
      this.isLoading = true;
      ApiHelper.getApi('ResvClinicPatternApi')
        .getClinicByPrefecture(value.id)
        .then((response) => {
          if (this.firstPrefecture !== value.id) {
            this.reservation = {
              ...this.reservation,
              clinic: {},
            };
            this.firstPrefecture = value.id;
          }
          this.listClinicByPrefecture = response.data;
        })
        .catch((error) => {
          if (error.response) {
            const data = error.response.data;
            return Toast.error(data.message);
          }
          Toast.error(error.message);
        })
      .finally(() => {
        this.isLoading = false;
      });
    }
  }
  private notBeforeToday(date) {
    return date < this.today.clone().startOf('date');
  }

  private notBeforeTime(date) {
    const year = this.today.clone().year();
    const month = this.today.clone().month();
    const day = this.today.clone().date();
    const hour = this.today.clone().hour() - 1;
    const minute = this.today.clone().minute();
    return date < new Date(year, month, day, hour, minute);
  }

  private async createReservationClinic() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const params = {
      start_date: moment(this.reservation.start_date).format('YYYY-MM-DD HH:mm'),
      clinic_pattern_id: this.reservation.clinic.id,
      user_id: this.userId,
    };
    ApiHelper.getApi('ResvClinicPatternApi')
      .create(params)
      .then((newResv) => {
        const newResvFormat = {
          ...newResv,
          start_date: moment(this.reservation.start_date).format('YYYY/MM/DD HH:mm'),
        };
        this.$emit('setNewResv', newResvFormat);
        Toast.success('作成しました');
        this.closeModal();
      })
      .catch((error) => {
        if (error.response) {
          const data = error.response.data;
          return Toast.error(data.message);
        }
        Toast.error(error.message);
      })
    .finally(() => {
      this.isLoading = false;
    });
  }

  private resetData() {
    this.prefecture = {
      ja_name: '',
      id: null,
    };
    this.reservation = {
      clinic: {},
      date: '',
    };
  }

  private handleReservation() {
    if (this.isUpdate) {
      this.updateReservationClinic();
    } else {
      this.createReservationClinic();
    }
  }

  private async updateReservationClinic() {
    this.isLoading = true;
    const params = {
      start_date: moment(this.reservation.start_date).format('YYYY-MM-DD HH:mm'),
      clinic_pattern_id: this.reservation.clinic.id,
      user_id: this.userId,
    };
    try {
      const newResv = await ApiHelper.getApi('ResvClinicPatternApi').update(this.resvId, params);
      const newResvFormat = {
        ...newResv,
        start_date: moment(this.reservation.start_date).format('YYYY/MM/DD HH:mm'),
      };
      this.$emit('setNewResv', newResvFormat);
      Toast.success('更新しました');
      this.closeModal();
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        return Toast.error(data.message);
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }

  private beforeClose() {
    this.isLoading = true;
    this.isFirstLoading = true;
    this.resetData();
  }

  private closeModal() {
    this.$modal.hide('popup-add-date-to-clinic');
    this.resetData();
  }

  private async beforeOpen(event) {
    try {
      const prefectureList = await ApiHelper.getApi('ResvClinicPatternApi').getPrefectureHasClinic();
      this.listPrefectureData = prefectureList.data;
      this.userId = event.params.userId;
      if (event.params.resvId) {
        this.resvId = event.params.resvId;
        this.reservation = event.params.dataResvClinicPattern;
        const prefectureSelected = prefectureList.data.find((prefecture) => {
          return prefecture.id === Number(event.params.dataResvClinicPattern.clinic.prefecture_id);
        });
        this.prefecture = prefectureSelected;
        this.firstPrefecture = prefectureSelected.id;
      }
      this.isUpdate = !!event.params.resvId;
      this.isMounted = true;
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        return Toast.error(data.message);
      }
      Toast.error(error.message);
    } finally {
      this.isLoading = false;
      this.isFirstLoading = false;
    }
  }

  private mounted() {
    dom.watch();
  }
}
