import Vue from 'vue';

export const CurrencyNumber = {
  bind: (el: any) => {
    el.addEventListener('keydown', (event: KeyboardEvent) => {
      const regex: RegExp = new RegExp(/^[0-9\,]{1,11}$/);
      const specialKeys: number[] = [8, 9, 13, 35, 36, 37, 38, 39, 40, 46];

      if (specialKeys.indexOf(event.keyCode) !== -1) {
        return;
      }

      const current = el.value;
      const next = current.concat(event.key).replace(/\b0+/g, '');

      if (next && !String(next).match(regex)) {
        event.preventDefault();
      }
    });
  },
};

Vue.directive('currency-number', CurrencyNumber);
