






















































import { Vue, Component, Mixins } from 'vue-property-decorator';
import { DataForm, defaultDataFormEdit } from '@/models/pharmacist';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { Roles } from '@/common/app.config';
import ApiHelper from 'api-helper';
import UploadImage from '@/components/common/UploadImage.vue';
import TablePharmacistForm from './TablePharmacistForm.vue';
import PharmacistMixin from '@common/mixins/Pharmacist/PharmacistMixin';

@Component({
  components: {
    UploadImage,
    TablePharmacistForm,
    PopupConfirm,
    PageLoader,
  },
})
export default class PharmacistUpdate extends Mixins(PharmacistMixin) {
  // Data
  private isSubmit: boolean = false;
  private dataForm: DataForm = JSON.parse(JSON.stringify(defaultDataFormEdit));
  private isChanged = false;
  private pharmacistId: any;

  // Computed
  private get computedForm() {
    return this.$refs.pharmacistFormEdit as any;
  }

  // Methods
  private created() {
    const { id } = this.$route.params;
    this.pharmacistId = id;
    this.getPharmacistDetail();
  }

  private async getPharmacistDetail(isImage = false) {
    try {
      if (!isImage) {
        this.isSubmit = true;
      }
      const params = { with: 'pharmacistStores' };
      const response = await ApiHelper.getApi('PharmacistApi').getOne(this.pharmacistId, params);
      const customData = this.getCustomData(response);
      this.dataForm = {...customData};
      this.isSubmit = false;
    } catch (error) {
      this.isSubmit = false;
      if (error.response) {
        const { data } = error.response;
        Toast.error(data.message);
        return;
      }
      Toast.error(error.message);
    }
  }

  private async updateImage(image) {
    try {
      const formData = new FormData();
      if (image) {
        formData.append('avatar', image);
      }
      const response = await ApiHelper.getApi('PharmacistApi').updateImage(
        this.pharmacistId,
        formData,
        );
    } catch (error) {
      const data = error.response.data;
      if (error.response.status === 422) {
        this.computedForm.setErrors(error.response.data.errors);
        return;
      }
      Toast.error(data.message);
    } finally {
      this.getPharmacistDetail(true);
    }
  }

  private getCustomData(data) {
    const mapData: DataForm = {
      id: data.id,
      uuid: data.uuid,
      pharmacist_name: data.name,
      pharmacist_name_kana: data.name_kana,
      stores: data.stores.map((x) => ({
          id: x.id,
          name: x.name,
          name_kana: x.name_kana,
        })),
      pharmacist_phone: data.phone.replace(/^(84|81)/gi, '0'),
      pharmacist_email: data.email,
      avatar: data.avatar_url,
      created_at: data.created_at,
      updated_at: data.updated_at,
    };

    return mapData;
  }
  private updateForm(field: string, value: string | []) {
    this.isChanged = true;
    this.$set(this.dataForm, field, value);
  }

  private async openPopupComfirm() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    this.$modal.show('comfirmUpdatePharmacist');
  }

  private cancelPopupConfirm() {
    this.$modal.hide('comfirmUpdatePharmacist');
  }

  private async updatePharmacist() {
    try {
      this.isSubmit = true;
      const mapParams = {
        pharmacist_email: this.dataForm.pharmacist_email,
        pharmacist_name: this.dataForm.pharmacist_name,
        pharmacist_name_kana: this.dataForm.pharmacist_name_kana,
        pharmacy_ids: this.dataForm.stores.map((x) => (x as any).id),
        pharmacist_phone: this.dataForm.pharmacist_phone.replace(/^0/gi, '81'),
      };
      const response = await ApiHelper.getApi('PharmacistApi').update(this.pharmacistId, mapParams);
      Toast.success('登録しました');
      this.getPharmacistDetail();
    } catch (error) {
      if (error.response) {
        const { data, status } = error.response;
        if (status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
      }
      Toast.error(error.message);
    } finally {
      this.cancelPopupConfirm();
      this.isSubmit = false;
    }
  }
}
