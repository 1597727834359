


















































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import PatientDetailImageDescription from './PatientDetailImageDescription.vue';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    ImagePrivate,
    PatientDetailImageDescription,
  },
  filters: {
    getStatusValue(value) {
      return value ? '再登録を請求中' : '再登録を請求する';
    },
  },
})
export default class PatientDetailImageDescriptionByMonth extends Vue {

  private get listMonth() {
    const listMonth = this.listMonthData.slice(1);
    const listMonthAllImage = listMonth.map((month) => {
      const monthHasMouthpiece = this.listMonthMouthpieceData
        .find((mouthpiece) => mouthpiece.pass_month === month.pass_month);
      if (monthHasMouthpiece) {
        return {
          ...month,
          monthMouthpiece: monthHasMouthpiece,
        };
      }
      return {
        ...month,
        monthMouthpiece: {},
      };
    });
    return listMonthAllImage;
  }

  private get firstMonth() {
    return this.listMonthData && this.listMonthData[0] || {};
  }

  private get firstMonthMouthpiece() {
    const firstMonthMouthpiece = this.listMonthMouthpieceData && this.listMonthMouthpieceData[0] || {};
    if (firstMonthMouthpiece.pass_month && firstMonthMouthpiece.pass_month === this.firstMonth.pass_month) {
      return firstMonthMouthpiece || {};
    }
    return {};
  }
  @Prop(String) readonly avatar!: string;
  @Prop(Object) readonly dataDetail!: object;
  @Prop(Object) readonly listMouthImage!: object;
  @Prop(Boolean) readonly isAdmin!: false;
  private listMonthShow: any = [];
  private statusCheckFail: boolean = false;

  private listMonthData: any = [];
  private listMonthMouthpieceData: any = [];

  mounted() {
    this.listMonthData = (this.listMouthImage as any).dates;
    this.listMonthMouthpieceData = (this.listMouthImage as any).dates_mounth;
    this.setStatusUpload();
  }

  @Watch('listMouthImage')
  private listMouthImageData() {
    this.listMonthData = (this.listMouthImage as any).dates;
    this.listMonthMouthpieceData = (this.listMouthImage as any).dates_mounth;
    this.setStatusUpload();
  }

  private getTitleMonth(month, monthPiece) {
    if (!month.id && !monthPiece.id) {
      return '';
    }
    if (month.no_upload && !monthPiece.upload_status) {
      return `${month.gen_link_display}_${month.pass_month}ヶ月目_写真なし`;
    }
    return `${month.gen_link_display}_${month.pass_month}ヶ月目_写真あり`;
  }

  private checkShow(month) {
    return this.listMonthShow.includes(month);
  }

  private showImage(monthHandle) {
    if (this.listMonthShow.includes(monthHandle)) {
      this.listMonthShow = this.listMonthShow.filter((month) => month !== monthHandle);
    } else {
      this.listMonthShow = [...this.listMonthShow, monthHandle];
    }
  }

  private setStatusUpload() {
    this.statusCheckFail = !!this.firstMonth.require_again_at;
  }

  private changeStatusUpload() {
    const { id } = this.$route.params;
    const body = {
      user_id: id,
      pass_month: this.firstMonth.pass_month,
    };
    ApiHelper.getApi('DoctorMouthApi')
      .reupload(body)
      .then()
      .catch((error) => {
        Toast.error(error.message);
      })
      .finally();
  }

}
