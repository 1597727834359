























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { uiAvatars } from '@common/helpers/ui-avatar';
import imageDefault from '@/assets/img/icon-no-image.svg';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import { Roles } from '@/common/app.config';
import { MESSAGE_TYPE } from '@/common/enums/message';
import { MessageModule } from '@/store';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import { USER_STATUS, getUserStateName } from '@/common/constants/auth';

@Component({
  components: {
    ImagePrivate,
  },
})
export default class ChannelItem extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop({ required: true }) channel!: any;

  private get isPharmacy() {
    if (!this.currentUser) { return false; }
    return this.currentUser.role === Roles.pharmacy;
  }

  get lastMessage() {
    if (!this.channel.latest_message) { return; }
    const lastMessage = this.channel.latest_message;
    if (lastMessage.type_message === MESSAGE_TYPE.IMAGE) {
      if (lastMessage.sender_id === this.currentUser.id) {
        return 'あなたが写真を送信しました';
      } else {
        return `${this.channelName}さんが写真を送信しました`;
      }
    }
    return lastMessage.message_content;
  }

  get unreadCount() {
    if (this.$route.params.channelId === this.channel.id || !this.channel.message_unread_count) {
      return '';
    }
    if (this.channel.message_unread_count > 99) { return '99+'; }
    return this.channel.message_unread_count;
  }

  get channelAvatar() {
    if (this.channel && this.channel.users) {
      const user = this.channel.users[0] || {};
      const name = `${user.family_name} ${user.given_name}`;

      if (!user.avatar_url && !user.family_name && !user.given_name) {
        return imageDefault;
      }
      const avatarUser = user.avatar || uiAvatars(name);
      return avatarUser;
    }
  }

  get channelAvatarText() {
    if (this.channel && this.channel.users) {
      const user = this.channel.users[0] || {};
      if (!user.family_name && !user.given_name) {
        return imageDefault;
      }

      const name = `${user.family_name} ${user.given_name}`;
      return uiAvatars(name);
    }
  }

  get hasChannelAvatar() {
    if (this.channel && this.channel.users) {
      const user = this.channel.users[0] || {};
      if (user.avatar) {
        return true;
      }
    }
    return false;
  }

  private get channelName() {
    if (this.channel && this.channel.users) {
      const user = this.channel.users[0] as any;
      if (this.isPharmacy) {
        return `${user.id}_${user.name || '-' }`;
      }
      return `${user.family_name || ''} ${user.given_name || ''}`.trim() || '-';
    }
  }

  get statusName() {
    if (this.channel && this.channel.users) {
      const user = this.channel.users[0] as any;
      const userStatus = user.state;

      const isDoctor = this.currentUser && this.currentUser.role === Roles.doctor;
      const isCS = this.currentUser && this.currentUser.role === Roles.customerService;

      return getUserStateName(userStatus, isDoctor, isCS);
    }
  }

  async onChanceChannel() {
    // if (this.channel.state === USER_STATUS.CHAT_INTERVIEW_SCHEDULED) {
    this.$emit('change');
    const { commit } = this.$store;
    if (this.channel.message_unread_count > 0) {
      await ApiHelper.getApi('MessageApi').readAllMessages(this.channel.id);
      this.channel.message_unread_count = 0;
    }
    commit('channel/updateActiveChannel', this.channel);

    MessageModule.toggleUserList(false);
  }

  getUserInfo() {
    if (this.channel && this.channel.users[0]) {
      return this.channel.users[0];
    }
    return {
      name: 'NN',
      avatar_url: '',
      id: '',
    };
  }
}
