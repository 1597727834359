






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

// Components
import QuestionListItemInput from '@/views/question/components/QuestionListItem/QuestionListItemInput.vue';
// Icons
import iconAddCircleSmall from '@/assets/img/icon-add-circle-small.svg';

@Component({
  components: {
    QuestionListItemInput,
  },
})
export default class QuestionListItemOptions extends Vue {
  @Prop({}) question!: any;
  @Prop(String) optionEditting: string;
  @Prop({ default: () => [], type: Array }) options: string[];
  @Prop(Boolean) readOnly: boolean;
  @Prop(Boolean) countNgRequired: boolean;

  isValidOptions: boolean = false;

  get iconAddCircleSmall() {
    return iconAddCircleSmall;
  }

  @Watch('question')
  watchQuestion(newVal, oldVal) {
    this.isValidOptions = newVal.options.length;
    this.emitValidOptions();
  }

  mounted() {
    this.isValidOptions = this.question.options.length;
    this.emitValidOptions();
  }

  getOptionId(optionIndex) {
    return `${this.question.id}_${optionIndex}`;
  }

  emitValidOptions() {
    this.$emit('validOptions', {
      id: this.question.id,
      value: !!this.isValidOptions,
    });
  }
}
