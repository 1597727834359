






















import {Component, Mixins, Prop} from 'vue-property-decorator';
import MessageMixin from './Message';
import { URL_REGEX } from '@common/app.config';
import sanitizeHtml from 'sanitize-html';

@Component
export default class MessageTemplate extends Mixins(MessageMixin) {
  @Prop() avatarUser!: any;
  @Prop() avatarManager!: any;
  convertToUrl(content) {
    const pattern = new RegExp(URL_REGEX);
    return sanitizeHtml(content.replace(pattern, '<a href="$1" target="_blank">$1</a>'));
  }
}
