import Vue from 'vue';
import Vuex from 'vuex';
import Authentication from './modules/auth';
import Localization from './modules/locale';
import Clinic from './modules/clinic';
import Doctor from './modules/doctor';
import Message from './modules/message';
import Channel from './modules/message/channel';
import Patient from './modules/patient';
import Resv from './modules/resv';
import Medicine from './modules/medicine';
import BankJP from './modules/bank';
import MedicalMenu from './modules/medical_menu';
import MessageNotify from './modules/messageNotify';
import DashBoard from './modules/dashboard';
import MonthlyReport from './modules/monthly_report';
import Plan from './modules/plan';
import Delivery from './modules/delivery';
import { getModule } from 'vuex-module-decorators';
import PatientList from './modules/v2/patient/index';
import PatientDetail from './modules/v2/patient/detail';
import TreatmentDetail from './modules/v2/patient/treatment/detail';
import UserTreatmentList from './modules/v2/patient/treatment/index';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth: Authentication,
    locale: Localization,
    clinic: Clinic,
    doctor: Doctor,
    channel: Channel,
    patient: Patient,
    message: Message,
    resv: Resv,
    medicine: Medicine,
    bankJP: BankJP,
    medical_menu: MedicalMenu,
    messageNotify: MessageNotify,
    dashboard: DashBoard,
    monthly_report: MonthlyReport,
    plan: Plan,
    delivery: Delivery,
    patient_list: PatientList,
    patient_detail: PatientDetail,
    treatment_detail: TreatmentDetail,
    user_treatment_list: UserTreatmentList,
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
});

export default store;
export const AuthenticationModule = getModule(Authentication, store);
export const LocalizationModule = getModule(Localization, store);
export const ClinicModule = getModule(Clinic, store);
export const DoctorModule = getModule(Doctor, store);
export const ChannelModule = getModule(Channel, store);
export const MessageModule = getModule(Message, store);
export const PatientModule = getModule(Patient, store);
export const ResvModule = getModule(Resv, store);
export const MedicineModule = getModule(Medicine, store);
export const BankJPModule = getModule(BankJP, store);
export const MedicalMenuModule = getModule(MedicalMenu, store);
export const MessageNotifyModule = getModule(MessageNotify, store);
export const DashboardModule = getModule(DashBoard, store);
export const MonthlyReportModule = getModule(MonthlyReport, store);
export const PlanModule = getModule(Plan, store);
export const PatientListModule = getModule(PatientList, store);
export const PatientDetailModule = getModule(PatientDetail, store);
export const TreatmentDetailModule = getModule(TreatmentDetail, store);
export const UserTreatmentListModule = getModule(UserTreatmentList, store);
