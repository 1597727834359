























































































































































































































































import ApiHelper from 'api-helper';
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import {
  Roles,
  DeliveryState,
  DeliveryPharmacistState,
} from '@/common/app.config';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import moment from 'moment';
import lodash from 'lodash';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';

import PageLoader from '@components/common/PageLoader.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@components/common/Pagination.vue';
import LocalStorageUtils from '@common/LocalStorageUtils';
import BasicSelect from '@components/common/BasicSelect.vue';
import ModalImportDeliveryCSV from '@/views/deliveries/components/ModalImportDeliveryCSV.vue';
import { DELIVERY_STATUS, DELIVERY_STATUS_NAME } from '@/common/constants/auth';
import Encoding from 'encoding-japanese';

@Component({
  components: {
    PageLoader,
    Pagination,
    BasicSelect,
    DatePicker,
    ModalImportDeliveryCSV,
  },
  filters: {
    paymentType: (paymentType) => {
      return paymentType == 1 ? '定期' : '単品';
    },
    status: (isShow) => {
      return isShow ? '表示' : '表示しない';
    },
  },
})
export default class ListDelivery extends Vue {
  get isReadyDeliveryData() {
    return !lodash.some(
      this.dataDeliveries,
      (data: any) => data.state !== DELIVERY_STATUS.READY_FOR_DELIVERY,
    );
  }
  get isPaidData() {
    return !lodash.some(
      this.dataDeliveries,
      (data: any) => data.state !== DELIVERY_STATUS.PAID,
    );
  }
  get isPrepareData() {
    return !lodash.some(
      this.dataDeliveries,
      (data: any) => data.state !== DELIVERY_STATUS.PREPARING_FOR_DELIVERY,
    );
  }

  get initialQuery() {
    if (this.$route.query.state) {
      this.isSearched = true;
    }
    return {
      ...this.paginate,
      with: 'order,user,order.plan,order.plan.medicines',
      state: this.$route.query.state || '',
      // delivery_date: this.$route.query.delivery_date || '',
      user_name: this.$route.query.user_name || '',
      id: this.$route.query.id || '',
      delivery_date_from: this.$route.query.delivery_date_from || '',
      delivery_date_to: this.$route.query.delivery_date_to || '',
    };
  }
  get queryParams() {
    return {
      ...this.paginate,
      with: 'order,user,order.plan,order.plan.medicines',
      ...this.query,
      // delivery_date: this.query && this.query.delivery_date ? moment(this.query.delivery_date.toString()).format('YYYY-MM-DD') : '',
      delivery_date_from:
        this.query && this.query.delivery_date_from
          ? moment(this.query.delivery_date_from.toString()).format(
              'YYYY-MM-DD',
            )
          : '',
      delivery_date_to:
        this.query && this.query.delivery_date_to
          ? moment(this.query.delivery_date_to.toString()).format('YYYY-MM-DD')
          : '',
    };
  }

  get isEmptyDataDelivery(): boolean {
    return this.dataDeliveries && !this.dataDeliveries.length;
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  idPlan: number = 0;
  dataDeliveries = [];
  isLoading = false;
  isSearching = false;
  totalPage = 1;
  isSearched = false;

  paginate = {
    page: this.$route.query.page || 1,
    per_page: 10,
  };

  get isPharmacistOrDelivery() {
    return this.currentUser.isPharmacistRole || this.currentUser.isDeliveryRole;
  }

  get searchOptions() {
    return this.isPharmacistOrDelivery
      ? DeliveryPharmacistState
      : DeliveryState;
  }

  get isShowBtnUploadCsv() {
    return (
      this.currentUser.isAdminRole ||
      this.currentUser.isCSRole ||
      this.currentUser.isPharmacistRole ||
      this.currentUser.isDeliveryRole
    );
  }

  get isShowBtnDownloadCsv() {
    return (
      (this.currentUser.isAdminRole ||
        this.currentUser.isCSRole ||
        this.currentUser.isPharmacistRole ||
        this.currentUser.isDeliveryRole) &&
      !this.isSearching &&
      this.dataDeliveries.length > 0
    );
  }

  private DELIVERY_STATUS = DELIVERY_STATUS;
  private searchOption: string | null = null;
  // private searchOptions: any[] = DeliveryState;
  private query = {
    state: this.$route.query.state || this.searchOption,
    // delivery_date: this.$route.query.delivery_date || '',
    user_name: this.$route.query.user_name || '',
    id: this.$route.query.id || '',
    delivery_date_from: this.$route.query.delivery_date_from || '',
    delivery_date_to: this.$route.query.delivery_date_to || '',
  };

  private patientName: string = '';
  private isLoadingSearch: boolean = false;

  isDisableDeliveryDateTo() {
    return this.query.delivery_date_from == '';
  }

  automaticSetDeliveryDateTo() {
    if (this.query.delivery_date_from == '') {
      this.query.delivery_date_to = '';
    } else {
      this.query.delivery_date_to = moment(
        this.query.delivery_date_from.toString(),
      )
        .add(3, 'days')
        .format('YYYY-MM-DD');
    }
  }

  clearDeliveryDateFrom() {
    this.clearDate('delivery_date_from');
    this.clearDate('delivery_date_to');
  }

  transformBlobToFile(data, file) {
    const uniArray = Encoding.stringToCode(data);
    const sjisArray = Encoding.convert(uniArray, {
      to: 'SJIS',
      from: 'UNICODE',
    });
    const unit8Array = new Uint8Array(sjisArray);
    const blob = new Blob([unit8Array], { type: 'text/csv;charset=Shift_JIS' });

    if (window.navigator.msSaveOrOpenBlob) {
      // for IE,Edge
      window.navigator.msSaveOrOpenBlob(blob, file);
    } else {
      // for chrome, firefox
      const url = URL.createObjectURL(blob);
      const linkEl: any = document.createElement('a');
      linkEl.href = url;
      linkEl.setAttribute('download', file);
      document.body.appendChild(linkEl);
      linkEl.click();

      URL.revokeObjectURL(url);
      linkEl.parentNode.removeChild(linkEl);
    }
  }

  async exportDelivery() {
    const queryParams = { ...this.queryParams };
    const removeParams = ['page', 'per_page'];
    removeParams.forEach((item) => {
      lodash.unset(queryParams, item);
    });
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    await ApiHelper.getApi('UserApi')
      .deliveryExport(queryParams)
      .then((res: any) => {
        ApiHelper.getApi('DeliveryApi')
          .getDeliveries(this.queryParams)
          .then((delivery: any) => {
            this.dataDeliveries = delivery.data;
            this.totalPage = delivery.last_page;
          })
          .catch((error: any) => {
            error.response && Toast.error(error.response.data.message);
          })
          .finally(() => {
            this.transformBlobToFile(
              res,
              `${moment().format('YYYY年MM月DD日')}.csv`,
            );
          });
      })
      .catch((error: any) => {
        error.response && Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
        this.isSearched = false;
      });
  }

  confirmDelivery() {
    if (this.isLoading || !this.isSearched) {
      return;
    }

    this.isLoading = true;

    ApiHelper.getApi('UserApi')
      .confirmDelivery({
        state: this.queryParams.state,
        user_name: this.queryParams.user_name,
        // delivery_date: this.queryParams.delivery_date,
        id: this.queryParams.id,
        delivery_date_from: this.queryParams.delivery_date_from,
        delivery_date_to: this.queryParams.delivery_date_to,
      })
      .then((res: any) => {
        this.isLoading = false;
        Toast.success('更新しました');

        this.query.state = DELIVERY_STATUS.DELIVERED;
        ApiHelper.getApi('DeliveryApi')
          .getDeliveries({
            state: DELIVERY_STATUS.DELIVERED,
            user_name: this.queryParams.user_name,
            // delivery_date: this.queryParams.delivery_date,
            with: 'order,user,order.plan,order.plan.medicines',
            id: this.queryParams.id,
            delivery_date_from: this.queryParams.delivery_date_from,
            delivery_date_to: this.queryParams.delivery_date_to,
          })
          .then((res: any) => {
            this.dataDeliveries = res.data;
            this.totalPage = res.last_page;
          })
          .catch((error: any) => {
            Toast.error(error.response.data.message);
          })
          .finally(() => {
            this.isLoading = false;
          });
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getDeliveryState(state) {
    return DELIVERY_STATUS_NAME[state];
  }

  getDeliveryDay(delivery) {
    return moment(delivery).format('YYYY年MM月DD日');
  }

  getDeliveryList(changePage) {
    if (this.isLoading) {
      return;
    }

    this.dataDeliveries = [];

    this.isLoading = true;

    ApiHelper.getApi('DeliveryApi')
      .getDeliveries(changePage ? this.queryParams : this.initialQuery)
      .then((res: any) => {
        this.dataDeliveries = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  mounted() {
    this.getDeliveryList(false);
  }

  created() {
    library.add(faTrashAlt);
    library.add(faPlusCircle);
    dom.watch();
  }
  private notBeforeDate(date) {
    if (this.query.delivery_date_from) {
      return date < new Date(this.query.delivery_date_from.toString());
    } else {
      return false;
    }
  }

  private notAfterDate(date) {
    if (this.query.delivery_date_to) {
      return date > new Date(this.query.delivery_date_to.toString());
    } else {
      return false;
    }
  }

  private clearDate(name) {
    if (this.query[name]) {
      this.query[name] = '';
    }
  }

  private notAfterEndDay(date) {
    return date > moment().clone().startOf('date');
  }
  private handleSearch() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.isSearched = true;
    this.isSearching = true;
    this.paginate.page = 1;

    ApiHelper.getApi('DeliveryApi')
      .getDeliveries(this.queryParams)
      .then((res: any) => {
        this.dataDeliveries = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        this.toast(error);
      })
      .finally(() => {
        const refData = this.$refs.table as any;
        refData && refData.onFocus;
        this.isLoading = false;
        this.isSearching = false;
      });
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  private pageChanged(page) {
    this.paginate = {
      ...this.paginate,
      ...this.queryParams,
      page,
    };
    this.getDeliveryList(true);
  }

  private gotoDetail(id) {
    if (this.currentUser.isSuperAdminRole) {
      return;
    }
    this.$router.push({
      path: `/deliveries/${id}`,
      query: {
        state: this.queryParams.state || '',
        // delivery_date: this.queryParams.delivery_date || '',
        user_name: this.queryParams.user_name || '',
        page: this.paginate.page ? this.paginate.page.toString() : '1',
        id: this.queryParams.id,
        delivery_date_from: this.queryParams.delivery_date_from,
        delivery_date_to: this.queryParams.delivery_date_to,
      },
    });
  }
}
