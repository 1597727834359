import BaseApi from './BaseApi';

class OrderApi extends BaseApi {
  getOrders(opts: any = {}) {
    return this.get('orders', opts);
  }

  changeStatus(orderId: number, data = {}) {
    return this.post(`orders/${orderId}/status`, data);
  }

  syncFromEcForce(data = {}) {
    return this.post(`orders/sync_from_ec_force`, data);
  }

  doctorConfirm(orderId: number, data = {}) {
    return this.post(`orders/${orderId}/doctor-confirm`, data);
  }

  getOrderDetail(orderId: number | string, data = {}) {
    return this.get(`orders/${orderId}`, data);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/';
  }
}

export default OrderApi;
