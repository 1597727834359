import BaseApi from './BaseApi';

class PlanApi extends BaseApi {
  public create(params: any) {
    return this.post('/', params);
  }

  public updateStatus(id: number | string, params: any) {
    return this.put(`/${id}/status`, params);
  }

  public updatePlan(id: number | string, params: any) {
    return this.post(`/${id}`, params);
  }

  public fetchPlanList(params: any) {
    return this.get('/', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers/plans';
  }
}

export default PlanApi;
