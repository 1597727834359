








































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';

@Component
export default class TrRow extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({default: 'text'}) readonly type!: string;
  @Prop(String) readonly name!: string;
  @Prop([String, Number]) readonly value!: string | number;
  @Prop([String, Number]) readonly maxLength!: string | number;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop(Boolean) readonly readonlyInput!: boolean;
  @Prop(Boolean) readonly isUpdate!: boolean;
  @Prop(String) readonly rules!: string;
  @Prop(Object) readonly customMessages!: object;
  @Prop({default: ''}) readonly placeholder!: string;
  @Prop({default: ''}) readonly autocomplete!: string;

  private mounted() {
    library.add(faSave);
    dom.watch();
  }

  @Emit('onInput')
  private onInput(e: Event) {
    return (e.target as HTMLInputElement).value;
  }

  private updateRow() {
    this.$emit('updateRow', this.name, this.value);
  }
}

