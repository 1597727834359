
















import {Component, Mixins, Prop} from 'vue-property-decorator';
import MessageMixin from './Message';

@Component
export default class MessageLink extends Mixins(MessageMixin) {
  @Prop() avatarUser!: any;
  @Prop() avatarManager!: any;
  getContentMessage(message) {
    const regex = /\{(.*?)\}/;
    const matched = regex.exec(message.message_content);
    const objResv = matched ? JSON.parse(matched[0].replace(/\'/g, '"')) : {};
    if (objResv.action === 'resv_detail') {
      const linkHtml = objResv ? `<a href="/resv/detail/${objResv.reservation_id}" style="text-decoration: underline" target="_blank">${objResv.text}</a>` : '';
      return message.message_content.replace(regex, linkHtml);
    }

    if (objResv.action === 'assgin_doctor_to_reservation') {
      return objResv && objResv.text;
    }

    if (objResv.action === 'pharmacist_reservation_detail') {
      const linkHtml = objResv ? `<a href="/reservation/${objResv.reservation_id}" style="text-decoration: underline" target="_blank">${objResv.text}</a>` : '';
      return message.message_content.replace(regex, linkHtml);
    }

    const actions = [
      'update_card',
      'resv_user_not_answer',
      'create_pharmacist_resv',
    ];

    if (actions.includes(objResv.action)) {
      return message.message_content.replace(regex, objResv.text);
    }

    return message.message_content;
  }
}
