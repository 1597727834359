
































































import { Vue, Component } from 'vue-property-decorator';
import { extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';
import { AuthenticationModule } from '@/store';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Roles } from '@common/app.config';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { State } from 'vuex-class';
import PageLoader from '@components/common/PageLoader.vue';


@Component({
  components: {
    Pagination,
    PopupConfirm,
    PageLoader,
  },
})

export default class ListDoctorAdmin extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  private perPage = 10;
  private pageCount = 1;
  private listDoctors = {};
  private doctorAdminId: any;
  private isLoading: boolean = false;

  private searchForm =  {
    per_page: this.perPage,
    page: 1,
  };

  private async created() {
    this.list(this.searchForm);
  }

  private mounted() {
    library.add(faPlus);
    dom.watch();
  }

  private pageChanged(page) {
    this.searchForm = {...this.searchForm, page};

    this.list(this.searchForm);
  }

  private async list(data) {
    try {
      const params = {
        with: 'clinics',
        ...data,
      };
      this.isLoading = true;
      this.listDoctors = [];
      this.pageCount = 1;
      const response = await ApiHelper.getApi('DoctorAdminApi').list(params);
      this.pageCount = response.last_page;
      this.listDoctors = response.data;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      const errors = error.response.data;

      Toast.error(errors.message);
    }
  }


  private hiddenPopup() {
    this.$modal.hide('delDoctorAdmin');
  }

  private show(id) {
    this.doctorAdminId = id;
    this.$modal.show('delDoctorAdmin');
  }


  private async deleteDoctor() {
    if (!this.doctorAdminId) {
      return ;
    }

    try {
      await ApiHelper.getApi('DoctorAdminApi').delete(this.doctorAdminId);

      Toast.success('削除しました');

      this.searchForm = {...this.searchForm, page: 1};
      this.list(this.searchForm);
      this.$modal.hide('delDoctorAdmin');
    } catch (error) {
      const data = error.response.data;
      if (error.response.status === 422) {
        return;
      }

      Toast.error(data.message);
    }
  }
}
