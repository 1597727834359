import { Module, VuexModule } from 'vuex-module-decorators';
import { DeliveryInput, Delivery } from '@/models/delivery';

@Module({ name: 'delivery', namespaced: true })
export default class DeliveryStore extends VuexModule {
  public dataDelivery: DeliveryInput[] = [];
  public delivery: Delivery = new Delivery();
  public total: number = 0;
  public totalPage: number = 0;
  public isLoading = false;
}
