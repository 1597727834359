<template>
  <div
    class="video-component"
    v-click-outside="showModalLeaveVideo"
    id="video-component"
  >
    <div class="video-header">
      <span>ビデオ通話</span>
      <span>{{
        `${currentChannelUser.family_name || ""} ${
          currentChannelUser.given_name || ""
        }`.trim() || "-"
      }}</span>
    </div>

    <img
      v-if="userMicOff"
      :src="micUserIcon"
      class="mic-icon-user"
      alt="Micro"
    />
    <div class="video-container">
      <div class="video-content" id="video-content"></div>
      <div class="video-content-user" id="video-content-user"></div>
    </div>
    <div class="video-footer">
      <div class="video-item" v-if="isCall">
        <button class="video-btn video-btn-share" @click="shareToggle">
          {{ shareText }}
        </button>
      </div>
      <div class="video-item">
        <img :src="camIcon" @click="camToggle()" alt="Camera" />
      </div>
      <div class="video-item">
        <button
          v-if="isCall"
          class="video-btn video-btn-end"
          @click="leaveCalling"
        >
          退出する
        </button>
        <button
          v-else
          class="video-btn video-btn-call"
          id="join"
          @click="joinCalling"
        >
          開始する
        </button>
      </div>
      <div class="video-item">
        <img :src="micIcon" @click="micToggle()" alt="Micro" />
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import AgoraRTC from "agora-rtc-sdk-ng";
import Toast from "@common/helpers/toast";
import ApiHelper from "api-helper";
import CamOn from "@/assets/img/camera.svg";
import CamOff from "@/assets/img/camera-lock.svg";
import MicOn from "@/assets/img/mic.svg";
import MicOff from "@/assets/img/mic-lock.svg";

@Component
export default class VideoComponent extends Vue {
  @Prop() currentChannel;
  @Prop() currentChannelUser;
  @Prop() setVideo;
  @Prop() showModalLeaveVideo;

  agoraTokenData = {};
  hasMic = true;
  hasCamera = true;
  hasShareScreen = false;
  isCall = false;
  hasUser = false;
  userMicOff = false;

  rtc = {
    localAudioTrack: null,
    localVideoTrack: null,
    screenVideoTrack: null, // Add a new track for screen sharing
    screenAudioTrack: null, // This track is optional for sharing screen audio
    client: null,
    uid: null,
  };

  options = {
    // Pass your App ID here.
    appId: "Your App ID",
    // Set the channel name.
    channel: "test",
    // Pass your temp token here.
    token: "Your temp token",
    // Set the user ID.
    uid: 123456,
  };

  mounted() {
    this.getVideoToken();
  }

  micToggle() {
    this.hasMic = !this.hasMic;
    this.rtc.localAudioTrack.setEnabled(this.hasMic);
  }

  camToggle() {
    this.hasCamera = !this.hasCamera;
    this.rtc.localVideoTrack.setEnabled(this.hasCamera);
  }

  async shareToggle() {
    if (!this.hasShareScreen) {
      const screenVideo = await AgoraRTC.createScreenVideoTrack(
        {
          encoderConfig: "720p",
        },
        "auto",
      );

      if (screenVideo instanceof Array) {
        this.rtc.screenVideoTrack = screenVideo[0];
        this.rtc.screenAudioTrack = screenVideo[1];
      } else {
        this.rtc.screenVideoTrack = screenVideo;
      }

      const streamContainer = this.addNewStream(
        this.rtc.screenVideoTrack,
        "my-screen",
      );

      // play local video track
      this.rtc.screenVideoTrack.play(streamContainer, {
        fit: "contain",
        mirror: false,
      });

      this.rtc.screenVideoTrack && await this.rtc.screenVideoTrack.on(
        "track-ended", async () => {
        await this.handleTrackEnded();
      });

      await this.rtc.client.unpublish([this.rtc.localVideoTrack]);
      if (!this.rtc.screenAudioTrack) {
        await this.rtc.client.publish([
          this.rtc.screenVideoTrack,
        ]);
      } else {
        await this.rtc.client.publish([
          this.rtc.screenVideoTrack,
          this.rtc.screenAudioTrack,
        ]);
      }
      this.hasMic && await this.rtc.client.publish([
          this.rtc.localAudioTrack,
        ]);
      this.hasShareScreen = true;
    } else {
      this.handleTrackEnded();
    }
  }

  get camIcon() {
    return this.hasCamera ? CamOn : CamOff;
  }

  get micIcon() {
    return this.hasMic ? MicOn : MicOff;
  }

  get micUserIcon() {
    return this.userMicOff ? MicOff : MicOn;
  }

  get shareText() {
    return this.hasShareScreen ? "共有を閉じる" : "画面共有する";
  }

  async getVideoToken() {
    await ApiHelper.getApi("ChannelApi")
      .agoraToken(this.currentChannel.id, {
        with: "users,latest_message,users.latestReservation",
      })
      .then((response) => {
        this.agoraTokenData = response.data;
        this.options = {
          appId: response.data.app_id,
          channel: response.data.channel_uuid,
          token: response.data.token,
          uid: Number(response.data.uid),
        };
        this.startBasicCall();
      })
      .catch((error) => {
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        console.log();
      });
  }

  addNewStream(stream, id) {
    // Dynamically create a container in the form of a DIV element for playing the remote video track.
    const streamContainer = document.createElement("div");
    // Specify the ID of the DIV container. You can use the uid of the remote user.
    streamContainer.id = id;

    // click to zoom to stream
    streamContainer.addEventListener("click", function () {
      if (streamContainer.parentNode.id === "video-content-user") {
        // swap 2 stream
        document
          .getElementById("video-content-user")
          .append(document.getElementById("video-content").children[0]);
        document.getElementById("video-content").append(streamContainer);
      }
    });

    if (document.getElementById("video-content").children.length) {
      document.getElementById("video-content").append(streamContainer);
      document.getElementById("video-content-user")
        .append(document.getElementById("video-content").children[0]);
    } else {
      // streamContainer.textContent = 'Remote user ' + user.uid.toString();
      document.getElementById("video-content").append(streamContainer);
    }

    return streamContainer;
  }

  async handleTrackEnded() {
    const myScreenDOM = document.getElementById("my-screen");

    if (document.querySelector('div#video-content-user div#my-screen')) {
      document.getElementById("video-content-user").removeChild(myScreenDOM);
    } else if (document.querySelector('div#video-content div#my-screen')) {
      document.getElementById("video-content").removeChild(myScreenDOM);
      document.getElementById("video-content").append(document.getElementById(this.rtc.uid));
    }

    this.rtc.screenVideoTrack.close();
    this.rtc.screenAudioTrack && this.rtc.screenAudioTrack.close();
    this.rtc.client.unpublish([this.rtc.screenVideoTrack]);
    this.rtc.screenAudioTrack && await this.rtc.client.unpublish([this.rtc.screenAudioTrack]);

    this.hasCamera && await this.rtc.client.publish([
      this.rtc.localVideoTrack,
    ]);
    this.rtc.screenAudioTrack = null;
    this.rtc.screenVideoTrack = null;
    this.hasShareScreen = false;
  }

  async startBasicCall() {
    // Create an AgoraRTCClient object.
    this.rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    AgoraRTC.enableLogUpload();

    // Listen for the 'user-published' event, from which you can get an AgoraRTCRemoteUser object.
    this.rtc.client.on("user-published", async (user, mediaType) => {
      // Subscribe to the remote user when the SDK triggers the 'user-published' event
      await this.rtc.client.subscribe(user, mediaType);
      console.log("subscribe success");

      this.rtc.uid = user.uid;

      // If the remote user publishes a video track.
      if (mediaType === "video") {
        // Get the RemoteVideoTrack object in the AgoraRcTCRemoteUser object.
        const streamContainer = this.addNewStream(
          user.videoTrack,
          user.uid.toString(),
        );

        // Play the remote video track.
        user.videoTrack.play(streamContainer, {
          fit: "contain",
          mirror: false,
        });
      }

      // If the remote user publishes an audio track.
      if (mediaType === "audio") {
        this.userMicOff = false;
        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        const remoteAudioTrack = user.audioTrack;
        // Play the remote audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }

      // Listen for the 'user-unpublished' event
      this.rtc.client.on("user-unpublished", (user, mediaType) => {
        // If the remote user publishes an audio track.
        if (mediaType === "audio") {
          this.userMicOff = true;
        }
        if (mediaType === "video") {
          // Destroy the container.
          document.getElementById(user.uid).remove();
          const rootRemoteContainer =
            document.getElementById("video-content")?.childNodes;
          const localUser =
            document.getElementById("video-content-user")?.children[1];
          if (rootRemoteContainer?.length === 0 && localUser) {
            const id = localUser.id;

            document.getElementById(id)?.remove();
            document.getElementById("video-content")?.append(localUser);
          }
        }
      });
    });
  }

  async joinCalling() {
    // document.getElementById('join').onclick = async function () {
    // Join an RTC channel.
    if (!this.rtc.client) {
      return;
    }

    const self = this;

    await this.rtc.client.join(
      this.options.appId,
      this.options.channel,
      this.options.token,
      this.options.uid,
    );
    // Create a local audio track from the audio sampled by a microphone.
    this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a local video track from the video captured by a camera.
    this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    // Publish the local audio and video tracks to the RTC channel.
    await this.rtc.client.publish([
      this.rtc.localAudioTrack,
      this.rtc.localVideoTrack,
    ]);
    // Dynamically create a container in the form of a DIV element for playing the local video track.
    const localPlayerContainer = document.createElement("div");

    // Specify the ID of the DIV container. You can use the uid of the local user.
    localPlayerContainer.id = this.options.uid;

    // localPlayerContainer.textContent = 'Local user ' + this.options.uid;
    document.getElementById("video-content-user").append(localPlayerContainer);

    // Play the local video track.
    // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
    this.rtc.localVideoTrack.play(localPlayerContainer);

    // When token-privilege-will-expire occurs, fetch a new token from the server and call renewToken to renew the token.
    this.rtc.client.on("token-privilege-will-expire", async function()  {
      let response = await ApiHelper.getApi("ChannelApi")
        .agoraToken(self.currentChannel.id, {
          with: "users,latest_message,users.latestReservation",
        });
      await self.rtc.client?.renewToken(response.data.token);
    });

    if (!this.hasCamera) {
      await this.rtc.localVideoTrack.setEnabled(false);
    }
    if (!this.hasMic) {
      await this.rtc.localAudioTrack.setEnabled(false);
    }
    console.log("publish success!");
    // };
    this.isCall = true;
  }

  async leaveCalling() {
    // Destroy the local audio and video tracks.
    this.rtc.localAudioTrack.close();
    this.rtc.localVideoTrack.close();

    // Traverse all remote users.
    this.rtc.client.remoteUsers.forEach((user) => {
      // Destroy the dynamically created DIV containers.
      const playerContainer = document.getElementById(user.uid);
      playerContainer && playerContainer.remove();
    });

    // Leave the channel.
    await this.rtc.client.leave();
    this.isCall = false;
    this.setVideo(false);
  }

  beforeDestroy() {
    this.rtc.client.leave();
    this.setVideo(false);
  }

  destroyed() {
    this.leaveCalling();
  }
}
</script>

<style lang="scss">
#video-content > div {
  width: 100%;
  height: 100%;
  border: 3px solid darkslategray;
  border-radius: 10px;
  overflow: hidden;
}

#video-content-user > div {
  width: 200px;
  height: 100%;
  margin-left: 10px;
  border: 3px solid darkslategray;
  border-radius: 10px;
  overflow: auto;
}
</style>

<style lang="scss" scoped>
.video {
  &-component {
    height: calc(100vh - 8vh);
    position: relative;
  }

  &-header {
    background-color: #000;
    padding: 10px;
    color: #fff;
    font-weight: bold;

    span {
      margin-left: 10px;
    }
  }

  &-content {
    z-index: 1;
    height: 80%;
    padding: 10px;
  }

  &-item {
    padding: 10px 10px 0;
    z-index: 1000;
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding: 10px 10px 10px;
  }

  &-btn {
    color: #fff;
    font-weight: bold;
    border-radius: 6px;
    padding: 10px;
    border: unset;

    &-call {
      background: #008505;
    }

    &-end {
      background: #fc1d1d;
    }

    &-share {
      background: #39a0ff;
    }
  }

  &-container {
    position: relative;
    height: 90%;
    background-color: #263238;
  }
}

.video-content-user {
  height: 20%;
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.mic-icon-user {
  position: absolute;
  top: 58px;
  z-index: 1000;
  height: 30px;
  left: 20px;
}
</style>
