import BaseApi from './BaseApi';

class TranslateApi extends BaseApi {
  public async getList(params?: any) {
    return await this.get('guest/translates', params);
  }

  public async getLastUpdate(params?: any) {
    return await this.get('managers/translates/last-update', params);
  }

  public async uploadCSV(params: FormData) {
    return await this.post('managers/translates/upload', params);
  }

  protected getSpecificApiPrefix(): string {
    return '';
  }
}

export default TranslateApi;
