

































































































































import { formatDateJp } from '@/common/app.config';
import { USER_TREATMENT_STATE } from '@/common/constants/auth';
import PrescriptionPopup from '@/components/v2/patient/message/partials/PrescriptionPopup.vue';
import { Patient } from '@/models/patient';
import { User } from '@/models/user';
import { UserTreatment } from '@/models/user-treatment';
import Toast from '@common/helpers/toast';
import BasicModal from '@components/common/BasicModal.vue';
import PageLoader from '@components/common/PageLoader.vue';
import ApiHelper from 'api-helper';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
// import PopupCreateFeedback from '@components/common/PopupCreateFeedback.vue';

@Component({
  components: {
    PageLoader,
    BasicModal,
    PrescriptionPopup,
    // PopupCreateFeedback,
  },
  computed: {},
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})
export default class QuestionInfoChannel extends Vue {
  // ********************* getters ********************* //
  get patientReserved() {
    return !!this.activeTreatment.reservations?.[0]?.reservation_date;
  }

  get treatmentName() {
    return this.activeTreatment.menu?.name;
  }

  get interviewDate() {
    return this.activeTreatment.onlyOneReservation?.latest_answer?.created_at;
  }

  get stateNotice() {
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD
    ) {
      return '診療方法を選択してください';
    }
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED
    ) {
      return 'チャット診療中';
    }
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED
    ) {
      return 'ビデオ診療';
    }
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.CONFIRMING_PRESCRIPTION
    ) {
      return '診療完了';
    }

    return '診療方法を選択してください';
  }

  @State((state) => state.auth.currentUser) currentUser!: User;
  @State((state) => state.patient_detail.data) currentPatient!: Patient;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;
  public formatDateJp = formatDateJp;

  public USER_TREATMENT_STATE = USER_TREATMENT_STATE;
  public isChangeState: boolean = false;
  public patientAnswer: any[] = [];

  async created() {
    await this.getAnswerData();
  }

  async getAnswerData() {
    this.$store.commit('treatment_detail/setLoadingChild', true);
    await ApiHelper.getApi('PatientV2Api')
      .getReservation(this.currentPatient.id, {
        user_treatment_id: this.activeTreatment.id,
        with: 'answers,userTreatment.menu,userTreatment.images',
      })
      .then((res: any) => {
        this.patientAnswer = res.data.answers;
        this.activeTreatment.images = res.data.user_treatment.images;
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        this.$store.commit('treatment_detail/setLoadingChild', false);
      });
  }

  onChangeUserStatus(status) {
    this.isChangeState = true;
    const payload = {
      state: status,
      user_treatment_id: this.activeTreatment.id,
    };
    ApiHelper.getApi('PatientV2Api')
      .changeUserTreatmentState(this.currentPatient.id, payload)
      .then((res: any) => {
        this.updateUserTreatmentState(res.data);

        Toast.success('患者に通知しました。');
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isChangeState = false;
      });
  }

  isDisabledAfterChoose(status) {
    return (
      this.activeTreatment.state !== status &&
      this.activeTreatment.state !==
        USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD
    );
  }

  updateUserTreatmentState(data) {
    this.activeTreatment.state = data.state;
    this.$store.commit('user_treatment_list/setUserTreatmentState', data);
  }

  handleWhenCreateFeedback(doctorFeedback: any) {
    this.updateUserTreatmentState(doctorFeedback.user_treatment);
  }

  // ********************* methods ********************* //
  changeState() {
    this.isChangeState = true;
  }
}
