import { CanvasVideoFrameBuffer } from 'amazon-chime-sdk-js'

function BlurBackgroundProcessor() {
  const tf = require('@tensorflow/tfjs')
  const bodyPix = require('@tensorflow-models/body-pix')

  tf.setBackend('webgl')

  let model = null

  const loadBodyPix = async () => {
    model = await bodyPix.load({
      architecture: 'MobileNetV1',
      outputStride: 16,
      multiplier: 0.5,
      quantBytes: 4
    })
  }

  loadBodyPix().then((r) => {})

  // the target canvas where we gonna draw the blurred background
  const targetCanvas = document.createElement('canvas')
  // used to retrieve the CanvasVideoFrameBuffer from the target canvas
  const canvasVideoFrameBuffer = new CanvasVideoFrameBuffer(targetCanvas)
  const process = async (buffers) => {
    for (let i = 0; i < buffers.length; i++) {
      const canvas = await buffers[i].asCanvasElement()
      // error handling
      if (canvas.width === 0 || canvas.height === 0) {
        return buffers
      }
      const backgroundBlurAmount = 20
      const edgeBlurAmount = 20
      const flipHorizontal = false
      // execute segmentPerson and blur the background
      try {
        const segmentation = await model.segmentPerson(canvas)
        bodyPix.drawBokehEffect(
          targetCanvas,
          canvas,
          segmentation,
          backgroundBlurAmount,
          edgeBlurAmount,
          flipHorizontal
        )
      } catch (err) {
        console.log('blur: ', err)
      }
      // replace video frames
      buffers[i] = canvasVideoFrameBuffer
    }

    // return new video frames
    return buffers
  }

  const destroy = () => {}

  return {
    targetCanvas,
    canvasVideoFrameBuffer,
    process,
    destroy
  }
}

export { BlurBackgroundProcessor }
