



































































import { Vue, Component, Mixins } from 'vue-property-decorator';
import { DataForm, defaultDataForm } from '@/models/pharmacist';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import { Roles } from '@/common/app.config';
import ApiHelper from 'api-helper';
import UploadImage from '@/components/common/UploadImage.vue';
import TablePharmacistForm from './TablePharmacistForm.vue';
import PharmacistMixin from '@common/mixins/Pharmacist/PharmacistMixin';
import { getFormData } from '@/common/utils';
import { State } from 'vuex-class';

@Component({
  components: {
    UploadImage,
    TablePharmacistForm,
    PopupConfirm,
    PageLoader,
  },
})

export default class PharmacistCreate extends Mixins(PharmacistMixin) {
  @State((state) => state.auth.currentUser) currentUser!: any;

  private isSubmit: boolean = false;
  private dataForm: DataForm = JSON.parse(JSON.stringify(defaultDataForm));

  private created() {
    if (this.currentUser.role === Roles.pharmacy) {
      this.dataForm.stores = this.currentUser.pharmacies;
    }
  }

  // Computed
  private get computedForm() {
    return this.$refs.pharmacistForm as any;
  }

  // Methods
  private updateForm(field: string, value: string | []) {
    this.$set(this.dataForm, field, value);
  }

  private async openPopupComfirm() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    this.$modal.show('comfirmCreatePharmacist');
  }

  private cancelPopupConfirm() {
    this.$modal.hide('comfirmCreatePharmacist');
  }

  private async createPharmacist() {
    try {
      this.isSubmit = true;
      const mapParams = {
        ...this.dataForm,
        pharmacy_ids: this.dataForm.stores.map((x) => (x as any).id),
        pharmacist_phone: this.dataForm.pharmacist_phone.replace(/^0/gi, '81'),
      };
      delete mapParams.stores;
      const body = getFormData(mapParams);

      const response = await ApiHelper.getApi('PharmacistApi').create(body);
      this.isSubmit = false;
      Toast.success('登録しました');
      this.$router.push({ name: 'pharmacists' });
    } catch (error) {
      this.isSubmit = false;
      if (error.response) {
        const { data, status } = error.response;
        if (status === 422) {
          this.computedForm.setErrors(data.errors);
          return;
        }
      }
      Toast.error(error.message);
    } finally {
      this.cancelPopupConfirm();
    }
  }
}
