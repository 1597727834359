
































































































































import ApiHelper from 'api-helper';
import {Component, Prop, Vue} from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import {PLAN_PAYMENT_TYPE} from '@/common/constants/plan';
import moment from 'moment';
import { extend } from 'vee-validate';
import { USER_STATE } from '@/common/constants/auth';
import PopupConfirm from '@/components/common/PopupConfirm.vue';

extend('notSpecialChar', (text) => {
  const textCompare = text;
  if (text) {
    return textCompare.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '').length == text.length
      ? true
      : '絵文字または特殊記号を入力しないでください。';
  }
  return true;
});

@Component({
  components: {
    PageLoader,
    PopupConfirm,
  },
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})
export default class PrescriptionForm extends Vue {
  @Prop() changeState: any;

  private get prescriptionFormRef() {
    return this.$refs.prescriptionForm as any;
  }
  @Prop() currentChannelUser!: any;
  @Prop() changeCurrentChannelUser!: any;
  // @Prop() savedFeedback: any;
  @Prop() listFeedback: any;

  protected PRESCRIPTION_TYPE = {
    PRESCRIPTION: 'prescription',
    NG: 'ng',
  };
  private isSubmitting: boolean = false;
  private isLoading: boolean = false;
  private listPlan: any[] = [];

  private PLAN_PAYMENT_TYPE: any = PLAN_PAYMENT_TYPE;
  private prescriptionForm: any = {
    content: null,
    type: null,
    plans: [],
  };
  private isPrescription: boolean = false;
  private isNG: boolean = false;
  private prescriptionType: string = '';

  mounted() {
    this.getListPlans();
  }

  private get isDisableCreateFeedback() {
    if (this.currentChannelUser.state === USER_STATE.CONFIRMING_PRESCRIPTION) {
      return false;
    } else {
      if (this.listFeedback.length > 0) {
        return true;
      }
      return false;
    }
    // return this.currentChannelUser.state == USER_STATE.CONFIRMING_PRESCRIPTION;
  }

  hideModal() {
    if (!this.isSubmitting) {
      this.$modal.hide('prescriptionForm');
      this.$modal.hide('chat-channel-popup'); // hide modal chat question in chat channel
    }
  }

  show(currentPrescription) {
    this.prescriptionForm = currentPrescription;
    this.prescriptionType = currentPrescription.type;
    this.$modal.show('prescriptionForm');
  }

  getListPlans() {
    this.isLoading = true;
    const self = this;
    // ApiHelper.getApi('PlanV2Api').fetchPlanList({ karte_status: true })
    ApiHelper.getApi('PatientV2Api').getPlansByPatientId(this.currentChannelUser.id)
        .then(function(res, error) {
          self.listPlan = res.data;
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isLoading = false;
        });
  }

  prescriptionHandle() {
    this.isPrescription = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.PRESCRIPTION;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.PRESCRIPTION;
  }

  ngHandle() {
    this.isNG = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.NG;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.NG;
  }

  async savePrescriptionForm() {
    const isValid = await this.prescriptionFormRef.validate();
    if (!isValid) {
      return;
    }
    if (this.listFeedback.length > 0) {
      this.$modal.show('confirmCreateKarte');
      return true;
    }
    this.createKarte();
  }

  async createKarte() {
    const data = {
      user_id: this.currentChannelUser.id,
      content: this.prescriptionForm.content,
      plans: this.prescriptionForm.type === this.PRESCRIPTION_TYPE.PRESCRIPTION ? this.prescriptionForm.plans : null,
      type: this.prescriptionForm.type,
    };
    this.isSubmitting = true;
    const self = this;

    if (this.prescriptionForm.id) {
      await ApiHelper.getApi('DoctorMouthApi').updateFeedback(this.prescriptionForm.id, data)
        .catch((error: any) => {
          error.response && Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
        });
    } else {
      await ApiHelper.getApi('DoctorMouthApi').createFeedback(data)
        .then(function(res, error) {
          // const channelData = {...self.currentChannelUser};
          // channelData.state = self.prescriptionForm.type === self.PRESCRIPTION_TYPE.PRESCRIPTION ?
            // USER_STATE.CONFIRMING_PRESCRIPTION : USER_STATE.SUGGESTED_SHAMPOO;
          // channelData.latestReservation.doctor = res.doctor;
          // self.changeCurrentChannelUser(channelData);
          // self.changeState();
        })
        .catch((error: any) => {
          const { response: { data: { code, message } }} = error;
          if (code && code === 'errors.exists_doctor_feedback_for_user') {
            return Toast.error('カルテが既に作成されています。');
          }
          message && Toast.error(message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
          self.$modal.hide('confirmCreateKarte');
        });
    }

    self.$emit('savedFeedback');
  }

  cancelPopupConfirm() {
    this.$modal.hide('confirmCreateKarte');
  }

}
