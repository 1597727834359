import { Deserializable } from '@common/interfaces/deserializable';

export interface PaginateInput {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export class Paginate implements Deserializable<Paginate>, PaginateInput {
  current_page: number = 1;
  last_page: number = 1;
  per_page: number = 20;
  total: number = 1;

  get hasPaginate(): boolean {
    return this.last_page > 1;
  }

  deserialize(input: Partial<PaginateInput>): Paginate {
    Object.assign(this, input);
    return this;
  }

  setPage(page: number) {
    this.current_page = page;
  }

  setPerPage(per_page: number) {
    this.per_page = per_page;
  }

  reset() {
    this.current_page = 1;
    this.per_page = 20;
  }

  toPaginateJSON() {
    return {
      page: this.current_page,
      per_page: this.per_page,
    };
  }
}
