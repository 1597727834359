import { Deserializable } from '@common/interfaces/deserializable';
import { Clinic } from './clinic';
import { Question } from './question';

interface TypeTimeFrameOption {
  value: number;
  name: string;
}
export interface MenuInput {
  id: number;
  name: string;
  questions: Question[];
  description: string;
  fee: string;
  is_free: boolean;
  status: number;
  time_frame: number;
  type: number;
  clinics?: Clinic[];
  time_frame_option?: TypeTimeFrameOption;
}

export class Menu implements Deserializable<Menu>, MenuInput {
  id!: number;
  name: string = '';
  questions: Question[] = [];
  description: string = '';
  fee: string = '';
  is_free: boolean = true;
  status: number = 1;
  time_frame: number;
  type: number;
  clinics: Clinic[] = [];
  time_frame_option: TypeTimeFrameOption = {
    name: '30分',
    value: 30,
  };

  get clinicNames() {
    return this.clinics
      .map((item: Clinic) => item.name)
      .join('、');
  }

  deserialize(input: Partial<MenuInput>): Menu {
    Object.assign(this, input);

    if (input.questions && input.questions.length) {
      this.questions = input.questions.map((item: Question) => new Question().deserialize(item));
    }

    if (input.clinics && input.clinics.length) {
      this.clinics = input.clinics.map((item: Clinic) => new Clinic().deserialize(item));
    }

    if (input.fee && +input.fee === 0) {
      this.fee = '';
    }

    return this;
  }

  timeFrameOption() {
    this.time_frame_option = {
      name: this.time_frame + '分',
      value: this.time_frame,
    };
  }

  formJSONData() {
    return {
      name: this.name,
      status: this.status,
      is_free: this.is_free,
      fee: this.fee || 0,
      description: this.description,
      type: 0,
      time_frame: this.time_frame_option.value,
      clinic_ids: this.clinics.map((item: Clinic) => item.id),
    };
  }
}
