







































































































import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

// Utils
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ApiHelper from 'api-helper';
// Components
import Pagination from '@components/common/Pagination.vue';
import PageLoader from '@components/common/PageLoader.vue';
import ContentLayout from '@components/layouts/Content.vue';
// Constants
import { Roles } from '@/common/app.config';
// Icons
import Listicon from '@/assets/img/icon-diagnosis.svg';

@Component({
  components: {
    Pagination,
    PageLoader,
    ContentLayout,
  },
  data() {
    return {
      listDiagnosis: {},
      iconHeader: Listicon,
    };
  },
})
export default class List extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  public iconHeader: any;
  public page = this.$route.query.page || 1;
  public perPage = 10;
  public pageCount = 1;
  public listDiagnosis!: any;
  public isLoading: boolean = false;

  get isSuperAdminRole() {
    return this.currentUser.isSuperAdminRole;
  }

  public searchForm = {
    name: this.$route.query.name || '',
    clinic_name: this.$route.query.clinic_name,
    order: '-created_at',
    per_page: this.perPage,
    page: this.page,
    with: 'clinics',
  };

  public searchDiagnosis() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));

    this.searchForm.page = 1;
    this.page = 1;
    this.getDiagnosisList(this.searchForm);
  }

  public pageChanged(page) {
    let paramsSearch: any;

    if (LocalStorageUtils.getItem('paramsSearch')) {
      paramsSearch = JSON.parse(LocalStorageUtils.getItem('paramsSearch'));
    } else {
      paramsSearch = Object.assign({}, this.searchForm);
      paramsSearch.name = '';
    }

    paramsSearch.page = page;
    this.page = page;
    this.getDiagnosisList(paramsSearch);
  }

  private async created() {
    if (LocalStorageUtils.getItem('paramsSearch')) {
      LocalStorageUtils.removeItem('paramsSearch');
    }

    this.getDiagnosisList(this.searchForm);
  }

  private mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  private async getDiagnosisList(data) {
    try {
      this.isLoading = true;
      const res = await ApiHelper.getApi('MedicalMenuV2Api').getList(data);
      this.listDiagnosis = res.data;
      this.pageCount = res.last_page;
      this.isLoading = false;
    } catch (error) {
      this.toast(error);
      this.isLoading = false;
    }
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  private makeClinicNames(clinicsName) {
    return clinicsName?.map((clinic) => clinic.name).join(', ');
  }
}
