const hashCode = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line:no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const hexCode = (name: string): string => {
  const hash = hashCode(name);
  return Math.floor(Math.abs((Math.sin(hash) * 10000) % 1 * 16777216)).toString(16);
};
