

























































































// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import lodash, { get } from 'lodash';
import ApiHelper from 'api-helper';

// interface
import { Patient } from '@/models/patient';
import { User } from '@/models/user';

// component
import Avatar from '@components/v2/Avatar.vue';
import ModalReservationDate from '@/views/messages/ModalReservationDate.vue';

// constants
import {
  formatDateTimeJp,
  formatDateJp,
  formatDateTimeJp2,
} from '@/common/app.config';
import { GENDER_KEY, GENDER_NAME } from '@/common/enums/gender';
import { USER_STATUS } from '@/common/constants/auth';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    Avatar,
    ModalReservationDate,
  },
})
export default class PatientControlReservation extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  private reservation: any = {};

  private formatDateTimeJp = formatDateTimeJp;
  private formatDateJp = formatDateJp;
  private formatDateTimeJp2 = formatDateTimeJp2;

  created() {
    this.getReservationDetail();
  }

  async getReservationDetail() {
    this.$store.commit('treatment_detail/setLoadingChild', true);
    await ApiHelper.getApi('PatientV2Api')
      .getReservation(this.patient.id, {
        user_treatment_id: this.activeTreatment.id,
        with: 'answers,userTreatment.menu,doctor,latestAnswer',
      })
      .then((res: any) => {
        this.reservation = res.data;
        this.$store.commit('treatment_detail/setTreatment', {
          ...this.activeTreatment,
          reservations: [res.data],
        });
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        this.$store.commit('treatment_detail/setLoadingChild', false);
      });
  }

  get isSMSVerified() {
    return !lodash.isEmpty(this.patient.otp_verified_at);
  }

  get getGenderName() {
    return this.patient.gender_id === GENDER_KEY.MALE
      ? GENDER_NAME.MALE
      : GENDER_NAME.FEMALE;
  }

  get userState() {
    return get(this.reservation, 'user_treatment.state');
  }

  private get canDisplayReservationDate() {
    return (
      this.reservation &&
      lodash.includes(
        [USER_STATUS.CONFIRMED_RESERVATION, USER_STATUS.VIDEO_INTERVIEW_DATE],
        this.userState,
      )
    );
  }

  private get canEditReservation() {
    return (
      [
        USER_STATUS.CONFIRMED_RESERVATION,
        USER_STATUS.VIDEO_INTERVIEW_DATE,
      ].includes(this.userState) &&
      (this.currentUser.isDoctorRole ||
        this.currentUser.isCSRole ||
        this.currentUser.isAdminRole)
    );
  }

  private openModalReservationDate() {
    this.getReservationDetail();
    this.$modal.show('modalReservationDate');
  }

  private updateLatestReservation(data) {
    this.reservation.reservation_date = data.reservation_date;
  }

  private closeModalReservationDate() {
    // this.reservation = {};
  }
}
