import { Deserializable } from '@/common/interfaces/deserializable';
import { getFormData } from '@/common/utils';
import { Plan } from './plan';


export interface MedicineInput {
  expiry_date: string;
  lot_number: string;
  medicine_id: number;
  id?: number;
  name?: string;
  created_at?: string;
  updated_at?: string;
  image?: string;
  image_url?: string;
  about_medicine?: string;
  usage?: string;
  plans?: Plan[];
}

export interface QueryMedicine {
  id?: number | null;
  page?: number;
  per_page?: number;
  type?: number | string;
  name?: string;
  freeword?: string;
  with?: string;
  medicine_type?: number | string;
}

export interface QueryMedicinePrescription {
  page?: number;
  per_page?: number;
  freeword?: string;
}

export class Medicine implements Deserializable<Medicine>, MedicineInput {
  id: number;
  name: string;
  usage: string;
  about_medicine: string;
  created_at: string;
  updated_at: string;
  image: string = '';
  image_url: string = '';
  expiry_date!: string;
  lot_number!: string;
  medicine_id!: number;
  plans: Plan[];

  get nameLink() {
    return `${this.name} 薬`;
  }

  get usageWithCommas() {
    if (this.usage) {
      return `( ${this.usage} )`;
    }
  }

  deserialize(input: Partial<MedicineInput>): Medicine {
    Object.assign(this, input);
    return this;
  }

  formJSONData(isEdit: boolean) {
    const data: any = {
      name: this.name,
      usage: this.usage,
      about_medicine: this.about_medicine,
      _method: isEdit ? 'put' : 'post',
    };
    if (isEdit && !this.image) {
      data.image = null;
    }

    return data;
  }

  formJSONDataHaveImage(isEdit: boolean) {
    const data: any = {
      name: this.name,
      usage: this.usage,
      about_medicine: this.about_medicine,
      _method: isEdit ? 'put' : 'post',
    };

    if (this.image) {
      data.image = this.image;
    }

    const formData = getFormData(data);

    return formData;
  }

  toJSONData() {
    return {
      name: this.name,
      usage: this.usage,
      about_medicine: this.about_medicine,
    };
  }
}
