




























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import Pagination from '@components/common/Pagination.vue';
import LocalStorageUtils from '@common/LocalStorageUtils';
import lodash from 'lodash';

@Component({
  components: {
    Pagination,
    PageLoader,
  },
})
export default class PopupAddMenu extends Vue {
  @Prop({ default: {} }) readonly menuSelected!: any;
  @Prop() readonly clinic_ids!: any;
  @Prop({ default: false }) readonly multiple!: boolean;

  get queryParams() {
    return {
      ...this.paginate,
      with: 'menus,license',
      clinic_ids: this.clinic_ids,
      ...this.query,
    };
  }
  get initialQuery() {
    return {
      ...this.paginate,
      with: 'menus,license',
      clinic_ids: this.clinic_ids,
    };
  }

  get isEmptyDataMenu(): boolean {
    return this.listMenus && !this.listMenus.length;
  }
  paginate = {
    page: this.$route.query.page || 1,
    per_page: 10,
  };
  totalPage = 1;
  private listMenus = [];
  private activeMenus: any;
  private isLoading: boolean = false;

  private query = {
    keyword: this.$route.query.keyword || '',
  };

  beforeOpen() {
    this.activeMenus = this.menuSelected || [];
    this.initDataDefault();
    this.getMenusList(this.initialQuery);
  }

  beforeClose() {
    this.paginate = {
      page: 1,
      per_page: 10,
    };
    this.totalPage = 1;
    this.activeMenus = [];
  }

  initDataDefault() {
    // Clear data when close popup
    this.listMenus = [];
    this.paginate = {
      page: 1,
      per_page: 10,
    };
    this.totalPage = 1;
    this.query = {
      keyword: '',
    };
  }

  cancelSetMenu() {
    this.activeMenus = [];
    this.initDataDefault();
    this.$emit('cancel');
  }

  getMenusList(query) {
    if (this.isLoading) {
      return;
    }
    this.listMenus = [];
    this.isLoading = true;

    // ApiHelper.getApi('MenuApi').list(changePage ? this.queryParams : this.initialQuery)
    ApiHelper.getApi('MedicalMenuV2Api')
      .getList(query)
      .then((res: any) => {
        this.listMenus = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private pageChanged(page) {
    let paramsSearch: any;
    this.paginate.page = page;

    paramsSearch = Object.assign({}, this.initialQuery);

    paramsSearch = {
      ...paramsSearch,
      ...this.paginate,
      page,
    };

    this.getMenusList(paramsSearch);
  }

  private isActiveMenu(menu) {
    return lodash.some(
      this.activeMenus,
      (data) => lodash.get(data, 'id') === menu.id,
    );
  }

  private handleSearch() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.paginate.page = 1;
    // LocalStorageUtils.saveItem('doctorDetailSearchMenu', JSON.stringify(this.queryParams));

    // ApiHelper.getApi('MenuApi').fetchMenuList(this.queryParams)
    ApiHelper.getApi('MedicalMenuV2Api')
      .getList(this.queryParams)
      .then((res: any) => {
        this.listMenus = res.data;
        this.totalPage = res.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private closePopup() {
    this.$emit('closeModal');
  }

  private setActiveMenu(menu) {
    const elMenuSelected = this.$refs[`menu-${menu.id}`][0];
    const elMenu: any = document.getElementsByClassName('table-hover');

    if (this.multiple) {
      elMenuSelected.classList.toggle('active-tr');
      if (this.checkActiveMenu(menu)) {
        lodash.remove(this.activeMenus, (item: any) => item.id === menu.id);
        return;
      }
      this.activeMenus.push(menu);
      return;
    }

    elMenu.forEach((el: any) => el.classList.remove('active-tr'));
    elMenuSelected.classList.add('active-tr');
    this.activeMenus = menu;
  }

  private checkActiveMenu(item) {
    if (this.activeMenus && this.activeMenus.length) {
      return this.activeMenus.some((act: any) => act.id === item.id);
    }

    return this.activeMenus.id === item.id;
  }

  private handleSubmit() {
    this.$emit('submit', this.activeMenus);
    this.$emit('cancel');
  }
}
