


























import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class FormAction extends Vue {
  @Prop({default: 'キャンセル'}) readonly btnCancelText!: string;
  @Prop({default: '送信'}) readonly btnSubmitText!: string;
  @Prop(Boolean) readonly disabledSubmit!: boolean;
  @Prop(Boolean) readonly disabledCancel!: boolean;
  @Prop(Boolean) readonly hiddenBtnSubmit!: boolean;
  @Prop(Boolean) readonly justifyCenter!: boolean;
  @Prop(Boolean) readonly submitDanger!: boolean;

  handleCancellation() {
    this.$emit('onCancel');
  }

  handleSubmit() {
    this.$emit('onSubmit');
  }
}
