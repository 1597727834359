








































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

// Components
import BaseInput from '@/components/common/BaseInput.vue';
// Icons
import iconTrash from '@/assets/img/icon-trash.svg';
import iconEdit from '@/assets/img/icon-edit-yellow.svg';

@Component({
  components: {
    BaseInput,
  },
})
export default class QuestionListItemInput extends Vue {
  @Prop(Boolean) isEditQuestion!: boolean;
  @Prop(Boolean) readOnly!: boolean;
  @Prop(String) value!: string;
  @Prop(String) placeholder!: string;
  @Prop(Boolean) countNgRequired: boolean;
  @Prop(Number) valueCountNumber!: number;

  isLoading: boolean = false;
  text: string = '';
  countNumber: number = this.valueCountNumber;
  isValid: boolean = true;

  get iconTrash() {
    return iconTrash;
  }

  get iconEdit() {
    return iconEdit;
  }

  get optionErrorMessages() {
    return {
      max: '255文字以内で入力してください',
    };
  }

  get optionErrorInputMessages() {
    return {
      max: '100文字以内で入力してください',
      numeric: '正の整数を入力してください',
    };
  }

  validateField() {
    const refInputOption = ((this.$refs.refInputOption) as any);
    return refInputOption.validate();
  }

  async handleChange() {
    const { valid } = await this.validateField();
    this.isValid = valid;
    const formattedValue = this.text?.replace(/\s{2,}/g, ' ');
    this.$emit('onChange', { value: formattedValue, count_number: this.countNumber });
  }

  async handleChangeCountNumber() {
    const value = this.text ? this.text : this.value;
    this.$emit('onChange', { value, count_number: this.countNumber });
  }


  @Watch('isEditQuestion')
  private watchIsEditQuestion() {
    if (this.isEditQuestion) {
      this.text = this.value;
    }
  }
}
