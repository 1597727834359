import { Vue, Component } from 'vue-property-decorator';

@Component
export default class CustomErrorMessage extends Vue {
  public get doctorLicenseErrorMessage() {
    return {
      max: '30文字以内で入力してください',
    };
  }

  public get doctorPriorityErrorMessage() {
    return {
      min: '1~4桁の数字を入力してください',
      max: '1~4桁の数字を入力してください',
      numeric: '半角の数字で入力してください',
    };
  }

  public get doctorNameErrorMessage() {
    return {
      max: 'ドクター名は100文字以内で入力してください',
    };
  }
  public get doctorNameKanaErrorMessage() {
    return {
      max: 'ドクター名(カナ)は100文字以内で入力してください。',
    };
  }
  public get phoneErrorMessage() {
    return {
      min: '電話番号を正しく入力してください。',
      max: '電話番号を正しく入力してください。',
      numeric: '電話番号は半角数字で入力してください（ハイフンなし）',
    };
  }
  public get emailErrorMessage() {
    return {
      email: 'メールアドレスを正しく入力してください。',
      max: 'メールアドレスは255文字以内で入力してください。',
    };
  }
  public get passwordErrorMessage() {
    return {
      min: 'パスワードを半角英数字8桁以上で入力してください',
    };
  }
  public get passwordConfirmationErrorMessage() {
    return {
      min: 'パスワードを半角英数字8桁以上で入力してください',
      confirmed: 'パスワードが一致しません。正しいパスワードを入力してください',
    };
  }

  private get oldPasswordErrorMessages() {
    return {
      min: 'パスワードは8桁以上半角英数字で入力してください',
    };
  }

  private get passwordProfileDoctorErr() {
    return {
      min: 'パスワードは8桁以上半角英数字で入力してください',
    };
  }

  private get passwordConfirmProfileDoctorErr() {
    return {
      min: 'パスワードは8桁以上半角英数字で入力してください',
      confirmed: 'パスワードと同じ文字を入力してください。',
    };
  }

  public get amountErrorMessage() {
    return {
      min_value: '正の数字を入力してください。',
      max_value: '決済額以下を入力してください',
    };
  }
}
