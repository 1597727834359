






































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { INotification } from '../../models/notification';
import autosize from 'autosize';
import DatePicker from 'vue2-datepicker';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

@Component({
  components: {
    DatePicker,
  },
})
export default class NotificationForm extends Vue {
  @Prop({ required: true }) private readonly notification!: Partial<INotification>;
  @Prop({ default: true }) private readonly isEditable!: boolean;
  private today = moment();
  private isTimeValid: boolean = true;
  private lang = { yearFormat: 'YYYY年' };
  private params: Partial<INotification> = {};
  private isSendNow: boolean | string = true;
  private isModifySendNow: any = false;

  @Watch('isSendNow')
  onChangeSend(value) {
    if (value === 'true') {
      this.$emit('sendNow', true);
      this.$emit('isValid', true);
      this.isTimeValid = true;
      this.isModifySendNow = true;
      return;
    }
    if (!this.notification.schedule_time) {
      this.$emit('isValid', false);
    }
    this.isModifySendNow = false;
    this.$emit('sendNow', false);
  }

  @Watch('isChangeData')
  onChangeData(value) {
    if (value) {
      this.$emit('change', this.notification);
    }
  }

  @Watch('notification', { deep: true })
  onChangeParams(value) {
    this.params = cloneDeep(value);
    this.$nextTick(() => {
      autosize.update(this.$refs.textarea);
    });
    if (value.schedule_time && !this.isModifySendNow) {
      this.isSendNow = false;
      const isValidDate = new Date(value.schedule_time) > new Date();
      this.$emit('isValid', isValidDate);
      if (!isValidDate && this.isEditable) {
        this.isTimeValid = false;
      }
    }
  }

  private get isChangeData() {
    return !isEqual(this.notification, this.params);
  }

  private notAfterToday(date) {
    return date < this.today.clone().startOf('date');
  }

  private onChangeTime(time) {
    if (new Date(time) > new Date()) {
      this.isTimeValid = true;
      this.$emit('isValid', true);
    } else {
      this.isTimeValid = false;
      this.$emit('isValid', false);
    }
  }

  private mounted() {
    const textarea = document.getElementById('messageContent')!;
    autosize(textarea);
    this.$nextTick(() => {
      autosize.update(textarea);
    });
  }
}
