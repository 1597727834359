




























































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { ClinicModule } from '@/store';
import { Pharmacy } from '@/models/pharmacist';
import { LIMIT_ADD_CLINIC } from '@/common/app.config';
import BasicModal from '@/components/common/BasicModal.vue';
import Pagination from '@components/common/Pagination.vue';
import PageLoader from '@components/common/PageLoader.vue';
import ApiHelper from 'api-helper';

import SearchInput from '@/components/common/SearchInput.vue';

@Component({
  components: {
    BasicModal,
    SearchInput,
    Pagination,
    PageLoader,
  },
})
export default class PopupPharmacyList extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(Array) readonly stores!: Pharmacy[];
  private isLoading: boolean = false;
  private pageCount = 0;
  private dataPharmacyList = [];
  private tempPharmacy = [];
  private query = {
    with: 'managers',
    freeword: '',
    order: '-created_at',
    page: 1,
    per_page: 10,
  };

  private get isDisabledButtonChoose() {
    return this.stores.length >= LIMIT_ADD_CLINIC;
  }

  @Emit('onChoosePharmacy')
  onChoosePharmacy(pharmacy) {
    const data: Pharmacy = {
      id: pharmacy.id,
      name: pharmacy.name,
      name_kana: pharmacy.name_kana,
    };

    (this.tempPharmacy as any).push(pharmacy.id);

    return [...this.stores, data];
  }

  @Emit('onChoosePharmacy')
  onRemovePharmacyChoosed(id: number) {
    return this.stores.filter((x) => x.id !== id);
  }

  @Emit('onChoosePharmacy')
  private cancelPopup() {
    this.$modal.hide(this.name);
    return this.stores.filter((x) => !(this.tempPharmacy as any).includes(x.id));
  }
  // Methods
  private checkPharmacyChoosed(id: number) {
    return this.stores.some((x) => x.id === id);
  }

  private beforeOpen(data) {
    this.tempPharmacy = [];
    this.query = {...this.query, freeword: '', page: 1};
    this.getList(this.query);
  }

  private onChangeSearch(freeword: string) {
    this.query = {...this.query, freeword, page: 1};
  }

  private onPageChaned(page: number) {
    this.query = {...this.query, page};
  }

  @Watch('query')
  private onQueryChanged(query: object, oldQuery: object) {
    this.getList(query);
  }

  private async getList(query) {
    try {
      this.dataPharmacyList = [];
      this.isLoading = true;
      const response = await ApiHelper.getApi('PharmacyApi').getList(query);
      this.isLoading = false;
      this.dataPharmacyList = response.data;
      this.pageCount = Number(response.last_page);
    } catch {
      this.isLoading = false;
    }
  }
}
