








import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import PageLoader from '@components/common/PageLoader.vue';
import Toast from '@common/helpers/toast';
import { ResvModule } from '@/store';
import moment from 'moment';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { State } from 'vuex-class';
import { Roles } from './common/app.config';

@Component({
  computed: {
    ...mapState('locale', [
      'isLoading',
    ]),
    ...mapState('auth', [
      'updateIsGettingUserInfo',
    ]),
  },
  components: {
    PageLoader,
  },
})
export default class App extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;

  private isLoading!: boolean;
  private updateIsGettingUserInfo!: boolean;

  public get fontFamily(): string {
    switch (this.$i18n.locale) {
      case 'ja':
        return 'noto-sans-jp';
      default:
        return '';
    }
  }

  created() {
    library.add(faTimes);
    dom.watch();

    this.$on('StartReservationBefore', (data) => {
      const startTime = moment(data.start_at).format('YYYY年 MM月DD日 HH:mm');
      let link = `/resv/detail/${data.resv_id}`;
      let text = 'の診察予定は10分後開始します。';
      if (data.isPharmacistResv) {
        link = `/reservation/${data.resv_id}`;
        text = 'のオンライン指導予定は10分後開始します。';
      }
      const message = `<div id="link-${data.resv_id}">`
                        + `<p class="mb-0 text-right icon-close"`
                          + `onclick="this.closest('.link-${data.resv_id}').remove()"`
                        + `>`
                          + `<i class="fas fa-times"></i>`
                        + `</p>`
                        + `<p class="mb-0">[${startTime}~]${text}</p>`
                        + `<p class="mb-0 mt-2">ご準備ください。`
                          + `<a onclick="this.closest('.link-${data.resv_id}').remove()"`
                            + `href="${link}"`
                            + `class="link"`
                            + `target="_blank"`
                          + `>`
                            + `LINK`
                          + `</a>`
                        + `</p>`
                      + `</div>`;
      Toast.link(message, {
        className: `link-toast link-${data.resv_id}`,
        duration: null,
      });
    });
    this.$on('CreatedResv', (channelId) => {
      Toast.link('新しい予約が入りました。', {
        action: [{
          class: 'action-link',
          text: 'Chatへ',
          href: `/messages/${channelId}`,
          onClick: (e, toastObject) => {
            const URL = e.target.href;
            toastObject.goAway(0);
            window.open(URL, '_blank');
          },
        },
        {
          class: 'action-link ml-3',
          icon: 'fa-times',
          target: channelId,
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
         }],
        duration: null,
      });
    });

    this.$on('CreatedPharmacistResv', (data) => {
      if (!this.currentUser) { return; }
      let link = `/reservation/${data.reservation_id}`;

      if (this.currentUser.role === Roles.superAdmin) {
        link = `/resv/pharmacist/detail/${data.reservation_id}`;
      }
      const message = `<div class="wrap-content-toasted">`
                        + `<p class="mb-0">[${moment(data.reservation_start_at).format('YYYY年 MM月DD日 HH:mm')}~]の新規予定は入りました。</p>`
                        + `<p class="mb-0 mt-2"><a href="${link}" class="link" target="_blank">LINK</a></p>`
                      + `</div>`;
      Toast.link(message, {
        className: 'link-toast',
        duration: 5000,
      });
    });

    this.$on('AssginDoctorToReservation', (data) => {
      Toast.link('You have been assigned to a patient', {
        className: 'link-toast',
        duration: 5000,
      });
    });
  }

  mounted() {
    this.$nextTick(() => {
      document.querySelectorAll('.btn').forEach((item) => {
        item.addEventListener('mouseup', (e) => {
          if (e && e.target) {
            (e.target as any).blur();
          }
        });
      });
    });
  }
}
