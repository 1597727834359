import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import ApiHelper from 'api-helper';
import { MedicineInput, QueryMedicine, Medicine } from '@/models/medicine';
import Toast from '@common/helpers/toast';

@Module({ name: 'medicine', namespaced: true })
export default class MedicineStore extends VuexModule {
  public dataMedicine: MedicineInput[] = [];
  public medicine: Medicine = new Medicine();
  public total: number = 0;
  public totalPage: number = 0;
  public isLoading = false;

  get isEmptyDataMedicine() {
    return !this.dataMedicine.length;
  }

  get isShowPagination() {
    return this.totalPage > 1;
  }

  @Action({ rawError: true, commit: 'SET_DATA_MEDICINE' })
  public async getMedicineList(params?: QueryMedicine) {
    const { commit, state } = this.context;
    try {
      commit('updateIsLoading', true);
      if (this.dataMedicine.length) {
        commit('RESET_STATE');
      }
      const response = await ApiHelper.getApi('MedicineApi').getList(params);
      commit('updateIsLoading', false);
      return response;
    } catch (error) {
      commit('updateIsLoading', false);
      const data = error.response.data;
      Toast.error(data.message);
    }
  }

  @Action({ rawError: true, commit: 'SET_DATA_MEDICINE_PAGINATE' })
  public async getMedicineListPaginate(params?: QueryMedicine) {
    const response = await ApiHelper.getApi('MedicineApi').getList(params);

    return response;
  }

  @Action({ rawError: true })
  public async deleteMedicine(id: number | string) {
    const reuslt = await ApiHelper.getApi('MedicineApi').destroy(id, {});
    return reuslt;
  }

  @Mutation
  public SET_DATA_MEDICINE_PAGINATE(response) {
    this.dataMedicine = response.data;
    this.total = response.total;
    this.totalPage = response.last_page;
  }

  @Mutation
  public RESET_STATE() {
    this.dataMedicine = [];
    this.total = 0;
    this.totalPage = 0;
  }

  @Mutation
  public updateIsLoading(isLoading) {
    this.isLoading = isLoading;
  }
}
