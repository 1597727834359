































import { Vue, Component, Prop } from 'vue-property-decorator';
import PrescriptionForm from '@components/v2/patient/message/partials/PrescriptionForm.vue';

import QuestionList from '@components/messages/partials/QuestionList.vue';

@Component({
  components: {
    PrescriptionForm,
    QuestionList,
  },
})
export default class PopupConfirm extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(String) readonly message!: string;
  @Prop() readonly patientAnswer!: any;
  @Prop() readonly patientQuestion!: any;
  @Prop() readonly feedbackForm!: any;
  @Prop() readonly currentChannelUser!: any;
  // @Prop() readonly changeCurrentChannelUser!: any;
  @Prop() readonly hasQuestion!: boolean;
  @Prop() readonly hasKarte!: boolean;
  @Prop() readonly setChatChannelOpen!: any;
  @Prop() readonly savedFeedback!: any;
  @Prop() readonly listFeedback!: any;

  private closeQuestionModal() {
    this.$modal.hide(this.name);
  }
}
