


























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BasicModal extends Vue {
  @Prop({ default: true }) readonly clickToClose!: boolean;
  @Prop({ default: false }) readonly draggable!: boolean;
  @Prop({ default: true }) readonly closeByIcon!: boolean;
  @Prop({ default: false }) readonly autoHeight!: boolean;
  @Prop(String) readonly name!: string;
  @Prop({default: 'auto'}) readonly height!: string | number;
  @Prop({ default: 400 }) readonly width!: string | number;
  @Prop({ default: true }) readonly scrollable!: boolean;

  private closePopup() {
    if (this.closeByIcon) {
      this.$modal.hide(this.name);
    } else {
      this.$emit('closeModal');
    }
  }
}
