


































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { DoctorModule } from '@/store';
import { LIMIT_ADD_DOCTOR_OF_MENU } from '@/common/app.config';
import BasicModal from '@/components/common/BasicModal.vue';
import Pagination from '@components/common/Pagination.vue';
import SearchInput from '../common/SearchInput.vue';
import { splitLongText } from '@common/helpers/split-long-text';

@Component({
  components: {
    BasicModal,
    SearchInput,
    Pagination,
  },
})
export default class PopupListDoctor extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(Array) readonly doctorsChoosed!: [];
  private clinicId: any;
  private tempDoctor = [];
  private tempDoctorRemove = [];
  private checkDirty = false;
  private query = {
    freeword: '',
    page: 1,
    per_page: 5,
  };

  private get dataDoctorList() {
    return DoctorModule.dataDoctor;
  }

  private longText(text: string, qty: number) {
    return splitLongText(text, qty);
  }

  private get isEmptyData() {
    return DoctorModule.isEmptyData;
  }


  private get totalPage() {
    return DoctorModule.totalPage;
  }

  private get isDisabledButtonChoose() {
    return this.doctorsChoosed.length >= LIMIT_ADD_DOCTOR_OF_MENU;
  }

  @Emit('onChooseDoctor')
  private onChooseDoctor(doctor) {
    this.checkDirty = true;
    (this.doctorsChoosed as any).push(doctor);
    (this.tempDoctor as any).push(doctor);

    const doctorIdRemove = (this.tempDoctorRemove as any).map((x) => x.id);
    if (doctorIdRemove.includes(doctor.id)) {
      this.tempDoctorRemove = this.tempDoctorRemove.filter((x) => !doctorIdRemove.includes((x as any).id));
    }

    return this.doctorsChoosed;
  }

  @Emit('onChooseDoctor')
  private onRemoveDoctorChoosed(doctor) {
    this.checkDirty = true;
    if (!(this.tempDoctorRemove as any).map((x) => x.id).includes(doctor.id)) {
      (this.tempDoctorRemove as any).push(doctor);
    }

    this.tempDoctor = this.tempDoctor.filter((x) => (x as any).id !== doctor.id);
    return this.doctorsChoosed.filter((x) => (x as any).id !== doctor.id);
  }

  @Emit('onChooseDoctor')
  private cancelPopup() {
    this.$modal.hide(this.name);
    if (this.tempDoctorRemove.length > 0) {
      this.tempDoctorRemove.filter((x) => {
      if (!(this.doctorsChoosed as any).map((item) => item.id).includes((x as any).id)) {
        this.doctorsChoosed.push(x);
      }
      });
    }
    const doctorIds = this.tempDoctor.map((x) => (x as any).id);
    return this.doctorsChoosed.filter((x) => !doctorIds.includes((x as any).id));
  }

  // Methods
  private checkDoctorChoosed(id: number) {
    return this.doctorsChoosed.some((x) => (x as any).id === id);
  }

  private beforeOpen(data) {
    this.tempDoctor = [];
    this.query = {...this.query, freeword: '', page: 1};
    DoctorModule.getDoctorListPaginate(this.query);
  }

  private onChangeSearch(freeword: string) {
    this.query = {...this.query, freeword, page: 1};
  }

  private onPageChaned(page: number) {
    this.query = {...this.query, page};
  }

  @Watch('query')
  private onQueryChanged(query: object, oldQuery: object) {
    DoctorModule.getDoctorListPaginate(query);
  }
}
