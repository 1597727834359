





























































import { Vue, Component, Prop } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import { formatDateTime } from '@/common/app.config';
import moment from 'moment';

@Component
export default class InsuranceInfo extends Vue {
  @Prop(String) readonly name!: string;

  private insuranceInfo = {};
  private nameUser: string = '';

  private formatDateTime(time) {
    const timeUTC = moment.utc(time).format();

    return moment(timeUTC).format(formatDateTime);
  }

  private beforeOpen(event) {
    if (event.params.insuranceInfo && event.params.insuranceInfo.templateMapping) {
      this.insuranceInfo = event.params.insuranceInfo.templateMapping;
    }

    if (event.params.nameUser) {
      this.nameUser = event.params.nameUser;
    }
  }

  private beforeClose() {
    this.insuranceInfo = {};
    this.nameUser = '';
  }
}
