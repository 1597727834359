



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import imageDefault from '@/assets/img/icon-no-image.svg';
import { ModelPatientDetail } from '@/models/patient';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import ZoomImage from './ZoomImage.vue';

@Component({
  components: {
    ZoomImage,
  },
})
export default class VerifyPhoto extends Vue {
  @Prop(Object) readonly dataPatientDetail!: object;
  private userFaceUrl: any = imageDefault;

  @Watch('dataPatientDetail')
  private onDataDetail() {
    const data = this.dataPatientDetail as any;
    this.userFaceUrl = data.verify_photo_url ? data.verify_photo_url : imageDefault;
  }

  private created() {
    library.add(faDownload);
    library.add(faSearchPlus);
    dom.watch();
  }
}
